import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import { history } from '../../utils/history';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class KeyActivitiesList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			keyactivities: [],
			outcomeID: null,
		};
	}

	componentDidMount() {
		if (this.props.isEdit) {
			this.setState({
				outcomeID: this.props.outcomeId.outcomeId
			});
			this.getUserKeyActivities();
		}
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		if (this.props.values.length !== prevProps.values.length) {
			this.setState({
				keyactivities: this.props.values
			});
		}
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUserKeyActivities() {

		this.props.setLoading(true);

		var postObj = {
			"outcomeID": this.props.outcomeId.outcomeId
		};

		API_Services.httpPOST(UrlConstants.URLS.getUserKeyActivities, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.props.addApiKeyActivityInList(response.data.data);
					this.setState({
						keyactivities: response.data.data
					});
				} else {
					// this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing key activity
	// Add API here so we can handle state even better
	handleKeyActivityDelete() {

		var { keyactivities, itemToRemove } = this.state;

		if (itemToRemove) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove,
			}

			API_Services.httpPOST(UrlConstants.URLS.deleteKeyActivity, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} else if (response.data) {

					if (response.data.status) {

						keyactivities.forEach((eachKeyActivity, index) => {

							if (eachKeyActivity.id == itemToRemove) {
								keyactivities.splice(index, 1);
							}
						});

						this.props.removeKeyActivity(itemToRemove);

						this.setState({
							deleteModal: false,
							itemToRemove: null,
							keyactivities: keyactivities,
						}, () => {
							this.props.setLoading(false);
						});
					} else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}

	}

	editKeyActivityScreen(rowId) {

		const { outcomeID } = this.state;

		history.push({
			pathname: '/key-activities/edit/' + rowId,
			search: '?outcomeID=' + outcomeID,
		});
	}

	handleOnDragEnd(result) {
		if (!result.destination) return;

		const { keyactivities, outcomeID } = this.state;

		const items = Array.from(keyactivities);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		this.setState({
			keyactivities: items
		}, () => {

			var sequenceIds = [];
			items.forEach((eachItem) => {
				sequenceIds.push(eachItem.id);
			});

			this.props.setLoading(true);

			var postObj = {
				keyActivities: sequenceIds,
				outcomeID: outcomeID,
			}

			API_Services.httpPOST(UrlConstants.URLS.updateKeyActivitiesRanks, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} else if (response.data) {

					if (response.data.status) {

						this.props.updateKeyActivitiesSequence(response.data.data.keyActivitiesSequence);
						this.props.setLoading(false);
					} else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});

		})
	}

	// Renders key activity in table
	renderKeyActivitiesTable() {

		// const { keyactivities } = this.props.keyactivities;
		const { keyactivities } = this.state;

		if (keyactivities.length == 0) {
			return (
				<div className="small text-info">No Key Activities added.</div>
			);
		};

		return (
			<div className="App" style={{ marginTop: 20 }}>
				<header className="App-header table-responsive">
					<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
						<Droppable droppableId="keyactivities">
							{(provided) => (
								<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
									<thead>
										<tr>
											<th className="text-center" style={{ width: 50 }}></th>
											<th className="text-center" style={{ width: 50 }}>#</th>
											<th className="text-left" style={{ width: 500 }}>Title</th>
											<th className="text-center" style={{ width: 100 }} colSpan={2}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{keyactivities.map((eachkeyactivity, index) => {
											return (
												<Draggable key={eachkeyactivity.id} draggableId={eachkeyactivity.id} index={index}>
													{(provided, snapshot) => (
														<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
															<td className="text-center" style={{ width: 50 }}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
															<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
															<td className="text-left" style={{ width: 500 }}>{eachkeyactivity.title}</td>
															<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.editKeyActivityScreen.bind(this, eachkeyactivity.id)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
															<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, eachkeyactivity.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
														</tr>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</tbody>
								</table>
							)}
						</Droppable>
					</DragDropContext>
				</header>
			</div>
		);
	}

	renderKeyActivityDeleteModal() {

		var kaObj = _.find(this.state.keyactivities, { id: this.state.itemToRemove });
		var kaTitle = kaObj ? kaObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete KeyActivity</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the key activity <span className="text-warning">{kaTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleKeyActivityDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					{this.renderKeyActivitiesTable()}
				</div>
				{this.renderKeyActivityDeleteModal()}
			</div>
		);
	}
}

KeyActivitiesList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	keyactivities: state.keyactivities,
});

export default connect(mapStateToProps, { setLoading })(
	KeyActivitiesList
);
