import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getUserNotes } from "../../actions/noteActions";
import { setLoading } from "../../actions/loadingActions.js";
import NotesList from "./NotesList";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class NoteDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		this.getUserNotes();
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUserNotes() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUserNotes, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				this.props.getUserNotes(response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	renderNoteList() {

		const { notes } = this.props.notes;

		if (notes && notes.length > 0) {
			return (
				<NotesList
					notes={this.props.notes}
				/>
			);
		} else {
			return (<p className="text-center">Please add note using the above button</p>)
		}
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/notes/">Notes</a>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="inner-container">
							<div className="text-center">
								<Link to="/notes/add" className="btn btn-primary mb-2" title="Add Note">Add Note <i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="inner-container-table" style={{marginTop: 20}}>
								{this.renderNoteList()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

NoteDashboard.propTypes = {
	getUserNotes: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	notes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	notes: state.notes,
});

export default connect(mapStateToProps, { setLoading, getUserNotes })(
	NoteDashboard
);
