import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import API_Services from '../../utils/API_Services';
import { setLoading } from "../../actions/loadingActions.js";
import TimeEstimateSelect from "../form-inputs/TimeEstimateSelect";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TaskStatus extends Component {

	constructor(props) {
		super(props);
		this.state = {
			promptTaskCompletionTimeModal: false,
			actualCompletionTimeInMinutes: this.props.taskObj ? this.props.taskObj.timeEstimateInMinutes : null,
			actualTimeInputError: null
		}
	}

	setApiResponse(message, hasError) {

		if (this.props.setApiResponse) {
			this.setState({
				promptTaskCompletionTimeModal: false
			},
			() => {
				this.props.setApiResponse(message, hasError);
			});
		}
	}

	updateTaskCompletionInServer(data) {

		this.props.setLoading(true);

		data.recordID = data.id;
		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});
		
		API_Services.httpPOST(UrlConstants.URLS.updateTask, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {
					this.setState({
						promptTaskCompletionTimeModal: false
					},
					() => {
						this.props.onTaskUpdate(response.data.data);
					});
				} 
				else {
					this.setApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			}
			this.props.setLoading(false);
		});
	}

	toggleTaskCompletion(completionStatus) {

		if (completionStatus === true) {
			
			this.setState({
				promptTaskCompletionTimeModal: true
			});
		}
		else {
			let data = this.props.taskObj;
			data.isCompleted = false;
			data.completionDateTime = '';
			this.updateTaskCompletionInServer(data);
		}
	}

	updateActualTimeForTheCompletedTasks() {

		if (!this.state.actualCompletionTimeInMinutes) {
			this.setState({
				actualTimeInputError: 'Please select valid time.'
			});
		}
		else {
			let data = this.props.taskObj;
			data.isCompleted = true;
			data.completionDateTime = new Date();
			data.actualCompletionTimeInMinutes = this.state.actualCompletionTimeInMinutes;
			this.updateTaskCompletionInServer(data);
		};
	}

	toggleModal() {

		this.setState({
			promptTaskCompletionTimeModal: !this.state.promptTaskCompletionTimeModal
		});
	}

	handleSelectField(fieldName, actualCompletionTimeInMinutes) {

		this.setState({
			actualCompletionTimeInMinutes: actualCompletionTimeInMinutes ? actualCompletionTimeInMinutes.value : null,
			actualTimeInputError: null
		});
	}

	promptTaskCompletionTimeModal() {

		var tObj = this.props.taskObj;
		var tTitle = tObj ? tObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.promptTaskCompletionTimeModal} toggle={this.toggleModal.bind(this)}>
					<ModalHeader toggle={this.toggleModal.bind(this)}>Complete Task</ModalHeader>
					<ModalBody>
						<p>Great job on completing the task <span className="text-warning">{tTitle}!</span></p>
						<p>Please specify the actual time spent on completing this task.</p>
						<div>
							<TimeEstimateSelect
								handleTimeEstimateSelect={this.handleSelectField.bind(this)}
								value={this.state.actualCompletionTimeInMinutes}
								error={this.state.actualTimeInputError}
								label={'Actual Time'}
								isRequired={true}
							/>
						</div>
						<div><small>* Provided Time Estimate is <span className="color-primary">{tObj ? Util.convertTimeToReadableText(tObj.timeEstimateInMinutes): ''}.</span></small></div>
						<div className="text-center mt-4 mb-2">
							<button type="button" className="btn btn-primary btn-sm" onClick={this.updateActualTimeForTheCompletedTasks.bind(this)}>Mark as completed</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}

    render() {

		let taskObj = this.props.taskObj;

        return (
			<div>
				<div className="align-middle" style={{ cursor: 'pointer' }} onClick={this.toggleTaskCompletion.bind(this, !taskObj.isCompleted)}>
					{taskObj.isCompleted ?
						<i className="fa fa-check-circle fa-lg color-primary" aria-hidden="true"></i>
						:
						<i className="fa fa fa-circle-o fa-lg color-primary" aria-hidden="true"></i>
					}
				</div>
				{this.promptTaskCompletionTimeModal()}
			</div>
		)
    }
}

TaskStatus.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	TaskStatus
);