import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TimeBlockTypeSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			type: [],
			apiResponse: {
				className: "",
				message: "",
			},
		}
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		// Get TimeBlocks
		this.props.setLoading(true);

		var postObj = {
			"category": "TimeBlockType"
		};

		API_Services.httpPOST(UrlConstants.URLS.getPlatformConstants, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					if (response.data.data && response.data.data.value && response.data.data.value.length > 0) {

						let timeBlockType = [];

						response.data.data.value.forEach((value, index) => {
							timeBlockType.push({
								label: value,
								value: value,
							});
						})
						this.setState({
							type: timeBlockType,
						});
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Handler for timeblocktype change event
	handleTimeBlockTypeSelect(name, value) {
		this.props.handleTimeBlockTypeSelect(name, value);
	}

	// Handler for timeblocktype name search
	handleTimeBlockTypeInputChange(value) {

	}

	selectAddedTimeBlockType(data) {

		this.props.handleTimeBlockTypeSelect("type", data);
		this.setState({
			addTimeBlockTypeModal: false,
		});
	}

	render() {

		const { type, apiResponse } = this.state;

		var timeblocktypeOptions = [];

		if (type.length > 0) {
			timeblocktypeOptions = type.map(e => ({ value: e.value, label: e.label }));
		}

		return (
			<div>
				<div className="form-group">
					<label>Time Block Type {this.props.isRequired ? <span className="text-danger">*</span> : null }</label>
					<div className="row">
						<div className="col-12">
							<ReactSelect
								name="type"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={timeblocktypeOptions}
								value={timeblocktypeOptions.find(option => option.value === this.props.value)}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleTimeBlockTypeSelect.bind(this, 'type')}
								onInputChange={this.handleTimeBlockTypeInputChange.bind(this)}
								placeholder="Select Time Block Type"
								isDisabled={this.props.disabled}
							/>
						</div>
					</div>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(apiResponse)
							?
							<p className={apiResponse.className}><span>{apiResponse.message}</span></p>
							: null
					}
				</div>
			</div>

		);
	}
}

TimeBlockTypeSelect.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	TimeBlockTypeSelect
);
