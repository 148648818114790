import React from 'react';
import TimeBlock from './TimeBlock'
import _ from 'lodash';

export default class DraggableTimeBlocks extends React.Component {

    render() {

        const events = this.props.events || []; // List of Scheduled events.
        const timeBlocks = this.props.timeBlocks || []; // Available events.
        
        let eventPopulatedArray = [];

        timeBlocks.map((timeBlockObj) => {
            
            // let associatedTimeBlock = _.filter(events, {timeBlockID: timeBlockObj.timeBlockID});

            let associatedTimeBlock = _.filter(events, function(e) { 
				if(!e.isRemovedFromThePlan && e.timeBlockID == timeBlockObj.timeBlockID) {
					return true;
				} else {
					return false;
				}
			});
        
            if (timeBlockObj.regularity == 'non-weekly') {

                timeBlockObj.sessionsPerWeek = 1; // NW TB will always have only 1 sessionsPerWeek
                timeBlockObj['scheduled'] = associatedTimeBlock.length + "/" + 1 + " scheduled";
                timeBlockObj['totalScheduled'] = associatedTimeBlock.length;

                let totalCompleted = 0;
                associatedTimeBlock.map((eachassociatedTimeBlock) => {
                    if (eachassociatedTimeBlock.isCompleted) {
                        totalCompleted = totalCompleted + 1;
                    };
                });

                timeBlockObj['completed'] = totalCompleted + "/" + 1 + " completed";
                timeBlockObj['totalCompleted'] = totalCompleted;

                let isAllCompleted = false;
                let isDraggable = true;

                if (totalCompleted >= timeBlockObj.sessionsPerWeek) {
                    isAllCompleted = true;
                };

                if (associatedTimeBlock.length >= timeBlockObj.sessionsPerWeek) {
                    isDraggable = false;
                };

                // From unplanned section, Ad-hoc TimeBlocks are restricted from Drag and Drop it to the Tracker section
                if (timeBlockObj.isAdHocTimeTrackerTimeBlock) isDraggable = false;

                eventPopulatedArray.push (
                    <TimeBlock event={timeBlockObj} isDraggable={isDraggable} isAllCompleted={isAllCompleted} key={timeBlockObj.id + timeBlockObj.start} onDoubleClick={this.props.onDoubleClick} onClickEvent={this.props.onClickEvent}/>
                );
            }
            else if (timeBlockObj.sessionsPerWeek){

                timeBlockObj['scheduled'] = associatedTimeBlock.length + "/" + timeBlockObj.sessionsPerWeek + " scheduled";
                timeBlockObj['totalScheduled'] = associatedTimeBlock.length;

                let totalCompleted = 0;
                associatedTimeBlock.map((eachassociatedTimeBlock) => {
                    if (eachassociatedTimeBlock.isCompleted) {
                        totalCompleted = totalCompleted + 1;
                    };
                });

                timeBlockObj['completed'] = totalCompleted + "/" + timeBlockObj.sessionsPerWeek + " completed";
                timeBlockObj['totalCompleted'] = totalCompleted;

                let isAllCompleted = false;
                let isDraggable = true;

                if (totalCompleted >= timeBlockObj.sessionsPerWeek) {
                    isAllCompleted = true;
                };

                if (associatedTimeBlock.length >= timeBlockObj.sessionsPerWeek) {
                    isDraggable = false;
                };

                // From unplanned section, Ad-hoc TimeBlocks are restricted from Drag and Drop it to the Tracker section
                if (timeBlockObj.isAdHocTimeTrackerTimeBlock) isDraggable = false;

                eventPopulatedArray.push (
                    <TimeBlock event={timeBlockObj} isDraggable={isDraggable} isAllCompleted={isAllCompleted} key={timeBlockObj.id + timeBlockObj.start} onDoubleClick={this.props.onDoubleClick} onClickEvent={this.props.onClickEvent}/>
                );
            }
        });

        if (eventPopulatedArray.length > 0) {
            return (
                <div className={'draggable-section'}>
                    {eventPopulatedArray}
                </div>
            );
        }
        else {
            return(
                <div className="small">You don't have any events.</div>
            );
        }
    }
}

