import {
	RESET_NOTE,
	GET_NOTE,
	GET_NOTES,
	DELETE_NOTE,
} from "../actions/types.js";

const initialState = {
	notes: [],
	note: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_NOTE:
			return {
				...initialState,
			}

		case GET_NOTE:
			return {
				...state,
				note: action.payload,
			};

		case GET_NOTES:
			return {
				...state,
				notes: action.payload,
			};

		case DELETE_NOTE:
			return {
				...state,
				notes: state.notes.filter(note => note.id !== action.payload)
			};

		default:
			return state;
	}
}
