import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";
import { connect } from "react-redux";
import Util from '../../utils/Util';
import SharedStyles from '../../assets/styles/SharedStyles';
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddOutcome from "../tracker/AddOutcome"
import AddKeyActivity from "../tracker/AddKeyActivity";
import IntervalOptions from './IntervalOptions';
import API_Services from '../../utils/API_Services';
import { setLoading } from "../../actions/loadingActions.js";
import { v4 as uuidV4 } from 'uuid';

var _ = require('lodash');
var momentTimezone = require('moment-timezone');
var UrlConstants = require('../../utils/UrlConstants');

class DetailedInfoPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			startDate: new Date(),
			recurringIntervalType: 'on_selected_day',
			recurringIntervalDayID: null,
			recurringIntervalWeekID: null,
			recurringIntervalMonthID: null,
			recurringIntervalISOWeekDayID: null,
			recurringInstances: null,
			recurringIntervalWeekDaysID: [],
			form: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
			},
			formErrors: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
			},
			focusAreas: [],
			focusAreasOptions: [],
			outcomesWithPopulatedData: [],
			outcomesOptions: [],
			keyActivityOptions: [],
		};
	}

	componentDidMount() {
		// this.populateDefaultValues(this.props);
	}

	componentWillReceiveProps(props) {
		this.populateDefaultValues(props);
	}

	// Set dropdown options for focus areas
	yourFocusAreas(focusAreas) {

		if (focusAreas && focusAreas.length > 0) {

			let focusAreasOptions = [];
			focusAreasOptions = Util.focusAreaDropdownOptions(focusAreas);

			this.setState({
				focusAreasOptions: focusAreasOptions,
				buttonDisabled: false,
			});
		}
	}

	// Set dropdown options for outcome
	yourOutcomes(outcomes) {

		if (outcomes && outcomes.length > 0) {

			let outcomesOptions = [];

			// Default select none outcomes
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				outcome: null,
			};

			let filteredOutcomesOptions = [];

			if(form["focusArea"] && form["focusArea"].value && form["focusArea"].value != 'all') {
				_.forEach(outcomes, function (eachOutcome) {
					if(eachOutcome.focusArea.id ===  form["focusArea"].value) {
						filteredOutcomesOptions.push(eachOutcome);
					}
				});
			}

			filteredOutcomesOptions.forEach((eachOutcome) => {
				outcomesOptions.push({
					label: eachOutcome.outcomeTitle,
					value: eachOutcome.id,
					focusAreaID: eachOutcome.focusArea.id
				});
			});

			if(form["focusArea"] && form["focusArea"].value) {
				outcomesOptions.push({
					label: "Add New Project",
					value: "ADD_NEW_OUTCOME",
				})
			}

			this.setState({
				form: formObj,
				outcomesOptions: outcomesOptions,
			});

		}
	}

	populateDefaultValues(props) {

		const { focusAreas, outcomesWithPopulatedData } = this.props;

		this.setState({
			focusAreas: focusAreas,
			outcomesWithPopulatedData: outcomesWithPopulatedData
		});

		this.yourFocusAreas(focusAreas);
		this.yourOutcomes(outcomesWithPopulatedData);
		this.resetForm();
		this.toggleAssignPBHPropertyForm(false)
		this.closeSubSection();

		let detailedInfo = props.detailedInfo;
		let isStartDateAdjustmentRequired = false; // When the component mounts, we need to use the synced startDate as such. 
		
		if (!detailedInfo || _.isEqual(detailedInfo, this.state.detailedInfo)) return; 

		let startDate = new Date();

		// If the user has already configured `startDate`, use that config as such.
		if (detailedInfo.startDate) {
			startDate = new Date(detailedInfo.startDate);
		}
		else if (detailedInfo.start) {
			// When a non-weekly time block is added to the Weekly Plan, let's select the default Start Date based on where the block is dropped on the weekly plan. 
		 	// Let's say the block is dropped at 9am on Thursday. We'd then use the date of Thursday based on the current week as the default Start Date IF that date has not passed already. 
			if (moment(detailedInfo.start).isAfter(moment())) {
				startDate = moment(detailedInfo.start).clone();
			}
			else {
				// If the date has already passed, we'd use the date of Thursday of the next week as the default start date. The user could then tweak it as needed.
				startDate = moment(detailedInfo.start).clone().add(7, 'd');
			};
		};

		if ((detailedInfo.regularity == 'weekly' || detailedInfo.regularity == 'daily' || detailedInfo.regularity == 'non-weekly') && !detailedInfo.startDate) {
			// If its new event & the startDate is not exist then set isStartDateAdjustmentRequired to true to adjust the start date based on saved tracker events in backend
			isStartDateAdjustmentRequired = true
		}

		if (detailedInfo.regularity == 'weekly' || detailedInfo.interval == 'every_x_weeks' || detailedInfo.interval == 'every_other_week') {

			const recurringIntervalWeekDaysID = _.clone(detailedInfo.recurringIntervalWeekDaysID);

			let daySlots = [];

			if (!recurringIntervalWeekDaysID) {
				let isoWeekday = moment(detailedInfo.start).clone().isoWeekday(); // Assign weekday based on event start date
				daySlots.push(Util.convertISOWeekDaysToRRuleWeekDays(isoWeekday)); 
			};
			this.setState({recurringIntervalWeekDaysID: recurringIntervalWeekDaysID || daySlots})
		};

		this.setState({
			startDate: new Date(startDate),
			recurringIntervalType: detailedInfo.recurringIntervalType || 'on_selected_day',
			recurringIntervalDayID: detailedInfo.recurringIntervalDayID,
			recurringIntervalWeekID: detailedInfo.recurringIntervalWeekID,
			recurringIntervalMonthID: detailedInfo.recurringIntervalMonthID,
			recurringIntervalISOWeekDayID: detailedInfo.recurringIntervalISOWeekDayID,
			recurringInterval: detailedInfo.recurringInterval,
			endsOn: detailedInfo.endsOn || "occurrence",
			occurrence: !detailedInfo.endsOn ? 100 : detailedInfo.count,
			until: detailedInfo.until? (moment(detailedInfo.until).endOf('day').toDate()) : null,
			detailedInfo: detailedInfo,
			isStartDateAdjustmentRequired: isStartDateAdjustmentRequired
		},
		() => {

			if ((detailedInfo.regularity == 'weekly' || detailedInfo.regularity == 'daily' || detailedInfo.regularity == 'non-weekly') && props.showMore === true ) {

				if (detailedInfo.startDate) { // If the user has already scheduled startDate & recurrence config, use that config as such and don't adjust recurring interval again.
					this.getRecurringInstancesOfTheEvent();
				}
				else {
					this.adjustRecurringIntervalAsPerStartDate(detailedInfo).then(() => {
						this.getRecurringInstancesOfTheEvent();
					});
				};
			};
		});
	}

	getOutcomesWithPopulatedData() {

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						outcomesWithPopulatedData: response.data.data,
					});
					this.yourOutcomes(response.data.data);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

		});
	}

	getRecurringInstancesOfTheEvent() {

		return new Promise((resolve) => {

			this.props.setLoading(true);

			let {startDate, recurringInterval, recurringIntervalType, recurringIntervalDayID, recurringIntervalWeekID, recurringIntervalMonthID, recurringIntervalISOWeekDayID, recurringIntervalWeekDaysID} = this.state;
			let detailedInfoForFetchingRecurringInstance = _.cloneDeep(this.state.detailedInfo);
			
			// The user could have experiment with various recurring options. so, don't pollute original obj and use its clone for computations.
			detailedInfoForFetchingRecurringInstance.startDate = startDate;
			detailedInfoForFetchingRecurringInstance.recurringIntervalType = recurringIntervalType;
			detailedInfoForFetchingRecurringInstance.recurringIntervalDayID = recurringIntervalDayID;
			detailedInfoForFetchingRecurringInstance.recurringIntervalWeekID = recurringIntervalWeekID;
			detailedInfoForFetchingRecurringInstance.recurringIntervalMonthID = recurringIntervalMonthID;
			detailedInfoForFetchingRecurringInstance.recurringIntervalWeekDaysID = recurringIntervalWeekDaysID;
			detailedInfoForFetchingRecurringInstance.recurringIntervalISOWeekDayID = recurringIntervalISOWeekDayID;
			detailedInfoForFetchingRecurringInstance.recurringInterval = recurringInterval;

			
			if (this.state.endsOn) {

				/* Assign updated "Ends On" changes */
	
				let {
					endsOn = (detailedInfoForFetchingRecurringInstance.endsOn || 'occurrence'), 
					occurrence = (detailedInfoForFetchingRecurringInstance.count || 100), 
					until = (moment(detailedInfoForFetchingRecurringInstance.until).endOf('day').toDate() || null)
				} = this.state;
	
				detailedInfoForFetchingRecurringInstance.endsOn = endsOn;
	
				if (endsOn === 'occurrence') {
					/* Ends on after # occurrence. */
					detailedInfoForFetchingRecurringInstance.count = occurrence;
					detailedInfoForFetchingRecurringInstance.until = null;
				}
	
				else if (endsOn === 'until') {
					/* Ends on specific date. */
					detailedInfoForFetchingRecurringInstance.until = moment(until).endOf('day').toDate();
					detailedInfoForFetchingRecurringInstance.count = null;
				}
	
				else {
					/* Ends on "Never". */
					detailedInfoForFetchingRecurringInstance.count = null;
					detailedInfoForFetchingRecurringInstance.until = null;
				};
			};

			let postObj = {
				eventObj: detailedInfoForFetchingRecurringInstance,
				recurrenceCount: 100, // Fetch handy number of instances but displaying first 6 recurring instances is enough for now.
				timezone: momentTimezone.tz.guess(),
				isStartDateAdjustmentRequired: this.state.isStartDateAdjustmentRequired || false
			};

			API_Services.httpPOST(UrlConstants.URLS.getRecurringInstancesOfTheEvent, postObj, (err, response) => {

				let recurringInstances = response && response.data && response.data.data ? response.data.data : null;
				if (this.state.isStartDateAdjustmentRequired && response && response.data && response.data.message) {
					alert(response.data.message);
				};
				this.setState({
					recurringInstances: recurringInstances,
					isStartDateAdjustmentRequired: true,
				},
				() => {

					if (recurringInstances && recurringInstances.allInstances && recurringInstances.allInstances[0]) {
						
						let recurrenceStartDate = momentTimezone.utc(recurringInstances.allInstances[0]);

						if (recurrenceStartDate.clone().startOf('day').isSame(momentTimezone(startDate).clone().startOf('day'))) {
							// The user selected startDate exactly falls on the first recurrence instance of the event.
							this.adjustRecurringIntervalAsPerStartDate(detailedInfoForFetchingRecurringInstance); // Just make sure interval options are rightly populated.
						}
						else {
							recurrenceStartDate = new Date(Date.UTC(recurrenceStartDate.clone().get('year'), recurrenceStartDate.clone().get('month'), recurrenceStartDate.clone().get('date')));
							recurrenceStartDate.setHours(0, 0, 0);

							this.setState({
								startDate: recurrenceStartDate
							},
							() => this.adjustRecurringIntervalAsPerStartDate(detailedInfoForFetchingRecurringInstance));
						};
					};
					this.props.setLoading(false);
					return resolve();
				});
			});
		});
	}

	handleStartDateUpdates(date) {

		let startDateClone = moment.utc(date).clone();
		let {until, endsOn} = this.state;

		if (until && endsOn == 'until') {
			if (moment(until).isSameOrBefore(startDateClone, 'day')) {
				return alert('Please adjust "Ends on" to feature date.');
			};
		};

		this.setState({
			startDate: date
		},
		() => {

			this.adjustRecurringIntervalAsPerStartDate().then(() => {
				this.getRecurringInstancesOfTheEvent();
			});
		});
	}

	adjustRecurringIntervalAsPerStartDate(refEventInfo) {

		let {startDate, detailedInfo, recurringIntervalType} = this.state;

		return new Promise((resolve) => {

			if (!startDate || !detailedInfo || !recurringIntervalType) return resolve(true);

			this.props.setLoading(true);

			let detailedInfoForFetchingRecurringInstance = _.cloneDeep(refEventInfo || this.state.detailedInfo);
			detailedInfoForFetchingRecurringInstance.startDate = startDate;

			let postObj = {
				eventObj: detailedInfoForFetchingRecurringInstance,
				timezone: momentTimezone.tz.guess(),
			};

			API_Services.httpPOST(UrlConstants.URLS.adjustRecurringIntervalAsPerStartDate, postObj, (err, response) => {

				let result = response && response.data && response.data.data ? response.data.data : {};
				let eventObj = result.eventObj || null;

				if (!eventObj) return resolve(true);

				this.setState({
					recurringIntervalDayID: eventObj.recurringIntervalDayID,
					recurringIntervalWeekID: eventObj.recurringIntervalWeekID,
					recurringIntervalMonthID: eventObj.recurringIntervalMonthID,
					recurringIntervalISOWeekDayID: eventObj.recurringIntervalISOWeekDayID,
				},
				() => {
					this.props.setLoading(false);
					resolve(true)
				});
			});
		});
	}

	adjustStartDateAsPerTheRecurringInterval(section) {

		const { startDate, detailedInfo } = this.state;

		if ((section === this.state.recurringIntervalType) && (detailedInfo.interval == 'monthly' || detailedInfo.interval == 'every_x_months')) {
			
			const clonedStartDate = momentTimezone(startDate).clone();

			if (section == 'on_selected_day') {

				const { recurringIntervalDayID } = this.state;

				if (recurringIntervalDayID == 32) {
					let monthEndOfStartDate = clonedStartDate.clone().endOf('month');
					clonedStartDate.set('date', monthEndOfStartDate.get('date'));
				}
				else if (clonedStartDate.clone().startOf('month').date() <= recurringIntervalDayID && clonedStartDate.clone().endOf('month').date() >= recurringIntervalDayID) {
					clonedStartDate.set('date', recurringIntervalDayID);
				}
				else {
					clonedStartDate.add(1, 'months');
					clonedStartDate.set('date', recurringIntervalDayID);
				};
				this.setState({ 
					startDate: clonedStartDate.toDate()
				}, 
				() => { 
					this.getRecurringInstancesOfTheEvent(); 
				});
			}
			else if (section == 'on_selected_xth_day_of_the_week') {

				let { recurringIntervalWeekID, recurringIntervalISOWeekDayID } = this.state;
				var modifiedStartDate = Util.getDateForSelectedXthWeekDayinMonth(startDate, recurringIntervalWeekID, recurringIntervalISOWeekDayID)

				clonedStartDate.set('date', modifiedStartDate.date());

				this.setState({ 
					startDate: clonedStartDate.toDate()
				}, 
				() => { 
					this.getRecurringInstancesOfTheEvent(); 
				});
			};
		};
	}

	onDeleteClick = () => {
		this.props.deleteEvent(this.state.detailedInfo);
		this.toggleDeleteModal();
	}

	toggleDeleteModal() {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
			});
		} else {
			this.setState({
				deleteModal: true,
			});
		}
	}

	renderEventDeleteModal() {

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Remove From Plan</ModalHeader>
					<ModalBody>
						<p><b>Are you sure you want to remove this event from the plan?</b></p>
						<div className="small">Please be informed that this action is permanent and any unsaved data(s) such as events, notes will be lost.</div>
						<div className="small mt-2">Any changes you made to this event in your <b>3-rd party calendar</b> (e.g. inviting participants) will also be lost when it gets synced.</div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.onDeleteClick}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	onEventUpdate() {

		let { events } = this.props;
		let { startDate, recurringInterval, recurringInstances, recurringIntervalType, recurringIntervalDayID, recurringIntervalWeekID, recurringIntervalMonthID, recurringIntervalISOWeekDayID, recurringIntervalWeekDaysID } = this.state;
		let eventInfo = _.cloneDeep(this.state.detailedInfo);

		if (this.state.endsOn) { /* Assign updated "Ends On" changes */

			let {
				endsOn = (eventInfo.endsOn || 'occurrence'), 
				occurrence = (eventInfo.count || 100), 
				until = (moment(eventInfo.until).endOf('day').toDate() || null)
			} = this.state;

			eventInfo.endsOn = endsOn;

			if (endsOn === 'occurrence') {
				/* Ends on after # occurrence. */
				eventInfo.count = occurrence;
				eventInfo.until = null;
			}

			else if (endsOn === 'until') {
				/* Ends on specific date. */
				eventInfo.until = moment(until).endOf('day').toDate();
				eventInfo.count = null;
			}

			else {
				/* Ends on "Never". */
				eventInfo.count = null;
				eventInfo.until = null;
			};
		};

		if (eventInfo && eventInfo.regularity == 'non-weekly' && (eventInfo.interval == 'monthly' || eventInfo.interval == 'every_x_months' || eventInfo.interval == 'annually')) {
			
			if (!this.state.startDate) {
				return alert('Please specify the start date');
			};

			if (!recurringIntervalType) {
				return alert('Please specify the recurring interval type');
			}
			else {

				if (recurringIntervalType == 'on_selected_day' && (eventInfo.interval == 'monthly' || eventInfo.interval == 'every_x_months') && !recurringIntervalDayID) {
					return alert('Please specify the recurring interval day');
				}
				else if (recurringIntervalType == 'on_selected_day' && eventInfo.interval == 'annually' && (!recurringIntervalDayID || typeof recurringIntervalMonthID != 'number')) {
					return alert('Please specify both the recurring interval month and date');
				}
				else if (recurringIntervalType == 'on_selected_xth_day_of_the_week' && (!recurringIntervalWeekID || !recurringIntervalISOWeekDayID)) {
					return alert('Please specify both the recurring interval week and day of the week');
				}
			};

			/* End of Validations. Assign the updated values to EventInfo */

			eventInfo.startDate = startDate ? new Date(startDate) : null;
			eventInfo.recurringIntervalType = recurringIntervalType;

			if (recurringIntervalType == 'on_selected_day') {
				eventInfo.recurringIntervalDayID = recurringIntervalDayID;
				eventInfo.recurringIntervalWeekID = null;
				eventInfo.recurringIntervalMonthID = eventInfo.interval == 'annually' ? recurringIntervalMonthID : null;
				eventInfo.recurringIntervalISOWeekDayID = null;
			}
			else if (recurringIntervalType == 'on_selected_xth_day_of_the_week') {
				eventInfo.recurringIntervalDayID = null;
				eventInfo.recurringIntervalWeekID = recurringIntervalWeekID;
				eventInfo.recurringIntervalMonthID = null;
				eventInfo.recurringIntervalISOWeekDayID = recurringIntervalISOWeekDayID;
			};

			/* 
			 - If the day of the week based on the start date is different from the day that the user dragged the event to on the Weekly Plan, the event should be moved to the appropriate day on the Weekly Plan. 
			 - The user should be informed about the adjustment so they’d know where to find the event. 
			*/
			if (eventInfo.start && startDate && eventInfo.start.getDay() != startDate.getDay()) {
				var dayToSet = momentTimezone(startDate).get('isoWeekday');
				var currentDayOfStart = momentTimezone(eventInfo.start).get('isoWeekday');
				var distance = dayToSet - currentDayOfStart;
				eventInfo.start.setDate(eventInfo.start.getDate() + distance);
				eventInfo.end.setDate(eventInfo.end.getDate() + distance);
				alert("The day of the week based on the selected start date is different from the day that you dragged the event to the Weekly Plan. Therefore, the event will be scheduled on your selected start date's week day.");
			};

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});

			let isEqual = _.isEqual(eventInfo, this.state.detailedInfo);
			// Set true to isEventUpdated If the user update the event configuration options(start date or interval options)
			if (isEqual === false) eventInfo.isEventUpdated = !isEqual;

			if (idx > -1) {
				events.splice(idx, 1, eventInfo); // update existing non-weekly event
			}
			else {
				let coreData = Util.getUser();
				eventInfo.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now();
				eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID + "-" + Date.now() +  "-" + uuidV4();
				events.push(eventInfo) // new non-weekly event
			};
			this.props.updateEvents(events);
		}
		else if (eventInfo && eventInfo.regularity == 'non-weekly' && eventInfo.interval == 'every_x_years') {
			
			if (!this.state.startDate) {
				return alert('Please specify the start date');
			};

			if (!recurringInterval) {
				return alert('Please specify the recurring interval');
			};

			/* End of Validations. Assign the updated values to EventInfo */

			eventInfo.startDate = startDate ? new Date(startDate) : null;
			eventInfo.recurringInterval = recurringInterval;

			this.alertDayShiftWarning(eventInfo, startDate);

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});
			let isEqual = _.isEqual(eventInfo, this.state.detailedInfo);

			// Set true to isEventUpdated If the user update the event configuration options(start date or interval options)
			if (isEqual === false) eventInfo.isEventUpdated = !isEqual;

			if (idx > -1) {
				events.splice(idx, 1, eventInfo); // update existing non-weekly event
			}
			else {
				let coreData = Util.getUser();
				eventInfo.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now();
				eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID + "-" + Date.now() +  "-" + uuidV4();
				events.push(eventInfo) // new non-weekly event
			};
			this.props.updateEvents(events);
		}
		else if (eventInfo && eventInfo.regularity == 'daily' && (eventInfo.interval == 'daily' || eventInfo.interval == 'every_x_days') ) {
			
			if (!this.state.startDate) {
				return alert('Please specify the start date');
			};

			if (eventInfo.interval == 'every_x_days' && !recurringInterval) {
				return alert('Please specify the recurring interval');
			};

			/* Assign the updated values to EventInfo */
			eventInfo.startDate = startDate ? new Date(startDate) : null;

			if (eventInfo.interval == 'every_x_days' && recurringInterval) {
				eventInfo.recurringInterval = recurringInterval;
			};

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});
			let isEqual = _.isEqual(eventInfo, this.state.detailedInfo);

			// Set true to isEventUpdated. If the user update the event configuration options(start date or interval options)
			if (isEqual === true) {
				return this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData ? true: false);
			};

			eventInfo.isEventUpdated = !isEqual;
			var futureInstances = recurringInstances.futureInstances || [];
			var upcomingEventInstanceCounter = 0;

			if (idx > -1) {
				_.map(events, (item) => {
					// Update the changes to the events those contains same WPRecurringEventID 
					if (item.WPRecurringEventID && item.WPRecurringEventID == eventInfo.WPRecurringEventID) {
						item.isEventUpdated = eventInfo.isEventUpdated;
						item.startDate = eventInfo.startDate;

						// Assign time slot changes for start date.
						item.start.setHours(eventInfo.start.getHours());
						item.start.setMinutes(eventInfo.start.getMinutes());
						item.start.setSeconds(eventInfo.start.getSeconds());

						// Assign time slot changes for end date.
						item.end.setHours(eventInfo.end.getHours());
						item.end.setMinutes(eventInfo.end.getMinutes());
						item.end.setSeconds(eventInfo.end.getSeconds());
						upcomingEventInstanceCounter = this.showUpcomingEventInstanceInWP(item, futureInstances, upcomingEventInstanceCounter);

						if (eventInfo.interval == 'every_x_days' && recurringInterval) {
							item.recurringInterval = recurringInterval;
						};

						item = _.assign(item, _.pick(eventInfo, ['startDate', 'isEventUpdated', 'endsOn', 'until', 'count']));
					};
				});
			}
			else {

				let coreData = Util.getUser();

				const startDayOfTheWeek = momentTimezone().startOf('isoWeek');
				const dailyStartDateTime = momentTimezone(eventInfo.start).startOf('isoWeek');
				const dailyEndDateTime = momentTimezone(eventInfo.start).startOf('isoWeek');

				dailyStartDateTime.set({
					day: startDayOfTheWeek.get('isoWeekday'),
					hour: eventInfo.start.getHours(),
					minute: eventInfo.start.getMinutes(),
					second: eventInfo.start.getSeconds()
				});
	
				dailyEndDateTime.set({
					day: startDayOfTheWeek.get('isoWeekday'),
					hour: eventInfo.end.getHours(),
					minute: eventInfo.end.getMinutes(),
					second: eventInfo.end.getSeconds()
				});

				eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID + "-" + Date.now();

				for (let i = 0; i < 7; i++) { // Create 7 daily events with WPRecurringEventID and unique WPEventID
					
					const dailyEventObj = _.cloneDeep(eventInfo);
					
					dailyEventObj.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now() + "-" + i + "-" + uuidV4();
					dailyEventObj.start = dailyStartDateTime.clone().add(i, 'd').toDate();
					dailyEventObj.end = dailyEndDateTime.clone().add(i, 'd').toDate();

					upcomingEventInstanceCounter = this.showUpcomingEventInstanceInWP(dailyEventObj, futureInstances, upcomingEventInstanceCounter);
					events.push(dailyEventObj);
				};

			};

			this.props.updateEvents(events);
		}
		else if (eventInfo && eventInfo.regularity == 'weekly' || eventInfo.interval == 'every_x_weeks' || eventInfo.interval == 'every_other_week') {

			eventInfo.startDate = startDate ? new Date(startDate) : moment(eventInfo.start).startOf('day').toDate();

			if (eventInfo.regularity == 'weekly') { /* Adjust startDate for weekly event. */

				if (eventInfo.startDate) {
					let currentWeekStartDate = moment().startOf('isoWeek');
					let eventStartDate = moment(eventInfo.startDate);
					if (eventStartDate.isSameOrBefore(currentWeekStartDate.clone())) {
						/* If startDate is falls before current week then we are updating the startDate for weekly events. */
						// eventInfo.startDate = currentWeekStartDate.toDate();
					};
				};
			};

			if (eventInfo.PBH_WP_Event_Version && recurringIntervalWeekDaysID && recurringIntervalWeekDaysID.length === 0) {
				return alert('Please select Weekday');
			};

			if (eventInfo.PBH_WP_Event_Version && recurringIntervalWeekDaysID && recurringIntervalWeekDaysID.length > 0) {

				eventInfo.recurringIntervalWeekDaysID = _.clone(recurringIntervalWeekDaysID);

				if (recurringIntervalWeekDaysID && !recurringIntervalWeekDaysID.length) {
					return alert('Please select Weekday');
				};

				let isEqual = _.isEqual(eventInfo, this.state.detailedInfo);
				
				if (isEqual) { // No changes were made. So, close the popup. 
					return this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData ? true: false);
				};

				let coreData = Util.getUser();

				if (!eventInfo.WPRecurringEventID) {

					/* Newly created event will not contain WPRecurringEventID. So, create one and assign it to eventInfo object. */
					eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID + "-" + Date.now() + "-" + uuidV4();

					eventInfo.isEventUpdated = !isEqual;
					
					const weekStartDate = momentTimezone().startOf('isoWeek');
					
					/* Create event based on the configuration */
					eventInfo.recurringIntervalWeekDaysID.map((weekday, key) => {

						const eventStartDate = momentTimezone(eventInfo.start);
						const eventEndDate = momentTimezone(eventInfo.end);

						const equivalentISOWeekday = Util.convertRRuleWeekDaysToISOWeekDays(weekday);
						const computedEventDate = weekStartDate.clone().isoWeekday(equivalentISOWeekday);
						const newOrUpdatedEvent = _.cloneDeep(eventInfo);

						eventStartDate.set({
							month: computedEventDate.clone().get('month'),
							year: computedEventDate.clone().get('year'),
							date: computedEventDate.clone().get('date')
						});

						eventEndDate.set({
							month: computedEventDate.clone().get('month'),
							year: computedEventDate.clone().get('year'),
							date: computedEventDate.clone().get('date')
						});

						newOrUpdatedEvent.start = eventStartDate.clone().toDate();
						newOrUpdatedEvent.end = eventEndDate.clone().toDate();
						newOrUpdatedEvent.WPEventID = eventInfo.WPRecurringEventID + "-" + (weekday);

						events.push(newOrUpdatedEvent);
					});

				}
				else {

					const weekStartDate = momentTimezone().startOf('isoWeek');
					const newlySelectedDaySlots = _.clone(recurringIntervalWeekDaysID);

					eventInfo.isEventUpdated = true;

					events.map((item, key) => { // Update events based on recurringIntervalWeekDaysID.

						if (item.WPRecurringEventID && item.WPRecurringEventID == eventInfo.WPRecurringEventID && item.recurringIntervalWeekDaysID) {

							let eventDaySlot = moment(item.start).clone().isoWeekday();
							eventDaySlot = Util.convertISOWeekDaysToRRuleWeekDays(eventDaySlot);

							if (recurringIntervalWeekDaysID.includes(eventDaySlot)) { // Update

								item = _.assign(item, _.pick(eventInfo, ['isEventUpdated', 'startDate', 'endsOn', 'until', 'count']));
								item.recurringIntervalWeekDaysID = _.clone(recurringIntervalWeekDaysID);

								item.start.setHours(eventInfo.start.getHours(), eventInfo.start.getMinutes(), 0);
								item.end.setHours(eventInfo.end.getHours(), eventInfo.end.getMinutes(), 0);

								const updatedDaySlotIdx = newlySelectedDaySlots.findIndex(day => day === eventDaySlot);
								if (updatedDaySlotIdx > -1) newlySelectedDaySlots.splice(updatedDaySlotIdx, 1);
							}
							else { // Remove

								item.isEventUpdated = true;
								item.isRemovedFromThePlan = true;
							};
						};

					});

					/* Create event newly selected day slots */
					newlySelectedDaySlots.map((weekday, key) => {

						const eventStartDate = momentTimezone(eventInfo.start);
						const eventEndDate = momentTimezone(eventInfo.end);
						
						const equivalentISOWeekday = Util.convertRRuleWeekDaysToISOWeekDays(weekday);
						const computedEventDate = weekStartDate.clone().isoWeekday(equivalentISOWeekday);
						const newEvent = _.cloneDeep(eventInfo);

						eventStartDate.set({
							month: computedEventDate.clone().get('month'),
							year: computedEventDate.clone().get('year'),
							date: computedEventDate.clone().get('date')
						});

						eventEndDate.set({
							month: computedEventDate.clone().get('month'),
							year: computedEventDate.clone().get('year'),
							date: computedEventDate.clone().get('date')
						});

						newEvent.start = eventStartDate.clone().toDate();
						newEvent.end = eventEndDate.clone().toDate();
						newEvent.recurringIntervalWeekDaysID = _.clone(recurringIntervalWeekDaysID);
						newEvent.WPEventID = newEvent.WPRecurringEventID + "-" + (weekday);

						events.push(newEvent);
					});
				};

				this.props.updateEvents(events);

			}
			else {

				/* One instance in a week */

				const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});

				let isEqual = _.isEqual(eventInfo, this.state.detailedInfo);

				if (isEqual === false) eventInfo.isEventUpdated = !isEqual; // Set isEventUpdated to true If its updated.
				
				if (!eventInfo.PBH_WP_Event_Version && eventInfo.recurringIntervalWeekDaysID) {
					delete eventInfo.recurringIntervalWeekDaysID;
				};
		
				let currentDaySlot = moment(eventInfo.start).clone().isoWeekday();
				eventInfo.recurringIntervalWeekDaysID = [Util.convertISOWeekDaysToRRuleWeekDays(currentDaySlot)]
				eventInfo.startDate = eventInfo.start;

				if (idx > -1) {
					events.splice(idx, 1, eventInfo); // Update existing event.
				}
				else {
					let coreData = Util.getUser();
					eventInfo.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now();
					eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID + "-" + Date.now() +  "-" + uuidV4();
					events.push(eventInfo) // new event
				};
				this.props.updateEvents(events);
			};

		};

		this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData ? true: false);
	}

	// Handler for submit
	handleAssignPBHPropertiesFormSubmit(e) {

		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			let chosenOutcome = null;
			let customTitle = this.state.detailedInfo.title; // default title as Timeblock_Title

			if (form['outcome'] && form['outcome']['value']) {
				chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: form['outcome']['value'] });
			}

			if (chosenOutcome && chosenOutcome['abbreviation'] && form['keyActivity'] && form['keyActivity']['label']) {
				customTitle =chosenOutcome['abbreviation'] + ": " + form['keyActivity']['label']; // custom title > Outome_Abbreviation: Key_Activity_Title
			}

			let outcomeID = chosenOutcome? form['outcome']['value'] : null;
			let outcomeAbbr = chosenOutcome? chosenOutcome['abbreviation'] : null;
			let outcomeTitle = chosenOutcome? form['outcome']['label'] : null;
			let keyActivityID = form['keyActivity'] && form['keyActivity']['value'] ? form['keyActivity']['value'] : null;
			let keyActivityTitle = form['keyActivity'] && form['keyActivity']['label'] ? form['keyActivity']['label'] : null;
			
			let focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
			let focusAreaTitle = form['focusArea'] && form['focusArea']['label'] ? form['focusArea']['label'] : null;

			// update event object state
			let detailedInfo = {
				...this.state.detailedInfo,
				outcomeID: outcomeID,
				outcomeAbbr: outcomeAbbr,
				outcomeTitle: outcomeTitle,
				keyActivityID: keyActivityID,
				keyActivityTitle: keyActivityTitle,
				focusAreaID: focusAreaID,
				focusAreaTitle: focusAreaTitle,
				isUnAssigned: false,
			};

			// If an unplanned event is assigned with KA, then insert the metricsAndTargets associated with the corresponding KA to it.
			if ((!detailedInfo.metricsAndTargets || _.isEmpty(detailedInfo.metricsAndTargets)) && chosenOutcome && chosenOutcome.keyActivities && keyActivityID) {
				
				let keyActivityObj = _.find(chosenOutcome.keyActivities, {id: keyActivityID});

				if (keyActivityObj && keyActivityObj.metricsAndTargets) {
					detailedInfo.metricsAndTargets = _.cloneDeep(keyActivityObj.metricsAndTargets);
				};
			};

			this.setState({
				detailedInfo: detailedInfo,
				showPBHPropertiesAssignmentForm: false,
			});

			this.props.updateNewlyAssignedPBHProperties(detailedInfo);

			this.resetForm();

			this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData ? true: false);
		}
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "outcome":
				if (!value) errorMsg = "Please select an Project";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select a Focus Area";
				break;

			case "keyActivity":
				if (!value) errorMsg = "Please select a Key Activity";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'outcome': null,
			'keyActivity': null,
		};

		let filteredOutcomesOptions = [];

		if(value.value != 'all') {
			_.forEach(this.state.outcomesWithPopulatedData, function (eachOutcome) {
			
				if(eachOutcome.focusArea.id ===  value.value) {
					filteredOutcomesOptions.push(eachOutcome);
				}
			});
		} else {
			filteredOutcomesOptions = this.state.outcomesWithPopulatedData;
		}

		let outcomesOptions = [];
		filteredOutcomesOptions.forEach((eachOutcome) => {

			outcomesOptions.push({
				label: eachOutcome.outcomeTitle,
				value: eachOutcome.id,
				focusAreaID: eachOutcome.focusArea.id
			});
		});

		outcomesOptions.push({
			label: "Add New Project",
			value: "ADD_NEW_OUTCOME",
		})

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for outcome select field change event
	handleOutcomeSelectField(name, value) {

		if (name == "outcome" && value["value"] == "ADD_NEW_OUTCOME") {
			// Show Section - Add New Outcome
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'keyActivity': null,
		};

		let chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: value.value });

		let keyActivities = chosenOutcome['keyActivities'];

		let keyActivityOptions = [];

		// Some user may not have added key activitites for an outcome
		if (keyActivities && keyActivities.length > 0) {

			keyActivities.forEach((eachkeyActivity) => {

				keyActivityOptions.push({
					label: eachkeyActivity.title,
					value: eachkeyActivity.id,
				});
			});
		}

		keyActivityOptions.push({
			label: "Add New Key Activity",
			value: "ADD_NEW_KA",
		});

		this.setState({
			form: formObj,
			keyActivityOptions: keyActivityOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for Key Activity select field change event
	handleKeyActivitySelectField(name, value) {

		if (name == "keyActivity" && value["value"] == "ADD_NEW_KA") {
			// Show Section - Add New KeyActivity
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	resetForm() {

		var formObj = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
		};

		var formErrors = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
			outcomesOptions: [],
			keyActivityOptions: [],
		});
	}

	toggleAssignPBHPropertyForm(status) {
		this.setState({
			showPBHPropertiesAssignmentForm: status,
		})
	}

	closeSubSection = () => {
		this.setState({
			displaySubSection: ""
		})
	}

	handleNewOutcomeSubmit = (createdOutcome, displaySubSection = "" ) => {

		this.getOutcomesWithPopulatedData();

		const { outcomesOptions, form } = this.state;
		const focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
		const newOutcome = {
			label: createdOutcome.outcomeTitle,
			value: createdOutcome.id,
			focusAreaID: focusAreaID
		}

		setTimeout(() => {
			
			outcomesOptions.splice((outcomesOptions.length - 1), 0, newOutcome); // push new outcome
			var keyActivityOptions = [{
				label: "Add New Key Activity",
				value: "ADD_NEW_KA",
			}];

			form["outcome"] = newOutcome; // select newly created outcome
			form["keyActivity"] = null;

			this.setState({
				reloadOutcomesData: true,
				displaySubSection: displaySubSection,
				outcomesOptions: outcomesOptions,
				form: form,
				keyActivityOptions: keyActivityOptions,
			})
		}, 500);
	}

	handleNewKeyActivitySubmit = (createdKA, displaySubSection ="" ) => {

		this.getOutcomesWithPopulatedData();

		const { keyActivityOptions, form } = this.state;

		const newKA = {
			label: createdKA.title,
			value: createdKA.id,
		}

		keyActivityOptions.splice((keyActivityOptions.length - 1), 0, newKA);

		setTimeout(() => {
			form["keyActivity"] = newKA; // select newly created KA 
			this.setState({
				reloadOutcomesData: true,
				displaySubSection: displaySubSection,
				keyActivityOptions: keyActivityOptions,
				form: form
			})
		}, 500);
	}

	toggleNewOutcomeKAModal() {

		this.setState({
			displaySubSection: "",
			showAssignForm: true,
		});

		if (this.state.showAssignForm) {
			this.setState({
				displaySubSection: "",
				showAssignForm: false,
			});
		} else {
			this.setState({
				displaySubSection: "",
				showAssignForm: true,
			});
		}
	}

	showUpcomingEventInstanceInWP = (item, futureInstances = [], upcomingEventInstanceCounter) => {

		if (futureInstances.length && upcomingEventInstanceCounter < futureInstances.length) {

			var featureEventIsoWeekDay = moment(futureInstances[upcomingEventInstanceCounter]).isoWeekday();
			var eventIsoWeekDay = moment(item.start).isoWeekday();

			if (featureEventIsoWeekDay == eventIsoWeekDay && moment(futureInstances[0]).isSame(futureInstances[upcomingEventInstanceCounter], 'week')) {
				upcomingEventInstanceCounter++;
				item.skipEventInWP = false;
			}

			else item.skipEventInWP = true;
		}

		else item.skipEventInWP = true;

		return upcomingEventInstanceCounter;
	}

	toggleWeekDaySelection = (selectedWeekday, detailedInfo) => {

		let { recurringIntervalWeekDaysID = [] } = this.state;
		let { sessionsPerWeek = 1, interval = '' } = detailedInfo;

		if (recurringIntervalWeekDaysID.includes(selectedWeekday.value)) {
			const idx = recurringIntervalWeekDaysID.findIndex(day => day === selectedWeekday.value);
			if (idx > -1) recurringIntervalWeekDaysID.splice(idx, 1); // Remove
		}

		else {
			if (recurringIntervalWeekDaysID.length < sessionsPerWeek) {
				recurringIntervalWeekDaysID.push(selectedWeekday.value); //Add
			}
			else {
				return alert("Session per week maximum limit is reached.");
			}
		}

		this.setState({recurringIntervalWeekDaysID: recurringIntervalWeekDaysID}, () => {
			this.getRecurringInstancesOfTheEvent();
		});
	}

	alertDayShiftWarning = (eventInfo, startDate) => {

		/* 
		- If the day of the week based on the start date is different from the day that the user dragged the event to on the Weekly Plan, the event should be moved to the appropriate day on the Weekly Plan. 
		- The user should be informed about the adjustment so they’d know where to find the event. 
		*/

		if (eventInfo.start && startDate && eventInfo.start.getDay() != startDate.getDay()) {
			var dayToSet = momentTimezone(startDate).get('isoWeekday');
			var currentDayOfStart = momentTimezone(eventInfo.start).get('isoWeekday');
			var distance = dayToSet - currentDayOfStart;
			eventInfo.start.setDate(eventInfo.start.getDate() + distance);
			eventInfo.end.setDate(eventInfo.end.getDate() + distance);
			alert("The day of the week based on the selected start date is different from the day that you dragged the event to the Weekly Plan. Therefore, the event will be scheduled on your selected start date's week day.");
		};
	}

	/* Scheduling section for a NonWeekly event. */
	renderSchedulingSection(detailedInfo) {

		if (!detailedInfo) return null;

		let { startDate, recurringIntervalType, recurringIntervalDayID, recurringIntervalWeekID, recurringIntervalMonthID, recurringIntervalISOWeekDayID, recurringInstances } = this.state;
		let recurringDateSelector = null;
		let isWeeklyEvent = detailedInfo.regularity === 'weekly';

		if (detailedInfo.interval == 'monthly' || detailedInfo.interval == 'every_x_months') {
			
			let ISOWeekDays = IntervalOptions.getISOWeekDays();
			let weekOptions = IntervalOptions.getWeekOptions(detailedInfo.interval);
			let dayIntervalNumericOptions = IntervalOptions.getDayIntervalNumericOptions(detailedInfo.interval);

			recurringDateSelector = (
				<div className="custom-radio-group" style={{flexDirection: 'column'}}>
					<div className="radio-item-container">
						<input
							type="radio"
							name={"recurring_interval_type"}
							checked={recurringIntervalType == 'on_selected_day'}
							value={'on_selected_day'}
							onChange={() => this.setState({
								recurringIntervalType: 'on_selected_day'
							}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_day'))}
						/>
						<label className="ml-2" style={{fontSize: 14}}>{detailedInfo.interval != 'every_x_months' ? _.capitalize(detailedInfo.interval) : ''} on day</label>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={dayIntervalNumericOptions}
								value={_.find(dayIntervalNumericOptions, {value: recurringIntervalDayID})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringIntervalDayID: item && item.value ? item.value : null
								}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_day'))}
							/>
						</div>
					</div>
					<div className="radio-item-container">
						<input
							type="radio"
							name={"recurring_interval_type"}
							checked={recurringIntervalType == 'on_selected_xth_day_of_the_week'}
							value={'on_selected_xth_day_of_the_week'}
							onChange={() => this.setState({
								recurringIntervalType: 'on_selected_xth_day_of_the_week'
							}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_xth_day_of_the_week'))}
						/>
						<label className="ml-2" style={{fontSize: 14}}>{detailedInfo.interval != 'every_x_months' ? _.capitalize(detailedInfo.interval) : ''} on the &nbsp;</label>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={weekOptions}
								value={_.find(weekOptions, {value: recurringIntervalWeekID})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringIntervalWeekID: item && item.value ? item.value : null
								}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_xth_day_of_the_week'))}
							/>
						</div>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={ISOWeekDays}
								value={_.find(ISOWeekDays, {value: recurringIntervalISOWeekDayID})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringIntervalISOWeekDayID: item && item.value ? item.value : null
								}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_xth_day_of_the_week'))}
							/>
						</div>
					</div>
				</div>
			);
		}
		else if (detailedInfo.interval == 'annually') {

			let monthsInAYear = IntervalOptions.getMonthsInAYear();
			let dayIntervalNumericOptions = IntervalOptions.getDayIntervalNumericOptions('monthly'); // Since we ask the users to select the month as well, the date can't exceed 31

			recurringDateSelector = (
				<div className="custom-radio-group" style={{flexDirection: 'column'}}>
					<div className="radio-item-container">
						<input 
							type="radio" 
							name={"recurring_interval_type"}
							checked={recurringIntervalType == 'on_selected_day'} 
							value={'on_selected_day'}
							onChange={() => this.setState({
								recurringIntervalType: 'on_selected_day'
							}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_day'))}
						/>
						<label className="ml-2" style={{fontSize: 14}}>Annually on day</label>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={monthsInAYear}
								value={_.find(monthsInAYear, {value: recurringIntervalMonthID})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringIntervalMonthID: item && item.value ? item.value : null
								}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_day'))}
							/>
						</div>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={dayIntervalNumericOptions}
								value={_.find(dayIntervalNumericOptions, {value: recurringIntervalDayID})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringIntervalDayID: item && item.value ? item.value : null
								}, () => this.adjustStartDateAsPerTheRecurringInterval('on_selected_day'))}
							/>
						</div>
					</div>
				</div>
			);
		}
		else if (detailedInfo.interval === 'every_x_years') {

			let { recurringInterval } = this.state;
			let yearIntervalNumericOptions = IntervalOptions.getNumericOptions(2, 5);
			let isRecurringIntervalValueIsExistInOptions = _.find(yearIntervalNumericOptions, {value: recurringInterval});

			if (!isRecurringIntervalValueIsExistInOptions) {
				yearIntervalNumericOptions.push({'label': recurringInterval, 'value': recurringInterval});
			}; 

			recurringDateSelector = (
				<div className="custom-radio-group" style={{flexDirection: 'column'}}>
					<div className="radio-item-container">
						<input 
							type="radio" 
							name={"recurring_interval_type"}
							checked={true}
						/>
						<label className="ml-2" style={{fontSize: 14}}>Repeat every # years</label>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={yearIntervalNumericOptions}
								value={_.find(yearIntervalNumericOptions, {value: recurringInterval})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringInterval: item && item.value ? item.value : null,
									isStartDateAdjustmentRequired: true,
								}, () => this.getRecurringInstancesOfTheEvent())}
							/>
						</div>
					</div>
				</div>
			);
		}
		else if (detailedInfo.interval == 'every_x_days') {

			let { recurringInterval } = this.state;

			let dayIntervalNumericOptions = IntervalOptions.getDayIntervalNumericOptions(detailedInfo.interval);
			let isRecurringIntervalValueIsExistInOptions = _.find(dayIntervalNumericOptions, {value: recurringInterval});

			if (!isRecurringIntervalValueIsExistInOptions) {
				dayIntervalNumericOptions.push({'label': recurringInterval, 'value': recurringInterval});
			};

			recurringDateSelector = (
				<div className="custom-radio-group" style={{flexDirection: 'column'}}>
					<div className="radio-item-container">
						<label className="ml-2" style={{fontSize: 14}}>Repeat every # day</label>
						<div className="ml-2" style={{minWidth:200}}>
							<ReactSelect
								name="recurring_interval"
								styles={SharedStyles.selectBoxStyles}
								options={dayIntervalNumericOptions}
								value={_.find(dayIntervalNumericOptions, {value: recurringInterval})}
								clearable={true}
								onChange={(item) => this.setState({
									recurringInterval: item && item.value ? item.value : null,
									isStartDateAdjustmentRequired: false,
								}, () => this.getRecurringInstancesOfTheEvent())}
							/>
						</div>
					</div>
				</div>
			);
		};

		return(
			<div className="mt-4">
				<h6 className="mb-3">Scheduling options</h6>
				{
				<div className="form-group">
					<div className="mb-2">Start Date <span className="text-danger">*</span></div>
					<DatePicker
						selected={startDate}
						onChange={(date) => this.handleStartDateUpdates(date)}
						minDate={new Date()}
						placeholderText="Start Date"
						className="form-control"
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dateFormat="MMMM d, yyyy"
						dropdownMode="select"
					/>
					{recurringDateSelector && detailedInfo.interval != 'annually' ?
						<div className="form-group mt-3">
							<div className="mb-2">Recurring Interval <span className="text-danger">*</span></div>
							{recurringDateSelector}
						</div>
					: null}
				</div>
				}
				{this.weekDaySelectorOption(detailedInfo)}
				<div className="form-group">
					<div className="form-group">
						{this.renderEndsOnConfiguration(detailedInfo)}
					</div>
				</div>
				{ recurringInstances && !_.isEmpty(recurringInstances.futureInstances) ?
					<div>
						{ detailedInfo.interval != 'annually' ?
							<h6 className="mb-4">Upcoming {recurringInstances.futureInstances.length > 5 && '6' } Recurring Instances of this Event</h6> :
							<h6 className="mb-4">This event will repeat annually on {momentTimezone.utc(recurringInstances.futureInstances[0]).format('MMM Do')}</h6>
						}
						<ol className="ml-n3">
							{recurringInstances.futureInstances.slice(0, 6).map((instanceDate, key) => {
								return <li key={'instance_id_' + key} className="mb-3 small-title-text">{momentTimezone.utc(instanceDate).format('llll')}</li>
							})}
						</ol>
					</div>
				: null }
				{
					!isWeeklyEvent &&
					<div>
						<div className="font-bold">Note:</div>
						<ol className="ml-n3">
							{/* <li className="small-title-text mt-3 mb-2">Kindly note that recurring events will not be updated for weeks that you have changes (e.g. tracking data) saved for already.</li> */}
							<li className="small-title-text mt-3">Please note that the start date could change based on the changes you make to the Recurring Interval configuration for the event.</li>
						</ol>
					</div>
				}
			</div>
		);
	}

	weekDaySelectorOption = (detailedInfo) => {

		if (!detailedInfo.PBH_WP_Event_Version) return null;

		if (detailedInfo.regularity === 'weekly' || detailedInfo.interval == 'every_x_weeks' || detailedInfo.interval == 'every_other_week' ) {
		
			let {recurringIntervalWeekDaysID = []} = this.state;
			let weekDays =  IntervalOptions.getWeekDays()
			let activeClass = 'weekly-plan-day-btn-active';
			let defaultClass = 'weekly-plan-day-btn';
	
			return ( 
				<div>
					<div className="form-group"> 
							<div className="mb-2">Week Day<span className="text-danger">*</span></div>
						<ButtonGroup>
							{
								weekDays.map((weekDay, i) => {
									return <div 
												key={'weekday'+i} 
												name={weekDay.label} 
												onClick={()=>{this.toggleWeekDaySelection(weekDay, detailedInfo)}}
												style={{minWidth: 50}}
												className={'p-2 btn-sm btn btn-secondary ' + (recurringIntervalWeekDaysID.includes(weekDay.value) ? activeClass: defaultClass)}>
													{(weekDay.label).substring(3, 0)}
											</div>
								})
							}
						</ButtonGroup>
					</div>
				</div>
			);

		}


	}

	/* Render Ends On settings for events. */
	renderEndsOnConfiguration = (detailedInfo) => {

		let {
			startDate,
			endsOn = (detailedInfo.endsOn || 'occurrence'), 
			occurrence = (detailedInfo.count || 100), 
			until = (moment(detailedInfo.until).endOf('day').toDate() || null)
		} = this.state;

		let occurrenceOptions = IntervalOptions.getNumericOptions();
		let isOccurrenceValueIsExistInOptions = _.find(occurrenceOptions, {value: occurrence});
		let minDate = moment();
		let startDateClone = startDate ? moment(startDate) : moment(detailedInfo.start);

		if (moment().diff(startDateClone, 'd') <= 0) {
			minDate = startDateClone;
		};

		if (!isOccurrenceValueIsExistInOptions) {
			occurrenceOptions.push({'label': occurrence, 'value': occurrence});
		};

		return (
			<div>
				<div className="mt-3 mb-2">Ends On <span className="text-danger">*</span></div>
				<div className="form-group">
					<div className="custom-radio-group" style={{flexDirection: 'column'}}>
						<div className="radio-item-container">
							<input
								type="radio"
								name={"ends_on_config"}
								checked={endsOn == 'occurrence'}
								value={'occurrence'}
								onChange={() => this.setState({
									endsOn: 'occurrence',
									occurrence: detailedInfo.count || 100,
									isStartDateAdjustmentRequired: false,
								}, () => {this.getRecurringInstancesOfTheEvent();})}
							/>
							<label className="ml-2" style={{fontSize: 14}}>Occurrence</label>
							<div className="ml-2" style={{minWidth:200}}>
								<ReactSelect
									clearable={true}
									name="occurrence_count"
									placeholderText="# Occurrence"
									options={occurrenceOptions}
									styles={SharedStyles.selectBoxStyles}
									isDisabled={endsOn == 'occurrence'? false : true}
									value={_.find(occurrenceOptions, {value: occurrence})}
									onChange={(item) => this.setState({
										occurrence: item && item.value ? item.value : null,
										endsOn: 'occurrence',
										isStartDateAdjustmentRequired: false,
									}, () => {this.getRecurringInstancesOfTheEvent();})}
								/>
							</div>
						</div>
					</div>
					<div className="custom-radio-group" style={{flexDirection: 'column'}}>
						<div className="radio-item-container">
							<input
								type="radio"
								name={"ends_on_until"}
								checked={endsOn == 'until'}
								value={'until'}
								onChange={() => {
										let defaultUntilDate = moment(detailedInfo.startDate || detailedInfo.start).add(50, 'day').endOf('day').toDate();
										this.setState({ isStartDateAdjustmentRequired: false, endsOn: 'until', until: defaultUntilDate}, () => {
											this.getRecurringInstancesOfTheEvent();
										});
									}
								}
							/>
							<label className="ml-2 mr-2" style={{fontSize: 14}}>Until</label>
							<div className="ml-5" style={{minWidth:200}}>
							<DatePicker
								selected={endsOn == 'until'? until : null}
								disabled={endsOn == 'until'? false : true}
								onChange={(date) => {

									/* Set selected date to until */
									let selectedDate = date;
									selectedDate.setHours(23, 59, 0);

									this.setState({
										isStartDateAdjustmentRequired: false, until: selectedDate, endsOn: 'until'
									}, ()=> {
										this.getRecurringInstancesOfTheEvent();
									});

								}}
								minDate={minDate.toDate()}
								placeholderText="End Date"
								className="form-control"
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dateFormat="MMMM d, yyyy"
								dropdownMode="select"
							/>
							</div>
						</div>
					</div>
					<div className="custom-radio-group mt-2" style={{flexDirection: 'column'}}>
						<div className="radio-item-container">
							<input
								type="radio"
								name={"ends_on_never"}
								checked={endsOn == 'never'}
								value={'never'}
								onChange={() => this.setState({ endsOn: 'never' }, () => {this.getRecurringInstancesOfTheEvent();})}
							/>
							<label className="ml-2" style={{fontSize: 14}}>Never</label>
							<div className="ml-2" style={{minWidth:200}}></div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderDayColumn = (day) => {

		const { detailedInfo, recurringIntervalType } = this.state;

		if (!detailedInfo) return null;

		let isNonWeeklyTB = detailedInfo && detailedInfo.regularity == 'non-weekly' ? true : false;

		if (!isNonWeeklyTB ||
				isNonWeeklyTB && (
						!recurringIntervalType 
						|| detailedInfo.interval == 'every_other_week'
						|| recurringIntervalType != 'on_selected_day'))
			return (
				<tr>
					<td className="small-title-text">Day</td>
					<td>{day}</td>
				</tr>
			)
		else return null;
	}

	renderPBHPropertyForm(title) {

		const { form, formErrors, focusAreasOptions, outcomesOptions, keyActivityOptions } = this.state;

		return(
			<React.Fragment>
				<ModalHeader>Update PBH Properties</ModalHeader>
				<ModalBody>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>FocusArea <span className="text-danger">*</span></label>
									<div>
										<ReactSelect
											name="focusArea"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={focusAreasOptions}
											value={form.focusArea}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
											placeholder="Select Focus Area"
										/>
										<p className="text-danger">{formErrors['focusArea'] &&
										<span className="err">{formErrors['focusArea']}</span>}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Project <span className="text-danger">*</span></label>
									<ReactSelect
										name="outcome"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={outcomesOptions}
										value={form['outcome']}
										removeSelected={true}
										autosize={true}
										clearable={true}
										onSelectResetsInput={true}
										onChange={this.handleOutcomeSelectField.bind(this, "outcome")}
										placeholder="Select Project"
									/>
									<p className="text-danger">{formErrors['outcome'] &&
										<span className="err">{formErrors['outcome']}</span>}</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Key Activity <span className="text-danger">*</span></label>
									<ReactSelect
										name="keyActivity"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={keyActivityOptions}
										value={form['keyActivity']}
										removeSelected={true}
										autosize={true}
										clearable={true}
										onSelectResetsInput={true}
										onChange={this.handleKeyActivitySelectField.bind(this, "keyActivity")}
										placeholder="Select Key Activity"
									/>
									<p className="text-danger">{formErrors['keyActivity'] &&
										<span className="err">{formErrors['keyActivity']}</span>}</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<button type="button" onClick={this.handleAssignPBHPropertiesFormSubmit.bind(this)} className="btn btn-sm mr-2 btn-primary">Save</button>
								<Button color="secondary" title="Cancel" onClick={this.toggleAssignPBHPropertyForm.bind(this, false)} className="btn-sm">Cancel</Button>
							</div>
						</div>
					</form>
				</ModalBody>
			</React.Fragment>
		);
	}

	renderPBHMetaData = (detailedInfo) => {

		return (
			<React.Fragment>
				{detailedInfo.isUnAssigned && this.props.showMore ? 
					<React.Fragment>
						<tr>
							<td><span className="small-title-text">Focus Area </span></td>
							<td rowSpan="4" style={{verticalAlign: "middle",textAlign: "center"}}>
								<button type="button" className="btn btn-primary" onClick={this.toggleAssignPBHPropertyForm.bind(this, true)}>Assign</button>
							</td>
						</tr>
						<tr>
							<td><span className="small-title-text">Project </span></td>
						</tr>
						<tr>
							<td><span className="small-title-text">Project Abbreviation </span></td>
						</tr>
						<tr>
							<td><span className="small-title-text">Key Activity </span></td>
						</tr>
					</React.Fragment>
					:
					<React.Fragment>
						<tr>
							<td><span className="small-title-text">Focus Area </span></td>
							<td>{detailedInfo.focusAreaTitle}</td>
						</tr>
						<tr>
							<td><span className="small-title-text">Project </span></td>
							<td><a href={'/projects/edit/' + detailedInfo.outcomeID} target='_blank' style={SharedStyles.titleLink}>{detailedInfo.outcomeTitle}</a></td>
						</tr>
						<tr>
							<td><span className="small-title-text">Project Abbreviation </span></td>
							<td>{detailedInfo.outcomeAbbr}</td>
						</tr>
						<tr>
							<td><span className="small-title-text">Key Activity </span></td>
							<td><a href={'/key-activities/edit/' + detailedInfo.keyActivityID + '?outcomeID=' + detailedInfo.outcomeID} target='_blank' style={SharedStyles.titleLink}>{detailedInfo.keyActivityTitle}</a></td>
						</tr>
					</React.Fragment>
				}
			</React.Fragment>
		)
	}

	render () {

		const { showMore } = this.props;
		const { detailedInfo, displaySubSection = '', showPBHPropertiesAssignmentForm = false, reloadOutcomesData = false } = this.state;

		if (!detailedInfo) return null;

		let title, startTime, endTime, day = '';
		let isNonWeeklyTB = detailedInfo && detailedInfo.regularity == 'non-weekly' ? true : false;
		let regularity = 'Weekly';

		if (isNonWeeklyTB) {
			if (detailedInfo.interval == 'every_x_years' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Years`;
			}
			else if (detailedInfo.interval == 'every_x_weeks' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Weeks`;
			}
			else if (detailedInfo.interval == 'every_x_months' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Months`;
			}
			else {
				regularity = _.startCase(_.camelCase(detailedInfo.interval));
			};
		}
		else if (detailedInfo.regularity === 'daily') {
			if (detailedInfo.interval == 'every_x_days' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Days`;
			}
			else {
				regularity = _.startCase(_.camelCase(detailedInfo.interval));
			};
			isNonWeeklyTB = true;
		};

		title = detailedInfo.timeBlockTitle;
		if (detailedInfo.outcomeAbbr && detailedInfo.keyActivityTitle) {
			title += ' (' + detailedInfo.outcomeAbbr + ': ' + detailedInfo.keyActivityTitle + ')';
		};


		if (showMore && detailedInfo) {
			day = moment(detailedInfo.start).format('dddd'); // get day
			startTime = moment(detailedInfo.start).format('LT'); // get time
			endTime = moment(detailedInfo.end).format('LT'); // get time
		};

		return (
			<div>
				<Modal
					size={'lg'}
					isOpen={this.props.showDetailedInfoModal} 
					toggle={()=>this.props.toggleDetailedInfoModal(false, null, reloadOutcomesData)}>

					{
						displaySubSection == "ADD_NEW_OUTCOME" && 
							<AddOutcome 
								onClose={this.toggleNewOutcomeKAModal.bind(this)}
								onCancel={this.closeSubSection}
								onSuccussSubmit={this.handleNewOutcomeSubmit}
								focusAreasOptions={this.state.focusAreasOptions}
								{...this.props}
								{...this.state} />
					}

					{
						displaySubSection == "ADD_NEW_KA" &&
							<AddKeyActivity 
								onClose={this.toggleNewOutcomeKAModal.bind(this)}
								onCancel={this.closeSubSection}
								onSuccussSubmit={this.handleNewKeyActivitySubmit}
								{...this.props}
								{...this.state} />
					}

					{
						!displaySubSection && showPBHPropertiesAssignmentForm && this.renderPBHPropertyForm()
					}

					{	!detailedInfo || displaySubSection || showPBHPropertiesAssignmentForm ? null :
						<React.Fragment>
							<ModalHeader>{title}</ModalHeader>
							<ModalBody>
								<table className="table-curved">
									<thead>
										<tr>
											<th className="w-50">Item</th>
											<th className="w-50">Value</th>
										</tr>
									</thead>
									<tbody>
										{this.renderPBHMetaData(detailedInfo)}
										<tr>
											<td className="small-title-text">Time Block</td>
											<td>{detailedInfo.timeBlockTitle}</td>
										</tr>
										<tr>
											<td className="small-title-text">Duration</td>
											<td>{Util.convertTimeToReadableText(detailedInfo.durationInMinutes)}</td>
										</tr>
										<tr>
											<td className="small-title-text">Regularity</td>
											<td>{regularity}</td>
										</tr>
										{
											!showMore? null :
											<React.Fragment>
												{this.renderDayColumn(day)}
												<tr>
													<td className="small-title-text">Start Time</td>
													<td>{startTime}</td>
												</tr>
												<tr>
													<td className="small-title-text">End Time</td>
													<td>{endTime}</td>
												</tr>
											</React.Fragment>
										}
									</tbody>
								</table>
								
								{ showMore ? this.renderSchedulingSection(detailedInfo) : null }

								<div className="mt-4 text-right">
									{ !showMore || (isNonWeeklyTB && !detailedInfo.startDate) ? null :
										<Button color="danger" title="Delete" onClick={this.toggleDeleteModal.bind(this)} className="btn-sm mr-2">Remove From Plan</Button>
									}
									{showMore ? <Button title="Update Event" onClick={this.onEventUpdate.bind(this)} className="btn btn-primary btn-sm mr-2">Save Changes</Button> : null}
									<Button color="secondary" title="Cancel" onClick={()=>this.props.toggleDetailedInfoModal(false, null, reloadOutcomesData)} className="btn-sm">Close</Button>
								</div>

								{this.renderEventDeleteModal()}
							</ModalBody>

						</React.Fragment>
					}
					</Modal>
			</div>
		)
	}
}

DetailedInfoPopup.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	DetailedInfoPopup
);