import axios from "axios";
import { toast } from "react-toastify";
import {
	LOADING,
	RESET_TIME_BLOCK,
	GET_TIME_BLOCK,
	GET_TIME_BLOCKS,
	DELETE_TIME_BLOCK,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetTimeBlockData = () => dispatch => {
	dispatch({
		type: RESET_TIME_BLOCK,
	})
}

// Get TimeBlocks
export const getUserTimeBlocks = (response) => dispatch => {

	dispatch({
		type: GET_TIME_BLOCKS,
		payload: response.data.data
	})
};

// Get a TimeBlock
export const getTimeBlockByID = (timeBlockID) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getTimeBlockByID;

	var postObj = {
		recordID: timeBlockID,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_TIME_BLOCK,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};