import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ValuesReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			results: this.props.results,
			top3Values: [],
			bottom3Values: []
		}
		window.scrollTo(0, 0);
	}

	componentDidMount() {
    
        var {top3Values, bottom3Values} = this.state;
        var valueResults = this.state.results && this.state.results.valueScores ? this.state.results.valueScores : [];
        
        // Sort the values based on score from low to high
        var sortedValueObj = _.sortBy(valueResults, 'valueScore');
        bottom3Values = sortedValueObj.splice(0, 3);

        // Sort the values based on score from high to low
        sortedValueObj = sortedValueObj.reverse();
        top3Values = sortedValueObj.splice(0, 3);

        this.setState({top3Values, bottom3Values});
    }

	/*
     - render individual values
     - values - JSON object contains value description
     - index - position in array to render background color
     - category - wheelhouse / aspirational / keystone
    */

	renderValues(values, index, category = null) {

		var sectionStyling = null;
		var imageSource = null;

		// change background as per the position

		if (index == 0) {
			sectionStyling = { backgroundColor: '#fdc057', borderColor: '#fdc057'};
		}
		else if (index == 1) {
			sectionStyling = { backgroundColor: '#acd076', borderColor: '#acd076'};
		}
		else if (index == 2) {
			sectionStyling = { backgroundColor: '#92d6e1', borderColor: '#92d6e1'};
		}

		// render image as per the category

		if (category == 'Wheelhouse') {
			imageSource = '/images/Wheelhouse_value_icon.png';
		}
		else if (category == 'Aspirational') {
			imageSource = '/images/Aspirational_value_icon.png';
		}
		else if (category == 'Keystone') {
			imageSource = '/images/Keystone_value_icon.png';
		}

		return (

			<div key={index} style={{...styles.valueSectionContainer, ...sectionStyling}}>

				{imageSource ?
					<img src={imageSource} style={styles.valueStyleIcon} />
				: null}

				{category ?
					<div style={styles.descriptionText}>Core Values: {category}</div>
				: null}
				
				<div style={styles.sectionValueNameText}>{values.valueName}</div>

				<div style={styles.descriptionText}>{values.definition}</div>

			</div>
		)
	}

	render() {

		return (
			<div>
				<div className="text-center">
					<p><img src='/images/Value_Icon.png' width={100}></img></p>
					<p style={{color: "#92d6e1", fontSize:20}} className="font-bold">Core Values</p>
				</div>

				<p className="text-blog">In this section, we are going to go through the Core Values that drive you. But before that, you may be asking: what exactly do you mean by Core Values? We’re glad you asked!</p>

				<div style={styles.valueIntoConatiner}>
					Our Values are the lens through which we experience our lives. As is most often the case, this happens unconsciously, based on years of previous experiences and a multitude of environmental and personality factors. Our interpretation - or judgement - of what's good, bad, right, and wrong is heavily influenced by a set of values that we are sensitive to.
				</div>

				<p className="text-blog">Now you understand what they are, lets take a look at your Core Values.</p>

				<h6 className="mt-3"> Your top 3 Values are:</h6>

				{this.state.top3Values.map((values, ind) => {
					return this.renderValues(values, ind);
				})}

				<h6 className="mt-4">Your bottom 3 Values are:</h6>

				{this.state.bottom3Values.map((values, ind) => {
					return this.renderValues(values, ind);
				})}

				<p style={{marginTop:15}} className="text-blog">So there they are - the Core Values that drive your life. The practical use of these Core Values can be seen in the context of relationships and the conflicts that we experience as a part of these relationships from time to time. Conflicts usually occur when someone violates one or more of your Core Values. It could even be you who 'causes' the conflict by violating one of your own Core Values. Also worth noting is that the process described above usually happens subconsciously. Furthermore, without realizing it, we may set high standards for others to meet even though we may ourselves not excel in our own Core Values. This can lead to scenarios where someone ticks us off for no particular reason. Thus, the more mindful we can be of our own Core Values, the more conscious we can be in how we respond to others’ words and actions as well as other external events.</p>

			</div>
		)
	}
}

ValuesReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

const styles = {

	valueSectionContainer: {
        borderRadius:10,
        borderWidth:1,
        marginTop:20,
        padding: 15,
	},
	valueIntoConatiner: {
        //backgroundImage: `url('/images/Value_Background.png')`,
		backgroundColor: '#92d6e1',
        color: '#fff',
        backgroundPosition: 'center',
        padding: 10,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: 10,
        fontFamily: 'Pangram-Light',
        fontSize: 16,
        lineHeight: 1.9,
        marginTop: 15,
        marginBottom: 15
    },
	valueStyleIcon: {
        resizeMode: 'contain',
        width: 50,
        height: 50,
    },
	descriptionText: {
        fontFamily:'Pangram-Light',
        fontSize:16,
        paddingTop:10,
        color: '#ffffff',
		lineHeight: 1.75
    },
	sectionValueNameText: {
        fontFamily:'Pangram-Bold',
        fontSize:18,
        color: '#ffffff'
    },
}

export default connect(mapStateToProps, { setLoading })(
	ValuesReport
);
