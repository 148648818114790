import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from "react-router-dom";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import ProductivityOutcomeMetrics from "./ProductivityOutcomeMetrics";
import ProjectsList from "./ProjectsList";

// For Unit Testing
import ProductivityOutcomesResponse from "../../assets/api_responses/productivity-planner/ProductivityOutcomesResponse.json";
import SystemDefaultProjects from "../../assets/api_responses/productivity-planner/SystemDefaultProjects.json";

import { history } from '../../utils/history';
import Util from '../../utils/Util';
var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class ProductivityOutcomeForm extends Component {

	constructor(props) {
		super(props);

		const { productivityOutcomeId } = this.props.match.params;

		let isEdit = false;

		if (productivityOutcomeId) {
			isEdit = true;
		}

		this.state = {
			formErrors: {
				outcome: null,
				description: null,
			},
			buttonDisabled: false,
			isEdit: isEdit,
			productivityOutcome: {
				outcome: "",
				description: "",
				projects: [],
				metricsAndTargets: [],
			},
			productivityOutcomeId: isEdit ? productivityOutcomeId : null,
			systemDefaultProjects: [],
			notesEditorState: EditorState.createEmpty(),
			unsavedChanges: false
		};
	}

	async componentDidMount() {

		const { isEdit } = this.state;

		if (isEdit) {

			this.props.setLoading(true);

			await Promise.all([
				this.getOutcomeDetails(),
				this.getSystemDefaultProjects(),
			]);

			this.props.setLoading(false);
		} else {

			this.props.setLoading(true);
			this.getSystemDefaultProjects();
			this.props.setLoading(false);
		}

		this.setStickyToolBar();
	}

	getOutcomeDetails() {

		const { productivityOutcomeId } = this.state;

		var postObj = {
			recordID: productivityOutcomeId,
		};

		this.setState({
			buttonDisabled: true,
		});

		API_Services.httpPOST(UrlConstants.URLS.getProductivityOutcomeDetailsByID, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					if (!Util.isEmpty(response.data.data)) {

						const { notes } = response.data.data;

						this.setState({
							productivityOutcome: response.data.data,
							notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
						});
					} else {
						this.setApiResponse("Something went wrong!", true);
					}
				} else {
					this.setApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	getSystemDefaultProjects() {

		API_Services.httpGET(UrlConstants.URLS.getUserTemplates, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				let systemDefaultProjects = [];

				response.data.data.forEach(eachTemplate => {

					if (eachTemplate.templateType == "project") {
						systemDefaultProjects.push(eachTemplate);
					}
				});

				this.setState({
					systemDefaultProjects: systemDefaultProjects,
				});
			} else {
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	setStickyToolBar() {

		const header = document.getElementById("productivity-outcomes-toolbar");

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { productivityOutcome, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...productivityOutcome,
			[name]: value,
		};

		this.setState({
			productivityOutcome: formObj,
			unsavedChanges: true
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "outcome":
				if (!value) errorMsg = "Please enter Outcome";
				break;

			case "description":
				// if (!value) errorMsg = "Please enter Description";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	handleAddOutcomeSubmit(formCopy, gotoParent) {

		// console.log('productivityOutcome..', this.state.productivityOutcome);
		// console.log('formCopy..', formCopy);

		this.setState({
			buttonDisabled: true,
		});

		let postObj = {
			outcomeObj: formCopy,
		}

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.createOutcomeDefinition, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {
				if (response.data.status && response.data.data) {
					/* Take the user to Edit form as soon as they created their productivity outcome. */
					setTimeout(() => {
						history.push(`/productivity-planner/outcomes/edit/${response.data.data.id}`);
						window.location.reload();
					}, 500);
					return; 
				}; 
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	handleEditOutcomeSubmit(formCopy, gotoParent) {

		// console.log('productivityOutcome..', this.state.productivityOutcome);
		// console.log('formCopy..', formCopy);

		this.setState({
			buttonDisabled: true,
		});

		let postObj = {
			outcomeObj: formCopy,
			recordID: formCopy.id,
		}

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateOutcomeDefinition, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status && response.data.data) {

					this.setState({
						unsavedChanges: false
					},
					() => {
						if (gotoParent) {
							history.push('/productivity-planner/outcomes');
						}
					});	
				};
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});

	}

	// Handler for outcome submit
	handleOutcomeSubmit(gotoParent, e) {
		
		if (e) e.preventDefault();

		const { productivityOutcome, formErrors, notesEditorState, isEdit } = this.state;
		const errorObj = this.validateForm(productivityOutcome, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...productivityOutcome };

			// Get only value from select object
			// _.forOwn(formCopy, function (value, key) {

			// 	if (Array.isArray(value)) {

			// 	} else if (_.isObject(value)) {
			// 		formCopy[key] = value.value;
			// 	}
			// });

			formCopy['notes'] = notesContent;

			if (isEdit) {
				this.handleEditOutcomeSubmit(formCopy, gotoParent);
			} else {
				this.handleAddOutcomeSubmit(formCopy, gotoParent);
			}
		}
	}

	//update ProductivityOutcome
	updateProductivityOutcome(updatedproductivityOutcome) {

		this.setState({
			productivityOutcome: updatedproductivityOutcome,
			unsavedChanges: true
		},
		() => this.handleOutcomeSubmit());
	}

	renderSummary() {

		const { productivityOutcome } = this.state;

		if (!productivityOutcome) return null;

		let outcomeMetrics = productivityOutcome.metricsAndTargets || [];
		let outcomeProjects = productivityOutcome.projects || [];
		outcomeProjects = _.filter(outcomeProjects, (item) => item.isAssigned === true && !item.isDeleted);
		let metricsSummary = [];

		let consolidatedKeyActivities = _.map(outcomeProjects, 'keyActivities');
		consolidatedKeyActivities = _.flatten(_.compact(consolidatedKeyActivities));
		consolidatedKeyActivities = _.filter(consolidatedKeyActivities, (item) => item.isAssigned === true && !item.isDeleted);
		
		let consolidatedKeyActivityMetrics = _.map(consolidatedKeyActivities, 'metricsAndTargets');
		consolidatedKeyActivityMetrics = _.flatten(_.compact(consolidatedKeyActivityMetrics));
		consolidatedKeyActivityMetrics = _.reject(consolidatedKeyActivityMetrics, {isDeleted: true}); // Don't account isDeleted items for summary computation.

		outcomeMetrics.map((metricObj, metricIndex) => {
			
			let propogatedKAMetrics = _.filter(consolidatedKeyActivityMetrics, {id: metricObj.id});
			let pastPerformanceTotal = _.sumBy(propogatedKAMetrics, (item) => item.pastPerformance ? Number(item.pastPerformance) : 0);
			let currentTargetTotal = _.sumBy(propogatedKAMetrics, (item) => item.targetValue ? Number(item.targetValue) : 0);

			metricsSummary.push(
				<tr key={metricObj.id + '_summary'} className="text-blog">
					<td className="text-center" style={{ width: 50 }}>{metricIndex + 1}</td>
					<td className="text-left" style={{ width: 500 }}>{metricObj.title}</td>
					<td className="text-center" style={{ width: 200 }}>{pastPerformanceTotal}</td>
					<td className="text-center" style={{ width: 200 }}>{currentTargetTotal}</td>
					<td className="text-center" style={{ width: 200 }}>{metricObj.targetScope ? _.capitalize(metricObj.targetScope) : '(not set)'}</td>
				</tr>
			);
		});

		let consolidatedTimeBlocks = _.map(consolidatedKeyActivities, 'timeBlocks');
		consolidatedTimeBlocks = _.flatten(_.compact(consolidatedTimeBlocks));
		consolidatedTimeBlocks = _.reject(consolidatedTimeBlocks, {isDeleted: true}); // Don't account isDeleted items for summary computation.

		let pastTBDurationTotal = _.sumBy(consolidatedTimeBlocks, (item) => {
			if (item.regularity == 'non-weekly') {
				return item.durationInMinutesPerWeekPastPerformance || 0;
			};
			return item.durationInMinutesPastPerformance && item.sessionsPerWeekPastPerformance ? Number(item.durationInMinutesPastPerformance) * item.sessionsPerWeekPastPerformance : 0;
		});
		let currentTBDurationTotal = _.sumBy(consolidatedTimeBlocks, (item) => {
			if (item.regularity == 'non-weekly') {
				return item.durationInMinutesPerWeek || 0;
			};
			return item.durationInMinutes && item.sessionsPerWeek ? Number(item.durationInMinutes) * item.sessionsPerWeek : 0;
		});

		if (metricsSummary.length == 0 && consolidatedTimeBlocks.length == 0) return null; /* Don't show summary section if both data not present. */

		return(
			<div className="form-group" style={styles.summaryWrapper}>
				<div className="mb-3"><u>Summary</u></div>
				{metricsSummary.length > 0 ?
					<div className="App">
						<header className="App-header table-responsive">
							<table className="table-curved">
								<thead>
									<tr>
										<th className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
										<th className="text-left font-weight-normal" style={{ width: 500 }}>Outcome Metric</th>
										<th className="text-center font-weight-normal" style={{ width: 200 }}>Past Performance</th>
										<th className="text-center font-weight-normal" style={{ width: 200 }}>Current Target</th>
										<th className="text-center font-weight-normal" style={{ width: 200 }}>Unit</th>
									</tr>
								</thead>
								<tbody>{metricsSummary}</tbody>
							</table>
						</header>
					</div>
				: null}
				{consolidatedTimeBlocks.length > 0 ?
					<table className="table-curved mt-4">
						<thead>
							<tr>
								<th style={{width:400}}></th>
								<th className="text-center font-weight-normal" style={{width:220}}>Past Perfomance</th>
								<th className="text-center font-weight-normal" style={{width:220}}>Current Target</th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td>
									<div>Total Hours/Week</div>
									<div><small className="text-blog">(across all the above projects)</small></div>
								</td>
								<td className="text-center text-blog">{Util.convertTimeToReadableText(pastTBDurationTotal)}</td>
								<td className="text-center text-blog">{Util.convertTimeToReadableText(currentTBDurationTotal)}</td>
							</tr>
						</tbody>
					</table>
				: null}
			</div>
		);
	}

	renderForm() {

		const { formErrors, notesEditorState, isEdit, unsavedChanges, productivityOutcomeId, productivityOutcome, systemDefaultProjects } = this.state;

		return (
			<div>
				<form onSubmit={(e) => e.preventDefault()}>

					<div id={'productivity-outcomes-toolbar'} className="text-right mb-1">
						{isEdit ?
							<button type="submit" onClick={this.handleOutcomeSubmit.bind(this, false)} className="btn btn-primary btn-sm" disabled={unsavedChanges ? false : true}>
								{unsavedChanges ? 'Save' : 'Saved'} &nbsp;
								<i className="fa fa-refresh" aria-hidden="true"></i>
							</button> :
							<button type="button" onClick={this.handleOutcomeSubmit.bind(this, false)} className="btn btn-primary btn-sm">Create</button>
						}
						<Link to="/productivity-planner/outcomes/" className="btn btn-primary btn-sm ml-4">Outcomes Home</Link>
					</div>

					<div>
						<div className="form-group">
							<label>Outcome <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control text-blog" name="outcome" value={productivityOutcome['outcome']} />
								<p className="text-danger">{formErrors['outcome'] &&
									<span className="err">{formErrors['outcome']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<label>Description</label>
							<div>
								<TextareaAutosize
									onChange={this.handleInputField.bind(this)}
									className="form-control text-blog"
									minRows={2}
									maxRows={10}
									name="description"
									value={productivityOutcome['description']}>
								</TextareaAutosize>
								<p className="text-danger">{formErrors['description'] &&
									<span className="err">{formErrors['description']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div>
								<ProductivityOutcomeMetrics
									isEdit={isEdit}
									updateProductivityOutcome={this.updateProductivityOutcome.bind(this)}
									productivityOutcome={productivityOutcome}
									productivityOutcomeId={productivityOutcomeId} />
							</div>
						</div>
						<div className="form-group">
							<div>
								<ProjectsList
									isEdit={isEdit}
									updateProductivityOutcome={this.updateProductivityOutcome.bind(this)}
									productivityOutcome={productivityOutcome}
									productivityOutcomeId={productivityOutcomeId}
									systemDefaultProjects={systemDefaultProjects}
								/>
							</div>
						</div>
						{this.renderSummary()}
						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
											unsavedChanges: true
										})
									}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}

	renderBreadcrumb() {

		const { isEdit, productivityOutcomeId } = this.state;

		if (isEdit) {

			return (
				<div className="bread-crumb">
					<a href="/productivity-planner/outcomes/">Outcomes</a> &nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<a href="#">Edit</a>
				</div>
			)

		} else {

			return (
				<div className="bread-crumb">
					<a href="/productivity-planner/outcomes/">Outcomes</a> &nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<a href="#">Add</a>
				</div>
			)
		}
	}

	render() {

		return (
			<div className="page-content container child-container">
				{this.renderBreadcrumb()}
				<div className="section-container">
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								{this.renderForm()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ProductivityOutcomeForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityOutcomeForm
);

const styles = {
	
	summaryWrapper: {
		//backgroundColor: '#f6f6f6',
		border: '2px solid #f79569',
		borderRadius: 8,
		padding: '15px',
		marginBottom: '30px !important'
	},
}
