import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';

import { updateFocusAreasRanks, removeFocusArea } from "../../actions/focusAreaActions";
var _ = require('lodash');

class FocusAreasList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			focusarearesponses: [],
		};
	}

	componentDidMount() {

		const { focusarearesponses } = this.props.focusareas;
		
		this.setState({
			focusarearesponses: focusarearesponses
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing focus area
	handleFocusAreaDelete() {

		if (this.state.itemToRemove) {
			// this.props.removeFocusArea(this.state.itemToRemove);
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
			return toast.error("The 'Focus Area' section has been moved under 'Guiding Stars' module.");
		}
	}

	handleOnDragEnd(result) {
		if (!result.destination) return;
		return toast.error("The 'Focus Area' section has been moved under 'Guiding Stars' module.");

		const { focusarearesponses } = this.state;

		const items = Array.from(focusarearesponses);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		this.setState({
			focusarearesponses: items
		}, () => {
			this.props.updateFocusAreasRanks(items);
		})
	}

	// Renders drag and drop lists
	renderDND() {

		const { focusareaquestions, focusarearesponses } = this.props.focusareas;
		// const { focusarearesponses } = this.state;

		return (
			<div className="App">
				<header className="App-header table-responsive">
					<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
						<Droppable droppableId="focusarearesponses">
							{(provided) => (
								<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
									<thead>
										<tr>
											<th className="text-center" style={{width:50}}></th>
											<th className="text-center" style={{width:60}}>Rank</th>
											<th className="text-center" style={{width:200}}>Focus Area Title</th>
											{
												focusareaquestions.map(({ uniqueID, prompt }) => {
													return (
														<th className="text-center" style={{width:300}} key={uniqueID}>{prompt}</th>
													)
												})
											}
											<th className="text-center" style={{width:140}} colSpan={2}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{focusarearesponses && focusarearesponses.length > 0 && focusarearesponses.map(({ id, focusAreaTitle, responses }, index) => {
											return (
												<Draggable key={id} draggableId={id} index={index}>
													{(provided, snapshot) => (
														<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
															<td className="text-center" style={{width:50}}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
															<td className="text-center" style={{width:60}}>{index + 1}</td>
															<td className="text-center" style={{width:200}}>{focusAreaTitle}</td>
															{responses.map(({ id, response }, innerIndex) => {
																return (
																	<td className="text-center" style={{width:300, whiteSpace: 'pre-wrap'}} key={id}>{response}</td>
																);
															})}
															<td className="text-center" style={{width:70}}><Link to={`/focus-areas/edit/${id}`} title="Edit" className="table-action-button"><i className="fa fa-pencil color-primary" aria-hidden="true"></i></Link></td>
															<td className="text-center" style={{width:70}}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
														</tr>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</tbody>
								</table>
							)}
						</Droppable>
					</DragDropContext>
				</header>
			</div>
		);
	}

	renderFocusAreaDeleteModal() {

		var faObj = _.find(this.state.focusarearesponses, {id: this.state.itemToRemove});
		var faTitle = faObj ? faObj.focusAreaTitle : ''; 

		return(
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Focus Area</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the focus area <span className="text-warning">{faTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleFocusAreaDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					{this.renderDND()}
				</div>
				{this.renderFocusAreaDeleteModal()}
			</div>
		);
	}
}

FocusAreasList.propTypes = {
	updateFocusAreasRanks: PropTypes.func.isRequired,
	removeFocusArea: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	// focusareas: state.focusareas,
});

export default connect(mapStateToProps, { updateFocusAreasRanks, removeFocusArea })(
	FocusAreasList
);
