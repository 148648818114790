import axios from "axios";
import { toast } from "react-toastify";
import {
	LOADING,
	SUBSCRIPTIONS,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Get getUserSubscriptions
export const getUserSubscriptions = () => dispatch => {

	// dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getUserSubscriptions;

	var postObj = {
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: SUBSCRIPTIONS,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		// dispatch(setLoading(false));
	});
};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};