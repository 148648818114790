import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import AppModules from './AppModules';
import Templates from './Templates';
import ActivationCodes from './ActivationCodes';
import MigrationScripts from './MigrationScripts';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class SuperAdminDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeMenu: null
		}
	}

	componentDidMount() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.verifyAccess, (err, response) => {

			if (response && response.status) {

				this.setState({
					activeMenu: 'app-modules' // Default section
				});
			};
			 
			this.props.setLoading(false);
		});
	}

	renderMenuButtons() {
        
		const { activeMenu } = this.state;

		if (!activeMenu) return null;
		
        let activeClass = "pbh-small-btn-active";
		let defaultClass = "pbh-small-btn-default";

        return(
            <div className="text-center" style={{marginBottom: 35}}>
                <ButtonGroup className="flex-wrap">
					<Button onClick={() => this.setState({activeMenu: 'app-modules'})} className={(activeMenu == 'app-modules'? activeClass: defaultClass)} style={{fontSize:14, minWidth:140}}>App Modules</Button>
					<Button onClick={() => this.setState({activeMenu: 'templates'})} className={(activeMenu == 'templates'? activeClass: defaultClass)} style={{fontSize:14, minWidth:140}}>Templates</Button>
					<Button onClick={() => this.setState({activeMenu: 'activation-codes'})} className={(activeMenu == 'activation-codes'? activeClass: defaultClass)} style={{fontSize:14, minWidth:140}}>Activation Codes</Button>
					<Button onClick={() => this.setState({activeMenu: 'migration-scripts'})} className={(activeMenu == 'migration-scripts'? activeClass: defaultClass)} style={{fontSize:14, minWidth:140}}>Migration Scripts</Button>
                </ButtonGroup>
            </div>
        );
    }

	renderActiveMenu() {

		const { activeMenu } = this.state;

		if (!activeMenu) {
			return <div className='text-danger'>Unauthorized: You don't have required permission to view this page.</div>
		}
		else if (activeMenu == 'app-modules') {
			return <AppModules />;
		}
		else if (activeMenu == 'templates') {
			return <Templates />;
		}
		else if (activeMenu == 'activation-codes') {
			return <ActivationCodes />;
		}
		else if (activeMenu == 'migration-scripts') {
			return <MigrationScripts />;
		}
		else {
			return <div />;
		};
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/super-admin/">Super Admin</a>
				</div>
				<div className="section-container">
					<div className="form-container">
                        {this.renderMenuButtons()}
                        {this.renderActiveMenu()}
					</div>
				</div>
			</div>
		);
	}
}

SuperAdminDashboard.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	SuperAdminDashboard
);
