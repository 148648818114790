import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var moment = require('moment');
var UrlConstants = require('../../utils/UrlConstants');

class MigrationScripts extends Component {

	constructor(props) {
		super(props);
        this.state = {
            scripts: [],
            executeMigrationModal: false,
            migrationFileToRun: null,
        }
    }
    
    setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
        };
        
        this.setState({
            executeMigrationModal: false,
            migrationFileToRun: null,
        })
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

    componentDidMount() {

		this.props.setLoading(true);

        API_Services.httpGET(UrlConstants.URLS.fetchMigrationScriptsList, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setState({
                        scripts: response.data.data,
                    })
				} else {
					this.setFormApiResponse("", true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
    }

    // Show or hide execute migration confirmation modal
	toggleExecuteMigrationConfirmationModal(filename) {

		if (this.state.executeMigrationModal) {
			this.setState({
                executeMigrationModal: false,
                migrationFileToRun: null,
			});
		} else {
			this.setState({
                executeMigrationModal: true,
                migrationFileToRun: filename,
			});
		}
	}

    // Render execute migration confirmation modal
	renderExecuteMigrationConfirmationModal() {

		return (
			<div>
				<Modal isOpen={this.state.executeMigrationModal} toggle={this.toggleExecuteMigrationConfirmationModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleExecuteMigrationConfirmationModal.bind(this)}>Execute Migration </ModalHeader>
					<ModalBody>
						<p>Are you sure you want to run this migration?</p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-primary" title="Run" onClick={this.executeMigrationScript.bind(this)}>Run</button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleExecuteMigrationConfirmationModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

    executeMigrationScript() {

        let filename = this.state.migrationFileToRun;

        this.props.setLoading(true);

        let postObj = {
            filename: filename,
        };

        API_Services.httpPOST(UrlConstants.URLS.executeMigrationScripts, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

                    var newScripts = _.map(this.state.scripts, function(a) {
                        return a.filename == filename ? {...a, "status": "Processed"} : a;
                    });

                    this.setState({
                        scripts: newScripts,
                        executeMigrationModal: false,
                        migrationFileToRun: null,
                    });
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
    }

	render() {

        let scripts = this.state.scripts;
        
        if (scripts.length == 0) {
            return(
                <div className="text-info small"> No migration scripts found.</div>
            );
        };
        
        scripts = _.sortBy(scripts, (item) => moment(item.filename, 'MM_DD_YYYY').format('x'));
        scripts = scripts.reverse(); // Display the scripts in reverse chronological order.

		return (
			<div>
                <div className="App">
					<header className="App-header table-responsive">
                        <table className="table-curved">
                            <thead>
                                <tr className="row m-0">
                                    <th className="text-center col-1">#</th>
                                    <th className="text-center col-2">File</th>
                                    <th className="col-7">Description</th>
                                    <th className="text-center col-2">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scripts.map((scriptObj, index) => {
                                    return(
                                        <tr key={index} className="row m-0">
                                            <td className="text-center col-1">{scripts.length - index}</td>
                                            <td className="text-center col-2">
                                                {scriptObj.status == 'Pending' ?
                                                    <span style={SharedStyles.titleLink} onClick={this.toggleExecuteMigrationConfirmationModal.bind(this, scriptObj.filename)} className="cursor-pointer">{scriptObj.filename}</span>
                                                : 
                                                    <span>{scriptObj.filename}</span>
                                                }
                                            </td>
                                            <td className="col-7" dangerouslySetInnerHTML={{ __html: scriptObj.description}} style={{lineHeight:1.8}} />
                                            <td className="text-center col-2"><span className={scriptObj.status == 'Pending' ? "text-info": "text-success"}>{scriptObj.status}</span></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </header>
                </div>
                {this.renderExecuteMigrationConfirmationModal()}
			</div>
		);
	}
}

MigrationScripts.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	MigrationScripts
);
