import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, Zoom } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Util from '../../utils/Util';
import { history } from '../../utils/history';

import { setLoading } from "../../actions/loadingActions.js";

var UrlConstants = require('../../utils/UrlConstants');
var Globals = require('../../utils/Globals');

// delete this
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

class ResetPassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				email: "",
				passwordRecoveryToken: "",
				newPassword: "",
				confirmPassword: "",
			},
			formErrors: {
				email: null,
				passwordRecoveryToken: null,
				newPassword: null,
				confirmPassword: null,
			},
			buttonClicked: false,
			buttonDisabled: false,
			formApiResponse: {
				className: "",
				message: "",
			},
			tokenGenerated: false,
		};
	}

	// Handler for input field change event
	handleGenerateRecoveryInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			// Realtime validate only if the user click submit with invalid details
			if (this.state.buttonClicked) {
				const errorMsg = this.validateGenerateRecoveryEmailField(name, value);
				var formErrorsObj = { ...formErrors, [name]: errorMsg };
				this.setState({ formErrors: formErrorsObj });
			}
		});

	}

	// validateGenerateRecoveryEmailField
	validateGenerateRecoveryEmailField(name, value, refValue) {

		var errorMsg = null;

		const { form } = this.state;

		switch (name) {

			case "email":
				if (!value) {
					errorMsg = "Please enter Email";
				} else if (!Util.validateEmail(value)) {
					errorMsg = "Please enter a valid email address";
				}
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// validateGenerateRecoveryEmailForm
	validateGenerateRecoveryEmailForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// Handler for input field change event
	handleResetPasswordInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			// Realtime validate only if the user click submit with invalid details
			if (this.state.buttonClicked) {
				const errorMsg = this.validateResetPasswordField(name, value);
				var formErrorsObj = { ...formErrors, [name]: errorMsg };
				this.setState({ formErrors: formErrorsObj });
			}
		});

	}	

	// validateResetPasswordField
	validateResetPasswordField(name, value, refValue) {

		var errorMsg = null;

		const { form } = this.state;

		switch (name) {

			case "passwordRecoveryToken":
				if (!value) {
					errorMsg = "Please enter Token";
				}
				break;

			case "newPassword":
				if (!value) {
					errorMsg = "Please enter New Password";
				} else if (!Util.validatePassword(value)) {
					errorMsg = "Password should be at least 6 characters long and include atleast 1 alphabetic and 1 numeric character";
				}
				break;

			case "confirmPassword":
				if (!value) {
					errorMsg = "Please enter Confirm Password";
				} else if (value != form.newPassword) {
					errorMsg = "Confirm Password doesn't match";
				}
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// validateResetPasswordForm
	validateResetPasswordForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	// Handler for generateRecoveryEmail submit
	generateRecoveryEmail(e) {
		e.preventDefault();

		this.setState({
			buttonClicked: true,
		})

		const { form, formErrors } = this.state;
		const errorObj = this.validateGenerateRecoveryEmailForm(form, formErrors, this.validateGenerateRecoveryEmailField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			axios
				.post(UrlConstants.URLS.generateRecoveryEmail, form)
				.then(res => {
					if (res.data) {

						if (res.data.status) {
							this.setFormApiResponse(res.data.message, false);
							this.setState({
								tokenGenerated: true,
							})
						} else {
							this.setFormApiResponse(res.data.message, true);
						}
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				})
				.catch(err => {
					if (err && err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true);
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				})
				.finally(() => {
					this.props.setLoading(false);
					this.setState({
						buttonDisabled: false,
					});
				});
		}
	}

	// Handler for resetPassword submit
	resetPassword(e) {
		e.preventDefault();

		this.setState({
			buttonClicked: true,
		})

		const { form, formErrors } = this.state;
		const errorObj = this.validateResetPasswordForm(form, formErrors, this.validateResetPasswordField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			axios
				.post(UrlConstants.URLS.resetPassword, form)
				.then(res => {
					if (res.data) {

						if (res.data.status) {
							this.setFormApiResponse(res.data.message, false);
							toast.success('Password Reset Success');
							history.push('/login');
						} else {
							this.setFormApiResponse(res.data.message, true);
						}
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				})
				.catch(err => {
					if (err && err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true);
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				})
				.finally(() => {
					this.props.setLoading(false);
					this.setState({
						buttonDisabled: false,
					});
				});
		}
	}

	renderRecoveryEmailForm() {

		const { form, formErrors, formApiResponse } = this.state;

		return (
			<form onSubmit={this.generateRecoveryEmail.bind(this)}>
				<div className="row">
					<div className="col-sm-12 align-self-center text-center bg-white">
						<div className="row signin-heading">
							<div className="col-sm-12">
								<h4 className="text-center mb-3">Reset Password</h4>
								<p className="px-5">Enter your user account's verified email address and we will send you a password reset link.</p>
							</div>
						</div>
						<div className="form-group">
							<div>
								<input type="text" className="form-control" name="email" value={form.email} onChange={this.handleGenerateRecoveryInputField.bind(this)} placeholder="Email" />
								<p className="text-danger">{formErrors.email &&
									<span className="err">{formErrors.email}</span>}</p>
							</div>
						</div>
						<button type="submit" className="btn btn-primary mb-3" disabled={this.state.buttonDisabled}>Submit</button>
						{
							(formApiResponse.message)
								?
								<p className={formApiResponse.className + ""}><span>{formApiResponse.message}</span></p>
								: null
						}
						<div>Already have an account? <NavLink to="/login/">Login</NavLink></div>
					</div>
				</div>
			</form>
		);
	}

	renderResetPasswordForm() {

		const { form, formErrors, formApiResponse } = this.state;

		return (
			<form onSubmit={this.resetPassword.bind(this)}>
				<div className="row">
					<div className="col-sm-12 align-self-center text-center bg-white">
						<div className="row signin-heading">
							<div className="col-sm-12">
								<h4 className="text-center mb-3">Reset Password</h4>
							</div>
						</div>
						<div className="form-group">
							<div>
								<input type="text" className="form-control" name="email" value={form.email} onChange={this.handleResetPasswordInputField.bind(this)} placeholder="Email" />
								<p className="text-danger">{formErrors.email &&
									<span className="err">{formErrors.email}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div>
								<input type="text" className="form-control" name="passwordRecoveryToken" value={form.passwordRecoveryToken} onChange={this.handleResetPasswordInputField.bind(this)} placeholder="Token" />
								<p className="text-danger">{formErrors.passwordRecoveryToken &&
									<span className="err">{formErrors.passwordRecoveryToken}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div>
								<input type="password" className="form-control" name="newPassword" value={form.newPassword} onChange={this.handleResetPasswordInputField.bind(this)} placeholder="New Password" />
								<p className="text-danger">{formErrors.newPassword &&
									<span className="err">{formErrors.newPassword}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div>
								<input type="password" className="form-control" name="confirmPassword" value={form.confirmPassword} onChange={this.handleResetPasswordInputField.bind(this)} placeholder="Confirm Password" />
								<p className="text-danger">{formErrors.confirmPassword &&
									<span className="err">{formErrors.confirmPassword}</span>}</p>
							</div>
						</div>
						<button type="submit" className="btn btn-primary mb-3" disabled={this.state.buttonDisabled}>Submit</button>
						{
							(formApiResponse.message)
								?
								<p className={formApiResponse.className + ""}><span>{formApiResponse.message}</span></p>
								: null
						}
						<div>Already have an account? <NavLink to="/login/">Login</NavLink></div>
					</div>
				</div>
			</form>
		);
	}

	render() {

		const { tokenGenerated } = this.state;

		return (
			<div className="page-content">
				<div className="container">
					<div className="login-form-section">
						{
							tokenGenerated
							? this.renderResetPasswordForm()
							: this.renderRecoveryEmailForm()
						}
					</div>
				</div>
			</div>
		);
	}
}

ResetPassword.propTypes = {
	auth: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { setLoading })(
	ResetPassword
);
