import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import Util from '../../utils/Util';
import { updateOutcomesRanks, removeOutcome } from "../../actions/outcomeActions";
var _ = require('lodash');

class OutcomesList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			outcomes: [],
		};
	}

	componentDidMount() {

		const { outcomes } = this.props.outcomes;

		this.setState({
			outcomes: outcomes
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing outcome
	handleOutcomeDelete() {

		if (this.state.itemToRemove) {
			this.props.removeOutcome(this.state.itemToRemove);
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		}
	}

	handleOnDragEnd(result) {
		if (!result.destination) return;

		const { outcomes } = this.props.outcomes;

		const items = Array.from(outcomes);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		var itemsCopy = [...items];

		itemsCopy.forEach((value) => {
			if (value.focusArea && value.focusArea.id) {
				value.focusArea = value.focusArea.id
			}
		});

		this.setState({
			outcomes: items
		}, () => {
			this.props.updateOutcomesRanks(itemsCopy);
		})
	}

	// Renders drag and drop lists
	renderDND() {

		const { outcomes } = this.props.outcomes;
		// const { outcomes } = this.state;

		return (
			<div className="App">
				<header className="App-header table-responsive">
					<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
						<Droppable droppableId="outcomes">
							{(provided) => (
								<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
									<thead>
										<tr>
											<th className="text-center" style={{ width: 50 }}></th>
											<th className="text-center" style={{ width: 50 }}>#</th>
											<th className="text-center" style={{ width: 200 }}>Focus Area</th>
											<th className="text-center" style={{ width: 200 }}>Title</th>
											<th className="text-center" style={{ width: 200 }}>Outcome</th>
											<th className="text-center" style={{ width: 200 }}>Success Definition</th>
											<th className="text-center" style={{ width: 50 }} colSpan={2}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{outcomes.map(({ id, focusArea, outcomeTitle, outcome, successDefinition, productivityOutcome }, index) => {
											return (
												<Draggable key={id} draggableId={id} index={index}>
													{(provided, snapshot) => (
														<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
															<td className="text-center" style={{ width: 50 }}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
															<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
															<td className="text-center" style={{ width: 200 }}>{Util.focusAreaTitleWithTimeScaleSuffix(focusArea)}</td>
															<td className="text-center" style={{ width: 200 }}>{outcomeTitle}</td>
															<td className="text-center" style={{ width: 200 }}>{outcome}</td>
															<td className="text-center" style={{ width: 200 }}>{successDefinition ? successDefinition : '-'}</td>
															{
																productivityOutcome
																	?
																		<td className="text-center" style={{ width: 50 }}><Link to={`/productivity-planner/outcomes/edit/${productivityOutcome}`} title="Edit" className="table-action-button"><i className="fa fa-pencil color-primary" aria-hidden="true"></i></Link></td>
																	:
																		<td className="text-center" style={{ width: 50 }}><Link to={`/projects/edit/${id}`} title="Edit" className="table-action-button"><i className="fa fa-pencil color-primary" aria-hidden="true"></i></Link></td>
															}
															{
																productivityOutcome
																	?
																		<td className="text-center" style={{ width: 50 }}><div className="small">N/A</div></td>
																	:
																		<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
															}
														</tr>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</tbody>
								</table>
							)}
						</Droppable>
					</DragDropContext>
				</header>
			</div>
		);
	}

	renderOutcomeDeleteModal() {

		var faObj = _.find(this.state.outcomes, { id: this.state.itemToRemove });
		var faTitle = faObj ? faObj.outcomeTitle : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Project</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the project <span className="text-warning">{faTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleOutcomeDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					{this.renderDND()}
				</div>
				{this.renderOutcomeDeleteModal()}
			</div>
		);
	}
}

OutcomesList.propTypes = {
	updateOutcomesRanks: PropTypes.func.isRequired,
	removeOutcome: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { updateOutcomesRanks, removeOutcome })(
	OutcomesList
);
