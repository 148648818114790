import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

var _ = require('lodash');

class GrowthAreas extends Component {

	render() {

        let growthAreas = this.props.growthAreas;
        let productivityGoals = this.props.productivityGoals || [];
        
        if (!growthAreas || growthAreas.length == 0) return null;

        return(
            <div>
                <div className="container-with-padding">
                    <p className="text-blog">Now that you have a good pulse on the various dimensions pertaining to your productivity and the symptoms to watch out for, let’s shift our attention to the more constructive part of this process, namely how you might go about improving your productivity.</p>
                    <p className="text-blog">By focusing on the following growth areas, you can improve your productivity dramatically and bring about a radical transformation in your life.</p>
                </div>
                <div className="growth-areas-report-section my-3 pb-1">
                    <div className="container-with-padding">
                        {growthAreas.map((reportObj, index) => {
                            
                            let productivityGoalObj = _.find(productivityGoals, {uniqueID: parseInt(reportObj.productivityGoal)}) || {};
                            let severityLevel = reportObj.score <= 3 ? 'Critical' : 'Medium';

                            return(
                                <div key={'growth_areas_'+index} style={severityLevel == 'Critical' ? styles.highSeverityContainer : styles.mediumSeverityContainer}>
                                    <div className="text-blog mb-2">
                                        <span className="font-bold">{index == 0 ? 'Primary: ' : 'Secondary: '}</span>
                                        <span className="font-bold">{reportObj.title} </span>
                                        {/* <span style={{ fontFamily: 'Pangram-Regular' }}>({severityLevel})</span> */}
                                    </div>
                                    {productivityGoalObj.title ?
                                        <p className="text-blog" style={{fontSize: 15}}>Productivity Goal: <span className="font-bold">{productivityGoalObj.title}</span></p>
                                    : null}
                                    <p className="text-blog mb-1">{reportObj.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
	}
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(
	GrowthAreas
);

const styles = {

    highSeverityContainer: {
        backgroundColor: '#fff',
        color: '#737373',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        lineHeight: 1.75
    },

    mediumSeverityContainer: {
        backgroundColor: '#fff',
        color: '#737373',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        lineHeight: 1.75
    }
};