import {
	GET_CURRENT_SPRINT_QUESTIONS,
	GET_CURRENT_SPRINT,
} from "./types";

// Get Current Sprint Questions
export const getCurrentSprintQuestions = (response) => dispatch => {

	dispatch({
		type: GET_CURRENT_SPRINT_QUESTIONS,
		payload: response.data.data
	})
};

// Get a Current Sprint
export const getCurrentSprintForTheUser = (response) => dispatch => {

	dispatch({
		type: GET_CURRENT_SPRINT,
		payload: response.data.data
	})
};