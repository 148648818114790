import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EmoStateReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			results: this.props.results,
		}
		window.scrollTo(0, 0);
	}

	/*
        - render individual states
        - states - JSON object contains states description
        - index - position in array to render background color
    */
	renderStates(states, index) {

		var sectionStyling = null;
		var imageSource = null;
		var category = states.energyState != 'Balanced' ? "(" + states.energyState + ")" : "";

		// change background as per the position

		if (index == 0) {
			sectionStyling = { backgroundColor: '#f9a576', borderColor: '#f9a576' };
		}
		else if (index == 1) {
			sectionStyling = { backgroundColor: '#92d6e1', borderColor: '#92d6e1' };
		}
		else if (index == 2) {
			sectionStyling = { backgroundColor: '#fdc057', borderColor: '#fdc057' };
		}

		// render image as per energy state

		if (states.energyState == 'Balanced') {
			imageSource = '/images/Balanced_State_Transparent.png';
		}
		else if (states.energyState == 'Inert') {
			imageSource = '/images/Inert_State_Transparent.png';
		}
		else if (states.energyState == 'Hyper') {
			imageSource = '/images/Hyper_State_Transparent.png';
		}

		return (

			<div
				key={index}
				style={{ ...styles.stateSectionContainer, ...sectionStyling }}>

				<img src={imageSource} width={50}></img>

				<p style={styles.sectionStateNameText}>{states.stateName} {category}</p>

				<p style={styles.descriptionText}>{states.stateDefinition}</p>

			</div>
		)
	}

	renderBalancedEmostates() {

		if (this.state.results && this.state.results.emostateResponses && this.state.results.emostateResponses.length == 6) {

			var emoStates = [];
			var balancedState = this.state.results.energyProfile.filter(energyState => energyState.state == 'Balanced');

			this.state.results.emostateResponses.slice(0, 3).map((emostate, index) => {
				emoStates.push(this.renderStates(emostate, index));
			});

			return (
				<div>

					<p style={styles.subHeaderText}>Your top Balanced States are: </p>

					{/* As of July 2020, the EnergyState scores will be computed on a 10pt scale (opposed to percentiles) */}

					{this.state.results.assessmentVersion && this.state.results.assessmentVersion < 4 ?
						<p style={[styles.sectionText, { marginBottom: 10 }]}>(representing {balancedState[0].average}% of your Energy State)</p>
						: null}

					<p className="text-blog">First, let's take a look at your Top Balanced EmoStates. As mentioned before, these are the states in which we operate best.</p>

					{emoStates}
				</div>
			)
		}
	}

	renderFragileAndHyperEmostates() {

		if (this.state.results && this.state.results.emostateResponses && this.state.results.emostateResponses.length == 6) {

			var emoStates = [];
			var inertState = this.state.results.energyProfile.filter(energyState => energyState.state == 'Inert');
			var hyperState = this.state.results.energyProfile.filter(energyState => energyState.state == 'Hyper');

			this.state.results.emostateResponses.slice(3, 6).map((emoState, index) => {
				emoStates.push(this.renderStates(emoState, index));
			});

			return (

				<div>

					<p className="text-blog mt-3">Next, we're going to take a look at some of the Inert and Hyper States in which you scored highly, as these are the areas we will target to improve. Don't worry - we all have areas in our life in which we can improve, so to have these states is completely normal. The great news is, by isolating the different States, we are able to improve them! Let's take a look...</p>

					<p style={styles.subHeaderText}>Your Inert and/or Hyper States that we have isolated are:</p>

					{/* As of July 2020, the EnergyState scores will be computed on a 10pt scale (opposed to percentiles) */}

					{this.state.results.assessmentVersion && this.state.results.assessmentVersion < 4 ?
						<p style={[styles.sectionText, { marginBottom: 10 }]}>(representing {parseFloat(inertState[0].average) + parseFloat(hyperState[0].average)}% of your Energy State)</p>
						: null}

					{emoStates}

				</div>
			)
		}
	}

	/* render Balanced, Inert, Hyper Emostates definition */
	renderEmoStates() {

		return (

			<div className="d-flex justify-content-between">
				<div style={styles.stateContainerView}>
					<div><img src='/images/Inert_State.png' width={50}></img></div>
					<p style={styles.introText}><div style={styles.iconCaption}>Inert </div>When we are weakest, easiest to fail</p>
				</div>
				<div style={styles.stateContainerView}>
					<div><img src='/images/Balanced_State.png' width={60}></img></div>
					<p style={styles.introText}><div style={styles.iconCaption}>Balanced </div>Where we operate best</p>
				</div>
				<div style={styles.stateContainerView}>
					<div><img src='/images/Hyper_State.png' width={60}></img></div>
					<p style={styles.introText}><div style={styles.iconCaption}>Hyper </div>When we allow strong emotions to cloud our judgement</p>
				</div>
			</div>
		)
	}

	render() {

		return (
			<div>
				<div className="text-center">
					<p><img src='/images/EmoState_Icon.png' width={100}></img></p>
					<p style={{color: "#f9a576", fontSize:20}} className="font-bold">EmoState</p>
				</div>

				<p className="text-blog">In this section, we are going to look at your 'EmoState', or to put it simpler, the top-level emotions that drive the various traits we humans have. They are states of feeling that result in physical and psychological changes which influence our behavior. We refer to them as EmoState Traits which fall in one of the following three categories:</p>

				{this.renderEmoStates()}

				<p className="mt-4 text-blog">Picture it like a tightrope walker. On one end of the balancing pole are all of your Inert traits, while on the other end are all of your hyper traits. You know where you need to go, but the only way to move forward is to keep your balance. If you go too far either way, you are likely to stumble. Keeping everything balanced is key.</p>
				<p className="text-blog">Now on to your results. Below are the top 6 which make up your EmoState, and ultimately, effect most decisions you make in your life.</p>

				{this.renderBalancedEmostates()}
				{this.renderFragileAndHyperEmostates()}

			</div>
		)
	}
}

EmoStateReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

const styles = {

	stateContainerView: {
		backgroundColor: '#fef2ec',
		textAlign: 'center',
		margin: 12,
		marginBottom: 0,
		padding: 12,
		borderRadius: 10,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center'
	},
	iconCaption: {
		fontFamily: 'Pangram-Bold',
		color: '#f9a576',
		textAlign: 'center',
		fontSize: 17,
		paddingTop: 10
	},
	introText: {
		fontFamily: 'Pangram-Light',
		fontWeight: 'normal',
		fontSize: 16,
		color: '#9e9c9d',
		textAlign: 'center',
		marginBottom: 0,
		paddingTop: 10,
	},
	subHeaderText: {
		fontFamily: 'Pangram-Regular',
		fontSize: '18px',
	},
	stateSectionContainer: {
		borderRadius: 10,
		borderWidth: 1,
		marginTop: 15,
		padding: 15,
		textAlign: 'center',
	},
	sectionStateNameText: {
		fontFamily: 'Pangram-Bold',
		fontSize: 20,
		paddingTop: 10,
		color: '#ffffff'
	},
	descriptionText: {
		fontFamily: 'Pangram-Light',
		fontSize: 16,
		color: '#ffffff',
		lineHeight: 1.75,
		marginBottom: 5
	},
	sectionText: {
		fontFamily: 'Pangram-Regular',
		fontSize: 16,
		textAlign: 'center',
		color: '#9e9c9d',
		lineHeight: 24
	},

}

export default connect(mapStateToProps, { setLoading })(
	EmoStateReport
);
