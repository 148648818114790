import PropTypes from 'prop-types';
import React, { Component } from 'react'

import Util from '../../utils/Util';
import { DragSource } from 'react-dnd';
import BigCalendar from 'react-big-calendar';

/* drag sources */
let eventSource = {
	beginDrag(props) {
		return Object.assign({},
			{ event: props.event },
			{ anchor: 'drop' }
		)
	}
}

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
}

const propTypes = {
	connectDragSource: PropTypes.func.isRequired,
	isDragging: PropTypes.bool.isRequired,
	event: PropTypes.object.isRequired
}

class DraggableTaskEvent extends Component {

	onDoubleClick = () => {
		//this.props.onDoubleClick(false, this.props.event);
	}

	render() {

		let { connectDragSource, hasCalendarSchedules, event } = this.props;
		let EventWrapper = BigCalendar.components.eventWrapper;
		let { durationInMinutes, timeBlockTitle } = event;
		let title = '';

		if (timeBlockTitle && event.outcomeAbbr && event.keyActivityTitle) {
			title = timeBlockTitle;
			title += ' (' + event.outcomeAbbr + ': ' + event.keyActivityTitle + ')';
		}
		else if (event.title) {
			title = event.title;
		};

		if (hasCalendarSchedules) {

			if (event.taskCompletionStatus === true) {
				return (
					<button type="button" className="table-action-button" title="This task has been marked as completed and you have scheduled it already atleast once."><i className="fa fa-calendar-check-o" aria-hidden="true" style={{color:'#AECF7A', fontSize:16}}></i></button>
				);
			}
			else {
				return (
					<EventWrapper event={event}>
						{
							connectDragSource(
								<button type="button" className="table-action-button" title="Drag and Drop this icon into the calendar to create this task as an event" style={{cursor:'grab'}}><i className="fa fa-calendar-check-o" aria-hidden="true" style={{color:'#FAB95E', fontSize:16}}></i></button>
							)
						}
					</EventWrapper>
				);
			};
		}
		else {

			if (event.taskCompletionStatus === true) {
				return (
					<button type="button" className="table-action-button" title="This task has been marked as completed and it cannot be created as an event"><i className="fa fa-info-circle" aria-hidden="true" style={{color:'#AECF7A'}}></i></button>
				);
			}
			else {
				return (
					<EventWrapper event={event}>
						{
							connectDragSource(
								<button type="button" className="table-action-button" title="Drag and Drop this icon into the calendar to create this task as an event" style={{cursor:'grab'}}><i className="fa fa-calendar-plus-o color-primary" aria-hidden="true"></i></button>
							)
						}
					</EventWrapper>
				);
			};
		};
	}
}

DraggableTaskEvent.propTypes = propTypes;

export default DragSource('event', eventSource, collectSource)(DraggableTaskEvent);