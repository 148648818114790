import axios from "axios";
import { toast } from "react-toastify";
import {
	GET_ERRORS,
	CLEAR_ERRORS,
	LOADING,
	RESET_NOTE,
	GET_NOTE,
	GET_NOTES,
	DELETE_NOTE,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetnoteData = () => dispatch => {
	dispatch({
		type: RESET_NOTE,
	})
}

// Get Notes
export const getUserNotes = (response) => dispatch => {

	dispatch({
		type: GET_NOTES,
		payload: response.data.data
	})
};

// Get a Note
export const getNoteInfoByID = (noteId) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getNoteInfoByID;

	var postObj = {
		recordID: noteId,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_NOTE,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};


// Remove a Note
export const removeNote = (id) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.deleteNote;

	var postObj = {
		recordID: id,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_NOTES,
					payload: response.data.data,
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});

};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};