import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import BigCalendar, { momentLocalizer } from "../../assets/scripts/react-big-calendar";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import Util from '../../utils/Util';

import '../../assets/styles/react-big-calendar.css';
import '../../assets/styles/dndCalendarStyles.css';

moment.locale("en", { week: {
	dow: 1, // First day of week is Monday 
}});

var _ = require('lodash');
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar, {backend : false});

class CustomizedCallender extends Component {

	constructor(props) {
		super(props);
		this.state = {
			events: this.props.events,
			showAlertModal: false,
			dayLayoutAlgorithm: 'no-overlap',
		};
	
		this.moveEvent = this.moveEvent.bind(this);
	}
	
	componentDidMount() {

		this._interval = setInterval(() => {

			if (this.props.adHocTimeTrackingIsOn) {
				this.props.updateCurrentAdHocTimeTrackerEvent()
				// setTimeout(() => {
				// this.forceUpdate();
				// }, 1000);
			}

		}, 30000);

	}

	componentWillUnmount() {
		clearInterval(this._interval);
	}

	moveEvent({ event, start, end }) {

		const { allEvents: events } = this.props;

		const idx = events.indexOf(event);
		const updatedEvent = { ...event, start, end };
		updatedEvent.activity = "drag-and-drop";

		if (idx === -1) {
			/* Set event title. */
			updatedEvent.title = Util.getEventTitle(updatedEvent);
		};

		if (start.getDay() != end.getDay() && end.getHours() == 0 && end.getMinutes() == 0) {
			end.setDate(start.getDate()); // Hot fix
			end.setHours(23, 59, 0); // Hot fix
		}

		if (start.getDay() != end.getDay()) {
			this.toggleWarningPopup('TimeSlotRestriction');
			return true;
		}

		// for (let i = 0; i < events.length; i++) { // Skip If event is scheduled already in a specific time slot or If it doesnt fit into the specific time spot 
		// 	const existingEvent = events[i];
		// 	if (idx != i && ((existingEvent.start < start && start < existingEvent.end)
		// 		|| (existingEvent.start < end && end < existingEvent.end)
		// 		|| (existingEvent.start.getTime() == start.getTime())
		// 		|| (existingEvent.end.getTime() == end.getTime())
		// 		|| (existingEvent.start.getTime() > start.getTime() && existingEvent.end.getTime() < end.getTime())
		// 		)) {
		// 		this.toggleWarningPopup();
		// 		return true;
		// 	}
		// }

		updatedEvent.isEventUpdated = true; // Set true to isEventUpdated key, cause this event is newly created [OR] updated

		if (idx > -1) {

			if (events[idx].isAdHocTimeTrackerUnPlannedEvent) {

				return this.toggleWarningPopup('AdHocRestriction');
			}

			else {

				const activityLog = events[idx].activityLog || [];
				const lastActivity = _.cloneDeep(_.omit(events[idx], ['activityLog']));
				activityLog.push(lastActivity)

				updatedEvent.activityLog = activityLog;
				updatedEvent.updatedAt = new Date().getTime();

				events.splice(idx, 1, updatedEvent); // update event
			}
		}
		else {

			let coreData = Util.getUser();

			updatedEvent.eventID = coreData.userCoreProperties.userID + "-" + Date.now();
			updatedEvent.createdAt = new Date().getTime();
			events.push(updatedEvent) // new event
		}

		this.props.updateEvents(events);

	}


	selectEvent = (event) => {
		/* Show detailed info in popup */
		this.props.showDetailedInfo(true, event);
	}
	
	resizeEvent = (resizeType, { event, start, end }) => {

		/**
		 * While resizing the events(both palanned and un-planned) in calendar
		 */

		const { allEvents: events } = this.props;
		const idx = events.indexOf(event);

		if (idx > -1) {

			if (events[idx].isAdHocTimeTrackerUnPlannedEvent && events[idx].isCurrentTrackingEvent) {
				return this.toggleWarningPopup('AdHocCurrentEventRestriction');
			}

			const activityLog = events[idx].activityLog || [];
			const lastActivity = _.cloneDeep(_.omit(events[idx], ['activityLog']));
			activityLog.push(lastActivity)

			let isResizingActivity = true;

			if (start.getDay() != end.getDay()) {

				// FIX for disapearing event
				isResizingActivity = false; // Consider this as drag&drop activity
				var durationInMinutes = event.durationInMinutes;

				if (event.start.getDay() == start.getDay()) {
					start.setFullYear(end.getFullYear(), end.getMonth(), end.getDate());
					start.setHours(end.getHours(), (end.getMinutes() - parseInt(durationInMinutes)), 0);
				}
				else {
					end.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());
					end.setHours(start.getHours(), (start.getMinutes() + parseInt(durationInMinutes)), 0);
				}
			}

			if (start.getDay() != end.getDay()) {
				this.toggleWarningPopup('TimeSlotRestriction');
				return true;
			}

			const duration = moment(end).diff(moment(start), 'minutes');
			events[idx] = {...event, start, end }; // update Time
			events[idx].actualCompletionTimeInMinutes = duration;
			events[idx].updatedAt = new Date().getTime();
			events[idx].activity = isResizingActivity? "re-sized" : "drag-and-drop";
			events[idx].activityLog = activityLog;
			events[idx].isEventUpdated = true; // Set true to isEventUpdated key, cause this event is updated the duration by resizing the event

			this.props.updateEvents(events);
		}

	};

	/* Toggle Popup */

	toggleWarningPopup = (section = null) => {

		this.setState({
			showAlertModal: !this.state.showAlertModal,
			warningDisplaySection: section
		});
	}

	renderWarningMessage = () => {

		const { warningDisplaySection } = this.state;

		if (warningDisplaySection == 'AdHocRestriction') {

			// Warn, if Ad-hoc event is draged or resized
			return(
				<p>{"An Ad-Hoc Tracker Event is restricted from dragging."}</p>
			)
		}

		if (warningDisplaySection == 'AdHocCurrentEventRestriction') {

			// Warn, if current tracking Ad-hoc event is draged or resized
			return(
				<p>{"An Ad-Hoc Tracker Event is restricted from dragging and resizing"}</p>
			)
		}

		else if (warningDisplaySection == 'TimeSlotRestriction') {

			// Warn, If the event can't fit inside the same day's time slot
			return(
				<p>{"The chosen time slot will not be able to hold this event. Please consider different time slots or reduce the size of the event."}</p>
			)
		}
	}

	/* show/hide Alert Popup */

	renderWarningPopup () {
		return (
			<Modal
				isOpen={this.state.showAlertModal} 
				toggle={this.toggleWarningPopup}>
				<ModalHeader >{"Heads up!"}</ModalHeader>

				<ModalBody>
					{this.renderWarningMessage()}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" title="Cancel" onClick={this.toggleWarningPopup} className="btn-sm">OK</Button>
				</ModalFooter>
			</Modal>
		)
	}
	
	render() {
		return (
	
			<div className="tracker-section">
				<DragAndDropCalendar
					localizer={localizer}
					selectable
					resizable
					onEventResize={this.resizeEvent}
					events={this.state.events}
					onEventDrop={this.moveEvent}
					onSelectEvent={this.selectEvent}
					eventPropGetter={eventStyleGetter}
					views={['week', 'day']}
					dayLayoutAlgorithm={this.state.dayLayoutAlgorithm}
					handleSaveChanges={this.props.handleSaveChanges}
					{...this.props}
				/>
				{this.renderWarningPopup()}
			</div>
		);
	}
}

const eventStyleGetter = (event, start, end, isSelected) => {

	/* Differentiate the Un-Planned ad-Hoc Time Tracking Events */

	let isCurrentWeek = moment().isoWeek() === moment(event.start).isoWeek() ? true : false;
	let currentDate = moment();
	let otherCustomStyles = {};

	if (event.isDeletedFromThirdPartyCalendar) {
		otherCustomStyles.opacity = '0.5';
		otherCustomStyles.fontSize = '25px';
	}

	if (isCurrentWeek
		&& event.isCurrentTrackingEvent
		&& event.isAdHocTimeTrackerUnPlannedEvent
		&& !event.stopAdHocTimeTracker
		&& currentDate.isSame(moment(event.start).format(), "day")) {
			return {style: { background: '#f07f71', background: 'linear-gradient(to bottom, #ff5722, #f07f71, #f7c1a2)', borderColor: '#ff5722', ...otherCustomStyles }}; 
	}

	// else if (event.isAdHocTimeTrackerUnPlannedEvent && event.isCurrentTrackingEvent && !event.stopAdHocTimeTracker && Util.isBetween(new Date(), event.start, event.end)) {
	// 	// calculate tracking and update the TrackerUI
	// 	var startTime = moment(event.start);//now
	// 	var currentTime = moment();
	// 	var totalSpent = currentTime.diff(startTime, 'minutes');
	// 	var totalEstimate = event.durationInMinutes;
	// 	let totalSpentInPercent = Math.max(0, Math.min(100, 100 * (totalSpent / totalEstimate)));
	// 	let remainingInPercent = 100 - totalSpentInPercent;
	// 	return {style: { background: '#f07f71', background: 'linear-gradient(#f07f71 '+totalSpentInPercent+'%, #ff5722 '+ (totalSpentInPercent + 2)+'%)', borderColor: '#ff5722' }};
	// }

	else if (event.isNonPBHEvent) {
		/* Differentiate the Non-PBH events */
		return {style: { backgroundColor: '#f29569', borderColor: '#f29569', ...otherCustomStyles }}; 
	}
	else if (event.isUnplanned) {
		/* Differentiate the Un-Planned events */
		return {style: { backgroundColor: '#ff5722', borderColor: '#ff5722', ...otherCustomStyles }}; 
	}
	else if (event.regularity == 'non-weekly' || event.regularity == 'daily') {
		/* Differentiate non-weekly events [OR] daily events */
		return {style: { backgroundColor: '#fab95e', borderColor: '#fab95e', ...otherCustomStyles }}; 
	}
	return {style: otherCustomStyles}; 
};

CustomizedCallender.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setLoading })(
	CustomizedCallender
);
