import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AppModules extends Component {

	constructor(props) {
		super(props);
        this.state = {
            appModules: [],
        }
    }
    
    setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
        };
        
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

    componentDidMount() {

		this.props.setLoading(true);

        API_Services.httpGET(UrlConstants.URLS.fetchAppModules, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    this.setState({
                        appModules: response.data.data,
                    });
				}; 
			} 
            else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
    }

    handleSubscriptionUpdate(index) {

        let appModules = this.state.appModules;
        let targetModule = appModules[index];
        targetModule.accessEligibility = targetModule.accessEligibility == 'all' ? 'subscribers' : 'all';
        
        this.props.setLoading(true);

        delete targetModule.createdAt; // Don't let mongo generated fields get overridden on the update operation.
        delete targetModule.updatedAt;

        let postObj = {
            moduleToBeUpdated: targetModule
        };

        API_Services.httpPOST(UrlConstants.URLS.updateModule, postObj, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    appModules[index] = response.data.data;
                    this.setState({
                        appModules: appModules,
                    });
				}; 
			} 
            else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
    }

	render() {

        let appModules = this.state.appModules;
        
        if (appModules.length == 0) {
            return(
                <div className="text-info small"> No App Module(s) found.</div>
            );
        };
        
		return (
			<div>
                <div className="App">
					<header className="App-header table-responsive">
                        <table className="table-curved">
                            <thead>
                                <tr className="row m-0">
                                    <th className="text-center col-1">#</th>
                                    <th className="col-9">Module</th>
                                    <th className="text-center col-2">Is Premium?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appModules.map((moduleObj, index) => {
                                    return(
                                        <tr key={index} className="row m-0">
                                            <td className="text-center col-1">{index + 1}</td>
                                            <td className="col-9">{moduleObj.label}</td>
                                            <td className="text-center col-2">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" onChange={() => console.log('Modular accessibility changed!')} className="custom-control-input" checked={moduleObj.accessEligibility == 'subscribers' ? 'checked' : ''} />
                                                    <label className="custom-control-label cursor-pointer" onClick={this.handleSubscriptionUpdate.bind(this, index)}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </header>
                </div>
			</div>
		);
	}
}

AppModules.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	AppModules
);
