import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from '../../actions/loadingActions.js';
import API_Services from '../../utils/API_Services';
import CompassArchivedList from './CompassArchivedList';
import CompassTable from './CompassTable';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class Compass extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.props.setLoading(true);
		this.getIndividualCompassResponses();
    }

	// Get Compass Response - (Compass, Notes, Archived Lists)
	getIndividualCompassResponses() {

		API_Services.httpGET(UrlConstants.URLS.getIndividualCompassResponses, (err, response) => {

			if (err) {

                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			}
            else if (response.data) {

				if (response.data.status) {

					if(response.data.data) {

						let compassData = response.data.data;
						let currentCompassResponse = compassData.currentCompassResponse || {} ;

						this.setState({
							currentCompassResponse: currentCompassResponse,
							compassData: compassData,
							archivedList: compassData.archivedCompassList
						});
					}
				}
			} 
            else {
				this.setFormApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Render Current Compass Archived List
	renderArchivedList() {
		return <CompassArchivedList archivedList={this.state.archivedList} />;
	}

	// Render Current Compass Table
	renderCurrentCompass() {

		if (!this.state.compassData){ return null };

		return	(
			<CompassTable 
				data={this.state.compassData}
				stickyPos={115}
				getCompassData={this.getIndividualCompassResponses.bind(this)} />
		);
	}

	render() {
		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="#">Compass</a>
				</div>
				<div className="section-container">
					<div className="form-container">
						<div style={{marginTop:0}}>
							{this.renderCurrentCompass()}
							{this.renderArchivedList()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Compass.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	Compass
);