import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

import ThriveMeterReport from './ThriveMeterReport.js';
import EnergyStateReport from './EnergyStateReport.js';
import ValuesReport from './ValuesReport.js';
import EmoStateReport from './EmoStateReport.js';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const TYPES = {
	"thrive-meter": {
		label: "Thrive Meter",
		value: "thrive-meter",
	},
	"energy-state": {
		label: "Energy State",
		value: "energy-state",
	},
	"values": {
		label: "Values",
		value: "values",
	},
	"emo-state": {
		label: "Emo State",
		value: "emo-state",
	},
};

class IIAReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			results: null,
			recommendedTracks: null,
			showCurrentResult: false,
			currentResult: null,
			currentResultLabel: null,
			balancedScore: 0,
			inertScore: 0,
			hyperScore: 0,
			top3Values: '',
			bottom3Values: '',
			balancedStates: '',
			hyperInertStates: ''
		}
		window.scrollTo(0, 0);
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.props.setLoading(true);

		const assessmentId = this.props.match.params.assessmentId;

		let postObj = {
			assessmentId: assessmentId,
		};

		API_Services.httpPOST(UrlConstants.URLS.getInnerInsightsReport, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					this.setState({
						results: response.data.data.results,
						recommendedTracks: response.data.data.recommendedTracks,
					},
					() => {
						this.calculateEnergyStateScores();
						this.calculateEmostateResponses();
						this.calculateUserValues();
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	calculateEnergyStateScores() {

        var {balancedScore, hyperScore, inertScore} = this.state;

        if (this.state.results && this.state.results.energyProfile) {

            this.state.results.energyProfile.map((energyState) => {
            
                if (energyState.state == 'Balanced') {
                    balancedScore = energyState.average;
                }
                else if (energyState.state == 'Hyper') {
                    hyperScore = energyState.average;
                }  
                else if (energyState.state == 'Inert') {
                    inertScore = energyState.average;
                };
            });
            this.setState({balancedScore, hyperScore, inertScore});
        };
    }

	calculateEmostateResponses() {

        var {balancedStates, hyperInertStates} = this.state;

        if (this.state.results && this.state.results.emostateResponses) {

            this.state.results.emostateResponses.map((emostate) => {
            
                if (emostate.energyState == 'Balanced') {
                    balancedStates += balancedStates == '' ? emostate.stateName : ' | ' + emostate.stateName;
                } 
                else {
                    hyperInertStates += hyperInertStates == '' ? emostate.stateName : ' | ' + emostate.stateName;
                }
            });
            this.setState({balancedStates, hyperInertStates});
        };
    }

	calculateUserValues() {

        var {top3Values, bottom3Values} = this.state;

        if (this.state.results && this.state.results.valueScores) {

            // Sort the values based on score from low to high
            var sortedValueObj = _.sortBy(this.state.results.valueScores, 'valueScore');
            
            // First three values will be considered as user's weak (or) bottom values
            for(var i=0; i<3; i++) {
                bottom3Values += bottom3Values == '' ? sortedValueObj[i].valueName : ' | ' + sortedValueObj[i].valueName;
            };

            // Reverse the sortedValueObj to get values from high to low 
            sortedValueObj = sortedValueObj.reverse();

            // First three values from this reversed array will be considered as user's top values
            for(var i=0; i<3; i++) {
                top3Values += top3Values == '' ? sortedValueObj[i].valueName : ' | ' + sortedValueObj[i].valueName;
            };

            this.setState({top3Values, bottom3Values});
        };
    }

	renderThriveMeterResults() {
		return(
			<div>
				<ThriveMeterReport recommendedTracks={this.state.recommendedTracks} />
				<center className="mt-4">
					<button type="button" onClick={this.handleResultsRender.bind(this, '')} className="btn thrive-meter-btn mr-3">Back To Overview</button>
					<button type="button" onClick={this.handleResultsRender.bind(this, 'energy-state')} className="btn thrive-meter-btn">Next: Energy State</button>
				</center>
			</div>
		)
	}

	renderEnergyStateResults() {
		return(
			<div>
				<EnergyStateReport results={this.state.results} />
				<center className="mt-4">
					<button type="button" onClick={this.handleResultsRender.bind(this, '')} className="btn energy-state-btn mr-3">Back To Overview</button>
					<button type="button" onClick={this.handleResultsRender.bind(this, 'values')} className="btn energy-state-btn">Next: Values</button>
				</center>
			</div>
		)
	}

	renderValueResults() {
		return(
			<div>
				<ValuesReport results={this.state.results} />
				<center className="mt-4">
					<button type="button" onClick={this.handleResultsRender.bind(this, '')} className="btn values-btn mr-3">Back To Overview</button>
					<button type="button" onClick={this.handleResultsRender.bind(this, 'emo-state')} className="btn values-btn">Next: Emo State</button>
				</center>
			</div>
		)
	}

	renderEmoStateResults() {
		return(
			<div>
				<EmoStateReport results={this.state.results} />
				<center className="mt-4">
					{/* <button type="button" onClick={this.handleResultsRender.bind(this, '')} className="btn emo-state-btn mr-3">Back To Overview</button> */}
					<button type="button" onClick={this.handleResultsRender.bind(this, '')} className="btn emo-state-btn">Done</button>
				</center>
			</div>
		)
	}

	renderResults() {

		let { currentResult } = this.state;

		if (currentResult == 'thrive-meter') {
			return(this.renderThriveMeterResults())
		}
		else if (currentResult == 'energy-state') {
			return(this.renderEnergyStateResults())
		}
		else if (currentResult == 'values') {
			return(this.renderValueResults())
		}
		else if (currentResult == 'emo-state') {
			return(this.renderEmoStateResults())
		}
	}

	handleResultsRender(currentResult) {

		if (currentResult) {
			this.setState({
				showCurrentResult: true,
				currentResult: TYPES[currentResult]['value'],
				currentResultLabel: TYPES[currentResult]['label'],
			});
		} 
		else {
			this.setState({
				showCurrentResult: false,
				currentResult: null,
			});
		}
	}

	renderThriveMeterResultSummary() {

		let recommendedTracks = this.state.recommendedTracks || {};

		return(
			<div onClick={this.handleResultsRender.bind(this, 'thrive-meter')} className="thrive-meter-section">
				<p style={styles.sectionTitle}>Thrive Meter</p>
				<div>
					<table className="w-100">
						<tbody>
							<tr>
								<td>Core Enablers</td>
								<td><div style={styles.resultValueContainer}>{recommendedTracks.coreEnablerGroupAvg || 0} / 10</div></td>
							</tr>
							<tr>
								<td>Inner Compass</td>
								<td><div style={styles.resultValueContainer}>{recommendedTracks.innerCompassGroupAvg || 0} / 10</div></td>
							</tr>
							<tr>
								<td>Good Living</td>
								<td><div style={styles.resultValueContainer}>{recommendedTracks.goodLivingGroupAvg || 0} / 10</div></td>
							</tr>
							<tr>
								<td>Being Extraordinary</td>
								<td><div style={styles.resultValueContainer}>{recommendedTracks.beingExtraordinaryGroupAvg || 0} / 10</div></td>
							</tr>
						</tbody>
					</table>
				</div>
				<p style={styles.viewMoreText}>View More</p>
			</div>
		);
	}

	renderEnergyStateResultSummary() {

		let {balancedScore, inertScore, hyperScore} = this.state;
		
		return(
			<div onClick={this.handleResultsRender.bind(this, 'energy-state')} className="energy-state-section">
				<p style={styles.sectionTitle}>Energy State</p>
				<div className="d-flex justify-content-around">
					<div className="text-center">
						<div style={styles.subSectionTitle}>Inert</div>
						<img src='/images/Inert_State_Transparent.png' style={styles.iconStyle}></img>
						<div style={styles.resultValueContainer}>{inertScore|| 0} / 10</div>
					</div>
					<div className="text-center">
						<div style={styles.subSectionTitle}>Balanced</div>
						<img src='/images/Balanced_State_Transparent.png' style={styles.iconStyle}></img>
						<div style={styles.resultValueContainer}>{balancedScore || 0} / 10</div>
					</div>
					<div className="text-center">
						<div style={styles.subSectionTitle}>Hyper</div>
						<img src='/images/Hyper_State_Transparent.png' style={styles.iconStyle}></img>
						<div style={styles.resultValueContainer}>{hyperScore || 0} / 10</div>
					</div>
				</div>
				<p style={styles.viewMoreText}>View More</p>
			</div>
		);
	}

	renderValueResultSummary() {

		let {top3Values, bottom3Values} = this.state;

		return(
			<div onClick={this.handleResultsRender.bind(this, 'values')} className="values-section">
				<p style={styles.sectionTitle}>Values</p>
				<div>
					<div style={styles.subSectionTitle}>Top 3 Values</div>
					<div style={styles.resultValueContainer}>{top3Values}</div>
				</div>
				<div className="mt-3">
					<div style={styles.subSectionTitle}>Bottom 3 Values</div>
					<div style={styles.resultValueContainer}>{bottom3Values}</div>
				</div>
				<p style={styles.viewMoreText}>View More</p>
			</div>
		);
	}

	renderEmoStateResultSummary() {

		let {balancedStates, hyperInertStates} = this.state;

		return(
			<div onClick={this.handleResultsRender.bind(this, 'emo-state')} className="emo-state-section">
				<p style={styles.sectionTitle}>EmoState</p>
				<div>
					<div style={styles.subSectionTitle}>Top Balanced States</div>
					<div style={styles.resultValueContainer}>{balancedStates}</div>
				</div>
				<div className="mt-3">
					<div style={styles.subSectionTitle}>Inert and/or Hyper States</div>
					<div style={styles.resultValueContainer}>{hyperInertStates}</div>
				</div>
				<p style={styles.viewMoreText}>View More</p>
			</div>
		);
	}

	renderReport() {

		return (

			<div>
				<p className="text-blog">You’re already on the path to understanding your values and taking control of your inner state to best drive your own personal growth. Below we are going to go through your results and give you personalized feedback on how to reach your full potential.</p>
				
				<div className="row">
					<div className="col-md-6">
						{this.renderThriveMeterResultSummary()}
					</div>
					<div className="col-md-6">
						{this.renderEnergyStateResultSummary()}
					</div>
				</div>

				<div className="row mt-0 mt-md-2">
					<div className="col-md-6">
						{this.renderValueResultSummary()}
					</div>
					<div className="col-md-6">
						{this.renderEmoStateResultSummary()}
					</div>
				</div>

				<div className="mt-3 text-center">
					<button type="button" onClick={this.handleResultsRender.bind(this, 'thrive-meter')} className="btn btn-primary" style={{minWidth:165}}>Review Report</button>
					<Link to={'/assessments/inner-insight'} className="btn btn-primary m-3" style={{minWidth:165}}>Inner Insight Home</Link>
				</div>

			</div>
		);
	}

	render() {

		let { results, showCurrentResult, currentResultLabel } = this.state;

		if (results == null) {
			return(
				<div className="page-content container child-container">
					<div className="bread-crumb">
						<a href="/assessments/">Assessments</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="/assessments/inner-insight">Inner Insight</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Report</a>
					</div>
				</div>
			);
		}
		else if (showCurrentResult) {
			return(
				<div className="page-content container child-container">
					<div className="bread-crumb">
						<a href="/assessments/">Assessments</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="/assessments/inner-insight">Inner Insight</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#" onClick={this.handleResultsRender.bind(this, '')}>Report</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">{currentResultLabel}</a>
					</div>
					<div className="container-with-padding" style={{paddingTop:20}}>
						{this.renderResults()}
					</div>
				</div>
			)
		}
		else if (results) {

			return (
				<div className="page-content container child-container">
					<div className="bread-crumb">
						<a href="/assessments/">Assessments</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="/assessments/inner-insight">Inner Insight</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Report</a>
					</div>
					<div className="inner-container">
						{this.renderReport()}
					</div>
				</div>
			);
		}
	}
}

const styles = {

	sectionTitle: {
		fontSize: 17,
		marginBottom: 10,
		textAlign: 'center',
		fontFamily: 'Pangram-Bold'
	},
	subSectionTitle: {
		fontSize: 15,
		margin: 10,
		textAlign: 'center'
	},
	resultValueContainer: {
		backgroundColor: '#fff',
		color: '#737373',
		borderRadius: 5,
		textAlign: 'center',
		padding: '3px 10px',
		marginTop: 5
	},
	iconStyle: {
		resizeMode: 'contain',
		width: 60,
		height: 60,
		marginBottom: 10
	},
	viewMoreText: {
		padding: '2px',
		background: '#fff',
		borderRadius: 5,
		width: 100,
		textAlign: 'center',
		fontSize: '14px',
		color: '#737373',
		marginTop: 20,
		marginBottom: 5,
		margin: '20px auto 5px auto'
	}
}

IIAReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	IIAReport
);
