import {
	RESET_KEY_ACTIVITY,
	GET_KEY_ACTIVITY,
	GET_KEY_ACTIVITIES,
	DELETE_KEY_ACTIVITY,
} from "../actions/types.js";

const initialState = {
	keyactivities: [],
	keyactivity: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_KEY_ACTIVITY:
			return {
				...initialState,
			}

		case GET_KEY_ACTIVITY:
			return {
				...state,
				keyactivity: action.payload,
			};

		case GET_KEY_ACTIVITIES:
			return {
				...state,
				keyactivities: action.payload,
			};

		case DELETE_KEY_ACTIVITY:
			return {
				...state,
				keyactivities: state.keyactivities.filter(keyactivity => keyactivity.id !== action.payload)
			};

		default:
			return state;
	}
}
