import {
	LOADING,
} from "./types";

// loading
export const setLoading = (loadingStatus, initiator = 'api_request') => dispatch => {

	dispatch({
		type: LOADING,
		payload: {
			loadingStatus,
			initiator // Name of the function that triggers the loader. Defaults to generic value 'api_request'. Refer to the file loadingReducer.js for further details.
		}
	});
};