import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Util from '../../utils/Util';

import TimeBlockTypeSelect from "../time-blocks/TimeBlockTypeSelect";
import TimeDurationSelect from "../form-inputs/TimeDurationSelect";
import TimeIntervalSelect from "../form-inputs/TimeIntervalSelect";
import RadioSelector from "../form-inputs/RadioSelector";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const regularityOptions = [
	{label: 'Weekly', value: 'weekly'},
	{label: 'Non-Weekly', value: 'non-weekly'},
];

class TimeBlocks extends Component {

	constructor(props) {
		super(props);
        this.state = {
            timeBlocks: this.props.timeBlocks ? _.clone(this.props.timeBlocks) : [],
            timeBlockObj: {}, // Handle the timeBlockObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            validationErrors: {},
            showUpdateModal: false,
            itemToRemove: null,
            showDeleteModal: false,
        };
    }

	toggleUpdateModal(timeBlockObj) {

        if (this.state.showUpdateModal) {
            this.setState({
                timeBlockObj: {},
                validationErrors: {},
                showUpdateModal: false
            });
        }
        else {

			// For new timeblocks and existing timeblocks without regularity, set default regularity as weekly.
			if (_.isEmpty(timeBlockObj) || (timeBlockObj && !timeBlockObj.regularity)) {
				timeBlockObj['regularity'] = "weekly";
			};

            this.setState({
                timeBlockObj: _.clone(timeBlockObj),
                validationErrors: {},
                showUpdateModal: true
            });
        };
    }

	updateTimeBlocksInParent() {
		
		let {timeBlocks, timeBlockObj, validationErrors} = this.state;
		
		let fieldsToBeValidated = ['title', 'type', 'durationInMinutes', 'regularity', 'sessionsPerWeek', 'interval', 'recurringInterval'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
			let refValue = null;

			if (fieldName == 'recurringInterval') {
				refValue = timeBlockObj['interval'];
			};

            let validationMessage = this.validateUserInput(fieldName, timeBlockObj[fieldName], refValue);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
		};
		
		if (timeBlockObj.regularity == 'non-weekly') {
			timeBlockObj.durationInMinutesPerWeek = Util.computeWeeklyTimeDurationForANonWeeklyTimeBlock((timeBlockObj.durationInMinutes || 0), timeBlockObj.interval, timeBlockObj.recurringInterval);
			timeBlockObj.sessionsPerWeek = 0;
		}
		else {
			timeBlockObj.durationInMinutesPerWeek = (timeBlockObj.durationInMinutes || 0) * (timeBlockObj.sessionsPerWeek || 0);
			timeBlockObj.interval = '';
		};
		
		let itemIndex = _.findIndex(timeBlocks, {id: timeBlockObj.id});

		if (itemIndex > -1) {
			timeBlocks[itemIndex] = timeBlockObj;
		}
		else {
			timeBlockObj.id = Util.generateObjectId();
			timeBlocks.push(timeBlockObj);
		};
		
		this.setState({
			timeBlocks: timeBlocks
		},
		() => {

			if (this.props.onChange) {
				this.props.onChange(timeBlocks); // Update state of both the parent and child components.
			};
			this.toggleUpdateModal();
		});
	}

	validateUserInput(fieldName, response, refValue) {

        let validationMessage = 'valid';
		let mandatoryFields = ['title', 'type', 'durationInMinutes', 'regularity'];

		const regularity = this.state.timeBlockObj['regularity'];

		if (regularity == 'weekly') { 
			mandatoryFields.push('sessionsPerWeek'); /* Mandatory only for 'Weekly' TB */
		} 
		else if (regularity == 'non-weekly') {
			mandatoryFields.push('interval'); /* Mandatory only for 'Non-Weekly' TB */
		};

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        }
		else if (fieldName == 'recurringInterval' && refValue == 'every_x_months') {
			
			if (!response) {
				validationMessage = 'This field is mandatory.';
			}
			else if (response && (response < 1 || response > 12)) {
				validationMessage = 'The number should be between 1 and 12';
			};
		};
        
        return validationMessage;
    }

	handleValueChanges(fieldName, response) {

		let {timeBlockObj, validationErrors} = this.state;

		let stringResponseFields = ['title'];
		let numberResponseFields = ['sessionsPerWeek', 'recurringInterval'];
		let dropdownFields = ['type', 'durationInMinutes', 'interval'];
		let refValue = null;

        if (stringResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? response.target.value : '';
        }
		else if (numberResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? parseInt(response.target.value) : null;
        }
		else if (dropdownFields.indexOf(fieldName) > -1) {
            response = response.value ? response.value : null;
        };

		if (fieldName == 'recurringInterval') {
			refValue = timeBlockObj['interval'];
		};

		let validationMessage = this.validateUserInput(fieldName, response, refValue);

		timeBlockObj[fieldName] = response;
		validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

		this.setState({
            timeBlockObj: timeBlockObj,
			validationErrors: validationErrors
        });
	}

	renderUpdateModal() {

        let {timeBlockObj, validationErrors} = this.state;

        return(
			<div>
				<Modal size={'lg'} isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleUpdateModal.bind(this)}>Time Block</ModalHeader>
					<ModalBody>

                        <div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div><input type="text" onChange={this.handleValueChanges.bind(this, 'title')} className="form-control" value={timeBlockObj['title']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['title']}</div>
						</div>

                        <TimeBlockTypeSelect
							handleTimeBlockTypeSelect={this.handleValueChanges.bind(this)}
							value={timeBlockObj['type']}
							error={validationErrors['type']}
							isRequired={true}
						/>

                        <TimeDurationSelect
                            handleTimeDurationSelect={this.handleValueChanges.bind(this)}
                            value={timeBlockObj['durationInMinutes']}
                            error={validationErrors['durationInMinutes']}
                            isRequired={true}
                        />

						<div className="form-group">
							<label>Regularity <span className="text-danger">*</span></label>
							<RadioSelector
								orientation={'horizontal'} 
								options={regularityOptions}
								radioGroupName={'regularity'}
								selectedValue={timeBlockObj['regularity'] ? timeBlockObj['regularity'] : null}
								onChange={this.handleValueChanges.bind(this)}
							/>
							<div className="text-danger small mt-1">{validationErrors['regularity']}</div>
						</div>
						{timeBlockObj['regularity'] && timeBlockObj['regularity'] == 'non-weekly' ?
							<div>
								<TimeIntervalSelect
									handleTimeIntervalSelect={this.handleValueChanges.bind(this)}
									value={timeBlockObj['interval']}
									error={validationErrors['interval']}
									isRequired={true}
								/>
								{timeBlockObj['interval'] == 'every_x_months' ?
									<div className="form-group">
										<label>Recurring Interval <span className="ml-1 small">(Please specify the months X in number)</span> <span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleValueChanges.bind(this, "recurringInterval")} className="form-control" name="recurringInterval" value={timeBlockObj['recurringInterval']} />
											<p className="text-danger">{validationErrors['recurringInterval'] &&
												<span className="err">{validationErrors['recurringInterval']}</span>}</p>
										</div>
									</div>
								: null}
							</div>
						:
							<div className="form-group">
								<label># of Sessions (per week) <span className="text-danger">*</span></label>
								<div><input type="number" onChange={this.handleValueChanges.bind(this, 'sessionsPerWeek')} className="form-control" value={timeBlockObj['sessionsPerWeek']} /></div>
                            	<div className="text-danger small mt-1">{validationErrors['sessionsPerWeek']}</div>
							</div>
						}

                        <button type="button" className="btn btn-primary btn-sm" onClick={this.updateTimeBlocksInParent.bind(this)}>Done</button>

						<div className="mt-3"><small>* The above button will store your changes locally upon clicking it. You need to click "Save Template" button from the homepage to update the database.</small></div>
						
					</ModalBody>
				</Modal>
			</div>
		);
    }

	handleTimeBlockDelete() {

		let {timeBlocks, itemToRemove} = this.state;

        if (!itemToRemove) return;

        let itemIndex = _.findIndex(timeBlocks, {id: itemToRemove});
        timeBlocks.splice(itemIndex, 1);

        this.setState({
            timeBlocks: timeBlocks
        },
        () => {
			if (this.props.onChange) {
				this.props.onChange(timeBlocks);
			};
            this.toggleDeleteModal();
        });
    }

	toggleDeleteModal(recordId) {

		if (this.state.showDeleteModal) {
			this.setState({
				showDeleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteModal: true,
				itemToRemove: recordId,
			});
		}
	}

	renderDeleteModal() {

		var timeBlockObj = _.find(this.state.timeBlocks, { id: this.state.itemToRemove });
		var title = timeBlockObj ? timeBlockObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Time Block</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the time block <span className="text-warning">{title}?</span></p>
						<div><small>* This action is temporary. You need to click "Save Template" button from the homepage to update the database.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sm" color="danger" title="Delete" onClick={this.handleTimeBlockDelete.bind(this)}>Delete</Button>{' '}
						<Button className="btn-sm" color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		let {timeBlocks} = this.state;

		return(
			<div className="form-group">
				<div className="row">
					<div className="col-6">
						<label>Time Blocks</label>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleUpdateModal.bind(this, {})}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				{timeBlocks && timeBlocks.length > 0 ?
					<div className="table-responsive">
						<table className="table-curved mt-3 mb-2">
							<thead>
								<tr className="row-section-container">
									<th className="text-center" style={{ width: 50 }}>#</th>
									<th className="text-left" style={{ width: 400 }}>Title</th>
									<th className="text-center" style={{ width: 175 }}>Type</th>
									<th className="text-center" style={{ width: 175 }}>Duration</th>
									<th className="text-center" style={{ width: 175 }}>Regularity</th>
									<th className="text-center" style={{ width: 175 }}><div># of Sessions<div><small>(per week)</small></div></div></th>
									<th className="text-center" style={{ width: 175 }}><div>Duration<div><small>(per week)</small></div></div></th>
									<th className="text-center" style={{ width: 100 }} colSpan={2}>Actions</th>
								</tr>
							</thead>
							<tbody>
								{timeBlocks.map((timeBlockObj, index) => {

									let isNonWeeklyTB = timeBlockObj.regularity == 'non-weekly' ? true : false;
									let durationInMinutesPerWeek = isNonWeeklyTB ? (timeBlockObj.durationInMinutesPerWeek || 0) : ((timeBlockObj.durationInMinutes || 0) * (timeBlockObj.sessionsPerWeek || 0));
									let regularity = 'Weekly';

									if (isNonWeeklyTB) {
										if (timeBlockObj.interval == 'every_x_months' && timeBlockObj.recurringInterval) {
											regularity = `Every ${timeBlockObj.recurringInterval} Months`;
										}
										else {
											regularity = _.startCase(_.camelCase(timeBlockObj.interval));
										};
									};

									return(
										<tr key={'timeblock_' + index} className="row-section-container">
											<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
											<td className="text-left" style={{ width: 400, minWidth: 250  }}>{timeBlockObj.title}</td>
											<td className="text-center" style={{ width: 175, minWidth: 175 }}>{timeBlockObj.type}</td>
											<td className="text-center" style={{ width: 175, minWidth: 175 }}>{timeBlockObj.durationInMinutes ? Util.convertTimeToReadableText(timeBlockObj.durationInMinutes) : ''}</td>
											<td className="text-center" style={{ width: 175, minWidth: 175 }}>{regularity}</td>
											<td className="text-center" style={{ width: 175, minWidth: 175 }}>{isNonWeeklyTB ? <small>N/A</small> : timeBlockObj.sessionsPerWeek}</td>
											<td className="text-center" style={{ width: 175, minWidth: 175 }}>{Util.convertTimeToReadableText(durationInMinutesPerWeek)}</td>
											<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Edit" onClick={this.toggleUpdateModal.bind(this, timeBlockObj)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
											<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, timeBlockObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{/* <div className="small mt-2">* Sessions per week</div> */}
					</div>
				: 
					<div className="small text-info mt-1">No Time Blocks added.</div>
				}
				{this.renderUpdateModal()}
				{this.renderDeleteModal()}
			</div>
		);
	}
}

TimeBlocks.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	TimeBlocks
);