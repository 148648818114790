import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Globals from '../../utils/Globals';
import { setLoading } from "../../actions/loadingActions.js";
import RadioSelector from "../form-inputs/RadioSelector";

class SelectCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModalPopup: false,
            selectedCalendar: this.props.preferences ? this.props.preferences.selectedCalendar : ''
        }
    }

    toggleModalPopup() {
        
        this.setState({
            showModalPopup: !this.state.showModalPopup
        });
    }

    handleSubmit() {

        if (this.state.selectedCalendar && this.props.preferences && this.props.onUpdate) {
            
            let preferences = this.props.preferences;
            preferences.selectedCalendar = this.state.selectedCalendar;
            
            this.props.onUpdate(preferences);
            this.toggleModalPopup();
        };
    }

    renderCalendarSelectModal() {

        let calendarList = this.props.calendarList || [];
        calendarList = calendarList.map((item) => {
            return {
                label: item.primary == true ? item.summary + ' (primary)': item.summary,
                value: item.id
            };
        });

        return(
			<div>
				<Modal isOpen={this.state.showModalPopup} toggle={this.toggleModalPopup.bind(this)}>
					<ModalHeader toggle={this.toggleModalPopup.bind(this)}>Choose Calendar</ModalHeader>
					<ModalBody>
                            <div className="form-group">
                                <label>Specify the calendar to be synced <span className="text-danger">*</span></label>
                                <div style={{lineHeight:2}}>
                                    <RadioSelector
                                        orientation={'vertical'} 
                                        options={calendarList}
                                        radioGroupName={'selected-calendar'}
                                        selectedValue={this.state.selectedCalendar}
                                        onChange={(name, value) => this.setState({selectedCalendar: value})}
                                    />
                                </div>
                            </div>
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.handleSubmit.bind(this)} disabled={!this.state.selectedCalendar}>Save Changes</button>
					</ModalBody>
				</Modal>
			</div>
		);
    }
    
    render() {

        if (Globals.ENVIRONMENT.ENV == 'DEV' || Globals.ENVIRONMENT.ENV == 'STAGING') {
            return(
                <span className="small ml-2 text-info cursor-pointer" onClick={this.toggleModalPopup.bind(this)}>
                    <u>[Change Calendar]</u>
                    {this.renderCalendarSelectModal()}
                </span>
            )
        }
        else {
            return null;
        };
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	SelectCalendar
);