import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SharedStyles from '../../assets/styles/SharedStyles';

import AddUTask from "./AddUTask";
import UTasksList from './UTasksList';
import Util from "../../utils/Util.js";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class UTaskDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			utasks: [],
			unfilteredTasks: [],
			showFilterSection: false,
			addTaskModal: false,
			outcomesWithPopulatedData: [],
			outcomeOptions: [],
			keyActivityOptions: [],
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			statusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Completed', value: 'completed'},
				{label: 'Open', value: 'open'}
			],
			selectedStatusOption: {label: 'Open', value: 'open'},
			scheduleStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Scheduled', value: 'scheduled'},
				{label: 'Unscheduled', value: 'unscheduled'}
			],
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			flagOptions: [
				{label: 'All', value: 'all'},
				{label: 'Flagged', value: 'flagged'},
				{label: 'Not Flagged', value: 'not-flagged'},
			],
			selectedFlagOptions: {label: 'All', value: 'all'},
			weeklyPlanStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Active', value: 'active'},
				{label: 'Inactive', value: 'inactive'}
			],
			selectedWeeklyPlanStatus: {label: 'Active', value: 'active'},
		}
	}

	componentDidMount() {
		this.getUTasks();
		this.getOutcomesWithPopulatedData(); // For filter options.
	}

	getOutcomesWithPopulatedData() {

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					let outcomesWithPopulatedData = response.data.data || [];
					let outcomeOptions = [
						{label: 'All', value: 'all'},
						{label: 'Unassigned', value: 'unassigned'},
					]; // For default selection.

					_.map(outcomesWithPopulatedData, (outcomeObj) => {
						outcomeOptions.push({
							label: outcomeObj.outcomeTitle,
							value: outcomeObj.id
						});
					});

					this.setState({
						outcomesWithPopulatedData: outcomesWithPopulatedData,
						outcomeOptions: outcomeOptions
					});
				}
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUTasks() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUTasks, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setState({
						utasks: response.data.data,
						unfilteredTasks: response.data.data // Store response in two variables to support frontend filtering.
					},
					() => {
						this.applyFilter();
					}); 
				} 
				else {
					this.setApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	onTaskAddition(itemPosition, newTask) {

		let { unfilteredTasks } = this.state;
		itemPosition = itemPosition ? parseInt(itemPosition)-1 : 0; // The newly added task will be added at the top by default.					
			
		if (itemPosition > unfilteredTasks.length) {
			itemPosition = unfilteredTasks.length;
		};
		unfilteredTasks.splice(itemPosition, 0, newTask); // Add tasks at the required position.

		this.setState({
			unfilteredTasks: unfilteredTasks
		},
		() => {
			this.applyFilter();
		});
	}

	onTaskUpdate(updatedTaskObj) {

		this.getUTasks();
		return;

		const { unfilteredTasks } = this.state;
		var taskObjIndex = _.findIndex(unfilteredTasks, { id: updatedTaskObj.id });
		
		if (taskObjIndex > -1) {
			unfilteredTasks[taskObjIndex] = _.assign(unfilteredTasks[taskObjIndex], updatedTaskObj);
			this.setState({
				unfilteredTasks: unfilteredTasks
			},
			() => {
				this.applyFilter();
			});
		};
	}

	onTaskDelete(deletedTaskObj) {

		const { unfilteredTasks } = this.state;
		var taskObjIndex = _.findIndex(unfilteredTasks, { id: deletedTaskObj.id });
		
		if (taskObjIndex > -1) {
			unfilteredTasks.splice(taskObjIndex, 1);
			this.setState({
				unfilteredTasks: unfilteredTasks
			},
			() => {
				this.applyFilter();
			});
		};
	}

	onRankUpdate(updatedTaskSequence) {

		let {unfilteredTasks} = this.state;

		unfilteredTasks = _.sortBy(unfilteredTasks, function(item) {
			return updatedTaskSequence.indexOf(item.id);
		});

		this.setState({
			unfilteredTasks: unfilteredTasks
		},
		() => {
			this.applyFilter();
		});
	}

	handleOutcomeSelectionChange(outcome) {

		if (outcome.value == 'unassigned') {

			let keyActivityOptions = [
				{ label: 'Unassigned', value: 'unassigned'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'Unassigned', value: 'unassigned'}
			});
		}
		else if (outcome.value == 'all') {

			let keyActivityOptions = [
				{ label: 'All', value: 'all'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
		else {

			let filterObjForTheSelectedOutcome = _.find(this.state.outcomesWithPopulatedData, {id: outcome.value});
			let keyActivityOptions = [
				{ label: 'All',value: 'all'}
			]; // For default selection.

			if (filterObjForTheSelectedOutcome && filterObjForTheSelectedOutcome.keyActivities) {

				_.map(filterObjForTheSelectedOutcome.keyActivities, (activityObj) => {
	
					keyActivityOptions.push({
						label: activityObj.title,
						value: activityObj.id
					});
				});
			};

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
	}

	applyFilter() {

		const {unfilteredTasks, selectedOutcome, selectedKeyActivity, selectedStatusOption, selectedScheduleStatusOption, selectedFlagOptions, selectedWeeklyPlanStatus} = this.state;

		let filteredTasks = [];

		// 1. Filter based on Outcome and KeyActivity.
		if (selectedOutcome.value == 'unassigned' || selectedKeyActivity.value == 'unassigned') {
			
			filteredTasks = _.filter(unfilteredTasks, (item) => {
				return !item.outcomeID && !item.keyActivity;
			});
		}
		else {

			if (selectedOutcome.value == 'all') {
				filteredTasks = Util.cloneArray(unfilteredTasks);
			}
			else if (selectedOutcome.value) {
				
				filteredTasks = _.filter(unfilteredTasks, (item) => {
					return item.outcomeID == selectedOutcome.value;
				});

				if (selectedKeyActivity.value && selectedKeyActivity.value != 'all' && selectedKeyActivity.value != 'unassigned') {
					
					filteredTasks = _.filter(filteredTasks, (item) => {
						return item.keyActivity == selectedKeyActivity.value;
					});
				}
			}
		};

		// 2. Filter based on includeInWeeklyPlan status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedWeeklyPlanStatus.value == 'all') return true;
			else if (!item.outcomeID && !item.keyActivity) return true; //The unassigned tasks should be unaffected by this filter
			else if (selectedWeeklyPlanStatus.value == 'active') return item.includeInWeeklyPlanStatusOfOutcome && item.includeInWeeklyPlanStatusOfKeyActivity;
			else if (selectedWeeklyPlanStatus.value == 'inactive') return !item.includeInWeeklyPlanStatusOfOutcome || !item.includeInWeeklyPlanStatusOfKeyActivity;
			else return false;
		});
		
		// 3. Filter based on task completion status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedStatusOption.value == 'all') return true;
			else if (selectedStatusOption.value == 'completed') return item.isCompleted;
			else if (selectedStatusOption.value == 'open') return !item.isCompleted;
			else return false;
		});

		// 4. Filter based on calendar schedule status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedScheduleStatusOption.value == 'all') return true;
			else if (selectedScheduleStatusOption.value == 'scheduled') return item.calendarSchedules && item.calendarSchedules.length > 0;
			else if (selectedScheduleStatusOption.value == 'unscheduled') return !item.calendarSchedules || (item.calendarSchedules && item.calendarSchedules.length == 0);
			else return false;
		});

		// 5. Filter based on flag.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedFlagOptions.value == 'all') return true;
			else if (selectedFlagOptions.value == 'flagged' && item.flags) return item.flags.length == 1;
			else if (selectedFlagOptions.value == 'not-flagged') return !item.flags || item.flags && item.flags.length == 0;
			else return false;
		});

		this.setState({
			utasks: filteredTasks,
			selectedOutcomeLabel: selectedOutcome ? selectedOutcome.label : '',
			selectedKeyActivityLabel: selectedKeyActivity ? selectedKeyActivity.label : '',
			selectedWeeklyPlanStatusLabel: selectedWeeklyPlanStatus ? selectedWeeklyPlanStatus.label : '',
			selectedStatusOptionLabel: selectedStatusOption ? selectedStatusOption.label : '',
			selectedScheduleStatusOptionLabel: selectedScheduleStatusOption ? selectedScheduleStatusOption.label : '',
			selectedFlagOptionLabel: selectedFlagOptions ? selectedFlagOptions.label : ''
		});
	}

	resetFilterOptions() {
		
		this.setState({
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			keyActivityOptions: [{label: 'All', value: 'all'}],
			selectedStatusOption: {label: 'All', value: 'all'},
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			selectedFlagOptions: {label: 'All', value: 'all'},
			selectedWeeklyPlanStatus: {label: 'All', value: 'all'},
			utasks: Util.cloneArray(this.state.unfilteredTasks)
		},
		() => {
			this.applyFilter();
		});
	}

	toggleFilterSection() {

		this.setState({
			showFilterSection: !this.state.showFilterSection
		});
	}

	toggleAddTaskModal() {

		this.setState({
			addTaskModal: !this.state.addTaskModal
		});
	}

	renderFilterSection() {

		const { showFilterSection, outcomesWithPopulatedData, outcomeOptions, keyActivityOptions, statusOptions, scheduleStatusOptions, flagOptions, weeklyPlanStatusOptions } = this.state;

		if (showFilterSection && outcomesWithPopulatedData.length > 0) {
			return(
				<div className="mt-3">
					<div className="row row-section-container">
						<div className="col-md-4 mb-3">
							<label>Project</label>
							<ReactSelect
								name="outcome"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={outcomeOptions}
								value={this.state.selectedOutcome}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(outcome) => this.handleOutcomeSelectionChange(outcome)}
								placeholder="Filter by Project..."
							/>
						</div>
						<div className="col-md-4 mb-3">
							<label>Key Activity</label>
							<ReactSelect
								name="keyActivity"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={keyActivityOptions}
								value={this.state.selectedKeyActivity}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(activity) => this.setState({selectedKeyActivity: activity})}
								placeholder="Filter by Key Activity..."
							/>
						</div>
						{this.state.selectedOutcome && this.state.selectedOutcome.value != 'unassigned' ?
							<div className="col-md-4 mb-3">
								<label>Include In Weekly Plan Status</label>
								<ReactSelect
									name="includeInWeeklyPlanStatus"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={weeklyPlanStatusOptions}
									value={this.state.selectedWeeklyPlanStatus}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={(option) => this.setState({selectedWeeklyPlanStatus: option})}
									placeholder="Filter by Include in Weekly Plan Status..."
								/>
							</div>
						: null}
					</div>
					<div className="row row-section-container">
						<div className="col-md-4 mb-3">
							<label>Status</label>
							<ReactSelect
								name="status"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={statusOptions}
								value={this.state.selectedStatusOption}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(option) => this.setState({selectedStatusOption: option})}
								placeholder="Filter by completion status..."
							/>
						</div>
						<div className="col-md-4 mb-3">
							<label>Calendar Schedule Status</label>
							<ReactSelect
								name="calendarScheduleStatus"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={scheduleStatusOptions}
								value={this.state.selectedScheduleStatusOption}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(option) => this.setState({selectedScheduleStatusOption: option})}
								placeholder="Filter by completion status..."
							/>
						</div>
						<div className="col-md-4 mb-3">
							<label>Flag</label>
							<ReactSelect
								name="flags"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={flagOptions}
								value={this.state.selectedFlagOptions}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(options) => this.setState({selectedFlagOptions: options})}
								placeholder="Filter by flags..."
							/>
						</div>
					</div>
					<div className="inner-container">
						<button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.applyFilter.bind(this)}>Apply</button>
						<button type="button" className="btn btn-primary btn-sm" onClick={this.resetFilterOptions.bind(this)}>Reset</button>
					</div>
					<div className="mx-3 my-1">{this.renderAppliedFiltersList()}</div>
				</div>
			)
		}
		else {
			return null;
		};
	}

	renderUTaskList() {

		const { utasks, unfilteredTasks } = this.state;

		if (unfilteredTasks && unfilteredTasks.length > 0) {
			return (
				<div>
					<UTasksList 
						utasks={utasks}
						unfilteredTasks={unfilteredTasks}
						reloadData={() => {this.getOutcomesWithPopulatedData()}}
						onTaskAddition={this.onTaskAddition.bind(this)}
						onTaskUpdate={this.onTaskUpdate.bind(this)}
						onRankUpdate={this.onRankUpdate.bind(this)} 
						onTaskDelete={this.onTaskDelete.bind(this)} />
				</div>
			);
		} 
		else {
			return (<p className="text-right">Please add tasks using the above button</p>)
		}
	}

	renderAddTaskModal() {

		return(
			<Modal size={'lg'} isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
				<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Add Task</ModalHeader>
				<ModalBody>
					<AddUTask
						itemPosition={1} // The tasks added using this button should always be positioned at the top.
						onClose={() => {this.toggleAddTaskModal()}}
						reloadData={() => {this.getOutcomesWithPopulatedData()}}
						addTaskInList={(itemPosition, taskObj) => {
							this.onTaskAddition(itemPosition, taskObj);
							this.toggleAddTaskModal();
						}} />
				</ModalBody>
			</Modal>
		)
	}

	renderAppliedFiltersList() {

		const {selectedOutcomeLabel, selectedKeyActivityLabel, selectedWeeklyPlanStatusLabel, selectedStatusOptionLabel, selectedScheduleStatusOptionLabel, selectedFlagOptionLabel} = this.state;

		return(
			<span style={{fontSize:13}}>
				<span>(Current Filters: </span>
				{!selectedOutcomeLabel || selectedOutcomeLabel == 'All' ? null :
					<span>Project: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedOutcomeLabel}</span>,&nbsp;&nbsp;</span> 
				}
				{!selectedKeyActivityLabel || selectedKeyActivityLabel == 'All' ? null :
					<span>Key Activity: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedKeyActivityLabel}</span>,&nbsp;&nbsp;</span> 
				}
				{!selectedWeeklyPlanStatusLabel || selectedOutcomeLabel == 'Unassigned' || selectedKeyActivityLabel == 'Unassigned' ? null :
					<span>Include In Weekly Plan: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedWeeklyPlanStatusLabel}</span>,&nbsp;&nbsp;</span>
				}
				<span>Status: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedStatusOptionLabel || '-'}</span></span>
				{!selectedScheduleStatusOptionLabel || selectedScheduleStatusOptionLabel == 'All' ? null :
					<span>,&nbsp;&nbsp;Schedule Status: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedScheduleStatusOptionLabel}</span></span> 
				}
				{!selectedFlagOptionLabel || selectedFlagOptionLabel == 'All' ? null :
					<span>,&nbsp;&nbsp;Flag: <span style={{fontFamily: 'Pangram-Bold'}}>{selectedFlagOptionLabel}</span></span> 
				}
				<span>)</span>
			</span>
		);
	}

	renderTotalTimeEstimates() {
		
		let totalTimeEstimates = 0;

		if (this.state.utasks.length > 0) {
			totalTimeEstimates = _.sumBy(this.state.utasks, 'timeEstimateInMinutes');
			return <span>Total Time Estimate: <span style={{fontFamily: 'Pangram-Bold'}}>{Util.convertTimeToReadableText(totalTimeEstimates)}</span></span>;
		};
		return null;
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/tasks/">Tasks</a>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="inner-container">
							<div className="grey-container mb-4">
								<div className="d-flex flex-wrap align-items-center cursor-pointer" onClick={this.toggleFilterSection.bind(this)}>
									<span className="color-primary">
										{this.state.showFilterSection ?
											<i class="fa fa-caret-down fa-lg" aria-hidden="true"></i>:
											<i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>}
									</span>
									<span className="mx-2" style={{fontFamily:'Pangram-Bold'}}>Filter Tasks</span>
									{this.state.showFilterSection ? null : <div className="ml-3 ml-sm-0">{this.renderAppliedFiltersList()}</div>}
								</div>
								{this.renderFilterSection()}
							</div>
							<div className="text-right">
								{this.renderTotalTimeEstimates()}
								<button type="button" className="btn btn-primary ml-3 mb-2" title="Add Task" onClick={this.toggleAddTaskModal.bind(this)}>Add Task <i className="fa fa-plus" aria-hidden="true"></i></button>
							</div>
							<div className="inner-container-table" style={{marginTop: 20}}>
								{this.renderUTaskList()}
								{this.renderAddTaskModal()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

UTaskDashboard.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	UTaskDashboard
);
