import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getTimeBlockByID, resetTimeBlockData } from "../../actions/timeBlockActions";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import TimeBlockForm from "./TimeBlockForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditTimeBlock extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	componentWillUnmount() {
		// Reset data so that table will re-render
		this.props.resetTimeBlockData();
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {
		this.props.getTimeBlockByID(this.props.timeBlockID);
	}

	// Handler for editing Time Block
	handleEditTimeBlock(data) {

		data.recordID = this.props.timeBlockID;

		var postObj = data;

		postObj.keyActivityID = this.props.keyActivityID;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateTimeBlocks, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Time Block saved successfully.", false);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		const { timeblock } = this.props.timeblocks;
		const { formApiResponse } = this.state;

		if (!Util.isEmpty(timeblock)) {

			return (
				<div className="section-container">
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								<TimeBlockForm
									initialValues={timeblock}
									onSubmit={this.handleEditTimeBlock.bind(this)}
									isEdit={true}
									timeBlockID={this.props.timeBlockID}
								/>
								{
									!_.isEmpty(formApiResponse)
										?
										<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			);
		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">

								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

EditTimeBlock.propTypes = {
	getTimeBlockByID: PropTypes.func.isRequired,
	resetTimeBlockData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	timeblocks: state.timeblocks,
});

export default connect(mapStateToProps, { getTimeBlockByID, resetTimeBlockData, setLoading })(
	EditTimeBlock
);
