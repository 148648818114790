import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import TextareaAutosize from 'react-textarea-autosize';

import { Link } from "react-router-dom";
import { setLoading } from "../../actions/loadingActions.js";
import { resetoutcomeData } from "../../actions/outcomeActions";

import ProjectsMetrics from "../metrics/ProjectsMetrics";

import FocusAreaSelect from "../focus-areas/FocusAreaSelect";
import CreationMaintenanceSelect from "../form-inputs/CreationMaintenanceSelect";
import RolesAndResponsibilitiesSelect from "../roles-and-responsibilities/RolesAndResponsibilitiesSelect";
import CollaboratorsSelect from "../collaborators/CollaboratorsSelect";
import KeyActivitiesList from "../key-activities/KeyActivitiesList";

import { history } from '../../utils/history';
import Util from '../../utils/Util';
var _ = require('lodash');

class OutcomeForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				outcomeTitle: "",
				abbreviation: "",
				includeInWeeklyPlan: true,
				focusArea: null,
				outcome: "",
				successDefinition: "",
				creationMaintenance: null,
				bufferInPercentage: 10,
				rolesAndResponsibilities: [],
				collaborators: [],
				notes: "",
			},
			formErrors: {
				outcomeTitle: null,
				abbreviation: null,
				includeInWeeklyPlan: null,
				focusArea: null,
				outcome: null,
				successDefinition: null,
				creationMaintenance: null,
				bufferInPercentage: null,
				rolesAndResponsibilities: null,
				collaborators: null,
				notes: null,
			},
			buttonDisabled: false,
			isEdit: false,
			outcomeID: null,
			keyActivities: [],
			keyActivitiesError: "",
			notesEditorState: EditorState.createEmpty(),
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { id, focusArea, creationMaintenance, rolesAndResponsibilities, collaborators, rank, notes } = this.props.initialValues;

			var formObj = {
				focusArea: null,
				rolesAndResponsibilities: [],
				collaborators: [],
				rank: rank,
			};

			if (!Util.isEmpty(focusArea)) {
				formObj.focusArea = {
					label: focusArea.focusAreaTitle,
					value: focusArea.id,
				}
			}

			if (!Util.isEmpty(creationMaintenance)) {
				formObj.creationMaintenance = {
					label: creationMaintenance,
					value: creationMaintenance,
				}
			}

			if (!Util.isEmpty(rolesAndResponsibilities)) {

				rolesAndResponsibilities.forEach((eachRole, index) => {

					formObj.rolesAndResponsibilities.push({
						label: eachRole.description,
						value: eachRole.id,
					})
				});
			}

			if (!Util.isEmpty(collaborators)) {

				collaborators.forEach((eachCollaborator, index) => {

					formObj.collaborators.push({
						label: eachCollaborator.name,
						value: eachCollaborator.id,
					})
				});
			}

			formObj = {
				...this.props.initialValues,
				...formObj,
			};

			this.setState({
				form: formObj,
				isEdit: true,
				notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
				outcomeID: id,
			});
		}
		this.setStickyToolBar();
	}

	setStickyToolBar() {

		const header = document.getElementById("outcomes-toolbar");

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "outcomeTitle":
				if (!value) errorMsg = "Please enter Project Title";
				break;

			case "abbreviation":
				if (!value) errorMsg = "Please enter Project Abbreviation";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select a Focus Area";
				break;

			case "outcome":
				if (!value) errorMsg = "Please enter Outcome";
				break;

			// case "successDefinition":
			// 	if (!value) errorMsg = "Please enter Success Definition";
			// 	break;

			// case "creationMaintenance":
			// 	if (!value) errorMsg = "Please enter Creation:Maintenance";
			// 	break;

			case "bufferInPercentage":
				// if (!value) errorMsg = "Please enter Buffer";
				if (!Util.validateNumber(value)) errorMsg = "Buffer should be a valid Number";
				break;

			// case "rolesAndResponsibilities":
			// 	if (value.length == 0) errorMsg = "Please select Roles And Responsibilities";
			// 	break;

			// case "collaborators":
			// 	if (value.length == 0) errorMsg = "Please select Collaborators";
			// 	break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for outcome submit
	handleOutcomeSubmit(gotoParent, e) {
		e.preventDefault();

		this.setState({
			keyActivitiesError: "",
		});

		const { form, formErrors, notesEditorState, keyActivities } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {

					if(key === "rolesAndResponsibilities" || key === "collaborators") {
						var newArr = [];
						value.forEach((eachValue, index) => {
							newArr.push(eachValue.value);
						});
						formCopy[key] = newArr;
					}
				} else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			var keyActivitiesCopy = [];

			keyActivities.forEach((eachTimeBlock) => {
				keyActivitiesCopy.push(eachTimeBlock.id);
			});

			formCopy['keyActivities'] = formCopy['keyActivitiesSequence'];

			if (formCopy['creationMaintenance'] === null) {
				formCopy['creationMaintenance'] = "";
			}
			formCopy['notes'] = notesContent;
			formCopy.bufferInPercentage = parseInt(formCopy.bufferInPercentage);

			this.props.onSubmit(formCopy, gotoParent);
		}
	}

	addKeyActivityScreen() {

		if (!this.state.isEdit) {
			this.setState({
				keyActivitiesError: "Please save your project before creating key activities.",
			})
		} else {
			if (this.state.outcomeID) {
				history.push({
					pathname: '/key-activities/add',
					search: '?outcomeID=' + this.state.outcomeID,
				});
			} else {
				this.setState({
					keyActivitiesError: "Something went wrong. OutcomeID not found.",
				})
			}
		}
	}

	addApiKeyActivityInList(data) {

		var newData = [...this.state.keyActivities, ...data];

		this.setState({
			keyActivities: newData,
		});
	}

	updateKeyActivitiesSequence(updatedSequence) {

		var formCopy = { ...this.state.form };
		formCopy['keyActivitiesSequence'] = updatedSequence;

		this.setState({
			form: formCopy,
		});
	}

	removeKeyActivity(itemToRemove) {

		var { keyActivities } = this.state;

		keyActivities.forEach((eachKeyActivity, index) => {

			if (eachKeyActivity.id == itemToRemove) {
				keyActivities.splice(index, 1);
			}
		})

		this.setState({
			keyActivities: keyActivities,
		});
	}

	render() {

		const { form, formErrors, notesEditorState, keyActivities, keyActivitiesError, isEdit } = this.state;

		return (
			<div>
				<form onSubmit={(e) => e.preventDefault()}>

					<div id={'outcomes-toolbar'} className="text-right mb-1">
						{ isEdit ?
							<div>
								<button type="submit" onClick={this.handleOutcomeSubmit.bind(this, false)} className="btn btn-primary btn-sm">Save</button>
								<button type="submit" onClick={this.handleOutcomeSubmit.bind(this, true)} className="btn btn-primary btn-sm ml-4">Save & Close</button>
							</div> :
							<div>
								<button type="button" onClick={this.handleOutcomeSubmit.bind(this, false)} className="btn btn-primary btn-sm">Create</button>
								<Link to="/projects/" className="btn btn-primary btn-sm ml-4">Cancel</Link>
							</div>
						}
					</div>

					<div>
						<div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="outcomeTitle" value={form['outcomeTitle']} />
								<p className="text-danger">{formErrors['outcomeTitle'] &&
									<span className="err">{formErrors['outcomeTitle']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<label>Project Abbreviation <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="abbreviation" value={form['abbreviation']} />
								<p className="text-danger">{formErrors['abbreviation'] &&
									<span className="err">{formErrors['abbreviation']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div className="custom-control custom-switch">
								<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="includeInWeeklyPlan" value={form['includeInWeeklyPlan']} id="includeInWeeklyPlanSwitch" checked={form['includeInWeeklyPlan'] ? "checked" : ""} />
								<label className="custom-control-label" htmlFor="includeInWeeklyPlanSwitch">Include In Weekly Plan <span className="text-danger">*</span></label>
								<p className="text-danger">{formErrors['includeInWeeklyPlan'] &&
									<span className="err">{formErrors['includeInWeeklyPlan']}</span>}</p>
							</div>
						</div>
						<div>
							<FocusAreaSelect
								handleFocusAreaSelect={this.handleSelectField.bind(this)}
								value={form.focusArea}
								error={formErrors.focusArea}
								isRequired={true}
							/>
						</div>
						<div className="form-group">
							<label>Outcome <span className="text-danger">*</span></label>
							<div>
								<TextareaAutosize
									onChange={this.handleInputField.bind(this)}
									className="form-control"
									minRows={2}
									maxRows={10}
									name="outcome"
									value={form['outcome']}>
										{form['outcome']}
								</TextareaAutosize>
								<p className="text-danger">{formErrors['outcome'] &&
									<span className="err">{formErrors['outcome']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<label>Success Definition </label>
							<div>
								<TextareaAutosize
									onChange={this.handleInputField.bind(this)}
									className="form-control"
									minRows={2}
									maxRows={10}
									name="successDefinition"
									value={form['successDefinition']}>
										{form['successDefinition']}
								</TextareaAutosize>
								<p className="text-danger">{formErrors['successDefinition'] &&
									<span className="err">{formErrors['successDefinition']}</span>}</p>
							</div>
						</div>
						<div>
							<CreationMaintenanceSelect
								handleCreationMaintenanceSelect={this.handleSelectField.bind(this)}
								value={form.creationMaintenance}
								error={formErrors.creationMaintenance}
								isRequired={false}
							/>
						</div>
						<div className="form-group">
							<label>Buffer (%) </label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="bufferInPercentage" value={form['bufferInPercentage']} />
								<p className="text-danger">{formErrors['bufferInPercentage'] &&
									<span className="err">{formErrors['bufferInPercentage']}</span>}</p>
							</div>
						</div>
						<div>
							<RolesAndResponsibilitiesSelect
								handleRolesAndResponsibilitiesSelect={this.handleSelectField.bind(this)}
								value={form.rolesAndResponsibilities}
								error={formErrors.rolesAndResponsibilities}
								isRequired={false}
								isParentEdit={isEdit}
							/>
						</div>
						<div>
							<CollaboratorsSelect
								handleCollaboratorsSelect={this.handleSelectField.bind(this)}
								value={form.collaborators}
								error={formErrors.collaborators}
								isRequired={false}
								isParentEdit={isEdit}
							/>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-6">
									<label>Key Activities</label>
								</div>
								<div className="col-6 text-right">
									<button type="button" className="btn btn-primary btn-sm" onClick={this.addKeyActivityScreen.bind(this)}>
										<i className="fa fa-plus" aria-hidden="true"></i>
									</button>
								</div>
							</div>
							<div>
								<KeyActivitiesList
									values={keyActivities}
									isEdit={this.props.isEdit}
									outcomeId={this.props.outcomeId}
									addApiKeyActivityInList={this.addApiKeyActivityInList.bind(this)}
									updateKeyActivitiesSequence={this.updateKeyActivitiesSequence.bind(this)}
									removeKeyActivity={this.removeKeyActivity.bind(this)} />
							</div>
							{
								keyActivitiesError
									? <p className="text-warning">{keyActivitiesError}</p>
									: null
							}

						</div>
						<div className="form-group">
							<div>
								<ProjectsMetrics
									isEdit={this.props.isEdit}
									outcomeId={this.props.outcomeId} />
							</div>
							<br />
						</div>
						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
										})
									}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

OutcomeForm.propTypes = {
	resetoutcomeData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetoutcomeData, setLoading })(
	OutcomeForm
);
