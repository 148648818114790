module.exports = {

    APP_NAME: "PBH",

    /* ASYNC STORAGE KEYS */
	ASYNC_STORAGE_KEYS: {
        USER_CORE_PROPERTIES: 'AS_APP_USER_PROPERTIES',
        CORE_DATA: 'CORE_DATA',
    },

    /* ENVIRONMENT */
    ENVIRONMENT: {

        ENV: process.env.NODE_ENV == 'development' ? 'DEV' : (process.env.REACT_APP_CUSTOM_NODE_ENV == 'staging' ? 'STAGING' : 'PRODUCTION'),

        DEV: {
            FRONTEND_BASE_URL: 'http://localhost:3000/',
            BACKEND_BASE_URL: 'http://localhost:1337/',
            REACT_APP_GA: 'UA-96461697-13',
            GOOGLE_CLIENT_ID: '871102823295-5gfbqmn9jkv0okmuh598jllhdr0kdsa0.apps.googleusercontent.com'
        },

        STAGING: {
            FRONTEND_BASE_URL: 'https://pbh.triumphunt.com/',
            BACKEND_BASE_URL: 'https://pbh-bapp.triumphunt.com/',
            REACT_APP_GA: 'UA-96461697-13',
            GOOGLE_CLIENT_ID: '871102823295-5gfbqmn9jkv0okmuh598jllhdr0kdsa0.apps.googleusercontent.com'
        },

        PRODUCTION: {
            FRONTEND_BASE_URL: 'https://app.productivitybyhabit.com/',
            BACKEND_BASE_URL: 'https://bapp.productivitybyhabit.com/',
            REACT_APP_GA: 'UA-96461697-12',
            GOOGLE_CLIENT_ID: '823931712919-k1noadorotpq16tj8a64o7a3rgbqsv23.apps.googleusercontent.com'
        },
    },

    ENLIGHT: {
        FIRST_ENLIGHT_SECTION: 'IIA',
        IIA_REPORT: "IIAReport",
    },
};
