import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import TextareaAutosize from 'react-textarea-autosize';
import ReactSelect from 'react-select';

import MetricsAndTargets from './MetricsAndTargets';
import Util from "../../utils/Util.js";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const statusOptions = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
];

class UpdateOutcomeTemplate extends Component {

	constructor(props) {
		super(props);
        this.state = {
            templateObj: this.props.templateObjToBeUpdated ? _.clone(this.props.templateObjToBeUpdated) : {},
            validationErrors: {},
            showUpdateTemplateModal: false,
            itemToRemove: null,
            showDeleteTemplateModal: false,
        };
    }
    
    componentDidMount() {
        this.setStickyToolBar();
    }

    setStickyToolBar() {

		const header = document.getElementById("update-outcome-template-floating-tool-bar");

		window.addEventListener("scroll", () => {
			
			if (!header) return;

			if (window.pageYOffset > 125) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

    handleSubmit() {

        let {templateObj, validationErrors} = this.state;
        
        let fieldsToBeValidated = ['templateStatus', 'outcomeTitle'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, templateObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };

        templateObj.templateType = 'outcome';
        
        this.props.setLoading(true);

        let postObj = {
            templateObj: templateObj
        };

        API_Services.httpPOST(UrlConstants.URLS.createOrUpdateSystemTemplate, postObj, (err, response) => {

            if (err) {
				
				if (err.response && err.response.data) {
					alert(err.response.data);
				} 
				else {
					alert('Something went wrong!');
				}
			}  
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    if (this.props.handleTemplateUpdates) {
                        this.props.handleTemplateUpdates(response.data.data);
                    };
				}; 
			}
            else {
                alert('Something went wrong!');
            }; 
			this.props.setLoading(false);
		});
    }
    
    validateUserInput(fieldName, response) {

        let validationMessage = 'valid';
        let mandatoryFields = ['templateStatus', 'outcomeTitle'];

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

    handleValueChanges(fieldName, response) {

        let {templateObj, validationErrors} = this.state;
        let stringResponseFields = ['outcomeTitle', 'description'];

        if (stringResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? response.target.value : '';
        }
        else if (fieldName == 'templateStatus') {   
            response = response.value;
        }
        else if (fieldName == 'projects') {
            let currentResponse = templateObj['projects'] || [];   
            response = _.map(response, (item) => { 
                let currentResponseForThisItem = _.find(currentResponse, {sourceCollectionRecordID: item.value});
                return({
                    sourceCollectionRecordID: item.value, 
                    sourceCollection: 'templates',
                    keyActivities: currentResponseForThisItem && currentResponseForThisItem.keyActivities ? currentResponseForThisItem.keyActivities : []
                }); 
            });
        };

        let validationMessage = this.validateUserInput(fieldName, response);
        
        templateObj[fieldName] = response;
        validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

        this.setState({
            templateObj: templateObj,
            validationErrors: validationErrors
        });
    }

    toggleUpdateTemplateModal(templateObj) {

        if (this.state.showUpdateTemplateModal) {
            this.setState({
                templateObj: {},
                validationErrors: {},
                showUpdateTemplateModal: false
            });
        }
        else {
            this.setState({
                templateObj: _.clone(templateObj),
                validationErrors: {},
                showUpdateTemplateModal: true
            });
        };
    }

    handleKeyActivityAssignmentUpdates(projectID, activityID) {

        let { templateObj } = this.state;
        
        if (templateObj && templateObj['projects']) {
            
            let correspondingConfigRecordInd = _.findIndex(templateObj['projects'], {sourceCollectionRecordID: projectID});
            
            if (correspondingConfigRecordInd > -1) {

                let configuredKeyActivities = templateObj['projects'][correspondingConfigRecordInd].keyActivities || [];
                let itemIndex = configuredKeyActivities.indexOf(activityID);

                if (itemIndex > -1) {
                    configuredKeyActivities.splice(itemIndex, 1);
                }
                else {
                    configuredKeyActivities.push(activityID);
                };

                templateObj['projects'][correspondingConfigRecordInd].keyActivities = configuredKeyActivities;
                this.setState({templateObj});
            };
        };
    }

    /* Allow admin to select the keyactivities list from each project(s) that needs to be propagated to this outcome */

    renderKeyActivitySelection(projectTemplates = [], configuredProjectIDs = []) {

        let { templateObj } = this.state;
        let responseJSX = [];

        projectTemplates.map((projectTemplate) => {

            if (configuredProjectIDs.indexOf(projectTemplate.id) > -1 && projectTemplate.keyActivities && projectTemplate.keyActivities.length > 0) {

                let configuredKeyActivities = [];
                
                if (templateObj && templateObj['projects']) {
                    let correspondingConfigRecord = _.find(templateObj['projects'], {sourceCollectionRecordID: projectTemplate.id});
                    configuredKeyActivities = correspondingConfigRecord && correspondingConfigRecord.keyActivities ? correspondingConfigRecord.keyActivities : [];
                };

                responseJSX.push(
                    <div key={'proj_ka_selection' + projectTemplate.id}>
                        <h6 className={responseJSX.length > 0 ? "mt-3" : "mt-1"}>{projectTemplate.projectTitle}</h6>
                        {_.map(projectTemplate.keyActivities, (activityObj) => {
                            return(
                                <div key={'proj_ka_selection_' + projectTemplate.id + '_ka_' + activityObj.id} className="mt-1">
                                    <span className="align-middle cursor-pointer mr-2" onClick={this.handleKeyActivityAssignmentUpdates.bind(this, projectTemplate.id, activityObj.id)}>
                                        {configuredKeyActivities.indexOf(activityObj.id) > -1 ?
                                            <i className="fa fa-check-circle color-primary" aria-hidden="true"></i>
                                        :   <i className="fa fa fa-circle-o color-primary" aria-hidden="true"></i> }
                                    </span>
                                    <span className="align-middle">{activityObj.title}</span>
                                </div>
                            );
                        })}
                    </div>
                );
            };
        });
        return responseJSX.length > 0 ? responseJSX : <small>No key activities found from the above selected project(s).</small>;
    }

	render() {

        let { templateObj, validationErrors } = this.state;
        let { projectTemplates } = this.props;

        let templateOptions = _.map(projectTemplates, (item) => {return ({label: item.projectTitle, value: item.id})});
        let configuredProjectIDs = templateObj['projects'] ? _.map(templateObj['projects'], 'sourceCollectionRecordID') : [];
        let configuredProjects = _.filter(templateOptions, (item) => configuredProjectIDs.indexOf(item.value) > -1);

		return (
			<div className="mt-n3">

                <div id={"update-outcome-template-floating-tool-bar"}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="font-bold"><u>Outcome Template</u></div>
                        <div>
                            <button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.handleSubmit.bind(this)}>Save Template</button>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.handleCancelUpdate()}>Templates Home</button>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Outcome Title <span className="text-danger">*</span></label>
                    <div><input type="text" onChange={this.handleValueChanges.bind(this, 'outcomeTitle')} className="form-control" value={templateObj['outcomeTitle']} /></div>
                    <div className="text-danger small mt-1">{validationErrors['outcomeTitle']}</div>
                </div>

                <div className="form-group">
                    <label>Description</label>
                    <TextareaAutosize
                        onChange={this.handleValueChanges.bind(this, 'description')}
                        className="form-control"
                        minRows={2}
                        maxRows={10}
                        name="description"
                        value={templateObj['description']}>
                    </TextareaAutosize>
                    <div className="text-danger small mt-1">{validationErrors['description']}</div>
                </div>
                
                <MetricsAndTargets
                    source={'productivity-outcomes'} 
					metricsAndTargets={templateObj['metricsAndTargets']} 
					onChange={this.handleValueChanges.bind(this, 'metricsAndTargets')} />

                <div className="form-group">
                    <label>Projects</label>
                    <ReactSelect
                        styles={SharedStyles.selectBoxStyles}
                        options={templateOptions}
                        isMulti={true}
                        value={configuredProjects}
                        isClearable={true}
                        onChange={this.handleValueChanges.bind(this, 'projects')}
                        noOptionsMessage={() => 'No Project Templates added'} />
                    <div className="text-danger small mt-1">{validationErrors['projects']}</div>
                </div>

                {configuredProjects.length > 0 ?
                    <div className="form-group">
                        <label>Key Activities Assignment</label>
                        <div className="htmlEditor">{this.renderKeyActivitySelection(projectTemplates, configuredProjectIDs)}</div>
                    </div>
                : null}

                <div className="form-group">
                    <label>Template Status <span className="text-danger">*</span></label>
                    <ReactSelect
                        styles={SharedStyles.selectBoxStyles}
                        options={statusOptions}
                        value={_.find(statusOptions, {value: templateObj['templateStatus']})}
                        onChange={this.handleValueChanges.bind(this, 'templateStatus')} />
                    <div className="text-danger small mt-1">{validationErrors['templateStatus']}</div>
                </div>
                
                <div style={{height:50}} /> {/* Temporary fix for scroll flickering issue. */}
                
			</div>
		);
	}
}

UpdateOutcomeTemplate.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	UpdateOutcomeTemplate
);
