import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TimeIntervalPastPerformanceSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			timeIntervalsPastPerformance: [],
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		var postObj = {
			"category": "DefaultTimeIntervals"
		};

		API_Services.httpPOST(UrlConstants.URLS.getPlatformConstants, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					if (response.data.data && response.data.data.value && response.data.data.value.length > 0) {

						let timeIntervalsPastPerformance = [];

						response.data.data.value.forEach((value, index) => {
							timeIntervalsPastPerformance.push({
								label: value.label,
								value: value.value,
							});
						})
						this.setState({
							timeIntervalsPastPerformance: timeIntervalsPastPerformance,
						});
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Handler for timeInterval change event
	handleTimeIntervalSelect(name, value) {
		this.props.handleTimeIntervalSelect(name, value);
	}

	render() {

		const { timeIntervalsPastPerformance, formApiResponse } = this.state;

		var timeIntervalOptions = [];

		if (timeIntervalsPastPerformance.length > 0) {
			timeIntervalOptions = timeIntervalsPastPerformance.map(e => ({ value: e.value, label: e.label }));
		}

		return (
			<div className="form-group">
				<label>Interval (Past Performance){this.props.isRequired ? <span className="text-danger">*</span> : null }</label>
				<div>
					<ReactSelect
						name="timeIntervalsPastPerformance"
						styles={SharedStyles.selectBoxStyles}
						closeOnSelect={false}
						options={timeIntervalOptions}
						value={timeIntervalOptions.find(option => option.value === this.props.value)}
						removeSelected={true}
						autosize={true}
						isClearable={true}
						onSelectResetsInput={true}
						onChange={this.handleTimeIntervalSelect.bind(this, 'intervalPastPerformance')}
						isDisabled={this.props.disabled}
					/>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</div>
			</div>
		);
	}
}

TimeIntervalPastPerformanceSelect.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	TimeIntervalPastPerformanceSelect
);
