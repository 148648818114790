import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getFocusAreaByID } from "../../actions/focusAreaActions";
import Util from '../../utils/Util';
import EditFocusAreaForm from "./EditFocusAreaForm";

class EditFocusArea extends Component {

	componentDidMount() {
		const { focusAreaId } = this.props.match.params;
		this.props.getFocusAreaByID(focusAreaId)
	}

	render() {

		const { focusarearesponse } = this.props.focusareas;

		if (!Util.isEmpty(focusarearesponse)) {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="bread-crumb">
						<a href="/focus-areas/">Focus Areas</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Edit</a>
					</div>
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<EditFocusAreaForm
										initialValues={focusarearesponse}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<p>Loading...</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

EditFocusArea.propTypes = {
	getFocusAreaByID: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	focusareas: state.focusareas,
});

export default connect(mapStateToProps, { getFocusAreaByID })(
	EditFocusArea
);
