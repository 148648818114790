import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Dropdown,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import { getUserSubscriptions } from "../../actions/subscriptionLockActions";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class NavBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			prepareDropdownOpen: false,
			assessmentsDropdownOpen: false,
			manageDropdownOpen: false,
			doDropdownOpen: false,
			userSubscriptions: [],
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		const { isAuthenticated } = this.props.auth;

		if (isAuthenticated) {

			this.props.getUserSubscriptions();return;

			this.props.setLoading(true);

			let postObj = {};

			API_Services.httpPOST(UrlConstants.URLS.getUserSubscriptions, postObj, (err, response) => {

				if (err) {
					
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} 
					else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} 
				else if (response.data) {

					if (response.data.status) {

						this.setState({
							userSubscriptions: response.data.data,
						});
					} 
					else {
						this.setFormApiResponse(response.data.message, true);
					}
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
				};

				this.props.setLoading(false);
			});
		};
	}

	// NavBar toggle for mobile
	toggle() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	}

	closeNavbar() {
		if (this.state.isOpen) {
			this.toggle();
		}
	}

	navigate(link) {
		this.props.history.replace(link)
	}

	dropdownToggle(menu) {

		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			
			if (menu == "prepare") {
				this.setState(prevState => ({
					prepareDropdownOpen: !prevState.prepareDropdownOpen
				}));
			};
			if (menu == "assessments") {
				this.setState(prevState => ({
					assessmentsDropdownOpen: !prevState.assessmentsDropdownOpen,
				}));
			};
			if (menu == "manage") {
				this.setState(prevState => ({
					manageDropdownOpen: !prevState.manageDropdownOpen
				}));
			};
			if (menu == "do") {
				this.setState(prevState => ({
					doDropdownOpen: !prevState.doDropdownOpen
				}));
			};
		} else {

			if (menu == "prepare") {
				this.setState(prevState => ({
					prepareDropdownOpen: !prevState.prepareDropdownOpen
				}));
			};
			if (menu == "assessments") {
				this.setState(prevState => ({
					assessmentsDropdownOpen: !prevState.assessmentsDropdownOpen,
					prepareDropdownOpen: !prevState.prepareDropdownOpen
				}));
			};
			if (menu == "manage") {
				this.setState(prevState => ({
					manageDropdownOpen: !prevState.manageDropdownOpen
				}));
			};
			if (menu == "do") {
				this.setState(prevState => ({
					doDropdownOpen: !prevState.doDropdownOpen
				}));
			};
		}
	}

	onMouseEnter(menu) {

		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			return;
		}

		if (menu == "prepare") {
			this.setState({ prepareDropdownOpen: true });
		};
		if (menu == "assessments") {
			this.setState({ assessmentsDropdownOpen: true });
		};
		if (menu == "manage") {
			this.setState({ manageDropdownOpen: true });
		};
		if (menu == "do") {
			this.setState({ doDropdownOpen: true });
		};
	}

	onMouseLeave(menu) {

		var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			return;
		}

		if (menu == "prepare") {
			this.setState({ prepareDropdownOpen: false });
		};
		if (menu == "assessments") {
			this.setState({ assessmentsDropdownOpen: false });
		};
		if (menu == "manage") {
			this.setState({ manageDropdownOpen: false });
		};
		if (menu == "do") {
			this.setState({ doDropdownOpen: false });
		};
	}

	// Render private links
	renderNavbarAuthLinks() {


		let userSubscriptions = this.props.usersubscriptions.usersubscriptions;

		if(userSubscriptions && userSubscriptions.length > 0) {

			let moduleWiseLock = {};

			userSubscriptions.forEach((value, index) => {

				let moduleName = value.value;
				moduleWiseLock[moduleName] = value.isSubscribed;
			});
			
			return (
				<Nav className="ml-auto" navbar>
	
					<Dropdown className="nav-item" onMouseOver={this.onMouseEnter.bind(this, "prepare")} onMouseLeave={this.onMouseLeave.bind(this, "prepare")} isOpen={this.state.prepareDropdownOpen} toggle={this.dropdownToggle.bind(this, "prepare")}>
						<DropdownToggle nav caret>Prepare</DropdownToggle>
						<DropdownMenu>
							<Dropdown className="nav-item dropdown-item" onMouseOver={this.onMouseEnter.bind(this, "assessments")} onMouseLeave={this.onMouseLeave.bind(this, "assessments")} isOpen={this.state.assessmentsDropdownOpen} toggle={this.dropdownToggle.bind(this, "assessments")}>
								<DropdownToggle nav caret>Assessments</DropdownToggle>
								<DropdownMenu style={{position:'absolute', left:135}}>
									<DropdownItem>
										<NavItem>
											{moduleWiseLock['productivity_meter'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
											<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} to="/assessments/productivity-meter" activeClassName="active">Productivity Meter</NavLink>
										</NavItem>
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem>
										<NavItem>
											{moduleWiseLock['inner_insight'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
											<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} to="/assessments/inner-insight" activeClassName="active">Inner Insight</NavLink>
										</NavItem>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['compass'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/compass/" activeClassName="active">Compass</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['guiding_stars'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/guiding-stars/" activeClassName="active">Guiding Stars</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['productivity_planner'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/productivity-planner/" activeClassName="active">Productivity Planner</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['outcomes'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/projects/" activeClassName="active">Projects</NavLink>
								</NavItem>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
	
					<Dropdown className="nav-item" onMouseOver={this.onMouseEnter.bind(this, "manage")} onMouseLeave={this.onMouseLeave.bind(this, "manage")} isOpen={this.state.manageDropdownOpen} toggle={this.dropdownToggle.bind(this, "manage")}>
						<DropdownToggle nav caret>Manage</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['available_time'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/available-time/" activeClassName="active">Available Time</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['time_commitment'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/time-commitment/" activeClassName="active">Time Commitment</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['weekly_plan'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/weekly-plan/" activeClassName="active">Weekly Plan</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['tracker_report'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/tracker-report/" activeClassName="active">Tracker Report</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['daily_review'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/daily-review/" activeClassName="active">Daily Review</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['weekly_review'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/weekly-review/" activeClassName="active">Weekly Review</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['monthly_review'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/monthly-review/" activeClassName="active">Monthly Review</NavLink>
								</NavItem>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
	
					<Dropdown className="nav-item" onMouseOver={this.onMouseEnter.bind(this, "do")} onMouseLeave={this.onMouseLeave.bind(this, "do")} isOpen={this.state.doDropdownOpen} toggle={this.dropdownToggle.bind(this, "do")}>
						<DropdownToggle nav caret>Do</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['tracker'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/calendar/" activeClassName="active">Calendar</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['daily_tracker'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/tracker/d/" activeClassName="active">Daily Tracker</NavLink>
								</NavItem>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<NavItem>
									{moduleWiseLock['universal_tasks'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
									<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/tasks/" activeClassName="active">Tasks</NavLink>
								</NavItem>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
	
					<NavItem>
						{moduleWiseLock['notes'] ? null : <i className="fa fa-lock color-primary ml-1 mr-2">&nbsp;</i>}
						<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/notes/" activeClassName="active">Notes</NavLink>
					</NavItem>
					<NavItem>
						<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/profile/" activeClassName="active"><i className="fa fa-user-circle fa-lg" aria-hidden="true"></i></NavLink>
					</NavItem>
				</Nav>
			);
		} else {
			return(<div></div>)
		}
	}

	// Render public/guests links
	renderNavbarGuestLinks() {

		return (
			<Nav className="ml-auto" navbar>
				<NavItem>
					<NavLink onClick={this.closeNavbar.bind(this)} tag={RRNavLink} exact to="/login" activeClassName="active">Login</NavLink>
				</NavItem>
			</Nav>
		);
	}

	render() {

		const { isAuthenticated, user } = this.props.auth;

		return (
			<div className="navbar-wrapper">
				<Navbar expand="md" className="container">
					<NavbarBrand>
						<a href='https://www.productivitybyhabit.com/'>
							<div className="navbar-header">
								<div style={{ float: 'left' }}><img src="/images/PbH_logo.png" alt="Triumph" className="header-logo" /></div>
								<div className="logo-caption">
									<div className="header-text">Productivity By Habit</div>
									<div className="logo-tagline">Be in control. Achieve more with ease.</div>
								</div>
							</div>
						</a>
					</NavbarBrand>
					<NavbarToggler onClick={this.toggle.bind(this)} />
					<Collapse isOpen={this.state.isOpen} navbar>
						{isAuthenticated ? this.renderNavbarAuthLinks() : this.renderNavbarGuestLinks()}
					</Collapse>
				</Navbar>
			</div>
		);
	}
}

NavBar.propTypes = {
	getUserSubscriptions: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	usersubscriptions: state.usersubscriptions,
});

export default connect(mapStateToProps, { getUserSubscriptions, setLoading })(
	NavBar
);