import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import TimeScope from "../form-inputs/TimeScope";

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";

import API_Services from '../../utils/API_Services';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class MetricForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				description: "",
				dataType: {
					label: "Numerical",
					value: "numerical",
				},
				targetScope: "",
				targetValue: "",
				pastPerformance: "",
			},
			formErrors: {
				title: null,
				description: null,
				dataType: null,
				targetScope: null,
				targetValue: null,
				pastPerformance: null,
			},
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		const { metricObj } = this.props;

		if (!Util.isEmpty(metricObj)) {

			var formObj = { ...metricObj };

			if(!_.has(formObj, 'dataType')) {
				formObj['dataType'] = {
					label: "Numerical",
					value: "numerical",
				};
			}

			this.setState({
				form: formObj,
				isEdit: true,
			});
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == 'targetValue') {
			if (value && !Util.validateNumber(value)) {
				var formErrorsObj = { ...formErrors, [name]: "Please enter Numeric Value" };
				this.setState({ formErrors: formErrorsObj });
				return;
			}
		}

		if (name == 'pastPerformance') {
			if (value && !Util.validateNumber(value)) {
				var formErrorsObj = { ...formErrors, [name]: "Please enter Numeric Value" };
				this.setState({ formErrors: formErrorsObj });
				return;
			}
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};

		if (!value) {
			formObj = {
				...form,
				[name]: "",
			};
			this.setState({
				form: formObj,
			})
			return;
		}

		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		const { isEdit, form } = this.state;
		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Title";
				break;

			case "dataType":
				if (!value) errorMsg = "Please select Data Type";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for submit
	handleSubmit = (e, nextSection) => {

		e.preventDefault();

		const { form, formErrors, isEdit } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		}
		else {

			if (form['targetScope']) {

				let formErrorsObj = { ...formErrors };
				let hasError = false;

				if (!form['targetValue']) {
					formErrorsObj = { ...formErrorsObj, "targetValue": "Please enter Target Value" };
					hasError = true;
				}
				// if (!form['pastPerformance']) {
				// 	formErrorsObj = { ...formErrors, "pastPerformance": "Please enter Past Performance" };
				// 	hasError = true;
				// }

				if(hasError) {
					this.setState({ formErrors: formErrorsObj });
					return;
				}
			}

			if (form['targetValue']) {

				let formErrorsObj = { ...formErrors };
				let hasError = false;

				if (_.isEmpty(form['targetScope'])) {
					formErrorsObj = { ...formErrorsObj, "targetScope": "Please enter Target Scope" };
					hasError = true;
				}
				// if (!form['pastPerformance']) {
				// 	formErrorsObj = { ...formErrorsObj, "pastPerformance": "Please enter Past Performance" };
				// 	hasError = true;
				// }
				
				if(hasError) {
					this.setState({ formErrors: formErrorsObj });
					return;
				}
			}

			// if (form['pastPerformance']) {

			// 	let formErrorsObj = { ...formErrors };
			// 	let hasError = false;

			// 	if (_.isEmpty(form['targetScope'])) {
			// 		formErrorsObj = { ...formErrorsObj, "targetScope": "Please enter Target Scope" };
			// 		hasError = true;
			// 	}
			// 	if (!form['targetValue']) {
			// 		formErrorsObj = { ...formErrorsObj, "targetValue": "Please enter Target Value" };
			// 		hasError = true;
			// 	}
				
			// 	if(hasError) {
			// 		this.setState({ formErrors: formErrorsObj });
			// 		return;
			// 	}
			// }

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {
				if (Array.isArray(value)) {
				}
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			this.createOrUpdateMetric(formCopy, nextSection || "");
		}
	}

	createOrUpdateMetric(data, nextSection) {

		if (nextSection) {

		}

		if (this.state.isEdit) {
			this.props.editMetricInList(data, nextSection);
		} else {
			data['id'] = Util.generateObjectId();
			this.props.addMetricInList(data, nextSection);
		}
	}

	renderAddOrEditKAMetric() {

		const { form, formErrors, isEdit } = this.state;

		// Disable title, description, targetscope if inherited metrics 
		let isInherited = false;
		if (isEdit) {

			if (form['isInheritedFromOutcome'] || form['isInheritedFromProject']) {
				isInherited = true;
			}
		}

		/**
		 * Scale is not for v1
		 * {
				label: "Scale",
				value: "scale",
			}
		 */
		var dataTypeOptions = [
			{
				label: "Numerical",
				value: "numerical",
			},
		];

		return (
			<div>
				<ModalHeader toggle={this.props.onClose}>{isEdit ? "Edit" : "Add"} Metric</ModalHeader>
				<ModalBody>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								{
									!isInherited
										?
										<div className="form-group">
											<label>Title <span className="text-danger">*</span></label>
											<div>
												<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
												<p className="text-danger">{formErrors['title'] &&
													<span className="err">{formErrors['title']}</span>}</p>
											</div>
										</div>
										: null
								}
								{
									!isInherited
										?
										<div className="form-group">
											<label>Description</label>
											<div>
												<TextareaAutosize
													onChange={this.handleInputField.bind(this)}
													className="form-control"
													minRows={1}
													maxRows={10}
													name="description"
													value={form['description']} />
												<p className="text-danger">{formErrors['description'] &&
													<span className="err">{formErrors['description']}</span>}</p>
											</div>
										</div>
										: null
								}

								{/* Since we're only supporting numeral datatype for now, we do NOT need to have the user select that from the drop down when creating/editing a metric. Let'd default to that without bringing it up to the user. */}
								{/* <div className="form-group">
							<label>Data Type <span className="text-danger">*</span></label>
							<div>
								<ReactSelect
									name="dataType"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={dataTypeOptions}
									value={dataTypeOptions.find(option => option.value === form['dataType'].value)}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleSelectField.bind(this, 'dataType')}
									isDisabled={this.props.disabled}
								/>
								<p className="text-danger">{formErrors['dataType'] &&
									<span className="err">{formErrors['dataType']}</span>}</p>
							</div>
						</div> */}
								<div className="form-group">
									<label>Past Performance</label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="pastPerformance" value={form['pastPerformance']} />
										<p className="text-danger">{formErrors['pastPerformance'] &&
											<span className="err">{formErrors['pastPerformance']}</span>}</p>
									</div>
								</div>
								<div>
									<label>Target</label>
									<div className="row">
										<div className="col-lg-6">
											<TimeScope
												handleSelectField={(label, selectedTimeScope) => this.handleSelectField('targetScope', selectedTimeScope)}
												value={form.targetScope}
												error={formErrors.targetScope}
												placeholder={'Target Scope'}
												isRequired={false}
												source="outcomes"
											/>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" placeholder="Target Value" name="targetValue" value={form['targetValue']} style={{ "minHeight": "42px" }} />
												<p className="text-danger">{formErrors['targetValue'] &&
													<span className="err">{formErrors['targetValue']}</span>}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="row">
									<div className="col-sm-4">
										<button type="button" onClick={(e) => this.handleSubmit(e)} className="btn btn-primary btn-sm">Save & Close</button>
									</div>
									<div className="col-sm-8">
										<div className="text-right">
											<Button color="secondary" title="Cancel" onClick={this.props.onCancel} className="btn-sm">Cancel</Button>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="small text-info mt-3">* All metrics are numerical.</div>
										{/* <div className="mt-2"><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
									</div>
								</div>
							</div>
						</div>
					</form>
				</ModalBody>
			</div>
		);
	}

	// Render

	render() {

		return (
			<div>
				{this.renderAddOrEditKAMetric()}
			</div>
		);
	}

}

MetricForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	MetricForm
);
