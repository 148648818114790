import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import AddUserProjectMetric from "./AddUserProjectMetric";
import EditUserProjectMetric from "./EditUserProjectMetric";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ProjectsMetrics extends Component {

	constructor(props) {
		super(props);
		this.state = {
			metricsAndTargets: [],
			userNotification: null,
			notificationMessage: null,
			addProjectMetricModal: false,
		};
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	componentDidMount() {
		if(this.props.outcomeId) {
			this.getUserProjectMetrics();
		}
	}

	getUserProjectMetrics() {

		this.props.setLoading(true);

		var postObj = {
			outcomeID: this.props.outcomeId.outcomeId,
			isSubscriptionOptional: true
		};

		API_Services.httpPOST(UrlConstants.URLS.getUserProjectMetrics, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.status == 402) {
					this.setState({
						userNotification: 'subscription_guidline'
					});
				}
                else if (err.response && err.response.data) {
					this.setState({
						notificationMessage: err.response.data
					});
				} 
                else {
					this.setState({
						notificationMessage: "Something went wrong!"
					});
				};
			} 
            else if (response && response.data) {

				if (response.data.status && response.data.data) {
					this.setState({
						metricsAndTargets: response.data.data
					});
				};
			};

			this.props.setLoading(false);
		});
	}

	addProjectMetricInList(newProjectMetric) {

		let { metricsAndTargets } = this.state;
		
		metricsAndTargets.push(newProjectMetric);

		this.setState({metricsAndTargets: metricsAndTargets});
		this.toggleAddProjectMetricModal();
	}

	toggleAddProjectMetricModal() {

		if (this.state.addProjectMetricModal) {
			this.setState({
				addProjectMetricModal: false,
			});
		} 
		else {
			this.setState({
				addProjectMetricModal: true,
			});
		}
	}

	renderAddUserProjectMetricInModal() {

		if(this.props.outcomeId) {

			return (
				<div>
					<Modal isOpen={this.state.addProjectMetricModal} toggle={this.toggleAddProjectMetricModal.bind(this)}>
						<ModalHeader toggle={this.toggleAddProjectMetricModal.bind(this)}>Add Project Metric</ModalHeader>
						<ModalBody>
							<AddUserProjectMetric
								addProjectMetricInList={this.addProjectMetricInList.bind(this)}
								outcomeID={this.props.outcomeId.outcomeId} />
						</ModalBody>
					</Modal>
				</div>
			)
		} else {
			return;
		}
	}

	// update the list with the edited data
	updateEditedProjectMetricInList(data) {

		let metricsAndTargets = [...this.state.metricsAndTargets];

		// Find item index using _.findIndex
		var index = _.findIndex(metricsAndTargets, {id: data.id});

		// Replace item at index using native splice
		metricsAndTargets.splice(index, 1, data);

		this.setState({
			editProjectMetricModal: false,
			metricsAndTargets: metricsAndTargets,
		});
	}

	toggleEditProjectMetricModal(rowId) {

		if (this.state.editProjectMetricModal) {
			this.setState({
				editProjectMetricModal: false,
				itemToEdit: null,
			});
		} 
		else {
			this.setState({
				editProjectMetricModal: true,
				itemToEdit: rowId,
			});
		}
	}

	renderEditUserProjectMetricInModal() {

		if(this.props.outcomeId) {

			return (
				<div>
					<Modal isOpen={this.state.editProjectMetricModal} toggle={this.toggleEditProjectMetricModal.bind(this)}>
						<ModalHeader toggle={this.toggleEditProjectMetricModal.bind(this)}>Edit Project Metric</ModalHeader>
						<ModalBody>
							<EditUserProjectMetric
								updateEditedProjectMetricInList={this.updateEditedProjectMetricInList.bind(this)}
								projectMetricObj={this.state.itemToEdit}
								outcomeID={this.props.outcomeId.outcomeId} />
						</ModalBody>
					</Modal>
				</div>
			)
		} else {
			return;
		}
	}

	// Handler for removing KA Metric
	// Add API here so we can handle state even better
	handleProjectMetricDelete() {

		var { metricsAndTargets, itemToRemove } = this.state;

		if (itemToRemove && itemToRemove.id) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove.id,
			}

			API_Services.httpPOST(UrlConstants.URLS.deleteUserProjectMetric, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} else if (response.data) {

					if (response.data.status) {

						metricsAndTargets.forEach((eachKAMeric, index) => {

							if (eachKAMeric.id == itemToRemove.id) {
								metricsAndTargets.splice(index, 1);
							}
						})

						this.setState({
							deleteProjectMetricModal: false,
							itemToRemove: null,
							metricsAndTargets: metricsAndTargets,
						}, () => {
							this.props.setLoading(false);
						});
					} else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}
	}

	toggleDeleteProjectMetricModal(rowId) {

		if (this.state.deleteProjectMetricModal) {
			this.setState({
				deleteProjectMetricModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				deleteProjectMetricModal: true,
				itemToRemove: rowId,
			});
		}
	}

	renderDeleteUserProjectMetricInModal() {

		var tbObj = {};

		if(this.state.itemToRemove && this.state.itemToRemove.id) {
			tbObj = _.find(this.state.metricsAndTargets, { id: this.state.itemToRemove.id });
		}

		var tbTitle = tbObj ? tbObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteProjectMetricModal} toggle={this.toggleDeleteProjectMetricModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteProjectMetricModal.bind(this)}>Delete KA Metric</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the Project Metric <span className="text-warning">{tbTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleProjectMetricDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteProjectMetricModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	// Renders metrics & targets in table
	renderMetricsTable() {

		const { metricsAndTargets, userNotification, notificationMessage } = this.state;

		if (userNotification == "subscription_guidline") {
			return (
				<div className="small">Do you have a valid activation code for unlocking Metrics & Targets? Please enter it <a href='/subscription?module=metrics_and_targets' className='color-primary'>here</a>.</div>
			);
		}
		else if (notificationMessage) {
			return (
				<div className='small text-info'>{notificationMessage}</div>
			);
		}
		else if (!this.props.isEdit) {
			return (
				<div className="small text-info">Please save your project before adding metrics & targets.</div>
			);
		}
		else if (metricsAndTargets.length == 0) {
			return (
				<div className="small text-info">No metrics & targets added.</div>
			);
		};

		return (
			<div>
				<div className="App" style={{ marginTop: 20 }}>
					<header className="App-header table-responsive">
						<table className="table-curved">
							<thead>
								<tr>
									<th className="text-center" style={{ width: 50 }}>#</th>
									<th className="text-left" style={{ width: 500 }}>Title</th>
									<th className="text-center" style={{ width: 200 }}>Target</th>
									<th className="text-center" style={{ width: 100 }} colSpan={2}>Actions</th>
								</tr>
							</thead>
							<tbody>
								{metricsAndTargets.map((value, index) => {
									return (
										<tr key={value.id}>
											<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
											<td className="text-left" style={{ width: 500 }}>{value.title}</td>
											<td className="text-center" style={{ width: 200 }}>{(value.targetScope && value.targetValue) ? value.targetValue + ' (' + value.targetScope + ')' : '(not set)'}</td>
											<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleEditProjectMetricModal.bind(this, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
											<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteProjectMetricModal.bind(this, value)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</header>
				</div>
			</div>
		);
	}

	render() {

		const { userNotification } = this.state;

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					<div className="row">
						<div className="col-6"><label>Metrics & Targets</label></div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-xs ml-3" onClick={this.toggleAddProjectMetricModal.bind(this)} disabled={!this.props.isEdit || userNotification == 'subscription_guidline' ? true : false}>
								<i className="fa fa-plus" aria-hidden="true"></i>
							</button>
						</div>
					</div>
					{this.renderMetricsTable()}
					{this.renderAddUserProjectMetricInModal()}
					{this.renderEditUserProjectMetricInModal()}
					{this.renderDeleteUserProjectMetricInModal()}
				</div>
			</div>
		);
	}
}

ProjectsMetrics.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProjectsMetrics
);
