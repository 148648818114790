import axios from "axios";
import { toast } from "react-toastify";
import {
	GET_ERRORS,
	CLEAR_ERRORS,
	LOADING,
	RESET_KEY_ACTIVITY,
	GET_KEY_ACTIVITY,
	GET_KEY_ACTIVITIES,
	DELETE_KEY_ACTIVITY,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetKeyActivityData = () => dispatch => {
	dispatch({
		type: RESET_KEY_ACTIVITY,
	})
}

// Get Key activities
export const getUserKeyActivities = (response) => dispatch => {

	dispatch({
		type: GET_KEY_ACTIVITIES,
		payload: response.data.data
	})
};

// Get a Key activity
export const getKeyActivityByID = (keyActivityId) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getKeyActivityByID;

	var postObj = {
		recordID: keyActivityId,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				/* In-order to keep productivity outcome and its projects synced, the projects & key activities that are part of productivity outcomes are restricted from user edit using the standalone module. */
				if (response.data.data && response.data.data.productivityOutcome) {
					let navURL = `/productivity-planner/outcomes/edit/${response.data.data.productivityOutcome}`;
					return window.location = navURL;
				}
				else {
					dispatch({
						type: GET_KEY_ACTIVITY,
						payload: response.data.data
					})
				};
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};