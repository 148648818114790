import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";
import Util from '../../utils/Util';
import SharedStyles from '../../assets/styles/SharedStyles';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import ReactTooltip from 'react-tooltip';
import API_Services from '../../utils/API_Services';
import IntervalOptions from '../weekly-plan/IntervalOptions';
import AddOutcome from "./AddOutcome"
import AddKeyActivity from "./AddKeyActivity.js";
import TaskManagement from "./TaskManagement";
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class DetailedInfoPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			durationInMinutesOptions: [],
			deleteModal: false,
		}

		this.getAvailableDurationOptions();
	}

	getAvailableDurationOptions() {

		var postObj = {
			"category": "DefaultTimeEstimates"
		};

		API_Services.httpPOST(UrlConstants.URLS.getPlatformConstants, postObj, (err, response) => {

			if (response && response.data) {

				if (response.data.status) {

					if (response.data.data && response.data.data.value && response.data.data.value.length > 0) {

						let durationInMinutesOptions = [];

						response.data.data.value.forEach((value, index) => {
							durationInMinutesOptions.push({
								label: value.label,
								value: value.value,
							});
						})

						this.setState({
							durationInMinutesOptions: durationInMinutesOptions,
						});
					}
				}
			}
		});
	}

	render () {
		const { showDetailedInfoModal } = this.props;
		return (
			<div>
				<Modal
					size={'lg'}
					isOpen={showDetailedInfoModal} 
					toggle={this.props.toggleDetailedInfoModal}>
					{ !showDetailedInfoModal? null : <TBInfo {...this.props} durationInMinutesOptions={this.state.durationInMinutesOptions} />
					}
				</Modal>
			</div>
		)
	}

}

class TBInfo extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isEdit: false,
			isCompleted: false,
			notesEditorState: EditorState.createEmpty(),
			detailedInfo: {},
			showAssignForm: false,
			form: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
			},
			formErrors: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
			},
			focusAreas: [],
			focusAreasOptions: [],
			outcomesWithPopulatedData: [],
			outcomesOptions: [],
			keyActivityOptions: [],
			utasks: [],
			displaySubSection: "",
		};
	}

	resetForm() {

		var formObj = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
		};

		var formErrors = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
			outcomesOptions: [],
			keyActivityOptions: [],
		});
	}

	componentDidMount() {

		const { detailedInfo, focusAreas, outcomesWithPopulatedData, utasks } = this.props;

		if (detailedInfo) {
			detailedInfo.metricsAndTargets = Util.sortMetricsBasedOnHierarchy(detailedInfo.metricsAndTargets);
		};

		this.setState({
			isCompleted: detailedInfo && detailedInfo.isCompleted? true : false,
			notesEditorState: detailedInfo && detailedInfo.notes ? EditorState.createWithContent(convertFromRaw(detailedInfo.notes)) : EditorState.createEmpty(),
			detailedInfo: detailedInfo,
			focusAreas: focusAreas,
			outcomesWithPopulatedData: outcomesWithPopulatedData,
			utasks: utasks
		});

		this.yourFocusAreas(focusAreas);

		this.yourOutcomes(outcomesWithPopulatedData);
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		// If both dates are not same then new week is called
		if (!_.isEqual(prevProps.outcomesWithPopulatedData, this.props.outcomesWithPopulatedData)) {

			this.setState({
				outcomesWithPopulatedData: this.props.outcomesWithPopulatedData,
			});

			this.yourOutcomes(this.props.outcomesWithPopulatedData);
		};

		if (!_.isEqual(prevProps.utasks, this.props.utasks)) {
			this.setState({utasks: this.props.utasks});
		};
	}

	getOutcomesWithPopulatedData() {

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						outcomesWithPopulatedData: response.data.data,
					});
					this.yourOutcomes(response.data.data);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	// Set dropdown options for focus areas
	yourFocusAreas(focusAreas) {

		if (focusAreas && focusAreas.length > 0) {

			let focusAreasOptions = [];

			// Default select all focusAreas
			// const { form } = this.state;
			// var formObj = {};
			// formObj = {
			// 	...form,
			// 	focusArea: {
			// 		label: "All",
			// 		value: "all",
			// 	},
			// };

			focusAreasOptions = Util.focusAreaDropdownOptions(focusAreas);

			this.setState({
				// form: formObj,
				focusAreasOptions: focusAreasOptions,
				buttonDisabled: false,
			});
		}
	}

	// Set dropdown options for outcome
	yourOutcomes(outcomes) {

		if (outcomes && outcomes.length > 0) {

			let outcomesOptions = [];

			// Default select none outcomes
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				outcome: null,
			};

			let filteredoutcomesOptions = [];

			if(form["focusArea"] && form["focusArea"].value && form["focusArea"].value != 'all') {
				_.forEach(outcomes, function (eachOutcome) {
					if(eachOutcome.focusArea.id ===  form["focusArea"].value) {
						filteredoutcomesOptions.push(eachOutcome);
					}
				});
			}

			filteredoutcomesOptions.forEach((eachOutcome) => {
				outcomesOptions.push({
					label: eachOutcome.outcomeTitle,
					value: eachOutcome.id,
					focusAreaID: eachOutcome.focusArea.id
				});
			});

			if(form["focusArea"] && form["focusArea"].value) {
				outcomesOptions.push({
					label: "Add New Project",
					value: "ADD_NEW_OUTCOME",
				})
			}

			this.setState({
				form: formObj,
				outcomesOptions: outcomesOptions,
				buttonDisabled: false,
			});

		}
	}

	onDeleteClick = () => {
		this.props.deleteEvent(this.props.detailedInfo);
	}

	onSaveClick = (e) => {
		let notesContent = this.state.notesEditorState && this.state.notesEditorState.getCurrentContent().hasText() ? convertToRaw(this.state.notesEditorState.getCurrentContent()) : null;
		this.props.updateEvent(e, this.props.detailedInfo, this.state.detailedInfo, notesContent, this.state.isCompleted);
	}

	renderDeleteButton = () => {
		return (
			<React.Fragment>
			{
			!this.props.showMore? null : <Button color="danger" title="Delete" onClick={this.toggleDeleteModal.bind(this)} className="btn-md btn-sm mr-2">Remove From Plan</Button>
			}
			</React.Fragment>
		)
	}

	renderSaveButton = () => {
		return (
			<React.Fragment> {
			!this.props.showMore? null : <Button color="primary" title="Save" onClick={this.onSaveClick} className="btn-md btn-sm mr-2">Save</Button>
			}
			</React.Fragment>
		)
	}

	toggleEditMode = () => {
		this.setState({
			isEdit: !this.state.isEdit
		})
	}

	toggleCompletedTBCheckbox = () => {

		this.setState({
			isCompleted: !this.state.isCompleted
		})
	}

	renderEventStatusBar = (showMore, detailedInfo) => {

		if (!showMore || !detailedInfo) {
			return <div></div>
		}

		var day = moment(detailedInfo.start).format('ddd'); // get day
		var startTime = moment(detailedInfo.start).format('LT'); // get time
		var endTime = moment(detailedInfo.end).format('LT'); // get time
		
		var dt = day + ", " + moment(detailedInfo.start).format('ll');

		return (
			<div>
				<div className="space-between-section popup-status-bar">
					<label>
						<i className="fa fa-calendar-check-o active" aria-hidden="true"></i>
						<span className="light-title-text">  {dt} </span>
					</label>

					<label>
						<i className="fa fa-clock-o" aria-hidden="true"></i>
						<span className="light-title-text">   {startTime + " - " + endTime}  </span>
					</label>

				</div>
			</div>
		)
	}

	renderAdHocTimeTrackingStatus = (showMore, detailedInfo) => {

		if (!showMore || !detailedInfo || !detailedInfo.isAdHocTimeTrackerUnPlannedEvent) {
			return <div></div>
		}

		var notificationAt = moment(detailedInfo.start).add(detailedInfo.setTimerInMinutes, 'minutes'); // get day

		var currentTime = moment();
		var totalSpent = 0, totalSpentInPercent = 0, timer = 0, timerSpentInPercent = 0;
		var animateBell = '';

		var showStopAdHocTimeTrackerBtn = detailedInfo.stopAdHocTimeTracker? false : true; // If the user stoped the tracking

		if (currentTime < moment(notificationAt)) { // calculate remaining time for notification
			timer = currentTime.diff(moment(detailedInfo.start), 'minutes');
			timerSpentInPercent = Math.max(0, Math.min(100, 100 * (timer / detailedInfo.setTimerInMinutes)));
		}
		else {
			animateBell = 'animate-bell';
			timerSpentInPercent = 100;
		}

		if (currentTime < moment(detailedInfo.end)) {
			totalSpent = currentTime.diff(moment(detailedInfo.start), 'minutes');
			totalSpentInPercent = Math.max(0, Math.min(100, 100 * (totalSpent / detailedInfo.durationInMinutes)));
		}
		else {
			totalSpentInPercent = 100;
		}

		if (detailedInfo.stopAdHocTimeTracker) {
			animateBell = ''; // stop animation if Tracker is stopped
		}

		return (
			<div className="time-tracking-status-section">
				<div className="space-between-section time-tracking-row">
					<span>Time Tracker</span>
					<div className="mt-2">
						{
							!showStopAdHocTimeTrackerBtn? null :
							<Button color="danger" title="Cancel" onClick={() => this.props.stopTrackingEvent(this.props.detailedInfo, 'stop')} className="btn-sm">Stop Tracker</Button>
						}
					</div>
				</div>

				{
					!detailedInfo.setTimerInMinutes ? null
					:
					<div className="space-between-section time-tracking-row">
						<label>
							<i className="fa fa-clock-o" aria-hidden="true"></i>
							<span className="light-title-text">  Set Timer : {Util.convertTimeToReadableText(detailedInfo.setTimerInMinutes)}  </span>
						</label>

						<label>
							<i className={"fa fa-bell " + animateBell} aria-hidden="true"></i>
							<span className="light-title-text"> { timerSpentInPercent < 100 ? "Notification At" : "Notified At"}  :  {notificationAt.format('LT')}  </span>

							{
								timerSpentInPercent < 100 ?

								<div className="space-between-section ">
									<div style={{ width:'100%', height: '6px', background: 'linear-gradient(to right, #f2956a ' + timerSpentInPercent + '%, #fff '+ (timerSpentInPercent + 2)+'%)', border: '1px solid #f7dfd4', borderRadius: '5px' }}></div>
								</div>

								: null
							}
							
						</label>
					</div>
				}
				
				<div className="space-between-section time-tracking-row">
					<label>
						<i className="fa fa-clock-o" aria-hidden="true"></i>
						<span className="light-title-text">  Start : {moment(detailedInfo.start).format('LT')}  </span>
					</label>
					<label>
						<i className="fa fa-clock-o" aria-hidden="true"></i>
						<span className="light-title-text">
						{
							detailedInfo.stopAdHocTimeTracker ? "  End : " + moment(detailedInfo.adHocTimeTrackerStopedAt).format('LT')
							:
							"  Tracking... : " + moment().format('LT')
						}
						</span>
					</label>
					{/* {
						totalSpent ?
						<label>
							<span className="light-title-text"> Completed :  {Util.convertTimeToReadableText(totalSpent)}  </span>
						</label>
						: null
					} */}
				</div>
				<div className="space-between-section ">
					<div style={{ width:'100%', height: '8px', background: 'linear-gradient(to right, rgb(242 149 105), rgba(255,0,0,0))', border: '1px solid #f7dfd4', borderRadius: '5px' }}></div>
					{/* <div style={{ width:'100%', height: '8px', background: 'linear-gradient(to right, #f2956a ' + totalSpentInPercent + '%, #fff '+ (totalSpentInPercent + 5)+'%)', border: '1px solid #f7dfd4', borderRadius: '5px' }}></div> */}
				</div>
				<div className="space-between-section time-tracking-row">
				</div>
			</div>
		)
	}

	// Handler for submit
	handleReassignSubmit(e) {
		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			let chosenOutcome = null;
			let customTitle = this.state.detailedInfo.title; // default title as Timeblock_Title

			if (form['outcome'] && form['outcome']['value']) {
				chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: form['outcome']['value'] });
			}

			if (chosenOutcome && chosenOutcome['abbreviation'] && form['keyActivity'] && form['keyActivity']['label']) {
				customTitle =chosenOutcome['abbreviation'] + ": " + form['keyActivity']['label']; // custom title > Outome_Abbreviation: Key_Activity_Title
			}

			let outcomeID = chosenOutcome? form['outcome']['value'] : null;
			let outcomeAbbr = chosenOutcome? chosenOutcome['abbreviation'] : null;
			let outcomeTitle = chosenOutcome? form['outcome']['label'] : null;
			let keyActivityID = form['keyActivity'] && form['keyActivity']['value'] ? form['keyActivity']['value'] : null;
			let keyActivityTitle = form['keyActivity'] && form['keyActivity']['label'] ? form['keyActivity']['label'] : null;
			
			let focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
			let focusAreaTitle = form['focusArea'] && form['focusArea']['label'] ? form['focusArea']['label'] : null;

			// update event object state
			let detailedInfo = {
				...this.state.detailedInfo,
				// title: customTitle,
				outcomeID: outcomeID,
				outcomeAbbr: outcomeAbbr,
				outcomeTitle: outcomeTitle,
				keyActivityID: keyActivityID,
				keyActivityTitle: keyActivityTitle,
				focusAreaID: focusAreaID,
				focusAreaTitle: focusAreaTitle,
				isUnAssigned: false,
				isNewlyAssignedPBHProperties: true
			};

			// If an unplanned event is assigned with KA, then insert the metricsAndTargets associated with the corresponding KA to it.
			if ((!detailedInfo.metricsAndTargets || _.isEmpty(detailedInfo.metricsAndTargets)) && chosenOutcome && chosenOutcome.keyActivities && keyActivityID) {
				
				let keyActivityObj = _.find(chosenOutcome.keyActivities, {id: keyActivityID});

				if (keyActivityObj && keyActivityObj.metricsAndTargets) {
					detailedInfo.metricsAndTargets = _.cloneDeep(keyActivityObj.metricsAndTargets);
				};
			};

			this.setState({
				detailedInfo: detailedInfo,
				showAssignForm: false,
			});

			this.resetForm();
		}
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "outcome":
				if (!value) errorMsg = "Please select an Project";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select a Focus Area";
				break;

			case "keyActivity":
				if (!value) errorMsg = "Please select a Key Activity";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'outcome': null,
			'keyActivity': null,
		};

		let filteredoutcomesOptions = [];

		if(value.value != 'all') {
			_.forEach(this.state.outcomesWithPopulatedData, function (eachOutcome) {
			
				if(eachOutcome.focusArea.id ===  value.value) {
					filteredoutcomesOptions.push(eachOutcome);
				}
			});
		} else {
			filteredoutcomesOptions = this.state.outcomesWithPopulatedData;
		}

		let outcomesOptions = [];
		filteredoutcomesOptions.forEach((eachOutcome) => {

			outcomesOptions.push({
				label: eachOutcome.outcomeTitle,
				value: eachOutcome.id,
				focusAreaID: eachOutcome.focusArea.id
			});
		});

		outcomesOptions.push({
			label: "Add New Project",
			value: "ADD_NEW_OUTCOME",
		})

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for outcome select field change event
	handleOutomeSelectField(name, value) {

		if (name == "outcome" && value["value"] == "ADD_NEW_OUTCOME") {
			// Show Section - Add New Outcome
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'keyActivity': null,
		};

		let chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: value.value });

		let keyActivities = chosenOutcome['keyActivities'];

		let keyActivityOptions = [];

		// Some user may not have added key activitites for an outcome
		if (keyActivities && keyActivities.length > 0) {

			keyActivities.forEach((eachkeyActivity) => {

				keyActivityOptions.push({
					label: eachkeyActivity.title,
					value: eachkeyActivity.id,
				});
			});
		}

		keyActivityOptions.push({
			label: "Add New Key Activity",
			value: "ADD_NEW_KA",
		});

		this.setState({
			form: formObj,
			keyActivityOptions: keyActivityOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for Key Activity select field change event
	handleKeyActivitySelectField(name, value) {

		if (name == "keyActivity" && value["value"] == "ADD_NEW_KA") {
			// Show Section - Add New KeyActivity
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	toggleAssignPBHPropertyForm(status) {
		this.setState({
			showAssignForm: status,
		})
	}

	// Handler to update ActualTime

	handleEditATSubmit(e) {

		e.preventDefault();

		const { selectedActualTime } = this.state;

		if (!selectedActualTime) {

			this.setState({
				showEditActualDruationForm: null,
				selectedActualTime: null
			});

			return false;
		}
		else {

			// update actualCompletionTimeInMinutes, and endTime

			const detailedInfo = _.cloneDeep(this.state.detailedInfo);
			const activityLog = detailedInfo.activityLog || [];
			const lastActivity = _.cloneDeep(_.omit(detailedInfo, ['activityLog']));
			activityLog.push(lastActivity)
			detailedInfo.updatedAt = new Date().getTime();
			detailedInfo.activity = "upadted-ACT"; // ACT - ActualCompletionTimeInMinutes
			detailedInfo.activityLog = activityLog;

			let actualCompletionTimeInMinutes = selectedActualTime ? selectedActualTime : null;

			if (actualCompletionTimeInMinutes) {
				// calculate end Time
				let updatedEndTime = moment(detailedInfo.start).add(actualCompletionTimeInMinutes, 'minutes').toDate();
				detailedInfo.end = updatedEndTime;
				detailedInfo.actualCompletionTimeInMinutes = actualCompletionTimeInMinutes;
			}

			this.setState({
				detailedInfo: detailedInfo,
				showEditActualDruationForm: null,
				selectedActualTime: null
			});

		}
	}

	// Handler for ActualTime select field change event

	handleActualTimeSelectField(name, selectedItem) {

		this.setState({
			[name]: selectedItem['value']
		});
	}

	// Handler to toggle edit option for ActualTime

	handleEditATStatus(status) {

		this.setState({
			showEditActualDruationForm: status,
		})
	}

	// Render Edit Option for ActualTime

	renderEditATForm() {

		const { detailedInfo } = this.state;

		const { durationInMinutesOptions } = this.props;
		let selectedActualTime = this.state.selectedActualTime || detailedInfo.actualCompletionTimeInMinutes || detailedInfo.durationInMinutes;
		let maxDutationAvailableInMins = 480;

		if (detailedInfo.start) {
			let startDate = moment(detailedInfo.start, 'DD-MM-YYYY HH:mm');
			let endOfDay = moment(detailedInfo.start, 'DD-MM-YYYY').endOf('day');
			maxDutationAvailableInMins = endOfDay.diff(startDate, 'minutes');
		}

		return(
			<div>
				<ModalHeader>{detailedInfo.title}</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-md-12">
							<p>Please specify the actual time spent on.</p>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<label>Actual Time</label>
								<div>
									<ReactSelect
										name="selectedActualTime"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={durationInMinutesOptions.filter(option => option.value <= maxDutationAvailableInMins)}
										value={durationInMinutesOptions.find(option => selectedActualTime && option.value === selectedActualTime)}
										autosize={true}
										onChange={this.handleActualTimeSelectField.bind(this, 'selectedActualTime')}
										placeholder="Select Available Duration"
									/>
								</div>
							</div>
						</div>
						<div className="col-md-12">
							{
								detailedInfo.durationInMinutes?
								<small>* Provided Time Estimate is <span className="color-primary">{Util.convertTimeToReadableText(detailedInfo.durationInMinutes)}.</span></small>
								: null
							}
						</div>
					</div>
					<br></br>
					<div className="row">
						<div className="col-md-12">
							<button type="button" onClick={this.handleEditATSubmit.bind(this)} className="btn btn-sm mr-2 btn-primary">Ok</button>
							<Button type="button" color="secondary" title="Cancel" onClick={this.handleEditATStatus.bind(this, false)} className="btn-sm">Cancel</Button>
						</div>
					</div>
				</ModalBody>
			</div>
		);
	}

	renderAssignForm() {

		const { form, formErrors, focusAreasOptions, outcomesOptions, keyActivityOptions } = this.state;

		return(
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>FocusArea <span className="text-danger">*</span></label>
							<div>
								<ReactSelect
									name="focusArea"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={focusAreasOptions}
									value={form.focusArea}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
									placeholder="Select Focus Area"
								/>
								<p className="text-danger">{formErrors['focusArea'] &&
								<span className="err">{formErrors['focusArea']}</span>}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Project <span className="text-danger">*</span></label>
							<ReactSelect
								name="outcome"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={outcomesOptions}
								value={form['outcome']}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleOutomeSelectField.bind(this, "outcome")}
								placeholder="Select Project"
							/>
							<p className="text-danger">{formErrors['outcome'] &&
								<span className="err">{formErrors['outcome']}</span>}</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Key Activity <span className="text-danger">*</span></label>
							<ReactSelect
								name="keyActivity"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={keyActivityOptions}
								value={form['keyActivity']}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleKeyActivitySelectField.bind(this, "keyActivity")}
								placeholder="Select Key Activity"
							/>
							<p className="text-danger">{formErrors['keyActivity'] &&
								<span className="err">{formErrors['keyActivity']}</span>}</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<button type="button" onClick={this.handleReassignSubmit.bind(this)} className="btn btn-sm mr-2 btn-primary">Submit</button>
						<Button color="secondary" title="Cancel" onClick={this.toggleAssignPBHPropertyForm.bind(this, false)} className="btn-sm">Cancel</Button>
					</div>
				</div>
			</form>
		);
	}

	handleAddNewOnCancel = () => {
		this.setState({
			displaySubSection: ""
		})
	}

	handleNewOutcomeSubmit = (createdOutcome, displaySubSection = "" ) => {

		this.getOutcomesWithPopulatedData();
		const { outcomesOptions, form } = this.state;
		const focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
		const newOutcome = {
			label: createdOutcome.outcomeTitle,
			value: createdOutcome.id,
			focusAreaID: focusAreaID
		}

		setTimeout(() => {
			
			outcomesOptions.splice((outcomesOptions.length - 1), 0, newOutcome); // push new outcome
			var keyActivityOptions = [{
				label: "Add New Key Activity",
				value: "ADD_NEW_KA",
			}];

			form["outcome"] = newOutcome; // select newly created outcome
			form["keyActivity"] = null;

			this.setState({
				displaySubSection: displaySubSection,
				outcomesOptions: outcomesOptions,
				form: form,
				keyActivityOptions: keyActivityOptions,
				reloadOutcomesData: true,
			})
		}, 500);
	}

	handleNewKeyActivitySubmit = (createdKA, displaySubSection ="" ) => {

		this.getOutcomesWithPopulatedData();

		const { keyActivityOptions, form } = this.state;

		const newKA = {
			label: createdKA.title,
			value: createdKA.id,
		}

		keyActivityOptions.splice((keyActivityOptions.length - 1), 0, newKA);

		setTimeout(() => {
			form["keyActivity"] = newKA; // select newly created KA 
			this.setState({
				displaySubSection: displaySubSection,
				keyActivityOptions: keyActivityOptions,
				reloadOutcomesData: true,
				form: form
			})
		}, 500);
	}

	toggleNewOutcomeKAModal() {

		// this.resetForm();

		this.setState({
			displaySubSection: "",
			showAssignForm: true,
		});

		if (this.state.showAssignForm) {
			this.setState({
				displaySubSection: "",
				showAssignForm: false,
			});
		} else {
			this.setState({
				displaySubSection: "",
				showAssignForm: true,
			});
		}
	}

	handleMetricValueChange(index, e) {

		let value = e.target.value;

		let detailedInfo = _.cloneDeep(this.state.detailedInfo);
		detailedInfo['metricsAndTargets'][index] = {
			...detailedInfo['metricsAndTargets'][index],
			response: parseInt(value),
		}

		this.setState({
			detailedInfo: detailedInfo,
		})
	}

	toggleDeleteModal() {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
			});
		} else {
			this.setState({
				deleteModal: true,
			});
		}
	}

	renderEventDeleteModal() {

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Remove From Plan</ModalHeader>
					<ModalBody>
						<p><b>Are you sure you want to remove this event from the plan?</b></p>
						<div className="small">Please be informed that this action is permanent and any <b>unsaved data(s) </b>such as events, notes will be lost.</div>
						<div className="small mt-2">Any changes you made to this event in your <b>3-rd party calendar</b> (e.g. inviting participants) will also be lost.</div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.onDeleteClick}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	getFormattedRecurringInterval(detailedInfo) {

		let formattedRecurringInterval = '';
		let {interval, startDate, recurringIntervalType, recurringIntervalDayID, recurringIntervalWeekID, recurringIntervalMonthID, recurringIntervalISOWeekDayID} = detailedInfo;
		let ISOWeekDays = IntervalOptions.getISOWeekDays();
		let recurringIntervalCopy = _.startCase(_.camelCase(interval));
		
		if (interval == 'every_x_months' && detailedInfo.recurringInterval) {
			recurringIntervalCopy = `Every ${detailedInfo.recurringInterval} Months`;
		};

		if (interval == 'every_other_week' && startDate) {
			let selectedISOWeekDay = _.find(ISOWeekDays, {value: moment(startDate).get('isoWeekday')});
			formattedRecurringInterval = recurringIntervalCopy + ' on ' + (selectedISOWeekDay && selectedISOWeekDay.label ? selectedISOWeekDay.label : '');
		}
		else if (recurringIntervalType == 'on_selected_day' && recurringIntervalDayID) {
			
			if (interval == 'annually' && recurringIntervalMonthID) {
				let monthsInAYear = IntervalOptions.getMonthsInAYear();
				let selectedMonth = _.find(monthsInAYear, {value: recurringIntervalMonthID});
				formattedRecurringInterval = recurringIntervalCopy + ' on ' + (selectedMonth && selectedMonth.label ? (selectedMonth.label + ' ') : '') + recurringIntervalDayID;
			}
			else {
				formattedRecurringInterval = recurringIntervalCopy + ' on day ' + recurringIntervalDayID;
			};
		}
		else if (recurringIntervalType == 'on_selected_xth_day_of_the_week' && recurringIntervalWeekID && recurringIntervalISOWeekDayID) {
			let selectedISOWeekDay = _.find(ISOWeekDays, {value: recurringIntervalISOWeekDayID});
			formattedRecurringInterval = recurringIntervalCopy + ' on ' + IntervalOptions.ordinal_suffix_of(recurringIntervalWeekID) + ' ' + (selectedISOWeekDay && selectedISOWeekDay.label ? selectedISOWeekDay.label : '');
		};
		return formattedRecurringInterval;
	}

	renderCalendarInfo (showMore, detailedInfo) {

		return (
			<React.Fragment>
				{
				showMore && detailedInfo && detailedInfo.isNonPBHEvent && 
					<div title="Calendar" style={{color: '#f29569'}}>
						<i className="fa fa-calendar alt mr-2" aria-hidden="true"></i>
						<span style={{fontSize:12, wordBreak: 'break-word'}}>{detailedInfo.googleCalendarDisplayName || "Primary"}{detailedInfo.googleCalendarEmail && ', ' + detailedInfo.googleCalendarEmail} </span>
						<br/>
					</div>
				}
			</React.Fragment>
		)
	}

	render () {

		const { showMore } = this.props;

		let { showAssignForm, detailedInfo, showEditActualDruationForm } = this.state;

		let title = '';
		let isNonWeeklyTB = detailedInfo && detailedInfo.regularity == 'non-weekly' ? true : false;
		let regularity = 'Weekly';

		if (isNonWeeklyTB) {
			if (detailedInfo.interval == 'every_x_years' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Years`;
			}
			else if (detailedInfo.interval == 'every_x_weeks' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Weeks`;
			}
			else if (detailedInfo.interval == 'every_x_months' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Months`;
			}
			else {
				regularity = _.startCase(_.camelCase(detailedInfo.interval));
			};
		}
		else if (detailedInfo.regularity === 'daily') {
			if (detailedInfo.interval == 'every_x_days' && detailedInfo.recurringInterval) {
				regularity = `Every ${detailedInfo.recurringInterval} Days`;
			}
			else {
				regularity = _.startCase(_.camelCase(detailedInfo.interval));
			};
			isNonWeeklyTB = true;
		};

		if (detailedInfo && !detailedInfo.title && detailedInfo.timeBlockTitle) {
			title = detailedInfo.timeBlockTitle;
			if (detailedInfo.outcomeAbbr && detailedInfo.keyActivityTitle) {
				title += ' (' + detailedInfo.outcomeAbbr + ': ' + detailedInfo.keyActivityTitle + ')';
			};
		}
		else if (detailedInfo && detailedInfo.title) {
			title = detailedInfo.title;
		};

		var innerHTML = null;

		if (this.state.displaySubSection == "ADD_NEW_OUTCOME") {
			innerHTML = <AddOutcome 
							onClose={this.toggleNewOutcomeKAModal.bind(this)}
							onCancel={this.handleAddNewOnCancel}
							onSuccussSubmit={this.handleNewOutcomeSubmit}
							focusAreasOptions={this.state.focusAreasOptions}
							{...this.props}
							{...this.state} />;
		}

		else if (this.state.displaySubSection == "ADD_NEW_KA") {
			innerHTML = <AddKeyActivity 
							onClose={this.toggleNewOutcomeKAModal.bind(this)}
							onCancel={this.handleAddNewOnCancel}
							onSuccussSubmit={this.handleNewKeyActivitySubmit}
							{...this.props}
							{...this.state} />;
		}

		if (innerHTML) {
			return (
				<div>
					<Modal isOpen={this.props.showDetailedInfoModal} toggle={this.toggleNewOutcomeKAModal.bind(this)} backdrop={"static"} keyboard={false}>
						{innerHTML}
					</Modal>
				</div>
			)
		}

		if(showAssignForm) {

			return(
				<React.Fragment>
					<ModalHeader>{title}</ModalHeader>
					<ModalBody>
						{this.renderAssignForm()}
					</ModalBody>
				</React.Fragment>
			)
		} 
		else if(showEditActualDruationForm) {
			return this.renderEditATForm();
		}
		else {

			return (
				<div>
						{!detailedInfo? null :
							<React.Fragment>
								<ModalHeader toggle={()=>this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData)}>
									{title}
									{this.renderCalendarInfo(showMore, detailedInfo)}
								</ModalHeader>
								<ModalBody>
									
									<div style={{maxHeight: 650, overflowY: 'auto', paddingRight: showMore ? 10 : 0}}>
						
										{this.renderEventStatusBar(showMore, detailedInfo)}
										{this.renderAdHocTimeTrackingStatus(showMore, detailedInfo)}
										
										<table className="table table-curved" style={{width: '100%'}}>
											<thead>
												<tr>
													<th className="text-left" style={{width: '35%'}}>Item</th>
													<th className="text-left" style={{width: '65%'}}>Value</th>
												</tr>
											</thead>
											<tbody>
												{detailedInfo.isUnAssigned && this.props.showMore ? 
												<React.Fragment>
													<tr>
														<td><span className="small-title-text">Focus Area </span></td>
														<td rowSpan="4" style={{verticalAlign: "middle",textAlign: "center"}}><button type="button" className="btn btn-primary" onClick={this.toggleAssignPBHPropertyForm.bind(this, true)}>Assign</button></td>
													</tr>
													<tr>
														<td><span className="small-title-text">Project </span></td>
													</tr>
													<tr>
														<td><span className="small-title-text">Project Abbreviation </span></td>
													</tr>
													<tr>
														<td><span className="small-title-text">Key Activity </span></td>
													</tr>
												</React.Fragment>
												:
												<React.Fragment>
													<tr>
														<td><span className="small-title-text">Focus Area </span></td>
														<td>{detailedInfo.focusAreaTitle}</td>
													</tr>
													<tr>
														<td><span className="small-title-text">Project </span></td>
														<td><a href={'/projects/edit/' + detailedInfo.outcomeID} target='_blank' style={SharedStyles.titleLink}>{detailedInfo.outcomeTitle}</a></td>
													</tr>
													<tr>
														<td><span className="small-title-text">Project Abbreviation </span></td>
														<td>{detailedInfo.outcomeAbbr}</td>
													</tr>
													<tr>
														<td><span className="small-title-text">Key Activity </span></td>
														<td><a href={'/key-activities/edit/' + detailedInfo.keyActivityID + '?outcomeID=' + detailedInfo.outcomeID} target='_blank' style={SharedStyles.titleLink}>{detailedInfo.keyActivityTitle}</a></td>
													</tr>
												</React.Fragment>
												}
												<tr>
													<td><span className="small-title-text">Time Block </span></td>
													<td>{detailedInfo.timeBlockTitle}</td>
												</tr>
												<tr>
													<td><span className="small-title-text">Duration </span></td>
													<td>{ detailedInfo.durationInMinutes ? Util.convertTimeToReadableText(detailedInfo.durationInMinutes) : null}</td>
												</tr>
												<tr>
													<td><span className="small-title-text">Regularity</span></td>
													<td>{regularity}</td>
												</tr>
												{isNonWeeklyTB && detailedInfo.startDate ?
													<tr>
														<td><span className="small-title-text">Schedule Start Date</span></td>
														<td>{moment(detailedInfo.startDate).format('MM/DD/YYYY')}</td>
													</tr>
												: null}
												{isNonWeeklyTB && detailedInfo.recurringIntervalType ?
													<tr>
														<td><span className="small-title-text">Recurring Interval</span></td>
														<td>{this.getFormattedRecurringInterval(detailedInfo)}</td>
													</tr>
												: null}		
												{
													!showMore? null :
													<React.Fragment>
														<tr>
															<td><span className="small-title-text">Actual Duration </span></td>
															<td>{ 
																detailedInfo.durationInMinutes ? 
																<span>
																	{ detailedInfo.actualCompletionTimeInMinutes ? Util.convertTimeToReadableText(detailedInfo.actualCompletionTimeInMinutes) : 
																	Util.convertTimeToReadableText(detailedInfo.durationInMinutes)}
																	{
																		// Dont so (change) option if Ad-Hoc Tracking is tracking any event
																		detailedInfo.isAdHocTimeTrackerUnPlannedEvent && !detailedInfo.stopAdHocTimeTracker ? null :
																		<span onClick={this.handleEditATStatus.bind(this, true)} className="text-center text-info cursor-pointer">  (change)</span>
																	}
																</span>
																: null
															}</td>
														</tr>
														<tr>
															<td><span className="small-title-text">Completed </span></td>
															<td>
		
																<div className="custom-control custom-switch">
																	<input type="checkbox" onChange={this.toggleCompletedTBCheckbox} className="custom-control-input" name="TBCompletedStatus" id="TBCompletedStatus" value={this.state.isCompleted} checked={this.state.isCompleted ? "checked" : ""}/>
																	<label className="custom-control-label" htmlFor="TBCompletedStatus"></label>
																</div>
															</td>
														</tr>
														
													</React.Fragment>
												}
											</tbody>
										</table>

										{!showMore || !this.state.isCompleted ? null :
											
											detailedInfo.metricsAndTargets && detailedInfo.metricsAndTargets.length > 0 ? 
												<React.Fragment>
													<div className="mt-2">
														<div className="space-between-section">
															<label>
																<span>Metrics: </span>
															</label>
														</div>
																
														<table className="table table-curved" style={{width: '100%'}}>
															<thead>
																<tr>
																	<th className="text-left" style={{width: '35%'}}>Title</th>
																	<th className="text-left" style={{width: '65%'}}>Response</th>
																</tr>
															</thead>
															<tbody>
																{detailedInfo.metricsAndTargets.map((eachMetric, index) => {

																	let metricRef = "metricRef" + index;
																	
																	return (
																		<tr key={eachMetric.id}>
																			<td className="small-title-text align-middle">
																				<span>{eachMetric.title}</span>
																				{!eachMetric.description ? null : 
																					<React.Fragment>
																						<span className="ml-2" onMouseEnter={() => ReactTooltip.show(this[metricRef])} onMouseLeave={() => ReactTooltip.hide(this[metricRef])}><i className="fa fa-info-circle color-primary" aria-hidden="true"></i></span>
																						<span>
																							<span ref={ref => this[metricRef] = ref} data-tip={eachMetric.description}></span>
																							<ReactTooltip backgroundColor={'#f79569'} color={'#737373'}
																							multiline={true}
																							className="reactToolTipCustom" />	
																						</span>
																					</React.Fragment>
																				}
																			</td>
																			<td>
																				<input onChange={this.handleMetricValueChange.bind(this, index)} value={_.has(eachMetric, 'response') ? eachMetric.response : ''} type="number" className="form-control" placeholder="Enter your data (numerical value) here..." />
																			</td>
																		</tr>
																	)
																})}
															</tbody>
														</table>
													</div>
												</React.Fragment>
											: null
										}

										{!showMore ? null :
											<div className="space-between-section my-4">
												<TaskManagement
													utasks={this.state.utasks}
													outcomesWithPopulatedData={this.state.outcomesWithPopulatedData}
													eventObj={this.state.detailedInfo}
													onUpdateUTasks={this.props.onUpdateUTasks} 
													onUpdate={(updatedEventObj) => this.setState({detailedInfo: updatedEventObj})} />
											</div>
										}

										{!showMore ? null :
											
											<React.Fragment>
												
												<div className="space-between-section">
													<label>
														<span>Notes: </span>
													</label>
												</div>
														
												<div className="htmlEditor mb-3">
													<Editor
														toolbar={{
															options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji']
														}}
														editorState={this.state.notesEditorState}
														toolbarClassName="toolbarClassName"
														wrapperClassName="wrapperClassName"
														editorClassName="editorClassName"
														onEditorStateChange={(editorState) =>
															this.setState({
																notesEditorState: editorState,
															})
														}
														placeholder="Add your notes here..."
													/>
												</div>
											</React.Fragment>
										}
									</div>
									
									<div className={showMore ? 'popup-footer-wrapper' : ''}>
										<div className="popup-footer-section">
											{this.renderDeleteButton()}
											<div className="mt-2 text-right">
												{this.renderSaveButton()}
												<Button color="secondary" title="Cancel" onClick={()=>this.props.toggleDetailedInfoModal(false, null, this.state.reloadOutcomesData)} className="btn-sm">Cancel</Button>
											</div>
										</div>
										{/* { showMore ? <div className="small pt-3">* The action(s) performed by the above buttons will not affect original database record. You need to click "Save Changes" button from the main page to get it updated in the database.</div> : null } */}
									</div>

									{this.renderEventDeleteModal()}
								</ModalBody>
	
							</React.Fragment>
						}
				</div>
			)
		}
	}
}

export default DetailedInfoPopup;