import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import SharedStyles from '../../assets/styles/SharedStyles';
import ReactSelect from 'react-select';

class PullGCEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModalPopup: false,
        }
    }

    toggleModalPopup = () => {
        this.setState({
            showModalPopup: !this.state.showModalPopup
        });
    }

    renderPreloadRecurringEvents = () => {

        const { userPreference, GCMetaData, excludedGoogleCalendarIDs = [] } = this.props;
        const calendarSyncPreference = userPreference && userPreference.hasEnabledCalendarSync;
        const currentSyncStatus = GCMetaData ? GCMetaData.syncStatus : false;
        let calendarList = GCMetaData && GCMetaData.calendarList ? GCMetaData.calendarList : [];
        let monthOptions = [1, 2, 3, 4, 5, 6].map(v => ({ label: v, value: v }));

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showModalPopup} toggle={this.toggleModalPopup.bind(this)}>
                    <ModalHeader toggle={this.toggleModalPopup.bind(this)}>Preload Recurring Events</ModalHeader>
                    <ModalBody>
                        {
                            calendarSyncPreference && currentSyncStatus &&
                            <div>
                                <div className="form-group">
                                    <div style={{ lineHeight: 2 }}>
                                    <label>Google Calendars</label>
                                        <div className="custom-checkbox-group" style={{ flexDirection: 'column' }}>
                                            {
                                                calendarList.map((item, index) => {
                                                    if (excludedGoogleCalendarIDs.includes(item.id)) return null;
                                                    else return <label key={index+'-'+item.id} className="checkbox-item-container" style={{ fontSize: 14 }}> <i className="fa fa-calendar alt mr-2" aria-hidden="true"></i> {item.summary} </label>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-radio-group" style={{ flexDirection: 'column' }}>
                                            <div className="radio-item-container">
                                                <label style={{ fontSize: 14 }}>Select Months </label>
                                                <div className="ml-2" style={{ minWidth: 72 }}>
                                                    <ReactSelect
                                                        name="xMonth"
                                                        styles={SharedStyles.selectBoxStyles}
                                                        options={monthOptions}
                                                        value={monthOptions.find((item) => item.value === (this.state.pullEventsForXMonth || 3))}
                                                        clearable={true}
                                                        onChange={(item) => this.setState({
                                                            pullEventsForXMonth: item.value
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2 mt-2">
                                        <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                                        <span className="small ml-2">
                                            <i>Pull the recurring events from the below Google Calendars for {this.state.pullEventsForXMonth || 3} {(this.state.pullEventsForXMonth || 3) > 1 ? "months" : "month"}. </i>
                                        </span>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary btn-sm" onClick={(e) => { this.toggleModalPopup(); this.props.onSubmitClick(this.state.pullEventsForXMonth || 3)}}>Preload Recurring Events</button>
                            </div>
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    render = () => {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary btn-sm" onClick={this.toggleModalPopup.bind(this)}>
                    <span>Preload Recurring Events</span>
                </button>
                {this.renderPreloadRecurringEvents()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
    PullGCEvents
);