import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProductivityMeterScale from "./ProductivityMeterScale";

class ProductivityGoals extends Component {

	render() {

        let productivityGoals = this.props.productivityGoals;

        if (!productivityGoals || productivityGoals.length == 0) return null;

        return(
            <div>
                <div className="container-with-padding">
                    <p className="text-blog">A sound productivity system should enable you to accomplish your desired outcomes while letting you maintain a sufficient level of control and enjoy a great degree of balance and freedom. Those are the dimensions that we will evaluate your productivity based on. Let’s take a closer look.</p>
                    <div className="row row-section-container mt-4">
                        {productivityGoals.map((reportObj, index) => {
                            return(
                                <div className="col-md-4 d-flex align-items-center justify-content-center" key={'pm_' + index}>
                                    <ProductivityMeterScale title={reportObj.title} score={reportObj.score} />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="productivity-meter-report-section my-3 pb-1">
                    <div className="container-with-padding">
                        {productivityGoals.map((reportObj, index) => {
                            return(
                                <div key={'productivity_goals_' + index} style={styles.descriptionContainer}>
                                    <p className="font-bold">{reportObj.title}</p>
                                    <p className="text-blog mb-1">{reportObj.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
	}
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(
	ProductivityGoals
);

const styles = {

    descriptionContainer: {
        backgroundColor: '#fff',
        color: '#737373',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        lineHeight: 1.75
    }
}