import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import { getRolesAndResponsibilitiesForTheUser } from "../../actions/rolesAndResponsibilityActions";
import API_Services from '../../utils/API_Services';
import AddRolesAndResponsibilities from './AddRolesAndResponsibilities';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class RolesAndResponsibilitiesSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			addRRModal: false,
			formApiResponse: {
				className: "",
				message: "",
			},
			rolesAndResponsibilitiesError: "",
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getRolesAndResponsibilitiesForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getRolesAndResponsibilitiesForTheUser(response);
				} else {
					// this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Search RolesAndResponsibilitiesForTheUser
	searchRolesAndResponsibilitiesForTheUser(searchString) {

		this.setState({
			formApiResponse: {
				className: "",
				message: "",
			},
		});

		this.props.setLoading(true);

		var endPoint = UrlConstants.URLS.searchRolesAndResponsibilitiesForTheUser;

		var postObj = searchString;

		API_Services.httpPOST(endPoint, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getRolesAndResponsibilitiesForTheUser(response);
				} else {
					// this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	};

	// Handler for rolesAndResponsibilities change event
	handleRolesAndResponsibilitiesSelect(name, value) {
		this.props.handleRolesAndResponsibilitiesSelect(name, value);
	}

	// Handler for rolesAndResponsibilities name search
	handleRolesAndResponsibilitiesInputChange(value) {

	}

	toggleAddRRModal() {

		if (!this.props.isParentEdit) {
			this.setState({
				rolesAndResponsibilitiesError: "Please save your project before creating roles and responsibilities.",
			})
		} else {
			if (this.state.addRRModal) {
				this.setState({
					addRRModal: false,
				});
			} else {
				this.setState({
					addRRModal: true,
				});
			}
		}
	}

	selectAddedRR(data) {

		var newData = [...this.props.value, data];
		this.props.handleRolesAndResponsibilitiesSelect("rolesAndResponsibilities", newData);
		this.setState({
			addRRModal: false,
		});
	}

	renderAddRolesAndResponsibilityFormInModal() {
		return (
			<div>
				<Modal isOpen={this.state.addRRModal} toggle={this.toggleAddRRModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddRRModal.bind(this)}>Add Roles And Responsibility </ModalHeader>
					<ModalBody>
						<AddRolesAndResponsibilities
							selectAddedRR={this.selectAddedRR.bind(this)} />
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		const { rolesandresponsibilities } = this.props.rolesandresponsibilities;
		const { formApiResponse, rolesAndResponsibilitiesError } = this.state;

		var rolesAndResponsibilitiesOptions = [];

		if (rolesandresponsibilities.length > 0) {
			rolesAndResponsibilitiesOptions = rolesandresponsibilities.map(e => ({ value: e.id, label: e.description }));
		}

		return (
			<div>
				<div className="form-group">
					<label>Role(s) & Responsibilities {this.props.isRequired ? <span className="text-danger">*</span> : null}</label>
					<div className="row">
						<div className="col-11">
							<ReactSelect
								name="rolesAndResponsibilities"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={rolesAndResponsibilitiesOptions}
								value={this.props.value}
								removeSelected={true}
								autosize={true}
								isMulti={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleRolesAndResponsibilitiesSelect.bind(this, 'rolesAndResponsibilities')}
								onInputChange={this.handleRolesAndResponsibilitiesInputChange.bind(this)}
								placeholder="Select Role(s) & Responsibilities"
								isDisabled={this.props.disabled}
							/>
						</div>
						<div className="col-1 text-right">
							<div>
								<button type="button" onClick={this.toggleAddRRModal.bind(this)} className="btn btn-primary btn-sm cursor-pointer"><i className="fa fa-plus" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
					{
						rolesAndResponsibilitiesError
							? <p className="text-warning">{rolesAndResponsibilitiesError}</p>
							: null
					}
				</div>
				{this.renderAddRolesAndResponsibilityFormInModal()}
			</div>

		);
	}
}

RolesAndResponsibilitiesSelect.propTypes = {
	getRolesAndResponsibilitiesForTheUser: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	rolesandresponsibilities: state.rolesandresponsibilities,
});

export default connect(mapStateToProps, { getRolesAndResponsibilitiesForTheUser, setLoading })(
	RolesAndResponsibilitiesSelect
);
