import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import momentTimezone from "moment-timezone";

import { logoutUser } from "../../actions/authActions";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import GoogleLogin from "./GoogleLogin";
import SelectCalendar from "./SelectCalendar";

import { default as ChooseGoogleCalendars } from '../common/GCFilterOptions.js';
import PullGCEvents from "../common/PullGCEvents";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');
var Globals = require('../../utils/Globals');

class UserProfile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			email: '',
			calendarSubscriptionChecked: false,
			calendarSubscribed: false,
			selectedCalendar: null,
			preferences: { // default preferences
				timezone: momentTimezone.tz.guess(),
				configuredWorkspace: null,
				hasEnabledCalendarSync: false,
				selectedCalendar: null,
			},
			excludedGoogleCalendarIDs: []
		}
	}

	componentDidUpdate(prevProps) {

		let userSubscriptions = this.props.usersubscriptions.usersubscriptions;

		// Typical usage (don't forget to compare props):
		if (!this.state.calendarSubscriptionChecked && userSubscriptions && userSubscriptions.length > 0) {

			let calendarSubscribed = false;

			let calendarSubscriptionModuleObj = _.find(userSubscriptions, { 'value': "third_party_integrations", 'isSubscribed': true });

			if (calendarSubscriptionModuleObj) {
				calendarSubscribed = true;
			};

			this.setState({
				calendarSubscribed: calendarSubscribed,
				calendarSubscriptionChecked: true,
			});
		}
	}

	componentDidMount() {

		let coreProperties = JSON.parse(localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES));

		if (coreProperties && coreProperties.userName && coreProperties.userEmail) {
			this.setState({
				username: coreProperties.userName,
				email: coreProperties.userEmail
			});
		};
		this.getUserPreferences();
	}

	getUserPreferences() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUserPreferences, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

          let data = response.data.data;
          let metaData = response.data.metaData;

          if (metaData && metaData.message) {
            alert(metaData.message);
          };

					this.setState({
						preferences: data,
						selectedCalendar: data.selectedCalendar || null,
						metaData: metaData,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || [],
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
	}

	// Logout user from the app
	logout() {
		
		var confirmationMessage = 'Are you sure you want to logout?';
		var confirmation = window.confirm(confirmationMessage);
		
		if (confirmation == true) {
      this.props.logoutUser();
    };
		// Else do nothing!
	}

	updateUserPreferencesInServer = (preferences, reloadPreferences = false) => {

		if (!preferences) return;

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateUserPreferences, {updatedUserPreferences: preferences}, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					let data = response.data.data;

					this.setState({
						preferences: data
					},
					() => {
						/* It is necessary to reload preferences in cases like whenever Google auth updated. That's because we need to verify the current sync status. */
						if (reloadPreferences) this.getUserPreferences();
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	updateCalendarPreferenceNdMoveEventsToSelectedCalendar(preferences) {

		const { selectedCalendar } = this.state

		if (!preferences) return;

		if (selectedCalendar && preferences.selectedCalendar && preferences.selectedCalendar == selectedCalendar) return;

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateCalendarPreferenceNdMoveEventsToSelectedCalendar, {updatedUserPreferences: preferences}, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					let data = response.data.data;

					if (response.data.message) this.setApiResponse(response.data.message, true);

					this.setState({
						preferences: data,
						selectedCalendar: data.selectedCalendar || null
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	updateCalendarSyncPreference() {

		let preferences = this.state.preferences;

		if (!preferences) return;
		
		preferences.hasEnabledCalendarSync = preferences.hasEnabledCalendarSync ? false: true;

		this.updateUserPreferencesInServer(preferences, true);
	}

	preloadRecurringEventsFromGC = (pullEventsForXMonth) => {

		this.props.setLoading(true);
		let clientTimeZone = momentTimezone.tz.guess();

		API_Services.httpPOST(UrlConstants.URLS.pullEventsFromSelectedGoogleCalendars, { clientTimeZone: clientTimeZone, pullEventsForXMonth: pullEventsForXMonth }, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			}

			else if (response && response.data && response.data.message) {
				this.setApiResponse(response.data.message, true);
			};

			this.props.setLoading(false);
		});
	}

	onAuthSuccess(params) {

		let preferences = this.state.preferences;

		if (!preferences) return;

		preferences.configuredWorkspace = params.provider;
		preferences.selectedCalendar = params.defaultCalendar;

		this.updateUserPreferencesInServer(preferences, true);
	}

	renderPreloadRecurringEvents = () => {

		const { preferences, metaData: GCMetaData, excludedGoogleCalendarIDs } = this.state;

		return (
			<span className="pl-2">
				<PullGCEvents
					GCMetaData={GCMetaData}
					userPreference={preferences} 
					excludedGoogleCalendarIDs={excludedGoogleCalendarIDs}
					onSubmitClick={this.preloadRecurringEventsFromGC}
					/>
			</span>
	)
	}

	renderChooseGoogleCalendars = () => {

		const { preferences, metaData: GCMetaData, excludedGoogleCalendarIDs } = this.state;

		return (
			<ChooseGoogleCalendars
				GCMetaData={GCMetaData}
				userPreference={preferences} 
				excludedGoogleCalendarIDs={excludedGoogleCalendarIDs}
				onUpdate={this.updateUserPreferencesInServer}
			/>
		)
	}

	renderUserDetails() {

		const {username, email} = this.state;

		return(
			<div>
				<div className="bread-crumb">
					<a href="#user-details" id="user-details">User Details</a>
				</div>
				<div className="mx-3">
					<div className="form-group">
						<label>Username</label>
						<div className="form-control width-fit-content">{username}</div>
					</div>
					<div className="form-group">
						<label>Email</label>
						<div className="form-control width-fit-content">{email}</div>
					</div>
					<Button color="primary" title="Logout" onClick={this.logout.bind(this)} className="btn-sm mb-4">Logout</Button>
				</div>
			</div>
		)
	}

	renderUserPreferences() {

		const { preferences, metaData, calendarSubscribed } = this.state;
		const calendarSyncPreference = preferences && preferences.hasEnabledCalendarSync;
		const currentSyncStatus = metaData ? metaData.syncStatus : false;
		const calendarList = metaData ? metaData.calendarList : [];
		const selectedCalendar = preferences && preferences.selectedCalendar ? _.find(calendarList, {id: preferences.selectedCalendar}) : null; 

    console.log('currentSyncStatus && preferences && selectedCalendar', currentSyncStatus, preferences, selectedCalendar);

		return(
			<div>
				<div className="bread-crumb">
					<a href="#preferences" id="preferences">Preferences</a>
				</div>
				<div className="mx-3">
					<div className="row m-0">
						<div className="cursor-pointer mr-2" onClick={calendarSubscribed ? this.updateCalendarSyncPreference.bind(this) : null}>
							{calendarSyncPreference ? 
								<i className="fa fa-check-circle fa-lg color-primary" aria-hidden="true"></i> :
								<i className="fa fa-circle-o fa-lg color-primary" aria-hidden="true"></i> }
						</div>
						<div className="form-group">
							<div>Calendar Synchronization</div>
							{ calendarSubscribed ?
								calendarSyncPreference ?
									<div>
										<GoogleLogin 
											disabled={currentSyncStatus && preferences && preferences.selectedCalendar}
											onAuthSuccess={this.onAuthSuccess.bind(this)} 
										/>
										{currentSyncStatus && preferences && selectedCalendar ?
										<div>
											<div className="text-blog">
												<span>Your Productivity By Habit events will be added to your Google account's <span className="color-primary">{selectedCalendar.summary}</span> calendar.</span>
												<SelectCalendar
													preferences={preferences} 
													calendarList={calendarList}
													onUpdate={this.updateCalendarPreferenceNdMoveEventsToSelectedCalendar.bind(this)} 
												/>
											</div>
											<div className="mt-3 mb-3">
												{this.renderChooseGoogleCalendars()}
												{this.renderPreloadRecurringEvents()}
											</div>
										</div>
										: 
											<div className="text-blog">Click the above button to start adding Productivity By Habit events to your Google Calendar.</div>
										}
									</div>
								: null
							:
								<div className="small mt-2">Do you have a valid activation code for unlocking Calendar synchronization feature? Please enter it <a href='/subscription?module=third_party_integrations' className='color-primary'>here</a>.</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {

		return (
			<div className="page-content container child-container">
				{this.renderUserDetails()}
				{this.renderUserPreferences()}
			</div>
		);
	}
}

UserProfile.propTypes = {
	logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	usersubscriptions: state.usersubscriptions,
});

export default connect(mapStateToProps, { setLoading, logoutUser })(
	UserProfile
);