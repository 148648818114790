import axios from "axios";
import { toast } from "react-toastify";
import {
	LOADING,
	RESET_ROLES_AND_RESPONSIBILITY,
	GET_ROLES_AND_RESPONSIBILITY,
	GET_ROLES_AND_RESPONSIBILITIES,
	DELETE_ROLES_AND_RESPONSIBILITY,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetRolesAndResponsibilityData = () => dispatch => {
	dispatch({
		type: RESET_ROLES_AND_RESPONSIBILITY,
	})
}

// Get RolesAndResponsibilities
export const getRolesAndResponsibilitiesForTheUser = (response) => dispatch => {

	dispatch({
		type: GET_ROLES_AND_RESPONSIBILITIES,
		payload: response.data.data
	})
};

// Get a RolesAndResponsibility
export const getRolesAndResponsibilityByID = (rolesAndResponsibilityID) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getRolesAndResponsibilityByID;
	var params = 'recordID=' + rolesAndResponsibilityID;

	API_Services.httpGETAddParams(endPoint, params, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_ROLES_AND_RESPONSIBILITY,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};