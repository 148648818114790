import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TimeDurationSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			durationInMinutesPastPerformance: [],
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		var postObj = {
			"category": "DefaultTimeEstimates"
		};

		API_Services.httpPOST(UrlConstants.URLS.getPlatformConstants, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					if (response.data.data && response.data.data.value && response.data.data.value.length > 0) {

						let durationInMinutesPastPerformance = [];

						response.data.data.value.forEach((value, index) => {
							durationInMinutesPastPerformance.push({
								label: value.label,
								value: value.value,
							});
						})
						this.setState({
							durationInMinutesPastPerformance: durationInMinutesPastPerformance,
						});
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Search TimeDuration
	searchTimeDuration(searchString) {

		// this.setState({
		// 	formApiResponse: {
		// 		className: "",
		// 		message: "",
		// 	},
		// });

		// this.props.setLoading(true);

		// var endPoint = UrlConstants.URLS.searchTimeDuration;

		// var postObj = searchString;

		// API_Services.httpPOST(endPoint, postObj, (err, response) => {

		// 	if (err) {
		// 		if (err.response && err.response.data) {
		// 			this.setFormApiResponse(err.response.data, true)
		// 		} else {
		// 			this.setFormApiResponse("Something went wrong!", true);
		// 			console.log('err...', err);
		// 		}
		// 	} else if (response.data) {

		// 		this.setState({
		// 			timeDuration: response.data
		// 		});
		// 	} else {
		// 		this.setFormApiResponse("Something went wrong!", true);
		// 	}

		// 	this.props.setLoading(false);
		// });
	};

	// Handler for timeDuration change event
	handleTimeDurationSelect(name, value) {
		this.props.handleTimeDurationSelect(name, value);
	}

	// Handler for timeDuration name search
	handleTimeDurationInputChange(value) {

		if (value) {

			// Search timeDuration only after user stops typing
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.searchTimeDuration({ searchString: value });
			}, 300);
		}
	}

	render() {

		const { durationInMinutesPastPerformance, formApiResponse } = this.state;

		var durationInMinutesPastPerformanceOptions = [];

		if (durationInMinutesPastPerformance.length > 0) {
			durationInMinutesPastPerformanceOptions = durationInMinutesPastPerformance.map(e => ({ value: e.value, label: e.label }));
		}

		return (
			<div className="form-group">
				<label>Duration (Past Performance){this.props.isRequired ? <span className="text-danger">*</span> : null }</label>
				<div>
					<ReactSelect
						name="durationInMinutesPastPerformance"
						styles={SharedStyles.selectBoxStyles}
						closeOnSelect={false}
						options={durationInMinutesPastPerformanceOptions}
						value={durationInMinutesPastPerformanceOptions.find(option => option.value === this.props.value)}
						removeSelected={true}
						autosize={true}
						isClearable={true}
						onSelectResetsInput={true}
						onChange={this.handleTimeDurationSelect.bind(this, 'durationInMinutesPastPerformance')}
						onInputChange={this.handleTimeDurationInputChange.bind(this)}
						isDisabled={this.props.disabled}
					/>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</div>
			</div>
		);
	}
}

TimeDurationSelect.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	TimeDurationSelect
);
