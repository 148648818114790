import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';

import PMDashboard from '../productivity-meter/PMDashboard';
import IIADashboard from '../inner-insights/IIADashboard';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const DEFAULT_ACTIVE_TAB = "productivity-meter"; // Default section

class SuperAdminDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeMenu: null
		}
	}

	componentDidMount() {

		// ToDo: API call to check user-access
		
		const active_tab = this.props.match.params.active_tab;

		if(!active_tab) {
			this.setState({
				activeMenu: DEFAULT_ACTIVE_TAB,
			});
		} else {
			this.setState({
				activeMenu: active_tab,
			});
		}
	}

	handleSwitchTabs(tabSlug) {

		this.setState({
			activeMenu: tabSlug,
		}, () => {
			history.push(`/assessments/${tabSlug}`);
		})
	}

	renderMenuButtons() {

		const { activeMenu } = this.state;

		let activeClass = "pbh-small-btn-active";
		let defaultClass = "pbh-small-btn-default";

		return (
			<div className="text-center" style={{ marginBottom: 15 }}>
				<ButtonGroup>
					<Button onClick={this.handleSwitchTabs.bind(this, 'productivity-meter')} className={(activeMenu == 'productivity-meter' ? activeClass : defaultClass)} style={{ fontSize: 14 }}>Productivity Meter</Button>
					<Button onClick={this.handleSwitchTabs.bind(this, 'inner-insight')} className={(activeMenu == 'inner-insight' ? activeClass : defaultClass)} style={{ fontSize: 14, minWidth: 145 }}>Inner Insight</Button>
				</ButtonGroup>
			</div>
		);
	}

	renderActiveMenu() {

		const { activeMenu } = this.state;

		if (!activeMenu) {
			return <div className='text-danger'>Unauthorized: You don't have required permission to view this page.</div>
		}
		else if (activeMenu == 'inner-insight') {
			return <IIADashboard />;
		}
		else if (activeMenu == 'productivity-meter') {
			return <PMDashboard />;
		}
		else {
			return <div>{activeMenu}</div>;
		};
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/assessments/">Assessments</a>
				</div>
				<div className="section-container">
					<div className="form-container">
						{this.renderMenuButtons()}
						{this.renderActiveMenu()}
					</div>
				</div>
			</div>
		);
	}
}

SuperAdminDashboard.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	SuperAdminDashboard
);
