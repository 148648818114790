import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import ReactSelect from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import FocusAreasList from "./FocusAreasList";
import TextareaAutosize from 'react-textarea-autosize';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class Timescale extends Component {

	constructor(props) {
		super(props);
		this.state = {
			sectionData: null,
			sectionPrompts: [],
			startDate: null,
			endDate: null,
			fieldErrors: {},
			notesEditorState: null,
			action: 'edit',
			displayTimescale: null,
			formApiResponse: {
				className: "",
				message: "",
			},
		};
	}

	componentWillReceiveProps(props) {

		let sectionData = props.sectionData || {};
		let sectionPrompts = [];
		let notesEditorState = null;

		if (sectionData.isResponded && sectionData.sectionResponse && sectionData.sectionResponse.responses) {
			sectionPrompts = sectionData.sectionResponse.responses;
			notesEditorState = sectionData.sectionResponse.notes ? EditorState.createWithContent(convertFromRaw(sectionData.sectionResponse.notes)) : EditorState.createEmpty()
		}
		else if (!sectionData.isResponded && sectionData.openEndedPrompts) {
			sectionPrompts = sectionData.openEndedPrompts;
		};

		this.setState({
			sectionData: sectionData,
			sectionPrompts: sectionPrompts,
			notesEditorState: notesEditorState,
			startDate: sectionData.sectionResponse && sectionData.sectionResponse.startDate ? new Date(sectionData.sectionResponse.startDate) : null,
			endDate: sectionData.sectionResponse && sectionData.sectionResponse.endDate ? new Date(sectionData.sectionResponse.endDate) : null,
			action: sectionData.isResponded ? 'view' : 'edit',
			displayTimescale: props.displayTimescale
		});
	}

	validateField(fieldObj) {

		let errorMsg = null;

		if (fieldObj.fieldType == 'text_and_dropdown') {

			if (!fieldObj.textFieldResponse || typeof fieldObj.textFieldResponse != fieldObj.textFieldResponseType) {
				errorMsg = "Please input valid entry of type " + fieldObj.textFieldResponseType;
			}
			else if (!fieldObj.dropDownResponse) {
				errorMsg = "Please select valid dropdown option for this field";
			};
		}
		else if (fieldObj.fieldType == 'text_area') {

			if (fieldObj.isMandatory && !fieldObj.response) {
				errorMsg = "This field is mandatory";
			};
		};
		return errorMsg;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	handleSubmit() {

		let { sectionData, sectionPrompts, startDate, endDate, displayTimescale, notesEditorState } = this.state;
		let fieldErrors = {};

		sectionPrompts.map(fieldObj => {
			let errorMsg = this.validateField(fieldObj);
			if (errorMsg) fieldErrors[fieldObj.uniqueID] = errorMsg;
		});

		if (!_.isEmpty(fieldErrors)) {
			return this.setState({ fieldErrors: fieldErrors });
		};

		let postObj = {
			responses: sectionPrompts,
			startDate: displayTimescale != 'daily' ? startDate : null,
			endDate: displayTimescale != 'daily' ? endDate : null,
			timescale: displayTimescale,
			notes: notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null
		};

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.saveTimescaleResponse, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data && response.data.status && response.data.data) {
				
				let responseData = response.data.data;

				if (sectionData.sectionResponse && sectionData.sectionResponse.focusAreas) {
					responseData.focusAreas = sectionData.sectionResponse.focusAreas;
				};
				this.props.handleSubmit(responseData); // Update parent state.
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			}
			this.props.setLoading(false);
		});
	}

	handleFocusAreaUpdates(updatedFocusAreas) {
		
		let { sectionData } = this.state;
		let sectionResponse = sectionData.sectionResponse;
		
		if (!sectionResponse) return;
		
		sectionResponse.focusAreas = updatedFocusAreas;
		
		this.setState({
			sectionData: sectionData
		},
		() => {
			this.props.handleSubmit(sectionResponse); // Update parent state.
		});
	}

	handleTextInputChange(fieldIndex, fieldName, textFieldResponseType, event) {

		let value = event.target.value;

		if (textFieldResponseType == 'number') {
			const regex = /^[0-9\b]+$/;
			if (value == '' || regex.test(value)) {
				this.handlePromptResponseChanges(fieldIndex, fieldName, Number(value));
			};
		}
		else {
			this.handlePromptResponseChanges(fieldIndex, fieldName, value);
		};
	}

	// Handler for input field change event
	handlePromptResponseChanges(index, field, response) {

		let { sectionPrompts, startDate, fieldErrors } = this.state;
		sectionPrompts[index][field] = response;

		this.setState({
			sectionPrompts: sectionPrompts,
		},
		() => {

			if (startDate && index == 0) {
				this.handleDateChange(startDate); // If the user has already selected the startDate and if they edit the timeHorizon after that, update the endDate accordingly.
			}
			else {
				let errorMsg = this.validateField(sectionPrompts[index]);
				let fieldErrorsObj = { ...fieldErrors, [sectionPrompts[index].uniqueID]: errorMsg };
				return this.setState({ fieldErrors: fieldErrorsObj });
			};
		});
	}

	handleDateChange(date) {

		const { sectionPrompts, fieldErrors } = this.state;

		if (date == null) {
			return this.setState({ startDate: null, endDate: null });
		};

		let timeHorizon = sectionPrompts && sectionPrompts[0] ? sectionPrompts[0] : {};
		let startDate = moment(date);
		let endDate = null;

		if (timeHorizon.textFieldResponse && timeHorizon.dropDownResponse) {
			endDate = startDate.clone();
			endDate.add(parseInt(timeHorizon.textFieldResponse), timeHorizon.dropDownResponse);
		}
		else {
			let errorMsg = this.validateField(timeHorizon);
			let fieldErrorsObj = { ...fieldErrors, [timeHorizon.uniqueID]: errorMsg };
			return this.setState({ fieldErrors: fieldErrorsObj });
		};

		this.setState({
			startDate: new Date(startDate),
			endDate: new Date(endDate)
		});
	}

	renderTextAreaField(fieldInputs, fieldIndex) {

		const { fieldErrors, action } = this.state;

		return (
			<div className="form-group">
				<p>{fieldInputs.prompt} <span className="text-danger">*</span></p>
				<div>
					<TextareaAutosize
						disabled={action == 'view'}
						onChange={this.handleTextInputChange.bind(this, fieldIndex, 'response', fieldInputs.textFieldResponseType)}
						className="form-control"
						minRows={fieldInputs.defaultRowsToBeRendered}
						maxRows={10}
						name={fieldInputs.uniqueID}
						value={fieldInputs.response ? fieldInputs.response : ''}>
						{fieldInputs.response ? fieldInputs.response : ''}
					</TextareaAutosize>
					<p className="text-danger">{fieldErrors[fieldInputs.uniqueID] &&
						<span className="err">{fieldErrors[fieldInputs.uniqueID]}</span>}</p>
				</div>
			</div>
		);
	}

	renderTextAndDropdown(fieldInputs, fieldIndex) {

		const { fieldErrors, action } = this.state;
		let dropDownOptions = fieldInputs.dropDownOptions ? fieldInputs.dropDownOptions : [];

		return (
			<div className="form-group">
				<p>{fieldInputs.prompt} <span className="text-danger">*</span></p>
				<div>
					<div className="row">
						<div className="col-md-1">
							<input
								disabled={action == 'view'}
								className="form-control"
								style={{ minHeight: 42 }}
								type={fieldInputs.textFieldResponseType}
								value={fieldInputs.textFieldResponse ? fieldInputs.textFieldResponse : ''}
								onChange={this.handleTextInputChange.bind(this, fieldIndex, 'textFieldResponse', fieldInputs.textFieldResponseType)}
								placeholder={fieldInputs.textPlaceholderText} />
						</div>
						<div className="col-md-2">
							<ReactSelect
								isDisabled={action == 'view'}
								name="text_dropdown"
								styles={SharedStyles.selectBoxStyles}
								options={dropDownOptions}
								value={_.find(dropDownOptions, { value: fieldInputs.dropDownResponse })}
								autosize={true}
								onChange={(selectedOption) => this.handlePromptResponseChanges(fieldIndex, 'dropDownResponse', selectedOption.value)}
								placeholder={fieldInputs.dropdownPlaceholderText} />
						</div>
					</div>
					<p className="text-danger">{fieldErrors[fieldInputs.uniqueID] &&
						<span className="err">{fieldErrors[fieldInputs.uniqueID]}</span>}</p>
				</div>
			</div>
		);
	}

	renderByFieldType(fieldInputs, fieldIndex) {

		if (fieldInputs.fieldType == 'text_area') {
			return this.renderTextAreaField(fieldInputs, fieldIndex);
		}
		else if (fieldInputs.fieldType == 'text_and_dropdown') {
			return this.renderTextAndDropdown(fieldInputs, fieldIndex);
		};
		return null;
	}

	renderFocusAreaList() {

		const { sectionData, displayTimescale } = this.state;

		if (displayTimescale != 'daily') { // For "daily" timescale, we don't need focusAreas. 
			return (
				<div style={{marginTop:35}}>
					<hr />
					<div className="mt-4">
						{sectionData.isResponded && sectionData.sectionResponse && sectionData.sectionResponse.id ?
							<FocusAreasList
								sectionData={sectionData}
								timescale={displayTimescale}
								handleFocusAreaUpdates={this.handleFocusAreaUpdates.bind(this)}
							/>
							:
							<div className="text-info small"><i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;You will be able to add the focus area(s) for this timescale as soon as you save your responses for the above (mandatory) questions.</div>
						}
					</div>
				</div>
			);
		};
		return null;
	}

	render() {

		const { startDate, endDate, sectionPrompts, sectionData, action, displayTimescale, notesEditorState } = this.state;

		if (!sectionData || _.isEmpty(sectionData)) return null;

		return (
			<div>
				{sectionPrompts.map((value, index) => {
					return <div className="row" key={index}>
						<div className="col-md-12">
							{this.renderByFieldType(value, index)}
						</div>
					</div>
				})}
				<div className="form-group">
					<p>Notes</p>
					<div className="htmlEditor" style={{backgroundColor: action == 'view' ? '#f6f6f6': 'transparent'}}>
						<Editor
							readOnly={action == 'view'}
							editorState={notesEditorState}
							toolbarClassName={action == 'view' ? "d-none" : "toolbarClassName"}
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={(editorState) =>
								this.setState({
									notesEditorState: editorState,
								})
							}
						/>
					</div>
				</div>
				{displayTimescale != 'daily' ? // For "daily" timescale, we don't need startDate and endDate
					<div className="form-group">
						<div className="row">
							<div className="col-lg-6">
								<p>Start Date</p>
								<DatePicker
									selected={startDate}
									onChange={this.handleDateChange.bind(this)}
									minDate={new Date()}
									placeholderText="Start Date"
									className="form-control"
									isClearable={action == 'edit' ? true : false}
									disabled={action == 'view'}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dateFormat="MMMM d, yyyy"
									dropdownMode="select"
								/>
							</div>
							<div className="col-lg-6">
								<div className="d-lg-none" style={{ margin: "8px" }}></div>
								<p>End Date</p>
								<DatePicker
									selected={endDate}
									placeholderText="End Date"
									className="form-control"
									disabled={true}
									dateFormat="MMMM d, yyyy"
									dropdownMode="select"
								/>
							</div>
						</div>
					</div>
				: null}
				<div style={{marginTop: 35}}>
					{action == "view" ?
						<button type="button" className="btn btn-primary" onClick={() => this.setState({ action: 'edit' })}><i className="fa fa-pencil" aria-hidden="true"></i> Edit Your Responses</button> :
						<button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Save Changes</button>
					}
				</div>
				{this.renderFocusAreaList()}
			</div>
		);
	}
}

Timescale.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	Timescale
);