import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { getTaskByID } from "../../actions/taskActions";
import { setLoading } from "../../actions/loadingActions.js";
import Util from '../../utils/Util';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var moment = require('moment');

class ViewTask extends Component {

	constructor(props) {
		super(props);
		this.state = {
			notesEditorState: EditorState.createEmpty(),
			gotTaskDetails: false,
		}
	}

	componentDidMount() {
		this.props.getTaskByID(this.props.taskID);
	}

	componentDidUpdate(prevProps) {

		if (!this.state.gotTaskDetails) {

			this.setState({
				gotTaskDetails: true,
				notesEditorState: this.props.tasks.task.notes ? EditorState.createWithContent(convertFromRaw(this.props.tasks.task.notes)) : EditorState.createEmpty(),
			});
		}
	}

	render() {

		const { task } = this.props.tasks;

		if (!Util.isEmpty(task)) {

			return (
				<div className="section-container">
					<table className="table table-curved" style={{width: '100%'}}>
						<thead>
							<tr>
								<th className="text-center" style={{width: '35%'}}>Item</th>
								<th className="text-center" style={{width: '65%'}}>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><span className="small-title-text">Task Name</span></td>
								<td><span className="small-title-text">{task.task}</span></td>
							</tr>
							<tr>
								<td><span className="small-title-text">Created At</span></td>
								<td><span className="small-title-text">{moment(task.createdAt).format('MM/DD/YYYY')}</span></td>
							</tr>
							<tr>
								<td><span className="small-title-text">Time Estimate</span></td>
								<td><span className="small-title-text">{Util.convertTimeToReadableText(task.timeEstimateInMinutes)}</span></td>
							</tr>
							<tr>
								<td><span className="small-title-text">Status</span></td>
								<td><span className="small-title-text">
									{task.isCompleted ? 
										<span><i className="fa fa-check-circle fa-lg color-primary mr-2" aria-hidden="true"></i>Completed</span> :
									    <span><i className="fa fa fa-circle-o fa-lg color-primary mr-2" aria-hidden="true"></i>Open</span>
									}
									</span>
								</td>
							</tr>
							{task.isCompleted && task.actualCompletionTimeInMinutes? 
								<tr>
									<td><span className="small-title-text">Actual Time</span></td>
									<td><span className="small-title-text">{Util.convertTimeToReadableText(task.actualCompletionTimeInMinutes)}</span></td>
								</tr>
							:null}
							{task.isCompleted && task.completionDateTime? 
								<tr>
									<td><span className="small-title-text">Completed At</span></td>
									<td><span className="small-title-text">{moment(task.completionDateTime).format('MM/DD/YYYY')}</span></td>
								</tr>
							:null}
							{task.outcome? 
								<tr>
									<td><span className="small-title-text">Project</span></td>
									<td><a href={'/projects/edit/' + task.outcome.id} target='_blank' style={SharedStyles.titleLink}>{task.outcome.abbreviation}</a></td>
								</tr>
							: null}
							{task.outcome && task.keyActivity && task.keyActivity.id && task.keyActivity.title ? 
								<tr>
									<td><span className="small-title-text">Key Activity</span></td>
									<td><a href={'/key-activities/edit/' + task.keyActivity.id + '?outcomeID=' + task.outcome.id} target='_blank' style={SharedStyles.titleLink}>{task.keyActivity.title}</a></td>
								</tr>
							: null}
							<tr>
								<td><span className="small-title-text">Flags</span></td>
								<td><span className="small-title-text">
									{task.flags && task.flags.length > 0 ? 
										<span><i className="fa fa-flag fa-lg color-primary mr-2" aria-hidden="true"></i>Flagged</span> :
									    <span><i className="fa fa-flag-o fa-lg color-primary mr-2" aria-hidden="true"></i>Not flagged</span>
									}
									</span>
								</td>
							</tr>
							{task.calendarSchedules && task.calendarSchedules.length > 0 ?
								<tr>
									<td><span className="small-title-text">Calendar Schedules</span></td>
									<td className="pb-0">
										<div className="small-title-text pr-0">
											{task.calendarSchedules.map((item, index) => {
												return(
													<div key={'calendar_schedules_' + index} className="mb-2">
														<span><i className="fa fa-calendar color-primary mr-2" aria-hidden="true"></i></span>
														<span>{moment(item.eventStartTime).format(item.isAllDayTask ? 'ddd, ll' : 'llll')}</span>
														<span>&nbsp;-&nbsp;</span>
														{item.isAllDayTask === true ? <span>All Day </span> : <span>{moment(item.eventEndTime).format('h:mm A')}</span>}
													</div>
												);
											})}
										</div>
									</td>
								</tr>
							: null}
						</tbody>
					</table>
					<h6 className="mt-4 mb-3">Notes:</h6>
					<div className="htmlEditor">
						<Editor
							editorState={this.state.notesEditorState}
							toolbarHidden
							readOnly
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
						/>
					</div>
				</div>
			);
		} 
		else {
			return (
				<div className="container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="text-center">
						<i class="fa fa-spinner fa-spin fa-2x color-primary"></i>
					</div>
				</div>
			);
		}
	}
}

ViewTask.propTypes = {
	getTaskByID: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	tasks: state.tasks,
});

export default connect(mapStateToProps, { getTaskByID, setLoading })(
	ViewTask
);
