import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getFocusAreaQuestions, getFocusAreasForTheUser } from "../../actions/focusAreaActions";
import { setLoading } from "../../actions/loadingActions.js";
import FocusAreasList from "./FocusAreasList";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class FocusAreaDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		this.props.getFocusAreaQuestions();
		this.getFocusAreasForTheUser();
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getFocusAreasForTheUser() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getFocusAreasForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if(response.data.status) {
					this.props.getFocusAreasForTheUser(response);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	renderFocusAreaList() {

		const { focusarearesponses, focusareaquestions } = this.props.focusareas;

		if (focusareaquestions && focusareaquestions.length > 0 && focusarearesponses && focusarearesponses.length > 0) {
			return (
				<FocusAreasList
					focusareas={this.props.focusareas}
				/>
			);
		} else {
			return(<p className="text-center">Please add focus area using the above button</p>)
		}
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/focus-areas/">Focus Areas</a>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="inner-container">
							<div className="text-center">
								<Link to="/focus-areas/add" className="btn btn-primary mb-2" title="Add Focus Area">Add Focus Area <i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="inner-container-table" style={{marginTop: 20}}>
								{this.renderFocusAreaList()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

FocusAreaDashboard.propTypes = {
	getFocusAreaQuestions: PropTypes.func.isRequired,
	getFocusAreasForTheUser: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	focusareas: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	focusareas: state.focusareas,
});

export default connect(mapStateToProps, { setLoading, getFocusAreaQuestions, getFocusAreasForTheUser })(
	FocusAreaDashboard
);
