import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import { getCollaboratorsForTheUser } from "../../actions/collaboratorActions";
import API_Services from '../../utils/API_Services';
import AddCollaborator from './AddCollaborator';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class CollaboratorsSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			addRRModal: false,
			formApiResponse: {
				className: "",
				message: "",
			},
			collaboratorsError: "",
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getCollaboratorsForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getCollaboratorsForTheUser(response);
				} else {
					// this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Search CollaboratorForTheUser
	searchCollaboratorForTheUser(searchString) {

		this.setState({
			formApiResponse: {
				className: "",
				message: "",
			},
		});

		this.props.setLoading(true);

		var endPoint = UrlConstants.URLS.searchCollaboratorForTheUser;

		var postObj = searchString;

		API_Services.httpPOST(endPoint, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getCollaboratorsForTheUser(response);
				} else {
					// this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	};

	// Handler for Collaborator change event
	handleCollaboratorsSelect(name, value) {
		this.props.handleCollaboratorsSelect(name, value);
	}

	// Handler for Collaborator name search
	handleCollaboratorInputChange(value) {

	}

	toggleAddRRModal() {

		if (!this.props.isParentEdit) {
			this.setState({
				collaboratorsError: "Please save your project before creating collaborators.",
			})
		} else {
			if (this.state.addRRModal) {
				this.setState({
					addRRModal: false,
				});
			} else {
				this.setState({
					addRRModal: true,
				});
			}
		}
	}

	selectAddedRR(data) {

		var newData = [...this.props.value, data];
		this.props.handleCollaboratorsSelect("collaborators", newData);
		this.setState({
			addRRModal: false,
		});
	}

	renderAddCollaboratorFormInModal() {
		return (
			<div>
				<Modal isOpen={this.state.addRRModal} toggle={this.toggleAddRRModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddRRModal.bind(this)}>Add Collaborator </ModalHeader>
					<ModalBody>
						<AddCollaborator
							selectAddedRR={this.selectAddedRR.bind(this)} />
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		const { collaborators } = this.props.collaborators;
		const { formApiResponse, collaboratorsError } = this.state;

		var collaboratorsOptions = [];

		if (collaborators.length > 0) {
			collaboratorsOptions = collaborators.map(e => ({ value: e.id, label: e.name }));
		}

		return (
			<div>
				<div className="form-group">
					<label>Collaborators {this.props.isRequired ? <span className="text-danger">*</span> : null}</label>
					<div className="row">
						<div className="col-11">
							<ReactSelect
								name="collaborators"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={collaboratorsOptions}
								value={this.props.value}
								removeSelected={true}
								autosize={true}
								isMulti={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleCollaboratorsSelect.bind(this, 'collaborators')}
								onInputChange={this.handleCollaboratorInputChange.bind(this)}
								placeholder="Select Collaborator"
								isDisabled={this.props.disabled}
							/>
						</div>
						<div className="col-1 text-right">
							<div>
								<button type="button" onClick={this.toggleAddRRModal.bind(this)} className="btn btn-primary btn-sm cursor-pointer"><i className="fa fa-plus" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
					{
						collaboratorsError
							? <p className="text-warning">{collaboratorsError}</p>
							: null
					}
				</div>
				{this.renderAddCollaboratorFormInModal()}
			</div>

		);
	}
}

CollaboratorsSelect.propTypes = {
	getCollaboratorsForTheUser: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	collaborators: state.collaborators,
});

export default connect(mapStateToProps, { getCollaboratorsForTheUser, setLoading })(
	CollaboratorsSelect
);
