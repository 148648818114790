import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import ReactTooltip from 'react-tooltip';
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import DailyTrackerResponse from "../../assets/api_responses/DailyTrackerResponse.json";
import DetailedInfoPopup from './DetailedInfoPopup';
import TaskStatus from "../tasks/TaskStatus";
import ViewUTask from "../tasks/ViewTask";
import Util from '../../utils/Util';
import { history } from '../../utils/history';
import GCFilterOptions from '../common/GCFilterOptions.js'

var _ = require('lodash');
var qs = require('qs');
var UrlConstants = require('../../utils/UrlConstants');

const MODE = 'LIVE'; // UNIT_TESTING or LIVE

class DailyTracker extends Component {

	constructor(props) {
		super(props);
		this.state = {
			todaysDate: moment().startOf('day'),
			targetDate: moment().startOf('day'),
			clientTimeZone: momentTimezone.tz.guess(),
			events: null,
			outcomeMetrics: null,
			dailyTasks: null,
			eventObj: null,
			utasks: [],
			showDetailedInfoModal: false,
			showAddTaskModalForDailyTasks: false,
			showAddTaskModalForEventTasks: false,
			viewTaskModal: false,
			itemToView: null,
			eventNotesEditorState: {}
		};
		this.metricRefs = {};
		this.getUserPreferences();
		this.getUTasks();
	}

	componentDidMount() {
		
		this.setStickyToolBar();
		
		/* ToDo:
			1. If the 'date' is passed as part of request URL '/tracker/d/:date', use that as target date. Else, consider today's date as target date.
		*/

		if (MODE == 'UNIT_TESTING') {
			this.setState({
				events: DailyTrackerResponse.events,
				outcomeMetrics: DailyTrackerResponse.outcomeMetrics
			});
		}
		else {

			let { targetDate } = this.state; 
			let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

			if (queryParams.date && moment(queryParams.date, 'MM-DD-YYYY', true).isValid()) {
				targetDate = moment(queryParams.date, 'MM-DD-YYYY').startOf('day');
			};

			this.setState({
				targetDate: targetDate
			},
			() => {
				history.replace(`/tracker/d/?date=${moment(targetDate).format('MM-DD-YYYY')}`);
				this.getTrackerForTheDate();
			});
		}
	}

	// get User Preferences
	getUserPreferences() {

        this.props.setLoading(true, 'getUserPreferences');

		API_Services.httpGET(UrlConstants.URLS.getUserPreferences, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					let data = response.data.data;
					let metaData = response.data.metaData;

					if (metaData && metaData.message) {
						alert(metaData.message);
					};

					this.setState({
						userPreference: data,
						GCMetaData: metaData,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || []
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, false);
				};
			}
			else {
				this.setFormApiResponse("Something went wrong!", true);
            };
            this.props.setLoading(false, 'getUserPreferences');
        });
	}

	getTrackerForTheDate() {

		this.props.setLoading(true, 'getTrackerForTheDate');

		this.setState({
			events: null
		});

		let postObj = {
			targetDate: this.state.targetDate,
			twoWaySyncRequired: true,
			clientTimeZone: this.state.clientTimeZone
		};

        API_Services.httpPOST(UrlConstants.URLS.getTrackerForTheDate, postObj, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
					let responseData = response.data.data;
					let events = responseData.events || [];
					let eventNotesEditorState = {};

					// Initialize the eventNotesEditorState variable. If notes exist for an event, convert it from raw and assign else initialize with an empty editor state.
					for (var i=0; i < events.length; i++) {
						let notesContent = events[i].notes ? EditorState.createWithContent(convertFromRaw(events[i].notes)) : EditorState.createEmpty();
						if (events[i].globalEventID) eventNotesEditorState[events[i].globalEventID] = notesContent;
					};

                    this.setState({
						events: events,
						outcomeMetrics: responseData.outcomeMetrics || [],
						dailyTasks: responseData.dailyTasks || null,
						responseData: responseData,
						eventNotesEditorState: eventNotesEditorState
					});
				}; 
			} 
            else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false, 'getTrackerForTheDate');
		});
	}

	getUTasks() {

        this.props.setLoading(true, 'getUTasks');

		API_Services.httpGET(UrlConstants.URLS.getUTasks, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    this.setState({
						utasks: response.data.data
					}); 
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				};
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
            };
            
            this.props.setLoading(false, 'getUTasks');
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
        };
        
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		});
	}

	setStickyToolBar() {

		const header = document.getElementById("daily-tracker-floating-tool-bar");

		window.addEventListener("scroll", () => {
			
			if (!header) return;

			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	handlerToNavigate = (e, navigateAction) => {
		
		e.preventDefault();
		
		let { targetDate } = this.state;

		if (navigateAction === 'PREV'){
			targetDate = moment(targetDate).subtract(1, 'day');
		}
		else if(navigateAction === 'NEXT') {
			targetDate = moment(targetDate).add(1, 'day');
		}
		else if(navigateAction === 'TODAY') {
			targetDate = moment().startOf('day');
		}

		this.setState({ targetDate }, () => {
			history.replace(`/tracker/d/?date=${moment(targetDate).format('MM-DD-YYYY')}`);
			this.getTrackerForTheDate(); // fetch Daily Tracker for the specific date.
		});
	}

	handleSaveChanges() {
		
		this.props.setLoading(true, 'handleSaveChanges');

		let {responseData, events, outcomeMetrics, dailyTasks, eventNotesEditorState} = this.state;
		let defaultSaveOptions = {
			syncWith3rdPartyCalendar : true
		};

		// Convert the notes To `raw` format for saving in DB  
		for (var i=0; i < events.length; i++) {
			if (events[i].globalEventID && eventNotesEditorState && eventNotesEditorState[events[i].globalEventID]) {
				let notesContent = eventNotesEditorState[events[i].globalEventID].getCurrentContent().hasText() ? convertToRaw(eventNotesEditorState[events[i].globalEventID].getCurrentContent()) : null;
				events[i].notes = notesContent;
			};
		};

		responseData.events = events;
		responseData.outcomeMetrics = outcomeMetrics;
		responseData.dailyTasks = dailyTasks;

		let postObj = {
			trackerRecord: responseData,
			saveOptions: defaultSaveOptions
		};

        API_Services.httpPOST(UrlConstants.URLS.saveDailyTrackerResponse, postObj, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {

					let responseData = response.data.data;
					let events = responseData.events || [];
					let eventNotesEditorState = {};

					// Update the eventNotesEditorState with the DB values
					for (var i=0; i < events.length; i++) {
						let notesContent = events[i].notes ? EditorState.createWithContent(convertFromRaw(events[i].notes)) : EditorState.createEmpty();
						if (events[i].globalEventID) eventNotesEditorState[events[i].globalEventID] = notesContent;
					};

                    this.setState({
						events: events,
						outcomeMetrics: responseData.outcomeMetrics || [],
						dailyTasks: responseData.dailyTasks || null,
						responseData: responseData,
						eventNotesEditorState: eventNotesEditorState
					});
				}; 
			} 
            else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false, 'handleSaveChanges');
		});
	}

	toggleDetailedInfoModal(eventObj) {
		
		//return alert('Event info will be shown in detail here.');

		if (this.state.showDetailedInfoModal) {
			this.setState({
				eventObj: null,
				showDetailedInfoModal: false
			});
		}
		else {
			this.setState({
				eventObj: eventObj,
				showDetailedInfoModal: true
			});
		};
	}

	updateEventHandler() {

	}

	updateUserPreferencesInServer = (preferences) => {

		if (!preferences) return;

		this.props.setLoading(true, 'updateUserPreferencesInServer');

		API_Services.httpPOST(UrlConstants.URLS.updateUserPreferences, {updatedUserPreferences: preferences}, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					let data = response.data.data;

					this.setState({
						userPreference: data,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || []
					},
					() => {
						/* It is necessary to reload PBH tracker/calendar to reflect the changes  */
						this.getTrackerForTheDate();
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, false);
				};
			}
			else {
				this.setFormApiResponse("Something went wrong!", true);
            };
            
			this.props.setLoading(false, 'updateUserPreferencesInServer');
		});
	}

	renderGCFilterOptions = () => {

		const { userPreference, GCMetaData, excludedGoogleCalendarIDs } = this.state;

		return (
			<span style={{marginRight: 20}}>
				<GCFilterOptions
					GCMetaData={GCMetaData}
					userPreference={userPreference}
					excludedGoogleCalendarIDs={excludedGoogleCalendarIDs}
					onUpdate={this.updateUserPreferencesInServer}
				/>
			</span>
		)
	}

	renderHeaderToolBar() {

		const { targetDate, todaysDate } = this.state;
		let isCurrentDay = moment(todaysDate.format('L')).isSame(targetDate.format('L'));

		return (
			<div id={"daily-tracker-floating-tool-bar"}>
				<div className={"d-flex flex-wrap justify-content-between"}>
					<div className="text-left">
						<Button onClick={(e) =>{this.handlerToNavigate(e, 'TODAY')}} className={"todays-btn btn-sm"} disabled={isCurrentDay} style={{fontSize:14}}>Today </Button>
					</div>
					<div className="d-flex align-items-center justify-content-center">
						<div className="d-flex align-items-center">
							<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'PREV')}} className="btn btn-primary btn-sm border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
							<span className="week-label" > {targetDate.format('L')} </span>
							<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'NEXT')}} className="btn btn-primary btn-sm border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}><i className="fa fa-angle-right" aria-hidden="true"></i></button>
						</div>
					</div>
					<div className="text-right mt-2 mt-sm-0">
						{this.renderGCFilterOptions()}
						<Button onClick={this.handleSaveChanges.bind(this)} className="btn btn-primary btn-sm" disabled={this.state.buttonDisabled}>Save</Button>
					</div>
				</div>
			</div>
		);
	}

	toggleEventCompletion(globalEventID) {

		let {events} = this.state;
		let index = _.findIndex(events, {globalEventID: globalEventID});
  
		if (index == -1) return;

		events[index].isCompleted = !events[index].isCompleted;
		events[index].isEventUpdated = true;
		events[index].isThirdPartySyncRequired = true;
		
		this.setState({
			events: events
		});
	}

	handleMetricValueChange(section, sectionObj, metricObjIndex, e) {

		let value = e.target.value;
		let { events, outcomeMetrics, targetDate } = this.state;
		let metricsAndTargets = Util.sortMetricsBasedOnHierarchy(sectionObj.metricsAndTargets || []);
		
		if (metricsAndTargets[metricObjIndex]) {
			metricsAndTargets[metricObjIndex].response = parseInt(value);
			sectionObj.metricsAndTargets = metricsAndTargets;
		};
		
		if (section == 'outcome_metrics') {
			let targetDateWeekDay = targetDate.get('isoWeekday');
			if (outcomeMetrics && outcomeMetrics[targetDateWeekDay]) {
				let outcomeIndex = _.findIndex(outcomeMetrics[targetDateWeekDay], {id: sectionObj.id});
				if (outcomeIndex > -1) {
					outcomeMetrics[targetDateWeekDay][outcomeIndex] = sectionObj;
					this.setState({outcomeMetrics});	
				};
			};
		}
		else if (section == 'keyactivity_metrics') {
			let eventIndex = _.findIndex(events, {globalEventID: sectionObj.globalEventID});
			if (eventIndex > -1) {
				events[eventIndex] = sectionObj;
				this.setState({events});
			};	
		};
	}

	renderMetricsAndTargets(sectionObj, section) {

		if (!sectionObj.metricsAndTargets || sectionObj.metricsAndTargets.length == 0) return null;

		let metricsAndTargets = Util.sortMetricsBasedOnHierarchy(sectionObj.metricsAndTargets);

		return(
			<div className="mt-3" style={{fontSize:14}}>
				<div><u><b>Metrics</b></u></div>
				{metricsAndTargets.map((metricObj, metricObjIndex) => {
					let refID = metricObj.id + '-' + (sectionObj.globalEventID || sectionObj.id) + '-' + metricObjIndex;
					return(
						<div key={'metric_' + refID} className="mt-3">
							<div className="small-title-text mb-1">
								<span>{metricObj.title}</span>
								{!metricObj.description ? null :
									<React.Fragment>
										<span className="ml-2" onMouseEnter={() => ReactTooltip.show(this.metricRefs[refID])} onMouseLeave={() => ReactTooltip.hide(this.metricRefs[refID])}><i className="fa fa-info-circle color-primary" aria-hidden="true"></i></span>
										<span>
											<span ref={ref => this.metricRefs[refID] = ref} data-tip={metricObj.description}></span>
											<ReactTooltip backgroundColor={'#f79569'} color={'#737373'} />	
										</span>
									</React.Fragment>
								}
							</div>
							<div style={{maxWidth:500}}><input type="number" className="form-control" placeholder="Enter your data (numerical value) here..." value={_.has(metricObj, 'response') ? metricObj.response : ''} onChange={this.handleMetricValueChange.bind(this, section, sectionObj, metricObjIndex)} /></div>
						</div>
					);
				})}
			</div>
		);
	}

	toggleTaskModalForEventTasks(eventObj = null) {

        this.setState({
			showAddTaskModalForEventTasks: !this.state.showAddTaskModalForEventTasks,
			eventObjForTheTaskUpdate: eventObj
		});
    }

	handleTaskUpdateForTheEvent(eventObj, taskObj) {

        let {events} = this.state;

        let eventTasks = eventObj.tasks || [];
        let taskIndex = _.findIndex(eventTasks, {id: taskObj.id});

        if (taskIndex > -1) {
            eventTasks[taskIndex].isActive = eventTasks[taskIndex].isActive ? false : true; // When a task is added, removed, added back before saving, just toggle the active status
        }
        else {
            eventTasks.push({
                id: taskObj.id,
                task: taskObj.task,
                isActive: true
            });
        };

		eventObj.tasks = eventTasks;
		eventObj.isEventUpdated = true;
		eventObj.isThirdPartySyncRequired = true;

		let eventIndex = _.findIndex(events, {globalEventID: eventObj.globalEventID});
		if (eventIndex > -1) {
			events[eventIndex] = eventObj;
			this.setState({events});
		};
    }

	renderTasksModalPopupForEventTasks() {

        let {eventObjForTheTaskUpdate, utasks} = this.state;

		if (!eventObjForTheTaskUpdate) return null;

		let eventTasks = eventObjForTheTaskUpdate.tasks || [];

        return(
			<Modal isOpen={this.state.showAddTaskModalForEventTasks} toggle={this.toggleTaskModalForEventTasks.bind(this)}>
				<ModalHeader toggle={this.toggleTaskModalForEventTasks.bind(this)}>Edit Tasks</ModalHeader>
				<ModalBody>
                    {utasks.length > 0 ?
						<div>
							<table className="table-curved">
								<thead>
									<tr>
										<th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}></th>
										<th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '65%'}}}>Task</th>
										<th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '25%'}}}>Status</th>
									</tr>
								</thead>
								<tbody>
									{utasks.map((eachtask, index) => {
									
										let isTaskAddedToTheEvent = _.findIndex(eventTasks, {id: eachtask.id, isActive: true}) > -1 ? true : false;

										return(
											<tr key={'task_list_in_model_' + index}>
												<td className="text-center" onClick={this.handleTaskUpdateForTheEvent.bind(this, eventObjForTheTaskUpdate, eachtask)} style={{fontSize:18}}>
													{isTaskAddedToTheEvent ? <i className="fa fa-check-square-o color-primary" aria-hidden="true"></i> : <i className="fa fa-square-o color-primary" aria-hidden="true"></i>}
												</td>
												<td className="text-left"><span>{eachtask.task.substring(0, 12)}{(eachtask.task.length > 12) ? '...' : ''}</span> <span className="small">({Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)})</span></td>
												<td className="text-left small">{eachtask.isCompleted ? 'Completed' : 'Open'}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="mt-4 mb-2 text-center" onClick={this.toggleTaskModalForEventTasks.bind(this)}><button className="btn btn-primary btn-sm">Done</button></div>
						</div>
					: 
						<div className="small">No task(s) found.</div>
					}
				</ModalBody>
			</Modal>
		)
    }

	renderEventTasks(eventObj) {

		let eventTasks = _.filter(this.state.utasks, (item) => _.findIndex(eventObj.tasks || [], {id: item.id, isActive: true}) > -1 ? true : false); // Filter only configured tasks.

		return(
			<div className="ml-4 mt-3" style={{fontSize:14}}>
				<div className="d-flex align-items-center justify-content-between">
					<div><u><b>Tasks</b></u></div>
					<button type="button" className="btn btn-primary btn-xs p-1" onClick={this.toggleTaskModalForEventTasks.bind(this, eventObj)}><i className="fa fa-gear" aria-hidden="true" title="Edit Tasks"></i></button>
				</div>
				{eventTasks.length > 0 ?
					eventTasks.map((taskObj, taskIndex) => {
						return(
							<div key={'event_tasks_' + eventObj.globalEventID + taskObj.id} className="mt-3 event-info-wrapper" style={{backgroundColor:'#fff'}}>
								<div className="event-info-timer mb-2">
									<span><i className="fa fa-clock-o mr-2" aria-hidden="true" style={styles.clockIcon}></i></span>
									<span>{Util.convertTimeToReadableText(taskObj.timeEstimateInMinutes)}</span>
								</div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center">
										<TaskStatus 
											taskObj={taskObj} 
											setApiResponse={this.setFormApiResponse.bind(this)}
											onTaskUpdate={this.onTaskUpdate.bind(this)} />
										<div className="ml-2" style={{fontSize:16}}>{taskObj.task}</div>
									</div>
									<div>
										<button className="btn btn-outline-info btn-xs mr-2 cursor-pointer p-1" onClick={this.toggleViewTaskModal.bind(this, taskObj.id)}>
											<i className="fa fa-info-circle" aria-hidden="true" title="View Task"></i> 
										</button>
										<button className="btn btn-outline-danger btn-xs mr-2 cursor-pointer p-1" onClick={this.handleTaskUpdateForTheEvent.bind(this, eventObj, taskObj)}>
											<i className="fa fa-minus-circle" aria-hidden="true" title="Remove Task"></i> 
										</button>
									</div>
								</div>
							</div>
						);
					})
				: <div className="small mt-3">No task(s) has been added for this event.</div> }	
			</div>
		);
	}

	handleNotesUpdate(eventObj, notesEditorState) {

		let {events, eventNotesEditorState} = this.state;

		if (!eventNotesEditorState) eventNotesEditorState = {};

		let eventIndex = _.findIndex(events, {globalEventID: eventObj.globalEventID});
		
		if (eventIndex > -1) {
			events[eventIndex].isEventUpdated = true; 
			events[eventIndex].isThirdPartySyncRequired = true; // Update the changes in GC.
			eventNotesEditorState[eventObj.globalEventID] = notesEditorState;
			this.setState({
				events: events, 
				eventNotesEditorState: eventNotesEditorState
			});
		};
	}

	renderEventNotes(eventObj) {

		let {eventNotesEditorState} = this.state;
		let editorState = eventObj.globalEventID && eventNotesEditorState[eventObj.globalEventID] ? eventNotesEditorState[eventObj.globalEventID] : null;

		return(
			<div className="ml-4 mt-3" style={{fontSize:14}}>
				<div><u><b>Notes</b></u></div>
				<div className="htmlEditor mt-3" style={{backgroundColor:'#fff'}}>
					<Editor
						toolbar={{
							options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji']
						}}
						editorState={editorState}
						toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName"
						editorClassName="editorClassName"
						onEditorStateChange={(editorState) => this.handleNotesUpdate(eventObj, editorState)}
						placeholder="Add your notes here..."
					/>
				</div>
			</div>
		);
	}

	renderEventsListView() {

		let {events, targetDate, clientTimeZone} = this.state;

		if (!events) {
			return(<p></p>);
		};

		events = _.filter(events, (item) => { // Filter events for the target date.
			return momentTimezone(item.start).tz(clientTimeZone).startOf('day').format() == targetDate.format();
		});

		events = _.sortBy(events, 'start'); // Display the events for the day in chronological order.

		let completedEvents = _.filter(events, (item) => item.isCompleted);
		let pendingEvents = _.filter(events, (item) => !item.isCompleted);

		let completedTime = _.sumBy(completedEvents, 'durationInMinutes');
		let pendingTime = _.sumBy(pendingEvents, 'durationInMinutes');

		if (events.length == 0) {
			return(
				<p className='small'>You don't have any scheuled events.</p>
			);
		};

		return(
			<div>
				<div className="event-info-wrapper mb-4" style={styles.statsContainer}>
					<div className="small mb-1">Completed Time: <span className="font-bold">{Util.convertTimeToReadableText(completedTime)}</span></div>
					<div className="small mb-0">Pending Time: <span className="font-bold">{Util.convertTimeToReadableText(pendingTime)}</span></div>
				</div>
				{events.map((eventObj, index) => {
					
					if (eventObj.isEventHiddenInPBH) return false; // Hide the events from unselected calendar.

					let title = eventObj.timeBlockTitle || eventObj.title;
					let isNonWeeklyTB = eventObj.regularity == 'non-weekly' ? true : false;

					if (eventObj.outcomeAbbr && eventObj.keyActivityTitle) {
						title += ' (' + eventObj.outcomeAbbr + ': ' + eventObj.keyActivityTitle + ')';
					};
					return(
						<div key={'events_' + index} className="event-info-wrapper">
							<div className="d-flex align-items-center justify-content-between mb-3">
								<div className="event-info-timer">
									<span><i className="fa fa-clock-o mr-2" aria-hidden="true" style={styles.clockIcon}></i></span>
									<span>{moment(eventObj.start).format('hh:mm A')}</span>
									<span> - </span>
									<span>{moment(eventObj.end).format('hh:mm A')}</span>
								</div>
								<div style={{fontSize:12}}>
									{eventObj.isUnplanned && !eventObj.isNonPBHEvent && <div><span className="unplanned-event-legend mr-2"></span>Unplanned (W)</div>}
									{
										!eventObj.isUnplanned && !eventObj.isNonPBHEvent &&
										<div><span className="planned-event-legend mr-2" style={isNonWeeklyTB ? {backgroundColor: '#FAB95E'} : {}}></span>Planned {isNonWeeklyTB ? '(NW)' : '(W)'}</div>
									}

									{eventObj.isNonPBHEvent && <div><span className="planned-event-legend mr-2" style={{backgroundColor: '#F5B28B'}}></span>Non-PBH</div>}
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div>
									<span className="mr-2 cursor-pointer" onClick={this.toggleEventCompletion.bind(this, eventObj.globalEventID)}>
										{eventObj.isCompleted ?
											<i className="fa fa-check-circle fa-lg color-primary" aria-hidden="true"></i> :
											<i className="fa fa fa-circle-o fa-lg color-primary" aria-hidden="true"></i>
										}
									</span>
									<span style={{fontSize:16}}><b>{title}</b></span>
								</div>
								<button className="btn btn-outline-info btn-xs cursor-pointer p-1" onClick={this.toggleDetailedInfoModal.bind(this, eventObj)}>
									<i className="fa fa-info-circle" aria-hidden="true" title="Event Info"></i>
								</button>
							</div>
							<div className="ml-4">
								{eventObj.isCompleted ? this.renderMetricsAndTargets(eventObj, 'keyactivity_metrics') : null}
							</div>
							{this.renderEventTasks(eventObj)}
							{this.renderEventNotes(eventObj)}
							<div className="mt-3">
								{
									eventObj.isNonPBHEvent && 
									<span title="Calendar">
										<i className="fa fa-calendar alt mr-2" aria-hidden="true" style={styles.clockIcon}></i><span style={{fontSize:12, wordBreak: 'break-word'}}>{eventObj.googleCalendarDisplayName || "Primary"}, {eventObj.googleCalendarEmail} </span>
										<br/>
									</span>
								}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	renderProjectMetrics() {

		let {outcomeMetrics, targetDate} = this.state;

		let targetDateWeekDay = targetDate.get('isoWeekday');
        outcomeMetrics = outcomeMetrics && outcomeMetrics[targetDateWeekDay] ? outcomeMetrics[targetDateWeekDay] : [];

		if (!outcomeMetrics || outcomeMetrics.length == 0) return null;

		return(
			<div>
				<h6 className="my-4">Project Metrics</h6>
				{outcomeMetrics.map((outcomeObj, index) => {
					return(
						<div key={'outcome_metrics_' + index} className="event-info-wrapper">
							<div style={{fontSize:16}}><b>{outcomeObj.title}</b></div>
							{this.renderMetricsAndTargets(outcomeObj, 'outcome_metrics')}
						</div>
					);
				})}
			</div>
		);
	}

	toggleTaskModalForDailyTasks() {

        this.setState({
			showAddTaskModalForDailyTasks: !this.state.showAddTaskModalForDailyTasks
		});
    }

	toggleTaskSelection(dateID, taskObj) {

		let dailyTasks = this.state.dailyTasks || {};
		let tasksForTheDay = dailyTasks[dateID] || [];

		let taskIndex = _.findIndex(tasksForTheDay, {id: taskObj.id});

		if (taskIndex > -1) {
			tasksForTheDay[taskIndex].isActive = tasksForTheDay[taskIndex].isActive ? false : true; // If the task already added, just toggle its active status.
		}
		else {
			tasksForTheDay.push({
				task: taskObj.task, 
				id: taskObj.id,
				isActive: true
			});
		};

		dailyTasks[dateID] = tasksForTheDay;

        this.setState({
			dailyTasks: dailyTasks
		});
    }

	handleRemoveTaskForTheDay = (dateID, taskID) => {

		let dailyTasks = this.state.dailyTasks || {};
		dailyTasks[dateID] = dailyTasks[dateID] || [];

		let taskIndex = _.findIndex(dailyTasks[dateID], {id: taskID});

		if (taskIndex > -1) {
		
			dailyTasks[dateID][taskIndex].isActive = false;

			this.setState({
				dailyTasks: dailyTasks
			});
		};
		// else If the task not even added for the day, no action needed.
	}
	
	onTaskUpdate(updatedTaskObj) {

		const { utasks } = this.state;
		var taskObjIndex = _.findIndex(utasks, { id: updatedTaskObj.id });
		
		if (taskObjIndex > -1) {
			utasks[taskObjIndex] = _.assign(utasks[taskObjIndex], updatedTaskObj);
			this.setState({
				utasks: utasks
			});
		};
	}

	renderTasksModalPopupForDailyTasks() {

        let {utasks, dailyTasks, targetDate} = this.state;
        let dateIdentifier = moment(targetDate).format('MMDDYYYY').toString();
		let tasksForTheDay = [];

		if (dailyTasks && dailyTasks[dateIdentifier]) {
			tasksForTheDay = dailyTasks[dateIdentifier];
		};

        return(
			<Modal isOpen={this.state.showAddTaskModalForDailyTasks} toggle={this.toggleTaskModalForDailyTasks.bind(this)}>
				<ModalHeader toggle={this.toggleTaskModalForDailyTasks.bind(this)}>Edit Tasks</ModalHeader>
				<ModalBody>
                    {utasks.length > 0 ?
						<div>
							<table className="table-curved">
								<thead>
									<tr>
										<th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}></th>
										<th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '65%'}}}>Task</th>
										<th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '25%'}}}>Status</th>
									</tr>
								</thead>
								<tbody>
									{utasks.map((eachtask, index) => {
									
										let isTaskAddedToTheDay = _.findIndex(tasksForTheDay, {id: eachtask.id, isActive: true}) > -1 ? true : false;

										return(
											<tr key={'task_list_in_model_' + index}>
												<td className="text-center" onClick={this.toggleTaskSelection.bind(this, dateIdentifier, eachtask)} style={{fontSize:18}}>
													{isTaskAddedToTheDay ? <i className="fa fa-check-square-o color-primary" aria-hidden="true"></i> : <i className="fa fa-square-o color-primary" aria-hidden="true"></i>}
												</td>
												<td className="text-left"><span>{eachtask.task.substring(0, 12)}{(eachtask.task.length > 12) ? '...' : ''}</span> <span className="small">({Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)})</span></td>
												<td className="text-left small">{eachtask.isCompleted ? 'Completed' : 'Open'}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="mt-4 mb-2 text-center" onClick={this.toggleTaskModalForDailyTasks.bind(this)}><button className="btn btn-primary btn-sm">Done</button></div>
						</div>
					: 
						<div className="small">No task(s) found.</div>
					}
				</ModalBody>
			</Modal>
		)
    }

	renderDailyTasks() {

		let {utasks, dailyTasks, targetDate} = this.state;
		let dateIdentifier = moment(targetDate).format('MMDDYYYY').toString();
		let tasksForTheDayJSX = [];

		if (dailyTasks && dailyTasks[dateIdentifier]) {
			let tasksForTheDay = dailyTasks[dateIdentifier];
			tasksForTheDay = _.filter(utasks, (item) => _.findIndex(tasksForTheDay, {id: item.id, isActive: true}) > -1 ? true : false); // Filter only configured tasks.
		
			_.map(tasksForTheDay, (taskObj, index) => {
				tasksForTheDayJSX.push(
					<div key={'daily_tasks_' + index} className="event-info-wrapper">
						<div className="event-info-timer mb-2">
							<span><i className="fa fa-clock-o mr-2" aria-hidden="true" style={styles.clockIcon}></i></span>
							<span>{Util.convertTimeToReadableText(taskObj.timeEstimateInMinutes)}</span>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<TaskStatus 
									taskObj={taskObj} 
									setApiResponse={this.setFormApiResponse.bind(this)}
									onTaskUpdate={this.onTaskUpdate.bind(this)} />
								<div className="ml-2" style={{fontSize:16}}>{taskObj.task}</div>
							</div>
							<div>
								<button className="btn btn-outline-info btn-xs mr-2 cursor-pointer p-1" onClick={this.toggleViewTaskModal.bind(this, taskObj.id)}>
									<i className="fa fa-info-circle" aria-hidden="true" title="View Task"></i> 
								</button>
								<button className="btn btn-outline-danger btn-xs mr-2 cursor-pointer p-1" onClick={this.handleRemoveTaskForTheDay.bind(this, dateIdentifier, taskObj.id)}>
									<i className="fa fa-minus-circle" aria-hidden="true" title="Remove Task"></i> 
								</button>
							</div>
						</div>
					</div>
				);
			});
		};

		return(
			<div>
				<div className="d-flex align-items-center justify-content-between my-4">
					<h6 className="mb-0">Tasks</h6>
					<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleTaskModalForDailyTasks.bind(this)}><i className="fa fa-gear" aria-hidden="true" title="Edit Tasks"></i></button>
				</div>
				{tasksForTheDayJSX.length > 0 ? tasksForTheDayJSX : <p className="small">No task(s) has been added for this day.</p>}
			</div>
		);
	}

	// Show or hide view modal
	toggleViewTaskModal(rowId) {

		if (this.state.itemToView) {
			this.setState({
				viewTaskModal: false,
				itemToView: null,
			});
		} 
		else {
			this.setState({
				viewTaskModal: true,
				itemToView: rowId,
			});
		}
	}

	// Render view modal
	renderViewTaskInModal() {

		return(
			<div>
				<Modal size={'lg'} isOpen={this.state.viewTaskModal} toggle={this.toggleViewTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleViewTaskModal.bind(this)}>
						View Task
					</ModalHeader>
					<ModalBody>
						<ViewUTask taskID={this.state.itemToView} />
					</ModalBody>
				</Modal>
			</div>
		);
	}

	render() {

		return (
            <div className="page-content container child-container">
                <div className="bread-crumb">
                    <a href="/tracker/d/">Daily Tracker</a>
                </div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								{this.renderHeaderToolBar()}
								<h6 className="my-4">Events</h6>
								{this.renderEventsListView()}
								{this.renderProjectMetrics()}
								{this.renderDailyTasks()}
								<DetailedInfoPopup
									showMore={false}
									showDetailedInfoModal={this.state.showDetailedInfoModal}
									detailedInfo={this.state.eventObj}
									toggleDetailedInfoModal={this.toggleDetailedInfoModal.bind(this)}
									updateEvent={this.updateEventHandler.bind(this)}
								/>
								{this.renderTasksModalPopupForDailyTasks()}
								{this.renderTasksModalPopupForEventTasks()}
								{this.renderViewTaskInModal()}
							</div>
						</div>
					</div>
				</div>
            </div>
		);
	}
}

DailyTracker.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	DailyTracker
);

const styles = {

	clockIcon: {
		color: '#737373',
		fontSize: 15
	},
	statsContainer: {
		backgroundColor: '#fef2ec',
		border: '2px solid #f9ded1',
		width: 'fit-content'
	}
}