import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class CreationMaintenanceSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			creationMaintenance: [],
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		var postObj = {
			"category": "Creation:Maintenance"
		};

		API_Services.httpPOST(UrlConstants.URLS.getPlatformConstants, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					if (response.data.data && response.data.data.value && response.data.data.value.length > 0) {

						let creationMaintenance = [];

						response.data.data.value.forEach((value, index) => {
							creationMaintenance.push({
								label: value,
								value: value,
							});
						})
						this.setState({
							creationMaintenance: creationMaintenance,
						});
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Search CreationMaintenance
	searchCreationMaintenance(searchString) {

		// this.setState({
		// 	formApiResponse: {
		// 		className: "",
		// 		message: "",
		// 	},
		// });

		// this.props.setLoading(true);

		// var endPoint = UrlConstants.URLS.searchCreationMaintenance;

		// var postObj = searchString;

		// API_Services.httpPOST(endPoint, postObj, (err, response) => {

		// 	if (err) {
		// 		if (err.response && err.response.data) {
		// 			this.setFormApiResponse(err.response.data, true)
		// 		} else {
		// 			this.setFormApiResponse("Something went wrong!", true);
		// 			console.log('err...', err);
		// 		}
		// 	} else if (response.data) {

		// 		this.setState({
		// 			creationMaintenance: response.data
		// 		});
		// 	} else {
		// 		this.setFormApiResponse("Something went wrong!", true);
		// 	}

		// 	this.props.setLoading(false);
		// });
	};

	// Handler for creationMaintenance change event
	handleCreationMaintenanceSelect(name, value) {
		this.props.handleCreationMaintenanceSelect(name, value);
	}

	// Handler for creationMaintenance name search
	handleCreationMaintenanceInputChange(value) {

		if (value) {

			// Search creationMaintenance only after user stops typing
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.searchCreationMaintenance({ searchString: value });
			}, 300);
		}
	}

	render() {

		const { creationMaintenance, formApiResponse } = this.state;

		var creationMaintenanceOptions = [];

		if (creationMaintenance.length > 0) {
			creationMaintenanceOptions = creationMaintenance.map(e => ({ value: e.value, label: e.label }));
		}

		return (
			<div className="form-group">
				<label>Creation:Maintenance {this.props.isRequired ? <span className="text-danger">*</span> : null }</label>
				<div>
					<ReactSelect
						name="creationMaintenance"
						styles={SharedStyles.selectBoxStyles}
						closeOnSelect={false}
						options={creationMaintenanceOptions}
						value={this.props.value}
						removeSelected={true}
						autosize={true}
						clearable={true}
						onSelectResetsInput={true}
						onChange={this.handleCreationMaintenanceSelect.bind(this, 'creationMaintenance')}
						onInputChange={this.handleCreationMaintenanceInputChange.bind(this)}
						placeholder=" Select Creation:Maintenance"
						isDisabled={this.props.disabled}
					/>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</div>
			</div>
		);
	}
}

CreationMaintenanceSelect.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	CreationMaintenanceSelect
);
