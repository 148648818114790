import {
	SUBSCRIPTIONS,
} from "../actions/types.js";

const initialState = {
	usersubscriptions: [],
};

export default function (state = initialState, action) {

	switch (action.type) {

		case SUBSCRIPTIONS:
			return {
				...state,
				usersubscriptions: action.payload,
			};

		default:
			return state;
	}
}
