import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { setLoading } from '../../actions/loadingActions.js';
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from "react-router-dom";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

import Util from '../../utils/Util';
var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');
const AUTO_SAVE_INTERVAL = 10000;

class CompassTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusAreaTitle: '',
				type: 'custom'
			},
			formErrors: {
				focusAreaTitle: null,
			},
			formApiResponse: {
				className: "",
				message: "",
			},
			focusAreaDropDownOptions: [],
			timeHorizonResponse: {},
			notesEditorState: null,
			timeScale: 'compass',
			action: 'edit',
			unsavedChanges: false,
			updatingChanges: false,
			addFocusAreaModal: false,
			sectionData: {},
			showArchiveWarningModal: false,
			showConfigureCompassModal: false,
			showDetailInfoModal: false,
			timeScaleDropDownOptions: [
				{label: 'Year(s)', value: 'years'},
				{label: 'Month(s)', value: 'months'},
				{label: 'Week(s)', value: 'weeks'},
				{label: 'Day(s)', value: 'days'},
			],
			compassResponseData:[],
			compassHeaders: [],
			compassDefaultHeaders: [
				{
					title: "Item",
					accessor: "item",
					renderCellType: "editable-text"
				},
				{
					title: "Focus Area",
					accessor: "focusArea",
					renderCellType: "single-select"
				},
				{
					title: "Ideal Outcome",
					accessor: "idealOutcome",
					renderCellType: "editable-text"
				},
				{
					title: "Immediate Term",
					accessor: "immediateTerm",
					renderHeaderType: "header-with-time-horizon-input-option",
					renderCellType: "editable-text",
					timeHorizonResponse: {
						value: 1,
						scaleValue: 'years'
					}
				},
				{
					title: "Short Term",
					accessor: "shortTerm",
					renderHeaderType: "header-with-time-horizon-input-option",
					renderCellType: "editable-text",
					timeHorizonResponse: {
						value: 3,
						scaleValue: 'years'
					}
				},
				{
					title: "Mid Term",
					accessor: "midTerm",
					renderHeaderType: "header-with-time-horizon-input-option",
					renderCellType: "editable-text",
					timeHorizonResponse: {
						value: 10,
						scaleValue: 'years'
					}
				},
				{
					title: "Long Term",
					accessor: "longTerm",
					renderHeaderType: "header-with-time-horizon-input-option",
					renderCellType: "editable-text",
					timeHorizonResponse: {
						value: 20,
						scaleValue: 'years'
					}
				}
			],
		};

	}

	componentDidMount() {
		this.props.setLoading(true);
		this.setStickyToolBar();
		this.updateCompass(this.props)
		this.loadExternalJS();
		this.autoSaveInterval = setInterval(this.updateChanges.bind(this), AUTO_SAVE_INTERVAL);
    }

	// Load external JS to handle expand/shrink row in compass
	loadExternalJS() {
		if(window && window.compassJS && typeof window.compassJS == 'function') {
			window.compassJS();
		}
	}

	componentWillReceiveProps(props) {
		this.updateCompass(props)
	}

	componentWillUnmount() {
		// Clear Iterval
		clearInterval(this.autoSaveInterval);
	}

	// Update Compass
	updateCompass(props) {

		let data = props.data || null;

		if (data) {

			let compassResponse = data.compassResponse || {};
			let compassResponseData = compassResponse && compassResponse.responses || [];
			let notesEditorState = compassResponse.notes ? EditorState.createWithContent(convertFromRaw(compassResponse.notes)) : EditorState.createEmpty()

			let sectionData = {
				isResponded: data.compassResponse ? true: false,
				sectionResponse: compassResponse,
			}

			compassResponseData.push({ action: "add-row" }); // Keep add row button as last record

			this.updateFocusAreaDropDown(compassResponse);

			this.setState({
				compassHeaders: compassResponse.headers || this.state.compassDefaultHeaders,
				compassResponse: compassResponse,
				compassResponseData: compassResponseData || [],
				notesEditorState: notesEditorState,
				sectionData: sectionData
			},()=>{
				// window.compassJS();
			});

		}
		this.props.setLoading(false);
	}

	// Update Focus Area Options
	updateFocusAreaDropDown(compassResponse) {

		let focusAreaDropDownOptions = []

		if (compassResponse 
				&& compassResponse.focusAreas 
				&& compassResponse.focusAreas.length > 0 ) {

			focusAreaDropDownOptions = Util.focusAreaDropdownOptions(compassResponse.focusAreas);
		}

		focusAreaDropDownOptions.push({
			label: "Add New Focus Area",
			value: "ADD_NEW_FA",
			type: "select",
		});

		this.setState({focusAreaDropDownOptions});
	}

	// Handler for FA input field change event
	handleFAInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		if (name != "notes") {
			if (!value) errorMsg = "This field is mandatory";
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// Handler for Focus Area submit
	handleFocusAreaSubmit(e) {
		e.preventDefault();

		const { form, formErrors, timeScale } = this.state;

		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let compassRecordID = this.state.compassResponse && this.state.compassResponse.id ? this.state.compassResponse.id : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			const { sectionData } = this.state;

			let sectionResponse = sectionData.sectionResponse;

			let userFocusAreas = sectionResponse.focusAreas || [];

			// If creating custom focus area already exists
			var ifFocusExists = _.find(userFocusAreas, function (fa) {
				return fa.focusAreaTitle.toLowerCase() === formCopy.focusAreaTitle.toLowerCase();
			})
			if(ifFocusExists) {

				let errorObj = {
					"focusAreaTitle": "This focus area title is already in the list. Please choose it from the dropdown",
				}
				
				this.setState({
					formErrors: { ...formErrors, ...errorObj },
				});
				return false;
			}

			var postObj = {
				focusAreaTitle: formCopy.focusAreaTitle,
				fa_responses: [],
				notes: null,
				type: formCopy['type'],
				timescale: timeScale,
				guidingstars: compassRecordID,
			}

			this.props.setLoading(true);

			API_Services.httpPOST(UrlConstants.URLS.addFocusAreaForTheTimescale, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {
					if(response.data.status) {
						this.setFormApiResponse("Record have been saved successfully!", false);
						this.addFocusAreaInList(response.data.data);
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
			});
		}
	}

    setStickyToolBar() {

		const header = document.getElementById("compass-toolbar");
		var stickyPos = this.props.stickyPos || 125;

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > stickyPos) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	updateChanges() {
		const { unsavedChanges, updatingChanges } = this.state;
		if (unsavedChanges && !updatingChanges) {
			// Update the server only if any unsaved changes
			this.handleSubmit();
		}
	}

	// Handle Save Submit Button
	handleSubmit() {

		this.setState({ updatingChanges: true });

		let { compassResponseData, compassResponse, compassHeaders, notesEditorState } = this.state;

		let postObj = compassResponse || {};

		let data = _.cloneDeep(compassResponseData);

		data.pop(); // Remove the last row item, it has add new row button

		postObj['responses'] = data;
		postObj['timescale'] = 'compass';
		postObj['section'] = 'compass';
		postObj['headers'] = compassHeaders;
		postObj['notes'] = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		// this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.saveCompassResponse, postObj, (err, response) => {

			if (err) {

                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			}
			else if (response.data && response.data.status && response.data.data) {
				this.setState({ unsavedChanges: false, compassResponse: response.data.data });
			}

			this.setState({updatingChanges : false});
			// this.props.setLoading(false);
		});
	}

	// Handle Archive Submit Button
	handleArchiveSubmit() {

		let { compassResponse } = this.state;

		let postObj = {
			'archivedDate' : new Date(),
			'section' : 'compass',
			'id' : compassResponse.id
		};

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.archiveCompass, postObj, (err, response) => {

			if (err) {

                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			}
			else if (response.data && response.data.status && response.data.data) {
				// reset compass
				this.toggleArchiveWarningPopup()
				this.props.getCompassData();
			}
			else {
				let message =  response.data && response.data.message || "Something went wrong!";
				this.setFormApiResponse(message, true)
				this.toggleArchiveWarningPopup()
				window.location = "/compass/";
			}
			this.props.setLoading(false);
		});
	}

	/* Delete Row Record */
	deleteRowRecord() {

		const {compassResponseData, recordToDelete} = this.state

		if (this.state.compassResponseData.length == 0 || !recordToDelete) {
			return;
		}
		
		let index = recordToDelete.index;
		compassResponseData.splice(index, 1);

		this.setState({ 
			compassResponseData: compassResponseData,
			unsavedChanges: true,
			recordToDelete: null
		},() => {
			// this.updateChanges();
		});

		this.toggleDeleteWarningPopup();
	}

	/* Handler to removeButton onClick */
	handleRemoveButtonOnClick(info) {

		const {compassResponseData} = this.state;

		if (compassResponseData.length == 0) {
			return;
		}

		this.setState({ recordToDelete : info });
		this.toggleDeleteWarningPopup();
	}

	/* Handler to archiveButton onClick */
	handleArchiveButtonOnClick(info) {

		const {compassResponseData} = this.state

		if (compassResponseData.length == 0) {
			return;
		}

		this.toggleDeleteWarningPopup();
	}

	/* Handler to move record position Up OR Down */
	handleItemSequenceOnClick(relativeSequence, info) {

		const {compassResponseData} = this.state
		let index = info.index;

		if (this.state.compassResponseData.length < 3 
			|| (relativeSequence == 'UP' && !index) // Dont move first record upwards
			|| relativeSequence == 'DOWN' && index == (this.state.compassResponseData.length - 2)) // Dont move last record downwards
		{
			return;
		}

		if (relativeSequence == 'UP' && index) {
			// Move up one position
			[compassResponseData[index -1], compassResponseData[index]] = [compassResponseData[index], compassResponseData[(index - 1)]]; // swap row
		}
		else {
			// Move down one position
			[compassResponseData[index], compassResponseData[index + 1]] = [compassResponseData[index + 1], compassResponseData[(index)]]; // swap row
		}

		this.setState({ compassResponseData, unsavedChanges: true }, () => {
			// this.updateChanges();
		});
	}

	/* Handler for navigation button onClick */
	handlerToNavigateRecord(navigateTo) {

		const {currentItem} = this.state
		let index = currentItem? currentItem.index : -1;

		if (index < 0 
			|| this.state.compassResponseData.length < 3 
			|| (navigateTo == 'PREV' && !index) // Dont move first record upwards
			|| navigateTo == 'NEXT' && index == (this.state.compassResponseData.length - 2)) // Dont move last record downwards
		{ return; }

		this.setState({currentItem: null});

		let newItem = null;

		if (navigateTo == 'PREV' && index) { // Show previous row record
			newItem = {index: (index -1)}; 
		}
		else { // Show next row record
			newItem = {index: (index +1)};
		}

		this.setState({ currentItem : newItem });
	}

	/* Handler for Add Row button onClick */
	handleAddRowOnClick() {

		const compassResponseData = this.state.compassResponseData;

		compassResponseData[(compassResponseData.length - 1)] = { // Add new empty row
			id: Date.now(),
			item: '',
			focusArea: null,
			idealOutcome: '',
			midTerm: '',
			shortTerm: '',
			longTerm: '',
		};

		compassResponseData.push({action: "add-row"}); // Add new row button in the last row

		this.setState({ compassResponseData, unsavedChanges: true },() => {
			// this.updateChanges();
			window.compassJS();
		});
	}

	/* Handler for TextArea onChange from compass TD cell */
	handleTextAreaChange(cellInfo, e) {

		let value = e.target.value;
		const compassResponseData = _.cloneDeep(this.state.compassResponseData);
		compassResponseData[cellInfo.index][cellInfo.column.id] = value;
		this.setState({ compassResponseData, unsavedChanges: true});
	}

	/* Handler for FA drop down onChange */
	handleFocusAreaSelect(key, cellInfo, selectedItem) {

		if (selectedItem && selectedItem.value == 'ADD_NEW_FA') {
			this.setState({
				addFocusAreaModal: true,
				currentRowID: cellInfo.original.id
			});
			return
		}
		
		const compassResponseData = _.cloneDeep(this.state.compassResponseData);
		compassResponseData[cellInfo.index][cellInfo.column.id] = selectedItem;
		
		this.setState({compassResponseData, unsavedChanges: true});
	}

	/* Handler to Add New FA */
	addFocusAreaInList(newData) {

		let { sectionData } = this.state;
		let sectionResponse = sectionData.sectionResponse;

		let focusAreas = sectionResponse.focusAreas || [];
		focusAreas.unshift(newData);

		// Update parent state
		this.handleFocusAreaUpdates(focusAreas);

		const currentRowID = this.state.currentRowID;

		if (currentRowID) {

			let selectedFA = {
				label: newData.focusAreaTitle + " (custom)",
				value: newData.id,
				type: "custom",
			}

			const { compassResponseData } = this.state;
			let foundIndex = _.findIndex(compassResponseData, {id: currentRowID});
			if (foundIndex > -1) {
				compassResponseData[foundIndex]["focusArea"] = selectedFA;
				this.setState({compassResponseData, unsavedChanges: true, currentRowID: null});
			}
		}

		this.setState({
			form: {
				focusAreaTitle: '',
				type: 'custom'
			},
			addFocusAreaModal: false,
		});
	}

	/* Handler to Update FA */
	handleFocusAreaUpdates(updatedFocusAreas) {
		
		let { sectionData } = this.state;
		let sectionResponse = sectionData.sectionResponse;
		
		if (!sectionResponse) return;
		
		sectionResponse.focusAreas = updatedFocusAreas;
		
		this.setState({
			sectionData: sectionData
		}, () => {
			this.updateFocusAreaDropDown(sectionResponse);
		});
	}

	/* Handler to TextArea onChange */
	handleTextAreaInputChange(id, key, event) {

		let value = event.target.value;

		const { compassResponseData } = this.state;
		let foundIndex = _.findIndex(compassResponseData, {id: id});

		if (foundIndex > -1) {
			compassResponseData[foundIndex][key] = value;
			this.setState({compassResponseData, unsavedChanges: true});
		}
	}

	/* Handler to FA drop down onSelect */
	handleFocusAreaOnChange(id, key, selectedItem) {

		if (selectedItem && selectedItem.value == 'ADD_NEW_FA') {
			this.setState({
				currentRowID: id,
				addFocusAreaModal: true
			});
			return
		}

		const { compassResponseData } = this.state;
		let foundIndex = _.findIndex(compassResponseData, {id: id});

		if (foundIndex > -1) {
			compassResponseData[foundIndex][key] = selectedItem;
			this.setState({compassResponseData, unsavedChanges: true});
		}
	}

	// handle TimeHorizon Input Change
	handleTimeHorizonInputChange(item, event) {

		let value = event.target.value;
		const regex = /^[0-9\b]+$/;

		if (value == '' || regex.test(value)) {

			const { compassHeaders } = this.state;
			let foundIndex = _.findIndex(compassHeaders, { accessor: item.accessor });

			if (foundIndex > -1) {
				compassHeaders[foundIndex]['timeHorizonResponse']['value'] = Number(value);
				this.setState({compassHeaders, unsavedChanges: true});
			}
		};
	}

	// handle TimeHorizon Scale onChange
	handleTimeHorizonScaleOnChange(item, selectedScale) {

		if (selectedScale && selectedScale.value) {

			const { compassHeaders } = this.state;
			let foundIndex = _.findIndex(compassHeaders, { accessor: item.accessor });

			if (foundIndex > -1) {
				compassHeaders[foundIndex]['timeHorizonResponse']['scaleValue'] = selectedScale.value;
				this.setState({compassHeaders, unsavedChanges: true});
			}
		};
	}

	// handle double click on editable-cell
	cellDoubleClickHandler(cellInfo, accessor) {

		this.setState({
			editCellAccessor: accessor,
			currentRowInfo: cellInfo,
			showEditCellModal: true
		})
	}

	/* Render editable cell */
	renderCellWithEditTextOption(cellInfo) {

		if (cellInfo.original && cellInfo.original && cellInfo.original.action == 'add-row') {
			return <span></span>;
		}

		var value = this.state.compassResponseData[cellInfo.index][cellInfo.column.id] || "";

		return(
			<div className="compass-cell">
				<div className="editable-text">
					<textarea 
						onDoubleClick={e => {this.cellDoubleClickHandler(cellInfo, cellInfo.column.id)}}
						type="text"
						className="cell-text-editor cell-text-editor-textarea"
						style={{'width': '100%', 'border': 'none', color: '#495057', resize: 'none', height: '100'}}
						rows={5}
						onChange={e => this.handleTextAreaChange(cellInfo, e)}
						name={cellInfo.column.id + cellInfo.index}
						value={value}
					></textarea>
				</div> 
			</div>
		)
	}

	/* Render Action Buttons */
	renderActionButtons(item) {

		if (item.original && item.original.action && item.original.action == 'add-row') {
			return (
				<div className="compass-action-button-cell">
					<div className="action-button-section">
						<button type="button" className="action-button compass-add-new-row" style={{width: 'auto', color: '#f29569', fontSize: '14px'}} title="Add a row"  onClick={this.handleAddRowOnClick.bind(this)}>
							{"Add Row "}<i className="fa fa-plus color-primary" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			);
		}
		else {
			return (
				<div className="compass-action-button-cell">
					<div className="action-button-section">

						<button type="button" className="action-button compass-toggle-expand" title="Expand OR Shrink row">
							<i className="fa fa-expand color-primary" aria-hidden="true"></i>
						</button> 

						<button type="button" className="action-button" title="Move up"  onClick={this.handleItemSequenceOnClick.bind(this, "UP", item)}>
							<i className="fa fa-long-arrow-up color-primary" aria-hidden="true"></i>
						</button>

						<button type="button" className="action-button" title="Move down" onClick={this.handleItemSequenceOnClick.bind(this, "DOWN", item)}>
							<i className="fa fa-long-arrow-down color-primary" aria-hidden="true"></i>
						</button>

						<button type="button" className="action-button" title="Edit" onClick={()=>{this.toggleDetailInfoPopup(item)}}>
							<i className="fa fa-pencil color-primary" aria-hidden="true"></i>
						</button>

						<button className="action-button" title="Delete" onClick={this.handleRemoveButtonOnClick.bind(this, item)}>
							<i className="fa fa-times text-danger" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			)
		}
	}

	/* Toggle delete warning Popup */
	toggleAddFocusAreaModal() {

		this.setState({
			addFocusAreaModal: !this.state.addFocusAreaModal,
			form: {
				focusAreaTitle: '',
				type: 'custom'
			},
			currentRow: null
		});
	}

	/* Render Compass Table Header*/
	renderCompassTableHeader(header) {

		if (header.renderHeaderType == "header-with-time-horizon-input-option") {

			let timeHorizonResponse = header.timeHorizonResponse;
			let timeScale = _.find(this.state.timeScaleDropDownOptions, { value: timeHorizonResponse.scaleValue })
			let timeHorizon = timeHorizonResponse.value + " " + (timeHorizonResponse.value > 1 ? timeScale.label : timeScale.label.replace('(s)', ''));

			/* Render header cell with title, timeHorizon and change option for the timeHorizon value */
			return (
				<div className={"compass-header-cell"}>
					<span style={{textTransform: "uppercase" }}>{header.title}</span>
					<br></br>
					<span style={{fontSize:12}}>{timeHorizon}</span>
					<br></br>
					<span style={{fontSize:12, color: '#fff', textDecoration: "underline" }} className="text-center cursor-pointer" onClick={()=>{this.toggleConfigureCompassPopup()}} >(change)</span>
				</div>
			);
		}
		else if (header.renderHeaderType == "header-with-archive-button-option") {

			let disableArchiveButton = true;
			if (this.state.compassResponse && this.state.compassResponse.id) {
				disableArchiveButton= false;
			};

			/* Render header cell with title and archive button */
			return(
				<div className={'compass-header-cell'}>
					<span style={{textTransform: "uppercase" }}> { header.title } </span>
					<br></br>
					<button disabled={disableArchiveButton} type="button" className="archive-action-button" title="Archive this compass" onClick={this.toggleArchiveWarningPopup.bind(this)}>
						{"Archive "}<i className="fa fa-archive color-primary" aria-hidden="true"></i>
					</button>
				</div>
			)
		}
		else {

			/* Render header cell with title */
			return(
				<div className={'compass-header-cell'}>
					<span style={{textTransform: "uppercase" }}> { header.title } </span>
				</div>
			)
		};
	}

	/* Render Cell with DropDown Options */
	renderCellWithSingleSelectOption(headerInfo, cellInfo) {

		const item = cellInfo.original;

		if (item && item.action && item.action == 'add-row') {
			return <span></span>;
		}

		if (headerInfo && headerInfo.accessor == 'focusArea') {

			const selectedFocusArea = item && item.focusArea && item.focusArea.value ? item.focusArea.value : null;
	
			return (
				<React.Fragment>
					<ReactSelect id="compass-focus-area-select"
						name="focusAreaTitle"
						styles={SharedStyles.compassFAReactSelectStyles}
						options={this.state.focusAreaDropDownOptions}
						value={_.find(this.state.focusAreaDropDownOptions, { value: selectedFocusArea })}
						autosize={true}
						onChange={this.handleFocusAreaSelect.bind(this, 'focusArea', cellInfo)}
						placeholder="Select"
					/>
				</React.Fragment>
			)
		}

		return <div/>

	}

	/* Render sticky Toolbar */
	stickyToolbarBar() {

		let disableArchiveButton = true;
		if (this.state.compassResponse && this.state.compassResponse.id) {
			disableArchiveButton= false;
		};

        return (
            <div id={'compass-toolbar'} className="text-right mb-1">
                <button 
					disabled={this.state.unsavedChanges? false : true}
					type="button"
					onClick={this.updateChanges.bind(this)} 
					className="compass-btn btn btn-secondary">
						{this.state.unsavedChanges ? " Save  " : "Saved "}
						<i className={ "fa fa-refresh fa-1x fa-fw" + (this.state.updatingChanges ? " fa-spin" : "")} ></i>
						<span className="sr-only">Loading...</span>
				</button>
				&nbsp;&nbsp;

				{
					this.props.disableArchiveFeature ? 
						<Link
							to="/compass/"
							className="compass-btn btn btn-secondary">
								<i className="fa fa-chevron-left fa-1x fa-fw" aria-hidden="true"> </i>
								Back To Current Compass
							</Link>
					:
					<button
						disabled={disableArchiveButton}
						type="button"
						className="compass-btn btn btn-secondary"
						title="Archive this compass" 
						onClick={this.toggleArchiveWarningPopup.bind(this)}>
							{"Archive "}<i className="fa fa-archive fa-1x " aria-hidden="true"></i>
					</button>
				}
            </div>

        )
    }

	/* Toggle archive warning Popup */
	toggleArchiveWarningPopup() {

		this.setState({
			showArchiveWarningModal: !this.state.showArchiveWarningModal
		});
	}
	
	/* show/hide Heads Up Popup for archive action*/
	renderArchiveWarningPopup() {
		return (
			<Modal
				isOpen={this.state.showArchiveWarningModal}
				toggle={this.toggleArchiveWarningPopup.bind(this)}>
				<ModalHeader >{"Heads up!"}</ModalHeader>
				<ModalBody>
					<div className="mb-4">
						<p>Are you sure you want to Archive this Compass.</p>
						<div className="small">* This action cannot be undone.</div>
					</div>
					<div className="popup-footer-section">
						<div>
							<Button color="danger" title="Archive Compass" onClick={(e) => this.handleArchiveSubmit()} className="btn-md btn-sm mr-2">Archive This Compass</Button>
						</div>
						<Button color="secondary" title="Cancel" onClick={this.toggleArchiveWarningPopup.bind(this)} className="btn-sm">Cancel</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	/* Toggle delete warning Popup */
	toggleDeleteWarningPopup() {

		this.setState({
			showDeleteWarningModal: !this.state.showDeleteWarningModal
		});
	}

	/* show/hide Heads Up Popup for delete*/
	renderDeleteWarningPopup() {
		return (
			<Modal
				isOpen={this.state.showDeleteWarningModal}
				toggle={this.toggleDeleteWarningPopup.bind(this)}>
				<ModalHeader >{"Heads up!"}</ModalHeader>

				<ModalBody>
					<div className="mb-4">
						<p>Are you sure you want to delete the record</p>
						<div className="small">* This action cannot be undone.</div>
					</div>
					<div className="popup-footer-section">
						<div>
							<Button color="danger" title="Delete Record" onClick={(e) => this.deleteRowRecord()} className="btn-md btn-sm mr-2">Delete</Button>
						</div>
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteWarningPopup.bind(this)} className="btn-sm">Cancel</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	/* Render EditPopup TopBar */
	renderEditPopupTopBar(item) {

		let {currentItem, compassResponseData} = this.state;
		let disablePrevButton = true, disableNextButton = true;
		let disableSaveButton = this.state.unsavedChanges? false : true;

		if (currentItem && currentItem.index) {
			disablePrevButton = false;
		};
		if (currentItem && currentItem.index > -1 && compassResponseData && compassResponseData.length > 2 && currentItem.index != (compassResponseData.length-2)) {
			disableNextButton = false;
		};

		return (
			<div id={"daily-review-floating-tool-bar"} className="d-flex flex-wrap justify-content-between mb-3">

				<div className={"d-flex flex-wrap justify-content-center mb-1"} style={{ minWidth: 70}}>
					{
						disablePrevButton? null:
						<button 
							disabled={disablePrevButton}
							type="button"
							onClick={(e) =>{this.handlerToNavigateRecord('PREV')}}
							className="compass-btn btn btn-secondary btn-sm">
								<i className="fa fa-angle-left fa-1x" aria-hidden="true"></i> &nbsp; {" Prev "}
						</button>
					}
				</div>

				<div className="mb-1">
					<button 
						disabled={this.state.unsavedChanges? false : true}
						type="button"
						onClick={this.updateChanges.bind(this)} 
						style={{ minWidth: 100}}
						className="compass-btn btn btn-secondary">
							{this.state.unsavedChanges ? " Save  " : "Saved "}
							<i className={ "fa fa-refresh fa-1x fa-fw" + (this.state.updatingChanges ? " fa-spin" : "")} ></i>
					</button>
				</div>

				<div className={"d-flex flex-wrap justify-content-center mb-1"} style={{ minWidth: 70}}>
					{
						disableNextButton? null:
						<button
							disabled={disableNextButton}
							type="button"
							onClick={(e) =>{this.handlerToNavigateRecord('NEXT')}}
							className="compass-btn btn btn-secondary">
								{" Next "} &nbsp;  <i className="fa fa-angle-right" aria-hidden="true"></i>
						</button>
					}
				</div>
			</div>
		);
	}

	/* Toggle Popup */
	toggleDetailInfoPopup(item) {

		this.setState({
			showDetailInfoModal: !this.state.showDetailInfoModal,
			currentItem: item ? item : null
		});
	}

	/* Render EditForm */
	renderEditForm = () => {

		const { compassResponseData, currentItem } = this.state;

		if (!currentItem || currentItem.index < 0) {
			return <div></div>
		}

		const rowRecord = compassResponseData[currentItem.index];
		const selectedFA = rowRecord.focusArea && rowRecord.focusArea.value ? _.find(this.state.focusAreaDropDownOptions, { value: rowRecord.focusArea.value }) : null;

		return (
			<div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Item"} </p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'item')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"item" + currentItem.index}
								value={rowRecord.item || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Focus Area"} </p>
						<div>
							<div className="row">
								<div className="col-md-12">
									<ReactSelect
										name={"focusArea" + currentItem.index}
										styles={SharedStyles.selectBoxStyles}
										options={this.state.focusAreaDropDownOptions}
										value={selectedFA}
										autosize={true}
										onChange={(selectedOption) => this.handleFocusAreaOnChange(rowRecord.id, 'focusArea', selectedOption)}
										placeholder={"Select Focus Area"} 
										/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Ideal Outcome"}</p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'idealOutcome')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"idealOutcome" + currentItem.index}
								value={rowRecord.idealOutcome || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Immediate Term"}</p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'immediateTerm')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"immediateTerm" + currentItem.index}
								value={rowRecord.immediateTerm || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Short Term"}</p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'shortTerm')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"shortTerm" + currentItem.index}
								value={rowRecord.shortTerm || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Mid Term"}</p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'midTerm')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"midTerm" + currentItem.index}
								value={rowRecord.midTerm || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<div className="form-group">
						<p>{"Long Term"}</p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, 'longTerm')}
								className="form-control"
								minRows={2}
								maxRows={10}
								name={"longTerm" + currentItem.index}
								value={rowRecord.longTerm || ""}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
			</div>
		)
	}

	/* Render EditPopup to edit row record */
	renderDetailInfoPopup() {

		return (
			<Modal
				size={'lg'}
				isOpen={this.state.showDetailInfoModal}
				toggle={this.toggleDetailInfoPopup.bind(this,0)}>
				
				<ModalHeader toggle={this.toggleDetailInfoPopup.bind(this,0)}>View & Edit Record</ModalHeader>

				<ModalBody>

					{this.renderEditPopupTopBar()} 
					{this.renderEditForm()}

					<br></br><br></br>

					<div className="popup-footer-section">
						<div></div>
						<Button 
							color="secondary"
							title="Okay" 
							onClick={this.toggleDetailInfoPopup.bind(this,0)} 
							className="btn-sm"> Close </Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	/* Toggle (Show/Hide) Compass Configuration Popup Popup */
	toggleConfigureCompassPopup = () => {
		this.setState({
			showConfigureCompassModal: !this.state.showConfigureCompassModal,
		});
	}

	// Render Input fields for timehorizon for timescale
	renderTimeHorizonInputField = (element, index) => {

		if (element.renderHeaderType == "header-with-time-horizon-input-option") {
			/**
			 * Option to update TimeHorizon for timescale
			 * Input Fields are Text box & Drop-Down
			 */
			return (
				<div className="mb-4" key={index}>
					<div className="form-group">
						<p>{element.title} <span className="text-danger">*</span></p>
						<div>
							<div className="row">
								<div className="col-md-4">
									<input
										className="form-control"
										style={{ minHeight: 42 }}
										type={"number"}
										value={element.timeHorizonResponse && element.timeHorizonResponse.value ? element.timeHorizonResponse.value : ''}
										onChange={this.handleTimeHorizonInputChange.bind(this, element)}
										placeholder={"Eg. 3"} 
										/>
								</div>
								<div className="col-md-8">
									<ReactSelect
										name="text_dropdown"
										styles={SharedStyles.selectBoxStyles}
										options={this.state.timeScaleDropDownOptions}
										value={_.find(this.state.timeScaleDropDownOptions, { value: element.timeHorizonResponse && element.timeHorizonResponse.scaleValue ? element.timeHorizonResponse.scaleValue : null })}
										autosize={true}
										onChange={(selectedOption) => this.handleTimeHorizonScaleOnChange(element, selectedOption)}
										placeholder={"Eg. Month(s)"} 
										/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	/* Toggle Edit Cell Popup */
	toggleEditCellPopup = () => {
		this.setState({
			showEditCellModal: false,
			editCellAccessor: null,
			currentRowInfo: null,
		});
	}

	/* Render edit cell value */
	renderTextAreaForEditCellValue() {

		const { compassResponseData, compassHeaders, currentRowInfo, editCellAccessor } = this.state;
		if (!currentRowInfo || currentRowInfo.index < 0 || !editCellAccessor ) {
			return
		}

		var headerInfo = _.find(compassHeaders, {'accessor': editCellAccessor});
		let rowRecord = compassResponseData[currentRowInfo.index];
		if (!headerInfo) { return };

		return (
			<div>
				<div className="mb-4">
					<div className="form-group">
						<p>{headerInfo.title} </p>
						<div>
							<TextareaAutosize
								onChange={this.handleTextAreaInputChange.bind(this, rowRecord.id, editCellAccessor)}
								className="form-control"
								minRows={5}
								maxRows={10}
								name={editCellAccessor+"-edit-cell-" + currentRowInfo.index}
								value={rowRecord[editCellAccessor]}
								>
							</TextareaAutosize>
						</div>
					</div>
				</div>
			</div>
		)
	}

	// Render Popup to edit cell value
	renderEditCellPopup() {
		return (
			<Modal isOpen={this.state.showEditCellModal} toggle={this.toggleEditCellPopup}>
				<ModalBody>
					{this.renderTextAreaForEditCellValue()}
					<div className="popup-footer-section">
						<div></div>
						<Button color="secondary" title="OK" onClick={this.toggleEditCellPopup} className="btn-sm">Close</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	/**
	 * Render Compass Configuration Popup
	 * - Form to update TimeHorizon's for all the timescales
	 **/
	renderConfigurePopup() {

		let {compassHeaders} = this.state

		if (!compassHeaders || compassHeaders.length == 0) {
			compassHeaders = [];
		}

		return (
			<Modal isOpen={this.state.showConfigureCompassModal} toggle={this.toggleConfigureCompassPopup}>

				<ModalHeader>Update time horizon for the below timescales.</ModalHeader>

				<ModalBody>
					{compassHeaders.map(this.renderTimeHorizonInputField)}
					<br/><br/>
					<div className="popup-footer-section">
						<div></div>
						<Button color="secondary" title="OK" onClick={this.toggleConfigureCompassPopup} className="btn-sm">Okay</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	// Render Notes
	renderCompassNotesSection() {

		const {  action, notesEditorState } = this.state;

		return (
			<div className="form-group">
				<br></br><br></br><br></br>
				<p>Notes</p>
				<div className="htmlEditor" style={{backgroundColor: action == 'view' ? '#f6f6f6': 'transparent'}}>
					<Editor
						editorState={notesEditorState}
						toolbarClassName={action == 'view' ? "d-none" : "toolbarClassName"}
						wrapperClassName="wrapperClassName"
						editorClassName="editorClassName"
						onEditorStateChange={(editorState) =>
							this.setState({
								unsavedChanges: true,
								notesEditorState: editorState,
							})
						}
					/>
				</div>
			</div>
		);
	}

	// Render Compass as editable table
	renderCompassEditableTable() {

		const { compassResponseData, compassHeaders } = this.state;
		
		let compassColumns = [];
		let pageSize = (compassResponseData.length + 100);

		compassHeaders.forEach(item => {

			// Customized Table Header
			item['Header'] = this.renderCompassTableHeader.bind(this, item)

			if (item.renderCellType && item.renderCellType == "editable-text") {

				// Customized text editable cell
				item['Cell'] = this.renderCellWithEditTextOption.bind(this);
			}
			else if (item.renderCellType && item.renderCellType == "single-select") {

				// Customized editable cell with single select option
				item['Cell'] = this.renderCellWithSingleSelectOption.bind(this, item);
			}

			compassColumns.push(item);
		});

		if (compassColumns.length > 0) {
			compassColumns.push({
				Header: this.renderCompassTableHeader.bind(this, {title: "ACTION"}),
				Cell: this.renderActionButtons.bind(this)
			});
		}

		return (
			<div id={"compassEditableTbl"}>
				<br></br>
				<ReactTable
					data={compassResponseData}
					columns={compassColumns}
					showPagination={false}
					sortable={false}
					className="-striped -highlight"
					minRows={8}
					defaultPageSize={pageSize}
					/>
			</div>
		);
	}

	// Render Add New Focus Area modal popup
	renderAddFocusAreaInModal() {

		const { form, formErrors } = this.state;

		return (
			<div>
				<Modal isOpen={this.state.addFocusAreaModal} toggle={this.toggleAddFocusAreaModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddFocusAreaModal.bind(this)}>Add Focus Area</ModalHeader>
					<ModalBody>
						<div className="mb-4">
							<div className="form-group">
								<label>Focus Area Title <span className="text-danger">*</span></label>
								<div className="row">
									<div className="col-sm-12">
										<input type="text" onChange={this.handleFAInputField.bind(this)} className="form-control" name="focusAreaTitle" value={form['focusAreaTitle']} />

										<p className="text-danger">{ formErrors['focusAreaTitle'] &&
											<span className="err">{formErrors['focusAreaTitle']}</span>}
										</p>
									</div>
								</div>
							</div>

							<div className=" text-right">
								<button type="button" className="btn btn-primary" onClick={this.handleFocusAreaSubmit.bind(this)}>
									Add New
								</button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div style={{marginTop:0}}>
				{this.stickyToolbarBar()}
				{this.renderCompassEditableTable()}
				{this.renderCompassNotesSection()}
				{this.renderEditCellPopup()}
				{this.renderConfigurePopup()}
				{this.renderDetailInfoPopup()}
				{this.renderDeleteWarningPopup()}
				{this.renderAddFocusAreaInModal()}
				{this.renderArchiveWarningPopup()}
			</div>
		);
	}
}

CompassTable.propTypes = {
	setLoading: PropTypes.func.isRequired,
};



const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	CompassTable
);