import React, { Component } from "react";
import ReactGoogleLogin from 'react-google-login';
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');
var Globals = require('../../utils/Globals');

class GoogleLogin extends Component {

    setApiResponse(message, hasError) {

		if (hasError) {
			return alert(message);
		};
	}

    googleSuccessResponse(response) {

        console.log('googleSuccessResponse', response);

		if (response && response.code) {

            let postObj = {
                provider: 'Google',
                googleAuthCode: response.code,
            };

            this.props.setLoading(true);

            API_Services.httpPOST(UrlConstants.URLS.saveAuthToken, postObj, (error, response) => {
                
                if (error) {
				
                    if (error.response && error.response.data) {
                        this.setApiResponse(error.response.data, true)
                    } 
                    else {
                        this.setApiResponse("Something went wrong!", true);
                        console.log('err...', error);
                    }
                } 
                else if (response.data) {
    
                    if (response.data.status && response.data.data) {

                        let responseData = response.data.data;
                        
                        if (responseData.status && this.props.onAuthSuccess) {
                            
                            let calendarList = responseData.calendarList || [];
                            let defaultCalendar = _.find(calendarList, {primary: true});
                            
                            defaultCalendar = defaultCalendar ? defaultCalendar.id: 'primary';
                            
                            this.props.onAuthSuccess({provider: 'Google', defaultCalendar: defaultCalendar});
                        };
                    } 
                    else {
                        this.setApiResponse(response.data.message, false);
                    };
                }
                else {
                    this.setApiResponse("Something went wrong!", true);
                };
                this.props.setLoading(false);
            });
		}
    }

    googleFailureResponse(response) {
        console.log('googleFailureResponse', response);
        alert('Something went wrong. Unable to authorize your Google account.');
    }

    render() {

        const {disabled} = this.props;

        return(
            <div className="my-3">
                <ReactGoogleLogin
                    clientId={Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].GOOGLE_CLIENT_ID}
                    //buttonText="Sync with Google"
                    accessType="offline"
                    responseType="code"
                    approvalPrompt="force"
                    prompt='consent'
                    scope={'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'}
                    disabled={disabled}
                    onSuccess={(response) => this.googleSuccessResponse(response)}
                    onFailure={(response) => this.googleFailureResponse(response)}
                    render={renderProps => (
                        <button type="button" style={{...styles.googleBtn, ...{opacity: disabled ? 0.7 : 1, cursor: disabled ? 'not-allowed' : 'pointer'}}} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                            <div className="mr-2">
                                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#000" fillRule="evenodd">
                                        <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path>
                                        <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path>
                                        <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path>
                                        <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path>
                                        <path fill="none" d="M0 0h18v18H0z"></path>
                                    </g>
                                </svg>
                            </div>
                            <span>Sync with Google</span>
                        </button>
                    )}
                />
            </div>
		)
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	GoogleLogin
);

const styles = {

	googleBtn: {
		backgroundColor: '#fff',
		border: '1px solid #ced4da',
		borderRadius: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#737373',
		padding: 8,
		fontSize: 14
	}
}