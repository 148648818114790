import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import TaskForm from "./TaskForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AddTask extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	componentDidMount() {

	}

	// Handler for Task submit
	handleTaskSubmit(data) {

		var postObj = data;

		postObj.itemPosition = this.props.itemPosition;
		postObj.refItem = this.props.refItem;
		postObj.relativePosition = this.props.relativePosition;
		postObj.keyActivityID = this.props.keyActivityID;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.addNewTask, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					this.props.addTaskInList(this.props.itemPosition, response.data.data);
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		return (
			<div className="section-container">
				<div className="row">
					<div className="col-md-12">
						<div className="form-container">
							<TaskForm
								onSubmit={this.handleTaskSubmit.bind(this)}
								formApiResponse={this.state.formApiResponse} />
							{this.props.itemPosition ?
								<div className="small text-info">* This item will be added at the position {this.props.itemPosition}.</div>
							: null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AddTask.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	AddTask
);
