import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import { getUserOutcomesWithPopulatedData } from "../../actions/outcomeActions";

import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import FocusAreaSelect from "../focus-areas/FocusAreaSelect";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class UnplannedTimeBlocks extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusArea: {
					label: "All",
					value: "all",
				},
				outcome: {
					label: "All",
					value: "all",
				},
			},
			formErrors: {
				focusArea: null,
				outcome: null
			},
			outcomesWithPopulatedData: [],
			outcomesOptions: [],
			apiResponse: {
				className: "",
				message: "",
			},
			showFilterSection: false,
			buttonDisabled: false,
		};
	}

	componentDidMount() {
		// this.getOutcomesWithPopulatedData();
	}

	componentDidUpdate(prevProps) {
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getOutcomesWithPopulatedData() {

		this.props.setLoading(true);

		var params = 'focusAreaID=' + this.state.form.focusArea.value;

		API_Services.httpGETAddParams(UrlConstants.URLS.getOutcomesWithPopulatedData, params, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.yourOutcomes(response.data.data);

					this.props.getUserOutcomesWithPopulatedData(response);

					this.yourTimeBlocks(response.data.data);
				} else {
					this.setApiResponse(response.data.message, true);

					// If no outcomes, reset outcome select option
					const { form } = this.state;

					var formObj = {};
					formObj = {
						...form,
						outcome: null,
					};

					this.setState({
						form: formObj,
						outcomesOptions: [],
						buttonDisabled: true,
					});
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Set dropdown options for outcome
	yourOutcomes(outcomes) {

		if (outcomes && outcomes.length > 0) {

			let outcomesOptions = [{
				label: "All",
				value: "all",
			}];

			// Default select all outcomes
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				outcome: {
					label: "All",
					value: "all",
				},
			};

			outcomes.forEach((eachOutcome) => {

				outcomesOptions.push({
					label: eachOutcome.outcomeTitle,
					value: eachOutcome.id,
					focusAreaID: eachOutcome.focusArea.id
				});
			});

			this.setState({
				form: formObj,
				outcomesOptions: outcomesOptions,
				availableOutcomes: outcomesOptions,
				buttonDisabled: false,
			});
		}
	}

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, value) {

		const { form } = this.state;

		let defaultOptions = [{
			label: "All",
			value: "all"
		}]

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'outcome': defaultOptions[0],
		};

		this.props.timeBlocks([]);

		let filteredoutcomesOptions = _.filter(this.state.availableOutcomes, {focusAreaID: value.value});
		let outcomesOptions = value.value != 'all' ? [...defaultOptions, ...filteredoutcomesOptions] : this.state.availableOutcomes;

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			// this.getOutcomesWithPopulatedData();
		});
	}

	// Handler for outcome select field change event
	handleOutomeSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };

			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "overcomes":
				if (!value) errorMsg = "Please select Overcomes";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select Focus Area";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	yourTimeBlocks(outcomesWithPopulatedData) {

		let timeBlocks = [];

		// For each outcome, will have only one item in an array if user selects an outcome.
		outcomesWithPopulatedData.forEach((eachOutcome) => {

			// some users may not have created keyactivities for an outcome.
			if (eachOutcome.includeInWeeklyPlan === true && eachOutcome.keyActivities && eachOutcome.keyActivities.length > 0) {

				// For each key activity
				eachOutcome.keyActivities.forEach((eachKeyActivity) => {

					// some users may not have created timeblocks for an keyactivities.
					if (eachKeyActivity.includeInWeeklyPlan === true && eachKeyActivity.timeBlocks && eachKeyActivity.timeBlocks.length > 0) {

						// For each time block
						eachKeyActivity.timeBlocks.forEach((eachtimeBlock) => {

							let customTitle = eachOutcome.abbreviation + ": " + eachKeyActivity.title;
							let isNonWeeklyTB = eachtimeBlock.regularity == 'non-weekly' ? true : false;

							var startDt = new Date();
							var endDt= new Date();
							startDt.setHours(0, 0, 0);
							endDt.setHours(0, (eachtimeBlock.durationInMinutes), 0);

							/* Construct event object. */
							timeBlocks.push({
								id: eachtimeBlock.id,
								title: customTitle,
								start: startDt,
								end: endDt,
								outcomeID: eachOutcome.id,
								outcomeAbbr: eachOutcome.abbreviation,
								outcomeTitle: eachOutcome.outcomeTitle,
								keyActivityID: eachKeyActivity.id,
								keyActivityTitle: eachKeyActivity.title,
								timeBlockID: eachtimeBlock.id,
								timeBlockTitle: eachtimeBlock.title,
								focusAreaID: eachOutcome.focusArea.id,
								focusAreaTitle: eachOutcome.focusArea.focusAreaTitle,
								durationInMinutes: eachtimeBlock.durationInMinutes,
								sessionsPerWeek: isNonWeeklyTB ? 1 : eachtimeBlock.sessionsPerWeek, // It's always going to be 1 session per week for the 'non-weekly' timeblocks.
								regularity: eachtimeBlock.regularity,
								interval: eachtimeBlock.interval,
								recurringInterval: eachtimeBlock.recurringInterval,
								durationInMinutesPerWeek: eachtimeBlock.durationInMinutesPerWeek,
								type: eachtimeBlock.type
							});
						});
					}
				});
			}
		});

		this.props.timeBlocks(timeBlocks);
	}

	// Handler for submit
	handleSubmit(e) {
		e.preventDefault();

		const { form, outcomesWithPopulatedData } = this.state;

		let filteredOutcomesWithPopulatedData = [];

		var filteredOutcomesByFocusArea = outcomesWithPopulatedData.filter(function (el) {
			return form.focusArea.value == 'all' || (el.focusArea && el.focusArea.id && el.focusArea.id == form.focusArea.value)
		});

		if (filteredOutcomesByFocusArea && filteredOutcomesByFocusArea.length > 0) {

			if (form.outcome.value === "all") {

				this.yourTimeBlocks(filteredOutcomesByFocusArea);
			} else {

				let selectedOutcomeID = form.outcome.value;

				filteredOutcomesByFocusArea.forEach((eachOutcome) => {

					if (eachOutcome.id === selectedOutcomeID) {
						filteredOutcomesWithPopulatedData.push(eachOutcome);
					}
				});

				this.yourTimeBlocks(filteredOutcomesWithPopulatedData);
			}
		}
	}

	// Render Your Commitments Section
	render() {

		const { form, formErrors, outcomesOptions, buttonDisabled } = this.state;

		return (
			<div style={{minHeight: '70vh'}}>
				
				{/* <div className="row row-section-container align-items-center justify-content-between mb-3">
					<h6>UNPLANNED</h6>
					<div className="cursor-pointer mt-n2" onClick={() => this.setState(prevState => ({showFilterSection: !prevState.showFilterSection}))}>
						<img src={"/images/Filter_Icon.png"} width={30} />
					</div>
				</div> */}

				{ this.state.showFilterSection === true ?
			
					<form onSubmit={(e) => e.preventDefault()}>
						<div>
							<FocusAreaSelect
								handleFocusAreaSelect={this.handleFocusAreaSelect.bind(this)}
								value={form.focusArea}
								error={formErrors.focusArea}
								isRequired={true}
								hasAllOption={true}
							/>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Project <span className="text-danger">*</span></label>
									<ReactSelect
										name="outcome"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={outcomesOptions}
										value={form['outcome']}
										removeSelected={true}
										autosize={true}
										clearable={true}
										onSelectResetsInput={true}
										onChange={this.handleOutomeSelectField.bind(this, "outcome")}
										placeholder="Select Project"
									/>
									<p className="text-danger">{formErrors['outcome'] &&
										<span className="err">{formErrors['outcome']}</span>}</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm">Submit</button>
							</div>
						</div>
						<hr />
					</form>
				: null}

				<div>{this.props.children}</div>

			</div>
		);
	}

}

UnplannedTimeBlocks.propTypes = {
	setLoading: PropTypes.func.isRequired,
	getUserOutcomesWithPopulatedData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomesWithPopulatedData })(
	UnplannedTimeBlocks
);
