import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import FocusAreaSelect from "../focus-areas/FocusAreaSelect";

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class UpdateProjects extends Component {

	constructor(props) {
		super(props);
		this.state = {
			projectsNewFields: [],
			validationErrors: [],
			showDetailsOfAllProjects: false // Incase of project abbreviation uniqueness error, display all the projects to the user so that they can edit again.
		};
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
	}

	validateUserInput(fieldName, response) {

		let validationMessage = '';
		let mandatoryFields = ['abbreviation', 'focusArea'];

		if (mandatoryFields.indexOf(fieldName) > -1 && _.isEmpty(response)) {
			validationMessage = 'This field is mandatory.';
		};

		return validationMessage;
	}

	// Handler for select field change event
	handleSelectField(project, fieldName, response) {

		this.handleProjectNewFieldChanges(project, fieldName, 'select', response);
	}

	handleProjectNewFieldChanges(project, fieldName, fieldType, response) {

		let { projectsNewFields } = this.state;

		if (fieldType == "text") {
			response = response.target.value ? response.target.value : '';
		}
		else if (fieldType == "select") {
			response = response;
		}

		this.props.chosenOutcome.projects.forEach((eachProject) => {

			// Update respective project
			if (project['id'] == eachProject.id) {

				// if already present update else push
				var projectIndex = _.findIndex(projectsNewFields, { id: eachProject.id });

				if (projectIndex > -1) {

					let updatedProjectData = {
						...projectsNewFields[projectIndex],
						[fieldName]: response,
					}

					projectsNewFields.splice(projectIndex, 1, updatedProjectData);
				} else {

					projectsNewFields.push({
						id: eachProject['id'],
						[fieldName]: response,
					})
				}
			}
		})

		this.setState({
			projectsNewFields: projectsNewFields,
			validationErrors: [],
		});
	}

	// Validate the required fields
	validateRequiredFields() {

		let validationErrors = [];
		let hasValidationError = false;

		this.props.chosenOutcome.projects.forEach((eachProject) => {

			// If project contains the required fields skip it.
			if (eachProject['abbreviation'] && eachProject['focusArea']) return;

			// If deleted project. Skip it
			if (eachProject['isDeleted']) return;

			let fieldsToBeValidated = ['abbreviation', 'focusArea'];

			fieldsToBeValidated.map((fieldName) => {

				// If project contains the required fields skip it.
				if (eachProject[fieldName]) return;

				// Get the response to validate
				let projectIndex = _.findIndex(this.state.projectsNewFields, { id: eachProject.id });
				let value;
				if (projectIndex > -1) {
					value = _.has(this.state.projectsNewFields[projectIndex], fieldName) ? this.state.projectsNewFields[projectIndex][fieldName] : "";
				}

				let validationMessage = this.validateUserInput(fieldName, value);

				if (validationMessage) {
					hasValidationError = true;
				};

				// set the validate message
				let validationIndex = _.findIndex(validationErrors, { id: eachProject.id });
				if (validationIndex == -1) {

					validationErrors.push({
						id: eachProject['id'],
						[fieldName]: validationMessage,
					})
				} else {

					let updatedValidationMessage = {
						...validationErrors[validationIndex],
						[fieldName]: validationMessage,
					}

					validationErrors.splice(validationIndex, 1, updatedValidationMessage);
				}
			});
		})

		return {
			hasValidationError: hasValidationError,
			validationErrors: validationErrors,
		}
	}

	// Update the new project fields in the outcome
	handleProjectsNewFieldsSubmit() {

		let { projectsNewFields } = this.state;

		// Validate
		let { hasValidationError, validationErrors } = this.validateRequiredFields();

		if (hasValidationError) {
			return this.setState({ validationErrors: validationErrors });
		};

		// Render the new project fields and call the outcome api
		let chosenOutcomeClone = _.cloneDeep(this.props.chosenOutcome);

		chosenOutcomeClone.projects.forEach((eachProject, index) => {

			let projectIndex = _.findIndex(projectsNewFields, { id: eachProject.id });

			let projectsNewFieldsCopy = _.cloneDeep(projectsNewFields[projectIndex]);

			// Get only value from select object
			_.forOwn(projectsNewFieldsCopy, function (value, key) {
				if (Array.isArray(value)) {
				}
				else if (_.isObject(value)) {
					projectsNewFieldsCopy[key] = value.value;
				}
			});

			let updatedProject = {
				...eachProject,
				...projectsNewFieldsCopy,
			}

			chosenOutcomeClone.projects.splice(index, 1, updatedProject);
		})

		this.setState({
			buttonDisabled: true,
		});

		let postObj = {
			outcomeObj: chosenOutcomeClone,
			recordID: chosenOutcomeClone.id,
		}

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateOutcomeDefinition, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true);
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
				};
			} 
			else if (response.data) {
				
				if (response.data.status && response.data.data) {
					this.setState({
						projectsNewFields: [],
						validationErrors: [],
						showDetailsOfAllProjects: false, // Assuming all the newly entered fields follow expected validations, allow users to sync their projects.
					},
					() => this.props.updateOutcome(response.data.data));
				}
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	// Create the projects
	createOrEditOutcomeProjects() {

		this.setState({
			buttonDisabled: true,
		});

		let postObj = {
			recordID: this.props.chosenOutcome['id'],
		}

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.createOrEditOutcomeProjects, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					
					if (err.response.data.msg && err.response.data.type && err.response.data.type == 'uniqueness_error') {
						alert(err.response.data.msg);
						this.setState({showDetailsOfAllProjects: true});
					} 
					else {
						this.setApiResponse(err.response.data, true);
					};
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setState({
						showDetailsOfAllProjects: false, // Everything is okay and all projects synced successfully
					},
					() => this.props.updateOutcome(response.data.data, true));
				};
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		let { chosenOutcome } = this.props;
		let { projectsNewFields, validationErrors, showDetailsOfAllProjects } = this.state;

		//An outcome may doesn't have any projects
		if (!_.has(chosenOutcome, 'projects') || chosenOutcome.projects.length == 0) {

			return (
				<div>
					<ModalHeader toggle={this.props.onCancel}>No Projects found</ModalHeader>
					<ModalBody>
						<div>
							<p>No Projects found. Please add projects in <span className="text-warning">{chosenOutcome['outcome']}</span> outcome</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel}>Close</Button>
					</ModalFooter>
				</div>
			)
		}

		// Render projects required fields form
		let innerHTML = [];

		chosenOutcome.projects.forEach((eachProject, index) => {

			// If deleted project. Skip it
			if (eachProject['isDeleted']) return;

			// If project contains the required fields skip it.
			if (!showDetailsOfAllProjects && eachProject['abbreviation'] && eachProject['focusArea']) return;

			// If data present in temp fields use that value, also fetch errors
			let abbreviationValue = eachProject['abbreviation'] || '';
			let focusAreaValue = eachProject['focusArea'] || null;
			let projectIndex = _.findIndex(projectsNewFields, { id: eachProject.id });
			
			if (projectIndex > -1) {

				if (_.has(projectsNewFields[projectIndex], 'abbreviation')) {
					abbreviationValue = projectsNewFields[projectIndex]['abbreviation'];
				};

				if (_.has(projectsNewFields[projectIndex], 'focusArea')) {
					focusAreaValue = projectsNewFields[projectIndex]['focusArea'];
				};
			};

			let validationIndex = _.findIndex(validationErrors, { id: eachProject.id });
			let abbreviationError = "";
			let focusAreaError = "";
			
			if (validationIndex > -1) {

				if (!eachProject['abbreviation']) {
					abbreviationError = _.has(validationErrors[validationIndex], 'abbreviation') ? validationErrors[validationIndex]['abbreviation'] : "";
				};

				if (!eachProject['focusArea']) {
					focusAreaError = _.has(validationErrors[validationIndex], 'focusArea') ? validationErrors[validationIndex]['focusArea'] : "";
				};
			};

			innerHTML.push(
				<div key={eachProject.id}>
					{innerHTML.length > 0 ? <hr className="mb-4 mt-2" /> : null}
					<p className="font-weight-bold color-primary mb-3">{eachProject.projectTitle}</p>
					{
						(_.has(eachProject, 'abbreviation') && !showDetailsOfAllProjects)
							?
							null
							:
							<div className="row">
								<div className="col-md-12">
									<div>
										<label>Project abbreviation <span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleProjectNewFieldChanges.bind(this, eachProject, 'abbreviation', 'text')} className="form-control" name="abbreviation" value={abbreviationValue} />
											<p className="text-danger">{abbreviationError &&
												<span className="err">{abbreviationError}</span>}</p>
										</div>
									</div>
								</div>
							</div>
					}
					{
						(_.has(eachProject, 'focusArea') && !showDetailsOfAllProjects)
							?
							null
							:
							<div className="row">
								<div className="col-md-12">
									<div>
										<FocusAreaSelect
											handleFocusAreaSelect={this.handleSelectField.bind(this, eachProject)}
											value={focusAreaValue}
											error={focusAreaError}
											isRequired={true}
										/>
									</div>
								</div>
							</div>
					}
				</div>
			)
		});

		// Not possible just an edge case. If all required fields are already entered. Show createOrEditOutcomeProjectsConfirmationModal
		if (innerHTML.length == 0) {

			return (
				<div>
					<ModalHeader toggle={this.props.onCancel}>Confirm Projects Update</ModalHeader>
					<ModalBody>
						<div>
							<p>Are your sure to update the projects?</p>
							<div><small><i>* This action cannot be undone.</i></small></div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" title="Update Projects" onClick={this.createOrEditOutcomeProjects.bind(this)}>Update projects</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel}>Cancel</Button>
					</ModalFooter>
				</div>
			)
		} else {

			return (
				<div>
					<ModalHeader toggle={this.props.onCancel}>Enter Required Project Details</ModalHeader>
					<ModalBody>{innerHTML}</ModalBody>
					<ModalFooter>
						<Button color="primary" title="Submit" onClick={this.handleProjectsNewFieldsSubmit.bind(this)}>Submit</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel}>Cancel</Button>
					</ModalFooter>
				</div>
			)
		}
	}
}

UpdateProjects.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	UpdateProjects
);

const styles = {

}