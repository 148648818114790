import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class ProductivityMeterScale extends Component {

    constructor(props) {
        super(props);
    }

    /*
     - The pointer image needs to be rotated to 240 deg to make it point to score 7.
     - We can use two approaches to achieve that.
     a. 240 / 7 = 34.38 deg. (ie. multiply the score with const 34.28 to get rotation angle).
     b. Specific section angles for accurate reading.
    */

    getRotationAngle(approach) {

        let score = this.props.score || 0;
        let rotationAngle = 0;

        // Approach 1
        if (approach == 1) {
            rotationAngle = score * 34.28;
            return rotationAngle;
        };

        // Approach 2
        let section1MaxAngle = 25;
        let section2MaxAngle = 55;
        let section3MaxAngle = 99;
        let section4MaxAngle = 140;
        let section5MaxAngle = 176;
        let section6MaxAngle = 215;
        let section7MaxAngle = 240;

        if (score > 0 && score <= 1) {
            rotationAngle = (score * section1MaxAngle);
        }
        else if (score > 1 && score <= 2) {
            rotationAngle = section1MaxAngle + ((score - 1) * (section2MaxAngle - section1MaxAngle));
        };
        if (score > 2 && score <= 3) {
            rotationAngle = section2MaxAngle + ((score - 2) * (section3MaxAngle - section2MaxAngle));
        };
        if (score > 3 && score <= 4) {
            rotationAngle = section3MaxAngle + ((score - 3) * (section4MaxAngle - section3MaxAngle));
        };
        if (score > 4 && score <= 5) {
            rotationAngle = section4MaxAngle + ((score - 4) * (section5MaxAngle - section4MaxAngle));
        };
        if (score > 5 && score <= 6) {
            rotationAngle = section5MaxAngle + ((score - 5) * (section6MaxAngle - section5MaxAngle));
        };
        if (score > 6 && score <= 7) {
            rotationAngle = section6MaxAngle + ((score - 6) * (section7MaxAngle - section6MaxAngle));
        };
        
        return rotationAngle;
    }

	render() {

        let rotationAngle = this.getRotationAngle(2); // Let's use the second approach as default
        let pointerStyle = {
            transformOrigin: '50% 50%',
            transform: `rotate(${rotationAngle}deg)`
        };

        return(
            <div className="text-center">
                <div style={styles.backgroundContainer}>
                    <div style={pointerStyle}>
                        <img src="/images/Pointer.png" alt="Pointer" style={styles.pointerImage} />
                    </div>
                </div>
                <div style={styles.titleCopyContainer}>
                    <div>{this.props.score} / 7</div>
                    <div className="font-bold">{this.props.title}</div>
                </div>
            </div>
        );
	}
}

const styles = {

    backgroundContainer: {
        width: 200,
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url('/images/Productivity_Meter_V3.png')",
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat'
    },

    pointerImage: {
        width: 120,
        height: 120,
        resize: 'contain'
    },

    titleCopyContainer: {
        marginTop: -70,
        marginBottom: 55,
        color: '#fff',
        width: 200,
        textAlign: 'center',
        fontSize: 14
    }
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(
	ProductivityMeterScale
);