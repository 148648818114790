import axios from "axios";
import { toast } from "react-toastify";
import Util from '../utils/Util';
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, LOADING } from "./types";
import jwt from 'jsonwebtoken';

import { history } from '../utils/history';
var UrlConstants = require('../utils/UrlConstants');
var Globals = require('../utils/Globals');

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

export const logoutUser = () => dispatch => {

	dispatch(setLoading(true));

	var coreData = Util.getUser();

	var apiURL = Util.generateBaseAPIURLWithUserToken(coreData.userCoreProperties, UrlConstants.URLS.logout);

	axios
		.get(apiURL)
		.then(res => {
			if (res.data) {

				localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA);
				localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES);
				Util.setAuthToken(false);

				dispatch(setCurrentUser({}));
			} else {
				toast.info('Something went wrong!');
			}
		})
		.catch(err => {
			if (err.response.status == 403) {
				localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA);
				localStorage.removeItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES);
				Util.setAuthToken(false);

				dispatch(setCurrentUser({}));
			}
			console.log('err...', err);
		})
		.finally(() => {
			dispatch(setLoading(false));
		});

};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};