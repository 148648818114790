import axios from "axios";
import { toast } from "react-toastify";
import {
	GET_ERRORS,
	CLEAR_ERRORS,
	LOADING,
	RESET_FOCUS_AREA,
	GET_FOCUS_AREA_QUESTIONS,
	GET_FOCUS_AREA,
	GET_FOCUS_AREAS,
	DELETE_FOCUS_AREA,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetfocusAreaData = () => dispatch => {
	dispatch({
		type: RESET_FOCUS_AREA,
	})
}

// Get Focus Area Questions
export const getFocusAreaQuestions = (response) => dispatch => {

	dispatch(setLoading(true));

	API_Services.httpGET(UrlConstants.URLS.getFocusAreaQuestions, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if(response.data.status) {
				dispatch({
					type: GET_FOCUS_AREA_QUESTIONS,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};

// Get Focus Areas
export const getFocusAreasForTheUser = (response) => dispatch => {

	dispatch({
		type: GET_FOCUS_AREAS,
		payload: response.data.data
	})
};

// Get a Focus Area
export const getFocusAreaByID = (focusAreaId) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getFocusAreaByID;
	var params = 'recordID=' + focusAreaId;

	API_Services.httpGETAddParams(endPoint, params, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_FOCUS_AREA,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};


// Remove a Focus Area
export const removeFocusArea = (id) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.removeFocusArea;

	var postObj = {
		recordID: id,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_FOCUS_AREAS,
					payload: response.data.data,
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});

};

// Update Focus Area are ranks
export const updateFocusAreasRanks = (updatedFocusArea) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.updateFocusAreasRanks;

	var postObj = {
		focusAreaResponses: updatedFocusArea,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_FOCUS_AREAS,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});

};

// Search focus areas
export const searchFocusAreasForTheUser = (searchData) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.searchFocusAreasForTheUser;

	var postObj = {
		searchData: searchData,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_FOCUS_AREAS,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};