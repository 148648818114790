import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import Util from '../../utils/Util';
import EditTimeBlock from "../time-blocks/EditTimeBlock";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TimeBlocksList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			timeblocks: [],
			itemToEdit: null,
			editTimeBlockModal: false,
		};
	}

	componentDidMount() {
		if (this.props.isEdit) {
			this.getUserTimeBlocks();
		}
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		if (this.props.values.length !== prevProps.values.length) {
			this.setState({
				timeblocks: this.props.values
			});
		}
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUserTimeBlocks() {

		this.props.setLoading(true);

		var postObj = {
			"keyActivityID": this.props.keyActivityID,
		};

		API_Services.httpPOST(UrlConstants.URLS.getUserTimeBlocks, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						timeblocks: response.data.data
					});
				} else {
					// this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing time block
	// Add API here so we can handle state even better
	handleTimeBlockDelete() {

		var { timeblocks, itemToRemove } = this.state;

		if (itemToRemove) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove,
			}

			API_Services.httpPOST(UrlConstants.URLS.deleteTimeBlock, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} else if (response.data) {

					if (response.data.status) {

						timeblocks.forEach((eachTimeblock, index) => {

							if (eachTimeblock.id == itemToRemove) {
								timeblocks.splice(index, 1);
							}
						})

						this.props.removeTimeBlock(itemToRemove);

						this.setState({
							deleteModal: false,
							itemToRemove: null,
							timeblocks: timeblocks,
						}, () => {
							this.props.setLoading(false);
						});
					} else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}
	}

	// Show or hide edit modal
	toggleEditTimeBlockModal(rowId) {

		if (this.state.itemToEdit) {
			this.getUserTimeBlocks();
			this.setState({
				editTimeBlockModal: false,
				itemToEdit: null,
			});
		} else {
			this.setState({
				editTimeBlockModal: true,
				itemToEdit: rowId,
			});
		}
	}

	// update the list with the edited data
	updateEditedTimeBlockInList(data) {

		var newData = [...this.state.timeblocks, data];

		this.setState({
			editTimeBlockModal: false,
			timeblocks: newData,
		});
	}

	// Render edit modal
	renderEditTimeBlockInModal() {

		return (
			<div>
				<Modal isOpen={this.state.editTimeBlockModal} toggle={this.toggleEditTimeBlockModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleEditTimeBlockModal.bind(this)}>Edit Time Block </ModalHeader>
					<ModalBody>
						<EditTimeBlock
							updateEditedTimeBlockInList={this.updateEditedTimeBlockInList.bind(this)}
							timeBlockID={this.state.itemToEdit}
							keyActivityID={this.props.keyActivityID} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	// Renders time blocks in table
	renderTimeBlocksTable() {

		// const { timeblocks } = this.props.timeblocks;
		const { timeblocks } = this.state;

		if (!this.props.isEdit) {
			return (
				<div className="small text-info">Please save your key activity before creating Time Blocks.</div>
			);
		}
		else if (timeblocks.length == 0) {
			return (
				<div className="small text-info">No Time Blocks added.</div>
			);
		};

		return (
			<div className="App" style={{ marginTop: 20 }}>
				<header className="App-header table-responsive">
					<table className="table-curved">
						<thead>
							<tr>
								<th className="text-center" style={{ width: 50 }}>#</th>
								<th className="text-left" style={{ width: 300 }}>Title</th>
								<th className="text-center" style={{ width: 150 }}>Type</th>
								<th className="text-center" style={{ width: 150 }}>Duration</th>
								<th className="text-center" style={{ width: 150 }}>Regularity</th>
								<th className="text-center" style={{ width: 120 }}><div># of Sessions<div><small>(per week)</small></div></div></th>
								<th className="text-center" style={{ width: 150 }}><div>Duration<div><small>(per week)</small></div></div></th>
								<th className="text-center" style={{ width: 100 }} colSpan={2}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{timeblocks.map((timeBlockObj, index) => {
								
								let isNonWeeklyTB = timeBlockObj.regularity == 'non-weekly' ? true : false;
								let isDailyTB = timeBlockObj.regularity == 'daily' ? true : false;
								let isEveryXDaysTB = timeBlockObj.interval == 'every_x_days' ? true : false;
								let durationInMinutesPerWeek = isNonWeeklyTB || isEveryXDaysTB ? (timeBlockObj.durationInMinutesPerWeek || 0) : ((timeBlockObj.durationInMinutes || 0) * (timeBlockObj.sessionsPerWeek || 0));
								let regularity = 'Weekly';

								if (isNonWeeklyTB || isDailyTB) {
									if (timeBlockObj.interval == 'every_x_months' && timeBlockObj.recurringInterval) {
										regularity = `Every ${timeBlockObj.recurringInterval} Months`;
									}
									else if (timeBlockObj.interval == 'every_x_years' && timeBlockObj.recurringInterval) {
										regularity = `Every ${timeBlockObj.recurringInterval} Years`;
									}
									else if (timeBlockObj.interval == 'every_x_weeks' && timeBlockObj.recurringInterval) {
										regularity = `Every ${timeBlockObj.recurringInterval} Weeks`;
									}
									else if (timeBlockObj.interval == 'every_x_days' && timeBlockObj.recurringInterval) {
										regularity = `Every ${timeBlockObj.recurringInterval} Days`;
									}
									else {
										regularity = _.startCase(_.camelCase(timeBlockObj.interval));
									};
								};

								return (
									<tr key={timeBlockObj.id}>
										<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
										<td className="text-left" style={{ width: 300 }}>{timeBlockObj.title}</td>
										<td className="text-center" style={{ width: 100 }}>{timeBlockObj.type}</td>
										<td className="text-center" style={{ width: 150 }}>{Util.convertTimeToReadableText(timeBlockObj.durationInMinutes)}</td>
										<td className="text-center" style={{ width: 150 }}>{regularity}</td>
										<td className="text-center" style={{ width: 120 }}>{isNonWeeklyTB || isEveryXDaysTB ? <small>N/A</small> : timeBlockObj.sessionsPerWeek}</td>
										<td className="text-center" style={{ width: 150 }}>{Util.convertTimeToReadableText(durationInMinutesPerWeek)}</td>
										<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleEditTimeBlockModal.bind(this, timeBlockObj.id)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
										<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, timeBlockObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{this.renderEditTimeBlockInModal()}
				</header>
			</div>
		);
	}

	renderTimeBlockDeleteModal() {

		var tbObj = _.find(this.state.timeblocks, { id: this.state.itemToRemove });
		var tbTitle = tbObj ? tbObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete TimeBlock</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the time block <span className="text-warning">{tbTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleTimeBlockDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<div className="section-container">
					{this.renderTimeBlocksTable()}
				</div>
				{this.renderTimeBlockDeleteModal()}
			</div>
		);
	}
}

TimeBlocksList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	timeblocks: state.timeblocks,
});

export default connect(mapStateToProps, { setLoading })(
	TimeBlocksList
);
