import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import UTaskForm from "./UTaskForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditUTask extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			task: {},
			apiInitiated: false,
		}
	}

	componentDidMount() {

		this.props.setLoading(true);
		
		this.setState({
			apiInitiated: true,
		});

		var postObj = {
			recordID: this.props.taskID,
		};

		API_Services.httpPOST(UrlConstants.URLS.getTaskByID, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setState({
						task: response.data.data,
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {	
				this.setFormApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
			this.setState({
				apiInitiated: false,
			});
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	// Handler for editing Task
	handleEditTask(data) {

		data.recordID = this.props.taskID;
		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateTask, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setFormApiResponse("Task saved successfully.", false);
					this.props.onTaskUpdate(response.data.data);
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		const { task, formApiResponse, apiInitiated } = this.state;

		if (!Util.isEmpty(task)) {

			return (
				<div>
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<UTaskForm
										initialValues={task}
										reloadData={this.props.reloadData}
										onSubmit={this.handleEditTask.bind(this)}
										isEdit={true}
										taskID={this.props.taskID}
									/>
									{
										!_.isEmpty(formApiResponse)
											?
											<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
											: null
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} 
		else if (apiInitiated) {
			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				</div>
			);
		}
		else {
			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<p className={'text-info'}><span>Task not found.</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

EditUTask.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	tasks: state.tasks,
});

export default connect(mapStateToProps, { setLoading })(
	EditUTask
);
