import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import AddTimeBlock from "../time-blocks/AddTimeBlock";
import TimeBlocksList from "../time-blocks/TimeBlocksList";

import KeyActivityMetrics from "../metrics/KeyActivityMetrics";

import AddTask from "../tasks/AddTask";
import TasksList from "../tasks/TasksList";

import { setLoading } from "../../actions/loadingActions.js";
import { resetKeyActivityData } from "../../actions/keyActivityActions";

import Util from '../../utils/Util';
var _ = require('lodash');

class EditKeyActivityForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				kaIncludeInWeeklyPlan: true,
				notes: "",
			},
			formErrors: {
				title: null,
				kaIncludeInWeeklyPlan: null,
				notes: null,
			},
			buttonDisabled: false,
			isEdit: false,
			notesEditorState: EditorState.createEmpty(),
			timeBlocks: [],
			addTimeBlockModal: false,
			timeBlocksError: "",
			tasks: [],
			addTaskModal: false,
			tasksError: "",
		};
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { timeBlocks, tasks, includeInWeeklyPlan, notes } = this.props.initialValues;

			var formObj = {};

			formObj = {
				...this.props.initialValues,
				"kaIncludeInWeeklyPlan": includeInWeeklyPlan,
			};

			this.setState({
				form: formObj,
				timeBlocks: timeBlocks,
				tasks: tasks,
				isEdit: true,
				notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
			});
		}
		this.setStickyToolBar();
	}

	setStickyToolBar() {

		const header = document.getElementById("key-activities-toolbar");

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Key Activity Title";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for keyActivity submit
	handleKeyActivitySubmit(gotoParent, e) {
		e.preventDefault();

		this.setState({
			timeBlocksError: "",
		});

		const { form, formErrors, notesEditorState, timeBlocks, tasks } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {

				} else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			var timeBlocksCopy = [];

			if (timeBlocks && timeBlocks.length > 0) {
				timeBlocks.forEach((eachTimeBlock) => {
					timeBlocksCopy.push(eachTimeBlock.id);
				});
			}
			formCopy['timeBlocks'] = timeBlocksCopy;

			var tasksCopy = [];

			if (tasks && tasks.length > 0) {
				tasks.forEach((eachTask) => {
					tasksCopy.push(eachTask.id);
				});
			}

			// formCopy['tasks'] = tasksCopy; Not needed as per the universal ranking approach.

			formCopy['notes'] = notesContent;
			formCopy['includeInWeeklyPlan'] = form['kaIncludeInWeeklyPlan'];

			this.props.onSubmit(formCopy, gotoParent);
		}
	}

	toggleAddTimeBlockModal() {

		if (!this.state.isEdit) {

			this.setState({
				timeBlocksError: "Please save your key activity before creating Time Blocks.",
			})
		} else {

			if (this.state.addTimeBlockModal) {
				this.setState({
					addTimeBlockModal: false,
				});
			} else {
				this.setState({
					addTimeBlockModal: true,
				});
			}
		}
	}

	renderAddTimeBlockInModal() {

		return (
			<div>
				<Modal isOpen={this.state.addTimeBlockModal} toggle={this.toggleAddTimeBlockModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddTimeBlockModal.bind(this)}>Add Time Block </ModalHeader>
					<ModalBody>
						<AddTimeBlock
							addTimeBlockInList={this.addTimeBlockInList.bind(this)}
							keyActivityID={this.props.keyActivityID} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	addTimeBlockInList(data) {

		var newData = [...this.state.timeBlocks, data];

		this.setState({
			addTimeBlockModal: false,
			timeBlocks: newData,
		});
	}

	removeTimeBlock(itemToRemove) {

		var { timeBlocks } = this.state;

		timeBlocks.forEach((eachTimeBlock, index) => {

			if (eachTimeBlock.id == itemToRemove) {
				timeBlocks.splice(index, 1);
			}
		})

		this.setState({
			timeBlocks: timeBlocks,
		});
	}

	toggleAddTaskModal() {

		if (!this.state.isEdit) {

			this.setState({
				tasksError: "Please save your key activity before creating Tasks.",
			})
		} else {

			if (this.state.addTaskModal) {
				this.setState({
					addTaskModal: false,
				});
			} else {
				this.setState({
					addTaskModal: true,
				});
			}
		}
	}

	renderAddTaskInModal() {

		return (
			<div>
				<Modal isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Add Task </ModalHeader>
					<ModalBody>
						<AddTask
							itemPosition={1} // Add new tasks at the top.
							addTaskInList={this.addTaskInList.bind(this)}
							keyActivityID={this.props.keyActivityID} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	addTaskInList(itemPosition, newTask) {

		let { tasks } = this.state;
		itemPosition = itemPosition ? parseInt(itemPosition)-1 : 0; // The newly added task will be added at the top by default.					
			
		if (itemPosition > tasks.length) {
			itemPosition = tasks.length;
		};
		tasks.splice(itemPosition, 0, newTask); // Add tasks at the required position.

		setTimeout(() => {
			this.setState({
				tasks: tasks,
				addTaskModal: false
			});
		}, 500);
	}

	updateTasksSequence(updatedSequence) {

		var formCopy = { ...this.state.form };
		formCopy['tasksSequence'] = updatedSequence;

		this.setState({
			form: formCopy,
		});
	}

	removeTask(itemToRemove) {

		var { tasks } = this.state;

		tasks.forEach((eachTask, index) => {

			if (eachTask.id == itemToRemove) {
				tasks.splice(index, 1);
			}
		})

		this.setState({
			tasks: tasks,
		});
	}

	render() {

		const { form, formErrors, notesEditorState, timeBlocks, timeBlocksError, tasks, tasksError, isEdit } = this.state;

		return (
			<div>
				<form onSubmit={(e) => e.preventDefault()}>

					<div id={'key-activities-toolbar'} className="text-right mb-1">
						{ isEdit ?
							<div>
								<button type="submit" onClick={this.handleKeyActivitySubmit.bind(this, false)} className="btn btn-primary btn-sm">Save</button>
								<button type="submit" onClick={this.handleKeyActivitySubmit.bind(this, true)} className="btn btn-primary btn-sm ml-4">Save & Close</button>
							</div> :
							<div>
								<button type="button" onClick={this.handleKeyActivitySubmit.bind(this, false)} className="btn btn-primary btn-sm">Create</button>
							</div>
						}
					</div>

					<div>
						<div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
								<p className="text-danger">{formErrors['title'] &&
									<span className="err">{formErrors['title']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div className="custom-control custom-switch">
								<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="kaIncludeInWeeklyPlan" value={form['kaIncludeInWeeklyPlan']} id="kaIncludeInWeeklyPlanSwitch" checked={form['kaIncludeInWeeklyPlan'] ? "checked" : ""} />
								<label className="custom-control-label" htmlFor="kaIncludeInWeeklyPlanSwitch">Include In Weekly Plan <span className="text-danger">*</span></label>
								<p className="text-danger">{formErrors['kaIncludeInWeeklyPlan'] &&
									<span className="err">{formErrors['kaIncludeInWeeklyPlan']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="col-6">
									<label>Time Blocks</label>
								</div>
								<div className="col-6 text-right">
									<button type="button" className="btn btn-primary btn-xs" onClick={this.toggleAddTimeBlockModal.bind(this)} disabled={this.props.isEdit ? false : true}>
										<i className="fa fa-plus" aria-hidden="true"></i>
									</button>
								</div>
							</div>
							<div>
								<TimeBlocksList
									values={timeBlocks}
									isEdit={this.props.isEdit}
									keyActivityID={this.props.keyActivityID}
									removeTimeBlock={this.removeTimeBlock.bind(this)} />
							</div>
							{
								timeBlocksError
									? <p className="text-warning">{timeBlocksError}</p>
									: null
							}
							<br />
						</div>

						<div className="form-group">
							<div>
								<KeyActivityMetrics
									isEdit={this.props.isEdit}
									outcomeID={this.props.outcomeID}
									keyActivityID={this.props.keyActivityID} />
							</div>
							<br />
						</div>

						<div className="form-group">
							{/* <div className="row">
								<div className="col-6">
									<label>Tasks</label>
								</div>
								<div className="col-6 text-right">
									<button type="button" className="btn btn-primary" onClick={this.toggleAddTaskModal.bind(this)}>
										<i className="fa fa-plus" aria-hidden="true"></i>
									</button>
								</div>
							</div> */}
							<div>
								<TasksList
									values={tasks}
									isEdit={isEdit}
									addTaskInList={this.addTaskInList.bind(this)}
									keyActivityID={this.props.keyActivityID}
									updateTasksSequence={this.updateTasksSequence.bind(this)}
									removeTask={this.removeTask.bind(this)} />
							</div>
							{
								tasksError
									? <p className="text-warning">{tasksError}</p>
									: null
							}
							<br />
						</div>

						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
										})
									}
								/>
							</div>
						</div>
					</div>
				</form>
				{this.renderAddTimeBlockInModal()}
				{this.renderAddTaskInModal()}
			</div>
		);
	}
}

EditKeyActivityForm.propTypes = {
	resetKeyActivityData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetKeyActivityData, setLoading })(
	EditKeyActivityForm
);
