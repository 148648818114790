import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ThriveMeterReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			recommendedTracks: this.props.recommendedTracks,
		}
		window.scrollTo(0, 0);
	}

	renderPercentileBar(component) {

		var score = 0;
		var styleColor = null;

		if (component == 'Core Enablers') {
			score = this.state.recommendedTracks.coreEnablerGroupAvg;
			styleColor = '#1EB0D8';
		}
		else if (component == 'Inner Compass') {
			score = this.state.recommendedTracks.innerCompassGroupAvg;
			styleColor = '#FAB95E';
		}
		else if (component == 'Good Living') {
			score = this.state.recommendedTracks.goodLivingGroupAvg;
			styleColor = '#AECF7A';
		}
		else if (component == 'Being Extraordinary') {
			score = this.state.recommendedTracks.beingExtraordinaryGroupAvg;
			styleColor = '#f9a576';
		}
		else {
			return <div />;
		}

		// The avg.score is computed on a 10pt scale. Multiply it with 100 to compute bar width.
		let width = (parseFloat(score) * 10) + '%';

		return (
			<div>
				<div style={{ ...styles.percentileText, ...{ color: styleColor } }}>{component} - {score} / 10</div>
				<div style={{ ...styles.percentileBar, ...{ width: width, backgroundColor: styleColor } }}></div>
			</div>
		)
	}

	renderCoreEnablersReport(recommendedTracks) {

		return (
			<div>
				<div style={styles.sectionTitle}>Core Enablers: {recommendedTracks.coreEnablerGroupAvg} / 10</div>
				<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#1EB0D8' } }}>
					<p style={styles.sectionText}>These are the elements that are required to <span style={{ fontFamily: 'Pangram-Bold' }}>bring about any kind of meaningful growth and change</span>. Without the ability to learn and grow effectively, there’s very little we could accomplish in life, and that makes this component foundational and core to our wellbeing and success.</p>
					<p style={styles.sectionText}>When lacking in this area, we might find ourselves stuck in a rut in at least some, if not all, aspects of our lives—unwilling or unable to break the various problematic habits and patterns, thus limiting our ability to thrive and succeed.</p>
					<p style={styles.sectionText}>You can improve in this area by working on the value of</p>
					{recommendedTracks.coreEnablerGroupResults && recommendedTracks.coreEnablerGroupResults[0] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.coreEnablerGroupResults[0].valueName}</div>
						</div>
						: null}
				</div>
			</div>
		)
	}

	renderInnerCompassReport(recommendedTracks) {

		return (
			<div>
				<div style={styles.sectionTitle}>Inner Compass: {recommendedTracks.innerCompassGroupAvg} / 10</div>
				<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#FAB95E' } }}>
					<p style={styles.sectionText}>These are the elements that are required to <span style={{ fontFamily: 'Pangram-Bold' }}>make sound choices and to be a good and grounded human being</span>. Whether we realize it or not, we are constantly making choices as we navigate from one moment to another. Even not taking action or not making a decision is a choice. Such choices have a far-reaching effect on all aspects of our lives, greatly influencing the kind of person we become.</p>
					<p style={styles.sectionText}>When lacking in this area, one might experience issues such as confusion, lack of confidence, and difficulty sustaining warm relationships.</p>
					<p style={styles.sectionText}>You can improve in this area by working on the value of</p>
					{recommendedTracks.innerCompassGroupResults && recommendedTracks.innerCompassGroupResults[0] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.innerCompassGroupResults[0].valueName}</div>
						</div>
						: null}
					{recommendedTracks.innerCompassGroupResults && recommendedTracks.innerCompassGroupResults[1] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.innerCompassGroupResults[1].valueName}</div>
						</div>
						: null}
				</div>
			</div>
		)
	}

	renderGoodLivingReport(recommendedTracks) {

		return (
			<div>
				<div style={styles.sectionTitle}>Good Living: {recommendedTracks.goodLivingGroupAvg} / 10</div>
				<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#AECF7A' } }}>
					<p style={styles.sectionText}>These are the elements that are required to <span style={{ fontFamily: 'Pangram-Bold' }}>be generally happy and productive</span>. Needless to say, this affects everything from our relationships to work, and the level of success we enjoy as well as the joy we experience in our lives.</p>
					<p style={styles.sectionText}>When lacking in this area, we might experience issues such as inability to follow through, discontentment, and disappointment.</p>
					<p style={styles.sectionText}>You can improve in this area by working on the value of</p>
					{recommendedTracks.goodLivingGroupResults && recommendedTracks.goodLivingGroupResults[0] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.goodLivingGroupResults[0].valueName}</div>
						</div>
						: null}
					{recommendedTracks.goodLivingGroupResults && recommendedTracks.goodLivingGroupResults[1] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.goodLivingGroupResults[1].valueName}</div>
						</div>
						: null}
				</div>
			</div>
		)
	}

	renderBeingExtraordinaryReport(recommendedTracks) {

		return (
			<div>
				<div style={styles.sectionTitle}>Being Extraordinary: {recommendedTracks.beingExtraordinaryGroupAvg} / 10</div>
				<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#f9a576' } }}>
					<p style={styles.sectionText}>These are the elements that are required to <span style={{ fontFamily: 'Pangram-Bold' }}>be outstanding</span>. This component helps us set ourselves apart from the rest, allowing us to think and operate on a whole new level.</p>
					<p style={styles.sectionText}>When lacking in this area, we might experience issues such as not living up to our expectations, finding ourselves competing with others, and inability to pursue audacious objectives.</p>
					<p style={styles.sectionText}>You can improve in this area by working on the value of</p>
					{recommendedTracks.beingExtraordinaryGroupResults && recommendedTracks.beingExtraordinaryGroupResults[0] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.beingExtraordinaryGroupResults[0].valueName}</div>
						</div>
						: null}
					{recommendedTracks.beingExtraordinaryGroupResults && recommendedTracks.beingExtraordinaryGroupResults[1] ?
						<div style={styles.valueRecommendationContainer}>
							<img src='/images/Value_Icon.png' style={styles.valueIcon}></img>
							<div style={styles.valueRecommendationText}>{recommendedTracks.beingExtraordinaryGroupResults[1].valueName}</div>
						</div>
						: null}
				</div>
			</div>
		)
	}

	render() {

		let { recommendedTracks } = this.state;

		return (
			<div>
				<div className="text-center">
					<p><img src='/images/Thrive_Icon.png' width={100}></img></p>
					<p style={{color: "#FAB95E", fontSize:20}} className="font-bold">Thrive Meter</p>
				</div>

				<p className="text-blog">Here, we consider what it takes to thrive and be successful in life. It is based on our <span style={{ fontFamily: 'Pangram-Bold' }}>Thrive framework</span> consisting of four key components, namely Core Enablers, Inner Compass, Good Living, and Being Extraordinary. Information on these is provided below, but know that it's when each of these components is healthy that we experience a certain kind of <span style={{ fontFamily: 'Pangram-Bold' }}>wholeness</span>, which in turn leads to effortless growth and all-around success.</p>

				<p className="text-blog">One other aspect that’s worth understanding before diving into the specifics is that the closer we get to wholeness as mentioned above, the greater our ability to do any given task or activity, irrespective of whether we have the innate ability to do that thing or not. Meaning, by making meaningful progress on this wholeness scale, we are able to break the limiting boxes that we (or others) typically put ourselves in with regard to skills, interests, and pursuits. Therefore, the greater our maturity on this wholeness dimension, the greater our capacity to do anything we’d like with ease and grace.</p>

				<p className="text-blog">Let's take a look at your score on each component.</p>

				{this.renderPercentileBar('Core Enablers')}
				{this.renderPercentileBar('Inner Compass')}
				{this.renderPercentileBar('Good Living')}
				{this.renderPercentileBar('Being Extraordinary')}

				<p style={{ marginTop: 15 }} className="text-blog">Let's take a look at what the different components specifically mean:</p>

				{this.renderCoreEnablersReport(recommendedTracks)}
				{this.renderInnerCompassReport(recommendedTracks)}
				{this.renderGoodLivingReport(recommendedTracks)}
				{this.renderBeingExtraordinaryReport(recommendedTracks)}
			</div>
		)
	}
}

ThriveMeterReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

const styles = {

	sectionHeading: {
		fontFamily: 'Pangram-Regular',
		color: '#FAB95E',
		textAlign: 'center',
		fontSize: '18px',
		marginBottom: '15px'
	},
	percentileText: {
		fontFamily: 'Pangram-Regular',
		fontSize: 16,
		paddingTop: 10,
		paddingBottom: 10,
	},
	percentileBar: {
		height: 20,
		borderRadius: 5,
		borderWidth: 1,
		backgroundColor: '#d49a89',
		borderColor: 'transparent'
	},
	sectionTitle: {
		fontFamily: 'Pangram-Bold',
		color: '#737373',
		fontSize: 17,
		paddingTop: 15,
		paddingBottom: 5
	},
	sectionText: {
		fontFamily: 'Pangram-Light',
		fontSize: 16,
		textAlign: 'center',
		color: '#fff',
		paddingTop: 5,
		marginBottom: 5,
		lineHeight: 1.75
	},
	reportContainer: {
		backgroundColor: '#FAB95E',
		padding: 10,
		marginTop: 10,
		marginBottom: 10,
		borderRadius: 10
	},
	valueRecommendationContainer: {
		backgroundColor: '#fff',
		alignSelf: 'center',
		paddingVertical: 3,
		paddingHorizontal: 10,
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '3px 15px',
		width: 'fit-content',
		margin: 'auto',
		marginTop: 10
	},
	valueIcon: {
		resizeMode: 'contain',
		width: 25,
		height: 25,
		marginRight: 10
	},
	valueRecommendationText: {
		fontFamily: 'Pangram-Regular',
		color: '#737373',
		fontSize: 15
	}
}

export default connect(mapStateToProps, { setLoading })(
	ThriveMeterReport
);
