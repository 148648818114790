import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import ReactTooltip from 'react-tooltip';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

let metricsAndTargetsSampleResponse = require('../../assets/api_responses/MetricsAndTargetsReports.json')

class TrackerReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			apiResponse: {
				className: "",
				message: "",
			},
			outcomes: [],
			defaultTimeBlockTypes: [],
			timeAllocationStats: {},
			outcomesWithKeyActivitiesVisible: [], // An Array that contains list of Outcome ID's. Based on this field, the KeyActivities for the Outcome will be displayed.
			visibleMetricsSection: [], // An Array that contains uniqueID's of Metrics & Targets section & sub section that are visible.
			showTable: false,
			tableDisplayView: 'detailed',
			zoomValue: 100,
			startWeekDt: moment().startOf('isoWeek'),
			endWeekDt: moment().endOf('isoWeek'),
			clientTimeZone: momentTimezone.tz.guess(),
			showFocusAreaTable: true,
			showOutcomesTable: true,
			showMetricsTable: true,
			totalAvailableTimeInMinutes: null,
		};
	}

	componentDidMount() {
		this.getTrackerReportForTheUser();
	}

	getTrackerReportForTheUser() {

		this.props.setLoading(true);

		this.setState({
			outcomes: null,
			focusAreas: null,
			outcomesTotal: null,
			focusAreasTotal: null,
			totalAvailableTimeInMinutes: null,
			showTable: false,
		});

		let postObj = {
			targetWeekStart: this.state.startWeekDt,
			targetWeekEnd: this.state.endWeekDt,
			clientTimeZone: this.state.clientTimeZone
		};

		API_Services.httpPOST(UrlConstants.URLS.getTrackerReportForTheWeek, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					let data = response.data.data;

					this.setState({
						outcomes: data.outcomes,
						focusAreas: data.focusAreas,
						outcomesTotal: data.outcomesTotal,
						focusAreasTotal: data.focusAreasTotal,
						totalAvailableTimeInMinutes: data.totalAvailableTimeInMinutes,
						metricsAndTargets: data.metricsAndTargets,
						showTable: true,
						showFocusAreaTable: true,
						showOutcomesTable: true,
						showMetricsTable: true,
						apiResponse: {
							className: '',
							message: ''
						}
					},
					() => {
						this.updateMetricsVisibility();
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	updateMetricsVisibility() {

		let { metricsAndTargets } = this.state;
		let visibleMetricsSection = [];

		if (metricsAndTargets) {
		
			for (var i=0; i < metricsAndTargets.length; i++) {
				let outcomeObj = metricsAndTargets[i];
				visibleMetricsSection.push('ow_' + outcomeObj.id); // Outcome metrics wrapper
			};
		};
		this.setState({visibleMetricsSection});
	}

	setApiResponse(message, hasError) {

		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	checkKeyActivitiesVisibility(outcomeID) {
		const { outcomesWithKeyActivitiesVisible } = this.state;
		return outcomesWithKeyActivitiesVisible.indexOf(outcomeID) > -1;
	};

	toggleKeyActivitiesVisibility(outcomeID) {

		let { outcomesWithKeyActivitiesVisible } = this.state;
		let outcomeIDIndex = outcomesWithKeyActivitiesVisible.indexOf(outcomeID);

		if (outcomeIDIndex == -1) {
			outcomesWithKeyActivitiesVisible.push(outcomeID);
		}
		else {
			outcomesWithKeyActivitiesVisible.splice(outcomeIDIndex, 1);
		};
		this.setState({outcomesWithKeyActivitiesVisible});
	};

	checkMetricsSectionVisibility(uniqueID) {
		const { visibleMetricsSection } = this.state;
		return visibleMetricsSection.indexOf(uniqueID) > -1;
	};

	toggleMetricsSectionVisibility(uniqueID) {

		let { visibleMetricsSection } = this.state;
		let uniqueIDIndex = visibleMetricsSection.indexOf(uniqueID);

		if (uniqueIDIndex == -1) {
			visibleMetricsSection.push(uniqueID);
		}
		else {
			visibleMetricsSection.splice(uniqueIDIndex, 1);
		};
		this.setState({visibleMetricsSection});
	};

	handleZoomChange(action = null, newValue) {

		let { zoomValue } = this.state;

		if (action == 'zoomIn') {
			zoomValue -= 5;
		}
		else if (action == 'zoomOut') {
			zoomValue += 5;
		}
		else if (newValue) {
			zoomValue = parseInt(newValue);
		};

		this.setState({zoomValue});
	}

	handlerToNavigate = (e, navigateAction) => {

		e.preventDefault();
		
		let { startWeekDt, endWeekDt } = this.state;

		if (navigateAction === 'PREV'){
			startWeekDt = moment(startWeekDt).subtract(1, 'weeks').startOf('isoWeek');
			endWeekDt = moment(endWeekDt).subtract(1, 'weeks').endOf('isoWeek');
		}
		else if (navigateAction === 'NEXT') {
			startWeekDt = moment(startWeekDt).add(1, 'weeks').startOf('isoWeek');
			endWeekDt = moment(endWeekDt).add(1, 'weeks').endOf('isoWeek');
		}
		else if (navigateAction === 'TODAY') {
			startWeekDt = moment().startOf('isoWeek');
			endWeekDt = moment().endOf('isoWeek');
		};

		this.setState({ startWeekDt, endWeekDt },() => {
			this.getTrackerReportForTheUser(); // fetch Tracker Report for that Week
		});
	}

	renderOutcomeSummaryRows() {

		const { outcomesTotal } = this.state;

		if (outcomesTotal) {
			return (
				<tr key={'required_total'} style={{backgroundColor: '#f6f6f6'}}>
					<td className="text-right" colSpan={4}>Total</td>
					<td className="text-center">{outcomesTotal.totalPlannedTimeWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(outcomesTotal.totalPlannedTimeWithBufferInMinutes) : ''}</td>
					<td className="text-center">{outcomesTotal.percentageOfPlannedTimeWithBufferBasedOnATE}%</td>
					<td className="text-center">{outcomesTotal.totalActualTimeInMinutes > 0 ? Util.convertTimeToReadableText(outcomesTotal.totalActualTimeInMinutes) : ''}</td>
					<td className="text-center">{outcomesTotal.percentageOfActualTimeBasedOnATE}%</td>
				</tr>
			);
		}
		else {
			return (
				<tr key={'required_total'} style={{backgroundColor: '#f6f6f6'}}>
					<td className="text-right" colSpan={4}>Total</td>
					<td className="text-center"></td>
					<td className="text-center"></td>
					<td className="text-center"></td>
					<td className="text-center"></td>
				</tr>
			);
		}
	}

	renderOutcomeKeyActivitiesData(outcomeObj) {

		let keyActivities = outcomeObj.keyActivities || [];
		let keyActivitiesArray = [];

		if (this.checkKeyActivitiesVisibility(outcomeObj.id) == false) {
			return null;
		};

		keyActivities.map((activityObj, activityIndex) => {

			keyActivitiesArray.push (
				<tr className="text-center" key={activityObj.id} style={styles.activitiesRow}>
					<td></td>
					<td><span></span></td>
					<td colSpan={2} className="text-left"><div className="ml-3"><a href={'/key-activities/edit/' + activityObj.id + '?outcomeID=' + outcomeObj.id} target='_blank' style={SharedStyles.subTitleLink}>{activityObj.title}</a></div></td>
					<td>{activityObj.totalPlannedTimeWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(activityObj.totalPlannedTimeWithBufferInMinutes) : ''}</td>
					<td>{activityObj.percentageOfPlannedTimeWithBufferBasedOnATE > 0 ? activityObj.percentageOfPlannedTimeWithBufferBasedOnATE + '%' : ''}</td>
					<td>{activityObj.totalActualTimeInMinutes > 0 ? Util.convertTimeToReadableText(activityObj.totalActualTimeInMinutes) : ''}</td>
					<td>{activityObj.percentageOfActualTimeBasedOnATE > 0 ? activityObj.percentageOfActualTimeBasedOnATE + '%' : ''}</td>
				</tr>
			);
		});

		return keyActivitiesArray;
	}

	renderOutcomeTableRow(outcomeObj, outcomeIndex) {

		const { tableDisplayView } = this.state;

		let associatedRows = [];
		let activitiesVisibilityIcon = this.checkKeyActivitiesVisibility(outcomeObj.id) ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		associatedRows.push (
			<tr className="text-center" key={'outcome_' + outcomeIndex}>
				<td>{outcomeIndex + 1}</td>
				<td className="cursor-pointer" onClick={() => this.toggleKeyActivitiesVisibility(outcomeObj.id)}><img src={activitiesVisibilityIcon} width="10" /></td>
				<td colSpan={2} className="text-left"><a href={'/projects/edit/' + outcomeObj.id} target='_blank' style={SharedStyles.titleLink}>{outcomeObj.outcomeTitle}</a></td>
				<td>{outcomeObj.totalPlannedTimeWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(outcomeObj.totalPlannedTimeWithBufferInMinutes) : ''}</td>
				<td>{outcomeObj.percentageOfPlannedTimeWithBufferBasedOnATE > 0 ? outcomeObj.percentageOfPlannedTimeWithBufferBasedOnATE + '%' : ''}</td>
				<td>{outcomeObj.totalActualTimeInMinutes > 0 ? Util.convertTimeToReadableText(outcomeObj.totalActualTimeInMinutes) : ''}</td>
				<td>{outcomeObj.percentageOfActualTimeBasedOnATE > 0 ? outcomeObj.percentageOfActualTimeBasedOnATE + '%': ''}</td>
			</tr>
		);

		if (tableDisplayView == 'detailed') {
			associatedRows.push(this.renderOutcomeKeyActivitiesData(outcomeObj));
		};
		
		return associatedRows;
	}

	renderFASummaryRows() {

		const { focusAreasTotal } = this.state;

		if (focusAreasTotal) {
			return (
				<tr key={'required_total'} style={{backgroundColor: '#f6f6f6'}}>
				<td className="text-right" colSpan={2}>Total</td>
				<td className="text-center">{focusAreasTotal.totalPlannedTimeWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(focusAreasTotal.totalPlannedTimeWithBufferInMinutes) : ''}</td>
				<td className="text-center">{focusAreasTotal.percentageOfPlannedTimeWithBufferBasedOnATE}%</td>
				<td className="text-center">{focusAreasTotal.totalActualTimeInMinutes > 0 ? Util.convertTimeToReadableText(focusAreasTotal.totalActualTimeInMinutes) : ''}</td>
				<td className="text-center">{focusAreasTotal.percentageOfActualTimeBasedOnATE}%</td>
			</tr>
			)
		}
		else {
			return (
				<tr key={'required_total'} style={{backgroundColor: '#f6f6f6'}}>
				<td className="text-right" colSpan={2}>Total</td>
				<td className="text-center"></td>
				<td className="text-center"></td>
				<td className="text-center"></td>
				<td className="text-center"></td>
			</tr>
			)
		}
	}

	renderFocusAreaTableRow() {

		const { focusAreas } = this.state;

		return (
			<React.Fragment>
				{
					!focusAreas || focusAreas && focusAreas.length == 0 ?
						<tr>
							<td className="text-center"></td>
							<td className="text-center"></td>
							<td className="text-center"></td>
							<td className="text-center"></td>
							<td className="text-center"></td>
							<td className="text-center"></td>
						</tr>
					:
					focusAreas.map((obj, index) => {
						return (
							<tr key={"focus_area_" + obj.id}>
								<td className="text-center">{(index + 1)}</td>
								<td className="text-left">{Util.focusAreaTitleWithTimeScaleSuffix(obj)}</td>
								<td className="text-center">{obj.totalPlannedTimeWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(obj.totalPlannedTimeWithBufferInMinutes) : ''}</td>
								<td className="text-center">{obj.percentageOfPlannedTimeWithBufferBasedOnATE > 0 ? obj.percentageOfPlannedTimeWithBufferBasedOnATE + '%' : ''}</td>
								<td className="text-center">{obj.totalActualTimeInMinutes > 0 ? Util.convertTimeToReadableText(obj.totalActualTimeInMinutes) : ''}</td>
								<td className="text-center">{obj.percentageOfActualTimeBasedOnATE > 0 ? obj.percentageOfActualTimeBasedOnATE + '%': ''}</td>
							</tr>
						);
					})
				}
			</React.Fragment>
		)
	}

	renderFocusAreaTable() {

		const { showFocusAreaTable } = this.state;
		let visibilityIcon = showFocusAreaTable ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		return (
			<div>
				<div className="mt-2 mb-3">
					<span className="cursor-pointer mr-2" onClick={() => this.setState({showFocusAreaTable: !showFocusAreaTable})}><img src={visibilityIcon} width="10" /></span>	
					<span className="font-bold" style={{fontSize:17}}>Focus Areas</span>
				</div>
				{showFocusAreaTable ?
					<div className="table-responsive vertical-center">
						<table className="table table-curved">
							<thead>
								<tr>
									<th className="text-center" colSpan={2}></th>
									<th className="text-center" colSpan={2}>Planned</th>
									<th className="text-center" colSpan={2}>Actual</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 75 }}>#</td>
									<td className="text-left" style={{ background: '#E9E9E9', minWidth: 300 }}>Focus Area</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 150}}>Total Time (incl. buffer)</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 100 }}>% Time *</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 150 }}>Actual Time</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 100 }}>% Time *</td>
								</tr>
								{this.renderFocusAreaTableRow()}
								{this.renderFASummaryRows()}
							</tbody>
						</table>
						<p className="mb-3"><span><small>* Based on your Available Time.</small></span></p>
					</div>
				: null}
			</div>
		)
	}

	renderOutcomeTable() {

		const { outcomes, showOutcomesTable } = this.state;
		let visibilityIcon = showOutcomesTable ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		return (
			<div>
				<div className="mt-2 mb-3">
					<span className="cursor-pointer mr-2" onClick={() => this.setState({showOutcomesTable: !showOutcomesTable})}><img src={visibilityIcon} width="10" /></span>	
					<span className="font-bold" style={{fontSize:17}}>Projects</span>
				</div>
				{showOutcomesTable ?
					<div className="table-responsive vertical-center">
						<table className="table table-curved">
							<thead>
								<tr>
									<th className="text-center" colSpan={4}></th>
									<th className="text-center" colSpan={2}>Planned</th>
									<th className="text-center" colSpan={2}>Actual</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 75 }}>#</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 300 }} colSpan={3}>Project</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 150}}>Total Time (incl. buffer)</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 100 }}>% Time *</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 150 }}>Actual Time</td>
									<td className="text-center" style={{ background: '#E9E9E9', minWidth: 100 }}>% Time *</td>
								</tr>
								{ !outcomes || outcomes && outcomes.length == 0 ?
									<tr>
										<td></td>
										<td></td>
										<td colSpan={2}></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								:
								<React.Fragment>
									{
										outcomes.map((outcomeObj, outcomeIndex) => {
											return this.renderOutcomeTableRow(outcomeObj, outcomeIndex);
										})
									}
								</React.Fragment>
								}
								{this.renderOutcomeSummaryRows()}
							</tbody>
						</table>
						<p className="mb-3"><span><small>* Based on your Available Time.</small></span></p>
					</div>
				: null}
			</div>
		)
	}

	renderMetricsAndTargetsReport(metricObj, uniqueID, metaInfo) {

		let metricRef = 'metric_ref_' + uniqueID;

		return(
			<tr key={uniqueID}>
				<td className="text-center"></td>
				<td className="text-center"></td>
				<td className="text-left">
					<div className="ml-3">
						<div>
							<span>{metricObj.title}</span>
							{!metricObj.description ? null : 
								<React.Fragment>
									<span className="ml-2 small" onMouseEnter={() => ReactTooltip.show(this[metricRef])} onMouseLeave={() => ReactTooltip.hide(this[metricRef])}><i className="fa fa-info-circle color-primary" aria-hidden="true"></i></span>
									<span>
										<span ref={ref => this[metricRef] = ref} data-tip={metricObj.description}></span>
										<ReactTooltip backgroundColor={'#f79569'} color={'#737373'} multiline={true} className="reactToolTipCustom" />	
									</span>
								</React.Fragment>
							}
						</div>
						{metaInfo ? metaInfo : null}
					</div>
				</td>
				<td className="text-center">{metricObj.performance}</td>
				<td className="text-center">{metricObj.YTD_performance}</td>
				<td className="text-center">{(metricObj.targetScope && metricObj.targetValue) ? metricObj.targetValue + ' (' + metricObj.targetScope + ')' : '(not set)'}</td>
			</tr>
		);
	}

	renderOutcomeMetricsAndKeyActivities(outcomeObj) {

		let tableRows = [];
		let pmUniqueID = 'o_pm_' + outcomeObj.id;
		let outcomeMetrics = outcomeObj.metricsAndTargets || [];
		let outcomeKeyActivities = outcomeObj.keyActivities || [];

		outcomeMetrics.map((metricObj) => {
			let outcomeMetricsUniqueID = pmUniqueID + '_met_' + metricObj.id;
			tableRows.push(this.renderMetricsAndTargetsReport(metricObj, outcomeMetricsUniqueID, null));
		});
		
		outcomeKeyActivities.map((activityObj) => {

			let keyActivityMetrics = activityObj.metricsAndTargets || [];

			keyActivityMetrics.map((metricObj) => {
				let kaMetricsUniqueID = pmUniqueID + '_act_' + activityObj.id + '_met_' + metricObj.id;
				let activityObjURL = <div className='small'><a href={'/key-activities/edit/' + activityObj.id + '?outcomeID=' + outcomeObj.id} target='_blank' style={SharedStyles.titleLink}>({activityObj.title})</a></div>;
				tableRows.push(this.renderMetricsAndTargetsReport(metricObj, kaMetricsUniqueID, activityObjURL));
			});
		});
		return tableRows;
	}

	renderMetricsAndTargets() {

		const { showMetricsTable, metricsAndTargets } = this.state;

		if (!metricsAndTargets || metricsAndTargets.length == 0) return null;

		let visibilityIcon = showMetricsTable ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		return(
			<div>
				<div className="mt-2 mb-3">
					<span className="cursor-pointer mr-2" onClick={() => this.setState({showMetricsTable: !showMetricsTable})}><img src={visibilityIcon} width="10" /></span>	
					<span className="font-bold" style={{fontSize:17}}>Metrics & Targets</span>
				</div>
				{showMetricsTable ?
					<div className="table-responsive vertical-center">
						<table className="table table-curved">
							<col />
							<col />
							<colgroup span="2"></colgroup>
							<col />
							<thead>
								<tr>
									<th rowSpan="2" className="text-center align-middle">#</th>
									<th rowSpan="2" className="text-center align-middle" colSpan={2}>Project</th>
									<th colSpan="2" className="text-center align-middle">Your Performance</th>
									<th rowSpan="2" className="text-center align-middle">Target</th>
								</tr>
								<tr>
									<th scope="col" className="text-center align-middle" style={{...SharedStyles.tableSubHeading, ...{background: '#E9E9E9', minWidth: 75}}}>This Week</th>
									<th scope="col" className="text-center align-middle" style={{...SharedStyles.tableSubHeading, ...{background: '#E9E9E9', minWidth: 75}}}>YTD *</th>
								</tr>
							</thead>
							<tbody>									
								{metricsAndTargets.map((outcomeObj, index) => {

									let uniqueID = 'ow_' + outcomeObj.id;
									let outcomesVisibilityIcon = this.checkMetricsSectionVisibility(uniqueID) ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";
									let metricRows = [];

									metricRows.push(
										<tr key={uniqueID}>
											<td className="text-center" style={{minWidth: 75}}>{index + 1}</td>
											<td className="text-center" style={{minWidth: 50}} onClick={() => this.toggleMetricsSectionVisibility(uniqueID)}><img src={outcomesVisibilityIcon} width="10" /></td>	
											<td className="text-left"   style={{minWidth: 300}}><a href={'/projects/edit/' + outcomeObj.id} target='_blank' style={SharedStyles.titleLink}>{outcomeObj.title}</a></td>
											<td className="text-center" style={{minWidth: 150}}></td>
											<td className="text-center" style={{minWidth: 150}}></td>
											<td className="text-center" style={{minWidth: 150}}></td>
										</tr>
									);

									if (this.checkMetricsSectionVisibility(uniqueID)) {
										metricRows.push(this.renderOutcomeMetricsAndKeyActivities(outcomeObj));
									};
									return metricRows;
								})}
							</tbody>
						</table>
						<p className="mb-3"><span><small>* Year To Date.</small></span></p>
					</div>
				: null}
			</div>
		);
	}

	renderZoomControl() {
		
		const { zoomValue, showTable } = this.state;
		
		if (!showTable) return <div style={{minWidth:200}} />;

		return (
			<div className="d-flex align-items-center">
				<div className="d-flex align-content-start">
					<span style={SharedStyles.zoomTitle}>Zoom (%)</span>
					<button type="button" onClick={this.handleZoomChange.bind(this, 'zoomIn', null)} className="btn btn-primary btn-sm border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>-</button>
					<input type="number" value={zoomValue} onChange={(e) => this.handleZoomChange(null, e.target.value)} style={SharedStyles.zoomBoxStyle} />
					<button type="button" onClick={this.handleZoomChange.bind(this, 'zoomOut', null)} className="btn btn-primary btn-sm border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>+</button>
				</div>
			</div>
		);
	}

	renderHeaderToolBar() {

		const { startWeekDt, endWeekDt } = this.state;
		let dateRange = startWeekDt.format('MMM DD') + " - " + endWeekDt.format('MMM DD');
		let isCurrentWeek = moment().isoWeek() == moment(startWeekDt).isoWeek() ? true : false;

		return (
			<div>
				<div className={"d-flex flex-wrap justify-content-between align-items-center"}>
					<div className="d-flex align-items-center mb-4" style={{minWidth:200}}>
						<Button onClick={(e) =>{this.handlerToNavigate(e, 'TODAY')}} className={"todays-btn"} disabled={isCurrentWeek}>Current Week</Button>
					</div>
					<div className="d-flex align-items-center mb-4">
						<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'PREV')}} className="btn btn-primary btn-sm border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
						<span className="week-label" > {dateRange} </span>
						<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'NEXT')}} className="btn btn-primary btn-sm border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}><i className="fa fa-angle-right" aria-hidden="true"></i></button>
					</div>
					<div className="mb-4">
						{this.renderZoomControl()}
					</div>
				</div>
			</div>
		);
	}

	render() {

		const { apiResponse, showTable, totalAvailableTimeInMinutes, zoomValue } = this.state;
		const tableStyle = {
			transform: 'scale(' + (zoomValue/100) + ')',
			transformOrigin: 'top left'
		};

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<NavLink to="/tracker-report">Tracker Report</NavLink>&nbsp;
				</div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">

								{this.renderHeaderToolBar()}

								{ !_.isEmpty(apiResponse) ?
									<div className={"text-center " + apiResponse.className}><span>{apiResponse.message}</span></div>
								: null }

								<div className="text-center">
									{ totalAvailableTimeInMinutes? <div style={{fontFamily:'Pangram-Bold'}} className="mb-3"> Total Available Time: {Util.convertTimeToReadableText(totalAvailableTimeInMinutes)} </div> : null }
								</div>

								{showTable ?
									<div style={tableStyle}>
										{this.renderFocusAreaTable()}
										{this.renderOutcomeTable()}
										{this.renderMetricsAndTargets()}
									</div>
								: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TrackerReport.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	TrackerReport
);

const styles = {

	activitiesRow: {
		color: '#737373', 
		fontFamily: 'Pangram-Light'
	},
	metricsWrapper: {
		border: '1px solid #e9e9e9',
		borderRadius: 5,
		overflow: 'hidden'
	},
	metricsHeading: {
		backgroundColor: '#f6f6f6',
		padding: 10
	}
}