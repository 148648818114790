export default class IntervalOptions {

    static ordinal_suffix_of(i) {
        
        var j = i % 10;
        var k = i % 100;
        
        if (j == 1 && k != 11) {
            return i + "st";
        }
        else if (j == 2 && k != 12) {
            return i + "nd";
        }
        else if (j == 3 && k != 13) {
            return i + "rd";
        }
        else {
            return i + "th";
        };
    }

    static getISOWeekDays() {

        return([
            {label: 'Monday', value: 1},
            {label: 'Tuesday', value: 2},
            {label: 'Wednesday', value: 3},
            {label: 'Thursday', value: 4},
            {label: 'Friday', value: 5},
            {label: 'Saturday', value: 6},
            {label: 'Sunday', value: 7},
        ]);
    };

    static getWeekDays() {

        return([
            {label: 'Monday', value: 0},
            {label: 'Tuesday', value: 1},
            {label: 'Wednesday', value: 2},
            {label: 'Thursday', value: 3},
            {label: 'Friday', value: 4},
            {label: 'Saturday', value: 5},
            {label: 'Sunday', value: 6},
        ]);
    };

    static getMonthsInAYear() {

        return([
            {label: 'January', value: 0},
            {label: 'February', value: 1},
            {label: 'March', value: 2},
            {label: 'April', value: 3},
            {label: 'May', value: 4},
            {label: 'June', value: 5},
            {label: 'July', value: 6},
            {label: 'August', value: 7},
            {label: 'September', value: 8},
            {label: 'October', value: 9},
            {label: 'November', value: 10},
            {label: 'December', value: 11},
        ]);
    }

    static getNumericOptions(startCounter = 1, endCounter = 100) {

        let numericOptions = [];

        for (var i = startCounter; i <= endCounter; i++) {
            numericOptions.push({label: i, value: i});
        };
        return numericOptions;
    }

    static getDayIntervalNumericOptions(interval) {

        let dayIntervalNumericOptions = [];
        let maxIterationLimit = 0;
        let startIterationFrom = 1;

        if (interval == 'monthly' || interval == 'every_x_months') maxIterationLimit = 32;
        else if (interval == 'quarterly') maxIterationLimit = 92;
        else if (interval == 'annually') maxIterationLimit = 31; // Since we ask the users to select the month as well, the date can't exceed 31
        else if (interval == 'every_x_days') {
            maxIterationLimit = 15; startIterationFrom = 2
        };

        for (var i = startIterationFrom; i <= maxIterationLimit; i++) {

            if ((interval == 'monthly' || interval == 'every_x_months') && i == 32) {
                dayIntervalNumericOptions.push({label: 'Last day of Month', value: i});
            }
            else {
                dayIntervalNumericOptions.push({label: i, value: i});
            };
        };
        return dayIntervalNumericOptions;
    }

    static getWeekOptions(interval) {

        let weekOptions = [];
        let maxIterationLimit = 0;

        if (interval == 'monthly' || interval == 'every_x_months') maxIterationLimit = 5;
        else if (interval == 'quarterly') maxIterationLimit = 14;
        else if (interval == 'annually') maxIterationLimit = 53;

        for (var i = 1; i <= maxIterationLimit; i++) {
            weekOptions.push({label: this.ordinal_suffix_of(i), value: i});
        };

        if (interval == 'monthly' || interval == 'every_x_months') {
            weekOptions[(weekOptions.length - 1)].label += ' [OR] last';
        };
        return weekOptions;
    }
}