import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Dashboard extends Component {

    componentDidMount() {
		
	}

    render() {

        const { user } = this.props.auth;

        return (
            <div className="page-content container child-container">
                <ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
                <div className="row bread-crumb">
                    <div className="col-md-12">
                        <h5>Dashboard</h5>
                        <p>Welcome { user.name }</p>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {  })(
    Dashboard
);
