import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, ButtonGroup } from 'reactstrap';
import { Link } from "react-router-dom";
import moment from "moment";
import Toolbar from 'react-big-calendar/lib/Toolbar';
import GCFilterOptions from '../common/GCFilterOptions.js';

class CustomizedToolbar extends Toolbar {
	
	handlerToNavigate = (e, navigateTo) => {

		e.preventDefault()
		const { date } = this.props;

		let startWeekDt = moment().startOf('isoWeek');
		let endWeekDt = moment().endOf('isoWeek');

		if (navigateTo === 'PREV'){
			startWeekDt = moment(date).subtract(1, 'weeks').startOf('isoWeek');
			endWeekDt = moment(date).subtract(1, 'weeks').endOf('isoWeek');
		}
		else if(navigateTo === 'NEXT') {
			startWeekDt = moment(date).add(1, 'weeks').startOf('isoWeek');
			endWeekDt = moment(date).add(1, 'weeks').endOf('isoWeek');
		};

		if (this.props.hasUnsavedChanges === true) {

			var userConfirmation = window.confirm('You have unsaved changes, are you sure you want to leave?');

			if (userConfirmation === true) {
				// User agrees to discard their unsaved changes. Hence reset this boolean flag.
				this.props.loadTrackerEvents(startWeekDt, endWeekDt);
				this.navigate(navigateTo); // navigate calendar
			}
			else return false;
		}
		else {
			this.props.loadTrackerEvents(startWeekDt,endWeekDt);
			this.navigate(navigateTo); // navigate calendar
		};
	}

	handleViewChange(newView) {

		this.props.onView(newView);
		if (newView == 'day') {
			this.props.onNavigate('DATE', this.props.date);
		};
	}

	renderGCFilterOptions = () => {

		const { userPreference, GCMetaData, excludedGoogleCalendarIDs } = this.props;

		return (
			<span style={{marginRight: 15}}>
				<GCFilterOptions
					GCMetaData={GCMetaData}
					userPreference={userPreference} 
					excludedGoogleCalendarIDs={excludedGoogleCalendarIDs}
					onUpdate={this.props.updateUserPreferencesInServer}
				/>
			</span>
		)
	}
	
	render() {

		let activeClass = "pbh-small-btn-active";
		let defaultClass = "weekly-plan-day-btn";

		let isCurrentWeek = moment().isoWeek() == moment(this.props.date).isoWeek() ? true : false;
		let adHocTimeTrackingIsOn = this.props.adHocTimeTrackingIsOn;

		return (
			<div>
				<div className={"d-flex flex-wrap justify-content-between mb-4"} style={{gap:10}}>
					<div>
						<Button onClick={(e) =>{this.handlerToNavigate(e, 'TODAY')}} className={"todays-btn"} disabled={isCurrentWeek}>Current Week</Button>
						<ButtonGroup style={{marginLeft: 15}}>
							<Button onClick={() => this.handleViewChange('week')} className={(this.props.view == 'week'? activeClass: defaultClass)}>Week</Button>
							<Button onClick={() => this.handleViewChange('day')} className={(this.props.view == 'day'? activeClass: defaultClass)}>Day</Button>
						</ButtonGroup>
						{
							/* Show start tracking button only if its current week */
							isCurrentWeek ? 
								!adHocTimeTrackingIsOn?
										<Button style={{marginLeft: 15}} onClick={(e) =>{this.props.toggleTrackingPopup()}} className={"todays-btn"} >Start Tracker</Button> : 
										<Button color="danger" style={{marginLeft: 15}} onClick={(e) =>{this.props.toggleTrackingPopup()}} className={"todays-btn btn-sm"} >Stop Tracker</Button>
								: null
						}
					</div>
					
					<div className="d-flex align-items-center justify-content-center ml-3 mr-3" style={{flex:1, minWidth: 180}}>
						{this.props.view == 'week' ?
							<div className="d-flex align-items-center">
								<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'PREV')}} className="btn btn-primary btn-sm border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
								<span className="week-label" > {this.props.label} </span>
								<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'NEXT')}} className="btn btn-primary btn-sm border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}><i className="fa fa-angle-right" aria-hidden="true"></i></button>
							</div>
						: null}
					</div>

					<div className="text-right">
						{this.renderGCFilterOptions()}
						<Button onClick={this.props.reset} className={activeClass} disabled={this.props.disableResetButton} style={{marginRight: 15}}><i className="fa fa-undo" aria-hidden="true"></i>  Reset</Button>
						<Button onClick={() => this.props.handleSaveChanges()} className={"pbh-small-btn-active"} disabled={this.props.disableSaveButton}>Save Changes</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default CustomizedToolbar;
