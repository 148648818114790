import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 text-sm-left text-center">
                            <div>© 2016-2024 RCCI Group</div>
                        </div>
                        <div className="col-sm-8 text-sm-right text-center">
                            <div>
                                <span style={{marginRight:"15px"}}><a target='_blank' href='https://www.productivitybyhabit.com/terms-of-use/'>Terms of Use</a></span>
                                <span><a target='_blank' href='https://www.productivitybyhabit.com/privacy-policy/'>Privacy Policy</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}


export default Footer;