import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import UTaskForm from "./UTaskForm";
import { history } from '../../utils/history';
import { isNumber } from "lodash";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AddUTask extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			itemPosition: null
		};
	}

	componentDidMount() {

		if (this.props.itemPosition) {

			const itemPosition = this.props.itemPosition;
			
			if (itemPosition && isNumber(itemPosition)) {
				this.setState({itemPosition: itemPosition});
			}
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Task submit
	handleTaskSubmit(data) {

		var postObj = data;
		postObj.universalItemPosition = this.state.itemPosition;
		postObj.refItem = this.props.refItem;
		postObj.relativePosition = this.props.relativePosition;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.addNewTask, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					postObj.id = response.data.data.id;
					this.props.addTaskInList(this.state.itemPosition, postObj);
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		return (
			<div>
				<div className="section-container">
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								<UTaskForm
									reloadData={this.props.reloadData}
									itemPosition={this.state.itemPosition}
									onSubmit={this.handleTaskSubmit.bind(this)}
									formApiResponse={this.state.formApiResponse} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AddUTask.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	AddUTask
);
