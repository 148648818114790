import {
	RESET_COLLABORATOR,
	GET_COLLABORATOR,
	GET_COLLABORATORS,
	DELETE_COLLABORATOR,
} from "../actions/types.js";

const initialState = {
	collaborators: [],
	collaborator: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_COLLABORATOR:
			return {
				...initialState,
			}

		case GET_COLLABORATOR:
			return {
				...state,
				collaborator: action.payload,
			};

		case GET_COLLABORATORS:
			return {
				...state,
				collaborators: action.payload,
			};

		default:
			return state;
	}
}
