import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Prompt } from 'react-router';
import { NavLink } from 'react-router-dom';
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Collapse } from 'reactstrap';
import { Badge, Button, ButtonGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';

import { setLoading } from "../../actions/loadingActions.js";
import { getUserOutcomesWithPopulatedData } from "../../actions/outcomeActions";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';

// Left Section
import YourCommitments from './YourCommitments';
import DragableTimeBlocks from './DragableTimeBlocks';
import UnplannedTimeBlocks from './UnplannedTimeBlocks';
import WeeklyNotes from './WeeklyNotes';
import Tasks from './Tasks';
// End of Left Section

import Calendar from './Calendar';
import TrackerCalendarToolBar from './TrackerCalendarToolBar';
import DayColumnHeader from './DayColumnHeader';
import DetailedInfoPopup from './DetailedInfoPopup';
import CustomEvent from './CustomEvent';
import DailyNotes from './DailyNotes';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class WeeklyTracker extends Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			timeBlocks: [],
			unplannedTimeBlocks: [],
			outcomeMetrics: null,
			dailyTasks: null,
			apiResponse: {
				className: "",
				message: "",
			},
			trackerID: null,
			buttonDisabled: false,
			focusAreas: [],
			outcomesWithPopulatedData: [],
			utasks: [],
			hasUnsavedChanges: false,
			showDetailedInfoModal: false,
			showResetModal: false,
			startTrackingBtnClicked: false,
			adHocTimeTrackingIsOn: false,
			currentTrackingData: { state : 'stopped' },
			weeklyNotesChildSubmitCalled: false,
			dailyNotesChildSubmitCalled: false,
			currentView: 'week',
			playSound: false,
			coreData: Util.getUser(),
			hasWeeklyPlan: null,
			userPreference: { // default preferences
				hasEnabledCalendarSync: false,
				selectedCalendar: null,
			},
			excludedGoogleCalendarIDs: [],
			activeLeftPaneSection: 'commitments'
		};

		this.scrollToTime = new Date();
		this.scrollToTime.setHours(8, 0, 0);
		this.getUserPreferences();
		this.loadTrackerEventsForTheWeek();

		this.getFocusAreas();
		this.getOutcomesWithPopulatedData();
		this.getUTasks();

		this.audio = new Audio("/sounds/message_tone.mp3");
	}

	componentDidMount() {
		
		this.audio.addEventListener('ended', () => this.setState({ playSound: false }));
		this.setStickyToolBar();
		
		window.addEventListener('focus', this.onFocus);
		window.addEventListener('beforeunload', this.alertUserAboutUnsavedChanges);
		document.addEventListener('mouseleave', this.alertUserAboutUnsavedChanges);
		document.addEventListener('dragstart', () => this.isDragging = true);
		document.addEventListener('dragend', () => this.isDragging = false);
		document.addEventListener('drop', () => this.isDragging = false);

		let userSubscriptions = this.props.usersubscriptions && this.props.usersubscriptions.usersubscriptions;

		// Typical usage (don't forget to compare props):
		if (!this.state.calendarSubscriptionChecked && userSubscriptions && userSubscriptions.length > 0) {

			let calendarSubscribed = false;

			let calendarSubscriptionModuleObj = _.find(userSubscriptions, { 'value': "third_party_integrations", 'isSubscribed': true });

			if (calendarSubscriptionModuleObj) {
				calendarSubscribed = true;
			};

			this.setState({
				calendarSubscribed: calendarSubscribed,
				calendarSubscriptionChecked: true,
			});
		};

		this.autoSaveInterval = setInterval(() => {

			// Let's auto-save unsaved user responses every 10 seconds.
			if (this.state.hasUnsavedChanges === true && !this.state.adHocTimeTrackingIsOn && !this.state.buttonDisabled && !this.isDragging) {
				this.updateTracker();
			};
		}, 10000);
	}
	
	componentWillUnmount() {
		this.audio.removeEventListener('ended', () => this.setState({ playSound: false })); 
		window.removeEventListener('focus', this.onFocus);
		window.removeEventListener('beforeunload', this.alertUserAboutUnsavedChanges);
		document.removeEventListener('mouseleave', this.alertUserAboutUnsavedChanges);
		document.removeEventListener('dragstart', () => console.log('dragstart listener has been removed'));
		document.removeEventListener('dragend', () => console.log('dragend listener has been removed'));
		document.removeEventListener('drop', () => console.log('drop listener has been removed'));
		clearInterval(this.autoSaveInterval);
	}

	onFocus = () => {
		return false; // Temporarily disable auto-refresh feature.
		// Refresh on browser tab focus
		const { targetWeekStart = null, targetWeekEnd = null} = this.state;
		if (this.state.showCustomAlert) setTimeout(() => {this.toggleCustomAlert();}, 100);
		if (targetWeekStart) this.loadTrackerEventsForTheWeek(targetWeekStart, targetWeekEnd);
	}

	alertUserAboutUnsavedChanges = (event) => {
		
		if (this.state.hasUnsavedChanges === true) {

			var userConfirmation = window.confirm('You have unsaved changes, are you sure you want to leave?');

			if (userConfirmation === true) {
				// User agrees to discard their unsaved changes. Hence reset this boolean flag.
				this.setState({hasUnsavedChanges: false});
			}
			else {
				const e = event || window.event;
				e.preventDefault();
				if (e) {
					e.returnValue = '';
				};
				return '';
			};
		};
	}

	setStickyToolBar() {

		const header = document.getElementById("tracker-toolbar");

		window.addEventListener("scroll", () => {
			
			if (!header) return;

			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			this.toggleCustomAlert(message, hasError);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUTasks(showLoader) {

		if (showLoader) this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUTasks, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					
					this.setState({
						utasks: response.data.data
					}); 
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			};

			if (showLoader) this.props.setLoading(false);
		});
	}

	updateUTasks = (updatedUTasks) => {
		this.setState({utasks: updatedUTasks});
	}

	// get User Preferences
	getUserPreferences() {

		API_Services.httpGET(UrlConstants.URLS.getUserPreferences, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

          let data = response.data.data;
          let metaData = response.data.metaData;

					if (metaData && metaData.message) {
						alert(metaData.message);
					};
					
					this.setState({
						userPreference: data,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || [],
						GCMetaData: metaData
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
		});
	}

	// Update events
	updateEvents = (events) => {
		
		let {unplannedTimeBlocks} = this.state;

		//When an event is created using task, we need to add them under the UNPLANNED section.
		let newlyUpdatedTaskEvents = _.filter(events, (item) => item.isEventUpdated === true && item.isCreatedFromTheTask === true);

		_.map(newlyUpdatedTaskEvents, (item) => {

			let position = _.findIndex(unplannedTimeBlocks, {timeBlockID: item.timeBlockID});

			if (position > -1) {
				unplannedTimeBlocks.splice(position, 1, item);
			}
			else {
				unplannedTimeBlocks.push(item);
			};
		});

		this.setState({
			events: events,
			unplannedTimeBlocks: unplannedTimeBlocks,
			hasUnsavedChanges: true
		}, 
		() => {
			// we are calling child components submit from parent. Since events are updated filter events based on event update
			if (this.yourCommitmentsChild) this.yourCommitmentsChild.filterEvents();
			if (this.unplannedTimeBlocksChild) this.unplannedTimeBlocksChild.filterEvents();
		});
	}

	// Toggle Model Popup
	toggleDetailedInfoModal = (showMore = false, detailedInfo = null, reloadOutcomesData = false) => {

		if (this.state.showDetailedInfoModal && reloadOutcomesData) {
			this.getOutcomesWithPopulatedData(); // Reload the Outcomes and KA
		};

		this.setState({
			detailedInfo: !this.state.showDetailedInfoModal ? detailedInfo : null,
			showMoreInfo: showMore,
			showDetailedInfoModal: !this.state.showDetailedInfoModal,
		});
	}

	// Get Weekly-Plan for the user
	getTrackerForTheWeek(postObj) {

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.getTrackerForTheWeek, postObj, (err, response) => {

			if (err) {

				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				}
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			}
			else if (response && response.data) {


				if (response.data) {

					let data = response.data.data;

					if (data) {
						this.constructTrackerData(data);
					} else {

						// reset weekly & daily notes
						this.setState({
							weeklyNotes: null,
							dailyNotes: null,
							hasWeeklyPlan: false,
						});
						this.props.setLoading(false);
					}
				}
				else {
					// this.setApiResponse(response.data.message, false);
					this.props.setLoading(false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};

			// update the state
			this.setState({
				targetWeekStart: postObj.targetWeekStart,
				targetWeekEnd: postObj.targetWeekEnd,
				clientTimeZone: postObj.clientTimeZone
			})

			//this.props.setLoading(false);
		});
	}

	// Toggle Tracking Popup

	toggleTrackingPopup = () => {

		if (this.state.adHocTimeTrackingIsOn) {

			const { events, currentTrackingData } = this.state;

			if (!events || !currentTrackingData || !currentTrackingData.event) {
				return
			}

			const idx = _.findIndex(events, ['eventID', currentTrackingData.event.eventID]);

			if (idx > -1) {
				this.toggleDetailedInfoModal(true, events[idx])
			}
		}

		else {

			this.setState({
				startTrackingBtnClicked: !this.state.startTrackingBtnClicked
			});
		}
	}

	updateCurrentAdHocTimeTrackerEvent = () => {

		/**
		 * This will update the current tracking event in real time in tracker. 
		 */
		const { events, currentTrackingData } = this.state;

		if (!events || !currentTrackingData || !currentTrackingData.event || !this.state.adHocTimeTrackingIsOn) {
			return
		}

		const idx = _.findIndex(events, ['eventID', currentTrackingData.event.eventID]);

		if (idx > -1) {

			if (!events[idx].durationInMinutes) {
				events[idx].end = new Date(); // set current date time
			}

			else if(new Date().getTime() >= events[idx].end.getTime()) {
				events[idx].end = new Date();
			}

			if (events[idx].start.getDay() != events[idx].end.getDay() && events[idx].end.getHours() >= 0 && events[idx].end.getMinutes() >= 0) {
				events[idx].end.setDate(events[idx].start.getDate()); // Hot fix
				events[idx].end.setHours(23, 59, 0); // Hot fix
			}

			this.setState({
				events: events,
				hasUnsavedChanges: true
			});
		}
	}

	// Set Ad-Hoc Time Tracking Data

	setAdHocTimeTracker = (adHocCurrentTrackingEvent, state, adHocTimeTrackingIsOn) => {

		this.setState({
			currentTrackingData: {
				event: adHocCurrentTrackingEvent,
				state: state
			},
			adHocTimeTrackingIsOn: adHocTimeTrackingIsOn,
			hasUnsavedChanges: true
		},() => {
			this.turnOnNotification(adHocCurrentTrackingEvent);
		})

	}

	constructTrackerData = (data) => {
		if (data) {

			this.props.setLoading(true);

			let targetWeek =  !data || !data.fromDate ? moment().isoWeek() :  moment(data.fromDate).isoWeek();
			let isCurrentWeek = moment().isoWeek() === targetWeek ? true : false;
			let currentDate = moment();

			for (let i = 0; i < data.events.length; i++) {

				data.events[i].start = new Date(data.events[i].start);
				data.events[i].end = new Date(data.events[i].end);

				if(!data.events[i].eventID) {
					/*
					Set eventID for events doesn't have the key,
					For the first time eventID won't be available because events are cloned from the weekly plan
					*/
					data.events[i].eventID = this.state.coreData.userCoreProperties.userID + "-" + uuidV4() + "-" + Date.now();
				}
				if (data.events[i].notificatioScheduledAt) {
					data.events[i].notificatioScheduledAt = new Date(data.events[i].notificatioScheduledAt);
				}

				if (data.events[i].isAdHocTimeTrackerUnPlannedEvent) {

					if (isCurrentWeek && currentDate.isSame(moment(data.events[i].start).format(), "day")) {

						// If its current week and the start date is also an current day then set the AdHocTracking info in state.
						if (!data.events[i].stopAdHocTimeTracker) {

							if (!data.events[i].durationInMinutes) {
								data.events[i].end = new Date(); // set current date time
							}
							else if(new Date().getTime() >= data.events[i].end.getTime()) {
								data.events[i].end = new Date();
							}

							if (data.events[i].start.getDay() != data.events[i].end.getDay() && data.events[i].getHours() == 0 && data.events[i].end.getMinutes() == 0) {
								data.events[i].end.setDate(data.events[i].start.getDate()); // Hot fix
								data.events[i].end.setHours(23, 59, 0); // Hot fix
							}
		
							this.setAdHocTimeTracker(data.events[i], 'tracking', true);
						}
					}

					else {

						if (!data.events[i].stopAdHocTimeTracker) {

							/**
							 * If the user has not stopped the Time Tracker in the same day then the below action will be taken.
							 * 
							 * Right now the event can not set the end date as next day, It will not render properly If the start date and end date is different.
							 * Due to the constraint in the calendar, we can track the event only till current end of the day.
							 * 
							 * So below code will automaticaly stop the tracking and set the end date as corrensponding end of the day of startDate 
							 * 
							 * Note: Only for Ad-Hoc Time Tracker Events
							 */

							const endDate = moment(data.events[i].start).endOf('day').toDate();
							const duration = moment(endDate).diff(moment(data.events[i].start), 'minutes'); // Calculate Duration
							data.events[i].end = endDate;
							data.events[i].isCurrentTrackingEvent = false;
							data.events[i].stopAdHocTimeTracker = true;
							data.events[i].adHocTimeTrackerState = 'stopped-by-sytem';
							data.events[i].adHocTimeTrackerStopedAt = new Date();
							data.events[i].durationInMinutes = duration;

						}
					}
				}

				if (data.events[i].description) { // It contains html content from GC.

					/* Remove default copy from description. */
					let defaultCopy = '\n ~~~ PLEASE USE \"PRODUCTIVITY BY HABIT\" APP TO EDIT THIS DESCRIPTION ~~~ \n';
					if (data.events[i].description.includes(defaultCopy)) {
						data.events[i].description = data.events[i].description.replace(defaultCopy, '');
					};

					let trackingURLRegEx = /(tracker\/d\/?)(\??)(date=?)\d{1,2}-\d{1,2}-\d{4}/g;
					if (trackingURLRegEx.test(data.events[i].description)) {
						// If tracking url exist, assign event date
						let eventDate = moment(data.events[i].start).format('MM-DD-YYYY');
						data.events[i].description = data.events[i].description.replace(trackingURLRegEx, ('tracker/d/?date=' + eventDate));
					};

					/* Convert the html content to raw content. */
					data.events[i].notes = Util.convertHtmlToRawDraftJS(data.events[i].description);
					delete data.events[i].description;
				}

			}

			console.log("Tracker Data...", data);
			console.log("Tracker ID...", data.id);
			console.log('data.weeklyNotes..', data.weeklyNotes);
			console.log('data.commitments..', data.commitments);
			console.log('data.unplannedEvents..', data.unplannedEvents);

			if (data.unplannedEvents && data.unplannedEvents.length > 0) {

				for (let i = 0; i < data.unplannedEvents.length; i++) {
					data.unplannedEvents[i].start = new Date(data.unplannedEvents[i].start);
					data.unplannedEvents[i].end = new Date(data.unplannedEvents[i].end);
				}
			}

			if (data.commitments && data.commitments.length > 0) {

				for (let i = 0; i < data.commitments.length; i++) {
					data.commitments[i].start = new Date(data.commitments[i].start);
					data.commitments[i].end = new Date(data.commitments[i].end);
				}

				this.setState({
					timeBlocks: data.commitments,
				});
			}
			else {
				// data.commitments = this.allLatesCommitments;
			}

			this.setState({
				trackerID: data.id ? data.id : null,
				events: data.events,
				unplannedTimeBlocks: data.unplannedEvents,
				weeklyNotes: data.weeklyNotes,
				commitments: data.commitments || [],
				dailyNotes: data.dailyNotes,
				outcomeMetrics: data.outcomeMetrics,
				dailyTasks: data.dailyTasks,
				hasWeeklyPlan: true,
			}, ()=> {
				this.props.setLoading(false);
			});
		}
		this.props.setLoading(false);
	}

	// Get outcomes with populated data
	// Sample response format
	/**
	 * [
	 * 	{
* 			abbreviation: "o1 abb",
			bufferInPercentage: 10,
			collaborators: [],
			createdAt: 1612535512156,
			creationMaintenance: "",
			focusArea: {
				createdAt: 1612780319320,
				updatedAt: 1612872117671,
				id: "6021131fff2e963190b45ec2",
				focusAreaTitle: "FA title 1",
				responses: Array(2), 
				…
			},
			id: "601d56d82213e12f04dd5003",
			includeInWeeklyPlan: true,
			keyActivities: [
				{
					createdAt: 1612679038847
					id: "601f877e01ff442b0c1e0f01"
					includeInWeeklyPlan: true
					notes: null
					outcome: "601d56d82213e12f04dd5003"
					tasks: [{…}, {…}, {…}, {…}]
					tasksSequence: ["6020dab127247b2114f2a5e8", "6020daa127247b2114f2a5e7", "6020e27c27247b2114f2a5e9"]
					timeBlocks: [
						{
							createdAt: 1612867059201,
							durationInMinutes: 20,
							id: "602265f3c10f7325f48a86cf",
							keyActivity: "601f877e01ff442b0c1e0f01",
							sessionsPerWeek: 1,
							title: "o1 k1 tb1 title",
							type: "Other",
							updatedAt: 1613558292401,
							userID: "120e4068-cc84-2d61-412d-7710c835514e"
						}
					],
					title: "o1 k1 title",
					updatedAt: 1613404445418,
					userID: "120e4068-cc84-2d61-412d-7710c835514e",
				}
			],	
			keyActivitiesSequence: ["601f877e01ff442b0c1e0f01", "601f894a01ff442b0c1e0f05", "60229c773332d418804eed72"],
			notes: null,
			outcome: "o1",
			outcomeTitle: "o1 title",
			rank: 1,
			rolesAndResponsibilities: [{…}],
			successDefinition: "",
			updatedAt: 1613557905915,
			userID: "120e4068-cc84-2d61-412d-7710c835514e",
	 *  }
	 * ]
	 */
	getOutcomesWithPopulatedData() {

		// this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						outcomesWithPopulatedData: response.data.data,
					});

					this.props.getUserOutcomesWithPopulatedData(response);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			// this.props.setLoading(false);
		});
	}

	// Get focus areas for the user
	getFocusAreas() {

		// this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getFocusAreasForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setState({
						focusAreas: response.data.data,
					});
				} else {
					this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			// this.props.setLoading(false);
		});
	}

	handleSaveChanges() {

		// e.preventDefault();

		if (!this.state.trackerID && this.state.events.length == 0) {
			this.setApiResponse("Schedule some events and try again", false);
			return this.props.setLoading(false);
		}
		else {
			this.props.setLoading(true);
			// we are calling child components submit from parent
			//this.weeklyNotesChild.handleSubmit();
			//this.dailyNotesChild.handleSubmit();
			this.updateTracker();
		}

	}

	handleWeeklyNoteSaveChanges(notesContent) {

		this.setState({
			weeklyNotes: notesContent,
			weeklyNotesChildSubmitCalled: true,
		});
	}

	handleDailyNoteSaveChanges(notesContent) {

		this.setState({
			dailyNotes: notesContent,
			dailyNotesChildSubmitCalled: true,
		});
	}

	resetBtnOnClickHandler = (e) => {
		e.preventDefault();
		this.toggleResetWarningPopup();
	}

	/* Toggle Popup */

	toggleResetWarningPopup = () => {
		this.setState({
			showResetModal: !this.state.showResetModal,
		});
	}

	loadTrackerEventsForTheWeek = (targetWeekStart = null, targetWeekEnd = null, twoWaySyncRequired = true) => {

		this.setState({hasUnsavedChanges: false}); // Tracker events for the week will be loaded either if there are no unsaved changes or if the user accepts the risk of losing data by confirming the alert.

		let clientTimeZone = momentTimezone.tz.guess();
		let postObj = {};

		if (!targetWeekStart || !targetWeekEnd) {

			// initial state
			this.setState({
				targetWeekStart: moment().startOf('isoWeek').format(),
				targetWeekEnd: moment().endOf('isoWeek').format(),
			});

			postObj = {
				targetWeekStart: moment().startOf('isoWeek').format(),
				targetWeekEnd: moment().endOf('isoWeek').format(),
				clientTimeZone: clientTimeZone
			};
		}
		else {

			this.setState({
				targetWeekStart: targetWeekStart,
				targetWeekEnd: targetWeekEnd,
			});

			postObj = {
				targetWeekStart: targetWeekStart,
				targetWeekEnd: targetWeekEnd,
				clientTimeZone: clientTimeZone
			};
		};

		postObj.twoWaySyncRequired = twoWaySyncRequired;
		this.getTrackerForTheWeek(postObj);
	}

	// Handler for update event in Tracker
	updateTracker = (saveOptions = null) => {

		this.props.setLoading(true);
		this.toggleCustomAlert('Saving...', true, null); // This notification shouldn't be closed automatically

		this.setState({
			buttonDisabled: true,
		});

		let allSetToUpdate = true;

		// we are calling child components submit from parent. So before setting state for daily and weekly notes this function is executed. So do a condition check for every 1 sec and if it passes clear the interval
		let updateCheckInterval = setInterval(() => {

			if (allSetToUpdate) {

				clearInterval(updateCheckInterval);

				allSetToUpdate = false;

				/*this.setState({
					weeklyNotesChildSubmitCalled: false,
					dailyNotesChildSubmitCalled: false,
				});*/

				/* Construct Commitments */
				let newCommitments = this.state.commitments;

				if (newCommitments.length == 0) {
					newCommitments = this.state.allLatesCommitments; // save all TB for first time in commitments
				} 
				else {
					for (let i = 0; i < this.state.events.length; i++) {

						const element = this.state.events[i];

						if (!element.isUnplanned) { // consider only planned event

							var timeBlock = _.find(newCommitments, { timeBlockID: element.timeBlockID });
							// save TB to commitments
							if (!timeBlock) {
								let data = _.pick(element, ['id', 'title', 'start', 'end', 'type', 'sessionsPerWeek', 'durationInMinutes', 'focusAreaID', 'focusAreaTitle', 'outcomeID', 'outcomeAbbr', 'outcomeTitle', 'keyActivityID', 'keyActivityTitle', 'timeBlockID', 'timeBlockTitle']);
								newCommitments.push(data);
							}
						}
					}
				}

				let defaultSaveOptions = {
					syncWith3rdPartyCalendar : true
				};

				let isEventUpdated = false;
				const { events, updatedEvent } = this.state;

				var updatedEvents = events.filter(event => {

					// set isEventUpdated to true, If any of the event is updated [OR] created [OR] removed
					if (!this.state.trackerID || event.isEventUpdated || event.isRemovedFromThePlan || event.isThirdPartySyncRequired){ 
						isEventUpdated = true;
						event.isThirdPartySyncRequired = true;
					}
					return event
				});

				var postObj = {
					events: updatedEvents,
					unplannedEvents: this.state.unplannedTimeBlocks || [],
					targetWeekStart: this.state.targetWeekStart,
					targetWeekEnd: this.state.targetWeekEnd,
					clientTimeZone: this.state.clientTimeZone,
					commitments: newCommitments,
					weeklyNotes: this.state.weeklyNotes,
					dailyNotes: this.state.dailyNotes,
					outcomeMetrics: this.state.outcomeMetrics,
					dailyTasks: this.state.dailyTasks,
					isEventUpdated: isEventUpdated,
					updatedEvent: updatedEvent,
					saveOptions: saveOptions ? saveOptions : defaultSaveOptions
				};

				API_Services.httpPOST(UrlConstants.URLS.updateOrCreateTrackerForTheWeek, postObj, (err, response) => { // update tracker schedule

					this.toggleCustomAlert(); // Hide "Saving..." notification.

					if (err || !response || !response.data) {

						if (err.response && err.response.data) {
							this.setApiResponse(err.response.data, true);
						} else {
							this.setApiResponse("Something went wrong!", true);
						}
						console.log('err...', err);

					} else if (response.data) {

						let data = response.data.data;
						// console.log('response...', response);

						if (data) {
							this.setState({ 
								trackerID: data.id,
								hasUnsavedChanges: false,
								updatedEvent: null
							},
							() => {
								this.constructTrackerData(data);
								this.getUTasks(); // Refresh the calendarSchedules list of the tasks.
								this.setApiResponse("Record have been saved successfully!", false);
							});

							if (response.data.status == false && response.data.message) {
								this.toggleCustomAlert(response.data.message, true);
							};
						}
						else this.setApiResponse("Something went wrong!", true);
					}

					this.setState({
						buttonDisabled: false,
					});

					this.props.setLoading(false);
				});
			}
		}, 1000);

	}

	// Reset Tracker, reset events, user need to manually save changes
	resetTrackerEvents = (e, resetItem) => {

		e.preventDefault();

		this.toggleResetWarningPopup();

		if (!this.state.trackerID || !resetItem) {
			return;
		}

		// Prevent SAVE action during RESET operation.
		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		var postObj = {
			resetItem: resetItem,
			clientTimeZone: this.state.clientTimeZone,
			recordID: this.state.trackerID
		};

		API_Services.httpPOST(UrlConstants.URLS.resetTrackerForTheWeek, postObj, (err, response) => { // Reset tracker to recent weekly plan

			if (err || !response || !response.data) {

				if(err.response && err.response.data) {
					this.setApiResponse(err.response.data, true);
				} else {
					this.setApiResponse("Something went wrong!", true);
				}
				console.log('err...', err);

			} else if (response.data) {

				let data = response.data.data;
				// console.log('response...', response);

				if (data) {
					
					this.constructTrackerData(data);
					this.getUTasks(); // Refresh the calendarSchedules list of the tasks.
					this.setApiResponse("Records have been reseted successfully!", false);

					if (response.data.message) {
						this.toggleCustomAlert(response.data.message, true);
					};
				}
				else this.setApiResponse("Something went wrong!", true);
			}

			this.setState({
				buttonDisabled: false,
			});
			this.props.setLoading(false);
		});

	}

	// Handler to update the event
	updateEventHandler = (e, orginalEvent, updatedEvent, notes, completedStatus) => {

		e.preventDefault();

		let { events, unplannedTimeBlocks } = this.state;
		let isNonPBHEvent = updatedEvent.isNonPBHEvent ? true : false;
		let isNewlyAssignedPBHProperties = updatedEvent.isNewlyAssignedPBHProperties || false;
		
		const idx = _.findIndex(events, ['eventID', orginalEvent.eventID]);
		delete updatedEvent.isNewlyAssignedPBHProperties; // Remove

		if (idx > -1) {

			if (!_.isEqual(orginalEvent, updatedEvent) 
				|| (!_.isEqual(orginalEvent.notes, notes))
				|| orginalEvent.isCompleted !== completedStatus) {
				// Update the changes in GC, cause the event is updated.
				updatedEvent.isEventUpdated = true; 
			};
			events[idx] = updatedEvent;
			events[idx].isCompleted = completedStatus;
			events[idx].notes = notes;
			
			if (orginalEvent.isUnplanned) {

				var findCriteria = orginalEvent.id ? {id: orginalEvent.id} : {timeBlockID: orginalEvent.timeBlockID};
				//  Find item index using _.findIndex
				var index = _.findIndex(unplannedTimeBlocks, findCriteria);

				if (index > -1 && unplannedTimeBlocks[index].isUnAssigned) {

					let updatedFields = _.pick(updatedEvent, ['outcomeID', 'outcomeAbbr', 'outcomeTitle', 'keyActivityID', 'keyActivityTitle', 'focusAreaID', 'focusAreaTitle', 'isUnAssigned', 'metricsAndTargets']);
					updatedFields.metricsAndTargets = _.map(updatedFields.metricsAndTargets || [], (item) => _.omit(item, 'response')); // The commitment section shouldn't contain metric responses.

					let updatedTimeBlock = {
						...unplannedTimeBlocks[index],
						...updatedFields
					};
					// Replace item at index using native splice
					unplannedTimeBlocks.splice(index, 1, updatedTimeBlock); // Update Corresponding TimeBlock
				};
			};

			// If the user assigns PBH properties to the NON-PBH recurring event, check whether if it has multiple child instances on the same tracker week.
			// If yes, assign the PBH properties to those instances as well.
			if (isNonPBHEvent && isNewlyAssignedPBHProperties) { 

				_.map(events, (item) => {

					if (item.WPRecurringEventID && updatedEvent.WPRecurringEventID && item.WPRecurringEventID == updatedEvent.WPRecurringEventID && item.eventID != updatedEvent.eventID) { // Update only siblings
						item.outcomeID = updatedEvent.outcomeID;
						item.outcomeAbbr = updatedEvent.outcomeAbbr;
						item.outcomeTitle = updatedEvent.outcomeTitle;
						item.keyActivityID = updatedEvent.keyActivityID;
						item.keyActivityTitle = updatedEvent.keyActivityTitle;
						item.focusAreaID = updatedEvent.focusAreaID;
						item.focusAreaTitle = updatedEvent.focusAreaTitle;
						item.metricsAndTargets = _.map(updatedEvent.metricsAndTargets || [], (item) => _.omit(item, 'response')); // When assigning metrics to sibling instances, we shouldn't consider metric responses of the assigned event.
						item.isUnAssigned = false;
					};
				});
			}
			else {
				updatedEvent = null;
			};

			this.setState({
				events: events,
				unplannedTimeBlocks: unplannedTimeBlocks,
				updatedEvent: updatedEvent,
				hasUnsavedChanges: true
			}, 
			() => {
				// we are calling child components submit from parent. Since events are updated filter events based on event update
				if (this.yourCommitmentsChild) this.yourCommitmentsChild.filterEvents();
				if (this.unplannedTimeBlocksChild) this.unplannedTimeBlocksChild.filterEvents();
				this.handleSaveChanges();
			});
		};

		this.toggleDetailedInfoModal();
	}

	// Handler to delete the event from Tracker
	deleteEventHandler = (event) => {

		const { events, currentTrackingData, unplannedTimeBlocks } = this.state;

		const idx = _.findIndex(events, ['eventID', event.eventID]); 

		if (idx > -1) {

			const eventObj = currentTrackingData.event;

			if (event.isAdHocTimeTrackerUnPlannedEvent && eventObj && event.eventID === eventObj.eventID) { // remove tracking if necessary

				let updatedtTrackingData = {state : 'stopped'};

				this.setState({
					currentTrackingData: updatedtTrackingData,
					adHocTimeTrackingIsOn: false
				})

				clearTimeout(this._adHocTimeTracker); // stop notification
			}

			// soft delete
			let eventNewData = events[idx];

			eventNewData = {
				...eventNewData,
				isRemovedFromThePlan: true,
				isEventUpdated: true
			}
			
			events.splice(idx, 1, eventNewData);

			if (event.isCreatedFromTheTask === true || event.isNonPBHEvent) { // If taskEvent is removed, delete it from the UNPLANNED section as well.

				let positionInUnplannedTB = _.findIndex(unplannedTimeBlocks, {timeBlockID: event.timeBlockID});
	
				if (positionInUnplannedTB > -1) {
					
					let associatedTimeBlock = _.filter(events, function(e) { 
						if (!e.isRemovedFromThePlan && e.timeBlockID == event.timeBlockID) {
							return true;
						} 
						else return false;
					});
	
					if (associatedTimeBlock.length == 0) { // The task can be added multiple times. Remove it from the UNPLANNED section ONLY if the week has no schedule for the given task.
						unplannedTimeBlocks.splice(positionInUnplannedTB, 1);
					};
				};
			};

			this.setState({
				events: events,
				unplannedTimeBlocks: unplannedTimeBlocks,
				hasUnsavedChanges: true
			}, 
			() => {
				// we are calling child components submit from parent. Since events are updated filter events based on event update
				if (this.yourCommitmentsChild) this.yourCommitmentsChild.filterEvents();
				if (this.unplannedTimeBlocksChild) this.unplannedTimeBlocksChild.filterEvents();
				this.handleSaveChanges();
			});
		}

		this.toggleDetailedInfoModal();
	}

	updateUserPreferencesInServer = (preferences) => {

		if (!preferences) return;

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateUserPreferences, {updatedUserPreferences: preferences}, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					this.props.setLoading(true);
					let data = response.data.data;

					this.setState({
						userPreference: data,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || []
					},
					() => {
						/* It is necessary to reload PBH tracker/calendar to reflect the changes  */
						const { targetWeekStart = null, targetWeekEnd = null} = this.state;
						this.loadTrackerEventsForTheWeek(targetWeekStart, targetWeekEnd);
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			//this.props.setLoading(false);
		});
	}

	setGeneratedTimeBlocks(timeBlocks, type) {

		if(type === 'all') {
			this.setState({
				allLatesCommitments: timeBlocks, // update Lastes TB
			});
		}

		this.setState({
			timeBlocks: timeBlocks,
		});

	}

	setGeneratedUnplannedTimeBlocks(unplannedTimeBlocks) {

		let unplannedTimeBlocksCopy = [...unplannedTimeBlocks];
		if (this.state.unplannedTimeBlocks && this.state.unplannedTimeBlocks.length > 0) {
			unplannedTimeBlocksCopy = [...unplannedTimeBlocks, ...this.state.unplannedTimeBlocks];
		}

		this.setState({
			unplannedTimeBlocks: unplannedTimeBlocksCopy,
			hasUnsavedChanges: true
		});
	}

	handleTaskBasedAdHocEvent = (taskEventObj) => {

		let {unplannedTimeBlocks, currentTrackingData} = this.state;
		let position = _.findIndex(unplannedTimeBlocks, {timeBlockID: taskEventObj.timeBlockID});
		
		if (position > -1) {
			unplannedTimeBlocks.splice(position, 1, taskEventObj);
		}
		else {
			unplannedTimeBlocks.push(taskEventObj);
		};

		this.setState({
			unplannedTimeBlocks: unplannedTimeBlocks,
			hasUnsavedChanges: true
		},
		() => {
			this.updateTrackingEvent(taskEventObj, 'start', currentTrackingData);
		});
	}

	updateTrackingEvent = (unplannedEvent, action, trackingData) => {

		const { events, currentTrackingData } = this.state;
		
		let options = {
			syncWith3rdPartyCalendar: false
		};

		if (action == 'start') {

			let start = new Date();
			let end = moment().add(unplannedEvent.durationInMinutes, 'minutes').toDate();

			if (start.getDay() != end.getDay() && end.getHours() == 0 && end.getMinutes() == 0) {
				end.setDate(start.getDate()); // Hot fix
				end.setHours(23, 59, 0); // Hot fix
			}

			let coreData = Util.getUser();
			unplannedEvent.start = start;
			unplannedEvent.end = end;
			unplannedEvent.eventID = coreData.userCoreProperties.userID + "-" + Date.now();
			unplannedEvent.adHocTimeTrackerStartedAt = new Date();
			unplannedEvent.isAdHocTimeTrackerUnPlannedEvent = true;
			unplannedEvent.isCurrentTrackingEvent = true;
			unplannedEvent.isCompleted = true;
			unplannedEvent.isEventUpdated = true;

			events.push(unplannedEvent) // new event

			trackingData.event = unplannedEvent;
			trackingData.state = 'tracking'; // current state

			this.setState({ 
				events: events,
				currentTrackingData: trackingData,
				hasUnsavedChanges: true
			}, 
			() => {
				this.initiateUpdatingTrackerEvents(options)
			});
		}
		else if (currentTrackingData && currentTrackingData.state === 'tracking') {

			let updatedtTrackingData = {state : 'stopped'};

			const idx = _.findIndex(events, ['eventID', currentTrackingData.event.eventID]); 

			if (idx > -1) {

				events[idx].isCurrentTrackingEvent = false;
				events[idx].stopAdHocTimeTracker = true;
				events[idx].adHocTimeTrackerState = 'stopped';
				events[idx].adHocTimeTrackerStopedAt = new Date();
				events[idx].end = new Date(); // set event endDt as current DateTime

				//Calculate Duration
				let duration = moment().diff(moment(events[idx].start), 'minutes');
				if (!events[idx].durationInMinutes) {
					// Update duration If duration is not specified while creating Ad-Hoc Event
					events[idx].durationInMinutes = duration;
				}
				events[idx].actualCompletionTimeInMinutes = duration;
				events[idx].isEventUpdated = true;

			}

			clearTimeout(this._adHocTimeTracker); // stop notification

			this.setState({
				currentTrackingData: updatedtTrackingData,
				adHocTimeTrackingIsOn: false,
				events: events,
				hasUnsavedChanges: true
			}, 
			() => {
				options.syncWith3rdPartyCalendar = true;
				this.initiateUpdatingTrackerEvents(options);
				this.toggleDetailedInfoModal();
			});
		}
	}

	initiateUpdatingTrackerEvents (saveOptions) {
		this.props.setLoading(true);
		//this.weeklyNotesChild.handleSubmit();
		//this.dailyNotesChild.handleSubmit();
		this.updateTracker(saveOptions);
	}

	turnOnNotification = (event) => {

		// add listener for sound add alert. Notify when meets set timer field value.
		// To seconds and milliseconds

		if(!event.setTimerInMinutes || !event.notificatioScheduledAt) {
			return // no notification
		}

		clearTimeout(this._adHocTimeTracker); // clear previous schedule notification

		const notificatioScheduledTime = event.notificatioScheduledAt.getTime();
		const currentTime = new Date().getTime();

		let remainingTime = notificatioScheduledTime - currentTime;

		if(remainingTime > 0) {

			this._adHocTimeTracker = setTimeout(() => {

				this.setState({ playSound: !this.state.playSound }, () => {
					this.state.playSound ? this.audio.play() : this.audio.pause();
				});

				// toast.info("Time's Up!");

				const { events, currentTrackingData } = this.state;
				const idx = _.findIndex(events, ['eventID', currentTrackingData.event.eventID]);

				if (idx > -1) {
					events[idx].adHocTimeTrackerState = 'notified';
					events[idx].adHocTimeTrackerNotifiedAt = new Date();
					currentTrackingData.event = events[idx];

					this.setState({
						events: events,
						currentTrackingData: currentTrackingData,
					},() => {
						this.toggleDetailedInfoModal(true, events[idx])
					})
				}

			}, remainingTime)
		}

	}

	updateTasksForTheDay = (dateID, taskID) => {

		let dailyTasks = this.state.dailyTasks || {};
		dailyTasks[dateID] = dailyTasks[dateID] || [];

		let taskIndex = _.findIndex(dailyTasks[dateID], {id: taskID});

		if (taskIndex == -1) {
			
			let taskObj = _.find(this.state.utasks, {id: taskID});

			if (taskObj) {
				dailyTasks[dateID].push({
					id: taskID, 
					task: taskObj.task, 
					isActive: true
				});
			};
		}
		else {
			dailyTasks[dateID][taskIndex].isActive = true; // If the newly added task already configured for the day, make sure it is in `Active` status.
		};

		this.setState({
			dailyTasks: dailyTasks,
			hasUnsavedChanges: true
		});
	}

	handleRemoveTaskForTheDay = (dateID, taskID) => {

		let dailyTasks = this.state.dailyTasks || {};
		dailyTasks[dateID] = dailyTasks[dateID] || [];

		let taskIndex = _.findIndex(dailyTasks[dateID], {id: taskID});

		if (taskIndex > -1) {
		
			dailyTasks[dateID][taskIndex].isActive = false;

			this.setState({
				dailyTasks: dailyTasks,
				hasUnsavedChanges: true
			});
		};
		// else If the task not even added for the day, no action needed.
	}

	toggleCustomAlert = (message = '', showCustomAlert = false, autoCloseTimer = 4000) => {
		
		this.setState({
			customAlertMessage: message,
			showCustomAlert: showCustomAlert
		},
		() => {
			if (this.state.showCustomAlert && autoCloseTimer) setTimeout(() => {this.toggleCustomAlert();}, 4000); // Close the alert after 4 seconds.
		});
	}

	renderAPIAlertMessage() {

		const { apiResponse } = this.state;

		return (
			<span className="small text-right">
				{
					!_.isEmpty(apiResponse)
						?
						<div className={apiResponse.className}><span>{apiResponse.message}</span></div>
						: null
				}
			</span>
		)
	}

	renderDetailedInfoPopup() {

		return <DetailedInfoPopup
			showDetailedInfoModal={this.state.showDetailedInfoModal}
			detailedInfo={this.state.detailedInfo}
			showMore={this.state.showMoreInfo}
			stopTrackingEvent={this.updateTrackingEvent}
			toggleDetailedInfoModal={this.toggleDetailedInfoModal}
			deleteEvent={this.deleteEventHandler}
			updateEvent={this.updateEventHandler}
			focusAreas={this.state.focusAreas} 
			outcomesWithPopulatedData={this.state.outcomesWithPopulatedData}
			utasks={this.state.utasks}
			onUpdateUTasks={this.updateUTasks}  
		/>;
	}

	toggle(sectionname) {

		this.setState(prevState => ({
			[sectionname]: !prevState[sectionname],
		}),
		() => {
			if (this.state[sectionname]) {
				//document.getElementById(sectionname).scrollIntoView({behavior: 'smooth'});
			};
		});
	}

	renderLeftSection() {

		const {activeLeftPaneSection} = this.state;
		let activeSectionJSX = null;

		if (activeLeftPaneSection == 'commitments') {
			activeSectionJSX = (
				<YourCommitments 
					onRef={ref => (this.yourCommitmentsChild = ref)}
					commitments={this.state.commitments}
					timeBlocks={this.state.timeBlocks}
					events={this.state.events}
					onDoubleClick={this.toggleDetailedInfoModal}
					focusAreas={this.state.focusAreas}
					outcomesWithPopulatedData={this.state.outcomesWithPopulatedData}
					targetWeekStart={this.state.targetWeekStart}
					updateTimeBlocks={this.setGeneratedTimeBlocks.bind(this)}>
				</YourCommitments>
			);
		}
		else if (activeLeftPaneSection == 'tasks') {
			activeSectionJSX = (
				<Tasks 
					onRef={ref => (this.tasksChild = ref)}
					focusAreas={this.state.focusAreas}
					utasks={this.state.utasks}
					onUpdateUTasks={this.updateUTasks} 
					onRefresh={this.getUTasks.bind(this, true)}
					adHocTimeTrackingIsOn={this.state.adHocTimeTrackingIsOn}
					handleTaskBasedAdHocEvent={this.handleTaskBasedAdHocEvent}
					outcomesWithPopulatedData={this.state.outcomesWithPopulatedData}
					reloadOutcomesData={this.getOutcomesWithPopulatedData.bind(this)}
					events={this.state.events} 
					toggleDetailedInfoModal={this.toggleDetailedInfoModal}
					targetWeekStart={this.state.targetWeekStart}>
				</Tasks>
			);
		};

		return (
			<div style={{ padding: 15, maxHeight: '75vh', overflow: 'auto' }}>
				<div className="text-center mb-3">
					<ButtonGroup className="tracker-your-commitments flex-wrap">
						<Button onClick={() => this.setState({activeLeftPaneSection: 'commitments'})} className={activeLeftPaneSection == 'commitments' ? "active" : ""} style={{minWidth:105}}>Commitments</Button>
						<Button onClick={() => this.setState({activeLeftPaneSection: 'unplanned'})} className={activeLeftPaneSection == 'unplanned' ? "active" : ""} style={{minWidth:105}}>Other</Button>
						<Button onClick={() => this.setState({activeLeftPaneSection: 'tasks'})} className={activeLeftPaneSection == 'tasks' ? "active" : ""} style={{minWidth:105}}>Tasks</Button>
						{/* <Button onClick={() => this.setState({activeLeftPaneSection: 'notes'})} className={activeLeftPaneSection == 'notes' ? "active" : ""}>Notes</Button> */}
					</ButtonGroup>
				</div>
				{activeSectionJSX || activeLeftPaneSection == 'unplanned' ? /* Unplanned section should be mounted for all tabs so that adhoc tracker will work as expected. */
					<div className="mt-4 mb-2 border p-3">
						<UnplannedTimeBlocks 
							onRef={ref => (this.unplannedTimeBlocksChild = ref)}
							activeLeftPaneSection={activeLeftPaneSection}
							focusAreas={this.state.focusAreas} 
							outcomesWithPopulatedData={this.state.outcomesWithPopulatedData}
							getOutcomesWithPopulatedData={this.getOutcomesWithPopulatedData.bind(this)} 
							unplannedTimeBlocks={this.state.unplannedTimeBlocks}
							events={this.state.events} 
							toggleDetailedInfoModal={this.toggleDetailedInfoModal}
							targetWeekStart={this.state.targetWeekStart}
							trackingData={this.state.currentTrackingData}
							updateTrackingEvent={this.updateTrackingEvent}
							startTrackingBtnClicked={this.state.startTrackingBtnClicked}
							addNewUnplannedTimeBlocks={this.setGeneratedUnplannedTimeBlocks.bind(this)}>
						</UnplannedTimeBlocks>
						{activeSectionJSX}
					</div>
				: null}
			</div>
		);
	}

	/* show/hide Alert Popup */
	renderResetWarningPopup() {
		return (
			<Modal
				isOpen={this.state.showResetModal}
				toggle={this.toggleResetWarningPopup}
			>
				<ModalHeader >{"Heads up!"}</ModalHeader>

				<ModalBody>
					<div className="mb-4">
						<p><b>Are you sure you want to reset the scheduled events?</b></p>
						<div className="small">Please be informed that this action is permanent and any <b>unsaved data(s)</b> such as events, notes will be lost.</div>
						<div className="small mt-2">Any changes you made to these events in your <b>3-rd party calendar</b> (e.g. inviting participants) will also be lost.</div>
					</div>
					<div className="popup-footer-section">
						<div>
							<Button color="danger" title="Reset-unplanned" onClick={(e) => this.resetTrackerEvents(e, "unplanned_events")} className="btn-md btn-sm mr-2">Reset unplanned events</Button>
							<Button color="danger" title="Reset-planned" onClick={(e) => this.resetTrackerEvents(e, "planned_events")} className="btn-md btn-sm mr-2">Reset planned events</Button>
						</div>
						<Button color="secondary" title="Cancel" onClick={this.resetBtnOnClickHandler} className="btn-sm">Cancel</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	renderCustomAlert = () => {

		const { customAlertMessage = "", showCustomAlert = false } = this.state

		return (
			<div>
				<Modal isOpen={showCustomAlert} toggle={()=>this.toggleCustomAlert()}>
					<ModalBody className="p-2">
						<button type="button" className="close pl-3 pr-1" aria-label="Close" onClick={()=>this.toggleCustomAlert()}><span aria-hidden="true">×</span></button>
						<div className="small-title-text m-1 p-0">{customAlertMessage}</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}


	render() {

		let formats = {
			eventTimeRangeFormat: () => null,
			dayRangeHeaderFormat: ({ start, end }, culture, localizer) => localizer.format(start, 'MMM DD', culture) + ' - ' + localizer.format(end, 'MMM DD', culture)
		};

		let isPastWeek = this.state.targetWeekStart ? moment(this.state.targetWeekStart).isBefore(moment().startOf('isoWeek')) : false;
		
		let components = {
			event: CustomEvent,
			toolbar: props => (
				<TrackerCalendarToolBar {...props}
					disableSaveButton={this.state.buttonDisabled}
					view={this.state.currentView}
					onView={(currentView) => this.setState({currentView: currentView})}
					disableResetButton={!this.state.trackerID || isPastWeek ? true : false} // Disable the RESET action button for the past weeks and unsaved tracker weeks.
					reset={this.resetBtnOnClickHandler}
					hasUnsavedChanges={this.state.hasUnsavedChanges}
					loadTrackerEvents={this.loadTrackerEventsForTheWeek}
					adHocTimeTrackingIsOn={this.state.adHocTimeTrackingIsOn}
					toggleTrackingPopup={this.toggleTrackingPopup}
					userPreference={this.state.userPreference}
					excludedGoogleCalendarIDs={this.state.excludedGoogleCalendarIDs}
					GCMetaData={this.state.GCMetaData}
					updateUserPreferencesInServer = {this.updateUserPreferencesInServer}
					handleSaveChanges={this.handleSaveChanges.bind(this)} 
				/>
			),
			header: props => (
				<DayColumnHeader {...props}
					utasks={this.state.utasks}
					dailyTasks={this.state.dailyTasks}
					updateTasksForTheDay={this.updateTasksForTheDay}
					handleRemoveTaskForTheDay={this.handleRemoveTaskForTheDay} 
				/>
			),
		}

		// Dont render deleted/hidden events
		let filteredEvents = _.filter(this.state.events, function(e) { 

			// if (e.isEventHiddenInPBH && e.isEventHiddenInPBH !== false) return false;
			if (!e.isEventHiddenInPBH && !e.isRemovedFromThePlan) {
				return true;
			} else {
				return false;
			}
		});

		return (
			<div className="page-content container child-container" id="tracker-top-parent">
				<Prompt
					when={this.state.hasUnsavedChanges}
					message='You have unsaved changes, are you sure you want to leave?'
				/>
				<div className="bread-crumb">
					<NavLink to="/calendar">Calendar</NavLink>&nbsp;
				</div>
				{/* <div id={'tracker-toolbar'} className="inner-container text-right">
					{this.state.hasWeeklyPlan === null ? null :
						<Button onClick={this.handleSaveChanges.bind(this)} className={"pbh-small-btn-active"} disabled={this.state.buttonDisabled}>Save Changes</Button>
					}
				</div> */}
				{ this.state.hasWeeklyPlan === null ? null :
					<div>
						{
							this.state.hasWeeklyPlan ?
								<div className="section-container">
									<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
									
									<div className="row row-section-container">

										<div className="col-md-3 p-0">
											{(this.state.focusAreas.length > 0 && this.state.targetWeekStart) ? this.renderLeftSection() : <div className="row h-25 justify-content-center align-items-center"><div className="spinner-border color-primary" role="status">
												<span className="sr-only">Loading...</span>
											</div></div>}
										</div>

										<div className="col-md-9 inner-container">
											{this.renderAPIAlertMessage()}
											<div className={"pbh-tracker"}>
												<Calendar
													formats={formats}
													events={filteredEvents}
													allEvents={this.state.events}
													view={this.state.currentView}
													defaultDate={new Date()}
													components={components}
													timeslots={12}
													step={5}
													default={30}
													scrollToTime={this.scrollToTime}
													adHocTimeTrackingIsOn={this.state.adHocTimeTrackingIsOn}
													updateCurrentAdHocTimeTrackerEvent={this.updateCurrentAdHocTimeTrackerEvent}
													handleSaveChanges={this.handleSaveChanges.bind(this)}
													updateEvents={this.updateEvents}
													showDetailedInfo={this.toggleDetailedInfoModal}
													onNavigate={(date, currentView, action) => this.setState({currentViewingDate: date})}
													onView={(currentView) => this.setState({currentView: currentView})}
												/>
											</div>
											
										</div>
									</div>

									{/* {this.state.targetWeekStart ?
										<div className="mt-4 row row-section-container">
											<div className="col-md-3 inner-container">
												<div>
													<h6 className="mb-4">WEEKLY NOTES</h6> 
													<div className="mb-2 border p-3">
														<WeeklyNotes onRef={ref => (this.weeklyNotesChild = ref)} weeklyNotes={this.state.weeklyNotes} handleWeeklyNoteSaveChanges={this.handleWeeklyNoteSaveChanges.bind(this)} />
													</div>
												</div> 
											</div>
											<div className="col-md-9 inner-container">
												<DailyNotes 
													targetWeekStart={this.state.targetWeekStart} 
													onRef={ref => (this.dailyNotesChild = ref)} 
													handleDailyNoteSaveChanges={this.handleDailyNoteSaveChanges.bind(this)} 
													dailyNotes={this.state.dailyNotes}
													showNotesForTheDate={this.state.currentView == 'day' && this.state.currentViewingDate ? moment(this.state.currentViewingDate).format('MM/DD/YYYY') : 'all'} />
											</div>
										</div>
									: null} */}
									
									{this.renderDetailedInfoPopup()}
									{this.renderResetWarningPopup()}
								</div>
							:
							<p className="text-center text-info">Please add your Weekly Plan before accessing the Tracker section.</p>
						}
					</div>
				}
				{this.renderCustomAlert()}
			</div>
		);

	}
}

WeeklyTracker.propTypes = {
	setLoading: PropTypes.func.isRequired,
	getUserOutcomesWithPopulatedData: PropTypes.func.isRequired,
};

// const CustomizedWeeklyTracker = DragDropContext(HTML5Backend)(WeeklyTracker);

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomesWithPopulatedData })(
	WeeklyTracker
);
