import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { setLoading } from "../../actions/loadingActions.js";
import { resetnoteData } from "../../actions/noteActions";

import { Link } from "react-router-dom";
import { history } from '../../utils/history';
import Util from '../../utils/Util';
var _ = require('lodash');

class NoteForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				notes: "",
			},
			formErrors: {
				title: null,
				notes: null,
			},
			buttonDisabled: false,
			isEdit: false,
			noteID: null,
			notesEditorState: EditorState.createEmpty(),
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { id, notes } = this.props.initialValues;

			var formObj = {	};

			formObj = {
				...this.props.initialValues,
				...formObj,
			};

			this.setState({
				form: formObj,
				isEdit: true,
				notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
				noteID: id,
			});
		}
		this.setStickyToolBar();
	}

	setStickyToolBar() {

		const header = document.getElementById("notes-toolbar");

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "noteTitle":
				if (!value) errorMsg = "Please enter Note Title";
				break;

			case "note":
				if (!value) errorMsg = "Please enter Note";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for note submit
	handleNoteSubmit(e) {
		e.preventDefault();

		this.setState({
			keyActivitiesError: "",
		});

		const { form, formErrors, notesEditorState, keyActivities } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {

					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			formCopy['notes'] = notesContent;

			this.props.onSubmit(formCopy);
		}
	}

	render() {

		const { form, formErrors, notesEditorState, isEdit } = this.state;

		return (
			<div>
				<form onSubmit={(e) => e.preventDefault()}>

					<div id={'notes-toolbar'} className="text-right mb-1">
						<button type="button" onClick={this.handleNoteSubmit.bind(this)} className="btn btn-primary btn-sm">{isEdit ? "Save Changes" : "Save & Close"}</button>
						<Link to="/notes/" className="btn btn-primary btn-sm ml-3">Cancel</Link>
					</div>

					<div>
						<div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
								<p className="text-danger">{formErrors['title'] &&
									<span className="err">{formErrors['title']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
										})
									}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		);

	}
}

NoteForm.propTypes = {
	resetnoteData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetnoteData, setLoading })(
	NoteForm
);
