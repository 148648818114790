import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';

import { setLoading } from "../../actions/loadingActions.js";
import BigCalendar, { momentLocalizer } from "../../assets/scripts/react-big-calendar";
import Util from '../../utils/Util';

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";

import '../../assets/styles/react-big-calendar.css';
import '../../assets/styles/dndCalendarStyles.css';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar, {backend : false});
var _ = require('lodash');

class CustomizedCallender extends Component {

	constructor(props) {
		super(props);
		this.state = {
			events: this.props.events,
			showAlertModal: false,
			dayLayoutAlgorithm: 'no-overlap',
		};
	
		this.moveEvent = this.moveEvent.bind(this);
	}
	
	moveEvent({ event, start, end }) {

		const { allEvents: events } = this.props;
		const idx = event.WPEventID ? events.indexOf(event): -1;
		const updatedEvent = { ...event, start, end };

		if (idx === -1) {
			/* Set event title. */
			updatedEvent.title = Util.getEventTitle(updatedEvent);
		};

		if (start.getDay() != end.getDay() && end.getHours() == 0 && end.getMinutes() == 0) {
			end.setDate(start.getDate()); // Hot fix
			end.setHours(23, 59, 0); // Hot fix
		}

		if (start.getDay() != end.getDay()) {
			this.toggleWarningPopup();
			return true;
		}

		for (let i = 0; i < events.length; i++) { // Skip If event is scheduled already in a specific time slot or If it doesnt fit into the specific time spot 
			const existingEvent = events[i];
			if (idx != i && ((existingEvent.start < start && start < existingEvent.end)
				|| (existingEvent.start < end && end < existingEvent.end)
				|| (existingEvent.start.getTime() == start.getTime())
				|| (existingEvent.end.getTime() == end.getTime())
				|| (existingEvent.start.getTime() > start.getTime() && existingEvent.end.getTime() < end.getTime())
				)) {
				//this.toggleWarningPopup(); /* Remove event overlapping restriction */
				//return true;
			}
		}

		/* When the user reschedules a non-weekly [or] daily [or] weekly event, prompt them to select the start date again. */
		if ((updatedEvent.regularity == 'weekly' && updatedEvent.PBH_WP_Event_Version || updatedEvent.regularity == 'daily' || updatedEvent.regularity == 'non-weekly') && updatedEvent.startDate) {
			alert('Heads up! You are rescheduling a event. You need to select the start date again to continue.');
			/* Remove all the previous recurring interval configurations. */
			['startDate', 'recurringIntervalType', 'recurringIntervalDayID', 'recurringIntervalWeekID', 'recurringIntervalMonthID', 'recurringIntervalISOWeekDayID'].forEach(prop => delete updatedEvent[prop]);
		};

		/* when a Non-Weekly [or] Weekly Time Block [or] daily Time Block is added to the Weekly Plan, we should automatically show them the detail popup for it. */
		if (((updatedEvent.regularity == 'weekly' && updatedEvent.PBH_WP_Event_Version)|| updatedEvent.regularity == 'daily' || updatedEvent.regularity == 'non-weekly') && !updatedEvent.startDate) {
			this.updateEventWithDaySlotChanges(updatedEvent);
			this.selectEvent(updatedEvent);
			return true;
		};

		if ((updatedEvent.regularity == 'daily')) {
			if (moment(updatedEvent.startDate).isBefore(moment().clone().startOf('isoWeek'))) {
				this.selectEvent(updatedEvent);
			}
			else {
				// Update the move time slot changes to all the daily events.
				this.moveDailyEvent(updatedEvent);
			}
			return true;
		};

		if (!updatedEvent.PBH_WP_Event_Version && updatedEvent.recurringIntervalWeekDaysID) {
			delete updatedEvent.recurringIntervalWeekDaysID;
		};

		let currentDaySlot = moment(updatedEvent.start).clone().weekday();
		updatedEvent.recurringIntervalWeekDaysID = [currentDaySlot]
		updatedEvent.startDate = updatedEvent.start;
		updatedEvent.isEventUpdated = true; // Set true to isEventUpdated, cause this event is created/updated 

		if (idx > -1) {
			events.splice(idx, 1, updatedEvent); // update event
		}
		else {
			let coreData = Util.getUser();
			updatedEvent.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now() +  "-" + uuidV4();
			updatedEvent.WPRecurringEventID = coreData.userCoreProperties.userID +  "-" + uuidV4() + "-" + Date.now() + "-" + uuidV4();
			events.push(updatedEvent) // new event
		}

		this.props.updateEvents(events);

	}

	updateEventWithDaySlotChanges = (eventInfo) => {

		const { allEvents: events } = this.props;

		if (eventInfo.regularity == 'non-weekly' && (eventInfo.interval == 'every_x_weeks' || eventInfo.interval == 'every_other_week')) {

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});
			let currentDaySlot = moment(eventInfo.start).clone().weekday();

			if (eventInfo.recurringIntervalWeekDaysID && eventInfo.recurringIntervalWeekDaysID.length > 1) {

				let prevDaySlot = moment(events[idx].start).clone().weekday();

				if (currentDaySlot != prevDaySlot && eventInfo.recurringIntervalWeekDaysID.includes(currentDaySlot)) {
					return alert('The selected day slot cannot accomodate this event. Please consider scheduling this event on a different day slot.')
				}
				else {
					if (currentDaySlot != prevDaySlot) { // Change in day slot
						let prevDaySlotIndex = eventInfo.recurringIntervalWeekDaysID.indexOf(prevDaySlot);
						eventInfo.recurringIntervalWeekDaysID.splice(prevDaySlotIndex, 1, currentDaySlot); // Update the day slot changes
					};
				}
			}

			else {
				eventInfo.recurringIntervalWeekDaysID = [currentDaySlot]
			};
		}
	}

	moveWeeklyEvent = (eventInfo) => { /* Handler for updating move changes to weekly events */

		const { allEvents: events } = this.props;

		if (eventInfo.recurringIntervalWeekDaysID && eventInfo.recurringIntervalWeekDaysID.length > 1) {

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});

			/* Set startDate for weekly events */
			if (eventInfo.startDate) {

				let currentWeekStartDate = moment().startOf('isoWeek');
				let eventStartDate = moment(eventInfo.startDate);

				if (eventStartDate.isSameOrBefore(currentWeekStartDate.clone())) {
					/* If startDate is falls before current week then we are updating the startDate for weekly events. */
					eventInfo.startDate = currentWeekStartDate.toDate();
				};
			}
			else {
				/* Set startDate for Weekly events */
				eventInfo.startDate = moment(eventInfo.start).startOf('day').toDate();
			};

			if (idx > -1) {

				let currentDaySlot = moment(eventInfo.start).clone().weekday();
				let prevDaySlot = moment(events[idx].start).clone().weekday();

				if (currentDaySlot != prevDaySlot && eventInfo.recurringIntervalWeekDaysID.includes(currentDaySlot)) {
					return alert('The selected day slot cannot accomodate this event. Please consider scheduling this event on a different day slot.')
				}
				else {

					if (currentDaySlot != prevDaySlot) { // Change in day slot

						let prevDaySlotIndex = eventInfo.recurringIntervalWeekDaysID.indexOf(prevDaySlot);
						eventInfo.recurringIntervalWeekDaysID.splice(prevDaySlotIndex, 1, currentDaySlot); // Update the day slot changes
					};

					events.splice(idx, 1, eventInfo); // Replace the current event

					/* Update the changes to all the sibilings events which has same WPRecurringEventID */
					_.map(events, (item) => {

						if (item.WPRecurringEventID && item.WPRecurringEventID == eventInfo.WPRecurringEventID) {

							// Assign time slot changes for start date.
							item.start.setHours(eventInfo.start.getHours());
							item.start.setMinutes(eventInfo.start.getMinutes());
							item.start.setSeconds(eventInfo.start.getSeconds());

							// Assign time slot changes for end date.
							item.end.setHours(eventInfo.end.getHours());
							item.end.setMinutes(eventInfo.end.getMinutes());
							item.end.setSeconds(eventInfo.end.getSeconds());

							// Assign the updated day slot IDs.
							item.recurringIntervalWeekDaysID = eventInfo.recurringIntervalWeekDaysID;
							item.startDate = eventInfo.startDate;
							item.isEventUpdated = true;
						};
					});
				};
			};

		}
		else {

			/* One instance in a week */

			const idx = _.findIndex(events, {WPEventID: eventInfo.WPEventID});

			let daySlot = moment(eventInfo.start).clone().weekday();
			let startDate = moment(eventInfo.start).startOf('day').toDate();

			eventInfo.startDate = startDate;
			eventInfo.recurringIntervalWeekDaysID = [daySlot];
			eventInfo.isEventUpdated = true;

			if (idx > -1) {
				events.splice(idx, 1, eventInfo); // Update existing event.
			}
			else {
				let coreData = Util.getUser();
				eventInfo.WPEventID = coreData.userCoreProperties.userID + "-" + Date.now();
				eventInfo.WPRecurringEventID = coreData.userCoreProperties.userID +  "-" + uuidV4() + "-" + Date.now() + "-" + uuidV4();
				events.push(eventInfo) // new event
			};
		};

		this.props.updateEvents(events);
	}

	moveDailyEvent = (eventInfo) => { /* Handler for for updating move changes to daily events */

		const { allEvents: events } = this.props;

		/* Update the changes to all the sibilings events which has same WPRecurringEventID */
		_.map(events, (item) => {

			if (item.WPRecurringEventID && item.WPRecurringEventID == eventInfo.WPRecurringEventID) {

				// Assign time slot changes for start date.
				item.start.setHours(eventInfo.start.getHours());
				item.start.setMinutes(eventInfo.start.getMinutes());
				item.start.setSeconds(eventInfo.start.getSeconds());

				// Assign time slot changes for end date.
				item.end.setHours(eventInfo.end.getHours());
				item.end.setMinutes(eventInfo.end.getMinutes());
				item.end.setSeconds(eventInfo.end.getSeconds());

				item.isEventUpdated = true;
			};
		});

		this.props.updateEvents(events);
	}

	selectEvent = (event) => {
		/* Show detailed info in popup */
		this.props.showDetailedInfo(true, event);
	}
	
	resizeEvent = (resizeType, { event, start, end }) => {
		const { events } = this.state;

		const nextEvents = events.map(existingEvent => {
			return existingEvent.WPEventID == event.WPEventID
			? { ...existingEvent, start, end }
			: existingEvent;
		});

		this.setState({
			events: nextEvents
		});
	};

	/* Toggle Popup */

	toggleWarningPopup = () => {
		this.setState({
			showAlertModal: !this.state.showAlertModal,
		});
	}

	/* show/hide Alert Popup */

	renderWarningPopup () {
		return (
			<Modal
				isOpen={this.state.showAlertModal} 
				toggle={this.toggleWarningPopup}>
				<ModalHeader >{"Heads up!"}</ModalHeader>

				<ModalBody>
					{/* <p>{"An event is already scheduled during this time slot. Please consider scheduling this block at a different time, or try removing the existing block first."}</p> */}
					<p>The selected time slot cannot accomodate this event. Please consider scheduling this event on a different time slot.</p>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" title="Cancel" onClick={this.toggleWarningPopup} className="btn-sm">OK</Button>
				</ModalFooter>
			</Modal>
		)
	}
	
	render() {
		return (
	
			<div>
				<DragAndDropCalendar
					localizer={localizer}
					selectable
					// resizable
					events={this.state.events}
					onEventDrop={this.moveEvent}
					onSelectEvent={this.selectEvent}
					eventPropGetter={eventStyleGetter}
					views={['week', 'day']}
					dayLayoutAlgorithm={this.state.dayLayoutAlgorithm}
					// onEventResize={this.resizeEvent}
					handleSaveChanges={this.props.handleSaveChanges}
					{...this.props}
				/>
				{this.renderWarningPopup()}
			</div>
		);
	}
}

const eventStyleGetter = (event, start, end, isSelected) => {

	if (event.isNonPBHEvent) {
		/* Differentiate the Non-PBH events both weekly and non-weekly */
		return {style: { backgroundColor: '#f29569', borderColor: '#f29569' }}; 
	}
 	else if (event.regularity == 'non-weekly' || event.regularity == 'daily') {
		/* Differentiate non-weekly events [OR] daily events */
		return {style: { backgroundColor: '#fab95e', borderColor: '#fab95e' }}; 
	}
	return {style: {}}; 
};

// const CustomizedCallender = DragDropContext(HTML5Backend)(CustomizedCallender);

CustomizedCallender.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { setLoading })(
	CustomizedCallender
);
