import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { history } from '../../utils/history';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import UpdateProjects from "./UpdateProjects";

// For Unit Testing
// import ProductivityOutcomesResponse from "../../assets/api_responses/productivity-planner/ProductivityOutcomesResponse.json";

var moment = require('moment');
var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class ProductivityOutcomes extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeMenu: null,
			deleteModal: false,
			itemToRemove: null,
			apiComplete: false,
			addOutcomeFromTemplateModal: false,
			outcomeTemplates: [],
			outcomes: [],
			updateProjectsModal: false,
			chosenOutcome: {},
			deleteConfirmationText: ''
		};
	}

	async componentDidMount() {

		this.props.setLoading(true);

		await Promise.all([
			this.getOutcomes(),
			this.getUserTemplates(),
		]);

		this.props.setLoading(false);

		this.setState({
			apiComplete: true,
		})
	}

	// Get outcomes
	getOutcomes() {

		API_Services.httpGET(UrlConstants.URLS.getProductivityOutcomesForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				this.setState({
					outcomes: response.data.data,
				});
			} else {
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	// Get user templates
	getUserTemplates() {

		API_Services.httpGET(UrlConstants.URLS.getUserTemplates, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				let outcomeTemplates = [];

				response.data.data.forEach(eachTemplate => {

					if (eachTemplate.templateType == "outcome") {
						outcomeTemplates.push(eachTemplate);
					}
				});

				this.setState({
					outcomeTemplates: outcomeTemplates,
				});
			} else {
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
				deleteConfirmationText: ''
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing outcome
	handleOutcomeDelete() {

		var { outcomes, itemToRemove, deleteConfirmationText } = this.state;

		if (!deleteConfirmationText || deleteConfirmationText.toUpperCase() != 'DELETE') {
			return alert('Please type the text DELETE in the input box to confirm your action.');
		};
		
		if (itemToRemove) {

			this.props.setLoading(true);

			API_Services.httpPOST(UrlConstants.URLS.deleteProductivityOutcome, {recordID: itemToRemove}, (err, response) => {

				if (err) {
					
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} 
					else {
						this.setApiResponse("Something went wrong!", true);
					};
				} 
				else if (response.data) {

					if (response.data.status && response.data.data) {

						outcomes.forEach((eachPOutcome, index) => {
							if (eachPOutcome.id == response.data.data.id) {
								outcomes.splice(index, 1);
							};
						});
						this.setState({
							outcomes: outcomes,
						}, 
						() => {
							this.toggleDeleteModal();
						});
					};
				}
				else {
					alert('Something went wrong!');
				};
				this.props.setLoading(false);
			});
		};
	}

	renderOutcomeDeleteModal() {

		const { outcomes, itemToRemove, deleteConfirmationText } = this.state;

		if (outcomes.length == 0 || itemToRemove == null) {
			return <div />
		};

		var obj = _.find(outcomes, { id: itemToRemove });

		if (!obj) return;

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Outcome</ModalHeader>
					<ModalBody>
						<p className="text-blog">Are you sure you want to delete the outcome <span className="color-primary font-bold">{obj['outcome']}?</span></p>
						<p className="text-blog">Please be informed that this action will delete the <span className="font-bold">Outcome and all the Projects</span> that are created based on this Outcome.</p>
						<p className="text-blog">Please type the text <span className="font-bold">DELETE</span> in the below input box to confirm your action.</p>
						<div className="mb-3">
							<input 
								type="text" 
								className="form-control" 
								name="outcome" 
								placeholder="Type 'DELETE' here to confirm"
								onChange={(e) => this.setState({deleteConfirmationText: e.target.value})} 
								value={deleteConfirmationText} />
						</div>
						<div><small>* This action cannot be undone.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleOutcomeDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	addOutcomeInList(newOutcome) {

		let outcomes = this.state.outcomes;
		outcomes.push(newOutcome);

		this.setState({
			outcomes: outcomes,
			addOutcomeFromTemplateModal: false,
		})
	}

	addOutcomeFromTemplateSubmit() {

		var postObj = {
			templateID: this.state.chosenOutcomeTemplate['id']
		};

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.createOutcomeFromTemplate, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.addOutcomeInList(response.data.data);
				} else {
					this.setApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	toggleAddOutcomeFromTemplate() {

		if (this.state.addOutcomeFromTemplateModal) {
			this.setState({
				addOutcomeFromTemplateModal: false,
			});
		}
		else {
			this.setState({
				addOutcomeFromTemplateModal: true,
			});
		}
	}

	handleOutcomeTemplateSelectField(value) {

		let chosenOutcomeTemplate = _.find(this.state.outcomeTemplates, { id: value.value });

		this.setState({
			chosenOutcomeTemplate: chosenOutcomeTemplate,
		});
	}

	renderAddOutcomeFromTemplateInModal() {

		let chosenOutcomeTemplateID = this.state.chosenOutcomeTemplate && this.state.chosenOutcomeTemplate['id'] ? this.state.chosenOutcomeTemplate['id'] : '';

		let outcomeTemplates = [];

		// if chosen project is system default and for some project templates there won't be key activities
		if (this.state.outcomeTemplates.length > 0) {
			this.state.outcomeTemplates.forEach(e => {
				outcomeTemplates.push({ value: e.id, label: e.outcomeTitle })
			});
		};

		return (
			<div>
				<Modal isOpen={this.state.addOutcomeFromTemplateModal} toggle={this.toggleAddOutcomeFromTemplate.bind(this)}>
					<ModalHeader toggle={this.toggleAddOutcomeFromTemplate.bind(this)}>Choose Outcome Template</ModalHeader>
					<ModalBody>
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<ReactSelect
											name="outcomeTemplate"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={outcomeTemplates}
											value={_.find(outcomeTemplates, { value: chosenOutcomeTemplateID })}
											removeSelected={true}
											autosize={true}
											clearable={true}
											placeholder="Choose Outcome Template"
											onChange={this.handleOutcomeTemplateSelectField.bind(this)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									<button type="button" onClick={this.addOutcomeFromTemplateSubmit.bind(this)} disabled={_.isEmpty(this.state.chosenOutcomeTemplate)} className="btn btn-primary mb-2 btn-sm" style={{ marginRight: 10 }}>Save & Return</button>
									<button type="button" onClick={this.toggleAddOutcomeFromTemplate.bind(this)} className="btn btn-secondary mb-2 btn-sm" style={{ marginRight: 10 }}>Cancel</button>
								</div>
							</div>
						</form>
					</ModalBody>
				</Modal>
			</div>
		)
	}

	toggleUpdateProjectsModal(chosenOutcome) {

		if (this.state.updateProjectsModal) {

			this.setState({
				updateProjectsModal: false,
				chosenOutcome: "",
			});
		}
		else {

			this.setState({
				updateProjectsModal: true,
				chosenOutcome: chosenOutcome,
			});
		}
	}

	updateOutcome(newOutcome, closeModal = false) {

		let outcomesClone = _.cloneDeep(this.state.outcomes);
		
		var outcomeIndex = _.findIndex(outcomesClone, { id: newOutcome['id'] });

		outcomesClone.splice(outcomeIndex, 1, newOutcome);

		this.setState({
			outcomes: outcomesClone,
			chosenOutcome: newOutcome,
		});

		if (closeModal) {
			this.toggleUpdateProjectsModal();
		};
	}

	renderUpdateProjectsModal() {

		let { chosenOutcome } = this.state;

		if (_.isEmpty(chosenOutcome)) return;

		return (
			<Modal isOpen={this.state.updateProjectsModal} toggle={this.toggleUpdateProjectsModal.bind(this)}>
				<UpdateProjects
					onCancel={this.toggleUpdateProjectsModal.bind(this)}
					updateOutcome={this.updateOutcome.bind(this)}
					chosenOutcome={this.state.chosenOutcome}
				/>
			</Modal>
		)
	}

	renderProductivityOutcomesTable() {

		const { outcomes } = this.state;

		if (outcomes.length == 0) {
			return <div className="mt-3 text-info small">No Outcomes found</div>;
		};

		return (
			<table className="table-curved mt-3">
				<thead>
					<tr>
						<th className="text-center" style={{ width: 50 }}>#</th>
						<th className="text-center" style={{ width: 400 }}>Outcome</th>
						<th className="text-center" style={{ width: 100 }}>Has Metrics?</th>
						<th className="text-center" style={{ width: 100 }} colSpan={3}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{outcomes.map((eachPOutcome, index) => {

						let hasMetricsIcon = !_.isEmpty(eachPOutcome['metricsAndTargets']) ? "/images/available.svg" : "/images/not-available.svg";

						return (
							<tr key={eachPOutcome['id']}>
								<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
								<td className="text-center" style={{ width: 400 }}>{eachPOutcome['outcome']}</td>
								<td className="text-center" style={{ width: 100 }}><img src={hasMetricsIcon} width="12" /></td>
								<td className="text-center" style={{ width: 50 }}><Link to={`/productivity-planner/outcomes/edit/${eachPOutcome['id']}`} title="Edit" className="table-action-button"><i className="fa fa-pencil color-primary" aria-hidden="true"></i></Link></td>
								<td className="text-center" style={{ width: 50 }}><button className="table-action-button color-primary" title="Update Projects" onClick={this.toggleUpdateProjectsModal.bind(this, eachPOutcome)}><i className="fa fa-refresh color-primary" aria-hidden="true"></i></button></td>
								<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, eachPOutcome['id'])}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
							</tr>
						);
					})}
				</tbody>
			</table>
		)
	}

	render() {

		if (!this.state.apiComplete) return (<div />)

		return (
			<div>
				<div className="row">
					<div className="col-sm-12">
						<div className="text-right">
							<Link to="/productivity-planner/outcomes/add" style={{ fontSize: 14 }} className="btn btn-primary mb-2 mr-2 btn-xs" title="Add Outcome">Add Outcome &nbsp; <i className="fa fa-plus" aria-hidden="true"></i></Link>
							<button type="button" className="btn btn-primary btn-xs mb-2" onClick={this.toggleAddOutcomeFromTemplate.bind(this)}>
								Add Outcome From Template &nbsp; <i className="fa fa-plus" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
						{this.renderProductivityOutcomesTable()}
						{this.renderOutcomeDeleteModal()}
						{this.renderAddOutcomeFromTemplateInModal()}
						{this.renderUpdateProjectsModal()}
					</div>
				</div>
			</div>
		);
	}
}

ProductivityOutcomes.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityOutcomes
);