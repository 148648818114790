import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { setLoading } from "../../actions/loadingActions.js";

import Util from '../../utils/Util';
var _ = require('lodash');

class WeeklyNotes extends Component {

	constructor(props) {
		super(props);
		this.state = {
			notesEditorState: null,
			weeklyNotes: null,
		};
	}

	componentDidMount() {
		this.props.onRef(this)
	}

	componentWillReceiveProps(props) {

		if(!_.isEqual(this.state.weeklyNotes, props.weeklyNotes)) {

			this.setState({
				notesEditorState: props.weeklyNotes ? EditorState.createWithContent(convertFromRaw(props.weeklyNotes)) : EditorState.createEmpty(),
				weeklyNotes: props.weeklyNotes,
			});
		}
	};

	// Handler for submit
	handleSubmit() {

		// e.preventDefault();

		this.props.setLoading(true);

		const { notesEditorState } = this.state;

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		this.props.handleWeeklyNoteSaveChanges(notesContent);
	}

	// Render Weekly notes Section
	render() {

		return (
			<div>
				<Editor
					toolbar={{
						options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
					}}
					editorState={this.state.notesEditorState}
					placeholder="Enter your weekly note here..."
					toolbarClassName="toolbarClassName"
					wrapperClassName="wrapperClassName"
					editorClassName="editorClassName"
					onEditorStateChange={(editorState) =>
						this.setState({
							notesEditorState: editorState,
						})
					}
				/>
				{/* <button type="button" onClick={this.handleSubmit.bind(this)} className="mt-2 btn btn-primary btn-sm">Save</button> */}
			</div>
		);
	}
}

WeeklyNotes.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	WeeklyNotes
);
