import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import AddTask from "../tasks/AddTask";
import EditTask from "../tasks/EditTask";
import ViewTask from "../tasks/ViewTask";
import TaskStatus from "../tasks/TaskStatus";
import TaskFlags from "../tasks/TaskFlags";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TasksList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			tasks: [],
			itemToEdit: null,
			editTaskModal: false,
			itemToView: null,
			viewTaskModal: false,
			hideCompleted: true,
			addTaskModal: false,
			itemToUpdate: null
		};
	}

	componentDidMount() {
		this.getUserTasks();
	}

	/*
	- In the past, we needed the parent component (KeyActivity) to get updated to have the updated taskSequence.
	- Now with the universal ranking concept, the backend will take care of this update. Hence commenting the below code to avoid conflicts.

	componentDidMount() {
		if (this.props.isEdit) {
			this.getUserTasks();
		}
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		if (this.props.values.length !== prevProps.values.length) {
			this.setState({
				tasks: this.props.values
			});
		}
	}*/

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUserTasks() {

		this.props.setLoading(true);

		var postObj = {
			"keyActivityID": this.props.keyActivityID,
		};

		API_Services.httpPOST(UrlConstants.URLS.getUserTasks, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						tasks: response.data.data
					});
				} else {
					// this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Handler for hide completed event
	handleHideCompleted(event) {

		let { name, value } = event.target;

		value = (!value || value == "false") ? true : false;

		this.setState({
			hideCompleted: value,
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing task
	// Add API here so we can handle state even better
	handleTaskDelete() {

		var { tasks, itemToRemove } = this.state;

		if (itemToRemove) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove,
			}

			API_Services.httpPOST(UrlConstants.URLS.deleteTask, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} else if (response.data) {

					if (response.data.status) {

						tasks.forEach((eachTask, index) => {

							if (eachTask.id == itemToRemove) {
								tasks.splice(index, 1);
							}
						})

						this.props.removeTask(itemToRemove);

						this.setState({
							deleteModal: false,
							itemToRemove: null,
							tasks: tasks,
						}, () => {
							this.props.setLoading(false);
						});
					} else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}
	}

	// Show or hide edit modal
	toggleEditTaskModal(rowId) {

		if (this.state.itemToEdit) {
			this.getUserTasks();
			this.setState({
				editTaskModal: false,
				itemToEdit: null,
			});
		} else {
			this.setState({
				editTaskModal: true,
				itemToEdit: rowId,
			});
		}
	}

	// update the list with the edited data
	updateEditedTaskInList(data) {

		var newData = [...this.state.tasks, data];

		this.setState({
			editTaskModal: false,
			tasks: newData,
		});
	}

	// Render edit modal
	renderEditTaskInModal() {

		return (
			<div>
				<Modal isOpen={this.state.editTaskModal} toggle={this.toggleEditTaskModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleEditTaskModal.bind(this)}>Edit Task </ModalHeader>
					<ModalBody>
						<EditTask
							updateEditedTaskInList={this.updateEditedTaskInList.bind(this)}
							taskID={this.state.itemToEdit}
							keyActivityID={this.props.keyActivityID} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	// Show or hide view modal
	toggleViewTaskModal(rowId) {

		if (this.state.itemToView) {
			this.setState({
				viewTaskModal: false,
				itemToView: null,
			});
		} else {
			this.setState({
				viewTaskModal: true,
				itemToView: rowId,
			});
		}
	}

	// Render view modal
	renderViewTaskInModal() {

		return (
			<div>
				<Modal isOpen={this.state.viewTaskModal} toggle={this.toggleViewTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleViewTaskModal.bind(this)}>View Task </ModalHeader>
					<ModalBody>
						<ViewTask taskID={this.state.itemToView} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	onTaskUpdate(updatedTaskObj) {

		const { tasks } = this.state;
		var taskObjIndex = _.findIndex(tasks, { id: updatedTaskObj.id });
		
		if (taskObjIndex > -1) {
			tasks[taskObjIndex] = updatedTaskObj;
			this.setState({tasks: tasks});
		};
	}

	handleOnDragEnd(result) {

		if (!result.source || !result.destination || !result.draggableId) return;
		if (result.source.index == result.destination.index) return; // No change happened.

		this.props.setLoading(true);

		let postObj = {
			changeItem: result.draggableId,
			sourceIndex: result.source.index,
			destinationIndex: result.destination.index,
			filterSequence: _.map(this.state.tasks, 'id'),
			keyActivityID: this.props.keyActivityID
		};

		API_Services.httpPOST(UrlConstants.URLS.updateTasksRanks, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
				this.props.setLoading(false);
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					
					let tasks = this.state.tasks;
					let updatedSequence = response.data.data.tasksSequence || [];

					tasks = _.sortBy(tasks, function(item) {
						return updatedSequence.indexOf(item.id);
					});

					this.setState({
						tasks: tasks
					},
					() => {
						this.props.updateTasksSequence(updatedSequence);
						this.props.setLoading(false);
					});
				} 
				else {
					this.props.setLoading(false);
					this.setApiResponse(response.data.message, true);
				}
			} 
			else {
				this.props.setLoading(false);
				this.setApiResponse("Something went wrong!", true);
			}
		});
	}

	toggleAddTaskModal(itemToUpdate) {

		if (this.state.addTaskModal) {
			this.setState({
				itemToUpdate: null,
				itemPosition: null,
				relativePosition: null,
				addTaskModal: false,
			});
		} 
		else {
			this.setState({
				addTaskModal: true,
				itemToUpdate: itemToUpdate ? itemToUpdate : null,
				itemPosition: !itemToUpdate ? 1 : null // Allowing users to add tasks using top + button. The tasks added using this button will be positioned at top by default.
			});
		}
	}

	addTaskInList(itemPosition, newTask) {

		let { tasks } = this.state;
		itemPosition = itemPosition ? parseInt(itemPosition)-1 : 0; // The newly added task will be added at the top by default.					
			
		if (itemPosition > tasks.length) {
			itemPosition = tasks.length;
		};
		tasks.splice(itemPosition, 0, newTask); // Add tasks at the required position.

		this.setState({tasks: tasks});
		this.toggleAddTaskModal();
	}

	renderAddTaskInModal() {

		var tObj = this.state.itemToUpdate ? _.find(this.state.tasks, { id: this.state.itemToUpdate }) : null;
		var tObjIndex = this.state.itemToUpdate ? _.findIndex(this.state.tasks, { id: this.state.itemToUpdate }) : -1;
		var tTitle = tObj ? tObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
					{this.state.itemPosition ?
						<div>
							<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Add Task</ModalHeader>
							<ModalBody>
								<AddTask
									itemPosition={this.state.itemPosition}
									refItem={this.state.itemToUpdate}
									relativePosition={this.state.relativePosition}
									addTaskInList={this.addTaskInList.bind(this)}
									keyActivityID={this.props.keyActivityID} />
							</ModalBody>
						</div>
					:
						<div>
							<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Choose Position</ModalHeader>
							<ModalBody>
								<div className="d-flex justify-content-around align-items-center">
									<button className="btn btn-primary btn-sm" title="Above" onClick={() => this.setState({itemPosition: tObjIndex+1, relativePosition: 'above'})}>Above This Task</button>
									<span className="ml-2 mr-2">[OR]</span>
									<button className="btn btn-primary btn-sm" title="Below" onClick={() => this.setState({itemPosition: tObjIndex+2, relativePosition: 'below'})}>Below This Task</button>
								</div>
							</ModalBody>
						</div>
					}
				</Modal>
			</div>
		)
	}

	renderTaskDeleteModal() {

		var tObj = _.find(this.state.tasks, { id: this.state.itemToRemove });
		var tTitle = tObj ? tObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Task</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the task <span className="text-warning">{tTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleTaskDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	renderTotalTimeEstimates() {
		
		let totalTimeEstimates = 0;
		let tasks = this.state.hideCompleted ? _.filter(this.state.tasks, (item) => !item.isCompleted) : this.state.tasks;

		if (tasks.length > 0) {
			totalTimeEstimates = _.sumBy(tasks, 'timeEstimateInMinutes');
			return <span>Total Time Estimate: <span style={{fontFamily: 'Pangram-Bold'}}>{Util.convertTimeToReadableText(totalTimeEstimates)}</span></span>;
		};
		return null;
	}

	// Renders tasks in table
	renderTasksTable() {

		const { tasks, hideCompleted } = this.state;

		if (!this.props.isEdit) {
			return (
				<div className="small text-info">Please save your key activity before creating Tasks.</div>
			);
		}
		else if (tasks.length == 0) {
			return (
				<div className="small text-info">No Tasks added.</div>
			);
		};

		return (
			<div>
				<div className="custom-control custom-switch">
					<input type="checkbox" onChange={this.handleHideCompleted.bind(this)} className="custom-control-input" name="hideCompleted" value={hideCompleted} id="hideCompleted" checked={hideCompleted ? "checked" : ""} />
					<label className="custom-control-label" htmlFor="hideCompleted">Hide Completed</label>
				</div>
				<div className="App" style={{ marginTop: 20 }}>
					<header className="App-header table-responsive">
						<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
							<Droppable droppableId="tasks">
								{(provided) => (
									<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
										<thead>
											<tr>
												<th className="text-center" style={{ width: 50 }}></th>
												{/* <th className="text-center" style={{ width: 50 }}>#</th> */}
												<th className="text-center" style={{ width: 50 }}>Status</th>
												<th className="text-left" style={{ width: 500 }}>Task</th>
												<th className="text-center" style={{ width: 125 }}>Time Estimate</th>
												<th className="text-center" style={{ width: 50 }}>Flag</th>
												<th className="text-center" style={{ width: 100 }} colSpan={4}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{tasks.map((eachtask, index) => {
												return (
													<Draggable key={eachtask.id} draggableId={eachtask.id} index={index}>
														{(provided, snapshot) => (
															<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={(snapshot.isDragging ? "dragging-row" : "") + ((eachtask.isCompleted && hideCompleted) ? " d-none" : "")}>
																<td className="text-center" style={{ width: 50 }}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
																{/* <td className="text-center" style={{ width: 50 }}>{index + 1}</td> */}
																<td className="text-center" style={{ width: 50 }}>
																	<TaskStatus 
																		taskObj={eachtask} 
																		setApiResponse={this.setApiResponse.bind(this)}
																		onTaskUpdate={this.onTaskUpdate.bind(this)} />
																</td>
																<td className="text-left" style={{ width: 500 }}>{eachtask.task}</td>
																<td className="text-center" style={{ width: 125 }}>{Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)}</td>
																<td className="text-center" style={{ width: 50 }}>
																	<TaskFlags 
																		taskObj={eachtask} 
																		setApiResponse={this.setApiResponse.bind(this)}
																		onTaskUpdate={this.onTaskUpdate.bind(this)} />
																</td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="Add task above or below" onClick={this.toggleAddTaskModal.bind(this, eachtask.id)}><i className="fa fa-plus color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="View" onClick={this.toggleViewTaskModal.bind(this, eachtask.id)}><i className="fa fa-eye color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleEditTaskModal.bind(this, eachtask.id)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, eachtask.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
															</tr>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
										</tbody>
									</table>
								)}
							</Droppable>
						</DragDropContext>
						{this.renderEditTaskInModal()}
						{this.renderViewTaskInModal()}
						{this.renderTaskDeleteModal()}
					</header>
				</div>
			</div>
		);
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					<div className="row">
						<div className="col-6"><label>Tasks</label></div>
						<div className="col-6 text-right">
							{this.props.isEdit ? this.renderTotalTimeEstimates() : null}
							<button type="button" className="btn btn-primary btn-xs ml-3" onClick={this.toggleAddTaskModal.bind(this, null)} disabled={this.props.isEdit ? false : true}>
								<i className="fa fa-plus" aria-hidden="true"></i>
							</button>
						</div>
					</div>
					{this.renderTasksTable()}
					{this.renderAddTaskInModal()}
				</div>
			</div>
		);
	}
}

TasksList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	tasks: state.tasks,
});

export default connect(mapStateToProps, { setLoading })(
	TasksList
);
