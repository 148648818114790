import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonGroup } from 'reactstrap';
import { history } from '../../utils/history';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

import ProductivityOutcomes from '../productivity-planner/ProductivityOutcomes';
import PlanningModules from '../productivity-planner/PlanningModules';

var moment = require('moment');
var UrlConstants = require('../../utils/UrlConstants');

const DEFAULT_ACTIVE_TAB = "outcomes"; // Default section

class ProductivityPlannerHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeMenu: null,
			initialAPICheck: false,
			planningModules: [],
		};
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	getPlanningModulesForTheUser() {

		this.props.setLoading(true);

		let postObj = {

		};

		API_Services.httpPOST(UrlConstants.URLS.getPlanningModulesForTheUser, postObj, (err, response) => {

			if (err) {

				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				}
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			}
			else if (response.data) {

				if (response.data.status && response.data.data) {

					this.setState({
						initialAPICheck: true,
						planningModules: response.data.data,
					})
				};
			}
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	componentDidMount() {

		this.getPlanningModulesForTheUser();

		const active_tab = this.props.match.params.active_tab;

		if (!active_tab) {
			this.setState({
				activeMenu: DEFAULT_ACTIVE_TAB,
			});
		}
		else {
			this.setState({
				activeMenu: active_tab,
			});
		}
	}

	handleSwitchTabs(tabSlug) {

		this.getPlanningModulesForTheUser();

		this.setState({
			activeMenu: tabSlug,
		}, () => {
			history.push(`/productivity-planner/${tabSlug}`);
		})
	}

	setApiResponse(message, hasError) {

		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	renderPlanningModulesTab() {

		const { activeMenu, planningModules } = this.state;

		let activeClass = "pbh-small-btn-active";
		let defaultClass = "pbh-small-btn-default";

		if (planningModules && planningModules.length == 0) {
			return;
		}

		return planningModules.map((eachModule) => {

			let refID = eachModule.refID;

			return (
				<Button key={refID} onClick={this.handleSwitchTabs.bind(this, refID)} className={(activeMenu == refID ? activeClass : defaultClass)} style={{ fontSize: 14, minWidth: 205 }}>{eachModule.title}</Button>
			)
		})
	}

	renderMenuButtons() {

		const { activeMenu } = this.state;

		let activeClass = "pbh-small-btn-active";
		let defaultClass = "pbh-small-btn-default";

		return (
			<div className="text-center" style={{ marginBottom: 15 }}>
				<ButtonGroup>
					<Button onClick={this.handleSwitchTabs.bind(this, 'outcomes')} className={(activeMenu == 'outcomes' ? activeClass : defaultClass)} style={{ fontSize: 14, minWidth: 205 }}>Outcomes</Button>
					{this.renderPlanningModulesTab()}
				</ButtonGroup>
			</div>
		);
	}

	renderActiveMenu() {

		const { activeMenu, planningModules } = this.state;

		if (!activeMenu) {
			return <div className="small">Do you have a valid activation code for unlocking Productivity Planner? Please enter it <a href='/subscription?module=productivity_planner' className='color-primary'>here</a>.</div>
		}
		else if (activeMenu == 'outcomes') {
			return <ProductivityOutcomes />;
		}
		else {

			if (planningModules && planningModules.length == 0) {
				return;
			}

			let currentPlanningModule = {};

			planningModules.map((eachModule) => {

				if (eachModule.refID === activeMenu) {
					currentPlanningModule = eachModule;
				}
			});

			return <PlanningModules
				currentPlanningModule={currentPlanningModule}
			/>;
		};
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/productivity-planner/">Productivity Planner</a>
				</div>
				{
					this.state.initialAPICheck
						?
						<div className="section-container">
							<div className="form-container">
								{this.renderMenuButtons()}
								{this.renderActiveMenu()}
							</div>
						</div>
						:
						<div />
				}
			</div>
		);
	}
}

ProductivityPlannerHome.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityPlannerHome
);

const styles = {

}