export default {

    selectBoxStyles: {
        menu: (provided) => ({ 
            ...provided, 
            zIndex: 9999 
        }),
        option: (provided, state) => ({
            ...provided,
            '&:hover': {
                backgroundColor: '#FFF5ED',
                color: '#737373'
            },
            backgroundColor: state.isSelected ? '#F79569' : '#FFF',
            color: state.isSelected ? '#FFF' : '#737373',
            outline: 'none',
        }),
        control: (base, state) => ({
            ...base,
            border: '1px solid #ced4da !important',
            boxShadow: 'none !important',
            minHeight: '42px',
            overflow: 'auto',
            backgroundColor: state.isDisabled ? '#f6f6f6' : '#fff'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#b2b2b2',
            fontFamily: 'Pangram-Light',
            fontSize: 14
        })
    },
    titleLink: {
		color: '#f79569'
	},
    subTitleLink: {
        color: 'rgb(247, 149, 105)'
	},
	zoomTitle: {
		margin: '3px 5px 3px 15px'
	},
	zoomBoxStyle: {
		height: 31,
		width: 50,
		textAlign: 'center',
		border: '1px solid #f79569',
    	fontFamily: 'Pangram-Light'
	},
    questionCount: {
        marginBottom: '-10px',
        paddingTop: '25px',
        color: '#737373'
    },
    tableHeading: {
        textAlign:'center',
        padding: '8px',
        fontSize:'14px',
        lineHeight: 1.5,
        fontFamily:'Pangram-Regular',
        backgroundColor: '#dfdfdf',
        minWidth: 60
    },
    tableSubHeading: {
        borderRadius: 0,
        borderTop: '1px solid #d2d0d0',
        minWidth: 150
    },
    compassFAReactSelectStyles: {
        menu: provided => ({ ...provided, zIndex: 999 }), 
        menuPortal: base => ({ ...base, zIndex: 999 }), 
        option: (provided, state) => ({
          ...provided,
          '&:hover': {
              backgroundColor: '#FFF5ED',
              color: '#737373'
          },
          backgroundColor: state.isSelected ? '#F79569' : '#FFF',
          color: state.isSelected ? '#FFF' : '#737373',
          outline: 'none',
          fontSize: 12,
          fontFamily: "Pangram-Regular"
        }),
        control: (base, state) => ({
            ...base,
            outline: 'none !important',
            border: '1px solid #cccccc !important',
            boxShadow: 'none !important',
            height: '100%',
            overflow: 'auto',
            fontSize: 12,
            fontFamily: "Pangram-Regular"
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#b2b2b2',
            fontFamily: 'Pangram-Light',
            fontSize: 14
        })
    }
};