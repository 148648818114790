var Globals = require('./Globals');

var baseUrl = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;

module.exports = {

	URLS: {

		/* Onboarding & Auth */
		webSignUp: baseUrl + 'User/webSignUp/',
		webLogin: baseUrl + 'User/webLogin/',
		logout: baseUrl + 'User/logout/',
		generateRecoveryEmail: baseUrl + 'User/generateRecoveryEmail/',
		resetPassword: baseUrl + 'User/resetPassword/',

		/* Current Sprint */
		getCurrentSprintQuestions: baseUrl + 'CurrentSprint/getCurrentSprintQuestions',
		getCurrentSprintForTheUser: baseUrl + 'CurrentSprint/getCurrentSprintForTheUser/',
		addCurrentSprint: baseUrl + 'CurrentSprint/addCurrentSprint/',
		editCurrentSprint: baseUrl + 'CurrentSprint/editCurrentSprint/',
		removeCurrentSprint: baseUrl + 'CurrentSprint/removeCurrentSprint/',

		/* Guiding Stars */
		getIndividualTimescaleResponses: baseUrl + 'GuidingStars/getIndividualTimescaleResponses',
		saveTimescaleResponse: baseUrl + 'GuidingStars/saveTimescaleResponse',
		
		/* Compass */
		getIndividualCompassResponses: baseUrl + 'GuidingStars/getIndividualCompassResponses',
		saveCompassResponse: baseUrl + 'GuidingStars/saveCompassResponse',
		archiveCompass: baseUrl + 'GuidingStars/archiveCompass',
		getCompassResponseByID: baseUrl + 'GuidingStars/getCompassResponseByID',
		switchCurrentCompass: baseUrl + 'GuidingStars/switchCurrentCompass',

		/* Focus Area */
		getFocusAreaQuestions: baseUrl + 'FocusArea/getFocusAreaQuestions/',
		getFocusAreaByID: baseUrl + 'FocusArea/getFocusAreaByID/',
		getFocusAreasForTheUser: baseUrl + 'FocusArea/getFocusAreasForTheUser/',
		searchFocusAreasForTheUser: baseUrl + 'FocusArea/searchFocusAreasForTheUser/',
		addFocusAreaResponse: baseUrl + 'FocusArea/addFocusAreaResponse/',
		editFocusAreaResponse: baseUrl + 'FocusArea/editFocusAreaResponse/',
		removeFocusArea: baseUrl + 'FocusArea/deleteFocusAreaResponse/',
		updateFocusAreasRanks: baseUrl + 'FocusArea/updateFocusAreasRanks/',

		// Focus Area APIs for the 'Guiding Stars' module.
		getFocusAreaTitleOptions: baseUrl + 'FocusArea/getFocusAreaTitleOptions/',
		addFocusAreaForTheTimescale: baseUrl + 'FocusArea/addFocusAreaForTheTimescale/',
		editFocusAreaResponseForTheTimescale: baseUrl + 'FocusArea/editFocusAreaResponseForTheTimescale/',
		deleteFocusAreaResponseForTheTimescale: baseUrl + 'FocusArea/deleteFocusAreaResponseForTheTimescale/',
		updateUniversalRanksOfTheFocusAreas: baseUrl + 'FocusArea/updateUniversalRanksOfTheFocusAreas/',

		/* Outcome */
		getOutcomeDetailsByID: baseUrl + 'Outcomes/getOutcomeDetailsByID/',
		getUserOutcomes: baseUrl + 'Outcomes/getUserOutcomes/',
		getOutcomesWithPopulatedData: baseUrl + 'Outcomes/getOutcomesWithPopulatedData/',
		addNewOutcome: baseUrl + 'Outcomes/addNewOutcome/',
		editOutcome: baseUrl + 'Outcomes/editOutcome/',
		removeOutcome: baseUrl + 'Outcomes/deleteOutcome/',
		updateOutcomesRanks: baseUrl + 'Outcomes/updateOutcomesRanks/',

		/* Roles And Responsibility */
		getRolesAndResponsibilityByID: baseUrl + 'RolesAndResponsibilities/getRolesAndResponsibilityByID/',
		getRolesAndResponsibilitiesForTheUser: baseUrl + 'RolesAndResponsibilities/getUserRolesAndResponsibilities/',
		addRolesAndResponsibility: baseUrl + 'RolesAndResponsibilities/addNew/',

		/* Collaborators */
		getCollaboratorByID: baseUrl + 'Collaborators/getCollaboratorByID/',
		getCollaboratorsForTheUser: baseUrl + 'Collaborators/getUserCollaborators/',
		addCollaborator: baseUrl + 'Collaborators/addNew/',

		/* KeyActivity */
		addNewKeyActivity: baseUrl + 'KeyActivities/addNewKeyActivity/',
		getUserKeyActivities: baseUrl + 'KeyActivities/getUserKeyActivities/',
		getKeyActivityByID: baseUrl + 'KeyActivities/getKeyActivityByID/',
		updateKeyActivity: baseUrl + 'KeyActivities/updateKeyActivity/',
		updateKeyActivitiesRanks: baseUrl + 'Outcomes/updateKeyActivitySequenceForTheOutcome/',
		deleteKeyActivity: baseUrl + 'KeyActivities/deleteKeyActivity/',

		/* TimeBlock */
		addNewTimeBlock: baseUrl + 'TimeBlocks/addNewTimeBlock/',
		getUserTimeBlocks: baseUrl + 'TimeBlocks/getUserTimeBlocks/',
		getTimeBlockByID: baseUrl + 'TimeBlocks/getTimeBlockByID/',
		updateTimeBlocks: baseUrl + 'TimeBlocks/updateTimeBlocks/',
		deleteTimeBlock: baseUrl + 'TimeBlocks/deleteTimeBlock/',
		getRecurringInstancesOfTheEvent: baseUrl + 'TimeBlocks/getRecurringInstancesOfTheEvent',
		adjustRecurringIntervalAsPerStartDate: baseUrl + 'TimeBlocks/adjustRecurringIntervalAsPerStartDate',

		/* Metrics */
		getUserKeyActivityMetrics: baseUrl + 'MetricsAndTargets/getUserKeyActivityMetrics',
		getKAMetricByID: baseUrl + 'MetricsAndTargets/getKAMetricByID',
		addUserKeyActivityMetric: baseUrl + 'MetricsAndTargets/createOrUpdateKeyActivityMetrics',
		editUserKeyActivityMetric: baseUrl + 'MetricsAndTargets/createOrUpdateKeyActivityMetrics',
		deleteUserKeyActivityMetric: baseUrl + 'MetricsAndTargets/deleteKeyActivityMetrics',
		getUserProjectMetrics: baseUrl + 'MetricsAndTargets/getUserOutcomeMetrics',
		getProjectMetricByID: baseUrl + 'MetricsAndTargets/getProjectMetricByID',
		addUserProjectMetric: baseUrl + 'MetricsAndTargets/createOrUpdateOutcomeMetrics',
		editUserProjectMetric: baseUrl + 'MetricsAndTargets/createOrUpdateOutcomeMetrics',
		deleteUserProjectMetric: baseUrl + 'MetricsAndTargets/deleteOutcomeMetrics',

		/* Task */
		addNewTask: baseUrl + 'Tasks/addNewTask/',
		getUserTasks: baseUrl + 'Tasks/getUserTasks/',
		getTaskByID: baseUrl + 'Tasks/getTasksByID/',
		getUTasks: baseUrl + 'Tasks/getUniversalTasks/',
		updateTask: baseUrl + 'Tasks/updateTask/',
		updateTasksRanks: baseUrl + 'KeyActivities/updateTaskSequenceForTheKeyActivity/',
		updateUTasksRanks: baseUrl + 'Tasks/updateUniversalRanksOfTheTasks/',
		deleteTask: baseUrl + 'Tasks/deleteTask/',

		/* AvailableTimeEstimates */
		addAvailableTimeEstimate: baseUrl + 'AvailableTimeEstimates/addAvailableTimeEstimate/',
		editAvailableTimeEstimate: baseUrl + 'AvailableTimeEstimates/editAvailableTimeEstimate/',
		getAvailableTimeEstimatesForTheUser: baseUrl + 'AvailableTimeEstimates/getAvailableTimeEstimatesForTheUser/',

		/* Time Allocation */
		getTimeAllocationForTheUser: baseUrl + 'TimeAllocation/getTimeAllocationForTheUser/',

		/* Monthly Review */
		getMonthlyReviewForTheTargetMonth: baseUrl + 'MonthlyReview/getMonthlyReviewForTheTargetMonth',
		updateOrCreateMonthlyReview: baseUrl + 'MonthlyReview/updateOrCreateMonthlyReview',

		/* Weekly plan */
		getWeeklyPlanForTheUser: baseUrl + 'WeeklyPlan/getWeeklyPlanForTheUser',
		createOrUpdateWeeklyPlan: baseUrl + 'WeeklyPlan/createOrUpdateWeeklyPlan',
		clearAllWeeklyPlanEventsInGC: baseUrl + 'WeeklyPlan/clearAllWeeklyPlanEventsInGC',
		updateNewlyAssignedPBHProperties: baseUrl + 'WeeklyPlan/updateNewlyAssignedPBHProperties',

		/* Tracker */
		getTrackerForTheWeek: baseUrl + 'Tracker/getTrackerForTheWeek',
		resetTrackerForTheWeek: baseUrl + 'Tracker/resetTrackerForTheWeek',
		updateOrCreateTrackerForTheWeek: baseUrl + 'Tracker/updateOrCreateTrackerForTheWeek',
		getSavedTrackerWeeks: baseUrl + 'Tracker/getSavedTrackerWeeks',

		/* Daily Tracker */
		getTrackerForTheDate: baseUrl + 'DailyTracker/getTrackerForTheDate',
		saveDailyTrackerResponse: baseUrl + 'DailyTracker/saveDailyTrackerResponse',

		/* TrackerReport */
		getTrackerReportForTheWeek: baseUrl + 'TrackerReport/getTrackerReportForTheWeek',

		/* Note */
		getNoteInfoByID: baseUrl + 'Notes/getNoteInfoByID/',
		getUserNotes: baseUrl + 'Notes/getUserNotes/',
		addNewNote: baseUrl + 'Notes/addNewNote/',
		updateNote: baseUrl + 'Notes/updateNote/',
		deleteNote: baseUrl + 'Notes/deleteNote/',

		/* WeeklyReview */
		getWeeklyReviewForTheTargetWeek: baseUrl + 'WeeklyReview/getWeeklyReviewForTheTargetWeek',
		updateOrCreateWeeklyReview: baseUrl + 'WeeklyReview/updateOrCreateWeeklyReview',
		
		/* DailyReview */
		getDailyReviewForTheDate: baseUrl + 'DailyReview/getDailyReviewForTheDate',
		updateOrCreateDailyReview: baseUrl + 'DailyReview/updateOrCreateDailyReview',

		/* Other */
		getPlatformConstants: baseUrl + 'PlatformData/getPlatformConstants/',

		/* Super Admin */
		verifyAccess: baseUrl + 'SuperAdmin/verifyAccess',
		fetchAppModules: baseUrl + 'AppModules/fetchAppModules',
		updateModule: baseUrl + 'AppModules/updateModule',
		fetchActivationCodes: baseUrl + 'ActivationCodes/fetchActivationCodes',
		createOrUpdateActivationCode: baseUrl + 'ActivationCodes/createOrUpdateActivationCode',
		deleteActivationCode: baseUrl + 'ActivationCodes/deleteActivationCode',
		fetchMigrationScriptsList: baseUrl + 'SuperAdmin/fetchMigrationScriptsList',
		executeMigrationScripts: baseUrl + 'SuperAdmin/executeMigrationScripts',
		getSystemTemplates: baseUrl + 'Templates/getSystemTemplates',
		createOrUpdateSystemTemplate: baseUrl + 'Templates/createOrUpdateSystemTemplate',
		deleteSystemTemplate: baseUrl + 'Templates/deleteSystemTemplate',

		/* IIA */
		getAssessmentPrompts: baseUrl + 'InnerInsights/getAssessmentPrompts/',
		saveAssessmentResponse: baseUrl + 'InnerInsights/saveAssessmentResponse/',
		getInnerInsightsReport: baseUrl + 'InnerInsights/getInnerInsightsReport/',

		/* Productivity Meter */
		getProductivityMeterPrompts: baseUrl + 'ProductivityMeter/getAssessmentPrompts/',
		saveProductivityMeterResponse: baseUrl + 'ProductivityMeter/saveAssessmentResponse/',
		getProductivityMeterReport: baseUrl + 'ProductivityMeter/getProductivityMeterReport/',
		exploreNextSteps: baseUrl + 'ProductivityMeter/exploreNextSteps',

		/* Subscriptions */
		subscribeUsingActivationCode: baseUrl + 'Subscriptions/subscribeUsingActivationCode',
		getUserSubscriptions: baseUrl + 'Subscriptions/getUserSubscriptions',

		/* Productivity planner */
		getPlanningModulesForTheUser: baseUrl + 'PlanningModules/getPlanningModulesForTheUser',
		savePlanningModuleResponse: baseUrl + 'PlanningModules/savePlanningModuleResponse',
		resetPlanningModuleResponse: baseUrl + 'PlanningModules/resetPlanningModuleResponse',
		getUserTemplates: baseUrl + 'Templates/getUserTemplates',
		getProductivityOutcomesForTheUser: baseUrl + 'ProductivityOutcomes/getProductivityOutcomesForTheUser',
		createOrEditOutcomeProjects: baseUrl + 'ProductivityOutcomes/createOrEditOutcomeProjects',
		createOutcomeFromTemplate: baseUrl + 'ProductivityOutcomes/createOutcomeFromTemplate',
		createOutcomeDefinition: baseUrl + 'ProductivityOutcomes/createOutcomeDefinition',
		updateOutcomeDefinition: baseUrl + 'ProductivityOutcomes/updateOutcomeDefinition',
		getProductivityOutcomeDetailsByID: baseUrl + 'ProductivityOutcomes/getProductivityOutcomeDetailsByID',
		deleteProductivityOutcome: baseUrl + 'ProductivityOutcomes/deleteProductivityOutcome',

		/* User - General */
		getUserPreferences: baseUrl + 'User/getUserPreferences',
		updateUserPreferences: baseUrl + 'User/updateUserPreferences',
		updateCalendarPreferenceNdMoveEventsToSelectedCalendar: baseUrl + 'User/updateCalendarPreferenceNdMoveEventsToSelectedCalendar',
		pullEventsFromSelectedGoogleCalendars: baseUrl + 'User/pullEventsFromSelectedGoogleCalendars',
		saveAuthToken: baseUrl + 'Integrations/saveAuthToken'
	},
};