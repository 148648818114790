import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class TimeAllocationTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			apiResponse: {
				className: "",
				message: "",
			},
			outcomes: [],
			defaultTimeBlockTypes: [],
			timeAllocationStats: {},
			outcomesWithKeyActivitiesVisible: [], // An Array that contains list of Outcome ID's. Based on this field, the KeyActivities for the Outcome will be displayed.
			showTable: true,
			tableDisplayView: 'simple',
			zoomValue: 100
		};
	}

	componentDidMount() {
		this.getTimeAllocationForTheUser();
	}

	getTimeAllocationForTheUser() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getTimeAllocationForTheUser, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					let data = response.data.data;

					this.setState({
						outcomes: data.outcomes,
						defaultTimeBlockTypes: data.defaultTimeBlockTypes ? data.defaultTimeBlockTypes.value : [],
						timeAllocationStats: data.timeAllocationStats ? data.timeAllocationStats : {},
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	checkKeyActivitiesVisibility(outcomeID) {
		const { outcomesWithKeyActivitiesVisible } = this.state;
		return outcomesWithKeyActivitiesVisible.indexOf(outcomeID) > -1;
	};

	toggleKeyActivitiesVisibility(outcomeID) {

		let { outcomesWithKeyActivitiesVisible } = this.state;
		let outcomeIDIndex = outcomesWithKeyActivitiesVisible.indexOf(outcomeID);

		if (outcomeIDIndex == -1) {
			outcomesWithKeyActivitiesVisible.push(outcomeID);
		}
		else {
			outcomesWithKeyActivitiesVisible.splice(outcomeIDIndex, 1);
		};
		this.setState({outcomesWithKeyActivitiesVisible});
	};

	toggleTableViewVisibility() {
		
		this.setState(prevState => ({
			tableDisplayView: prevState.tableDisplayView == 'simple' ? 'detailed' : 'simple',
		}));
	}

	handleZoomChange(action = null, newValue) {
		
		let { zoomValue } = this.state;

		if (action == 'zoomIn') {
			zoomValue -= 5;
		}
		else if (action == 'zoomOut') {
			zoomValue += 5;
		}
		else if (newValue) {
			zoomValue = parseInt(newValue);
		};

		this.setState({zoomValue});
	}

	renderHeading() {

		const { tableDisplayView, defaultTimeBlockTypes } = this.state;

		return (
			<thead>
				<tr>
					<th className="text-center" style={{ minWidth: 75 }}>#</th>
					<th className="text-center" style={{ minWidth: 75 }}>Include?</th>
					<th className="text-center" style={{ minWidth: 250 }} colSpan={2}>Project</th>
					{tableDisplayView == 'detailed' ?
						defaultTimeBlockTypes.map((eachType, index) => {
							return (
								<th key={index} className="text-center" style={{ minWidth: 150 }}>{eachType}</th>
							);
						})
					: null}
					<th className="text-center" style={{ minWidth: 200 }}>Total Time (per week)</th>
					<th className="text-center" style={{ minWidth: 100 }}>Buffer (%)</th>
					<th className="text-center" style={{ minWidth: 200 }}>Total Time (per week)</th>
				</tr>
			</thead>
		);
	}

	renderKeyActivitiesHeading(outcomeObj) {

		const { defaultTimeBlockTypes } = this.state;
		let activitiesVisibilityIcon = this.checkKeyActivitiesVisibility(outcomeObj.id) ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		return (
			<tr className="text-center" key={'key_activities_header_for_outcome_' + outcomeObj.id}>
				<td colSpan={2} className="cursor-pointer" onClick={() => this.toggleKeyActivitiesVisibility(outcomeObj.id)}><img src={activitiesVisibilityIcon} width="12" /></td>
				<td style={{color: '#666666'}}>Include?</td>
				<td style={{color: '#666666', minWidth: 300}}>Key Activity</td>
				<td colSpan={defaultTimeBlockTypes.length + 3}></td>
			</tr>
		);
	}

	renderKeyActivitiesRow(outcomeObj) {

		let keyActivities = outcomeObj.keyActivities || [];
		let keyActivitiesArray = [];

		if (this.checkKeyActivitiesVisibility(outcomeObj.id) == false) {
			return null;
		};

		keyActivities.map((activityObj, activityIndex) => {
			
			let activityIncludedInWeeklyPlan = activityObj.includeInWeeklyPlan ? "/images/available.svg" : "/images/not-available.svg";

			keyActivitiesArray.push (
				<tr className="text-center" key={activityObj.id} style={styles.activitiesRow}>
					<td colSpan={2}></td>
					<td><img src={activityIncludedInWeeklyPlan} width="15" /></td>
					<td><a href={'/key-activities/edit/' + activityObj.id + '?outcomeID=' + outcomeObj.id} target='_blank' style={SharedStyles.titleLink}>{activityObj.title}</a></td>
					{activityObj.timeAllocationStats ? 
						activityObj.timeAllocationStats.map((statObj, statIndex) => {
							return (
								<td key={"activity_" + activityObj.id + '_stat_' + statIndex}>
									<div>{statObj.totalDurationInMinutes > 0 ? Util.convertTimeToReadableText(statObj.totalDurationInMinutes) : ''}</div>
									<div>{statObj.totalSessions > 0 ? '[' + statObj.totalSessions + ' sessions]' : ''}</div>
								</td>
							);
						})
					: null}
					<td>{activityObj.totalDurationInMinutes > 0 ? Util.convertTimeToReadableText(activityObj.totalDurationInMinutes) : ''}</td>
					<td>{activityObj.totalDurationInMinutes > 0 ? outcomeObj.bufferInPercentage: ''}</td>
					<td>{activityObj.totalDurationWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(activityObj.totalDurationWithBufferInMinutes) : ''}</td>
				</tr>
			);
		});

		return keyActivitiesArray;
	}

	renderOutcomeRow(outcomeObj, outcomeIndex) {

		const { tableDisplayView, defaultTimeBlockTypes } = this.state;

		let associatedRows = [];
		let outcomeIncludedInWeeklyPlan = outcomeObj.includeInWeeklyPlan ? "/images/available.svg" : "/images/not-available.svg";

		associatedRows.push (
			<tr className="text-center" key={'outcome_' + outcomeIndex}>
				<td>{outcomeIndex + 1}</td>
				<td><img src={outcomeIncludedInWeeklyPlan} width="15" /></td>
				<td colSpan={2}><a href={'/projects/edit/' + outcomeObj.id} target='_blank' style={SharedStyles.titleLink}>{outcomeObj.outcomeTitle}</a></td>
				{tableDisplayView == 'detailed' && outcomeObj.timeAllocationStats ? 
					outcomeObj.timeAllocationStats.map((statObj, statIndex) => {
						return (
							<td key={"outcome_" + outcomeIndex + 'stat_' + statIndex}>
								<div>{statObj.totalDurationInMinutes > 0 ? Util.convertTimeToReadableText(statObj.totalDurationInMinutes) : ''}</div>
								<div>{statObj.totalSessions > 0 ? '[' + statObj.totalSessions + ' sessions]' : ''}</div>
							</td>
						);
					})
				: null}
				<td>{outcomeObj.totalDurationInMinutes > 0 ? Util.convertTimeToReadableText(outcomeObj.totalDurationInMinutes) : ''}</td>
				<td>{outcomeObj.totalDurationInMinutes > 0 ? outcomeObj.bufferInPercentage: ''}</td>
				<td>{outcomeObj.totalDurationWithBufferInMinutes > 0 ? Util.convertTimeToReadableText(outcomeObj.totalDurationWithBufferInMinutes) : ''}</td>
			</tr>
		);

		if (tableDisplayView == 'detailed') {
			associatedRows.push(this.renderKeyActivitiesHeading(outcomeObj));
			associatedRows.push(this.renderKeyActivitiesRow(outcomeObj));
		};
		
		return associatedRows;
	}

	renderSummaryRows() {

		const { tableDisplayView, timeAllocationStats, defaultTimeBlockTypes } = this.state;

		let colSpan = 3 + (tableDisplayView == 'detailed' ? defaultTimeBlockTypes.length : 0) + 3; // 3: (#, include?, outcome title)
		
		let differenceRowStyling = {
			backgroundColor: '#f6f6f6', 
			fontFamily: 'Pangram-Bold',
			color: timeAllocationStats.timeDifferenceInMinutes >= 0 ? '#35a344' : '#ed406e'
		};

		let associatedRows = [
			<tr key={'required_total'} style={{backgroundColor: '#f6f6f6'}}>
				<td className="text-right" colSpan={colSpan}>Required Total</td>
				<td className="text-center">{Util.convertTimeToReadableText(timeAllocationStats.totalRequiredTimeInMinutes)}</td>
			</tr>,
			<tr key={'available_time'} style={{backgroundColor: '#f6f6f6'}}>
				<td className="text-right" colSpan={colSpan}>Available Time</td>
				<td className="text-center">{Util.convertTimeToReadableText(timeAllocationStats.availableTimeInMinutes)}</td>
			</tr>,
			<tr key={'total_difference'} style={differenceRowStyling}>
				<td className="text-right" colSpan={colSpan}>Difference</td>
				<td className="text-center">{Util.convertTimeToReadableText(timeAllocationStats.timeDifferenceInMinutes)}</td>
			</tr>
		];

		return associatedRows;
	}

	renderTable() {

		const { zoomValue, outcomes } = this.state;
		const tableStyle = {
			transform: 'scale(' + (zoomValue/100) + ')',
			transformOrigin: 'top left'
		};

		if (outcomes.length == 0) {
			return(
				<div className="text-info">No Projects added. Please add an project to access this section.</div>
			);
		};

		return (
			<div>
				<div className="d-flex justify-content-end">
					<button type="button" onClick={this.toggleTableViewVisibility.bind(this)} className="btn btn-primary btn-sm mb-4">Simplified / Detailed View</button>
					<div className="d-flex align-content-start">
						<span style={SharedStyles.zoomTitle}>Zoom (%)</span>
						<button type="button" onClick={this.handleZoomChange.bind(this, 'zoomIn', null)} className="btn btn-primary btn-sm mb-4 border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>-</button>
						<input type="number" value={zoomValue} onChange={(e) => this.handleZoomChange(null, e.target.value)} style={SharedStyles.zoomBoxStyle} />
						<button type="button" onClick={this.handleZoomChange.bind(this, 'zoomOut', null)} className="btn btn-primary btn-sm mb-4 border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>+</button>
					</div>
				</div>
				<div className="table-responsive vertical-center">
					<table className="table table-curved" style={tableStyle}>
						{this.renderHeading()}
						<tbody>
							{outcomes.map((outcomeObj, outcomeIndex) => {
								return this.renderOutcomeRow(outcomeObj, outcomeIndex);
							})}
							{this.renderSummaryRows()}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	render() {

		const { showTable, apiResponse } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<NavLink to="/time-commitment">Time Commitment</NavLink>&nbsp;
				</div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								{showTable ? this.renderTable() : null}
								{ !_.isEmpty(apiResponse) ?
									<p className={apiResponse.className}><span>{apiResponse.message}</span></p>
								: null }
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TimeAllocationTable.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	TimeAllocationTable
);

const styles = {

	activitiesRow: {
		color: '#737373', 
		fontFamily: 'Pangram-Light'
	}
}