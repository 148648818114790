import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';
import moment from "moment";
import momentTimezone from "moment-timezone";
import { v4 as uuidV4 } from 'uuid';
import { Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import YourCommitments from './YourCommitments';
import DragableTimeBlocks from './DragableTimeBlocks';
import UnplannedTimeBlocks from './UnplannedTimeBlocks';
import Calendar from './Calendar';
import CustomEvent from './CustomEvent';
import WeeklyPlanCalendarToolBar from './WeeklyPlanCalendarToolBar';
import { getUserOutcomesWithPopulatedData } from "../../actions/outcomeActions";
import DetailedInfoPopup from './DetailedInfoPopup';
import Util from '../../utils/Util';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class WeeklyPlanScheduler extends Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			timeBlocks: [],
			unplannedTimeBlocks: [],
			apiResponse: {
				className: "",
				message: "",
			},
			weeklyPlanID: null,
			buttonDisabled: false,
			userPreference: { // default preferences
				hasEnabledCalendarSync: false,
				selectedCalendar: null,
			},
			excludedGoogleCalendarIDs: [],
			showDetailedInfoModal: false
		};

		this.scrollToTime = new Date();
		this.scrollToTime.setHours(8, 0, 0);

		this.getWeeklyPlanForTheUser();
		this.getUserPreferences();

		this.getFocusAreas();
		this.getOutcomesWithPopulatedData();
	}

	// Get User Preferences
	getUserPreferences() {

		API_Services.httpGET(UrlConstants.URLS.getUserPreferences, (err, response) => {

			if (err) {
				
        if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true);
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					let userPreference = response.data.data;
					let GCMetaData = response.data.metaData;
					let calendarSyncPreference = userPreference && userPreference.hasEnabledCalendarSync || false;
					let currentSyncStatus = GCMetaData && GCMetaData.syncStatus || false;

					if (GCMetaData && GCMetaData.message) {
						alert(GCMetaData.message);
					};

					this.setState({
						userPreference: userPreference,
						excludedGoogleCalendarIDs: userPreference && userPreference.excludedGoogleCalendarIDs || [],
						GCMetaData: GCMetaData,
						GoogleCalendarCurrentSyncStatus: calendarSyncPreference && currentSyncStatus || false
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
		});
	}

	getOutcomesWithPopulatedData() {

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					this.setState({
						outcomesWithPopulatedData: response.data.data,
					});

					this.props.getUserOutcomesWithPopulatedData(response);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

		});
	}

	// Get focus areas for the user
	getFocusAreas() {

		API_Services.httpGET(UrlConstants.URLS.getFocusAreasForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setState({
						focusAreas: response.data.data,
					});
				} else {
					this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

		});
	}

	updateNewlyAssignedPBHProperties = (updatedEvent) => {

		var params = {
			updatedEvent: updatedEvent
		};

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateNewlyAssignedPBHProperties, params, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true);
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
				this.props.setLoading(false);
			} 
			else if (response.data) {

				var { events = [], unplannedTimeBlocks = [] } = this.state;
				var PBHAttributes = ['outcomeID', 'outcomeAbbr', 'outcomeTitle', 'keyActivityID', 'keyActivityTitle', 'focusAreaID', 'focusAreaTitle', 'timeBlockID', 'timeBlockTitle', 'isUnAssigned', 'metricsAndTargets'];
				var updatedData = _.pick(updatedEvent, PBHAttributes);

				updatedData.title = Util.getEventTitle(updatedEvent);

				_.map(events, (event) => {
					if (updatedEvent.WPRecurringEventID === event.WPRecurringEventID || updatedEvent.timeBlockID === event.timeBlockID) {
						event = _.assign(event, updatedData);
					};
				});

				_.map(unplannedTimeBlocks, (unplannedTimeBlock) => {
					if (updatedEvent.timeBlockID === unplannedTimeBlock.timeBlockID) {
						unplannedTimeBlock = _.assign(unplannedTimeBlock, updatedData);
					};
				});

				this.setState({
					events: events,
					unplannedTimeBlocks: unplannedTimeBlocks
				});

				this.props.setLoading(false);
			}
			else {
				this.setApiResponse("Something went wrong!", true);
				this.props.setLoading(false);
			};
		});

	}

	updateUserPreferencesInServer = (preferences) => {

		if (!preferences) return;

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateUserPreferences, {updatedUserPreferences: preferences}, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					this.props.setLoading(true);
					let data = response.data.data;

					this.setState({
						userPreference: data,
						excludedGoogleCalendarIDs: data && data.excludedGoogleCalendarIDs || []
					},
					() => {
						/* It is necessary to reload PBH weeklyplan to reflect the changes  */
						this.getWeeklyPlanForTheUser();
					});
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			//this.props.setLoading(false);
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Update events
	updateEvents = (events) => {
		this.setState({
			events: events
		});
	}

	// Toggle Model Popup
	toggleSaveConfirmationModal = () => {

		let { showSaveConfirmationModal = false } = this.state;
		this.setState({
			showSaveConfirmationModal: !showSaveConfirmationModal,
			saveType: ''
		});
	}

	// Toggle Model Popup
	toggleDetailedInfoModal = (showMore = false, detailedInfo  = null, reloadOutcomesData = false) => {

		this.setState({
			detailedInfo: !this.state.showDetailedInfoModal? detailedInfo : null,
			showMoreInfo: showMore ? true : false,
		}, () => {
			if (this.state.showDetailedInfoModal && reloadOutcomesData) {
				this.getOutcomesWithPopulatedData(); // Reload the Outcomes and KA
			};
			this.setState({showDetailedInfoModal: !this.state.showDetailedInfoModal})
		});
	}

	// Get Weekly-Plan for the user
	getWeeklyPlanForTheUser() {

		this.props.setLoading(true);

		var postObj = {
			currentDate: moment(),
			clientTimeZone: momentTimezone.tz.guess()
		};

		let coreData = Util.getUser();

		API_Services.httpPOST(UrlConstants.URLS.getWeeklyPlanForTheUser, postObj, (err, response) => {

			if (err) {

				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response && response.data) {

				// console.log("response...",response.data)

				if (response.data) {

					let data = response.data.data;

					if (data) {
			
						for (let i = 0; i < data.events.length; i++) {
							
              console.log('title::', data.events[i].title);
              data.events[i].start = new Date(data.events[i].start);
							data.events[i].end = new Date(data.events[i].end);

							if (!data.events[i].WPEventID) {
								data.events[i].WPEventID = coreData.userCoreProperties.userID + "-" + i + "-" + uuidV4() + "-" + Date.now(); // set WeeklyPlanEvent ID
              }
						}

						this.setUnplannedTimeBlocks(data.unplannedTimeBlocks);

						const events = data.events;

						this.setState({
							weeklyPlanID: data.id,
							events: events,
						});
					}
				} 
				else {
					// this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	// Get saved tracker weeks for the user
	getSavedTrackerWeeks = (cb) => {

		this.props.setLoading(true);

		let currentWeekStartDt = moment().startOf('isoWeek').clone();
		var postObj = {
			currentWeekStartDt: currentWeekStartDt,
			clientTimeZone: momentTimezone.tz.guess()
		};

		API_Services.httpPOST(UrlConstants.URLS.getSavedTrackerWeeks, postObj, (err, response) => {

			if (err) {

				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response && response.data) {

				if (response.data) {

					let savedTrackerWeeks = response.data.data || [];
					savedTrackerWeeks = _.filter(savedTrackerWeeks, (tracker) => {if (moment(tracker.fromDate).clone().isSameOrAfter(currentWeekStartDt)) return tracker;});

					this.setState({savedTrackerWeeks});
					this.props.setLoading(false);
					return cb(savedTrackerWeeks);
				}
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	handleSaveChanges(e, saveOptions = {}) {

		e.preventDefault();

		if (!this.state.weeklyPlanID && this.state.events.length == 0 ) {
			this.setApiResponse("Add some events to calendar and try again", false);
			return this.props.setLoading(false);
		}
		else {
			this.setState({ saveOptions });
			this.preProcessWPUpdate();
		};
	}

	preProcessWPUpdate = () => {

		let isEventUpdated = false;
		const { events } = this.state;

		events.map(event => {
			if (_.isNil(event.isEventUpdated)
				|| event.isEventUpdated
				|| event.isRemovedFromThePlan
				|| event.isThirdPartySyncRequired) {
				isEventUpdated = true;
			}
		});

		if (!isEventUpdated) { // Seems, nothing has changed. Skip the update API call
			alert('No changes have been detected since the last save.');
			return this.props.setLoading(false);
		}
		else {
			this.getSavedTrackerWeeks((data)=> {
				if (data && data.length > 0) {
					this.toggleSaveConfirmationModal(); // show confirmation modal before update
				}
				else if(data && data.length == 0) {
					this.updateWeeklyPlan(); // Update the WP changes in backend
				}
			})
		}
	}

	// Handler for Weekly-Plan to add and update events
	clearPBHEventsFromGC = (clearOptions = {clearCurrentNdFeatureSavedInstances: false}) => {

		this.props.setLoading(true);

		var postObj = {
			clearOptions: clearOptions,
			clientTimeZone: momentTimezone.tz.guess()
		};

		API_Services.httpPOST(UrlConstants.URLS.clearAllWeeklyPlanEventsInGC, postObj, (err, response) => {

			if (err || !response || !response.data) {
				this.props.setLoading(false);
				this.setApiResponse("Something went wrong!", true);
				console.log('err...', err);
			} 
			else if (response.data) {

				let data = response.data.data;

				if (data) {

					for (let i = 0; i < data.events.length; i++) {
						data.events[i].start = new Date(data.events[i].start);
						data.events[i].end = new Date(data.events[i].end);
					};

					this.setUnplannedTimeBlocks(data.unplannedTimeBlocks);

					this.setState({
						events: data.events,
					});

					if (response.data && response.data.message) {
						this.setApiResponse(response.data.message, true)
					}
				}
				this.props.setLoading(false);
			};
		});
	}

	// Handler for Weekly-Plan to add and update events
	updateWeeklyPlan = () => {

		this.props.setLoading(true);

		this.setState({
			buttonDisabled: true,
		});

		let isEventUpdated = false;
		let clientTimeZone = momentTimezone.tz.guess();
		const { events, saveOptions = {}, saveType, unplannedTimeBlocks = [] } = this.state;
		saveOptions.canOverrideSavedInstances = saveType == 'overwrite_current_nd_feature_instances' ? true : false;

		let updatedEvents = events.filter(event => {

			if (_.isNil(event.isEventUpdated) || event.isEventUpdated || event.isRemovedFromThePlan || event.isThirdPartySyncRequired) {

				isEventUpdated = true; // set global isEventUpdated to true, If any of the event is updated/created/removed

				if (_.isNil(event.isEventUpdated)) { event.isEventUpdated = true; }

				if ((event.isEventUpdated || event.isRemovedFromThePlan)) {
					event.isThirdPartySyncRequired = true; // set event level isEventUpdated to true, If the event is updated/created/removed
				};
			}
			return event
		});

		if (this.state.showSaveConfirmationModal) this.toggleSaveConfirmationModal();

		if (!isEventUpdated) { // Seems, nothing has changed. Skip the update API call
			this.toggleSaveButton(false);
			return
		};

		var postObj = {
			events: updatedEvents,
			unplannedTimeBlocks: unplannedTimeBlocks,
			isEventUpdated: isEventUpdated,
			saveOptions: saveOptions,
			clientTimeZone: clientTimeZone
		};

		API_Services.httpPOST(UrlConstants.URLS.createOrUpdateWeeklyPlan, postObj, (err, response) => { // update weekly plan schedule

			if (err || !response || !response.data) {
				this.setApiResponse("Something went wrong!", true);
				console.log('err...', err);
			} 
			else if (response.data) {

				let data = response.data.data;

				if (data) {

					for (let i = 0; i < data.events.length; i++) {
						data.events[i].start = new Date(data.events[i].start);
						data.events[i].end = new Date(data.events[i].end);
					};

					this.setUnplannedTimeBlocks(data.unplannedTimeBlocks);

					this.setState({
						weeklyPlanID: data.id,
						events: data.events,
					});

					if (response.data && response.data.message) {
						this.setApiResponse(response.data.message, true)
					}
				}
				this.setApiResponse("Record have been saved successfully!", false);
				console.log('response...', response);
			};
			this.toggleSaveButton(false);
		});
	}

	toggleSaveButton = (flag) => {
		this.setState({buttonDisabled: flag});
		this.props.setLoading(flag);
		return
	}

	// Handler for delete and update Weekly-Plan schedule
	handleDeleteEvent = (event) => {

		const { events } = this.state;

		const idx = events.indexOf(event);

		if (idx > -1) {

			let eventNewData = events[idx];

			eventNewData.isEventUpdated = true;
			eventNewData.isRemovedFromThePlan = true;

			if (eventNewData.googleEventID && eventNewData.googleCalendarID) {
				eventNewData.isThirdPartySyncRequired = true;;
			};


			var unplannedTimeBlocks = this.state.unplannedTimeBlocks || [];
			if (eventNewData.isUnplanned && eventNewData.timeBlockID) {
				// Remove the TB from other tab
				unplannedTimeBlocks = unplannedTimeBlocks.filter((item, index) => {
					return item.timeBlockID !== eventNewData.timeBlockID;
				})
			}

			if (eventNewData.regularity == 'daily'
				|| (eventNewData.recurringIntervalWeekDaysID && eventNewData.recurringIntervalWeekDaysID.length > 1)) {

					/* This is for the events has more than one instance in a weeklyplan. */

				_.map(events, (item) => {
					// Set Delete flags for all the events that contains same WPEventID 
					if (item.WPRecurringEventID && item.WPRecurringEventID == eventNewData.WPRecurringEventID) {
						item.isEventUpdated = eventNewData.isEventUpdated;
						item.isRemovedFromThePlan = eventNewData.isRemovedFromThePlan;
						item.isThirdPartySyncRequired = eventNewData.isThirdPartySyncRequired || false;
					};
				});
			}
			else {
				events.splice(idx, 1, eventNewData);
			}


			this.setState({ events, unplannedTimeBlocks, saveOptions: {syncWith3rdPartyCalendar : true}},() => {
				this.preProcessWPUpdate();
			});
		}

		this.toggleDetailedInfoModal();

	}

	setUnplannedTimeBlocks(unplannedTimeBlocks = []) {

		for (let i = 0; i < unplannedTimeBlocks.length; i++) {

			const startDt = new Date();
			const endDt= new Date();

			startDt.setHours(0, 0, 0);
			endDt.setHours(0, (unplannedTimeBlocks[i].durationInMinutes), 0);

			unplannedTimeBlocks[i].start = startDt;
			unplannedTimeBlocks[i].end = endDt;
		};

		this.setState({
			unplannedTimeBlocks: unplannedTimeBlocks,
		});

	}

	setGeneratedTimeBlocks(timeBlocks) {

		this.setState({
			timeBlocks: timeBlocks,
		});
	}

	renderAPIAlertMessage() {

		const { apiResponse } = this.state;

		return (
			<span className="small text-center">
				{
					!_.isEmpty(apiResponse)
						?
						<div className={apiResponse.className}><span>{apiResponse.message}</span></div>
						: null
				}
			</span>
		)
	}

	renderSaveConfirmationPopup = () => {

		const { saveType, savedTrackerWeeks = [], showSaveConfirmationModal } = this.state
		let [ saveTypeOption1, saveTypeOption2 ] = ['overwrite_current_nd_feature_instances', 'do_not_overwrite_saved_instances'];

		return (
			<div>
				<Modal isOpen={showSaveConfirmationModal} toggle={this.toggleSaveConfirmationModal}>
					<ModalHeader toggle={this.toggleSaveConfirmationModal}>Heads up!</ModalHeader>
					<ModalBody>

						{
							savedTrackerWeeks && savedTrackerWeeks.length > 0 && <div>
								<h6 className="mb-3">You have data saved for the following weeks:</h6> 
								<ol className="ml-n3">
									{savedTrackerWeeks.map((data, key) => {
										return <li key={'saved_tracker_key_' + key} className="mb-3 small-title-text">
											{momentTimezone(data.fromDate).format('ll') + " - " + momentTimezone(data.toDate).format('ll')} 
											</li>
									})}
								</ol>
								<br></br>
							</div>
						}
						
						<h6 className="mb-3">Please select how you'd like to proceed?</h6> 

						<div className="ml-3">
							<div className="custom-radio-group" style={{flexDirection: 'column'}}>
								<div className="radio-item-container">
									<input
										type="radio"
										name={saveTypeOption1}
										checked={saveType == saveTypeOption1}
										value={saveTypeOption1}
										onChange={() => this.setState({
											saveType: saveTypeOption1
										})}
									/>
									<label className="ml-2" style={{fontSize: 14}}>Update all current and future weeks.</label>
								</div>
								<div className="radio-item-container">
									<input
										type="radio"
										name={saveTypeOption2}
										checked={saveType != saveTypeOption1}
										value={saveTypeOption2}
										onChange={() => this.setState({
											saveType: saveTypeOption2
										})}
										/>
									<label className="ml-2" style={{fontSize: 14}}>Do not overwrite the above mentioned weeks.</label>
								</div>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<Button color="primary" className="btn-sm" title="Save" onClick={this.updateWeeklyPlan}>Proceed</Button>{' '}
						<Button color="secondary" className="btn-sm" title="Cancel" onClick={this.toggleSaveConfirmationModal}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	renderDetailedInfoPopup() {

		return(
			<DetailedInfoPopup 
				events={this.state.events}
				showDetailedInfoModal={this.state.showDetailedInfoModal} 
				detailedInfo ={this.state.detailedInfo}
				showMore={this.state.showMoreInfo}
				toggleDetailedInfoModal={this.toggleDetailedInfoModal}
				updateEvents={this.updateEvents}
				deleteEvent={this.handleDeleteEvent}
				focusAreas={this.state.focusAreas || []} 
				outcomesWithPopulatedData={this.state.outcomesWithPopulatedData || []}
				updateNewlyAssignedPBHProperties={this.updateNewlyAssignedPBHProperties}
			/>
		);
	}


	renderLeftSection() {

		const {activeLeftPaneSection = 'commitments'} = this.state;
		let activeSectionJSX = null;

		if (activeLeftPaneSection == 'commitments') {
			activeSectionJSX = (
				<YourCommitments timeBlocks={this.setGeneratedTimeBlocks.bind(this)}>
					<DragableTimeBlocks timeBlocks={this.state.timeBlocks} events={this.state.events} onDoubleClick={this.toggleDetailedInfoModal}/>
				</YourCommitments>
			);
		}
		else if (activeLeftPaneSection == 'unplanned') {
			activeSectionJSX = (
				<UnplannedTimeBlocks>
					<DragableTimeBlocks timeBlocks={this.state.unplannedTimeBlocks} events={this.state.events} onDoubleClick={this.toggleDetailedInfoModal}/>
				</UnplannedTimeBlocks>
			);
		};

		return (
			<div style={{ padding: 15, maxHeight: '75vh', overflow: 'auto' }}>
				<div className="text-center mb-3">
					<ButtonGroup className="tracker-your-commitments">
						<Button onClick={() => this.setState({activeLeftPaneSection: 'commitments'})} className={activeLeftPaneSection == 'commitments' ? "active" : ""} style={{minWidth:105}}>Commitments</Button>
						<Button onClick={() => this.setState({activeLeftPaneSection: 'unplanned'})} className={activeLeftPaneSection == 'unplanned' ? "active" : ""} style={{minWidth:105}}>Other</Button>
					</ButtonGroup>
				</div>
				{activeSectionJSX ?
					<div className="mt-4 mb-2 border p-3">{activeSectionJSX}</div>
				: null}
			</div>
		);
	}

	render() {

		let formats = {
			eventTimeRangeFormat: () => null,
			dayFormat: (date, culture, localizer) => (localizer.format(date, 'ddd', culture)).toString().toUpperCase(),
			dayRangeHeaderFormat: (date, culture, localizer) => 'WEEKLY PLAN',
			dayHeaderFormat: (date, culture, localizer) => 'WEEKLY PLAN'
		};

		let components = {
			event: CustomEvent,
			toolbar: props => (<WeeklyPlanCalendarToolBar {...props} 
				disableSaveButton={this.state.buttonDisabled}
				handleSaveOptionChanges={this.handleSaveOptionChanges}
				handleSaveChanges={this.handleSaveChanges.bind(this)}
				clearPBHEventsFromGC={this.clearPBHEventsFromGC}
				GoogleCalendarCurrentSyncStatus={this.state.GoogleCalendarCurrentSyncStatus}
				GCMetaData={this.state.GCMetaData}
				userPreference={this.state.userPreference}
				excludedGoogleCalendarIDs={this.state.excludedGoogleCalendarIDs}
				updateUserPreferencesInServer = {this.updateUserPreferencesInServer}
				/>),
		}

		// Dont render deleted events
		let filteredEvents = _.filter(this.state.events, function(e) {
			if (!e.isEventHiddenInPBH && !e.skipEventInWP && !e.isRemovedFromThePlan) {
				return true;
			} else {
				return false;
			}
		});

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<NavLink to="/available-time">Weekly Plan</NavLink>&nbsp;
				</div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row row-section-container">

						<div className="col-md-3 p-0">
						 	{this.renderLeftSection()}
						</div>

						<div className="col-md-9 inner-container">
							{this.renderAPIAlertMessage()}
							<div className={"weekly-plan overflow-hidden"}>
								<Calendar 
									formats={formats}
									events={filteredEvents}
									allEvents={this.state.events}
									defaultView={'week'}
									defaultDate={new Date()}
									components={components}
									timeslots={12}
									step={5}
									default={30}
									length={5}
									scrollToTime={this.scrollToTime}
									handleSaveChanges={this.handleSaveChanges.bind(this)}
									updateEvents={this.updateEvents}
									showDetailedInfo={this.toggleDetailedInfoModal}
								/>
							</div>
						</div>
					</div>
					{this.renderDetailedInfoPopup()}
					{this.renderSaveConfirmationPopup()}
				</div>
			</div>
		);

	}
}

WeeklyPlanScheduler.propTypes = {
	setLoading: PropTypes.func.isRequired,
	getUserOutcomesWithPopulatedData: PropTypes.func.isRequired,
};

// const CustomizedWeeklyPlanScheduler = DragDropContext(HTML5Backend)(WeeklyPlanScheduler);

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading, getUserOutcomesWithPopulatedData })(
	WeeklyPlanScheduler
);
