import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Util from '../../utils/Util';

import TimeBlocks from './TimeBlocks';
import Tasks from './Tasks';
import MetricsAndTargets from './MetricsAndTargets';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class KeyActivities extends Component {

	constructor(props) {
		super(props);
        this.state = {
            keyActivities: this.props.keyActivities ? _.clone(this.props.keyActivities) : [],
            activityObj: {}, // Handle the activityObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            validationErrors: {},
            showUpdateModal: false,
            itemToRemove: null,
            showDeleteModal: false,
        };
    }

	toggleUpdateModal(activityObj) {

        if (this.state.showUpdateModal) {
            this.setState({
                activityObj: {},
                validationErrors: {},
                showUpdateModal: false
            });
        }
        else {

			/* When adding a new keyactivity, if there are project metrics (that applies to all keyactivities configured), add them as keyactivity metrics by default. */
			
			if (_.isEmpty(activityObj) && this.props && this.props.defaultKAMetrics && this.props.defaultKAMetrics.length > 0) {
				activityObj['metricsAndTargets'] = this.props.defaultKAMetrics;
			};

            this.setState({
                activityObj: _.clone(activityObj),
                validationErrors: {},
                showUpdateModal: true
            });
        };
    }

	updateKeyActivity() {
		
		let {keyActivities, activityObj, validationErrors} = this.state;
		let fieldsToBeValidated = ['title'];
        let hasValidationError = false;
		
		fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, activityObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

		if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };

		let activityObjIndex = _.findIndex(keyActivities, {id: activityObj.id});

		if (activityObjIndex > -1) {
			keyActivities[activityObjIndex] = activityObj;
		}
		else {
			activityObj.id = Util.generateObjectId();
			keyActivities.push(activityObj);
		};
		
		this.setState({
			keyActivities: keyActivities
		},
		() => {
			
			if (this.props.onChange) {
				this.props.onChange(keyActivities);
			};
			this.toggleUpdateModal();
		});
	}

	validateUserInput(fieldName, response) {

        let validationMessage = 'valid';
        let mandatoryFields = ['title'];

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

	handleValueChanges(fieldName, response) {

		let {activityObj, validationErrors} = this.state;

		if (fieldName == 'title') {
            response = response.target.value ? response.target.value : '';
        };

		let validationMessage = this.validateUserInput(fieldName, response);

		activityObj[fieldName] = response;
		validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

		this.setState({
            activityObj: activityObj,
			validationErrors: validationErrors
        });
	}

	renderUpdateModal() {

        let {activityObj, validationErrors} = this.state;

        return(
			<div>
				<Modal size={'xl'} isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleUpdateModal.bind(this)}>Key Activity</ModalHeader>
					<ModalBody>

                        <div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div><input type="text" onChange={this.handleValueChanges.bind(this, 'title')} className="form-control" value={activityObj['title']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['title']}</div>
						</div>

						<TimeBlocks 
							timeBlocks={activityObj['timeBlocks']} 
							onChange={this.handleValueChanges.bind(this, 'timeBlocks')} />

						<MetricsAndTargets
							source={"keyactivities"} 
							metricsAndTargets={activityObj['metricsAndTargets']} 
							onChange={this.handleValueChanges.bind(this, 'metricsAndTargets')} />

						<Tasks 
							tasks={activityObj['tasks']} 
							onChange={this.handleValueChanges.bind(this, 'tasks')} />
						
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.updateKeyActivity.bind(this)}>Done</button>

						<div className="mt-3"><small>* The above button will store your changes locally upon clicking it. You need to click "Save Template" button from the homepage to update the database.</small></div>

					</ModalBody>
				</Modal>
			</div>
		);
    }

	handleActivityDelete() {

		let {keyActivities, itemToRemove} = this.state;

        if (!itemToRemove) return;

        let itemIndex = _.findIndex(keyActivities, {id: itemToRemove});
        keyActivities.splice(itemIndex, 1);

        this.setState({
            keyActivities: keyActivities
        },
        () => {
			if (this.props.onChange) {
				this.props.onChange(keyActivities);
			};
            this.toggleDeleteModal();
        });
    }

	toggleDeleteModal(recordId) {

		if (this.state.showDeleteModal) {
			this.setState({
				showDeleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteModal: true,
				itemToRemove: recordId,
			});
		}
	}

	renderDeleteModal() {

		var activityObj = _.find(this.state.keyActivities, { id: this.state.itemToRemove });
		var activityTitle = activityObj ? activityObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Key Activity</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the key activity <span className="text-warning">{activityTitle}?</span></p>
						<div><small>* This action is temporary. You need to click "Save Template" button from the homepage to update the database.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sm" color="danger" title="Delete" onClick={this.handleActivityDelete.bind(this)}>Delete</Button>{' '}
						<Button className="btn-sm" color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		let {keyActivities} = this.state;

		return(
			<div className="form-group">
				<div className="row">
					<div className="col-6">
						<label>Key Activities</label>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleUpdateModal.bind(this, {})}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				{keyActivities && keyActivities.length > 0 ?
					<table className="table-curved mt-3">
						<thead>
							<tr className="row row-section-container">
								<th className="text-center col-1">#</th>
								<th className="text-left col-7 col-md-9">Title</th>
								<th className="text-center col-4 col-md-2" colSpan={2}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{keyActivities.map((activityObj, index) => {
								return(
									<tr key={'keyactivity_' + index} className="row row-section-container">
										<td className="text-center col-1">{index + 1}</td>
										<td className="text-left col-7 col-md-9">{activityObj.title}</td>
										<td className="text-center col-2 col-md-1"><button className="table-action-button" title="Edit" onClick={this.toggleUpdateModal.bind(this, activityObj)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
										<td className="text-center col-2 col-md-1"><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, activityObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
									</tr>
								);
							})}
						</tbody>
					</table>
				: 
					<div className="small text-info mt-1">No Key Activities added.</div>
				}
				{this.renderUpdateModal()}
				{this.renderDeleteModal()}
			</div>
		);
	}
}

KeyActivities.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	KeyActivities
);