export const GET_ERRORS = "GET_ERRORS";
export const LOADING = "LOADING";
export const RESET_FORM = "RESET_FORM";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

/* Dashboard */

/* End of Dashboard */

/* Current Sprint */
export const RESET_CURRENT_SPRINT = "RESET_CURRENT_SPRINT";
export const GET_CURRENT_SPRINT_QUESTIONS = "GET_CURRENT_SPRINT_QUESTIONS";
export const GET_CURRENT_SPRINT = "GET_CURRENT_SPRINT";
export const DELETE_CURRENT_SPRINT = "DELETE_CURRENT_SPRINT";
/* End of Current Sprint */

/* Focus Area */
export const RESET_FOCUS_AREA = "RESET_FOCUS_AREA";
export const GET_FOCUS_AREA_QUESTIONS = "GET_FOCUS_AREA_QUESTIONS";
export const GET_FOCUS_AREA = "GET_FOCUS_AREA";
export const GET_FOCUS_AREAS = "GET_FOCUS_AREAS";
export const DELETE_FOCUS_AREA = "DELETE_FOCUS_AREA";
/* End of Focus Area */

/* Outcome */
export const RESET_OUTCOME = "RESET_OUTCOME";
export const GET_OUTCOME = "GET_OUTCOME";
export const GET_OUTCOMES = "GET_OUTCOMES";
export const DELETE_OUTCOME = "DELETE_OUTCOME";
export const GET_OUTCOMES_WITH_POPULATED_DATA = "GET_OUTCOMES_WITH_POPULATED_DATA";
/* End of Outcome */

/* KeyActivity */
export const RESET_KEY_ACTIVITY = "RESET_KEY_ACTIVITY";
export const GET_KEY_ACTIVITY = "GET_KEY_ACTIVITY";
export const GET_KEY_ACTIVITIES = "GET_KEY_ACTIVITIES";
export const DELETE_KEY_ACTIVITY = "DELETE_KEY_ACTIVITY";
/* End of KeyActivity */

/* TimeBlock */
export const RESET_TIME_BLOCK = "RESET_TIME_BLOCK";
export const GET_TIME_BLOCK = "GET_TIME_BLOCK";
export const GET_TIME_BLOCKS = "GET_TIME_BLOCKS";
export const DELETE_TIME_BLOCK = "DELETE_TIME_BLOCK";
/* End of TimeBlock */

/* Task */
export const RESET_TASK = "RESET_TASK";
export const GET_TASK = "GET_TASK";
export const GET_TASKS = "GET_TASKS";
export const DELETE_TASK = "DELETE_TASK";
/* End of Task */

/* Roles And Responsibilities */
export const RESET_ROLES_AND_RESPONSIBILITY = "RESET_ROLES_AND_RESPONSIBILITY";
export const GET_ROLES_AND_RESPONSIBILITY = "GET_ROLES_AND_RESPONSIBILITY";
export const GET_ROLES_AND_RESPONSIBILITIES = "GET_ROLES_AND_RESPONSIBILITIES";
export const DELETE_ROLES_AND_RESPONSIBILITY = "DELETE_ROLES_AND_RESPONSIBILITY";
/* End of Roles And Responsibilities */

/* Collaborator */
export const RESET_COLLABORATOR = "RESET_COLLABORATOR";
export const GET_COLLABORATOR = "GET_COLLABORATOR";
export const GET_COLLABORATORS = "GET_COLLABORATORS";
export const DELETE_COLLABORATOR = "DELETE_COLLABORATOR";
/* End of Collaborator */

/* Note */
export const RESET_NOTE = "RESET_NOTE";
export const GET_NOTE = "GET_NOTE";
export const GET_NOTES = "GET_NOTES";
export const DELETE_NOTE = "DELETE_NOTE";
/* End of Note */

/* Note */
export const SUBSCRIPTIONS = "SUBSCRIPTIONS";