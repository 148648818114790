import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Util from '../../utils/Util';

import TimeScope from "../form-inputs/TimeScope";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class MetricsAndTargets extends Component {

	constructor(props) {
		super(props);
        this.state = {
            metricsAndTargets: this.props.metricsAndTargets ? _.clone(this.props.metricsAndTargets) : [],
            metricObj: {}, // Handle the metricObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            validationErrors: {},
            showUpdateModal: false,
            itemToRemove: null,
            showDeleteModal: false,
        };
    }

	toggleUpdateModal(metricObj) {

        if (this.state.showUpdateModal) {
            this.setState({
                metricObj: {},
                validationErrors: {},
                showUpdateModal: false
            });
        }
        else {
            this.setState({
                metricObj: _.clone(metricObj),
                validationErrors: {},
                showUpdateModal: true
            });
        };
    }

	updateMetricsInParent() {
		
		let {metricsAndTargets, metricObj, validationErrors} = this.state;
		
		let fieldsToBeValidated = ['title'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, metricObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };
		
		let itemIndex = _.findIndex(metricsAndTargets, {id: metricObj.id});

		if (itemIndex > -1) {
			metricsAndTargets[itemIndex] = metricObj;
		}
		else {
			metricObj.id = Util.generateObjectId();
			metricsAndTargets.push(metricObj);
		};
		
		this.setState({
			metricsAndTargets: metricsAndTargets
		},
		() => {

			if (this.props.onChange) {
				this.props.onChange(metricsAndTargets); // Update state of both the parent and child components.
			};
			this.toggleUpdateModal();
		});
	}

	validateUserInput(fieldName, response) {

        let validationMessage = 'valid';
        let mandatoryFields = ['title'];

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

	handleValueChanges(fieldName, response) {

		let {metricObj, validationErrors} = this.state;

		let stringResponseFields = ['title', 'description', 'targetValue'];
		let dropdownFields = ['targetScope'];

        if (stringResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? response.target.value : '';
        }
		else if (dropdownFields.indexOf(fieldName) > -1) {
            response = response && response.value ? response.value : null;
        }
		else if (fieldName == 'applyToAllKeyActivities') {
			response = metricObj['applyToAllKeyActivities'] ? false : true;
		};

		let validationMessage = this.validateUserInput(fieldName, response);

		metricObj[fieldName] = response;
		validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

		this.setState({
            metricObj: metricObj,
			validationErrors: validationErrors
        });
	}

	renderUpdateModal() {

        let {metricObj, validationErrors} = this.state;

        return(
			<div>
				<Modal size={'lg'} isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleUpdateModal.bind(this)}>Metrics & Targets</ModalHeader>
					<ModalBody>

                        <div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div><input type="text" onChange={this.handleValueChanges.bind(this, 'title')} className="form-control" value={metricObj['title']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['title']}</div>
						</div>

                        <div className="form-group">
							<label>Description</label>
							<TextareaAutosize
                                onChange={this.handleValueChanges.bind(this, 'description')}
                                className="form-control"
                                minRows={1} 
                                maxRows={10}
                                name="description"
                                value={metricObj['description']} />
                            <div className="text-danger small mt-1">{validationErrors['description']}</div>
						</div>

                        <div className="form-group">
							<label>Target</label>
							<div className="row">
								<div className="col-lg-6">
									<TimeScope
										handleSelectField={(label, selectedTimeScope) => this.handleValueChanges('targetScope', selectedTimeScope)}
										value={metricObj['targetScope']}
										error={validationErrors['targetScope']}
										placeholder={'Target Scope'}
										isRequired={false}
										source={"keyactivities"}  /* Project metrics can be applied to all keyactivities. For that we need 'per_event' unit. */
									/>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<input type="text" onChange={this.handleValueChanges.bind(this, 'targetValue')} className="form-control" placeholder="Target Value" name="targetValue" value={metricObj['targetValue']} style={{"minHeight": "42px"}} />
									</div>
								</div>
							</div>
                            <div className="text-danger small mt-1">{validationErrors['target']}</div>
							<div className="small text-info mt-n3">* All metrics are numerical.</div>
						</div>

						{this.props && this.props.source === 'projects' ? 
							<div className="form-group">
								<div className="custom-control custom-switch">
									<input type="checkbox" onChange={() => console.log('Metrics apply to all keyactivities preference has been changed!')} className="custom-control-input" checked={metricObj['applyToAllKeyActivities'] ? 'checked' : ''} />
									<label className="custom-control-label cursor-pointer" onClick={this.handleValueChanges.bind(this, 'applyToAllKeyActivities')}>Apply to all Key Activities</label>
								</div>
							</div>
						: null}

                        <button type="button" className="btn btn-primary btn-sm" onClick={this.updateMetricsInParent.bind(this)}>Done</button>

						<div className="mt-3"><small>* The above button will store your changes locally upon clicking it. You need to click "Save Template" button from the homepage to update the database.</small></div>

					</ModalBody>
				</Modal>
			</div>
		);
    }

	handleMetricDelete() {

		let {metricsAndTargets, itemToRemove} = this.state;

        if (!itemToRemove) return;

        let itemIndex = _.findIndex(metricsAndTargets, {id: itemToRemove});
        metricsAndTargets.splice(itemIndex, 1);

        this.setState({
            metricsAndTargets: metricsAndTargets
        },
        () => {
			if (this.props.onChange) {
				this.props.onChange(metricsAndTargets);
			};
            this.toggleDeleteModal();
        });
    }

	toggleDeleteModal(recordId) {

		if (this.state.showDeleteModal) {
			this.setState({
				showDeleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteModal: true,
				itemToRemove: recordId,
			});
		}
	}

	renderDeleteModal() {

		var metricObj = _.find(this.state.metricsAndTargets, { id: this.state.itemToRemove });
		var metricTitle = metricObj ? metricObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Metric</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the metric <span className="text-warning">{metricTitle}?</span></p>
						<div><small>* This action is temporary. You need to click "Save Template" button from the homepage to update the database.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sm" color="danger" title="Delete" onClick={this.handleMetricDelete.bind(this)}>Delete</Button>{' '}
						<Button className="btn-sm" color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		let {metricsAndTargets} = this.state;
		metricsAndTargets = Util.sortMetricsBasedOnHierarchy(metricsAndTargets);

		return(
			<div className="form-group">
				<div className="row">
					<div className="col-6">
						<label>Metrics & Targets</label>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleUpdateModal.bind(this, {})}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				{metricsAndTargets && metricsAndTargets.length > 0 ?
					<div>
						<table className="table-curved mt-3">
							<thead>
								<tr className="row row-section-container">
									<th className="text-center col-1">#</th>
									<th className="text-left col-7">Title</th>
									<th className="text-center col-2">Target</th>
									<th className="text-center col-2" colSpan={2}>Actions</th>
								</tr>
							</thead>
							<tbody>
								{metricsAndTargets.map((metricObj, index) => {
									return(
										<tr key={'metric_' + index} className="row row-section-container">
											<td className="text-center col-1">{index + 1}</td>
											<td className="text-left col-7">{metricObj.title}</td>
											<td className="text-center col-2">{(metricObj.targetScope && metricObj.targetValue) ? metricObj.targetValue + ' (' + metricObj.targetScope + ')' : '(not set)'}</td>
											{metricObj.isInheritedFromOutcome == true || metricObj.isInheritedFromProject == true ?
												<td className="text-center col-1"><small>N/A</small></td> : 
												<td className="text-center col-1"><button className="table-action-button" title="Edit" onClick={this.toggleUpdateModal.bind(this, metricObj)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
											}
											{metricObj.isInheritedFromOutcome == true || metricObj.isInheritedFromProject == true ?
												<td className="text-center col-1"><small>N/A</small></td> : 
												<td className="text-center col-1"><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, metricObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
											}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				: 
					<div className="small text-info mt-1">No Metrics & Targets added.</div>
				}
				{this.renderUpdateModal()}
				{this.renderDeleteModal()}
			</div>
		);
	}
}

MetricsAndTargets.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	MetricsAndTargets
);