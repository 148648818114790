import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { DropTarget } from 'react-dnd';
import moment from "moment";
var _ = require('lodash');

var eventTarget = {
    
    drop: function (props, monitor) {
        let {date, culture, localizer} = props;
        let dateIdentifier = localizer.format(date, 'MMDDYYYY', culture).toString();
        let propsItem = monitor.getItem();
        let taskID = propsItem.event && propsItem.event.taskID ? propsItem.event.taskID : null;

        if (dateIdentifier && taskID) {
            props.updateTasksForTheDay(dateIdentifier, taskID);
        };
    }
};

function collectSource(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
	};
}

class DayColumnHeader extends Component {

    removeTask(e, dateIdentifier, taskID) {
        e.preventDefault();
        e.stopPropagation(); // Prevent calendar from changing "week" view to "day" view.
        this.props.handleRemoveTaskForTheDay(dateIdentifier, taskID);
    }

	render() {

        let {date, culture, localizer, dailyTasks, utasks} = this.props;
        let displayDate = localizer.format(date, 'DD ddd', culture).toString();
        let dateIdentifier = localizer.format(date, 'MMDDYYYY', culture).toString();
        let { connectDropTarget } = this.props;

        var dayTasks = dailyTasks && dailyTasks[dateIdentifier] ? dailyTasks[dateIdentifier] : [];
        dayTasks = _.filter(utasks || [], (item) => _.findIndex(dayTasks, {id: item.id, isActive: true}) > -1 ? true : false); // Filter only configured tasks. This is to preserve universal task ranks

        return connectDropTarget(
            <div className="day-col-header-wrapper">
                <div>{displayDate}</div>
                <div className="day-tasks">
                    {dayTasks.length > 0 ? 
                        _.map(dayTasks, (itemObj) => {
                            return itemObj.task && itemObj.id ? (
                                <div className="day-task-title" key={dateIdentifier + '-' + itemObj.id}>
                                    <div>{itemObj.task.substring(0, 12)}{(itemObj.task.length > 12) ? '...' : ''}</div>
                                    <div onClick={(e) => this.removeTask(e, dateIdentifier, itemObj.id)}><i className="fa fa-window-close ml-2" aria-hidden="true"></i></div>
                                </div>
                            ) : null;
                        })
                    : <div className="day-tasks-info">Drag and drop the tasks that needs to be accomplished on this day.</div>
                    }
                </div> 
            </div>
        );
	}
}

export default DropTarget('event', eventTarget, collectSource)(DayColumnHeader);