import {
	RESET_ROLES_AND_RESPONSIBILITY,
	GET_ROLES_AND_RESPONSIBILITY,
	GET_ROLES_AND_RESPONSIBILITIES,
	DELETE_ROLES_AND_RESPONSIBILITY,
} from "../actions/types.js";

const initialState = {
	rolesandresponsibilities: [],
	rolesandresponsibility: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_ROLES_AND_RESPONSIBILITY:
			return {
				...initialState,
			}

		case GET_ROLES_AND_RESPONSIBILITY:
			return {
				...state,
				rolesandresponsibility: action.payload,
			};

		case GET_ROLES_AND_RESPONSIBILITIES:
			return {
				...state,
				rolesandresponsibilities: action.payload,
			};

		default:
			return state;
	}
}
