import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class AddKeyActivity extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				kaIncludeInWeeklyPlan: true,
			},
			formErrors: {
				title: null,
				kaIncludeInWeeklyPlan: null,
				notes: null,
			},
			buttonDisabled: false,
		};
	}

	resetForm() {

		var formObj = {
			title: "",
			kaIncludeInWeeklyPlan: true,
		};

		var formErrors = {
			title: null,
			kaIncludeInWeeklyPlan: null,
			notes: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
		});
	}

	componentDidMount() {

		this.setState({
			outcomeID: this.props.selectedOutcome && this.props.selectedOutcome.value? this.props.selectedOutcome.value : null
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Key Activity Title";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for submit
	handleSubmit(e) {

		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		}
		else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {
				if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});
			formCopy['notes'] = null;
			formCopy['includeInWeeklyPlan'] = form['kaIncludeInWeeklyPlan'];
			formCopy['timeBlocks'] = [];

			this.createNewKA(formCopy);
		}
	}

	createNewKA(data) {

		var postObj = data;
		postObj.outcomeID = this.state.outcomeID;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		// console.log('postObj ...', postObj);
		API_Services.httpPOST(UrlConstants.URLS.addNewKeyActivity, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					this.props.onSuccussSubmit(response.data.data)
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	renderFooterButtons() {

		const { buttonDisabled } = this.state;

		return(
			<div className="row">
				<div className="col-4">
					<div className="text-left">
						<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary btn-sm" style={{marginRight: 10}}>Save</button>
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel} className="btn-sm">Cancel</Button>
					</div>
				</div>
			</div>
		);
	}

	renderAddNewKAForm() {

		const { form, formErrors, outcomeID } = this.state;
		const editOutcomePage = "/projects/edit/" + outcomeID;
		const addKeyActivityPage = "/key-activities/add?outcomeID=" + outcomeID;

		return (
			<div>
				<div className="bread-crumb m-0 mb-4">
					<NavLink to={editOutcomePage}>Project</NavLink>&nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<NavLink to={editOutcomePage}>Key Activities</NavLink>&nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<NavLink to={addKeyActivityPage}>Add</NavLink>&nbsp;
				</div>
				<form onSubmit={(e) => e.preventDefault()}>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Title<span className="text-danger">*</span></label>
								<div>
									<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
									<p className="text-danger">{formErrors['title'] &&
										<span className="err">{formErrors['title']}</span>}</p>
								</div>
							</div>
							<div className="form-group">
								<label>
									<div className="custom-control custom-switch">
										<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="kaIncludeInWeeklyPlan" value={form['kaIncludeInWeeklyPlan']} id="includeInWeeklyPlanSwitch" checked={form['kaIncludeInWeeklyPlan'] ? "checked" : ""} />
										<label className="custom-control-label" htmlFor="includeInWeeklyPlanSwitch">Include In Weekly Plan <span className="text-danger">*</span></label>
										<p className="text-danger">{formErrors['kaIncludeInWeeklyPlan'] &&
											<span className="err">{formErrors['kaIncludeInWeeklyPlan']}</span>}</p>
									</div>
								</label>
							</div>
						</div>
					</div>
					{this.renderFooterButtons()}
				</form>
			</div>
		)
	}

	render() {
		return (
			<div>
				{this.renderAddNewKAForm()}
			</div>
		);
	}

}

AddKeyActivity.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading })(
	AddKeyActivity
);
