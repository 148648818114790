import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import { getUserOutcomes } from "../../actions/outcomeActions";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class OutcomeSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUserOutcomes, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getUserOutcomes(response);
				} else {
					// this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Handler for outcome change event
	handleOutcomeSelect(name, value) {
		this.props.handleOutcomeSelect(name, value);
	}

	// Handler for outcome name search
	handleOutcomeInputChange(value) {

	}

	render() {

		const { outcomes } = this.props.outcomes;
		const { formApiResponse } = this.state;

		var outcomeOptions = [];

		if (outcomes.length > 0) {
			outcomeOptions = outcomes.map(e => ({ value: e.id, label: e.outcomeTitle }));
		}

		return (
			<div>
				<div className="form-group">
					<label>Project Title <span className="text-danger">*</span></label>
					<div className="row">
						<div className="col-11">
							<ReactSelect
								name="outcome"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={outcomeOptions}
								value={this.props.value}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={this.handleOutcomeSelect.bind(this, 'outcome')}
								onInputChange={this.handleOutcomeInputChange.bind(this)}
								placeholder="Select Project"
								isDisabled={this.props.disabled}
							/>
						</div>
					</div>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</div>
			</div>

		);
	}
}

OutcomeSelect.propTypes = {
	getUserOutcomes: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { getUserOutcomes, setLoading })(
	OutcomeSelect
);
