import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import AddFocusArea from "./AddFocusArea";
import EditFocusArea from "./EditFocusArea";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class FocusAreasList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			itemToEdit: null,
			focusAreas: [],
			addFocusAreaModal: false,
			editFocusAreaModal: false,
			formApiResponse: {
				className: "",
				message: "",
			},
		};
	}

	componentDidMount() {
		this.updateFocusAreaList(this.props);
	}

	componentWillReceiveProps(props) {
		this.updateFocusAreaList(props);
	}

	updateFocusAreaList(props) {

		const sectionData = props.sectionData;
		const { sectionResponse } = sectionData;

		this.setState({
			focusAreas: sectionResponse.focusAreas || [],
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing focus area
	handleFocusAreaDelete() {

		if (this.state.itemToRemove) {

			this.props.setLoading(true);

			let postObj = {
				recordID: this.state.itemToRemove,
				timescale: this.props.timescale,
			}

			API_Services.httpPOST(UrlConstants.URLS.deleteFocusAreaResponseForTheTimescale, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {

					if(response.data.status) {
						this.setFormApiResponse("Record have been deleted successfully!", false);

						let focusAreas = [...this.state.focusAreas];

						_.remove(focusAreas, focusArea => focusArea.id === this.state.itemToRemove);

						// Update parent state
						this.props.handleFocusAreaUpdates(focusAreas);
						
						this.setState({
							deleteModal: false,
							itemToRemove: null,
							focusAreas: focusAreas,
						});

					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
				this.setState({
					buttonDisabled: false,
				});
			});
		}
	}

	handleOnDragEnd(result) {
		if (!result.destination) return;

		this.props.setLoading(true);

		const { focusAreas } = this.state;

		const items = Array.from(focusAreas);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		let itemSequence = _.map(items, 'id');

		let postObj = {
			itemSequence: itemSequence,
			timescale: this.props.timescale,
		};

		API_Services.httpPOST(UrlConstants.URLS.updateUniversalRanksOfTheFocusAreas, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if(response.data.status) {

					// Update parent state
					this.props.handleFocusAreaUpdates(items);
					
					this.setState({
						deleteModal: false,
						itemToRemove: null,
						focusAreas: items,
					});

					this.setState({
						focusAreas: items
					});
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	// Renders drag and drop lists
	renderDND() {

		const sectionData = this.props.sectionData;

		const { focusAreas } = this.state;

		if (!focusAreas || focusAreas.length == 0) return <p className="text-info small">No focus area(s) added!</p>;

		return (
			<div className="App">
				<header className="App-header table-responsive">
					<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
						<Droppable droppableId="focusarearesponses">
							{(provided) => (
								<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
									<thead>
										<tr>
											<th className="text-center" style={{ width: 50 }}></th>
											<th className="text-center" style={{ width: 60 }}>Rank</th>
											<th className="text-center" style={{ width: 200 }}>Focus Area Title</th>
											{
												sectionData.focusAreaPrompts.map(({ uniqueID, prompt }) => {
													return (
														<th className="text-center" style={{ width: 300 }} key={uniqueID}>{prompt}</th>
													)
												})
											}
											<th className="text-center" style={{ width: 140 }} colSpan={2}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{focusAreas.map(({ id, focusAreaTitle, responses }, index) => {
											return (
												<Draggable key={id} draggableId={id} index={index}>
													{(provided, snapshot) => (
														<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
															<td className="text-center" style={{ width: 50 }}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
															<td className="text-center" style={{ width: 60 }}>{index + 1}</td>
															<td className="text-center" style={{ width: 200 }}>{focusAreaTitle}</td>
															{
																responses.map(({uniqueID, response}) => {
																	return (
																		<td className="text-center" style={{ width: 300, whiteSpace: 'pre-wrap' }} key={uniqueID + '_response'}>{response}</td>
																	)
																})
															}
															<td className="text-center" style={{ width: 70 }}>
																<button type="button" title="Edit"  className="table-action-button" onClick={this.toggleEditFocusAreaModal.bind(this, id)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button>
															</td>
															<td className="text-center" style={{ width: 70 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
														</tr>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</tbody>
								</table>
							)}
						</Droppable>
					</DragDropContext>
				</header>
			</div>
		);
	}

	renderFocusAreaDeleteModal() {

		var faObj = _.find(this.state.focusAreas, { id: this.state.itemToRemove });
		var faTitle = faObj ? faObj.focusAreaTitle : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Focus Area</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the focus area <span className="text-warning">{faTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleFocusAreaDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	toggleAddFocusAreaModal() {

		if (this.state.addFocusAreaModal) {
			this.setState({
				addFocusAreaModal: false,
			});
		} else {
			this.setState({
				addFocusAreaModal: true,
			});
		}
	}

	addFocusAreaInList(newData) {

		let focusAreas = [...this.state.focusAreas];
		focusAreas.unshift(newData);

		// Update parent state
		this.props.handleFocusAreaUpdates(focusAreas);

		this.setState({
			focusAreas: focusAreas,
			addFocusAreaModal: false,
		});
	}

	renderAddFocusAreaInModal() {

		const { focusAreaPrompts, sectionResponse } = this.props.sectionData;

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.addFocusAreaModal} toggle={this.toggleAddFocusAreaModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddFocusAreaModal.bind(this)}>Add Focus Area</ModalHeader>
					<ModalBody>
						<AddFocusArea
							addFocusAreaInList={this.addFocusAreaInList.bind(this)}
							timescale={this.props.timescale}
							focusAreaPrompts={focusAreaPrompts}
							timescaleRecordId={sectionResponse['id']} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	editRespectiveFocusAreaInList(updatedItem) {

		let focusAreas = [...this.state.focusAreas];
		
		// Find item index using _.findIndex
		var index = _.findIndex(focusAreas, {id: this.state.itemToEdit});

		// Replace item at index using native splice
		focusAreas.splice(index, 1, updatedItem);

		// Update parent state
		this.props.handleFocusAreaUpdates(focusAreas);

		this.setState({
			focusAreas: focusAreas,
			editFocusAreaModal: false,
		});
	}

	toggleEditFocusAreaModal(rowId) {

		if (this.state.editFocusAreaModal) {
			this.setState({
				editFocusAreaModal: false,
				itemToEdit: null,
			});
		} else {
			this.setState({
				editFocusAreaModal: true,
				itemToEdit: rowId,
			});
		}
	}

	renderEditFocusAreaInModal() {

		const { sectionResponse } = this.props.sectionData;

		const { itemToEdit } = this.state;

		if( sectionResponse && itemToEdit ) {

			let focusAreaToEdit = _.find(sectionResponse['focusAreas'], {id:itemToEdit}); 

			return (
				<div>
					<Modal size={'lg'} isOpen={this.state.editFocusAreaModal} toggle={this.toggleEditFocusAreaModal.bind(this)} backdrop={"static"} keyboard={false}>
						<ModalHeader toggle={this.toggleEditFocusAreaModal.bind(this)}>Edit Focus Area</ModalHeader>
						<ModalBody>
							<EditFocusArea
								editRespectiveFocusAreaInList={this.editRespectiveFocusAreaInList.bind(this)}
								timescale={this.props.timescale}
								focusAreaToEdit={focusAreaToEdit}
								timescaleRecordId={sectionResponse['id']} />
						</ModalBody>
					</Modal>
				</div>
			)
		}
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="d-flex align-items-center justify-content-between mt-4 mb-4">
					<h6 style={{ fontSize: 17 }}>Focus Areas</h6>
					<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleAddFocusAreaModal.bind(this)}>
						<i className="fa fa-plus" aria-hidden="true"></i>
					</button>
				</div>
				<div className="section-container">
					{this.renderDND()}
				</div>
				{this.renderFocusAreaDeleteModal()}
				{this.renderAddFocusAreaInModal()}
				{this.renderEditFocusAreaInModal()}
			</div>
		);
	}
}

FocusAreasList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	// focusareas: state.focusareas,
});

export default connect(mapStateToProps, { setLoading })(
	FocusAreasList
);
