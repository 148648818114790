import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, ButtonGroup} from 'reactstrap';
import moment from "moment";
import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";

import SharedStyles from '../../assets/styles/SharedStyles';
import DragableTimeBlocks from './DragableTimeBlocks';

var _ = require('lodash');

class YourCommitments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusArea: {
					label: "All",
					value: "all",
				},
				outcome: {
					label: "All",
					value: "all",
				},
				hideFullyScheduledEvents: false,
				hideFullyCompletedEvents: false,
			},
			formErrors: {
				focusArea: null,
				outcome: null
			},
			focusAreas: [],
			focusAreasOptions: [],
			outcomesWithPopulatedData: [],
			outcomesOptions: [],
			showFilterSection: false,
			activeCommitmentsTab: 'Saved',
			buttonDisabled: false,
			commitments: [],
			timeBlocks: [],
			eventsLoading: false,
		};
	}

	componentDidMount() {

		this.props.onRef(this);

		const { focusAreas, outcomesWithPopulatedData } = this.props;

		this.setState({
			focusAreas: focusAreas,
			outcomesWithPopulatedData: outcomesWithPopulatedData,
		})

		this.yourFocusAreas(focusAreas);

		this.yourOutcomes(outcomesWithPopulatedData);

		this.yourTimeBlocks(outcomesWithPopulatedData);

		this.conditionalSwitchTabsForCommitments();
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		// If both dates are not same then new week is called
		// update saved commitments when user click save changes for the first time for a week
		if(!moment(prevProps.targetWeekStart).isSame(this.props.targetWeekStart) || ( this.props.commitments && this.props.commitments.length >= 0 && !_.isEqual(this.props.commitments, prevProps.commitments) ) ) {

			this.conditionalSwitchTabsForCommitments();
		}
	}

	conditionalSwitchTabsForCommitments() {

		let formObj = {};
		formObj = {
			...this.state.form,
			hideFullyScheduledEvents: false,
			hideFullyCompletedEvents: false,
		};

		// If there are save commitments/events then default to current tab else default to saved tab
		if(this.props.commitments && this.props.commitments.length === 0) {
			this.setState({
				activeCommitmentsTab: 'Current',
				form: formObj,
				commitments: [],
				// timeBlocks: this.props.timeBlocks,
			})
		} else {
			this.setState({
				activeCommitmentsTab: 'Saved',
				form: formObj,
				commitments: this.props.commitments,
				// timeBlocks: this.props.timeBlocks,
			});
		}
	}

	// Set dropdown options for focus areas
	yourFocusAreas(focusAreas) {

		/* When displaying the focus area options to the user, list only the system default and the custom focus areas that are NOT created using system-default focus areas */
		// focusAreas = _.reject(focusAreas, (item) => item.fromSystemDefault == true && item.systemDefaultFocusAreaID);

		if (focusAreas && focusAreas.length > 0) {

			let focusAreasOptions = [{
				label: "All",
				value: "all",
			}];

			// Default select all focusAreas
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				focusArea: {
					label: "All",
					value: "all",
				},
			};

			focusAreasOptions = Util.focusAreaDropdownOptions(focusAreas);

			focusAreasOptions.unshift({
				label: "All",
				value: "all",
			});

			this.setState({
				form: formObj,
				focusAreasOptions: focusAreasOptions,
				buttonDisabled: false,
			});
		}
	}

	// Set dropdown options for outcome
	yourOutcomes(outcomes) {

		if (outcomes && outcomes.length > 0) {

			let outcomesOptions = [{
				label: "All",
				value: "all",
			}];

			// Default select all outcomes
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				outcome: {
					label: "All",
					value: "all",
				},
			};

			outcomes.forEach((eachOutcome) => {

				outcomesOptions.push({
					label: eachOutcome.outcomeTitle,
					value: eachOutcome.id,
					focusAreaID: eachOutcome.focusArea.id
				});
			});

			this.setState({
				form: formObj,
				outcomesOptions: outcomesOptions,
				availableOutcomes: outcomesOptions,
				buttonDisabled: false,
			});
		}
	}

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, selectedFAOption) {

		const { form, focusAreas } = this.state;

		let defaultOptions = [{
			label: "All",
			value: "all"
		}]

		var formObj = {};
		formObj = {
			...form,
			[name]: selectedFAOption,
			'outcome': defaultOptions[0],
		};

		this.props.updateTimeBlocks([]);

		// let filteredoutcomesOptions = _.filter(this.state.availableOutcomes, (outcomeObj) => {
			
		// 	if (selectedFAOption.type == 'system-default') {
		// 		let focusAreasIDToBeMatched = _.filter(focusAreas, {systemDefaultFocusAreaID: selectedFAOption.value});
		// 		focusAreasIDToBeMatched = _.map(focusAreasIDToBeMatched, 'id');
		// 		focusAreasIDToBeMatched.push(selectedFAOption.value);
		// 		return outcomeObj.focusArea && focusAreasIDToBeMatched.indexOf(outcomeObj.focusArea) > -1;
		// 	}
		// 	else {
		// 		return outcomeObj.focusArea == selectedFAOption.value;
		// 	};
		// });

		// let outcomesOptions = selectedFAOption.value != 'all' ? [...defaultOptions, ...filteredoutcomesOptions] : this.state.availableOutcomes;

		let filteredoutcomesOptions = _.filter(this.state.availableOutcomes, { focusAreaID: selectedFAOption.value });
		
		let outcomesOptions = selectedFAOption.value != 'all' ? [...defaultOptions, ...filteredoutcomesOptions] : this.state.availableOutcomes;

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			// this.getOutcomesWithPopulatedData();
		});
	}

	// Handler for outcome select field change event
	handleOutomeSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };

			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
			eventsLoading: true,
		}, () => {
			this.filterEvents();
		});

	}

	/**
	 * 1) Hide Fully Scheduled Events,
	 * 2) Hide Fully Completed Events. 
	 * - We'd have an on/off toggle for both these settings.
	 * - When the first one is activated, the blocks that have all their events scheduled will be hidden. 
	 * - When the second one is activated, the blocks that have all their events completed will be hidden.
	 */
	filterEvents() {

		const { commitments, timeBlocks } = this.props;
		const { hideFullyScheduledEvents, hideFullyCompletedEvents } = this.state.form;

		// console.log('this.state...', this.state);
		// console.log('commitments..', commitments);

		const events = this.props.events || []; // List of Scheduled events.
		let timeBlocksCopy = [...timeBlocks];

		timeBlocksCopy.map((timeBlockObj) => {
            
			// let associatedTimeBlock = _.filter(events, {timeBlockID: timeBlockObj.timeBlockID});
			
			let associatedTimeBlock = _.filter(events, function(e) { 
				if(!e.isRemovedFromThePlan && e.timeBlockID == timeBlockObj.timeBlockID) {
					return true;
				} else {
					return false;
				}
			});
        
            if (timeBlockObj.sessionsPerWeek) {

                timeBlockObj['scheduled'] = associatedTimeBlock.length + "/" + timeBlockObj.sessionsPerWeek + " scheduled";
                timeBlockObj['totalScheduled'] = associatedTimeBlock.length;

                let totalCompleted = 0;
                associatedTimeBlock.map((eachassociatedTimeBlock) => {

                    if(eachassociatedTimeBlock.isCompleted) {
                        totalCompleted = totalCompleted + 1;
                    }
                })
                timeBlockObj['completed'] = totalCompleted + "/" + timeBlockObj.sessionsPerWeek + " completed";
                timeBlockObj['totalCompleted'] = totalCompleted;
            }
		});

		let commitmentsCopy = [...commitments];

		commitmentsCopy.map((timeBlockObj) => {
            
			// let associatedTimeBlock = _.filter(events, {timeBlockID: timeBlockObj.timeBlockID});
			
			let associatedTimeBlock = _.filter(events, function(e) { 
				if(!e.isRemovedFromThePlan && e.timeBlockID == timeBlockObj.timeBlockID) {
					return true;
				} else {
					return false;
				}
			});
        
            if (timeBlockObj.sessionsPerWeek) {

                timeBlockObj['scheduled'] = associatedTimeBlock.length + "/" + timeBlockObj.sessionsPerWeek + " scheduled";
                timeBlockObj['totalScheduled'] = associatedTimeBlock.length;

                let totalCompleted = 0;
                associatedTimeBlock.map((eachassociatedTimeBlock) => {

                    if(eachassociatedTimeBlock.isCompleted) {
                        totalCompleted = totalCompleted + 1;
                    }
                })
                timeBlockObj['completed'] = totalCompleted + "/" + timeBlockObj.sessionsPerWeek + " completed";
                timeBlockObj['totalCompleted'] = totalCompleted;
            }
		});
		
		let filteredCommitments = [...commitmentsCopy];
		let filteredTimeBlocks = [...timeBlocksCopy];

		// Filter for saved tab
		if(this.state.activeCommitmentsTab == 'Saved') {

			if(commitments && commitments.length > 0) {
				commitments.map((eachSavedCommitment, index) => {

					// If hideFullyScheduledEvents is selected and current saved commitment is fully scheduled remove them from the commitments array. We are using lodash to match object and remove from array instead of array.splice since if we remove index then hideFullyCompletedEvents will be affected.
					if(hideFullyScheduledEvents) {
						if(eachSavedCommitment.totalScheduled >= eachSavedCommitment.sessionsPerWeek) {
							_.remove(filteredCommitments, filteredCommitment => filteredCommitment.id === eachSavedCommitment.id);
						}
					}

					// If hideFullyCompletedEvents is selected and current saved commitment is fully completed remove them from the commitments array
					if(hideFullyCompletedEvents) {
						if(eachSavedCommitment.totalCompleted >= eachSavedCommitment.sessionsPerWeek) {
							_.remove(filteredCommitments, filteredCommitment => filteredCommitment.id === eachSavedCommitment.id);
						}
					}
				})
			}

			if(!hideFullyScheduledEvents && !hideFullyCompletedEvents) {
				filteredCommitments = [...commitments];
			}
		}

		// Filter for current tab
		if(this.state.activeCommitmentsTab != 'Saved') {

			if(timeBlocks && timeBlocks.length > 0) {
				timeBlocks.map((eachTimeBlock, index) => {

					if(hideFullyScheduledEvents) {
						if(eachTimeBlock.totalScheduled >= eachTimeBlock.sessionsPerWeek) {
							_.remove(filteredTimeBlocks, filteredTimeBlock => filteredTimeBlock.id === eachTimeBlock.id);
						}
					}

					if(hideFullyCompletedEvents) {
						if(eachTimeBlock.totalCompleted >= eachTimeBlock.sessionsPerWeek) {
							_.remove(filteredTimeBlocks, filteredTimeBlock => filteredTimeBlock.id === eachTimeBlock.id);	
						}
					}
				})
			}

			if(!hideFullyScheduledEvents && !hideFullyCompletedEvents) {
				filteredTimeBlocks = [...timeBlocks];
			}
		}

		this.setState({
			commitments: filteredCommitments,
			timeBlocks: filteredTimeBlocks,
			eventsLoading: false,
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "overcomes":
				if (!value) errorMsg = "Please select Overcomes";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select Focus Area";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// User time blocks based on outcome.
	// Sample input format
	/**
	 * [
	 * 	{
* 			abbreviation: "o1 abb",
			bufferInPercentage: 10,
			collaborators: [],
			createdAt: 1612535512156,
			creationMaintenance: "",
			focusArea: {
				createdAt: 1612780319320,
				updatedAt: 1612872117671,
				id: "6021131fff2e963190b45ec2",
				focusAreaTitle: "FA title 1",
				responses: Array(2), 
				…
			},
			id: "601d56d82213e12f04dd5003",
			includeInWeeklyPlan: true,
			keyActivities: [
				{
					createdAt: 1612679038847
					id: "601f877e01ff442b0c1e0f01"
					includeInWeeklyPlan: true
					notes: null
					outcome: "601d56d82213e12f04dd5003"
					tasks: [{…}, {…}, {…}, {…}]
					tasksSequence: ["6020dab127247b2114f2a5e8", "6020daa127247b2114f2a5e7", "6020e27c27247b2114f2a5e9"]
					timeBlocks: [
						{
							createdAt: 1612867059201,
							durationInMinutes: 20,
							id: "602265f3c10f7325f48a86cf",
							keyActivity: "601f877e01ff442b0c1e0f01",
							sessionsPerWeek: 1,
							title: "o1 k1 tb1 title",
							type: "Other",
							updatedAt: 1613558292401,
							userID: "120e4068-cc84-2d61-412d-7710c835514e"
						}
					],
					title: "o1 k1 title",
					updatedAt: 1613404445418,
					userID: "120e4068-cc84-2d61-412d-7710c835514e",
				}
			],	
			keyActivitiesSequence: ["601f877e01ff442b0c1e0f01", "601f894a01ff442b0c1e0f05", "60229c773332d418804eed72"],
			notes: null,
			outcome: "o1",
			outcomeTitle: "o1 title",
			rank: 1,
			rolesAndResponsibilities: [{…}],
			successDefinition: "",
			updatedAt: 1613557905915,
			userID: "120e4068-cc84-2d61-412d-7710c835514e",
	 *  }
	 * ]
	 */
	yourTimeBlocks(outcomesWithPopulatedData) {

		let timeBlocks = [];

		// For each outcome, will have only one item in an array if user selects an outcome.
		outcomesWithPopulatedData.forEach((eachOutcome) => {

			// some users may not have created keyactivities for an outcome.
			if (eachOutcome.includeInWeeklyPlan === true && eachOutcome.keyActivities && eachOutcome.keyActivities.length > 0) {

				// For each key activity
				eachOutcome.keyActivities.forEach((eachKeyActivity) => {

					// some users may not have created timeblocks for an keyactivities.
					if (eachKeyActivity.includeInWeeklyPlan === true && eachKeyActivity.timeBlocks && eachKeyActivity.timeBlocks.length > 0) {

						// For each time block
						eachKeyActivity.timeBlocks.forEach((eachtimeBlock) => {

							let customTitle = eachOutcome.abbreviation + ": " + eachKeyActivity.title;
							let isNonWeeklyTB = eachtimeBlock.regularity == 'non-weekly' ? true : false;

							var startDt = new Date();
							var endDt = new Date();
							startDt.setHours(0, 0, 0);
							endDt.setHours(0, (eachtimeBlock.durationInMinutes), 0);

							let eventMetrics = Util.sortMetricsBasedOnHierarchy(eachKeyActivity.metricsAndTargets);

							/* Construct event object. */
							timeBlocks.push({
								id: eachtimeBlock.id,
								title: customTitle,
								start: startDt,
								end: endDt,
								outcomeID: eachOutcome.id,
								outcomeAbbr: eachOutcome.abbreviation,
								outcomeTitle: eachOutcome.outcomeTitle,
								keyActivityID: eachKeyActivity.id,
								keyActivityTitle: eachKeyActivity.title,
								timeBlockID: eachtimeBlock.id,
								timeBlockTitle: eachtimeBlock.title,
								focusAreaID: eachOutcome.focusArea.id,
								focusAreaTitle: eachOutcome.focusArea.focusAreaTitle,
								durationInMinutes: eachtimeBlock.durationInMinutes,
								sessionsPerWeek: isNonWeeklyTB ? 1 : eachtimeBlock.sessionsPerWeek, // It's always going to be 1 session per week for the 'non-weekly' timeblocks.,
								regularity: eachtimeBlock.regularity,
								interval: eachtimeBlock.interval,
								recurringInterval: eachtimeBlock.recurringInterval,
								durationInMinutesPerWeek: eachtimeBlock.durationInMinutesPerWeek,
								type: eachtimeBlock.type,
								startDate: eachtimeBlock.startDate,
								recurringIntervalType: eachtimeBlock.recurringIntervalType,
								recurringIntervalDayID: eachtimeBlock.recurringIntervalDayID,
								recurringIntervalWeekID: eachtimeBlock.recurringIntervalWeekID,
								recurringIntervalMonthID: eachtimeBlock.recurringIntervalMonthID,
								recurringIntervalISOWeekDayID: eachtimeBlock.recurringIntervalISOWeekDayID,
								metricsAndTargets: eventMetrics
							});
						});
					}
				});
			}
		});
		
		this.setState({
			timeBlocks: timeBlocks,
		});

		this.props.updateTimeBlocks(timeBlocks, this.state.form.focusArea.value);
	}

	// Handler for submit
	handleSubmit(e) {
		e.preventDefault();

		const { form, outcomesWithPopulatedData } = this.state;

		let filteredOutcomesWithPopulatedData = [];

		var filteredOutcomesByFocusArea = outcomesWithPopulatedData.filter(function (el) {
			return form.focusArea.value == 'all' || (el.focusArea && el.focusArea.id && el.focusArea.id == form.focusArea.value)
		});

		if (filteredOutcomesByFocusArea && filteredOutcomesByFocusArea.length > 0) {

			if (form.outcome.value === "all") {

				this.yourTimeBlocks(filteredOutcomesByFocusArea);
			} else {

				let selectedOutcomeID = form.outcome.value;

				filteredOutcomesByFocusArea.forEach((eachOutcome) => {

					if (eachOutcome.id === selectedOutcomeID) {
						filteredOutcomesWithPopulatedData.push(eachOutcome);
					}
				});

				this.yourTimeBlocks(filteredOutcomesWithPopulatedData);
			}
		} else {

			this.setState({
				timeBlocks: [],
			});
	
			this.props.updateTimeBlocks([], this.state.form.focusArea.value);
		}
	}

	resetFilterOptions = () =>{
		// reset the filter to default
		const { form, outcomesWithPopulatedData } = this.state;
		var formObj = {};
		formObj = {
			...form,
			focusArea: {
				label: "All",
				value: "all",
			},
			outcome: {
				label: "All",
				value: "all",
			},
		};

		this.setState({form : formObj, showFilterSection: false});
		this.yourTimeBlocks(outcomesWithPopulatedData);
	}

	renderHideEventsFilter() {

		const { form, activeCommitmentsTab, commitments, timeBlocks } = this.state;
		
		if (activeCommitmentsTab == 'Saved' && _.isEmpty(commitments)) return null;
		else if (activeCommitmentsTab == 'Current' && _.isEmpty(timeBlocks)) return null;

		return(
			<div>
				<div className="mt-3 mb-2">
					<div className="custom-control custom-switch">
						<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="hideFullyScheduledEvents" value={form['hideFullyScheduledEvents']} id="hideFullyScheduledEventsSwitch" checked={form['hideFullyScheduledEvents'] ? "checked" : ""} />
						<label className="custom-control-label small" htmlFor="hideFullyScheduledEventsSwitch" style={{paddingTop:3}}>Hide Fully Scheduled Events</label>
					</div>
				</div>
				<div className="">
					<div className="custom-control custom-switch">
						<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="hideFullyCompletedEvents" value={form['hideFullyCompletedEvents']} id="hideFullyCompletedEventsSwitch" checked={form['hideFullyCompletedEvents'] ? "checked" : ""} />
						<label className="custom-control-label small" htmlFor="hideFullyCompletedEventsSwitch" style={{paddingTop:3}}>Hide Fully Completed Events</label>
					</div>
				</div>
			</div>
		)
	}

	renderSavedCommitments() {
		const { selectedSavedFocusArea, selectedSavedOutcome, savedFocusAreasOptions, savedOutcomesOptions, buttonDisabled } = this.state;
		return(
			<div>
				{/* {this.props.commitments && this.props.commitments.length > 0 ?
					<div className="row">
							<div className="col-md-12">
								<div className="cursor-pointer float-right mb-2" onClick={() => this.setState(prevState => ({ showSavedFilterSection: !prevState.showSavedFilterSection }))}>
								<img src={"/images/Filter_Icon.png"} width={30} />
							</div>
						</div>
					</div>
				: 
				<div className="mt-4" /> } */}
				<div className="mt-2" />
				{this.state.showSavedFilterSection === true ?
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>FocusArea <span className="text-danger">*</span></label>
									<div>
										<ReactSelect
											name="focusArea"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={savedFocusAreasOptions}
											value={selectedSavedFocusArea}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={(focusArea) => this.handleSavedFocusAreaSelect(focusArea)}
											placeholder="Select Focus Area"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Project <span className="text-danger">*</span></label>
									<ReactSelect
										name="outcome"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={savedOutcomesOptions}
										value={selectedSavedOutcome}
										removeSelected={true}
										autosize={true}
										clearable={true}
										onSelectResetsInput={true}
										onChange={(outcome) => this.setState({
											selectedSavedOutcome: outcome,
											filteredSavedCommitments: []
										})}
										placeholder="Select Project"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm">Submit</button>
							</div>
						</div>
						<hr />
					</form>
				: null} 
				<div>
					<DragableTimeBlocks timeBlocks={this.state.commitments} events={this.props.events} onDoubleClick={this.props.onDoubleClick} />
				</div>
			</div>
		)
	}

	renderCurrentCommitments() {
		const { form, formErrors, focusAreasOptions, outcomesOptions, buttonDisabled } = this.state;
		return (
			<div>
				<div className="row">
						<div className="col-md-12">
							<div className="cursor-pointer float-right mb-2" onClick={() => this.setState(prevState => ({ showFilterSection: !prevState.showFilterSection }))}>
							<img src={"/images/Filter_Icon.png"} width={30} />
						</div>
					</div>
				</div>
				{this.state.showFilterSection === true ?
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>FocusArea <span className="text-danger">*</span></label>
									<div>
										<ReactSelect
											name="focusArea"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={focusAreasOptions}
											value={form.focusArea}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
											placeholder="Select Focus Area"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Project <span className="text-danger">*</span></label>
									<ReactSelect
										name="outcome"
										styles={SharedStyles.selectBoxStyles}
										closeOnSelect={false}
										options={outcomesOptions}
										value={form['outcome']}
										removeSelected={true}
										autosize={true}
										clearable={true}
										onSelectResetsInput={true}
										onChange={this.handleOutomeSelectField.bind(this, "outcome")}
										placeholder="Select Project"
									/>
									<p className="text-danger">{formErrors['outcome'] &&
										<span className="err">{formErrors['outcome']}</span>}</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm">Submit</button>
							</div>
						</div>
						<hr />
					</form>
				: null}
				<div>
					<DragableTimeBlocks timeBlocks={this.state.timeBlocks} events={this.props.events} onDoubleClick={this.props.onDoubleClick} />
				</div>
			</div>
		)
	}

	// Render Your Commitments Section
	render() {

		const { form, eventsLoading } = this.state;

		return (
			<div>
				<div className="clearfix mb-2 text-center">
					<ButtonGroup className="tracker-your-commitments">
						<Button 
							onClick={() => { 
								let formObj = {};
								formObj = {
									...form,
									hideFullyScheduledEvents: false,
									hideFullyCompletedEvents: false,
								};
								this.setState({
									activeCommitmentsTab: "Saved",
									form: formObj,
								})
							}}
							className={this.state.activeCommitmentsTab == "Saved" ? "active" : ""}>Saved</Button>
						<Button 
							onClick={() => { 
								this.resetFilterOptions(); 
								let formObj = {};
								formObj = {
									...form,
									hideFullyScheduledEvents: false,
									hideFullyCompletedEvents: false,
								};
								this.setState({
									activeCommitmentsTab: "Current",
									form: formObj,
								})
							}} 
							className={this.state.activeCommitmentsTab == "Current" ? "active" : ""}>Current</Button>
					</ButtonGroup>
					{/* <div className="cursor-pointer float-right mt-n4" onClick={() => this.setState(prevState => ({ showFilterSection: !prevState.showFilterSection }))}>
						<img src={"/images/Filter_Icon.png"} width={30} />
					</div> */}
				</div>

				{eventsLoading 
					? 
						<div className="row h-25 justify-content-center align-items-center">
							<div className="spinner-border color-primary" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					: 
						<div>
							{
								this.state.activeCommitmentsTab == 'Saved' ?
									<div>
										{this.renderHideEventsFilter()}
										<React.Fragment>
											{this.renderSavedCommitments()}
										</React.Fragment>
									</div>
								:
									<div>
										{this.renderHideEventsFilter()}
										<React.Fragment>
											{this.renderCurrentCommitments()}
										</React.Fragment>
									</div>
							}
						</div>
				}
			</div>
		);
	}

}

YourCommitments.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading })(
	YourCommitments
);
