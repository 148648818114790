import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { setLoading } from '../../actions/loadingActions.js';
import API_Services from '../../utils/API_Services';
import CompassTable from "./CompassTable";
import 'react-table-6/react-table.css'
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditCompass extends Component {

	constructor(props) {
		super(props);
		this.state = {
			reports: null,
			compassData: null,
			notesEditorState: null,
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {
		this.props.setLoading(true);
		this.getCompassRecord();
	}

	// Get Compass Record
	getCompassRecord() {

		const recordId = this.props.match.params.compassId;

		let postObj = {
			id: recordId,
			section: 'compass'
		};

		API_Services.httpPOST(UrlConstants.URLS.getCompassResponseByID, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if(response.data.data && response.data.data.compassResponse) {
					let compassData = response.data.data;
					this.setState({ compassData });
				}
				else {
					let errMsg = response.data.message || "No compass records found.";
					this.setFormApiResponse(errMsg, false);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	renderEditCompass() {

		let { compassData } = this.state;

		if (compassData == null || !compassData) {
			return (
				<div className="text-info small">No compass records found.</div>
			);
		}

		else {
			return(
				<CompassTable 
					data={compassData}
					disableArchiveFeature={true}
					stickyPos={115}
					getCompassData={this.getCompassRecord.bind(this)} />
			)
		};
	}

	render() {

		return (
			<div>
				<div className="page-content container child-container">
					<div className="bread-crumb">
						<a href="/compass/">Compass</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Edit</a>
					</div>
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									{this.renderEditCompass()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

EditCompass.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	EditCompass
);