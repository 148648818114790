import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import Checkbox from "../form-inputs/Checkbox";
import { Link } from "react-router-dom";

var _ = require('lodash');

class GCSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModalPopup: false,
            selectedCalendar: this.props.userPreference ? this.props.userPreference.selectedCalendar : '',
            excludedGoogleCalendarIDs: this.props.userPreference && this.props.userPreference.excludedGoogleCalendarIDs || []
        }
    }

    componentDidUpdate(prevProps) {

        // Typical usage (don't forget to compare props):
        if ((!prevProps.excludedGoogleCalendarIDs && this.props.excludedGoogleCalendarIDs) || (this.props.excludedGoogleCalendarIDs && this.props.excludedGoogleCalendarIDs.length !== prevProps.excludedGoogleCalendarIDs.length || !_.isEqual(this.props.excludedGoogleCalendarIDs.sort(), prevProps.excludedGoogleCalendarIDs.sort()))) {
            this.setState({
                selectedCalendar: this.props.userPreference ? this.props.userPreference.selectedCalendar : '',
                excludedGoogleCalendarIDs: this.props.excludedGoogleCalendarIDs
            });
        }
    }

    toggleModalPopup = () => {
        this.setState({
            showModalPopup: !this.state.showModalPopup
        });
    }

    handleSubmit = () => {

        if (this.state.selectedCalendar && this.props.userPreference && this.props.onUpdate) {

            let preferences = this.props.userPreference;
            preferences.excludedGoogleCalendarIDs = this.state.excludedGoogleCalendarIDs;

            this.props.onUpdate(preferences);
            this.toggleModalPopup();
        };
    }

    updateCalendarSelections = (value) => {

        var excludedGoogleCalendarIDs = this.state.excludedGoogleCalendarIDs;
        var index = this.state.excludedGoogleCalendarIDs.indexOf(value);

        index > -1? excludedGoogleCalendarIDs.splice(index, 1) : excludedGoogleCalendarIDs.push(value);
        this.setState({excludedGoogleCalendarIDs});
    }

    renderCalendarSelectModal = () => {

        const { userPreference, GCMetaData, calendarSubscribed } = this.props;
        let calendarList = GCMetaData && GCMetaData.calendarList ? GCMetaData.calendarList : [];
        const calendarSyncPreference = userPreference && userPreference.hasEnabledCalendarSync;
        const currentSyncStatus = GCMetaData ? GCMetaData.syncStatus : false;
        const selectedCalendar = userPreference && userPreference.selectedCalendar ? _.find(calendarList, {id: userPreference.selectedCalendar}) : null;

        calendarList = calendarList.map((item) => {
            return {
                label: item.summary,
                value: item.id
            };
        });

        return(
            <React.Fragment>
                <Modal isOpen={this.state.showModalPopup} toggle={this.toggleModalPopup.bind(this)}>
                    <ModalHeader toggle={this.toggleModalPopup.bind(this)}>Google Calendars</ModalHeader>
                    <ModalBody>
                        {
                            (!calendarSyncPreference || !currentSyncStatus) &&
                            <div className="text-blog mb-2">
                                <span className="small">Enable "Google Calendar Synchronization" in &nbsp;
                                    <Link to="/profile/#preferences" title="Sync with Calendar"><u className="text-info cursor-pointer">[Profile page]</u>
                                    </Link>.
                                </span>
                            </div>
                        }
                        {
                            calendarSyncPreference && currentSyncStatus &&
                            <div>
                                <div className="form-group">
                                    <label className="small">Select calendars to show the events in PBH calendar.</label>
                                    <div style={{lineHeight:2}}>
                                        <Checkbox
                                            orientation={'vertical'} 
                                            options={calendarList}
                                            checkboxGroupName={'selected-calendars'}
                                            unSelectedValues={this.state.excludedGoogleCalendarIDs}
                                            onChange={this.updateCalendarSelections}
                                        />
                                    </div>
                                </div>

                                {
                                    currentSyncStatus && userPreference && selectedCalendar &&
                                        <div className="mb-3 mt-n3 p-2" style={{backgroundColor: "#FFF5ED", borderRadius: 8}}>
                                            <i className="fa fa-info-circle color-primary" aria-hidden="true"></i>
                                            <span className="small ml-2">
                                                <span>Your Productivity By Habit events will be added to your Google account's <span className="color-primary">{selectedCalendar.summary}</span> calendar. </span>
                                            </span>
                                        </div>
                                }

                                <button type="button" className="btn btn-primary btn-sm" onClick={this.handleSubmit} disabled={!this.state.selectedCalendar}>Save Changes</button>
                            </div>
                        }
                        </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }

    render = () => {
        return(
            <React.Fragment>
                <span className="btn btn-primary btn-sm cursor-pointer" title="Sync with Calendar" onClick={this.toggleModalPopup.bind(this)}>
                    <i className="fa fa-calendar alt mr-2" aria-hidden="true"></i>
                    <i className="fa fa-link" aria-hidden="true"></i>
                </span>
                {this.renderCalendarSelectModal()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	GCSettings
);