import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

import { setLoading } from "../../actions/loadingActions.js";
import { getUserOutcomesWithPopulatedData } from "../../actions/outcomeActions";

import API_Services from '../../utils/API_Services';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class AddOutcome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				outcomeTitle: "",
				abbreviation: "",
				includeInWeeklyPlan: true,
				focusArea: null,
				outcome: "",
				successDefinition: "",
				creationMaintenance: "",
				bufferInPercentage: 10,
				rolesAndResponsibilities: [],
				collaborators: [],
				notes: null,
			},
			formErrors: {
				outcomeTitle: null,
				abbreviation: null,
				includeInWeeklyPlan: null,
				focusArea: null,
				outcome: null,
			},
			buttonDisabled: false,
		};
	}

	resetForm() {

		var formObj = {
			outcomeTitle: "",
			abbreviation: "",
			includeInWeeklyPlan: true,
			focusArea: null,
			outcome: "",
			successDefinition: "",
			creationMaintenance: "",
			bufferInPercentage: 10,
			rolesAndResponsibilities: [],
			collaborators: [],
			notes: {},
		};

		var formErrors = {
			outcomeTitle: null,
			abbreviation: null,
			includeInWeeklyPlan: null,
			focusArea: null,
			outcome: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
		});
	}

	componentDidMount() {

		const { form } = this.state;
		const focusArea = this.props.form && this.props.form.focusArea ? this.props.form.focusArea : null;

		if(focusArea) {
			form.focusArea = focusArea;
			this.setState({ form: form });
		}
	}

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		let filteredoutcomesOptions = [];

		if(value.value != 'all') {
			_.forEach(this.state.outcomesWithPopulatedData, function (eachOutcome) {
			
				if(eachOutcome.focusArea.id ===  value.value) {
					filteredoutcomesOptions.push(eachOutcome);
				}
			});
		} else {
			filteredoutcomesOptions = this.state.outcomesWithPopulatedData;
		}

		let outcomesOptions = [];
		filteredoutcomesOptions.forEach((eachOutcome) => {

			outcomesOptions.push({
				label: eachOutcome.outcomeTitle,
				value: eachOutcome.id,
				focusAreaID: eachOutcome.focusArea.id
			});
		});

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "outcomeTitle":
				if (!value) errorMsg = "Please enter Project Title";
				break;

			case "abbreviation":
				if (!value) errorMsg = "Please enter Project Abbreviation";
				break;

			case "focusArea":
				if (!value) errorMsg = "Please select a Focus Area";
				break;

			case "outcome":
				if (!value) errorMsg = "Please enter Outcome";
				break;
			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for submit
	handleSubmit = (e, nextSection) => {

		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		}
		else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {
				if (Array.isArray(value)) {

					if(key === "rolesAndResponsibilities" || key === "collaborators") {
						var newArr = [];
						value.forEach((eachValue, index) => {
							newArr.push(eachValue.value);
						});
						formCopy[key] = newArr;
					}
				} 
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			formCopy['notes'] = null;
			this.createNewOutcome(formCopy, nextSection || "");
		}
	}

	// Save new outcome in Backend
	createNewOutcome(data, nextSection) {

		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		// console.log('postObj ...', postObj);
		API_Services.httpPOST(UrlConstants.URLS.addNewOutcome, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					this.props.onSuccussSubmit(response.data.data, nextSection)
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	renderFooterButtons() {

		const { buttonDisabled } = this.state;

		return(
			<div className="row">
				<div className="col-sm-4">
					<button type="button" onClick={(e)=>this.handleSubmit(e)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm" style={{marginRight: 10}}>Save & Return</button>
				</div>
				<div className="col-sm-8">
					<div className="text-right">
						<button type="button" onClick={(e)=>this.handleSubmit(e,"ADD_NEW_KA")} disabled={buttonDisabled} className="btn btn-primary btn-sm" style={{marginRight: 10}}>Save & Add New Key Activity</button>
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel} className="btn-sm">Cancel</Button>
					</div>
				</div>
			</div>
		);
	}

	renderAddNewOutcome() {

		const { form, formErrors } = this.state;

		return (
			<div>
				<ModalHeader toggle={this.props.onClose}>Add Project</ModalHeader>
				<ModalBody>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Title<span className="text-danger">*</span></label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="outcomeTitle" value={form['outcomeTitle']} />
										<p className="text-danger">{formErrors['outcomeTitle'] &&
											<span className="err">{formErrors['outcomeTitle']}</span>}</p>
									</div>
								</div>
								<div className="form-group">
									<label>Project abbreviation<span className="text-danger">*</span></label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="abbreviation" value={form['abbreviation']} />
										<p className="text-danger">{formErrors['abbreviation'] &&
											<span className="err">{formErrors['abbreviation']}</span>}</p>
									</div>
								</div>
								{/* <div className="form-group">
									<label>FocusArea<span className="text-danger">*</span></label>
									<div>
										<ReactSelect
											name="focusArea"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={focusAreasOptions}
											value={form.focusArea}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
											placeholder="Select Focus Area"
										/>
										<p className="text-danger">{formErrors['focusArea'] &&
											<span className="err">{formErrors['focusArea']}</span>}</p>
									</div>
								</div> */}
								<div className="form-group">
									<label>Outcome<span className="text-danger">*</span></label>
									<div>
										<TextareaAutosize
											onChange={this.handleInputField.bind(this)}
											className="form-control"
											minRows={2}
											maxRows={10}
											name="outcome"
											value={form['outcome']}>
												{form['outcome']}
										</TextareaAutosize>
										<p className="text-danger">{formErrors['outcome'] &&
											<span className="err">{formErrors['outcome']}</span>}</p>
									</div>
								</div>
								{/* <div className="form-group">
									<label>
										<div className="custom-control custom-switch">
											<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="includeInWeeklyPlan" value={form['includeInWeeklyPlan']} id="includeInWeeklyPlanSwitch" checked={form['includeInWeeklyPlan'] ? "checked" : ""} />
											<label className="custom-control-label" htmlFor="includeInWeeklyPlanSwitch">Include In Weekly Plan <span className="text-danger">*</span></label>
											<p className="text-danger">{formErrors['includeInWeeklyPlan'] &&
												<span className="err">{formErrors['includeInWeeklyPlan']}</span>}</p>
										</div>
									</label>
								</div> */}
							</div>
						</div>
						{this.renderFooterButtons()}
					</form>
				</ModalBody>
			</div>
		)
	}

	// Render

	render() {

		return (
			<div>
				{this.renderAddNewOutcome()}
			</div>
		);
	}

}

AddOutcome.propTypes = {
	setLoading: PropTypes.func.isRequired,
	getUserOutcomesWithPopulatedData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomesWithPopulatedData })(
	AddOutcome
);
