import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import { resetTimeBlockData } from "../../actions/timeBlockActions";
import TimeBlockTypeSelect from "./TimeBlockTypeSelect";
import TimeDurationSelect from "../form-inputs/TimeDurationSelect";
import TimeIntervalSelect from "../form-inputs/TimeIntervalSelect";
import RadioSelector from "../form-inputs/RadioSelector";

import Util from '../../utils/Util';
import { parseInt } from "lodash";
var _ = require('lodash');

const regularityOptions = [
	{label: 'Daily', value: 'daily'},
	{label: 'Weekly', value: 'weekly'},
	{label: 'Non-Weekly', value: 'non-weekly'},
];

const DailyRecurringIntervals = {
	DAILY: 'daily',
	EVERY_X_DAYS: 'every_x_days'
};

class TimeBlockForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				type: null,
				durationInMinutes: null,
				regularity: 'weekly',
				recurringInterval: 1,
				sessionsPerWeek: "",
				interval: ""
			},
			formErrors: {
				title: null,
				type: null,
				durationInMinutes: null,
				regularity: null,
				recurringInterval: null,
				sessionsPerWeek: null,
				interval: ""
			},
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { durationInMinutes, type } = this.props.initialValues;

			var formObj = {};

			formObj = {
				...this.props.initialValues,
				...formObj,
			};

			if (!_.has(formObj, 'regularity')) {
				formObj['regularity'] = 'weekly';
			}

			this.setState({
				form: formObj,
				isEdit: true,
			});
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == "sessionsPerWeek" || name == "recurringInterval") {
			if (!isNaN(parseInt(value))) {
				value = parseInt(value);
			};
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			let refValue = null;

			if (name == 'sessionsPerWeek') {
				refValue = form['regularity'];
			}
			else if (name == "recurringInterval") {
				refValue = form['interval'];
			};
			const errorMsg = this.validateField(name, value, refValue);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		if (_.isObject(value) && value.value) {
			value = _.isObject(value) && value.value;
		};

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		if (name == 'interval') { /* Set default recurring interval. */
			if (value == 'every_x_months') {
				formObj.recurringInterval = 1;
			}
			else if (value == 'every_x_years' || value == 'every_x_days') {
				formObj.recurringInterval = 2;
			}
			else if (value == 'every_x_weeks') {
				formObj.recurringInterval = 3;
			};
		};

		this.setState({
			form: formObj,
		}, () => {

			let refValue = null;

			if (name == 'interval') {
				refValue = form['regularity'];
			};
			const errorMsg = this.validateField(name, value, refValue);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Time Block Title";
				break;

			case "type":
				if (!value) errorMsg = "Please select Time Block Type";
				break;

			case "durationInMinutes":
				if (!value) errorMsg = "Please enter Duration";
				break;

			case "regularity":
				if (!value) errorMsg = "Please choose the Regularity";
				break;

			case "sessionsPerWeek":
				if (refValue == 'weekly') {
					if (!value) errorMsg = "Please enter # of Sessions";
					if (!Util.validateNumber(value)) errorMsg = "Sessions should be a valid number";
				};
				break;
			
			case "interval":
				if (refValue == 'non-weekly' && !value) {
					errorMsg = "Please specify the Interval";
				};
				break;

			case "recurringInterval":
				if (refValue == 'every_x_months') {
					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < 1 || parseInt(value) > 12)) {
						errorMsg = "The value should be between 1 and 12";
					};
				}
				else if (refValue === 'every_x_weeks' || refValue === 'every_x_years') {

					let minValue = refValue === 'every_x_weeks'? 3: 2;

					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < minValue || parseInt(value) > 15)) {
						errorMsg = `The value should be between ${minValue} and 15`;
					};
				}
				else if (refValue === 'daily' || refValue === 'every_x_days') {

					let minValue = 1;

					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < 0 || parseInt(value) > 15)) {
						errorMsg = `The value should be between ${minValue} and 15`;
					};
				};
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			if (x == 'sessionsPerWeek' || x == 'interval') {
				refValue = form['regularity'];
			}
			else if (x == 'recurringInterval') {
				refValue = form['interval'];
			};
			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for TimeBlock submit
	handleTimeBlockSubmit(e) {
		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {

					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			if (formCopy.regularity == 'daily') {
				formCopy.interval = formCopy.recurringInterval == 1? DailyRecurringIntervals.DAILY : DailyRecurringIntervals.EVERY_X_DAYS;
				formCopy.sessionsPerWeek = Math.ceil(7 / (formCopy.recurringInterval || 1));
				formCopy.durationInMinutesPerWeek = Util.computeWeeklyTimeDurationForADailyTimeBlock(formCopy.durationInMinutes, formCopy.interval, formCopy.recurringInterval);
			}
			else if (formCopy.regularity == 'non-weekly') {
				formCopy.durationInMinutesPerWeek = Util.computeWeeklyTimeDurationForANonWeeklyTimeBlock((formCopy.durationInMinutes || 0), formCopy.interval, formCopy.recurringInterval);
				formCopy.sessionsPerWeek = 0;
			}
			else {
				formCopy.durationInMinutesPerWeek = (formCopy.durationInMinutes || 0) * (formCopy.sessionsPerWeek || 0);
				formCopy.interval = '';
			};
			this.props.onSubmit(formCopy);
		}
	}

	render() {

		const { form, formErrors, isEdit } = this.state;

		return (
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Time Block Title <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
								<p className="text-danger">{formErrors['title'] &&
									<span className="err">{formErrors['title']}</span>}</p>
							</div>
						</div>
						<TimeBlockTypeSelect
							handleTimeBlockTypeSelect={this.handleSelectField.bind(this)}
							value={form.type}
							error={formErrors.type}
							isRequired={true}
						/>
						<div>
							<TimeDurationSelect
								handleTimeDurationSelect={this.handleSelectField.bind(this)}
								value={form.durationInMinutes}
								error={formErrors.durationInMinutes}
								isRequired={true}
							/>
						</div>
						<div className="form-group">
							<label>Regularity <span className="text-danger">*</span></label>
							<RadioSelector
								orientation={'horizontal'} 
								options={regularityOptions}
								radioGroupName={'regularity'}
								selectedValue={form['regularity'] ? form['regularity'] : 'weekly'}
								onChange={this.handleSelectField.bind(this)}
							/>
							<p className="text-danger">{formErrors['regularity'] &&
									<span className="err">{formErrors['regularity']}</span>}</p>
						</div>
						{form['regularity'] && form['regularity'] == 'non-weekly' &&
							<div>
								<TimeIntervalSelect
									handleTimeIntervalSelect={this.handleSelectField.bind(this)}
									value={form.interval}
									error={formErrors.interval}
									isRequired={true}
								/>
								{form['interval'] == 'every_x_months' ?
									<div className="form-group">
										<label>Recurring Interval <span className="ml-1 small">(Please specify the months X in number)</span> <span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="recurringInterval" value={form['recurringInterval']} />
											<p className="text-danger">{formErrors['recurringInterval'] &&
												<span className="err">{formErrors['recurringInterval']}</span>}</p>
										</div>
									</div>
								: null}
								{(form['interval'] === 'every_x_weeks' || form['interval'] === 'every_x_years') &&
									<div className="form-group">
										<label>Recurring Interval <span className="ml-1 small">(Please specify the {form['interval'] === 'every_x_weeks'? 'weeks' : 'years'} X in number)</span> <span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="recurringInterval" value={form['recurringInterval']} />
											<p className="text-danger">{formErrors['recurringInterval'] &&
												<span className="err">{formErrors['recurringInterval']}</span>}</p>
										</div>
									</div>
								}
							</div>
						}
						{form['regularity'] && form['regularity'] == 'weekly' &&
							<div className="form-group">
								<label># of Sessions (per week) <span className="text-danger">*</span></label>
								<div>
									<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="sessionsPerWeek" value={form['sessionsPerWeek']} />
									<p className="text-danger">{formErrors['sessionsPerWeek'] &&
										<span className="err">{formErrors['sessionsPerWeek']}</span>}</p>
								</div>
							</div>
						}
						{ form['regularity'] && form['regularity'] == 'daily' &&
							<div className="form-group">
								<label>Recurring Interval <span className="ml-1 small">(Please specify the day X in number.)</span> <span className="text-danger">*</span></label>
								<div>
									<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="recurringInterval" value={form['recurringInterval']} />
									<p className="text-danger">{formErrors['recurringInterval'] &&
										<span className="err">{formErrors['recurringInterval']}</span>}</p>
								</div>
							</div>
						}
					</div>
				</div>

				<button type="button" onClick={this.handleTimeBlockSubmit.bind(this)} className="btn btn-primary mb-2">{isEdit ? "Save" : "Save & Close"}</button>
				{
					!_.isEmpty(this.props.formApiResponse)
						?
						<p className={this.props.formApiResponse.className}><span>{this.props.formApiResponse.message}</span></p>
						: null
				}
			</form>
		);

	}
}

TimeBlockForm.propTypes = {
	resetTimeBlockData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetTimeBlockData, setLoading })(
	TimeBlockForm
);
