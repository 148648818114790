import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import TimeScope from "../form-inputs/TimeScope";

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";

import TimeBlockTypeSelect from "../time-blocks/TimeBlockTypeSelect";
import TimeDurationSelect from "../form-inputs/TimeDurationSelect";
import TimeDurationPastPerformanceSelect from "../form-inputs/TimeDurationPastPerformanceSelect";
import TimeIntervalSelect from "../form-inputs/TimeIntervalSelect";
import TimeIntervalPastPerformanceSelect from "../form-inputs/TimeIntervalPastPerformanceSelect";
import RadioSelector from "../form-inputs/RadioSelector";

import API_Services from '../../utils/API_Services';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

const regularityOptions = [
	{label: 'Daily', value: 'daily'},
	{label: 'Weekly', value: 'weekly'},
	{label: 'Non-Weekly', value: 'non-weekly'},
];

const DailyRecurringIntervals = {
	DAILY: 'daily',
	EVERY_X_DAYS: 'every_x_days'
};

class TimeBlockForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				type: null,
				durationInMinutes: null,
				durationInMinutesPastPerformance: null,
				regularity: 'weekly',
				sessionsPerWeek: "",
				sessionsPerWeekPastPerformance: "",
				interval: "",
				intervalPastPerformance: "",
				recurringInterval: 1,
				recurringIntervalPastPerformance: 1,
			},
			formErrors: {
				title: null,
				type: null,
				durationInMinutes: null,
				durationInMinutesPastPerformance: null,
				regularity: null,
				sessionsPerWeek: null,
				sessionsPerWeekPastPerformance: null,
				interval: null,
				intervalPastPerformance: null,
				recurringInterval: null,
				recurringIntervalPastPerformance: null,
			},
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		const { timeBlockObj } = this.props;

		if (!Util.isEmpty(timeBlockObj)) {

			var formObj = { ...timeBlockObj };

			if (!_.has(formObj, 'regularity')) {
				formObj['regularity'] = 'weekly';
			}

			this.setState({
				form: formObj,
				isEdit: true,
			});
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == "sessionsPerWeek" || name == 'sessionsPerWeekPastPerformance' || name == "recurringInterval" || name == "recurringIntervalPastPerformance") {
			if (!isNaN(parseInt(value))) {
				value = parseInt(value);
			};
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			let refValue = null;

			if (name == 'sessionsPerWeek') {
				refValue = form['regularity'];
			}
			else if (name == "recurringInterval") {
				refValue = form['interval'];
			};
			const errorMsg = this.validateField(name, value, refValue);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		if (_.isObject(value) && value.value) {
			value = _.isObject(value) && value.value;
		};
		
		var formObj = {};

		if (!value) {
			formObj = {
				...form,
				[name]: "",
			};
			this.setState({
				form: formObj,
			})
			return;
		}

		formObj = {
			...form,
			[name]: value,
		};

		if (name === 'interval' || name === 'intervalPastPerformance') { /* Set default recurring interval. */

			let recurringIntervalKey = 'recurringInterval';

			if (name === 'intervalPastPerformance') {
				recurringIntervalKey += 'PastPerformance'
			};

			if (formObj[name] === '') { // If cleared reset the recurring interval
				formObj[recurringIntervalKey] = null;
				return
			};

			if (value == 'every_x_months') {
				formObj[recurringIntervalKey] = 1;
			}
			else if (value == 'every_x_years' || value == 'every_x_days') {
				formObj[recurringIntervalKey] = 2;
			}
			else if (value == 'every_x_weeks') {
				formObj[recurringIntervalKey] = 3;
			};
		};

		this.setState({
			form: formObj,
		}, () => {

			let refValue = null;

			// Only current is mandatory, past performance is optional so no intervalPastPerformance
			if (name == 'interval') {
				refValue = form['regularity'];
			};
			const errorMsg = this.validateField(name, value, refValue);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		const { isEdit, form } = this.state;
		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Time Block Title";
				break;

			case "type":
				if (!value) errorMsg = "Please select Time Block Type";
				break;

			case "durationInMinutes":
				if (!value) errorMsg = "Please enter Duration (Current Target)";
				break;

			// case "durationInMinutesPastPerformance":
			// 	if (!value) errorMsg = "Please enter Duration (Past Performance)";
			// 	break;

			case "regularity":
				if (!value) errorMsg = "Please choose the Regularity";
				break;

			case "sessionsPerWeek":
				if (refValue == 'weekly') {
					if (!value) errorMsg = "Please enter # of Sessions";
					if (!Util.validateNumber(value)) errorMsg = "Sessions should be a valid number";
				};
				break;

			case "interval":
				if (refValue == 'non-weekly' && !value) {
					errorMsg = "Please specify the Interval";
				};
				break;

			// case "sessionsPerWeekPastPerformance":
			// 	if (!value) errorMsg = "Please enter # of Sessions (Past Performance)";
			// 	if (!Util.validateNumber(value)) errorMsg = "Sessions should be a valid Number";
			// 	break;

			case "recurringInterval":
				if (refValue == 'every_x_months') {
					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < 1 || parseInt(value) > 12)) {
						errorMsg = "The value should be between 1 and 12";
					};
				}
				else if (refValue === 'every_x_weeks' || refValue === 'every_x_years') {

					let minValue = refValue === 'every_x_weeks'? 3: 2;

					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < minValue || parseInt(value) > 15)) {
						errorMsg = `The value should be between ${minValue} and 15`;
					};
				}
				else if (refValue === 'daily' || refValue === 'every_x_days') {

					let minValue = 1;

					if (!value) {
						errorMsg = "Please specify the Recurring Interval";
					}
					else if (value && (parseInt(value) < 0 || parseInt(value) > 15)) {
						errorMsg = `The value should be between ${minValue} and 15`;
					};
				};
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			if (x == 'sessionsPerWeek' || x == 'interval') {
				refValue = form['regularity'];
			}
			else if (x == 'recurringInterval') {
				refValue = form['interval'];
			};

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for submit
	handleSubmit = (e, nextSection) => {

		e.preventDefault();

		const { form, formErrors, isEdit } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		}
		else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {
				if (Array.isArray(value)) {
				}
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			if (formCopy.regularity == 'daily') {

				// Daily
				// Current
				let sessionsPerWeek = Math.ceil(7 / (formCopy.recurringInterval || 1));
				formCopy.interval = formCopy.recurringInterval == 1? DailyRecurringIntervals.DAILY : DailyRecurringIntervals.EVERY_X_DAYS;
				formCopy.sessionsPerWeek = sessionsPerWeek;
				formCopy.durationInMinutesPerWeek = Util.computeWeeklyTimeDurationForADailyTimeBlock(formCopy.durationInMinutes, formCopy.interval, formCopy.recurringInterval);

				// Past Performance
				let sessionsPerWeekPastPerformance = Math.ceil(7 / (formCopy.recurringIntervalPastPerformance || 1));
				formCopy.intervalPastPerformance = formCopy.recurringIntervalPastPerformance == 1? DailyRecurringIntervals.DAILY : DailyRecurringIntervals.EVERY_X_DAYS;
				formCopy.sessionsPerWeekPastPerformance = sessionsPerWeekPastPerformance;
				formCopy.durationInMinutesPerWeekPastPerformance = Util.computeWeeklyTimeDurationForADailyTimeBlock(formCopy.durationInMinutesPastPerformance || 0, formCopy.intervalPastPerformance, formCopy.recurringIntervalPastPerformance);
			}
			else if (formCopy.regularity == 'non-weekly') {

				// Current
				formCopy.durationInMinutesPerWeek = Util.computeWeeklyTimeDurationForANonWeeklyTimeBlock((formCopy.durationInMinutes || 0), formCopy.interval, formCopy.recurringInterval);
				formCopy.sessionsPerWeek = 0;

				// Past Performance
				formCopy.durationInMinutesPerWeekPastPerformance = Util.computeWeeklyTimeDurationForANonWeeklyTimeBlock((formCopy.durationInMinutesPastPerformance || 0), formCopy.intervalPastPerformance, formCopy.recurringIntervalPastPerformance);
				formCopy.sessionsPerWeekPastPerformance = 0;
			}
			else {

				// Current
				formCopy.durationInMinutesPerWeek = (formCopy.durationInMinutes || 0) * (formCopy.sessionsPerWeek || 0);
				formCopy.interval = '';

				// Past Performance
				formCopy.durationInMinutesPerWeekPastPerformance = (formCopy.durationInMinutesPastPerformance || 0) * (formCopy.sessionsPerWeekPastPerformance || 0);
				formCopy.intervalPastPerformance = '';
			};

			this.createOrUpdateTimeBlock(formCopy, nextSection || "");
		}
	}

	createOrUpdateTimeBlock(data, nextSection) {

		if (nextSection) {

		}

		if (this.state.isEdit) {
			this.props.editKATimeBlockInList(data, nextSection);
		} else {
			data['id'] = Util.generateObjectId();
			this.props.addKATimeBlockInList(data, nextSection);
		}
	}

	renderAddOrEditKATimeBlock() {

		const { form, formErrors, isEdit } = this.state;

		let intervalLabel = '';

		if (form['intervalPastPerformance'] || form['interval']) {

			let interval = '';

			if (form['intervalPastPerformance'] == 'every_x_months' || form['interval'] == 'every_x_months') {
				interval = 'months';
			}

			else if (form['intervalPastPerformance'] == 'every_x_years' || form['interval'] == 'every_x_years') {
				interval = 'years';
			}

			else if (form['intervalPastPerformance'] == 'every_x_weeks' || form['interval'] == 'every_x_weeks') {
				interval = 'weeks';
			}

			else if (form['regularity'] == 'daily') {
				interval = 'days';
			};

			intervalLabel = `(Please specify the ${interval} X in number)`;
		};

		return (
			<div>
				<ModalHeader toggle={this.props.onClose}>{isEdit ? "Edit" : "Add"} Time Block</ModalHeader>
				<ModalBody>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Time Block Title <span className="text-danger">*</span></label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
										<p className="text-danger">{formErrors['title'] &&
											<span className="err">{formErrors['title']}</span>}</p>
									</div>
								</div>
								<TimeBlockTypeSelect
									handleTimeBlockTypeSelect={this.handleSelectField.bind(this)}
									value={form.type}
									error={formErrors.type}
									isRequired={true}
								/>
								<div>
									<TimeDurationPastPerformanceSelect
										handleTimeDurationSelect={this.handleSelectField.bind(this)}
										value={form.durationInMinutesPastPerformance}
										error={formErrors.durationInMinutesPastPerformance}
										// isRequired={true}
									/>
								</div>
								<div>
									<TimeDurationSelect
										handleTimeDurationSelect={this.handleSelectField.bind(this)}
										value={form.durationInMinutes}
										error={formErrors.durationInMinutes}
										isRequired={true}
									/>
								</div>
								<div className="form-group">
									<label>Regularity <span className="text-danger">*</span></label>
									<RadioSelector
										orientation={'horizontal'} 
										options={regularityOptions}
										radioGroupName={'regularity'}
										selectedValue={form['regularity'] ? form['regularity'] : 'weekly'}
										onChange={this.handleSelectField.bind(this)}
									/>
									<p className="text-danger">{formErrors['regularity'] &&
											<span className="err">{formErrors['regularity']}</span>}</p>
								</div>
								{form['regularity'] && (form['regularity'] == 'non-weekly' || form['regularity'] == 'daily') &&
									<React.Fragment>
										<div>
											{ form['regularity'] !== 'daily' &&
												<TimeIntervalPastPerformanceSelect
													handleTimeIntervalSelect={this.handleSelectField.bind(this)}
													value={form.intervalPastPerformance}
													error={formErrors.intervalPastPerformance}
													// isRequired={true}
												/>
											}
											{form['intervalPastPerformance'] == 'every_x_months' || form['intervalPastPerformance'] == 'every_x_weeks' || form['intervalPastPerformance'] == 'every_x_years' || form['regularity'] == 'daily' ?
												<div className="form-group">
													<label>Recurring Interval (Past Performance)<span className="ml-1 small">{intervalLabel}</span> <span className="text-danger">*</span></label>
													<div>
														<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="recurringIntervalPastPerformance" value={form['recurringIntervalPastPerformance']} />
														<p className="text-danger">{formErrors['recurringIntervalPastPerformance'] &&
															<span className="err">{formErrors['recurringIntervalPastPerformance']}</span>}</p>
													</div>
												</div>
											: null}
										</div>
										<div>
											{ form['regularity'] !== 'daily' &&
												<TimeIntervalSelect
													handleTimeIntervalSelect={this.handleSelectField.bind(this)}
													value={form.interval}
													error={formErrors.interval}
													isRequired={true}
												/>
											}
											{form['interval'] == 'every_x_months' || form['interval'] == 'every_x_weeks' || form['interval'] == 'every_x_years' || form['regularity'] == 'daily' ?
												<div className="form-group">
													<label>Recurring Interval <span className="ml-1 small">{intervalLabel}</span> <span className="text-danger">*</span></label>
													<div>
														<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="recurringInterval" value={form['recurringInterval']} />
														<p className="text-danger">{formErrors['recurringInterval'] &&
															<span className="err">{formErrors['recurringInterval']}</span>}</p>
													</div>
												</div>
											: null}
										</div>
									</React.Fragment>
								}
								{form['regularity'] && form['regularity'] == 'weekly' &&
									<React.Fragment>
										<div className="form-group">
											<label># of Sessions (per week) (Past Performance)</label>
											<div>
												<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="sessionsPerWeekPastPerformance" value={form['sessionsPerWeekPastPerformance']} />
												<p className="text-danger">{formErrors['sessionsPerWeekPastPerformance'] &&
													<span className="err">{formErrors['sessionsPerWeekPastPerformance']}</span>}</p>
											</div>
										</div>
										<div className="form-group">
											<label># of Sessions (per week) <span className="text-danger">*</span></label>
											<div>
												<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="sessionsPerWeek" value={form['sessionsPerWeek']} />
												<p className="text-danger">{formErrors['sessionsPerWeek'] &&
													<span className="err">{formErrors['sessionsPerWeek']}</span>}</p>
											</div>
										</div>
									</React.Fragment>
								}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="row">
									<div className="col-sm-4">
										<button type="button" onClick={(e) => this.handleSubmit(e)} className="btn btn-primary btn-sm">Save & Close</button>
									</div>
									<div className="col-sm-8">
										<div className="text-right">
											<Button color="secondary" title="Cancel" onClick={this.props.onCancel} className="btn-sm">Cancel</Button>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										{/* <div className="mt-2"><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
									</div>
								</div>
							</div>
						</div>
					</form>
				</ModalBody>
			</div>
		);
	}

	// Render

	render() {

		return (
			<div>
				{this.renderAddOrEditKATimeBlock()}
			</div>
		);
	}

}

TimeBlockForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	TimeBlockForm
);
