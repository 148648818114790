import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { getUserSubscriptions } from "../../actions/subscriptionLockActions";

var moment = require('moment');
var UrlConstants = require('../../utils/UrlConstants');

class SubscriptionHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activationCode: '',
            responseRecords: [],
            apiResponse: null
        };
    }

    handleCodeChange(e) {
        
        this.setState({
            activationCode: e.target.value.toUpperCase(),
            apiResponse: null
        });
    }

    handleSubmit() {

        if (!this.state.activationCode && this.state.apiResponse) return;

        this.props.setLoading(true);

        let postObj = {
            activationCode: this.state.activationCode
        };

        API_Services.httpPOST(UrlConstants.URLS.subscribeUsingActivationCode, postObj, (err, response) => {

			if (err) {
                
                if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
                else {
					this.setApiResponse("Something went wrong!", true);
				};
			} 
            else if (response.data) {
				
                if (response.data.data) {
                    
                    this.setState({
                        responseRecords: response.data.data
                    }, () => {
                        this.props.getUserSubscriptions();return;
                    });
                }
                else if (response.data.message) {
                    this.setApiResponse(response.data.message, true);
                };
			} 
            else {
				this.setApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
    }

    setApiResponse(message, hasError) {
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	render() {
        
        let {responseRecords} = this.state;

        if (responseRecords && responseRecords.length > 0) {

            return(
                <div className="page-content container child-container">
                    <div style={styles.outerContainer}>
                        <div>
                            <h5>Subscription Successful &nbsp;<i className="fa fa-check-circle fa-lg color-green" aria-hidden="true"></i></h5>
                            <div>
                                <p className="my-3">Congratulations! You have successfully unlocked the below module(s).</p>
                                <ol style={{lineHeight:2}}>
                                    {responseRecords.map((subscriptionObj, index) => {
                                        return <li key={index}><span className="font-bold">{subscriptionObj.moduleAlias}</span> - [Validity - {subscriptionObj.expirationDate ? moment(subscriptionObj.expirationDate).format('MM/DD/YYYY') : 'Lifetime'}].</li>
                                    })}
                                </ol>
                            </div>
                            <div className="small">For support, kindly email us to  <a href="mailto:hello@triumphhq.com" className="color-primary">hello@triumphhq.com</a></div>
                        </div>
                    </div>
                </div>
            );
        };

        return(
            <div className="page-content container child-container">
                <div style={styles.outerContainer}>
                    <div>
                        <h5>Subscription Required</h5>
                        <div>
                            <p className="my-3">Enter the activation code you were given to unlock the module(s).</p>
                            <div><input type="text" className="form-control" value={this.state.activationCode} placeholder="Enter code" onChange={this.handleCodeChange.bind(this)}/></div>
                            {this.state.apiResponse ?
                                <div className={'small mt-1 ' + this.state.apiResponse.className}>{this.state.apiResponse.message}</div>
                            : null}
                            <div style={styles.btnBackground} className="my-4"><button type="submit" className="btn btn-primary btn-sm" onClick={this.handleSubmit.bind(this)} disabled={!this.state.activationCode}>Submit</button></div>
                        </div>
                        <div className="small">For support, kindly email us to  <a href="mailto:hello@triumphhq.com" className="color-primary">hello@triumphhq.com</a></div>
                    </div>
                </div>
            </div>
        );
	}
}

SubscriptionHome.propTypes = {
    setLoading: PropTypes.func.isRequired,
	getUserSubscriptions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading, getUserSubscriptions })(
	SubscriptionHome
);

const styles = {

    outerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: '#fef2ec',
        borderRadius: 10,
        padding: 20,
        border: '1px solid #f79569'
    },
    btnBackground: {
        backgroundColor: '#fff', 
        width:'fit-content'
    }
}