import { combineReducers } from "redux";
import authReducer from "./authReducer.js";
import loadingReducer from "./loadingReducer.js";
import formResetReducer from "./formResetReducer.js";
import currentSprintReducer from "./currentSprintReducer.js";
import focusAreaReducer from "./focusAreaReducer.js";
import outcomeReducer from "./outcomeReducer.js";
import keyActivityReducer from "./keyActivityReducer.js";
import timeBlockReducer from "./timeBlockReducer.js";
import taskReducer from "./taskReducer.js";
import rolesAndResponsibilityReducer from "./rolesAndResponsibilityReducer.js";
import collaboratorReducer from "./collaboratorReducer.js";
import noteReducer from "./noteReducer.js";
import subscriptionLockReducer from "./subscriptionLockReducer.js";

export default combineReducers({
	auth: authReducer,
	loading: loadingReducer,
	resetform: formResetReducer,
	currentsprint: currentSprintReducer,
	focusareas: focusAreaReducer,
	outcomes: outcomeReducer,
	keyactivities: keyActivityReducer,
	timeblocks: timeBlockReducer,
	tasks: taskReducer,
	rolesandresponsibilities: rolesAndResponsibilityReducer,
	collaborators: collaboratorReducer,
	notes: noteReducer,
	usersubscriptions: subscriptionLockReducer,
});
