import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import ProjectForm from "./ProjectForm";
import KeyActivityForm from "./KeyActivityForm";
import MetricForm from "./MetricForm";
import TimeBlockForm from "./TimeBlockForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ProjectsList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			visibleKADetailsSection: [], // An Array that contains uniqueID's of KA details section that are visible
			deleteModal: false,
			itemToRemove: null,
		};
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	componentDidMount() {

	}

	chooseDefaultProjectModal(displayModalSection) {
		this.setState({
			chosenProject: null,
		}, () => {
			this.toggleModal(displayModalSection);
		})
	}

	addEditDeleteProjectOrKAModal(displayModalSection, project, ka = null) {

		if (this.state.mainModal) {
			this.setState({
				chosenProject: null,
				chosenKeyActivity: null,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		} else {
			this.setState({
				chosenProject: project,
				chosenKeyActivity: ka,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		}
	}

	// Project metric toggle
	projectAddEditDeleteMetricModal(displayModalSection, project, metric = null) {

		if (this.state.mainModal) {

			this.setState({
				chosenProject: null,
				chosenProjectMetric: null,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		} else {

			this.setState({
				chosenProject: project,
				chosenProjectMetric: metric,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		}
	}

	// Project KA toggle
	projectKAModal(displayModalSection, project, keyActivity) {

		if (this.state.mainModal) {

			this.setState({
				chosenProject: null,
				chosenKeyActivity: null,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		} else {

			this.setState({
				chosenProject: project,
				chosenKeyActivity: keyActivity,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		}
	}

	// Project KA metric toggle
	projectKAAddEditDeleteMetricModal(displayModalSection, project, keyActivity, metric = null) {

		if (this.state.mainModal) {

			this.setState({
				chosenProject: null,
				chosenKeyActivity: null,
				chosenProjectKAMetric: null,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		} else {

			this.setState({
				chosenProject: project,
				chosenKeyActivity: keyActivity,
				chosenProjectKAMetric: metric,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		}
	}

	// Project KA timeblock toggle
	projectKAAddEditDeleteTimeBlockModal(displayModalSection, project, keyActivity, timeblock = null) {

		if (this.state.mainModal) {

			this.setState({
				chosenProject: null,
				chosenKeyActivity: null,
				chosenProjectKATimeBlock: null,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		} else {

			this.setState({
				chosenProject: project,
				chosenKeyActivity: keyActivity,
				chosenProjectKATimeBlock: timeblock,
			}, () => {
				this.toggleModal(displayModalSection);
			})
		}
	}

	toggleModal(displayModalSection = "") {

		if (this.state.mainModal) {

			this.setState({
				mainModal: false,
				displayModalSection: "",
				// itemToEdit: null,
				// itemToRemove: null,
			});
		}
		else {

			// If add key activity project open set the parent project
			if (displayModalSection == "KA_LISTS" || displayModalSection == "ADD_NEW_KA") {

				this.setState({
					mainModal: true,
					displayModalSection: displayModalSection,
				});
			} else {
				this.setState({
					mainModal: true,
					displayModalSection: displayModalSection,
					// itemToEdit: row,
					// itemToRemove: row,
				});
			}
		}
	}

	renderModal() {

		const { displayModalSection } = this.state;

		// Add project
		if (displayModalSection == "ADD_NEW_PROJECT") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ProjectForm
							addProjectInList={this.addProjectInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							productivityOutcome={this.props.productivityOutcome}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</Modal>
				</div>
			)
		}
		else if (displayModalSection == "EDIT_PROJECT") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ProjectForm
							updateEditedProjectInList={this.updateEditedProjectInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							projectObj={this.state.chosenProject}
							productivityOutcome={this.props.productivityOutcome}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</Modal>
				</div>
			)
		}
		// Delete project
		else if (displayModalSection == "DELETE_PROJECT") {

			if (_.isEmpty(this.state.chosenProject)) return;

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.toggleModal.bind(this)}>Delete Project</ModalHeader>
						<ModalBody>
							<p>Are you sure you want to delete the project <span className="text-warning">{this.state.chosenProject.projectTitle}?</span></p>
							{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
						</ModalBody>
						<ModalFooter>
							<Button color="danger" title="Delete" onClick={this.handleProjectDelete.bind(this)}>Delete</Button>{' '}
							<Button color="secondary" title="Cancel" onClick={this.toggleModal.bind(this)}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
			)
		} else if (displayModalSection == "DEFAULT_PROJECT_LISTS") {

			let chosenProjectID = this.state.chosenProject && this.state.chosenProject['id'] ? this.state.chosenProject['id'] : '';

			let systemDefaultProjectsOptions = [];

			const { productivityOutcome } = this.props;

			// Only list system default projects and the projects that are not added in the outcome
			if (productivityOutcome.projects && productivityOutcome.projects.length > 0) {

				let filteredProjects = _.filter(productivityOutcome.projects, function(o) { 
					return o.isOutcomeDefault && ( !o.isAssigned || o.isDeleted); 
				});

				systemDefaultProjectsOptions = filteredProjects.map(e => ({ value: e.id, label: e.projectTitle }));
				
			}

			systemDefaultProjectsOptions.push({
				label: "Add New Project",
				value: "ADD_NEW_PROJECT",
			});

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.props.onClose}>Select or Add Project</ModalHeader>
						<ModalBody>
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<ReactSelect
												name="project"
												styles={SharedStyles.selectBoxStyles}
												closeOnSelect={false}
												options={systemDefaultProjectsOptions}
												value={_.find(systemDefaultProjectsOptions, { value: chosenProjectID })}
												removeSelected={true}
												autosize={true}
												clearable={true}
												placeholder="Select or Add Project"
												onChange={this.handleProjectSelectField.bind(this)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<button type="button" onClick={this.addProjectInList.bind(this, this.state.chosenProject, "")} disabled={_.isEmpty(this.state.chosenProject)} className="btn btn-primary mb-2 btn-sm" style={{ marginRight: 10 }}>Save & Return</button>
										<button type="button" onClick={this.toggleModal.bind(this)} className="btn btn-secondary mb-2 btn-sm" style={{ marginRight: 10 }}>Cancel</button>
									</div>
								</div>
							</form>
						</ModalBody>
					</Modal>
				</div>
			)
		}
		// If add project custom metric
		else if (displayModalSection == "ADD_PROJECT_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<MetricForm
							addMetricInList={this.addProjectCustomMetricInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
						/>
					</Modal>
				</div>
			)
		}
		// If edit project metric
		else if (displayModalSection == "EDIT_PROJECT_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<MetricForm
							editMetricInList={this.editProjectMetricInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
							metricObj={this.state.chosenProjectMetric}
						/>
					</Modal>
				</div>
			)
		}
		// Delete project custom metric
		else if (displayModalSection == "DELETE_PROJECT_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.toggleModal.bind(this)}>Delete Metric</ModalHeader>
						<ModalBody>
							<p>Are you sure you want to delete the metric <span className="text-warning">{this.state.chosenProjectMetric.title}?</span></p>
							{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
						</ModalBody>
						<ModalFooter>
							<Button color="danger" title="Delete" onClick={this.handleProjectMetricDelete.bind(this)}>Delete</Button>{' '}
							<Button color="secondary" title="Cancel" onClick={this.toggleModal.bind(this)}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
			)
		}
		// If key activity select dropdown modal
		else if (displayModalSection == "KA_LISTS") {

			const { chosenProject, chosenKeyActivity } = this.state;

			if (_.isEmpty(chosenProject)) return;

			let chosenKAID = chosenKeyActivity && chosenKeyActivity['id'] ? chosenKeyActivity['id'] : '';

			let systemDefaultKAOptions = [];

			// if chosen project is system default and for some project templates there won't be key activities
			if (chosenProject['keyActivities'] && chosenProject['keyActivities'].length > 0) {

				systemDefaultKAOptions = chosenProject['keyActivities'].filter(function (eachKA) {
					if (!eachKA['isAssigned'] && eachKA['isProjectDefault']) {
						return true;
					} else {
						return false; // skip
					}
				}).map((eachKA) => ({ value: eachKA.id, label: eachKA.title }));
			}

			systemDefaultKAOptions.push({
				label: "Add New Key Activity",
				value: "ADD_NEW_KA",
			});

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.props.onClose}>Select or Add Key Activity</ModalHeader>
						<ModalBody>
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<ReactSelect
												name="keyActivity"
												styles={SharedStyles.selectBoxStyles}
												closeOnSelect={false}
												options={systemDefaultKAOptions}
												value={_.find(systemDefaultKAOptions, { value: chosenKAID })}
												removeSelected={true}
												autosize={true}
												clearable={true}
												placeholder="Select or Add Key Activity"
												onChange={this.handleKASelectField.bind(this)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<button type="button" onClick={this.addProjectKAInList.bind(this, this.state.chosenKeyActivity, "")} disabled={_.isEmpty(this.state.chosenProject)} className="btn btn-primary mb-2 btn-sm" style={{ marginRight: 10 }}>Save & Return</button>
										<button type="button" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "KA_LISTS")} className="btn btn-secondary mb-2 btn-sm" style={{ marginRight: 10 }}>Cancel</button>
									</div>
								</div>
							</form>
						</ModalBody>
					</Modal>
				</div>
			)
		}
		// If Add key acitvity
		else if (displayModalSection == "ADD_NEW_KA") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<KeyActivityForm
							addProjectKAInList={this.addProjectKAInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							productivityOutcome={this.props.productivityOutcome}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</Modal>
				</div>
			)
		}
		// If Edit key acitvity
		else if (displayModalSection == "EDIT_KA") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<KeyActivityForm
							updateEditedKAInList={this.updateEditedKAInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							kaObj={this.state.chosenKeyActivity}
							productivityOutcome={this.props.productivityOutcome}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</Modal>
				</div>
			)
		}
		// Delete KA
		else if (displayModalSection == "DELETE_KA") {

			if (_.isEmpty(this.state.chosenKeyActivity)) return;

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.toggleModal.bind(this)}>Delete Project</ModalHeader>
						<ModalBody>
							<p>Are you sure you want to delete the key activity <span className="text-warning">{this.state.chosenKeyActivity.title}?</span></p>
							{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
						</ModalBody>
						<ModalFooter>
							<Button color="danger" title="Delete" onClick={this.handleKADelete.bind(this)}>Delete</Button>{' '}
							<Button color="secondary" title="Cancel" onClick={this.toggleModal.bind(this)}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
			)
		}
		// If add project key acitvity custom metric
		else if (displayModalSection == "ADD_PROJECT_KA_CUSTOM_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<MetricForm
							addMetricInList={this.addKACustomMetricInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
							chosenKeyActivity={this.state.chosenKeyActivity}
						/>
					</Modal>
				</div>
			)
		}
		// If edit project key acitvity metric
		else if (displayModalSection == "EDIT_PROJECT_KA_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<MetricForm
							editMetricInList={this.editKAMetricInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
							chosenKeyActivity={this.state.chosenKeyActivity}
							metricObj={this.state.chosenProjectKAMetric}
						/>
					</Modal>
				</div>
			)
		}
		// Delete custom metric
		else if (displayModalSection == "DELETE_PROJECT_KA_CUSTOM_METRIC") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.toggleModal.bind(this)}>Delete Metric</ModalHeader>
						<ModalBody>
							<p>Are you sure you want to delete the metric <span className="text-warning">{this.state.chosenProjectKAMetric.title}?</span></p>
							{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
						</ModalBody>
						<ModalFooter>
							<Button color="danger" title="Delete" onClick={this.handleKAMetricDelete.bind(this)}>Delete</Button>{' '}
							<Button color="secondary" title="Cancel" onClick={this.toggleModal.bind(this)}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
			)
		}
		// If add timeblock
		else if (displayModalSection == "ADD_PROJECT_KA_TIMEBLOCK") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<TimeBlockForm
							addKATimeBlockInList={this.addKATimeBlockInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
							chosenKeyActivity={this.state.chosenKeyActivity}
						/>
					</Modal>
				</div>
			)
		}
		// If edit project key acitvity timeblock
		else if (displayModalSection == "EDIT_PROJECT_KA_TIMEBLOCK") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<TimeBlockForm
							editKATimeBlockInList={this.editKATimeBlockInList.bind(this)}
							onCancel={this.toggleModal.bind(this)}
							chosenProject={this.state.chosenProject}
							chosenKeyActivity={this.state.chosenKeyActivity}
							timeBlockObj={this.state.chosenProjectKATimeBlock}
						/>
					</Modal>
				</div>
			)
		}
		// Delete timeblock
		else if (displayModalSection == "DELETE_PROJECT_KA_TIMEBLOCK") {

			return (
				<div>
					<Modal isOpen={this.state.mainModal} toggle={this.toggleModal.bind(this)}>
						<ModalHeader toggle={this.toggleModal.bind(this)}>Delete Time Block</ModalHeader>
						<ModalBody>
							<p>Are you sure you want to delete the timeblock <span className="text-warning">{this.state.chosenProjectKATimeBlock.title}?</span></p>
							{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
						</ModalBody>
						<ModalFooter>
							<Button color="danger" title="Delete" onClick={this.handleKATimeBlockDelete.bind(this)}>Delete</Button>{' '}
							<Button color="secondary" title="Cancel" onClick={this.toggleModal.bind(this)}>Cancel</Button>
						</ModalFooter>
					</Modal>
				</div>
			)
		}
	}

	// Handler for project select field change event
	handleProjectSelectField(value) {

		if (value["value"] == "ADD_NEW_PROJECT") {
			// Show Section - Add New Project
			return this.setState({ displayModalSection: value["value"] })
		}

		let chosenProject = _.find(this.props.productivityOutcome.projects, { id: value.value });

		let chosenProjectClone = _.cloneDeep(chosenProject);

		delete chosenProjectClone['isDeleted'];

		this.setState({
			chosenProject: chosenProjectClone,
		});
	}

	// Handler for project ka select field change event
	handleKASelectField(value) {

		if (value["value"] == "ADD_NEW_KA") {
			// Show Section - Add New KA
			return this.setState({
				displayModalSection: value["value"],
			})
		}

		var projectIndex = _.findIndex(this.props.productivityOutcome.projects, { id: this.state.chosenProject['id'] });

		let chosenKeyActivity = _.find(this.props.productivityOutcome.projects[projectIndex].keyActivities, { id: value.value });

		this.setState({
			chosenKeyActivity: chosenKeyActivity,
		});
	}

	//============= PROJECT / PROJECT METRICS CRUD ==============

	// Add project in list
	addProjectInList(newProject, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// If this project isOutcomeDefault. Just remove the isdeleted flag
		if (newProject['isOutcomeDefault']) {

			newProject['isAssigned'] = true;
			delete newProject['isDeleted'];

			var defaultProjectIndex = _.findIndex(productivityOutcomeClone.projects, { id: newProject.id });

			productivityOutcomeClone.projects.splice(defaultProjectIndex, 1, newProject);
		} else {

			newProject['isAssigned'] = true;

			//Add outcome metric in project level
			// If there is no outcome metric
			if (productivityOutcomeClone['metricsAndTargets'].length == 0) {
				newProject['metricsAndTargets'] = [];
			} else {
				let outcomeMetricsAndTargets = _.cloneDeep(productivityOutcomeClone.metricsAndTargets);
				let inheritedProjectMetrics = [];

				outcomeMetricsAndTargets.forEach((eachOMetric) => {

					inheritedProjectMetrics.push({
						...eachOMetric,
						targetScope: "",
						targetValue: "",
						isInheritedFromOutcome: true,
					});
				})
				newProject['metricsAndTargets'] = inheritedProjectMetrics;
			}

			// Add key-activity 'Other' to all projects by default
			newProject['keyActivities'] = [];
			newProject['keyActivities'].push({
				id: Util.generateObjectId(),
				title: 'Other',
				isAssigned: true,
				timeBlocks: [],
				metricsAndTargets: [],
				tasks: [],
			})
			if (newProject['metricsAndTargets'].length > 0) {

				newProject['metricsAndTargets'].forEach((eachProjectMetric) => {

					let eachProjectMetricClone = _.cloneDeep(eachProjectMetric);

					//All the newly created KA metrics will inherit only project metrics.
					delete eachProjectMetricClone['applyToAllKeyActivities'];
					delete eachProjectMetricClone['isInheritedFromOutcome'];
					eachProjectMetricClone['isInheritedFromProject'] = true;

					newProject['keyActivities'][0]['metricsAndTargets'].push(eachProjectMetricClone);
				})
			}
			productivityOutcomeClone.projects.push(newProject);
		}


		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Update edited project in list
	updateEditedProjectInList(data, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// Find item index using _.findIndex
		var index = _.findIndex(productivityOutcomeClone.projects, { id: data.id });

		// Replace item at index using native splice
		productivityOutcomeClone.projects.splice(index, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Handler for removing project
	handleProjectDelete() {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		const { chosenProject } = this.state;

		if (chosenProject && chosenProject.id) {

			productivityOutcomeClone.projects.forEach((eachProductivityOutcomeMeric, index) => {

				if (eachProductivityOutcomeMeric.id == chosenProject.id) {

					// Soft delete
					let data = productivityOutcomeClone.projects[index];

					data = {
						...data,
						isDeleted: true,
						isAssigned: false,
					}

					productivityOutcomeClone.projects.splice(index, 1, data);

					// productivityOutcomeClone.projects.splice(index, 1);
				}
			});

			this.props.updateProductivityOutcome(productivityOutcomeClone);

			this.toggleModal();
		}
	}

	// Add project custom metric in list
	addProjectCustomMetricInList(newMetric, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// Find respective project index
		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		let chosenProject = productivityOutcomeClone.projects[projectIndex];

		// If this is the first project metric
		if (!_.has(productivityOutcomeClone.projects[projectIndex], 'metricsAndTargets')) {
			chosenProject['metricsAndTargets'] = [];
		}

		chosenProject['metricsAndTargets'].push({...newMetric, applyToAllKeyActivities: true}); /* Temporarily assign the project metrics created from the productivity outcome section to all its key activities. */

		// project metrics that applies to all key activities
		if (_.has(chosenProject, 'keyActivities') && chosenProject.keyActivities.length > 0) {
			
			chosenProject.keyActivities.forEach((eachKA) => {

				// If there is no ka metric
				if (!_.has(eachKA, 'metricsAndTargets')) {
					eachKA['metricsAndTargets'] = [];
				}

				//Push outcome metrics in ka
				eachKA['metricsAndTargets'].push({
					...newMetric,
					targetScope: "",
					targetValue: "",
					pastPerformance: "",
					isInheritedFromProject: true,
				});
			})
		}

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Update edited Project Metric in list
	editProjectMetricInList(data, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectMetricIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].metricsAndTargets, { id: data['id'] });

		productivityOutcomeClone.projects[projectIndex].metricsAndTargets.splice(projectMetricIndex, 1, data);

		let chosenProject = productivityOutcomeClone.projects[projectIndex];

		//Edit project metric in ka level
		if (_.has(chosenProject, 'keyActivities') && chosenProject.keyActivities.length > 0) {

			// For each ka
			chosenProject.keyActivities.forEach((eachKA) => {

				var index = _.findIndex(eachKA.metricsAndTargets, { id: data.id });

				let updatedKAMetic = {
					...data,
					targetScope: "",
					targetValue: "",
					pastPerformance: "",
					isInheritedFromProject: true,
				}
				eachKA.metricsAndTargets.splice(index, 1, updatedKAMetic);
			})
		}

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Handler for removing project metric
	handleProjectMetricDelete() {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectMetricIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].metricsAndTargets, { id: this.state.chosenProjectMetric['id'] });

		// Remove item
		// productivityOutcomeClone.projects[projectIndex].metricsAndTargets.splice(projectMetricIndex, 1);

		// Soft delete
		let data = productivityOutcomeClone.projects[projectIndex].metricsAndTargets[projectMetricIndex];

		data = {
			...data,
			isDeleted: true,
		}

		productivityOutcomeClone.projects[projectIndex].metricsAndTargets.splice(projectMetricIndex, 1, data);

		let chosenProject = productivityOutcomeClone.projects[projectIndex];

		//Delete project metric in ka level
		if (_.has(chosenProject, 'keyActivities') && chosenProject.keyActivities.length > 0) {

			// For each ka
			chosenProject.keyActivities.forEach((eachKA) => {

				var index = _.findIndex(eachKA.metricsAndTargets, { id: data.id });

				let updatedKAMetic = {
					...data,
					isDeleted: true,
					isInheritedFromProject: true,
				}
				eachKA.metricsAndTargets.splice(index, 1, updatedKAMetic);
			})
		}

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		this.toggleModal();
	}

	//============= END OF PROJECT / PROJECT METRICS CRUD ==============

	//============= PROJECT KA CRUD ==============

	// Add project key activity in list
	addProjectKAInList(newKA, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		let chosenProject = _.find(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		// If this is the first key activity
		if (!_.has(chosenProject, 'keyActivities')) {
			chosenProject['keyActivities'] = [];
		}

		// If project default only change/add isAssigned: true and find and replace the respective KA. Else push the newly added KA
		if (newKA['isProjectDefault']) {

			newKA['isAssigned'] = true;
			delete newKA['isDeleted'];

			let projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });
			let kaIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: newKA['id'] });
			productivityOutcomeClone.projects[projectIndex].keyActivities.splice(kaIndex, 1, newKA);
		} else {

			// Project metrics will apply to all key activities
			newKA['metricsAndTargets'] = [];
			chosenProject['metricsAndTargets'].forEach((eachProjectMetric) => {

				let eachProjectMetricClone = _.cloneDeep(eachProjectMetric);

				//All the newly created KA metrics will inherit only project metrics.
				delete eachProjectMetricClone['applyToAllKeyActivities'];
				delete eachProjectMetricClone['isInheritedFromOutcome'];
				delete eachProjectMetricClone['targetScope'];
				delete eachProjectMetricClone['targetValue'];
				delete eachProjectMetricClone['pastPerformance'];
				eachProjectMetricClone['isInheritedFromProject'] = true;

				newKA['metricsAndTargets'].push(eachProjectMetricClone);
			})

			// Render only the key-activities with "isAssigned: true". Newly added KA will be assigned.
			newKA['isAssigned'] = true;

			//Push the new keyactivity to project
			chosenProject['keyActivities'].push(newKA);
		}

		// Update the respective project in productivityOutcome
		let index = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });
		productivityOutcomeClone.projects.splice(index, 1, chosenProject);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Update edited ka in list
	updateEditedKAInList(data, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		let projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		let kaIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: data['id'] });

		productivityOutcomeClone.projects[projectIndex].keyActivities.splice(kaIndex, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Handler for removing ka
	handleKADelete() {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		const { chosenProject, chosenKeyActivity } = this.state;

		if (chosenProject && chosenProject.id && chosenKeyActivity && chosenKeyActivity.id) {

			let projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

			productivityOutcomeClone.projects[projectIndex].keyActivities.forEach((eachKA, index) => {

				if (eachKA.id == chosenKeyActivity.id) {

					// Soft delete
					let data = productivityOutcomeClone.projects[projectIndex].keyActivities[index];

					data = {
						...data,
						isDeleted: true,
						isAssigned: false,
					}

					productivityOutcomeClone.projects[projectIndex].keyActivities.splice(index, 1, data);
				}
			});

			this.props.updateProductivityOutcome(productivityOutcomeClone);

			this.toggleModal();
		}
	}

	//============= END OF PROJECT KA CRUD ==============

	//============= PROJECT KA METIRCS CRUD ==============

	// Add project key activity custom metric in list
	addKACustomMetricInList(newKA, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// Find respective project index
		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		// Find respective KA index
		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		let chosenKA = productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex];

		// If this is the first key activity metric
		if (!_.has(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex], 'metricsAndTargets')) {
			chosenKA['metricsAndTargets'] = [];
		}

		chosenKA['metricsAndTargets'].push(newKA);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Update edited KA Metric in list
	editKAMetricInList(data, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		var projectKAMetricIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets, { id: data['id'] });

		// Replace item at index using native splice
		productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets.splice(projectKAMetricIndex, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Handler for removing ka metric
	handleKAMetricDelete() {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		var projectKAMetricIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets, { id: this.state.chosenProjectKAMetric['id'] });

		// Remove item
		// productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets.splice(projectKAMetricIndex, 1);

		// Soft delete
		let data = productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets[projectKAMetricIndex];

		data = {
			...data,
			isDeleted: true,
		}

		productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].metricsAndTargets.splice(projectKAMetricIndex, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		this.toggleModal();
	}

	//============= END OF PROJECT KA METIRCS CRUD ==============

	//============= PROJECT KA TIMEBLOCK CRUD ==============

	// Add project key activity timeblock in list
	addKATimeBlockInList(newKA, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// Find respective project index
		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		// Find respective KA index
		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		let chosenKA = productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex];

		// If this is the first key activity timeblock
		if (!_.has(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex], 'timeBlocks')) {
			chosenKA['timeBlocks'] = [];
		}

		chosenKA['timeBlocks'].push(newKA);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Update edited KA TimeBlock in list
	editKATimeBlockInList(data, displayModalSection) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		var projectKATimeBlockIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks, { id: data['id'] });

		// Replace item at index using native splice
		productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks.splice(projectKATimeBlockIndex, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		if (displayModalSection == '') {
			this.toggleModal();
		}
	}

	// Handler for removing ka timeblock
	handleKATimeBlockDelete() {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		var projectIndex = _.findIndex(productivityOutcomeClone.projects, { id: this.state.chosenProject['id'] });

		var projectKAIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities, { id: this.state.chosenKeyActivity['id'] });

		var projectKATimeBlockIndex = _.findIndex(productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks, { id: this.state.chosenProjectKATimeBlock['id'] });

		// Remove item
		// productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks.splice(projectKATimeBlockIndex, 1);

		// Soft delete
		let data = productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks[projectKATimeBlockIndex];

		data = {
			...data,
			isDeleted: true,
		}

		productivityOutcomeClone.projects[projectIndex].keyActivities[projectKAIndex].timeBlocks.splice(projectKATimeBlockIndex, 1, data);

		this.props.updateProductivityOutcome(productivityOutcomeClone);

		this.toggleModal();
	}

	//============= END OF PROJECT KA TIMEBLOCK CRUD ==============

	renderProjectKAMetrics(project, keyActivity) {

		if (!_.has(keyActivity, 'metricsAndTargets') || keyActivity['metricsAndTargets'].length == 0) return;

		let metricsHTML = [];

		// Only render inherited metrics
		let serialNumber = 1;
		let metricsAndTargets = Util.sortMetricsBasedOnHierarchy(keyActivity.metricsAndTargets);
		
		metricsAndTargets.forEach((value, index) => {

			if(value['isDeleted']) return;

			if (value['isInheritedFromOutcome'] || value['isInheritedFromProject']) {

				metricsHTML.push(
					<tr key={value.id} className="text-blog">
						<td className="text-center" style={{ width: 50 }}>{serialNumber}</td>
						<td className="text-left" style={{ width: 500 }}>{value.title}</td>
						<td className="text-center" style={{ width: 200 }}>{value.pastPerformance ? value.pastPerformance : '(not set)'}</td>
						<td className="text-center" style={{ width: 200 }}>{value.targetValue ? value.targetValue : '(not set)'}</td>
						<td className="text-center" style={{ width: 200 }}>{value.targetScope ? _.capitalize(value.targetScope) : '(not set)'}</td>
						<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, "EDIT_PROJECT_KA_METRIC", project, keyActivity, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
					</tr>
				)

				serialNumber = serialNumber + 1;
			}
		});

		if (_.isEmpty(metricsHTML)) {
			return (
				<div className="row">
					<div className="col">
						<p>Metrics & Targets</p>
					</div>
					<div className="small text-info">No Metrics added.</div>
				</div>
			);
		};

		return (
			<div className="App" style={{ marginTop: 20 }}>
				<header className="App-header table-responsive">
					<table className="table-curved">
						<thead>
							<tr>
								<th className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
								<th className="text-left font-weight-normal" style={{ width: 500 }}>Title</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Past Performance</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Current Target</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Unit</th>
								<th className="text-center font-weight-normal" style={{ width: 100 }} colSpan={1}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{metricsHTML}
						</tbody>
					</table>
				</header>
			</div>
		)
	}

	renderProjectKACustomMetrics(project, keyActivity) {

		if (!_.has(keyActivity, 'metricsAndTargets') || keyActivity['metricsAndTargets'].length == 0) {
			return (
				<div>
					<div className="row">
						<div className="col-6">
							<p>Metrics & Targets</p>
						</div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-sm" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_KA_CUSTOM_METRIC', project, keyActivity)}>
								<i className="fa fa-plus" aria-hidden="true"></i> Activity Metric
							</button>
						</div>
					</div>
					<div className="small text-info">No Metrics added.</div>
				</div>
			);
		}

		let metricsHTML = [];

		// Only render custom metrics
		let serialNumber = 1;
		let metricsAndTargets = Util.sortMetricsBasedOnHierarchy(keyActivity.metricsAndTargets);

		metricsAndTargets.forEach((value, index) => {

			if (value['isInheritedFromOutcome'] || value['isInheritedFromProject'] || value['isDeleted']) return;

			metricsHTML.push(
				<tr key={value.id} className="text-blog">
					<td className="text-center" style={{ width: 50 }}>{serialNumber}</td>
					<td className="text-left" style={{ width: 500 }}>{value.title}</td>
					<td className="text-center" style={{ width: 200 }}>{value.pastPerformance ? value.pastPerformance : '(not set)'}</td>
					<td className="text-center" style={{ width: 200 }}>{value.targetValue ? value.targetValue : '(not set)'}</td>
					<td className="text-center" style={{ width: 200 }}>{value.targetScope ? _.capitalize(value.targetScope) : '(not set)'}</td>
					<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, "EDIT_PROJECT_KA_METRIC", project, keyActivity, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
					<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, "DELETE_PROJECT_KA_CUSTOM_METRIC", project, keyActivity, value)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
				</tr>
			);
			serialNumber = serialNumber + 1;
		});

		if (_.isEmpty(metricsHTML)) {
			return (
				<div>
					<div className="row">
						<div className="col-6">
							<p>Metrics & Targets</p>
						</div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-sm" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_KA_CUSTOM_METRIC', project, keyActivity)}>
								<i className="fa fa-plus" aria-hidden="true"></i> Activity Metric
							</button>
						</div>
					</div>
					<div className="small text-info">No Metrics added.</div>
				</div>
			);
		};

		return (
			<div>
				<div className="row">
					<div className="col-6">
						<p>Metrics & Targets</p>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.projectKAAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_KA_CUSTOM_METRIC', project, keyActivity)}>
							<i className="fa fa-plus" aria-hidden="true"></i> Activity Metric
						</button>
					</div>
				</div>
				<header className="App-header table-responsive">
					<table className="table-curved mt-2">
						<thead>
							<tr>
								<th className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
								<th className="text-left font-weight-normal" style={{ width: 500 }}>Title</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Past Performance</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Current Target</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Unit</th>
								<th className="text-center font-weight-normal" style={{ width: 100 }} colSpan={2}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{metricsHTML}
						</tbody>
					</table>
				</header>
			</div>
		)
	}

	renderProjectKATimeBlocks(project, keyActivity) {

		let innerHTML = [];

		if (!_.has(keyActivity, 'timeBlocks') || keyActivity['timeBlocks'].length == 0) {
			innerHTML = <div className="small text-info">No Time Blocks added.</div>;
		}
		else {
			
			innerHTML = (
				<header className="App-header table-responsive">
					<table className="table-curved mt-2 mb-2">
						{/* <col />
						<col />
						<colgroup span="2"></colgroup>
						<colgroup span="2"></colgroup>
						<col />
						<col /> */}
						<thead>
							<tr>
								<th rowSpan="2" className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
								<th rowSpan="2" className="text-left font-weight-normal" style={{ width: 400 }}>Title</th>
								<th rowSpan="2" className="text-center font-weight-normal" style={{ width: 100 }}>Type</th>
								<th colSpan="4" className="text-center font-weight-normal" style={{ width: 200 }}>Past Performance</th>
								<th colSpan="4" className="text-center font-weight-normal" style={{ width: 200 }}>Current Target</th>
								<th rowSpan="2" className="text-center font-weight-normal" style={{ width: 100 }} colSpan={2}>Actions</th>
							</tr>
							<tr>

								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}>Duration</th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}>Regularity</th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}><div># of Sessions<div><small>(per week)</small></div></div></th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}><div>Duration<div><small>(per week)</small></div></div></th>
								

								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}>Duration</th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}>Regularity</th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}><div># of Sessions<div><small>(per week)</small></div></div></th>
								<th scope="col" className="text-center font-weight-normal" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading, ...{backgroundColor: '#E9E9E9', fontSize:16}}}><div>Duration<div><small>(per week)</small></div></div></th>
								
							</tr>
						</thead>
						<tbody>
							{keyActivity.timeBlocks.map((value, index) => {

								if (value['isDeleted']) return;

								let isNonWeeklyTB = value.regularity == 'non-weekly' ? true : false;

								let durationInMinutesPerWeek = isNonWeeklyTB ? (value.durationInMinutesPerWeek || 0) : ((value.durationInMinutes || 0) * (value.sessionsPerWeek || 0));
								let durationInMinutesPerWeekPastPerformance = isNonWeeklyTB ? (value.durationInMinutesPerWeekPastPerformance || 0) : ((value.durationInMinutesPastPerformance || 0) * (value.sessionsPerWeekPastPerformance || 0));

								let regularityPastPerformance = '(not set)';
								let regularity = '(not set)';

								if (isNonWeeklyTB) {
									
									/* Past Performance */
									if (value.intervalPastPerformance == 'every_x_months' && value.recurringIntervalPastPerformance) {
										regularityPastPerformance = `Every ${value.recurringIntervalPastPerformance} Months`;
									}
									else if (value.intervalPastPerformance == 'every_x_years' && value.recurringIntervalPastPerformance) {
										regularityPastPerformance = `Every ${value.recurringIntervalPastPerformance} Years`;
									}
									else if (value.intervalPastPerformance == 'every_x_weeks' && value.recurringIntervalPastPerformance) {
										regularityPastPerformance = `Every ${value.recurringIntervalPastPerformance} Weeks`;
									}
									else if (value.intervalPastPerformance == 'every_x_days' && value.recurringIntervalPastPerformance) {
										regularityPastPerformance = `Every ${value.recurringIntervalPastPerformance} Days`;
									}
									else if (value.intervalPastPerformance) {
										regularityPastPerformance = _.startCase(_.camelCase(value.intervalPastPerformance));
									};

									/* Current Performance */
									if (value.interval == 'every_x_months' && value.recurringInterval) {
										regularity = `Every ${value.recurringInterval} Months`;
									}
									else if (value.interval == 'every_x_years' && value.recurringInterval) {
										regularity = `Every ${value.recurringInterval} Years`;
									}
									else if (value.interval == 'every_x_weeks' && value.recurringInterval) {
										regularity = `Every ${value.recurringInterval} Weeks`;
									}
									else if (value.interval == 'every_x_days' && value.recurringInterval) {
										regularity = `Every ${value.recurringInterval} Days`;
									}
									else if (value.interval) {
										regularityPastPerformance = _.startCase(_.camelCase(value.interval));
									};
								}
								else {
									
									/* Past Performance */
									if (value.sessionsPerWeekPastPerformance) {
										regularityPastPerformance = 'Weekly';
									};

									/* Current Performance */
									if (value.sessionsPerWeek) {
										regularity = 'Weekly';
									};
								};

								return (
									<tr key={value.id} className="text-blog">
										<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
										<td className="text-left" style={{ width: 400, minWidth: 250 }}>{value.title}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{value.type}</td>

										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{Util.convertTimeToReadableText(value.durationInMinutesPastPerformance)}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{regularityPastPerformance}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{isNonWeeklyTB ? <small>N/A</small> : value.sessionsPerWeekPastPerformance ? value.sessionsPerWeekPastPerformance : '(not set)'}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{durationInMinutesPerWeekPastPerformance ? Util.convertTimeToReadableText(durationInMinutesPerWeekPastPerformance) : '(not set)'}</td>

										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{Util.convertTimeToReadableText(value.durationInMinutes)}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{regularity}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{isNonWeeklyTB ? <small>N/A</small> : value.sessionsPerWeek}</td>
										<td className="text-center" style={{ width: 100, minWidth: 175 }}>{Util.convertTimeToReadableText(durationInMinutesPerWeek)}</td>

										<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.projectKAAddEditDeleteTimeBlockModal.bind(this, "EDIT_PROJECT_KA_TIMEBLOCK", project, keyActivity, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
										<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.projectKAAddEditDeleteTimeBlockModal.bind(this, "DELETE_PROJECT_KA_TIMEBLOCK", project, keyActivity, value)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</header>
			);
		};

		return(
			<div>
				<div className="row">
					<div className="col-6">
						<p>Time Blocks</p>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.projectKAAddEditDeleteTimeBlockModal.bind(this, 'ADD_PROJECT_KA_TIMEBLOCK', project, keyActivity)}>
							<i className="fa fa-plus" aria-hidden="true"></i> Time Block
						</button>
					</div>
				</div>
				{innerHTML}
			</div>
		);
	}

	checkKADetailsSectionVisibility(uniqueID) {
		const { visibleKADetailsSection } = this.state;
		return visibleKADetailsSection.indexOf(uniqueID) > -1;
	};

	toggleKADetailsSectionVisibility(uniqueID) {

		let { visibleKADetailsSection } = this.state;
		let uniqueIDIndex = visibleKADetailsSection.indexOf(uniqueID);

		if (uniqueIDIndex == -1) {
			visibleKADetailsSection.push(uniqueID);
		}
		else {
			visibleKADetailsSection.splice(uniqueIDIndex, 1);
		};
		this.setState({ visibleKADetailsSection });
	};

	renderProjectKADetails(project, keyActivity) {

		let visibilityIcon = this.checkKADetailsSectionVisibility(keyActivity['id']) ? "/images/Triangle_Down.png" : "/images/Triangle_Right.png";

		return (
			<div>
				<p onClick={() => this.toggleKADetailsSectionVisibility(keyActivity['id'])}><img src={visibilityIcon} width="10" className="mr-1 cursor-pointer" /> Details</p>

				{
					this.checkKADetailsSectionVisibility(keyActivity['id'])
						?
						<div className="my-3 ml-5">
							{this.renderProjectKACustomMetrics(project, keyActivity)}
							<br />
							{this.renderProjectKATimeBlocks(project, keyActivity)}
							<br />
						</div>
						:
						null
				}
			</div>
		)
	}

	// Render project key activities
	renderProjectKeyActivities(project) {

		if (!project.keyActivities || project.keyActivities.length == 0) return;

		let serialNumber = 0;

		return (
			<div>
				{project.keyActivities.map((eachKA, index) => {

					// Only render assigned KA 
					if (!eachKA['isAssigned']) return;

					if (eachKA['isDeleted']) return;

					serialNumber = serialNumber + 1;

					return (
						<div key={eachKA['id']}>
							<div className="row">
								<div className="col-12">
									<div style={serialNumber > 1 ? styles.activityDivider: {}} />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<label>{serialNumber}. {eachKA['title']}</label>
									<button type="button" style={styles.tableActionButton} title="Edit" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "EDIT_KA", project, eachKA)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button>
									<button style={styles.tableActionButton} title="Delete" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "DELETE_KA", project, eachKA)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									{this.renderProjectKAMetrics(project, eachKA)}
									<br />
									{this.renderProjectKADetails(project, eachKA)}
								</div>
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	renderProjectMetrics(project) {

		let projectMetrics = project.metricsAndTargets ? _.cloneDeep(project.metricsAndTargets) : [];
		projectMetrics = _.reject(projectMetrics, {isInheritedFromOutcome: true}); /* Don't show Outcome Metrics at the project level. */
		projectMetrics = Util.sortMetricsBasedOnHierarchy(projectMetrics);

		if (projectMetrics.length == 0) {
			return (
				<div>
					<div className="row">
						<div className="col-6">
							<p>Metrics & Targets</p>
						</div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-sm" onClick={this.projectAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_METRIC', project)}>
								<i className="fa fa-plus" aria-hidden="true"></i> Project Metric
							</button>
						</div>
					</div>
					<div className="small text-info">No Metrics added.</div>
				</div>
			);
		}

		let metricsHTML = [];

		let serialNumber = 0;

		projectMetrics.forEach((value, index) => {

			if (value['isDeleted']) return;

			serialNumber = serialNumber + 1;

			metricsHTML.push(
				<tr key={value.id} className="text-blog">
					<td className="text-center" style={{ width: 50 }}>{serialNumber}</td>
					<td className="text-left" style={{ width: 500 }}>{value.title}</td>
					<td className="text-center" style={{ width: 200 }}>{value.pastPerformance ? value.pastPerformance : '(not set)'}</td>
					<td className="text-center" style={{ width: 200 }}>{value.targetValue ? value.targetValue : '(not set)'}</td>
					<td className="text-center" style={{ width: 200 }}>{value.targetScope ? _.capitalize(value.targetScope) : '(not set)'}</td>
					{
						value['isInheritedFromOutcome']
							?
							<React.Fragment>
								<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.projectAddEditDeleteMetricModal.bind(this, "EDIT_PROJECT_METRIC", project, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
								<td className="text-center" style={{ width: 50 }}><div className="small">N/A</div></td>
							</React.Fragment>
							:
							<React.Fragment>
								<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.projectAddEditDeleteMetricModal.bind(this, "EDIT_PROJECT_METRIC", project, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
								<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.projectAddEditDeleteMetricModal.bind(this, "DELETE_PROJECT_METRIC", project, value)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
							</React.Fragment>
					}
				</tr>
			);
		});

		if (_.isEmpty(metricsHTML)) {
			return (
				<div>
					<div className="row">
						<div className="col-6">
							<p>Metrics & Targets</p>
						</div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-sm" onClick={this.projectAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_METRIC', project)}>
								<i className="fa fa-plus" aria-hidden="true"></i> Project Metric
							</button>
						</div>
					</div>
					<div className="small text-info">No Metrics added.</div>
				</div>
			);
		};

		return (
			<div>
				<div className="row">
					<div className="col-6">
						<p>Metrics & Targets</p>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.projectAddEditDeleteMetricModal.bind(this, 'ADD_PROJECT_METRIC', project)}>
							<i className="fa fa-plus" aria-hidden="true"></i> Project Metric
						</button>
					</div>
				</div>
				<header className="App-header table-responsive">
					<table className="table-curved mt-2">
						<thead>
							<tr>
								<th className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
								<th className="text-left font-weight-normal" style={{ width: 500 }}>Title</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Past Performance</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Current Target</th>
								<th className="text-center font-weight-normal" style={{ width: 200 }}>Unit</th>
								<th className="text-center font-weight-normal" style={{ width: 100 }} colSpan={2}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{metricsHTML}
						</tbody>
					</table>
				</header>
			</div>
		)
	}

	// Renders project lists
	renderProjects() {

		let { projects } = _.cloneDeep(this.props.productivityOutcome);

		projects = _.filter(projects, (item) => item.isAssigned === true && !item.isDeleted);

		if (projects.length == 0) {
			return (
				<div className="small text-info">No projects added.</div>
			);
		}

		let serialNumber = 0;

		return (
			<div className="App">

				{projects.map((eachProject, index) => {

					if (eachProject['isDeleted']) return;

					serialNumber = serialNumber + 1;

					return (
						<div className="row" key={eachProject['id']}>
							<div className="col-12">
								<div style={styles.projectTitleWrapper}>
									<div style={styles.projectTitle}>#{serialNumber}. {eachProject['projectTitle']}</div>
									<div style={styles.projectActionIcons}>
										<button type="button" className="table-action-button" title="Edit" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "EDIT_PROJECT", eachProject)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button>
										<button className="table-action-button" title="Delete" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "DELETE_PROJECT", eachProject)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button>
									</div>
								</div>
								<div className="ml-5">
									<div className="row mt-2 mb-3">
										<div className="col-12">
											{this.renderProjectMetrics(eachProject)}
										</div>
									</div>
									<div className="row mt-4 mb-2">
										<div className="col-10">
											<p className="text-blog">How will you carry out this project? What are the specific steps and activities?</p>
										</div>
										<div className="col-2 text-right">
											<button type="button" className="btn btn-primary btn-sm" onClick={this.addEditDeleteProjectOrKAModal.bind(this, "KA_LISTS", eachProject)}>
												<i className="fa fa-plus" aria-hidden="true"></i> Activity
											</button>
										</div>
									</div>
									{this.renderProjectKeyActivities(eachProject)}
								</div>
								<div style={styles.projectDivider} />
							</div>
						</div>
					)
				})}
			</div>
		);
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					<div className="row">
						<div className="col-12">
							<label>Projects</label>
							<div className="row">
								<div className="col-6">
									<p className="text-blog">What will you need to do to achieve this outcome?</p>
								</div>
								<div className="col-6 text-right">
									<button type="button" className="btn btn-primary btn-sm" onClick={this.chooseDefaultProjectModal.bind(this, 'DEFAULT_PROJECT_LISTS')}>
										<i className="fa fa-plus" aria-hidden="true"></i> Project
									</button>
									{/* <button type="button" className="btn btn-primary btn-sm" onClick={this.toggleModal.bind(this, 'ADD_NEW_PROJECT')}>
										<i className="fa fa-plus" aria-hidden="true"></i> Project
									</button> */}
								</div>
							</div>
							{this.renderProjects()}
						</div>
					</div>
				</div>
				{this.renderModal()}
			</div>
		);
	}
}

ProjectsList.propTypes = {
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(
	ProjectsList
);

const styles = {

	projectTitleWrapper: {
		display: 'flex',
		backgroundColor: '#fef2ec',
		border: '2px solid #f9ded1',
		borderRadius: 8,
		padding: '6px 10px',
		marginTop: 10,
		marginBottom: 25
	},
	projectTitle: {
		//color: '#f79569',
		fontSize: 16
	},
	projectActionIcons: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end'
	},
	projectDivider: {
		//height: 4,
		width: '100%',
		backgroundColor: '#f9ded1',
		backgroundImage: 'radial-gradient(circle, #f9ded1, #fbccb6, #fbba9b, #f9a882, #f79569)',
		borderRadius: 10,
		//marginBottom: 15
	},
	activityDivider: {
		height: 2,
		width: '100%',
		backgroundColor: '#f9ded1',
		backgroundImage: 'radial-gradient(circle, #f9ded1, #fbccb6, #fbba9b, #f9a882, #f79569)',
		borderRadius: 10,
		marginBottom: 20
	},
	tableActionButton: {
		backgroundColor: '#f6f6f6',
		marginLeft: '8px',
		borderRadius: 5,
		border: '1px solid transparent',
		fontSize: 14
	}
}