import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import moment from 'moment';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class PMDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			reports: null,
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.props.setLoading(true);

		let postObj = {};

		API_Services.httpPOST(UrlConstants.URLS.getProductivityMeterReport, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					this.setState({
						reports: response.data.data,
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	renderTableRow(reports, reportObj, index) {
		
		let tableRowArray = [];
		let reportUrl = "/assessments/productivity-meter-report/" + reportObj['assessmentId'];
		
		let achievementGoal = _.find(reportObj.productivityGoalsResult, (item) => item.title == 'Achievement');
		let controlGoal = _.find(reportObj.productivityGoalsResult, (item) => item.title == 'Control');
		let balanceAndFreedomGoal = _.find(reportObj.productivityGoalsResult, (item) => item.title == 'Balance & Freedom');

		let symptomsAndRisks = _.filter(reportObj.symptomsAndRisksResult, (item) => item.score > 3);
        symptomsAndRisks = _.sortBy(symptomsAndRisks, 'score');
        symptomsAndRisks = symptomsAndRisks.reverse();

		let growthAreas = _.filter(reportObj.growthAreasResult, (item) => item.score < 6);
        growthAreas = _.sortBy(growthAreas, 'score');

		tableRowArray.push(
			<tr key={'row_scope_1_' + index}>
				<td className="text-center">{reports.length - index}</td>
				<td className="text-center" style={{minWidth: 150}}>
					<Link to={reportUrl} style={SharedStyles.titleLink}>{moment(reportObj['updatedAt']).format('MMM Do YYYY')}</Link>
				</td>
				<td className="text-center">{achievementGoal ? achievementGoal.score: 0}</td>
				<td className="text-center">{controlGoal ? controlGoal.score: 0}</td>
				<td className="text-center">{balanceAndFreedomGoal ? balanceAndFreedomGoal.score: 0}</td>
				<td className="text-center">{symptomsAndRisks[0] ? symptomsAndRisks[0].title : ''}</td>
				<td className="text-center">{growthAreas[0] ? growthAreas[0].title : ''}</td>
				<td className="text-center" style={{minWidth: 150}}>
					<Link to={reportUrl} className="btn btn-primary btn-xs">View Report</Link>
				</td>
			</tr>
		);

		return tableRowArray;
	}

	renderInnerInsights() {

		let { reports } = this.state;

		if (reports == null || reports.length == 0) {
			return (
				<div className="text-info small">No assessments records found.</div>
			);
		};

		return (
			<div style={{overflowX: 'auto'}}>
				<table class="table-curved mb-2">
					<col />
					<col />
					<colgroup span="3"></colgroup>
					<col />
					<thead>
						<tr>
							<th rowSpan="2" style={SharedStyles.tableHeading}>#</th>
							<th rowSpan="2" style={SharedStyles.tableHeading}>Date</th>
							<th colSpan="3" style={SharedStyles.tableHeading}>Productivity Goals (out of 7)</th>
							<th rowSpan="2" style={SharedStyles.tableHeading}>Top Risk</th>
							<th rowSpan="2" style={SharedStyles.tableHeading}>Top Growth Area</th>
							<th rowSpan="2" style={SharedStyles.tableHeading}>Action</th>
						</tr>
						<tr>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Achievement</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Control</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Balance & Freedom</th>
						</tr>
					</thead>
					<tbody>
						{
							reports.map((reportObj, index) => {
								return this.renderTableRow(reports, reportObj, index)
							})
						}
					</tbody>
				</table>
			</div>
		);
	}

	render() {

		return (
			<div>
				<div className="text-right">
					<Link to="/assessments/productivity-meter-assessment" className="btn btn-primary btn-sm mb-4"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp; Productivity Meter</Link>	
				</div> {/* Clicking on this should load IIA.js */}
				{this.renderInnerInsights()}
			</div>
		);
	}
}

PMDashboard.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	PMDashboard
);