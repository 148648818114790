import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import Util from '../../utils/Util';
import ReactSelect from 'react-select';
import SharedStyles from '../../assets/styles/SharedStyles';
import ViewUTask from "../tasks/ViewTask";
import TaskStatus from "../tasks/TaskStatus";
var _ = require('lodash');

class TaskManagement extends Component {

	constructor(props) {
		super(props);
		this.state = {
			utasks: [],
            unfilteredTasks: [],
            eventObj: _.cloneDeep(this.props.eventObj),
            outcomesWithPopulatedData: [],
			outcomeOptions: [],
			keyActivityOptions: [],
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			statusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Completed', value: 'completed'},
				{label: 'Open', value: 'open'}
			],
			selectedStatusOption: {label: 'Open', value: 'open'},
			scheduleStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Scheduled', value: 'scheduled'},
				{label: 'Unscheduled', value: 'unscheduled'}
			],
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			flagOptions: [
				{label: 'All', value: 'all'},
				{label: 'Flagged', value: 'flagged'},
				{label: 'Not Flagged', value: 'not-flagged'},
			],
			selectedFlagOptions: {label: 'All', value: 'all'},
			weeklyPlanStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Active', value: 'active'},
				{label: 'Inactive', value: 'inactive'}
			],
			selectedWeeklyPlanStatus: {label: 'Active', value: 'active'},
            itemToView: null,
            showAddTaskModal: false,
            showViewTaskModal: false,
            showFilterSection: false
		};
	}

    componentDidMount() {
		this.populatePropsData(this.props);
	}

    componentWillReceiveProps(props) {

        if (props.eventObj != this.state.eventObj) {

            this.setState({
                eventObj: _.cloneDeep(props.eventObj)
            });
        };

        if (!_.isEqual(props.utasks, this.state.unfilteredTasks)) {
			this.populatePropsData(props);
		};
    }

    populatePropsData(props) {
		
		let outcomesWithPopulatedData = props.outcomesWithPopulatedData || [];
		let outcomeOptions = [
			{label: 'All', value: 'all'},
			{label: 'Unassigned', value: 'unassigned'},
		]; // For default selection.

		_.map(outcomesWithPopulatedData, (outcomeObj) => {
			outcomeOptions.push({
				label: outcomeObj.outcomeTitle,
				value: outcomeObj.id
			});
		});

		this.setState({
			outcomesWithPopulatedData: outcomesWithPopulatedData,
			outcomeOptions: outcomeOptions,
			utasks: _.cloneDeep(props.utasks),
			unfilteredTasks: _.cloneDeep(props.utasks)
		},
		() => {
			this.applyFilter();
		}); 
	}

    setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
    }

    handleOutcomeSelectionChange(outcome) {

		if (outcome.value == 'unassigned') {

			let keyActivityOptions = [
				{ label: 'Unassigned', value: 'unassigned'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'Unassigned', value: 'unassigned'}
			});
		}
		else if (outcome.value == 'all') {

			let keyActivityOptions = [
				{ label: 'All', value: 'all'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
		else {

			let filterObjForTheSelectedOutcome = _.find(this.state.outcomesWithPopulatedData, {id: outcome.value});
			let keyActivityOptions = [
				{ label: 'All',value: 'all'}
			]; // For default selection.

			if (filterObjForTheSelectedOutcome && filterObjForTheSelectedOutcome.keyActivities) {

				_.map(filterObjForTheSelectedOutcome.keyActivities, (activityObj) => {
	
					keyActivityOptions.push({
						label: activityObj.title,
						value: activityObj.id
					});
				});
			};

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
	}

    applyFilter() {

		const {unfilteredTasks, selectedOutcome, selectedKeyActivity, selectedStatusOption, selectedScheduleStatusOption, selectedFlagOptions, selectedWeeklyPlanStatus} = this.state;

		let filteredTasks = [];

		// 1. Filter based on Outcome and KeyActivity.
		if (selectedOutcome.value == 'unassigned' || selectedKeyActivity.value == 'unassigned') {
			
			filteredTasks = _.filter(unfilteredTasks, (item) => {
				return !item.outcomeID && !item.keyActivity;
			});
		}
		else {

			if (selectedOutcome.value == 'all') {
				filteredTasks = Util.cloneArray(unfilteredTasks);
			}
			else if (selectedOutcome.value) {
				
				filteredTasks = _.filter(unfilteredTasks, (item) => {
					return item.outcomeID == selectedOutcome.value;
				});

				if (selectedKeyActivity.value && selectedKeyActivity.value != 'all' && selectedKeyActivity.value != 'unassigned') {
					
					filteredTasks = _.filter(filteredTasks, (item) => {
						return item.keyActivity == selectedKeyActivity.value;
					});
				}
			}
		};

		// 2. Filter based on includeInWeeklyPlan status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedWeeklyPlanStatus.value == 'all') return true;
			else if (!item.outcomeID && !item.keyActivity) return true; //The unassigned tasks should be unaffected by this filter
			else if (selectedWeeklyPlanStatus.value == 'active') return item.includeInWeeklyPlanStatusOfOutcome && item.includeInWeeklyPlanStatusOfKeyActivity;
			else if (selectedWeeklyPlanStatus.value == 'inactive') return !item.includeInWeeklyPlanStatusOfOutcome || !item.includeInWeeklyPlanStatusOfKeyActivity;
			else return false;
		});
		
		// 3. Filter based on task completion status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedStatusOption.value == 'all') return true;
			else if (selectedStatusOption.value == 'completed') return item.isCompleted;
			else if (selectedStatusOption.value == 'open') return !item.isCompleted;
			else return false;
		});

		// 4. Filter based on calendar schedule status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedScheduleStatusOption.value == 'all') return true;
			else if (selectedScheduleStatusOption.value == 'scheduled') return item.calendarSchedules && item.calendarSchedules.length > 0;
			else if (selectedScheduleStatusOption.value == 'unscheduled') return !item.calendarSchedules || (item.calendarSchedules && item.calendarSchedules.length == 0);
			else return false;
		});

		// 5. Filter based on flag.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedFlagOptions.value == 'all') return true;
			else if (selectedFlagOptions.value == 'flagged' && item.flags) return item.flags.length == 1;
			else if (selectedFlagOptions.value == 'not-flagged') return !item.flags || item.flags && item.flags.length == 0;
			else return false;
		});

		this.setState({
			utasks: filteredTasks,
			selectedOutcomeLabel: selectedOutcome ? selectedOutcome.label : '',
			selectedKeyActivityLabel: selectedKeyActivity ? selectedKeyActivity.label : '',
			selectedWeeklyPlanStatusLabel: selectedWeeklyPlanStatus ? selectedWeeklyPlanStatus.label : '',
			selectedStatusOptionLabel: selectedStatusOption ? selectedStatusOption.label : '',
			selectedScheduleStatusOptionLabel: selectedScheduleStatusOption ? selectedScheduleStatusOption.label : '',
			selectedFlagOptionLabel: selectedFlagOptions ? selectedFlagOptions.label : '',
			showFilterSection: false
		});
	}

    resetFilterOptions() {
		
		this.setState({
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			keyActivityOptions: [{label: 'All', value: 'all'}],
			selectedStatusOption: {label: 'All', value: 'all'},
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			selectedFlagOptions: {label: 'All', value: 'all'},
			selectedWeeklyPlanStatus: {label: 'All', value: 'all'},
			utasks: Util.cloneArray(this.state.unfilteredTasks),
		},
		() => {
			this.applyFilter();
		});
	}

    onTaskUpdate(updatedTaskObj) {

		const { unfilteredTasks } = this.state;
		var taskObjIndex = _.findIndex(unfilteredTasks, { id: updatedTaskObj.id });
		
		if (taskObjIndex > -1) {
			unfilteredTasks[taskObjIndex] = _.assign(unfilteredTasks[taskObjIndex], updatedTaskObj);
			this.setState({
				unfilteredTasks: unfilteredTasks
			},
			() => {
                this.applyFilter();
				this.props.onUpdateUTasks(unfilteredTasks);
			});
		};
	}

    toggleAddTaskModal() {

        this.setState({
			showAddTaskModal: !this.state.showAddTaskModal
		});
    }

    // Show or hide view modal
	toggleViewTaskModal(rowId) {

		if (this.state.itemToView) {
			this.setState({
				showViewTaskModal: false,
				itemToView: null,
			});
		} 
		else {
			this.setState({
				showViewTaskModal: true,
				itemToView: rowId,
			});
		}
	}

    toggleTaskSelection(taskObj) {

        let {eventObj} = this.state;
        let eventTasks = eventObj.tasks || [];

        let taskIndex = _.findIndex(eventTasks, {id: taskObj.id});

        if (taskIndex > -1) {
            eventTasks[taskIndex].isActive = eventTasks[taskIndex].isActive ? false : true; // When a task is added, removed, added back before saving, just toggle the active status
        }
        else {
            eventTasks.push({
                id: taskObj.id,
                task: taskObj.task,
                isActive: true
            });
        };

        eventObj.tasks = eventTasks;

        this.setState({
            eventObj: eventObj
        },
        () => this.props.onUpdate(eventObj));
    }

    toggleFilterSection() {

        this.setState({
			showFilterSection: !this.state.showFilterSection
		});
    }
    
    renderFilterSection() {

		let outcomesWithPopulatedData = this.props.outcomesWithPopulatedData || [];
		const { showFilterSection, outcomeOptions, keyActivityOptions, statusOptions, scheduleStatusOptions, flagOptions, weeklyPlanStatusOptions } = this.state;

		if (showFilterSection && outcomesWithPopulatedData.length > 0) {
			return(
				<div className="grey-container mb-3 tracker-utasks-filter">
					<div className="mb-2">
						<label className="small-title-text">Project</label>
						<ReactSelect
							name="outcome"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={outcomeOptions}
							value={this.state.selectedOutcome}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(outcome) => this.handleOutcomeSelectionChange(outcome)}
							placeholder="Filter by Project..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Key Activity</label>
						<ReactSelect
							name="keyActivity"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={keyActivityOptions}
							value={this.state.selectedKeyActivity}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(activity) => this.setState({selectedKeyActivity: activity})}
							placeholder="Filter by Key Activity..."
						/>
					</div>
					{this.state.selectedOutcome && this.state.selectedOutcome.value != 'unassigned' ?
						<div className="mb-2">
							<label className="small-title-text">Include In Weekly Plan Status</label>
							<ReactSelect
								name="includeInWeeklyPlanStatus"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={weeklyPlanStatusOptions}
								value={this.state.selectedWeeklyPlanStatus}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(option) => this.setState({selectedWeeklyPlanStatus: option})}
								placeholder="Filter by Include in Weekly Plan Status..."
							/>
						</div>
					: null}
					<div className="mb-2">
						<label className="small-title-text">Status</label>
						<ReactSelect
							name="status"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={statusOptions}
							value={this.state.selectedStatusOption}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(option) => this.setState({selectedStatusOption: option})}
							placeholder="Filter by completion status..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Calendar Schedule Status</label>
						<ReactSelect
							name="calendarScheduleStatus"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={scheduleStatusOptions}
							value={this.state.selectedScheduleStatusOption}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(option) => this.setState({selectedScheduleStatusOption: option})}
							placeholder="Filter by completion status..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Flag</label>
						<ReactSelect
							name="flags"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={flagOptions}
							value={this.state.selectedFlagOptions}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(options) => this.setState({selectedFlagOptions: options})}
							placeholder="Filter by flags..."
						/>
					</div>
					<div className="row row-section-container mt-3 mb-2">
						<button type="button" className="btn btn-primary btn-xs mr-3" onClick={this.applyFilter.bind(this)}>Apply</button>
						<button type="button" className="btn btn-primary btn-xs" onClick={this.resetFilterOptions.bind(this)}>Reset</button>
					</div>
				</div>
			)
		}
		else {
			return null;
		};
	}

    renderAddTasksModalPopup() {

        let {utasks, showFilterSection, eventObj} = this.state;
        let eventTasks = eventObj.tasks || [];

        return(
			<Modal isOpen={this.state.showAddTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
				<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>
                    <span>Add Tasks</span>
                    <span><button type="button" className="btn btn-primary btn-xs ml-2" title="Filter Task" onClick={this.toggleFilterSection.bind(this)} style={{fontSize:12, padding:5}}><i className="fa fa-filter" aria-hidden="true"></i></button></span>
                </ModalHeader>
				<ModalBody>
                    <div>
                        <div style={{maxHeight:600, overflowY:'auto', paddingRight: (showFilterSection || utasks.length > 10) ? 10 : 0}}>
                            {this.renderFilterSection()}
                            {utasks.length > 0 ?
                                <table className="table-curved">
                                    <thead>
                                        <tr>
                                            <th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}></th>
                                            <th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '65%'}}}>Task</th>
                                            <th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '25%'}}}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {utasks.map((eachtask, index) => {
                                        
                                            let isTaskAddedToTheEvent = _.findIndex(eventTasks, {id: eachtask.id, isActive: true}) > -1 ? true : false;

                                            return(
                                                <tr key={'task_list_in_model_' + index}>
                                                    <td className="text-center" onClick={this.toggleTaskSelection.bind(this, eachtask)} style={{fontSize:18}}>
                                                        {isTaskAddedToTheEvent ? <i className="fa fa-check-square-o color-primary" aria-hidden="true"></i> : <i className="fa fa-square-o color-primary" aria-hidden="true"></i>}
                                                    </td>
                                                    <td className="text-left"><span>{eachtask.task.substring(0, 12)}{(eachtask.task.length > 12) ? '...' : ''}</span> <span className="small">({Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)})</span></td>
                                                    <td className="text-left small">{eachtask.isCompleted ? 'Completed' : 'Open'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            : 
                                <div className="small">No task(s) found.</div>
                            } 
                        </div>
                        {utasks.length > 0 ?
                            <div className="mt-4 mb-2 text-center" onClick={this.toggleAddTaskModal.bind(this)}><button className="btn btn-primary btn-sm">Done</button></div>
                        : null}
                    </div>
				</ModalBody>
			</Modal>
		)
    }

    // Render view modal
	renderViewTaskInModal() {

		return (
			<div>
				<Modal isOpen={this.state.showViewTaskModal} toggle={this.toggleViewTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleViewTaskModal.bind(this)}>
						View Task
					</ModalHeader>
					<ModalBody>
						<ViewUTask taskID={this.state.itemToView} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	render() {

        let { eventObj, unfilteredTasks } = this.state;

        if (!eventObj) return null;

        let associatedTasks = _.reject(eventObj.tasks || [], {isActive: false});
        let eventTasks = _.filter(unfilteredTasks, (item) => _.findIndex(associatedTasks, {id: item.id}) > -1 ? true : false); // Filter only configured events.

		return (
			<div className="w-100">
                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <div>Tasks</div>
                    <div><button type="button" className="btn btn-primary btn-xs" title="Add Task" onClick={this.toggleAddTaskModal.bind(this)} style={{fontSize:12, padding:5}}><i className="fa fa-plus" aria-hidden="true"></i></button></div>
                </div>
                {eventTasks.length > 0 ?
                    <table className="table-curved mt-3">
                        <thead>
                            <tr>
                                <th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}><i className="fa fa-check" aria-hidden="true"></i></th>
                                <th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '50%'}}}>Task</th>
                                <th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '30%'}}}>Time Estimate</th>
                                <th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventTasks.map((eachtask, index) => {  
                                return(
                                    <tr key={'event_task_list_' + index}>
                                        <td className="text-center" style={{fontSize:16}}>
                                            <TaskStatus 
                                                taskObj={eachtask} 
                                                setApiResponse={this.setApiResponse.bind(this)}
                                                onTaskUpdate={this.onTaskUpdate.bind(this)} />
                                        </td>
                                        <td className="text-left small-title-text"><span style={SharedStyles.titleLink} className="cursor-pointer" onClick={this.toggleViewTaskModal.bind(this, eachtask.id)}>{eachtask.task.substring(0, 12)}{(eachtask.task.length > 12) ? '...' : ''}</span></td>
                                        <td className="text-left small-title-text">{Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)}</td>
                                        <td className="text-center" style={{fontSize:18}} onClick={this.toggleTaskSelection.bind(this, eachtask)}><i className="fa fa-minus-circle text-danger cursor-pointer" aria-hidden="true"></i></td>
                                    </tr>   
                                );
                            })}
                        </tbody>
                    </table>
                : 
                    <div className="small text-info">No associated task(s) found for this event.</div>
                }
                {this.renderAddTasksModalPopup()}
                {this.renderViewTaskInModal()}
			</div>
		);
	}
}

TaskManagement.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	TaskManagement
);