import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';

import { getKeyActivityByID, resetKeyActivityData } from "../../actions/keyActivityActions";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import KeyActivityForm from "./KeyActivityForm";

import { history } from '../../utils/history';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditKeyActivity extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			outcomeID: null,
			error: null,
			keyActivityID: null,
		}
	}

	componentWillUnmount() {
		// Reset data so that table will re-render
		this.props.resetKeyActivityData();
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		const { keyActivityId } = this.props.match.params;

		if (history.location.search) {
			const query = new URLSearchParams(history.location.search);
			const outcomeID = query.get('outcomeID');
			if (outcomeID) {
				this.setState({
					outcomeID: outcomeID,
					keyActivityID: keyActivityId,
				})
				this.props.getKeyActivityByID(keyActivityId);
			} else {
				this.setState({
					error: "Please create your project before creating key activities.",
				})
			}
		} else {
			this.setState({
				error: "Please create your project before creating key activities.",
			})
		}
	}

	// Handler for editing keyActivity
	handleEditKeyActivity(data, gotoParent) {

		data.recordID = this.state.keyActivityID;
		data.outcomeID = this.state.outcomeID;

		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateKeyActivity, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Key Activity saved successfully.", false);
					if (gotoParent) {

						const { outcomeID } = this.state;
						const editOutcomePage = "/projects/edit/" + outcomeID;
						history.push(editOutcomePage);
					}
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	renderForm() {

		const { keyactivity } = this.props.keyactivities;

		const { outcomeID, keyActivityID, formApiResponse } = this.state;

		if (!Util.isEmpty(keyactivity)) {

			return (
				<div className="row">
					<div className="col-md-12">
						<div className="form-container">
							<KeyActivityForm
								initialValues={keyactivity}
								onSubmit={this.handleEditKeyActivity.bind(this)}
								isEdit={true}
								outcomeID={outcomeID}
								keyActivityID={keyActivityID}
							/>
							{
								!_.isEmpty(formApiResponse)
									?
									<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
									: null
							}
						</div>
					</div>
				</div>
			);

		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">

								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	render() {

		const { outcomeID, keyActivityID, error } = this.state;

		const editOutcomePage = "/projects/edit/" + outcomeID;
		const editKeyActivityPage = "/key-activities/edit/" + keyActivityID + "?outcomeID=" + outcomeID;

		return (
			<div className="page-content container child-container">
				{!error
					? <div className="bread-crumb">
						<NavLink to={editOutcomePage}>Project</NavLink>&nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<NavLink to={editOutcomePage}>Key Activities</NavLink>&nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<NavLink to={editKeyActivityPage}>Edit</NavLink>&nbsp;
					</div>
					: null}
				<div className="section-container">
					{error
						? <p className="text-center py-4 text-warning">{error}</p>
						: this.renderForm()}
				</div>
			</div>
		);

	}
}

EditKeyActivity.propTypes = {
	getKeyActivityByID: PropTypes.func.isRequired,
	resetKeyActivityData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	keyactivities: state.keyactivities,
});

export default connect(mapStateToProps, { getKeyActivityByID, resetKeyActivityData, setLoading })(
	EditKeyActivity
);
