import axios from "axios";
import { toast } from "react-toastify";
import {
	GET_ERRORS,
	CLEAR_ERRORS,
	LOADING,
	RESET_OUTCOME,
	GET_OUTCOME,
	GET_OUTCOMES,
	GET_OUTCOMES_WITH_POPULATED_DATA,
	DELETE_OUTCOME,
} from "./types";

import API_Services from '../utils/API_Services';

var UrlConstants = require('../utils/UrlConstants');

// Reset to initial Values
export const resetoutcomeData = () => dispatch => {
	dispatch({
		type: RESET_OUTCOME,
	})
}

// Get User outcomes
export const getUserOutcomes = (response) => dispatch => {

	dispatch({
		type: GET_OUTCOMES,
		payload: response.data.data
	})
};

// Get user outcomes with populated data
export const getUserOutcomesWithPopulatedData = (response) => dispatch => {

	dispatch({
		type: GET_OUTCOMES_WITH_POPULATED_DATA,
		payload: response.data.data
	})
};

// Get a Outcome details by id
export const getOutcomeDetailsByID = (outcomeId) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.getOutcomeDetailsByID;

	var postObj = {
		recordID: outcomeId,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				/* In-order to keep productivity outcome and its projects synced, the projects that are part of productivity outcomes are restricted from user edit using the standalone module. */
				if (response.data.data && response.data.data.productivityOutcome) {
					let navURL = `/productivity-planner/outcomes/edit/${response.data.data.productivityOutcome}`;
					return window.location = navURL;
				}
				else {
					dispatch({
						type: GET_OUTCOME,
						payload: response.data.data
					});
				}
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});
};


// Remove an Outcome
export const removeOutcome = (id) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.removeOutcome;

	var postObj = {
		recordID: id,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_OUTCOMES,
					payload: response.data.data,
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});

};

// Update Outcome ranks
export const updateOutcomesRanks = (updatedOutcome) => dispatch => {

	dispatch(setLoading(true));

	var endPoint = UrlConstants.URLS.updateOutcomesRanks;

	var postObj = {
		outcomeResponses: updatedOutcome,
	}

	API_Services.httpPOST(endPoint, postObj, (err, response) => {

		if (err) {
			if (err.response && err.response.data) {
				toast.error(err.response.data);
			} else {
				toast.error('Something went wrong!');
				console.log('err...', err);
			}
		} else if (response.data) {
			if (response.data.status) {
				dispatch({
					type: GET_OUTCOMES,
					payload: response.data.data
				})
			} else {
				toast.info(response.data.message);
			}
		} else {
			toast.info('Something went wrong!');
		}

		dispatch(setLoading(false));
	});

};

export const setLoading = (loadingStatus) => {
	return {
		type: LOADING,
		payload: loadingStatus
	};
};