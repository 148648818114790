import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import TextareaAutosize from 'react-textarea-autosize';
import ReactSelect from 'react-select';

import KeyActivities from './KeyActivities';
import MetricsAndTargets from './MetricsAndTargets';
import Util from "../../utils/Util.js";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const statusOptions = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
];

class UpdateProjectTemplate extends Component {

	constructor(props) {
		super(props);
        this.state = {
            templateObj: this.props.templateObjToBeUpdated ? _.clone(this.props.templateObjToBeUpdated) : {},
            validationErrors: {},
            showUpdateTemplateModal: false,
            itemToRemove: null,
            showDeleteTemplateModal: false,
        };
    }
    
    componentDidMount() {
        this.setStickyToolBar();
    }

    setStickyToolBar() {

		const header = document.getElementById("update-project-template-floating-tool-bar");

		window.addEventListener("scroll", () => {
			
			if (!header) return;

			if (window.pageYOffset > 125) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

    computeDefaultKAMetricsFromProjectMetrics(projectMetrics) {

        projectMetrics = _.cloneDeep(projectMetrics || []);
        let projectMetricsAppliesToAllKeyActivities = _.filter(projectMetrics, {applyToAllKeyActivities: true});
        return _.map(projectMetricsAppliesToAllKeyActivities, (item) => {
            delete item.applyToAllKeyActivities;
            item.isInheritedFromProject = true;
            return item;
        });
    }

    handleSubmit() {

        let {templateObj, validationErrors} = this.state;
        
        let fieldsToBeValidated = ['templateStatus', 'projectTitle', 'outcome'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, templateObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };

        templateObj.templateType = 'project';
      
        this.props.setLoading(true);

        let postObj = {
            templateObj: templateObj
        };

        API_Services.httpPOST(UrlConstants.URLS.createOrUpdateSystemTemplate, postObj, (err, response) => {

            if (err) {
				
				if (err.response && err.response.data) {
					alert(err.response.data);
				} 
				else {
					alert('Something went wrong!');
				}
			}  
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    if (this.props.handleTemplateUpdates) {
                        this.props.handleTemplateUpdates(response.data.data);
                    };
				}; 
			}
            else {
                alert('Something went wrong!');
            }; 
			this.props.setLoading(false);
		});
    }
    
    validateUserInput(fieldName, response) {

        let validationMessage = 'valid';
        let mandatoryFields = ['templateStatus', 'projectTitle', 'outcome'];

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

    handleValueChanges(fieldName, response) {

        let {templateObj, validationErrors} = this.state;
        let stringResponseFields = ['projectTitle', 'outcome', 'successDefinition'];

        if (stringResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? response.target.value : '';
        }
        else if (fieldName == 'templateStatus') {   
            response = response.value;
        }
        else if (fieldName == 'metricsAndTargets') {
            
            let defaultKAMetrics = this.computeDefaultKAMetricsFromProjectMetrics(response);
            let projectKeyActivities = templateObj['keyActivities'];

            if (projectKeyActivities && projectKeyActivities.length > 0) {

                for (var i=0; i < projectKeyActivities.length; i++) {

                    let keyActivityMetrics = projectKeyActivities[i]['metricsAndTargets'];

                    if (keyActivityMetrics && keyActivityMetrics.length > 0) {
                        keyActivityMetrics = _.reject(keyActivityMetrics, {isInheritedFromProject: true});
                        projectKeyActivities[i]['metricsAndTargets'] = defaultKAMetrics.concat(keyActivityMetrics);
                    }
                    else {
                        projectKeyActivities[i]['metricsAndTargets'] = defaultKAMetrics;
                    };
                };
                templateObj['keyActivities'] = projectKeyActivities;
            };
        };

        let validationMessage = this.validateUserInput(fieldName, response);
        
        templateObj[fieldName] = response;
        validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

        this.setState({
            templateObj: templateObj,
            validationErrors: validationErrors
        });
    }

    toggleUpdateTemplateModal(templateObj) {

        if (this.state.showUpdateTemplateModal) {
            this.setState({
                templateObj: {},
                validationErrors: {},
                showUpdateTemplateModal: false
            });
        }
        else {
            this.setState({
                templateObj: _.clone(templateObj),
                validationErrors: {},
                showUpdateTemplateModal: true
            });
        };
    }

	render() {

        let { templateObj, validationErrors } = this.state;
        
		return (
			<div className="mt-n3">

                <div id={"update-project-template-floating-tool-bar"}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="font-bold"><u>Project Template</u></div>
                        <div>
                            <button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.handleSubmit.bind(this)}>Save Template</button>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.handleCancelUpdate()}>Templates Home</button>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>Project Title <span className="text-danger">*</span></label>
                    <div><input type="text" onChange={this.handleValueChanges.bind(this, 'projectTitle')} className="form-control" value={templateObj['projectTitle']} /></div>
                    <div className="text-danger small mt-1">{validationErrors['projectTitle']}</div>
                </div>

                <div className="form-group">
                    <label>Outcome <span className="text-danger">*</span></label>
                    <TextareaAutosize
                        onChange={this.handleValueChanges.bind(this, 'outcome')}
                        className="form-control"
                        minRows={2}
                        maxRows={10}
                        name="outcome"
                        value={templateObj['outcome']}>
                    </TextareaAutosize>
                    <div className="text-danger small mt-1">{validationErrors['outcome']}</div>
                </div>

                <div className="form-group">
                    <label>Success Definition</label>
                    <TextareaAutosize
                        onChange={this.handleValueChanges.bind(this, 'successDefinition')}
                        className="form-control"
                        minRows={2}
                        maxRows={10}
                        name="successDefinition"
                        value={templateObj['successDefinition']}>
                    </TextareaAutosize>
                    <div className="text-danger small mt-1">{validationErrors['successDefinition']}</div>
                </div>

                <KeyActivities 
                    keyActivities={templateObj['keyActivities']}
                    defaultKAMetrics={this.computeDefaultKAMetricsFromProjectMetrics(templateObj['metricsAndTargets'])}
                    onChange={this.handleValueChanges.bind(this, 'keyActivities')}/>
                
                <MetricsAndTargets
                    source={'projects'}
					metricsAndTargets={templateObj['metricsAndTargets']} 
					onChange={this.handleValueChanges.bind(this, 'metricsAndTargets')} />
			
                <div className="form-group">
                    <label>Template Status <span className="text-danger">*</span></label>
                    <ReactSelect
                        styles={SharedStyles.selectBoxStyles}
                        options={statusOptions}
                        value={_.find(statusOptions, {value: templateObj['templateStatus']})}
                        onChange={this.handleValueChanges.bind(this, 'templateStatus')} />
                    <div className="text-danger small mt-1">{validationErrors['templateStatus']}</div>
                </div>

                <div style={{height:50}} /> {/* Temporary fix for scroll flickering issue. */}
                
            </div>
		);
	}
}

UpdateProjectTemplate.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	UpdateProjectTemplate
);
