import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import ReactSelect from 'react-select';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const subscriptionPeriods = [
    {label: 'Lifetime', value: 'lifetime'},
    {label: 'Annual', value: 'annual'}
];

const statusOptions = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
];

class ActivationCodes extends Component {

	constructor(props) {
		super(props);
        this.state = {
            activationCodes: [],
            appModules: [],
            activationCodeObj: {}, // Handle the activationCodeObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            validationErrors: {},
            showUpdateCodeModal: false,
            itemToRemove: null,
            showDeleteCodeModal: false,
        };
    }
    
    componentDidMount() {

        this.props.setLoading(true);

        let fetchActivationCodes = this.fetchActivationCodes();
        let fetchAppModules = this.fetchAppModules();

        Promise.all([fetchActivationCodes, fetchAppModules]).then(results => {
            this.props.setLoading(false);
        });
    }

    fetchActivationCodes() {

        return new Promise((resolve) => {

            API_Services.httpGET(UrlConstants.URLS.fetchActivationCodes, (err, response) => {

                if (response.data) {
    
                    if (response.data.status && response.data.data) {
                        
                        this.setState({
                            activationCodes: response.data.data,
                        });
                    }; 
                };
                return resolve(true);
            });
        });
    }

    fetchAppModules() {
        
        return new Promise((resolve) => {
            
            API_Services.httpGET(UrlConstants.URLS.fetchAppModules, (err, response) => {

                if (response.data) {

                    if (response.data.status && response.data.data) {
                        
                        this.setState({
                            appModules: response.data.data,
                        });
                    }; 
                    return resolve(true);
                }; 
            });
        });
    }

    handleSubmit() {

        let {activationCodes, activationCodeObj, validationErrors} = this.state;
        
        let fieldsToBeValidated = ['code', 'accessModules', 'useLimit', 'subscriptionPeriod', 'status'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, activationCodeObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };

        this.props.setLoading(true);

        delete activationCodeObj.createdAt; // Don't let mongo generated fields get overridden on the update operation.
        delete activationCodeObj.updatedAt;

        let postObj = {
            activationCodeObj: activationCodeObj
        };

        API_Services.httpPOST(UrlConstants.URLS.createOrUpdateActivationCode, postObj, (err, response) => {

            if (err) {
				
				if (err.response && err.response.data) {
					alert(err.response.data);
				} 
				else {
					alert('Something went wrong!');
				}
			}  
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    let itemIndex = _.findIndex(activationCodes, {id: response.data.data.id});

                    if (itemIndex > -1) { // Update operation
                        activationCodes[itemIndex] = response.data.data;
                    }
                    else { // Add operation
                        activationCodes.push(response.data.data);
                    };
                    this.setState({
                        activationCodes: activationCodes
                    },
                    () => {
                        this.toggleUpdateCodeModal();
                    });
				}; 
			}
            else {
                alert('Something went wrong!');
            }; 
			this.props.setLoading(false);
		});
    }
    
    validateUserInput(fieldName, response) {

        let validationMessage = 'valid';

        if (fieldName == 'code' && !response) {
            validationMessage = 'This field is mandatory.'; 
        }
        else if (fieldName == 'accessModules' && (!response || (response && response.length == 0))) {
            validationMessage = 'Please add atleast one module.'; 
        }
        else if (fieldName == 'useLimit' && response && typeof response != 'number') {
            validationMessage = 'Please enter valid entry of type number';
        }
        else if (fieldName == 'subscriptionPeriod' && !response) {
            validationMessage = 'This field is mandatory.'; 
        }
        else if (fieldName == 'status' && !response) {   
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

    handleValueChanges(fieldName, response) {

        let {activationCodeObj, validationErrors} = this.state;

        if (fieldName == 'code') {
            response = response.target.value ? response.target.value.trim().toUpperCase() : '';
        }
        else if (fieldName == 'description') {
            response = response.target.value;
        }
        else if (fieldName == 'accessModules') {
            response = _.map(response, (item) => item.value);
        }
        else if (fieldName == 'useLimit') {
            response = parseInt(response.target.value);
        }
        else if (fieldName == 'subscriptionPeriod') { 
            response = response.value;
        }
        else if (fieldName == 'status') {   
            response = response.value;
        };

        let validationMessage = this.validateUserInput(fieldName, response);
        
        activationCodeObj[fieldName] = response;
        validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

        this.setState({
            activationCodeObj: activationCodeObj,
            validationErrors: validationErrors
        });
    }

    toggleUpdateCodeModal(activationCodeObj) {

        if (this.state.showUpdateCodeModal) {
            this.setState({
                activationCodeObj: {},
                validationErrors: {},
                showUpdateCodeModal: false
            });
        }
        else {
            this.setState({
                activationCodeObj: _.clone(activationCodeObj),
                validationErrors: {},
                showUpdateCodeModal: true
            });
        };
    }

    renderUpdateCodeModal() {

        let {activationCodeObj, validationErrors, appModules} = this.state;
        let accessModules = activationCodeObj['accessModules'] ? _.filter(appModules, (item) => activationCodeObj['accessModules'].indexOf(item.value) > -1) : null;

        return(
			<div>
				<Modal size={'lg'} isOpen={this.state.showUpdateCodeModal} toggle={this.toggleUpdateCodeModal.bind(this)}>
					<ModalHeader toggle={this.toggleUpdateCodeModal.bind(this)}>Activation Code</ModalHeader>
					<ModalBody>

                        <div className="form-group">
							<label>Code <span className="text-danger">*</span></label>
							<div><input type="text" onChange={this.handleValueChanges.bind(this, 'code')} className="form-control" value={activationCodeObj['code']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['code']}</div>
						</div>

                        <div className="form-group">
							<label>Description</label>
                            <TextareaAutosize
                                onChange={this.handleValueChanges.bind(this, 'description')}
                                className="form-control"
                                minRows={1} 
                                maxRows={10}
                                value={activationCodeObj['description']} />
                            <div className="text-danger small mt-1">{validationErrors['description']}</div>
						</div>

                        <div className="form-group">
							<label>Access Modules <span className="text-danger">*</span></label>
                            <ReactSelect
                                styles={SharedStyles.selectBoxStyles}
                                options={appModules}
                                isMulti={true}
                                value={accessModules}
                                isClearable={true}
                                onChange={this.handleValueChanges.bind(this, 'accessModules')} />
                            <div className="text-danger small mt-1">{validationErrors['accessModules']}</div>
                        </div>

                        <div className="form-group">
							<label>Use Limit <span className="small">(No. of times this code can be used)</span></label>
							<div><input type="number" onChange={this.handleValueChanges.bind(this, 'useLimit')} className="form-control" value={activationCodeObj['useLimit']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['useLimit']}</div>
						</div>

                        <div className="form-group">
							<label>Subscription Period <span className="text-danger">*</span></label>
                            <ReactSelect
                                styles={SharedStyles.selectBoxStyles}
                                options={subscriptionPeriods}
                                value={_.find(subscriptionPeriods, {value: activationCodeObj['subscriptionPeriod']})}
                                onChange={this.handleValueChanges.bind(this, 'subscriptionPeriod')} />
                            <div className="text-danger small mt-1">{validationErrors['subscriptionPeriod']}</div>
                        </div>

                        <div className="form-group">
							<label>Status <span className="text-danger">*</span></label>
                            <ReactSelect
                                styles={SharedStyles.selectBoxStyles}
                                options={statusOptions}
                                value={_.find(statusOptions, {value: activationCodeObj['status']})}
                                onChange={this.handleValueChanges.bind(this, 'status')} />
                            <div className="text-danger small mt-1">{validationErrors['status']}</div>
                        </div>

                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Save Changes</button>

					</ModalBody>
				</Modal>
			</div>
		);
    }

    toggleDeleteCodeModal(recordId) {

		if (this.state.showDeleteCodeModal) {
			this.setState({
				showDeleteCodeModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteCodeModal: true,
				itemToRemove: recordId,
			});
		}
	}

    renderDeleteCodeModal() {

		var activationCodeObj = _.find(this.state.activationCodes, { id: this.state.itemToRemove });
		var code = activationCodeObj ? activationCodeObj.code : '';

		return (
			<div>
				<Modal isOpen={this.state.showDeleteCodeModal} toggle={this.toggleDeleteCodeModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteCodeModal.bind(this)}>Delete Code</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the Code <span className="text-warning">{code}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleCodeDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteCodeModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

    handleCodeDelete() {

        let {activationCodes, itemToRemove} = this.state;

        if (!itemToRemove) return;

        this.props.setLoading(true);

        let postObj = {
            recordID: itemToRemove
        };

        API_Services.httpPOST(UrlConstants.URLS.deleteActivationCode, postObj, (err, response) => {

			if (err) {
                alert('Something went wrong!');
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    let itemIndex = _.findIndex(activationCodes, {id: response.data.data.id});
                    activationCodes.splice(itemIndex, 1);

                    this.setState({
                        activationCodes: activationCodes
                    },
                    () => {
                        this.toggleDeleteCodeModal();
                    });
				}; 
			}; 
			this.props.setLoading(false);
		});
    }

	render() {

        let { activationCodes, appModules } = this.state;
        
		return (
			<div>
                <div className="text-right mt-n3 mb-4">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.toggleUpdateCodeModal.bind(this, {})}>Create Code <i className="fa fa-plus" aria-hidden="true"></i></button>
                </div>
                {activationCodes.length == 0 ?
                    <div className="text-info small"> No Activation Code(s) found.</div>
                :
                    <div className="App">
                        <header className="App-header table-responsive" style={{overflowX: 'auto'}}>
                            <table className="table-curved mb-3">
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="" style={{minWidth:150, maxWidth: 200}}>Code</th>
                                        <th className="" style={{minWidth:250, maxWidth: 350}}>Access Modules</th>
                                        <th className="text-center">Subscription</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center"># Limit</th>
                                        <th className="text-center"># Used</th>
                                        <th className="text-center" colSpan="2" style={{width: 100}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activationCodes.map((codeObj, index) => {
                                        
                                        let subscriptionPeriod = _.find(subscriptionPeriods, {value: codeObj.subscriptionPeriod});
                                        let status = _.find(statusOptions, {value: codeObj.status});
                                        let accessModules = _.filter(appModules, (item) => codeObj.accessModules.indexOf(item.value) > -1);
                                        accessModules = _.map(accessModules, (item) => item.label);

                                        return(
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="font-bold" style={{minWidth:125, maxWidth: 200}}>{codeObj.code}</td>
                                                <td className="" style={{minWidth:250, maxWidth: 350}}>{accessModules.join(', ')}</td>
                                                <td className="text-center">{subscriptionPeriod ? subscriptionPeriod.label : ''}</td>
                                                <td className="text-center">{status ? status.label : ''}</td>
                                                <td className="text-center">{codeObj.useLimit}</td>
                                                <td className="text-center">{codeObj.usedCount}</td>
                                                <td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleUpdateCodeModal.bind(this, codeObj)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
                                                <td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteCodeModal.bind(this, codeObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </header>
                    </div>
                }
                {this.renderUpdateCodeModal()}
                {this.renderDeleteCodeModal()}
			</div>
		);
	}
}

ActivationCodes.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ActivationCodes
);
