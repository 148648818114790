import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ButtonGroup } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Timescale from "./Timescale";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class GuidingStarsDashboard extends Component {

    constructor(props) {
		super(props);
		this.state = {
			displayTimescale: 'long-term', // The visible timescale section auto-selected by the system [OR] manually selected by the user.
            responseData: null,
            sectionData: null
        };
	}

    componentDidMount() {
        this.getIndividualTimescaleResponses();
    }

    getIndividualTimescaleResponses() {

        this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getIndividualTimescaleResponses, (err, response) => {

			if (err) {
				
                if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
                else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
					
                    let responseData = response.data.data;
                    
                    /*
                    When the user goes to the Guiding Star, the default tab should be per the following logic:
                     - If the user does not have data for any of the sections saved, let’s open the Long Term tab open by default.
                     - If the user has the Long Term data saved but not the Mid Term, let’s open the Mid Term tab open by default.
                     - If the user has Long Term and Mid Term data saved but not the Short Term, let’s open the Short Term tab by default.
                     - If the user has Long Term, Mid Term, and Short Term data saved but not the Daily, let’s open the Daily tab by default.
                     - If the user has Long Term, Mid Term, and Short Term and Daily data saved, let’s open the Current Sprint tab by default.
                    */
                    let displayTimescale = 'long-term';

                    if (responseData.longTermResponse.isResponded && !responseData.midTermResponse.isResponded) {
                        displayTimescale = 'mid-term';
                    }
                    else if (responseData.longTermResponse.isResponded && responseData.midTermResponse.isResponded && !responseData.shortTermResponse.isResponded) {
                        displayTimescale = 'short-term';
                    }
                    else if (responseData.longTermResponse.isResponded && responseData.midTermResponse.isResponded && responseData.shortTermResponse.isResponded && !responseData.dailyResponse.isResponded) {
                        displayTimescale = 'daily';
                    }
                    else if (responseData.longTermResponse.isResponded && responseData.midTermResponse.isResponded && responseData.shortTermResponse.isResponded && responseData.dailyResponse.isResponded) {
                        displayTimescale = 'current-sprint';
                    };

                    this.setState({
                        responseData: responseData
                    }, 
                    () => this.handleTimescaleDisplayChange(displayTimescale));
				} 
                else {
					this.setApiResponse(response.data.message, true);
				};
			} 
            else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

    setApiResponse(message, hasError) {

        if (hasError) {
			alert(message);
		};
		return;
        
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message
			},
		},
        () => {
            setTimeout(() => {
                this.setState({
                    apiResponse: {
                        className: "",
                        message: "",
                    },
                });
            }, 3000);
        });
	}

    handleSubmit(sectionResponse) {

        let { responseData, displayTimescale } = this.state;

        if (displayTimescale == 'current-sprint' && responseData.currentSprintResponse) {
            responseData.currentSprintResponse['isResponded'] = true;     
            responseData.currentSprintResponse['sectionResponse'] = sectionResponse;     
        }
        else if (displayTimescale == 'daily' && responseData.dailyResponse) {
            responseData.dailyResponse['isResponded'] = true;     
            responseData.dailyResponse['sectionResponse'] = sectionResponse;     
        }
        else if (displayTimescale == 'short-term' && responseData.shortTermResponse) {
            responseData.shortTermResponse['isResponded'] = true;     
            responseData.shortTermResponse['sectionResponse'] = sectionResponse;
        }
        else if (displayTimescale == 'mid-term' && responseData.midTermResponse) {
            responseData.midTermResponse['isResponded'] = true;     
            responseData.midTermResponse['sectionResponse'] = sectionResponse;
        }
        else if (displayTimescale == 'long-term' && responseData.longTermResponse) {
            responseData.longTermResponse['isResponded'] = true;     
            responseData.longTermResponse['sectionResponse'] = sectionResponse;
        };

        this.setState({
            responseData: responseData
        }, 
        () => this.handleTimescaleDisplayChange(displayTimescale));
    }

    handleTimescaleDisplayChange(timescale) {

        let { responseData, sectionData } = this.state;

        if (timescale == 'current-sprint') {
            sectionData = responseData.currentSprintResponse;     
        }
        else if (timescale == 'daily') {
            sectionData = responseData.dailyResponse;     
        }
        else if (timescale == 'short-term') {
            sectionData = responseData.shortTermResponse;
        }
        else if (timescale == 'mid-term') {
            sectionData = responseData.midTermResponse;
        }
        else if (timescale == 'long-term') {
            sectionData = responseData.longTermResponse;
        }

        this.setState({
            displayTimescale: timescale,
            sectionData: sectionData
        });
    }

    renderTimescaleButtons() {
        
        let activeClass = "pbh-small-btn-active";
		let defaultClass = "pbh-small-btn-default";

        return(
            <div className="text-center">
                <ButtonGroup>
                    <Button onClick={this.handleTimescaleDisplayChange.bind(this, 'current-sprint')} className={(this.state.displayTimescale == 'current-sprint'? activeClass: defaultClass)} style={{fontSize:14}}>Current Sprint</Button>
                    <Button onClick={this.handleTimescaleDisplayChange.bind(this, 'daily')} className={(this.state.displayTimescale == 'daily'? activeClass: defaultClass)} style={{fontSize:14}}>Daily</Button>
                    <Button onClick={this.handleTimescaleDisplayChange.bind(this, 'short-term')} className={(this.state.displayTimescale == 'short-term'? activeClass: defaultClass)} style={{fontSize:14}}>Short Term</Button>
                    <Button onClick={this.handleTimescaleDisplayChange.bind(this, 'mid-term')} className={(this.state.displayTimescale == 'mid-term'? activeClass: defaultClass)} style={{fontSize:14}}>Mid Term</Button>
                    <Button onClick={this.handleTimescaleDisplayChange.bind(this, 'long-term')} className={(this.state.displayTimescale == 'long-term'? activeClass: defaultClass)} style={{fontSize:14}}>Long Term</Button>
                </ButtonGroup>
            </div>
        );
    }

    renderDisplayTimescale() {

        const { sectionData, displayTimescale } = this.state;

        return(
            <div style={{marginTop:35}}>
                <Timescale
                    displayTimescale={displayTimescale} 
                    sectionData={sectionData} 
                    handleSubmit={this.handleSubmit.bind(this)}/>
            </div>
        );
    }

    render() {

        const { apiResponse } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/guiding-stars/">Guiding Stars</a>
				</div>
				<div className="section-container">
					<div className="form-container">
                        {this.renderTimescaleButtons()}
                        { !_.isEmpty(apiResponse) ?
							<p className={apiResponse.className + ' text-center mt-3'}><span>{apiResponse.message}</span></p>
						: null}
                        {this.renderDisplayTimescale()}
					</div>
				</div>
			</div>
		);
	}
}

GuidingStarsDashboard.propTypes = {
    setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {setLoading})(
	GuidingStarsDashboard
);