import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker/dist/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class AddCurrentSprint extends Component {

	constructor(props) {
		super(props);
		this.state = {
			date: {
				maxStartEndDate: null,
				maxEndDate: null,
				startDate: '',
				endDate: '',
				clearStartDate: '',
				clearEndDate: '',
			},
			dateErrors: {
				startDate: null,
				endDate: null,
			},
			formApiResponse: {
				className: "",
				message: "",
			},
			buttonDisabled: false,
		};
	}

	componentDidMount() {

		if (this.props.currentsprintquestions && this.props.currentsprintquestions.length > 0) {

			// Generate/define question state
			var stateObj = {
				form: {},
				formErrors: {},
				formDisabledInputs: false,
				dynamicqrendered: false,
			};

			this.props.currentsprintquestions.map((value, index) => {
				stateObj['form'][value.uniqueID] = "";
				stateObj['formErrors'][value.uniqueID] = "";
			});

			stateObj['dynamicqrendered'] = true;
			this.setState(stateObj);
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Callback for Date change
	handleChangeDate(name, value) {

		var { date, dateErrors } = this.state;

		var dateObj = {};

		var maxEndDate = this.state.date.maxEndDate;

		if (name === "startDate") {

			dateObj = {
				...date,
				startDate: value,
				endDate: "",
				maxEndDate: maxEndDate,
				clearStartDate: true,
				clearEndDate: false,
			};
		} else if (name === "endDate") {

			dateObj = {
				...date,
				endDate: value,
				clearEndDate: true,
			};
		}

		this.setState({
			date: dateObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var dateErrorsObj = { ...dateErrors, [name]: errorMsg };
			this.setState({ dateErrors: dateErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		if (!value) errorMsg = "This field is mandatory";

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Current Sprint submit
	handleCurrentSprintSubmit(e) {
		e.preventDefault();

		return this.setFormApiResponse("The 'Current Sprint' section has been moved under 'Guiding Stars' module.", true);

		this.props.setLoading(true);

		const { form, formErrors, date, dateErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));
		const dateErrorObj = this.validateForm(date, dateErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0 || Object.keys(dateErrorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
				dateErrors: { ...dateErrors, ...dateErrorObj },
			}, () => {
				this.props.setLoading(false);
			});

			return false;

		} else {

			this.setState({
				buttonDisabled: true,
			});

			var currentSprintResponses = [];
			var startDate = moment(date.startDate).format('MM/DD/YYYY');
			var endDate = moment(date.endDate).format('MM/DD/YYYY');

			_.forEach(this.props.currentsprintquestions, function (value, key) {
				currentSprintResponses.push({
					...value,
					response: form[value['uniqueID']],
				})
			});

			var postObj = {
				cs_responses: currentSprintResponses,
				startDate: startDate,
				endDate: endDate,
			}

			API_Services.httpPOST(UrlConstants.URLS.addCurrentSprint, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.setState({
						buttonDisabled: false,
					});
				} else if (response.data) {

					this.setFormApiResponse("Record have been saved successfully!", false);
					window.location = "/current-sprint/";
					// console.log('response...', response);
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					this.setState({
						buttonDisabled: false,
					});
				}

				this.props.setLoading(false);
			});
		}
	}

	renderTextAreaField(fieldInputs) {

		const { form, formErrors } = this.state;

		return (
			<div className="form-group">
				<label>{fieldInputs.prompt} <span className="text-danger">*</span></label>
				<div>
					<textarea
						onChange={this.handleInputField.bind(this)}
						className="form-control"
						rows={fieldInputs.defaultRowsToBeRendered}
						name={fieldInputs.uniqueID}
						value={form[fieldInputs.uniqueID]}>
						{form[fieldInputs.uniqueID]}
					</textarea>
					<p className="text-danger">{formErrors[fieldInputs.uniqueID] &&
						<span className="err">{formErrors[fieldInputs.uniqueID]}</span>}</p>
				</div>
			</div>
		);
	}

	renderByFieldType(fieldInputs) {

		if (fieldInputs.fieldType == 'text_area') {
			return (
				this.renderTextAreaField(fieldInputs)
			);
		}
	}

	render() {

		if (this.state.dynamicqrendered) {

			const { date, dateErrors, formApiResponse } = this.state;

			return (
				<form onSubmit={this.handleCurrentSprintSubmit.bind(this)}>
					{
						this.props.currentsprintquestions.map((value, index) => {
							return <div className="row" key={index}>
								<div className="col-md-12">
									{this.renderByFieldType(value)}
								</div>
							</div>
						})
					}
					<div className="form-group">
						<div className="row">
							<div className="col-lg-6">
								<p>Start Date <span className="text-danger">*</span></p>
								<DatePicker
									selected={date.startDate}
									startDate={date.startDate}
									endDate={date.endDate}
									onChange={this.handleChangeDate.bind(this, "startDate")}
									maxDate={date.maxStartEndDate}
									placeholderText="Start Date"
									className="form-control"
									isClearable={date.clearStartDate}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dateFormat="MMMM d, yyyy"
									dropdownMode="select"
								/>
								<p className="text-danger">{dateErrors.startDate &&
									<span className="err">{dateErrors.startDate}</span>}</p>
							</div>
							<div className="col-lg-6">
								<div className="d-lg-none" style={{ margin: "8px" }}></div>
								<p>End Date <span className="text-danger">*</span></p>
								<DatePicker
									selected={date.endDate}
									startDate={date.startDate}
									endDate={date.endDate}
									onChange={this.handleChangeDate.bind(this, "endDate")}
									minDate={date.startDate}
									maxDate={date.maxEndDate}
									placeholderText="End Date"
									className="form-control"
									isClearable={date.clearEndDate}
									disabled={date.startDate ? false : true}
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dateFormat="MMMM d, yyyy"
									dropdownMode="select"
								/>
								<p className="text-danger">{dateErrors.endDate &&
									<span className="err">{dateErrors.endDate}</span>}</p>
							</div>
						</div>
					</div>
					<button type="submit" className="btn btn-primary">Save</button>
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</form>
			);
		}
		else {
			return <div />;
		}
	}
}

AddCurrentSprint.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	AddCurrentSprint
);
