import React, { Component } from 'react';
import { Router, Switch, Route } from "react-router";
import ReactGA from "react-ga";

import { Provider } from "react-redux";
import store from "./store.js";
import { history } from './utils/history';

import PrivateRoute from "./components/common/PrivateRoute.js";
import RedirectRoute from "./components/common/RedirectRoute.js";
import Spinner from "./components/common/Spinner.js";

// General Layouts
import NavBar from "./components/layout/NavBar";
import Footer from "./components/layout/Footer";
import Landing from './components/layout/Landing';
import NotFound from './components/not-found/NotFound';

// Auth Pages
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import ResetPassword from "./components/auth/ResetPassword";

// Dashboard
import Dashboard from "./components/dashboard/Dashboard";

// GuidingStar
import GuidingStarsDashboard from "./components/guiding-stars/GuidingStarsDashboard";
import CurrentSprintDashboard from "./components/current-sprint/CurrentSprintDashboard";

//Compass
import CompassDashboard from "./components/compass/CompassDashboard";
import EditCompass from "./components/compass/EditCompass";

// FocusArea
import FocusAreaDashboard from "./components/focus-areas/FocusAreaDashboard";
import AddFocusArea from "./components/focus-areas/AddFocusArea";
import EditFocusArea from "./components/focus-areas/EditFocusArea";

// ProductivityPlanner
import ProductivityPlannerHome from "./components/productivity-planner/ProductivityPlannerHome";
import ProductivityOutcomeForm from "./components/productivity-planner/ProductivityOutcomeForm";

// Outcome
import OutcomeDashboard from "./components/outcomes/OutcomeDashboard";
import AddOutcome from "./components/outcomes/AddOutcome";
import EditOutcome from "./components/outcomes/EditOutcome";

// KeyActivity
import KeyActivityDashboard from "./components/key-activities/KeyActivityDashboard";
import AddKeyActivity from "./components/key-activities/AddKeyActivity";
import EditKeyActivity from "./components/key-activities/EditKeyActivity";

// Note
import NoteDashboard from "./components/notes/NoteDashboard";
import AddNote from "./components/notes/AddNote";
import EditNote from "./components/notes/EditNote";

// Available Time
import AvailableTimeTable from "./components/available-time/AvailableTimeTable";

// Weekly Plan
import WeeklyPlanScheduler from "./components/weekly-plan/WeeklyPlanScheduler";

// Tracker
import Tracker from "./components/tracker/Tracker";
import DailyTracker from "./components/tracker/DailyTracker";

// Time Allocation
import TimeAllocationTable from "./components/time-allocation/TimeAllocationTable";

// Tracker Report
import TrackerReport from "./components/tracker-report/TrackerReport";

// Universal Tasks
import UTaskDashboard from "./components/universal-tasks/UTaskDashboard";
import EditUTask from "./components/universal-tasks/EditUTask";
import AddUTask from "./components/universal-tasks/AddUTask";

// Daily Review
import DailyReview from "./components/daily-review/DailyReview";

// Weekly Review
import WeeklyReview from "./components/weekly-review/WeeklyReview";

// Monthly Review
import MonthlyReview from "./components/monthly-review/MonthlyReview";

// Super Admin
import SuperAdminDashboard from "./components/super-admin/SuperAdminDashboard";

// Assessments
import AssessmentsDashboard from "./components/assessments/AssessmentsDashboard";
import IIA from "./components/inner-insights/IIA";
import IIAReport from "./components/inner-insights/IIAReport";
import PMAssessment from "./components/productivity-meter/PMAssessment";
import ProductivityMeterReport from "./components/productivity-meter/ProductivityMeterReport";

// Subscriptions
import SubscriptionHome from "./components/subscription/SubscriptionHome";

// Profile
import UserProfile from "./components/user/UserProfile";

var Globals = require('./utils/Globals');
var gaID = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].REACT_APP_GA;

history.listen((location) => {
	window.scrollTo(0, 0);
	ReactGA.initialize(gaID);
    ReactGA.pageview(location.pathname + location.search);
});

export default class Navigation extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Provider store={store}>
				<Router history={history}>
					<div className="App">
						<Spinner />
						<NavBar />
						<div style={{marginTop: 80}} className="container-with-background-image">
							<Switch>
								{/* Public Routes */}
								<RedirectRoute path='/' exact component={Login} />
								<RedirectRoute path='/login' exact component={Login} />
								<RedirectRoute path='/signup' exact component={Signup} />
								<RedirectRoute path='/reset-password' exact component={ResetPassword} />

								{/* Private Routes */}

								{/* Dashboard routes */}
								<PrivateRoute exact path="/dashboard" component={Dashboard} />

								{/* GuidingStar routes */}
								<PrivateRoute exact path="/guiding-stars" component={GuidingStarsDashboard} />
								<PrivateRoute exact path="/current-sprint" component={CurrentSprintDashboard} />

								{/* Compass routes */}
								<PrivateRoute exact path="/compass/:compassId" component={EditCompass} />
								<PrivateRoute exact path="/compass" component={CompassDashboard} />

								{/* FocusArea routes */}
								{/* <PrivateRoute exact path="/focus-areas" component={FocusAreaDashboard} />
								<PrivateRoute exact path="/focus-areas/add" component={AddFocusArea} />
								<PrivateRoute exact path="/focus-areas/edit/:focusAreaId" component={EditFocusArea} /> */}

								{/* Productivity Planner routes */}
								<PrivateRoute exact path="/productivity-planner/:active_tab?" component={ProductivityPlannerHome} />
								<PrivateRoute exact path="/productivity-planner/outcomes/add" component={ProductivityOutcomeForm} />
								<PrivateRoute exact path="/productivity-planner/outcomes/edit/:productivityOutcomeId" component={ProductivityOutcomeForm} />

								{/* Outcome routes */}
								<PrivateRoute exact path="/projects" component={OutcomeDashboard} />
								<PrivateRoute exact path="/projects/add" component={AddOutcome} />
								<PrivateRoute exact path="/projects/edit/:outcomeId" component={EditOutcome} />

								{/* KeyActivity routes */}
								<PrivateRoute exact path="/key-activities" component={KeyActivityDashboard} />
								<PrivateRoute exact path="/key-activities/add" component={AddKeyActivity} />
								<PrivateRoute exact path="/key-activities/edit/:keyActivityId" component={EditKeyActivity} />

								{/* Note routes */}
								<PrivateRoute exact path="/notes" component={NoteDashboard} />
								<PrivateRoute exact path="/notes/add" component={AddNote} />
								<PrivateRoute exact path="/notes/edit/:noteId" component={EditNote} />

								{/* Available Time routes */}
								<PrivateRoute exact path="/available-time" component={AvailableTimeTable} />

								{/* Time Allocation routes */}
								<PrivateRoute exact path="/time-commitment" component={TimeAllocationTable} />

								{/* Weekly Plan routes */}
								<PrivateRoute exact path="/weekly-plan" component={WeeklyPlanScheduler} />

								{/* Tracker routes */}
								<PrivateRoute exact path="/calendar" component={Tracker} />
								<PrivateRoute exact path="/tracker/d" component={DailyTracker} />
								<PrivateRoute exact path="/tracker-report" component={TrackerReport} />

								{/* Universal Task routes */}
								<PrivateRoute exact path="/tasks" component={UTaskDashboard} />
								{/* <PrivateRoute exact path="/tasks/add" component={AddUTask} />
								<PrivateRoute exact path="/tasks/edit/:taskId" component={EditUTask} /> */}

								{/* Daily Review routes */}
								<PrivateRoute exact path="/daily-review" component={DailyReview} />

								{/* Monthly Review routes */}
								<PrivateRoute exact path="/monthly-review" component={MonthlyReview} />

								{/* Weekly Review routes */}
								<PrivateRoute exact path="/weekly-review" component={WeeklyReview} />

								{/* Super Admin routes */}
								<PrivateRoute exact path="/super-admin" component={SuperAdminDashboard} />
								
								{/* Assessments routes */}
								<PrivateRoute exact path="/assessments/inner-insight-assessment" component={IIA} />
								<PrivateRoute exact path="/assessments/inner-insight-report/:assessmentId" component={IIAReport} />
								<PrivateRoute exact path="/assessments/productivity-meter-assessment" component={PMAssessment} />
								<PrivateRoute exact path="/assessments/productivity-meter-report/:assessmentId" component={ProductivityMeterReport} />
								<PrivateRoute exact path="/assessments/:active_tab?" component={AssessmentsDashboard} />

								{/* Subscription routes */}
								<PrivateRoute exact path="/subscription" component={SubscriptionHome} />

								{/* User routes */}
								<PrivateRoute exact path="/profile" component={UserProfile} />

								<Route exact path="*" component={NotFound} />
							</Switch>
						</div>
						<Footer />
					</div>
				</Router>
			</Provider>
		);
	}
}