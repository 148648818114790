import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Util from '../../utils/Util';

import TimeEstimateSelect from "../form-inputs/TimeEstimateSelect";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class Tasks extends Component {

	constructor(props) {
		super(props);
        this.state = {
            tasks: this.props.tasks ? _.clone(this.props.tasks) : [],
            taskObj: {}, // Handle the taskObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            validationErrors: {},
            showUpdateModal: false,
            itemToRemove: null,
            showDeleteModal: false,
        };
    }

	toggleUpdateModal(taskObj) {

        if (this.state.showUpdateModal) {
            this.setState({
                taskObj: {},
                validationErrors: {},
                showUpdateModal: false
            });
        }
        else {
            this.setState({
                taskObj: _.clone(taskObj),
                validationErrors: {},
                showUpdateModal: true
            });
        };
    }

	updateTasksInParent() {
		
		let {tasks, taskObj, validationErrors} = this.state;
		
		let fieldsToBeValidated = ['task', 'timeEstimateInMinutes'];
        let hasValidationError = false;

        fieldsToBeValidated.map((fieldName) => {
            
            let validationMessage = this.validateUserInput(fieldName, taskObj[fieldName]);
            
            if (validationMessage != 'valid') {
                validationErrors[fieldName] = validationMessage;
                hasValidationError = true;
            };
        });

        if (hasValidationError) {
            return this.setState({validationErrors: validationErrors});
        };
		
		let itemIndex = _.findIndex(tasks, {id: taskObj.id});

		if (itemIndex > -1) {
			tasks[itemIndex] = taskObj;
		}
		else {
			taskObj.id = Util.generateObjectId();
			tasks.push(taskObj);
		};
		
		this.setState({
			tasks: tasks
		},
		() => {

			if (this.props.onChange) {
				this.props.onChange(tasks); // Update state of both the parent and child components.
			};
			this.toggleUpdateModal();
		});
	}

	validateUserInput(fieldName, response) {

        let validationMessage = 'valid';
        let mandatoryFields = ['task', 'timeEstimateInMinutes'];

        if (mandatoryFields.indexOf(fieldName) > -1 && !response) {
            validationMessage = 'This field is mandatory.'; 
        };
        
        return validationMessage;
    }

	handleValueChanges(fieldName, response) {

		let {taskObj, validationErrors} = this.state;

		let stringResponseFields = ['task'];
		let dropdownFields = ['timeEstimateInMinutes'];

        if (stringResponseFields.indexOf(fieldName) > -1) {
            response = response.target.value ? response.target.value : '';
        }
		else if (dropdownFields.indexOf(fieldName) > -1) {
            response = response.value ? response.value : null;
        };

		let validationMessage = this.validateUserInput(fieldName, response);

		taskObj[fieldName] = response;
		validationErrors[fieldName] = validationMessage == 'valid' ? '' : validationMessage;

		this.setState({
            taskObj: taskObj,
			validationErrors: validationErrors
        });
	}

	renderUpdateModal() {

        let {taskObj, validationErrors} = this.state;

        return(
			<div>
				<Modal size={'lg'} isOpen={this.state.showUpdateModal} toggle={this.toggleUpdateModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleUpdateModal.bind(this)}>Task</ModalHeader>
					<ModalBody>

                        <div className="form-group">
							<label>Task <span className="text-danger">*</span></label>
							<div><input type="text" onChange={this.handleValueChanges.bind(this, 'task')} className="form-control" value={taskObj['task']} /></div>
                            <div className="text-danger small mt-1">{validationErrors['task']}</div>
						</div>

                        <TimeEstimateSelect
							handleTimeEstimateSelect={this.handleValueChanges.bind(this)}
							value={taskObj['timeEstimateInMinutes']}
							error={validationErrors['timeEstimateInMinutes']}
							isRequired={true}
						/>

                        <button type="button" className="btn btn-primary btn-sm" onClick={this.updateTasksInParent.bind(this)}>Done</button>

						<div className="mt-3"><small>* The above button will store your changes locally upon clicking it. You need to click "Save Template" button from the homepage to update the database.</small></div>
						
					</ModalBody>
				</Modal>
			</div>
		);
    }

	handleTaskDelete() {

		let {tasks, itemToRemove} = this.state;

        if (!itemToRemove) return;

        let itemIndex = _.findIndex(tasks, {id: itemToRemove});
        tasks.splice(itemIndex, 1);

        this.setState({
            tasks: tasks
        },
        () => {
			if (this.props.onChange) {
				this.props.onChange(tasks);
			};
            this.toggleDeleteModal();
        });
    }

	toggleDeleteModal(recordId) {

		if (this.state.showDeleteModal) {
			this.setState({
				showDeleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteModal: true,
				itemToRemove: recordId,
			});
		}
	}

	renderDeleteModal() {

		var taskObj = _.find(this.state.tasks, { id: this.state.itemToRemove });
		var taskName = taskObj ? taskObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.showDeleteModal} toggle={this.toggleDeleteModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Task</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the task <span className="text-warning">{taskName}?</span></p>
						<div><small>* This action is temporary. You need to click "Save Template" button from the homepage to update the database.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sm" color="danger" title="Delete" onClick={this.handleTaskDelete.bind(this)}>Delete</Button>{' '}
						<Button className="btn-sm" color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		let {tasks} = this.state;

		return(
			<div className="form-group">
				<div className="row">
					<div className="col-6">
						<label>Tasks</label>
					</div>
					<div className="col-6 text-right">
						<button type="button" className="btn btn-primary btn-sm" onClick={this.toggleUpdateModal.bind(this, {})}>
							<i className="fa fa-plus" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				{tasks && tasks.length > 0 ?
					<div>
						<table className="table-curved mt-3">
							<thead>
								<tr className="row row-section-container">
									<th className="text-center col-1">#</th>
									<th className="text-left col-7">Task</th>
									<th className="text-center col-2">Time Estimate</th>
									<th className="text-center col-2" colSpan={2}>Actions</th>
								</tr>
							</thead>
							<tbody>
								{tasks.map((taskObj, index) => {
									return(
										<tr key={'tasks_' + index} className="row row-section-container">
											<td className="text-center col-1">{index + 1}</td>
											<td className="text-left col-7">{taskObj.task}</td>
											<td className="text-center col-2">{taskObj.timeEstimateInMinutes ? Util.convertTimeToReadableText(taskObj.timeEstimateInMinutes) : ''}</td>
											<td className="text-center col-1"><button className="table-action-button" title="Edit" onClick={this.toggleUpdateModal.bind(this, taskObj)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
											<td className="text-center col-1"><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, taskObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				: 
					<div className="small text-info mt-1">No Tasks added.</div>
				}
				{this.renderUpdateModal()}
				{this.renderDeleteModal()}
			</div>
		);
	}
}

Tasks.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	Tasks
);