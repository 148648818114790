import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getUserOutcomes } from "../../actions/outcomeActions";
import { getUserKeyActivities } from "../../actions/keyActivityActions";
import { setLoading } from "../../actions/loadingActions.js";
import OutcomeSelect from "../outcomes/OutcomeSelect";
import KeyActivitiesList from "./KeyActivitiesList";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class KeyActivityDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			outcome: null,
		}
	}

	componentDidMount() {
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for selecting outcome
	handleOutcomeSelect(name, value) {

		this.setState({
			[name]: value,
		});

	}

	getUserKeyActivities() {

		API_Services.httpGET(UrlConstants.URLS.getUserKeyActivities, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				this.props.getUserKeyActivities(response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	renderKeyActivityList() {

		const { keyactivities } = this.props.keyactivities;

		if (keyactivities && keyactivities.length > 0) {
			return (
				<KeyActivitiesList
					keyactivities={this.props.keyactivities}
				/>
			);
		} else {
			return (<p className="text-center">Please add Key Activity using above button</p>)
		}
	}

	render() {

		const { outcome } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/key-activities/">Key Activities</a>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="inner-container">
							<div className="row">
								<div className="col-sm-4">
									<p>Please select Project to access key activities</p>
									<OutcomeSelect
										handleOutcomeSelect={this.handleOutcomeSelect.bind(this)}
									/>
								</div>
							</div>
							{
								outcome
									?
									<div>
										<div className="text-center">
											<Link to="/key-activities/add" className="btn btn-primary mb-2" title="Add KeyActivity">Add Key Activity <i className="fa fa-plus" aria-hidden="true"></i></Link>
										</div>
										<div className="inner-container-table ">
											{this.renderKeyActivityList()}
										</div>
									</div>
									: <div />
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

KeyActivityDashboard.propTypes = {
	getUserOutcomes: PropTypes.func.isRequired,
	getUserKeyActivities: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	keyactivities: PropTypes.object.isRequired,
	outcomes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
	keyactivities: state.keyactivities,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomes, getUserKeyActivities })(
	KeyActivityDashboard
);
