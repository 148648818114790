import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getCurrentSprintForTheUser, getCurrentSprintQuestions } from "../../actions/currentSprintActions";
import AddCurrentSprint from "./AddCurrentSprint";
import EditCurrentSprint from "./EditCurrentSprint";
import { Link } from "react-router-dom";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class CurrentSprintDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			apiResponse: {
				className: "",
				message: "",
			},
		}
	}

	componentDidMount() {
		this.getCurrentSprintQuestions();
		this.getCurrentSprintForTheUser();
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getCurrentSprintQuestions() {

		API_Services.httpGET(UrlConstants.URLS.getCurrentSprintQuestions, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getCurrentSprintQuestions(response);
				} else {
					this.setApiResponse(response.data.message, false);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	getCurrentSprintForTheUser() {

		API_Services.httpGET(UrlConstants.URLS.getCurrentSprintForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getCurrentSprintForTheUser(response);
				} else {
					this.setApiResponse(response.data.message, true);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	userCurrentSprint() {

		const { currentsprintresponse } = this.props.currentsprint;

		var currentsprintquestions = [];

		if (currentsprintresponse.responses && currentsprintresponse.responses.length > 0) {

			currentsprintresponse.responses.forEach((value, index) => {

				currentsprintquestions.push(value.prompt);
			});

			return (
				<div>
					<EditCurrentSprint
						currentsprintquestions={currentsprintquestions}
						currentsprintresponse={currentsprintresponse} />
				</div>
			);
		}
	}

	addUserCurrentSprint() {

		const { currentsprintquestions } = this.props.currentsprint;

		return (
			<div>
				<AddCurrentSprint
					currentsprintquestions={currentsprintquestions} />
			</div>
		);
	}

	render() {

		const { currentsprintresponse, currentsprintquestions } = this.props.currentsprint;
		const { apiResponse } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/current-sprint/">Current Sprint</a>
				</div>
				<div className="section-container">
					<div className="form-container">
						{
							(currentsprintquestions && currentsprintquestions.length > 0) ?
								(_.isEmpty(currentsprintresponse) ? this.addUserCurrentSprint() : this.userCurrentSprint())
								: null
						}

						{
							!_.isEmpty(apiResponse)
								?
								<p className={apiResponse.className}><span>{apiResponse.message}</span></p>
								: null
						}
					</div>
				</div>
			</div>
		);
	}
}

CurrentSprintDashboard.propTypes = {
	getCurrentSprintForTheUser: PropTypes.func.isRequired,
	getCurrentSprintQuestions: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	currentsprint: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	currentsprint: state.currentsprint,
});

export default connect(mapStateToProps, { setLoading, getCurrentSprintForTheUser, getCurrentSprintQuestions })(
	CurrentSprintDashboard
);
