import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import { Button } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import ReactSelect from 'react-select';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class DailyReview extends Component {

	constructor(props) {
		super(props);
		this.state = {
			apiResponse: {
				className: "",
				message: "",
			},
			form: {},
			responseSets: [],
			showPrompts: false,
			buttonDisabled: false,
			today: moment().startOf('day'),
			date: moment().startOf('day'),
			clientTimeZone: momentTimezone.tz.guess(),
		};
	}

	componentDidMount() {
		this.getDailyReview();
		this.setStickyToolBar();
	}

	setStickyToolBar() {

		const header = document.getElementById("daily-review-floating-tool-bar");
		// const sticky = header.offsetTop;

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 65) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	getDailyReview() {

		this.props.setLoading(true);

		this.setState({
			responseSets: null,
			showPrompts: false,
		});

		let postObj = {
			date: this.state.date,
			clientTimeZone: this.state.clientTimeZone
		}

		API_Services.httpPOST(UrlConstants.URLS.getDailyReviewForTheDate, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					let data = response.data.data;
					let stats = response.data.data.stats;

					// Append response if empty
					let responseSetsCopy = _.cloneDeep(data.responseSets);

					// For each sections
					responseSetsCopy.forEach((eachSection, sectionIndex) => {

						// For each prompt
						eachSection.promptAndResponses.map((eachPrompt, promptIndex) => {

							// For Html editor
							if(eachPrompt.fieldType == "html_editor") {
								
								eachPrompt['response'] = eachPrompt.response ? EditorState.createWithContent(convertFromRaw(eachPrompt.response)) : EditorState.createEmpty();
							}
							else {

								eachPrompt['response'] = eachPrompt.response ? eachPrompt.response : null;
							}

							// update prompt with response
							responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex] = eachPrompt;
						})
					});

					this.setState({
						responseSets: responseSetsCopy,
						showPrompts: true,
						stats: stats,
					});
	
				} 
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	checkValidation() {

		let hasValidationError = false;

		let responseSetsCopy = _.cloneDeep(this.state.responseSets);

		// For each sections
		responseSetsCopy.forEach((eachSection, sectionIndex) => {

			// For each prompt
			eachSection.promptAndResponses.map((eachPrompt, promptIndex) => {

				if(eachPrompt.isMandatory) {

					// Validate Html editor
					if(eachPrompt.fieldType == "html_editor") {

						if(!eachPrompt['response'] || !eachPrompt['response'].getCurrentContent().hasText()) {

							hasValidationError = true;

							eachPrompt['error'] = "This field is mandatory";
						}
					}

					// Validate text field
					if(eachPrompt.fieldType == "text_field") {

						// If empty
						if(!eachPrompt['response'] || eachPrompt['response'].trim().length == 0) {

							hasValidationError = true;

							eachPrompt['error'] = "This field is mandatory";
						}

						// For number validate max and min
						if(eachPrompt.responseType == "number") {
							
							if( (parseInt(eachPrompt['response']) > parseInt(eachPrompt.max)) || (parseInt(eachPrompt['response']) < parseInt(eachPrompt.min)) ) {

								hasValidationError = true;

								eachPrompt['error'] = "Value should be min: " + eachPrompt.min + " and max: " + eachPrompt.max;
							}
						}
					}

					// Validate single select
					if(eachPrompt.fieldType == "single_select") {

						
					}
					
				}
			})
		});

		return {hasValidationError, responseSetsCopy};
	}

	handleSaveChanges() {

		this.props.setLoading(true);

		let validationResponse= this.checkValidation();
		if(validationResponse.hasValidationError) {

			this.setState({
				responseSets: validationResponse.responseSetsCopy,
			});
			this.props.setLoading(false);
			return;
		}

		// Html editor will be raw content so convert them to save to DB
		let responseSetsCopy = _.cloneDeep(this.state.responseSets);

		let cumulativeStats = {
			...this.state.stats,
			totalPrompts: 0,
			totalRespondedPrompts: 0,
		};

		// For each sections
		responseSetsCopy.forEach((eachSection, sectionIndex) => {

			let stats = {
				...eachSection.stats,
				totalPrompts: 0,
				totalRespondedPrompts: 0,
			};

			// For each prompt
			eachSection.promptAndResponses.map((eachPrompt, promptIndex) => {

				// Compute total number of prompts
				stats["totalPrompts"] = stats["totalPrompts"] + 1;
				cumulativeStats["totalPrompts"] = cumulativeStats["totalPrompts"] + 1;

				// For Html editor
				if(eachPrompt.fieldType == "html_editor") {

					let content = null;

					if(eachPrompt['response'] && eachPrompt['response'].getCurrentContent().hasText()) {
						content = convertToRaw(eachPrompt['response'].getCurrentContent());
						
						// Compute total number of responded prompts
						stats["totalRespondedPrompts"] = stats["totalRespondedPrompts"] + 1;
						cumulativeStats["totalRespondedPrompts"] = cumulativeStats["totalRespondedPrompts"] + 1;
					}

					eachPrompt['response'] = content;
					
					// update response
					responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex] = eachPrompt;
				}
				else {

					if(eachPrompt['response']) {

						if(eachPrompt.fieldType == "text_field") {
							
							if(eachPrompt['response'].trim().length > 0) {

								// Compute total number of responded prompts
								stats["totalRespondedPrompts"] = stats["totalRespondedPrompts"] + 1;
								cumulativeStats["totalRespondedPrompts"] = cumulativeStats["totalRespondedPrompts"] + 1;
							}
						} else {

								// Compute total number of responded prompts
								stats["totalRespondedPrompts"] = stats["totalRespondedPrompts"] + 1;
								cumulativeStats["totalRespondedPrompts"] = cumulativeStats["totalRespondedPrompts"] + 1;
						}
					}
				}
			});

			eachSection['stats'] = stats;
		});

		let postObj = {
			date: this.state.date,
			clientTimeZone: this.state.clientTimeZone,
			responseSets: responseSetsCopy,
			stats: cumulativeStats,
		}

		API_Services.httpPOST(UrlConstants.URLS.updateOrCreateDailyReview, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {
					this.setApiResponse("Record have been saved successfully!", false);
				}
				else {
					this.setApiResponse(response.data.message, false);
				};
			}
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
		
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	handlerToNavigate = (e, navigateAction) => {

		e.preventDefault()
		let { date } = this.state;

		if(navigateAction === 'PREV'){
			date = moment(date).subtract(1, 'day');
		}
		else if(navigateAction === 'NEXT') {
			date = moment(date).add(1, 'day');
		}
		else if(navigateAction === 'TODAY') {
			date = moment().startOf('day');
		}

		this.setState({ date },() => {
			this.getDailyReview(); // fetch Daily Review for the specific date
		})
	}

	renderHeaderToolBar() {

		const { apiResponse, date, today } = this.state;
		let isCurrentDay = moment(today.format('L')).isSame(date.format('L'));

		return (
			<div id={"daily-review-floating-tool-bar"}>
				<div className={"d-flex flex-wrap justify-content-between"}>
					<div className="text-left">
						<Button onClick={(e) =>{this.handlerToNavigate(e, 'TODAY')}} className={"todays-btn btn-sm"} disabled={isCurrentDay} style={{fontSize:14}}>Today </Button>
					</div>
					<div className="d-flex align-items-center justify-content-center">
							<div className="d-flex align-items-center">
								<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'PREV')}} className="btn btn-primary btn-sm border-right-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}><i className="fa fa-angle-left" aria-hidden="true"></i></button>
								<span className="week-label" > {date.format('L')} </span>
								<button type="button" onClick={(e) =>{this.handlerToNavigate(e, 'NEXT')}} className="btn btn-primary btn-sm border-left-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}><i className="fa fa-angle-right" aria-hidden="true"></i></button>
							</div>
					</div>
					<div className="text-right mt-2 mt-sm-0">
						<Button onClick={this.handleSaveChanges.bind(this)} className="btn btn-primary btn-sm" disabled={this.state.buttonDisabled}>Save Changes</Button>
					</div>
				</div>
				{ !_.isEmpty(apiResponse) ?<p className={"text-center bg-white " + apiResponse.className}><span>{apiResponse.message}</span></p>: null }
			</div>
		);
	}

	// Handler for checkbox field change event
	handleCheckboxField(sectionIndex, promptIndex, event) {

		const { checked } = event.target;
		const { responseSets } = this.state;

		let responseSetsCopy = [...responseSets];

		responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = checked;

		this.setState({
			responseSets: responseSetsCopy,
		});

	}

	// Render checkbox field
	renderCheckBox(eachPrompt, sectionIndex, promptIndex) {

		let { responseSets } = this.state;

		return (
			<div className="form-group">
				<div className="custom-control custom-switch">
					<input type="checkbox" 
						onChange={this.handleCheckboxField.bind(this, sectionIndex, promptIndex)} 
						className="custom-control-input" name="" 
						value={eachPrompt.response} 
						id={eachPrompt.uniqueID} 
						checked={eachPrompt.response ? true : false}
						disabled={eachPrompt.isLocked} 
					/>
					<label className="custom-control-label" htmlFor={eachPrompt.uniqueID}>{eachPrompt.prompt} {eachPrompt.isMandatory ? <span className="text-danger">*</span> : null}</label>
					{eachPrompt.isLocked ?
						<i className="fa fa-lock color-primary ml-2"></i>
					: null}
					<p className="text-danger">{eachPrompt.error &&
						<span className="err">{eachPrompt.error}</span>}</p>
				</div>
			</div>
		)
	}

	// Render html editor field
	renderHtmlEditor(eachPrompt, sectionIndex, promptIndex) {

		let { responseSets } = this.state;

		return (
			<div className="form-group">
				<p className="open-ended-prompt-container">{eachPrompt.prompt} {eachPrompt.isMandatory ? <span className="text-danger">*</span> : null}</p>
				<div className="open-ended-prompt-response-container">
					<Editor
						toolbar={{
							options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
						}}
						editorState={ responseSets[sectionIndex]['promptAndResponses'][promptIndex]['response'] }
						placeholder="Enter your response here..."
						//toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName"
						editorClassName="editorClassName bg-white"
						onEditorStateChange={(editorState) => {

							let responseSetsCopy = [...responseSets];

							responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = editorState;
							responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['error'] = "";

							this.setState({
								responseSets: responseSetsCopy,
							})
						}}
					/>
				</div>
				<p className="text-danger">{eachPrompt.error &&
						<span className="err">{eachPrompt.error}</span>}</p>
			</div>
		)
	}

	// Handler for numeric field change event
	handleNumericField(sectionIndex, promptIndex, event) {

		const { value } = event.target;
		const { responseSets } = this.state;

		let responseSetsCopy = [...responseSets];

		if(Util.validateNumber(value)) {

			responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = value;
			responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['error'] = "";
		} else {

			responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = "";

			responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['error'] = "Should be valid number";	
		}

		this.setState({
			responseSets: responseSetsCopy,
		});
	}

	// Render number field - Its a text field with number validation
	renderNumericField(eachPrompt, sectionIndex, promptIndex) {

		let { responseSets } = this.state;

		return (
			<div className="form-group">
				<p htmlFor={eachPrompt.uniqueID}>{eachPrompt.prompt} {eachPrompt.isMandatory ? <span className="text-danger">*</span> : null}</p>
				<input type="text" onChange={this.handleNumericField.bind(this, sectionIndex, promptIndex)} className="form-control" id={eachPrompt.uniqueID} value={responseSets[sectionIndex]['promptAndResponses'][promptIndex]['response']} />
				<p className="text-danger">{eachPrompt.error &&
						<span className="err">{eachPrompt.error}</span>}</p>
			</div>
		)
	}

	// Handler for text field change event
	handleTextField(sectionIndex, promptIndex, event) {

		const { value } = event.target;
		const { responseSets } = this.state;

		let responseSetsCopy = [...responseSets];

		responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = value;
		responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['error'] = "";

		this.setState({
			responseSets: responseSetsCopy,
		});
	}

	// Handler for drop down onChange event
	handleSingleSelectOnChange(sectionIndex, promptIndex, selectedItem) {

		const { value } = selectedItem || { value : '' };
		const { responseSets } = this.state;

		let responseSetsCopy = [...responseSets];

		responseSetsCopy[sectionIndex]['promptAndResponses'][promptIndex]['response'] = value;

		this.setState({
			responseSets: responseSetsCopy,
		});

	}

	// Render drop down with options
	renderSingleSelect(eachPrompt, sectionIndex, promptIndex) {

		return (
			<div className="form-group">
				<p for={eachPrompt.uniqueID}>{eachPrompt.prompt} {eachPrompt.isMandatory ? <span className="text-danger">*</span> : null}</p>
				<div>
					<ReactSelect
						name={eachPrompt.uniqueID}
						styles={SharedStyles.selectBoxStyles}
						closeOnSelect={false}
						options={eachPrompt.options}
						value={eachPrompt.options && eachPrompt.options.find(option => eachPrompt.response && option.value === eachPrompt.response)}
						removeSelected={true}
						autosize={true}
						isClearable
						onSelectResetsInput={true}
						onChange={this.handleSingleSelectOnChange.bind(this, sectionIndex, promptIndex)}
						placeholder="Select ..."
					/>
				</div>
				<p className="text-danger">{eachPrompt.error &&
						<span className="err">{eachPrompt.error}</span>}</p>
			</div>
		)
	}

	// Render text field
	renderTextField(eachPrompt, sectionIndex, promptIndex) {

		let { responseSets } = this.state;

		return (
			<div className="form-group">
				<p htmlFor={eachPrompt.uniqueID}>{eachPrompt.prompt} {eachPrompt.isMandatory ? <span className="text-danger">*</span> : null}</p>
				<input type="text" onChange={this.handleTextField.bind(this, sectionIndex, promptIndex)} className="form-control" id={eachPrompt.uniqueID} value={responseSets[sectionIndex]['promptAndResponses'][promptIndex]['response']} />
				<p className="text-danger">{eachPrompt.error &&
						<span className="err">{eachPrompt.error}</span>}</p>
			</div>
		)
	}

	renderPromptByFieldType(eachPrompt, sectionIndex, promptIndex) {

		if(eachPrompt.fieldType == "check_box") {
			return(
				<div>
					{this.renderCheckBox(eachPrompt, sectionIndex, promptIndex)}
				</div>
			)
		}

		if(eachPrompt.fieldType == "html_editor") {
			return(
				<div>
					{this.renderHtmlEditor(eachPrompt, sectionIndex, promptIndex)}
				</div>
			)
		}

		if(eachPrompt.fieldType == "text_field" && eachPrompt.responseType && eachPrompt.responseType == "number") {
			return(
				<div>
					{this.renderNumericField(eachPrompt, sectionIndex, promptIndex)}
				</div>
			)
		}

		if(eachPrompt.fieldType == "text_field") {
			return(
				<div>
					{this.renderTextField(eachPrompt, sectionIndex, promptIndex)}
				</div>
			)
		}

		if(eachPrompt.fieldType === "single_select" || eachPrompt.fieldType === "numeric_single_select") {
			return(
				<div>
					{this.renderSingleSelect(eachPrompt, sectionIndex, promptIndex)}
				</div>
			)
		}

	}

	renderDynamicPrompts(promptAndResponses, sectionIndex) {

		return promptAndResponses.map((eachPrompt, index) => {
			
			return(
				<div className="row" key={index}>
					<div className="col-md-12">
						{this.renderPromptByFieldType(eachPrompt, sectionIndex, index)}
					</div>
				</div>
			);
		});
	}

	renderDynamicSections() {

		const { responseSets } = this.state;
		
		return responseSets.map((eachSection, index) => {
			
			return(
				<div key={index}>
					<h5 className="mb-3">{eachSection.title || ""}</h5>
					<div className="card custom-card">
						<div className="card-body">
							{this.renderDynamicPrompts(eachSection.promptAndResponses, index)}
						</div>
					</div>
					<br></br>
				</div>
			);
		});
	}

	render() {

		const { showPrompts } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<NavLink to="/daily-review">Daily Review</NavLink>&nbsp;
				</div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">

								{this.renderHeaderToolBar()}
								<br></br>
								{showPrompts ? this.renderDynamicSections() : null}
								
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

DailyReview.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	DailyReview
);

const styles = {

	activitiesRow: {
		color: '#737373', 
		fontFamily: 'Pangram-Light'
	}
}