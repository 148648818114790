import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';
import NoteForm from "./NoteForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AddNote extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	// Handler for Note submit
	handleNoteSubmit(data) {

		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.addNewNote, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					history.push('/notes');
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		const { formApiResponse } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/notes/">Notes</a> &nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<a href="#">Add</a>
				</div>
				<div className="section-container">
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">
								<NoteForm onSubmit={this.handleNoteSubmit.bind(this)} />
								{
									!_.isEmpty(formApiResponse)
										?
										<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AddNote.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	AddNote
);
