import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';
import KeyActivityForm from "./KeyActivityForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AddKeyActivity extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
			outcomeID: null,
			error: null,
		}
	}

	componentDidMount() {

		if (history.location.search) {
			const query = new URLSearchParams(history.location.search);
			const outcomeID = query.get('outcomeID');
			if (outcomeID) {
				this.setState({
					outcomeID: outcomeID,
				})
			} else {
				this.setState({
					error: "Please create your project before creating key activities.",
				})
			}
		} else {
			this.setState({
				error: "Please create your project before creating key activities.",
			})
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	// Handler for KeyActivity submit
	handleKeyActivitySubmit(data) {

		var postObj = data;

		postObj.outcomeID = this.state.outcomeID;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.addNewKeyActivity, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Record have been saved successfully!", false);
					history.push('/projects/edit/' + this.state.outcomeID);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	renderForm() {

		const { outcomeID, formApiResponse } = this.state;

		return (
			<div className="row">
				<div className="col-md-12">
					<div className="form-container">
						<KeyActivityForm
							onSubmit={this.handleKeyActivitySubmit.bind(this)}
							outcomeID={outcomeID} />
						{
							!_.isEmpty(formApiResponse)
								?
								<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
								: null
						}
					</div>
				</div>
			</div>
		);
	}

	render() {

		const { outcomeID, error } = this.state;

		const editOutcomePage = "/projects/edit/" + outcomeID;
		const addKeyActivityPage = "/key-activities/add?outcomeID=" + outcomeID;

		return (
			<div className="page-content container child-container">
				{!error
					? <div className="bread-crumb">
						<NavLink to={editOutcomePage}>Project</NavLink>&nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<NavLink to={editOutcomePage}>Key Activities</NavLink>&nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<NavLink to={addKeyActivityPage}>Add</NavLink>&nbsp;
					</div>
					: null}
				<div className="section-container">
					{error
						? <p className="text-center py-4 text-warning">{error}</p>
						: this.renderForm()}
				</div>
			</div>
		);
	}
}

AddKeyActivity.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	AddKeyActivity
);
