import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class RadioSelector extends Component {
	
    handleValueChange(value) {

        if (this.props.onChange) {
            this.props.onChange(this.props.radioGroupName, value);
        }
        else {
            console.log('Radio group value updated for group - ' + this.props.radioGroupName + ' - as - ' + value);
        };
    }

    render() {
        
        let {options, orientation, radioGroupName, selectedValue} = this.props;

        return(
            <div className="custom-radio-group" style={{flexDirection: orientation == 'vertical' ? 'column' : 'row'}}>
                {options.map((item, index) => {
                    return(
                        <div className="radio-item-container" key={'radio_selector_for_' + radioGroupName + '_ind_' + index}>
                            <input 
                                type="radio" 
                                name={radioGroupName}
                                checked={selectedValue == item.value} 
                                value={item.value}
                                onChange={() => this.handleValueChange(item.value)} />
                            <label className="ml-2" style={{fontSize: 14}}>{item.label}</label>
                        </div>
                    );
                })}
            </div>
        );
    }
};

RadioSelector.propTypes = {
	
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(RadioSelector);