import {
	RESET_FOCUS_AREA,
	GET_FOCUS_AREA,
	GET_FOCUS_AREAS,
	GET_FOCUS_AREA_QUESTIONS,
	DELETE_FOCUS_AREA,
} from "../actions/types.js";

const initialState = {
	focusarearesponses: [],
	focusarearesponse: {},
	focusareaquestions: [],
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_FOCUS_AREA:
			return {
				...initialState,
			}

		case GET_FOCUS_AREA:
			return {
				...state,
				focusarearesponse: action.payload,
			};

		case GET_FOCUS_AREAS:
			return {
				...state,
				focusarearesponses: action.payload,
			};

		case GET_FOCUS_AREA_QUESTIONS:
			return {
				...state,
				focusareaquestions: action.payload,
			};

		case DELETE_FOCUS_AREA:
			return {
				...state,
				focusarearesponses: state.focusarearesponses.filter(focusarea => focusarea.id !== action.payload)
			};

		default:
			return state;
	}
}
