import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import { history } from '../../utils/history';
import Util from '../../utils/Util';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AvailableTimeTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				dailyStartTime: null,
				dailyEndTime: null,
				slotDuration: null,
			},
			formErrors: {
				dailyStartTime: null,
				dailyEndTime: null,
				slotDuration: null,
			},
			availableStartTimeOptions: [],
			availableEndTimeOptions: [],
			slotDurationOptions: [{
				label: "15 Mins",
				value: 15,
			},
			{
				label: "30 Mins",
				value: 30,
			},
			{
				label: "1 Hour",
				value: 60,
			}],
			buttonDisabled: false,
			showTable: false,
			defaultRowEntries: [1, 1, 1, 1, 1, 1, 1],
			timeSlotColumnData: [],
			entries: {},
			dayWiseTotalAvailableTimeInMinutes: [0, 0, 0, 0, 0, 0, 0],
			totalAvailableTimeInMinutes: 0,
			apiResponse: {
				className: "",
				message: "",
			},
			recordId: '',
			ATENewUpdateModal: false,
		};

	}

	resetTableAndRenderFreshData(selectAll) {

		let defaultRowEntries = [1, 1, 1, 1, 1, 1, 1];

		if (!selectAll) {
			defaultRowEntries = [0, 0, 0, 0, 0, 0, 0];
		}

		this.setState({
			timeSlotColumnData: [],
			entries: {},
			dayWiseTotalAvailableTimeInMinutes: [0, 0, 0, 0, 0, 0, 0],
			totalAvailableTimeInMinutes: 0,
			defaultRowEntries: defaultRowEntries,
		}, () => {
			this.generateTableData();
		});
	}

	componentDidMount() {

		let availableStartTimeOptions = this.generateTimesForDropdown({
			label: "00:00AM",
			value: 0,
		}, "startTime");

		this.setState({
			availableStartTimeOptions: availableStartTimeOptions,
		}, () => {
			this.getAvailableTimeEstimatesForTheUser();
		})
	}

	getAvailableTimeEstimatesForTheUser() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getAvailableTimeEstimatesForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {

					const { availableStartTimeOptions, slotDurationOptions } = this.state;
					let data = response.data.data;

					let dailyStartTimeInMinutes = _.find(availableStartTimeOptions, { 'value': data.dailyStartTimeInMinutes });

					let dailyEndTimeInMinutes = _.find(availableStartTimeOptions, { 'value': data.dailyEndTimeInMinutes });

					let slotDurationInMinutes = _.find(slotDurationOptions, { 'value': data.slotDurationInMinutes });

					let availableEndTimeOptions = this.generateTimesForDropdown(dailyStartTimeInMinutes, "endTime");

					this.setState({
						form: {
							dailyStartTime: dailyStartTimeInMinutes,
							dailyEndTime: dailyEndTimeInMinutes,
							slotDuration: slotDurationInMinutes,
						},
						availableEndTimeOptions: availableEndTimeOptions,
						buttonDisabled: false,
						timeSlotColumnData: [],
						entries: data.entries,
						dayWiseTotalAvailableTimeInMinutes: data.dayWiseTotalAvailableTimeInMinutes,
						totalAvailableTimeInMinutes: data.totalAvailableTimeInMinutes,
						recordId: data.id,
					}, () => {
						this.generateTableData();
					});
				} else {
					this.setApiResponse(response.data.message, false);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	generateTimesForDropdown(dailyStartTime, timeType) {

		if (dailyStartTime) {

			let availableTimeOptions = [];

			var x = 15; //minutes interval
			var times = []; // time array
			var startTime = timeType == "startTime" ? dailyStartTime.value : dailyStartTime.value + 15; // start time
			var ap = ['AM', 'PM']; // AM-PM

			//loop to increment the time and push results in array
			for (var i = 0; startTime < 24 * 60; i++) {
				var hh = Math.floor(startTime / 60); // getting hours of day in 0-24 format
				var mm = (startTime % 60); // getting minutes of the hour in 0-55 format

				let time = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

				// Fix for 12:00pm instead of 00:00pm
				if(hh === 12) {
					time = "12" + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)];
				}

				availableTimeOptions.push({
					label: time,
					value: startTime,
				});

				startTime = startTime + x;
			}

			return availableTimeOptions;
		}

	}

	// Handler for select field change event
	handleSelectField(name, value) {

		let { form, formErrors, availableStartTimeOptions, availableEndTimeOptions, slotDurationOptions, showTable } = this.state;

		let dailyStartTime = form.dailyStartTime;
		let dailyEndTime = form.dailyEndTime;

		// Generate end time based on start time
		if (name == "dailyStartTime") {
			availableEndTimeOptions = this.generateTimesForDropdown(value, "endTime");
			dailyEndTime = null;
		}

		var formObj = {};
		formObj = {
			...form,
			dailyStartTime: dailyStartTime,
			dailyEndTime: dailyEndTime,
			[name]: value,
		};

		// Change slot times based on start time and end time duration
		// value.value = endtime
		if (name == "dailyEndTime") {

			let timeDiff = value.value - dailyStartTime.value;

			if (timeDiff == 15 || timeDiff == 45) {
				slotDurationOptions = [
					{
						label: "15 Mins",
						value: 15,
					},
				];
			} else if (timeDiff == 30) {
				slotDurationOptions = [
					{
						label: "15 Mins",
						value: 15,
					},
					{
						label: "30 Mins",
						value: 30,
					},
				];
			} else {
				slotDurationOptions = [
					{
						label: "15 Mins",
						value: 15,
					},
					{
						label: "30 Mins",
						value: 30,
					},
					{
						label: "1 Hour",
						value: 60,
					}
				];
			}

			formObj['slotDuration'] = null;
		}

		this.setState({
			form: formObj,
			availableStartTimeOptions: availableStartTimeOptions,
			availableEndTimeOptions: availableEndTimeOptions,
			slotDurationOptions: slotDurationOptions,
			showTable: false,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "dailyStartTime":
				if (!value) errorMsg = "Please select Daily Start Time";
				break;

			case "dailyEndTime":
				if (!value) errorMsg = "Please select Daily End Time";
				break;

			case "slotDuration":
				if (!value) errorMsg = "Please select Slot Duration";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	/* 
		Prepare the timeSlotColumnData = ["09:00AM - 10:00AM", "10:00AM - 11.00AM", ...]
	*/

	prepareTimeSlots() {

		const { form } = this.state;

		var slotDuration = form.slotDuration.value; //minutes interval
		var timeSlotColumnData = []; // time array
		var startTime = form.dailyStartTime.value; // start time
		var endTime = form.dailyEndTime.value;
		var ap = ['AM', 'PM']; // AM-PM

		//loop to increment the time and push results in array
		for (var i = 0; startTime < endTime; i++) {
			
			// For every entry, compute interval start time and interval end time.
			var intervalStartTime = startTime;
			var intervalEndTime = startTime + slotDuration;

			if (intervalEndTime > endTime) {
				break; 
			};

			// 1. Interval start time

			var inetervalStartTimeHH = Math.floor(intervalStartTime / 60); // getting hours of day in 0-24 format
			var inetervalStartTimeMM = (intervalStartTime % 60); // getting minutes of the hour in 0-55 format
			
			var inervalStartTimeText = ("0" + (inetervalStartTimeHH % 12)).slice(-2) + ':' + ("0" + inetervalStartTimeMM).slice(-2) + ' ' + ap[Math.floor(inetervalStartTimeHH / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

			// Fix for 12:00pm instead of 00:00pm
			if (inetervalStartTimeHH === 12) {
				inervalStartTimeText = "12" + ':' + ("0" + inetervalStartTimeMM).slice(-2) + ap[Math.floor(inetervalStartTimeHH / 12)];
			};

			// 2. Interval end time
			
			var inetervalEndTimeHH = Math.floor(intervalEndTime / 60); // getting hours of day in 0-24 format
			var inetervalEndTimeMM = (intervalEndTime % 60); // getting minutes of the hour in 0-55 format
			
			var inervalEndTimeText = ("0" + (inetervalEndTimeHH % 12)).slice(-2) + ':' + ("0" + inetervalEndTimeMM).slice(-2) + ' ' + ap[Math.floor(inetervalEndTimeHH / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]

			// Fix for 12:00pm instead of 00:00pm
			if (inetervalEndTimeHH === 12) {
				inervalEndTimeText = "12" + ':' + ("0" + inetervalEndTimeMM).slice(-2) + ap[Math.floor(inetervalEndTimeHH / 12)];
			};

			timeSlotColumnData.push(inervalStartTimeText + ' - ' + inervalEndTimeText);
			startTime = intervalEndTime;
		};

		return {
			timeSlotColumnData: timeSlotColumnData,
		}
	}

	generateTableData() {

		const preparedTimeSlots = this.prepareTimeSlots();

		let timeSlotColumnData = preparedTimeSlots.timeSlotColumnData;

		// Prepare the entries or get from DB
		/* {
			"09:00AM - 10:00AM": [0, 1, 1, 0, 0, 0, 0],
			"10:00AM - 11:00AM": [0, 1, 1, 0, 0, 0, 0],
			"11:00AM - 12:00PM": [0, 1, 1, 0, 0, 0, 0],
			"12:00PM - 01:00PM": [0, 1, 1, 0, 0, 0, 0],
			....
		} */
		const { entries } = this.state;

		let entriesCopy = {...this.state.entries };

		if (_.isEmpty(entriesCopy)) {

			timeSlotColumnData.forEach((eachTime) => {
				entriesCopy[eachTime] = this.state.defaultRowEntries;
			});
		}

		/* 
		timeSlotColumnData: ["09:00AM - 10:00AM", "10:00AM - 11:00AM", "11:00AM - 12:00AM"]
		entries: {
			"09:00AM - 10:00AM": [0, 1, 1, 0, 0, 0, 0],
			"10:00AM - 11:00AM": [0, 1, 1, 0, 0, 0, 0],
			"11:00AM - 12:00PM": [0, 1, 1, 0, 0, 0, 0],
			"12:00PM - 01:00PM": [0, 1, 1, 0, 0, 0, 0],
			....
		} */
		this.setState({
			entries: entriesCopy,
			timeSlotColumnData: timeSlotColumnData,
			showTable: true,
			ATENewUpdateModal: false,
		}, () => {
			this.updateTotal();
		});
	}

	toggleATENewUpdateModal() {

		if (this.state.ATENewUpdateModal) {
			this.setState({
				ATENewUpdateModal: false,
			});
		} else {
			this.setState({
				ATENewUpdateModal: true,
			});
		}
	}

	renderATENewUpdateModal() {

		return (
			<div>
				<Modal isOpen={this.state.ATENewUpdateModal} toggle={this.toggleATENewUpdateModal.bind(this)}>
					<ModalHeader toggle={this.toggleATENewUpdateModal.bind(this)}>Generate New Data</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to re-generate the data with new inputs?</p>
						<div><small><i>* Your old Available Time data will be lost.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Proceed" onClick={this.resetTableAndRenderFreshData.bind(this, true)}>Proceed</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleATENewUpdateModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	// Handler for Time Slot submit
	handleTimeSlotSubmit(e) {
		e.preventDefault();

		const { form, formErrors, recordId } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			if (recordId) {
				this.toggleATENewUpdateModal();
			} else {
				this.generateTableData();
			}
		}
	}

	timeSlotForm() {

		const { form, formErrors, availableStartTimeOptions, availableEndTimeOptions, slotDurationOptions } = this.state;
		// const { availabletime } = this.props.availabletime;

		return (
			<div>
				<form onSubmit={(e) => e.preventDefault()}>

					<div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<label>Daily Start Time <span className="text-danger">*</span></label>
								<ReactSelect
									name="dailyStartTime"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={availableStartTimeOptions}
									value={form['dailyStartTime']}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleSelectField.bind(this, 'dailyStartTime')}
									placeholder="Start Time"
								/>
								<p className="text-danger">{formErrors['dailyStartTime'] &&
									<span className="err">{formErrors['dailyStartTime']}</span>}</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label>Daily End Time <span className="text-danger">*</span></label>
								<ReactSelect
									name="dailyEndTime"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={availableEndTimeOptions}
									value={form['dailyEndTime']}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleSelectField.bind(this, 'dailyEndTime')}
									placeholder="End Time"
								/>
								<p className="text-danger">{formErrors['dailyEndTime'] &&
									<span className="err">{formErrors['dailyEndTime']}</span>}</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label>Slot Duration <span className="text-danger">*</span></label>
								<ReactSelect
									name="slotDuration"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={slotDurationOptions}
									value={form['slotDuration']}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleSelectField.bind(this, 'slotDuration')}
									placeholder="Slot Duration"
								/>
								<p className="text-danger">{formErrors['slotDuration'] &&
									<span className="err">{formErrors['slotDuration']}</span>}</p>
							</div>
						</div>
					</div>

					<button type="button" onClick={this.handleTimeSlotSubmit.bind(this)} className="btn btn-primary mb-4">Submit</button>
				</form>
				{this.renderATENewUpdateModal()}
			</div>
		);
	}

	renderTimeSlotCell(cellData) {

		return (
			<td style={{ width: '23%' }}>{cellData}</td>
		);
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	updateDataAPI() {

		const { form, entries, dayWiseTotalAvailableTimeInMinutes, totalAvailableTimeInMinutes, recordId } = this.state;

		var postObj = {
			dailyStartTimeInMinutes: form.dailyStartTime.value,
			dailyEndTimeInMinutes: form.dailyEndTime.value,
			slotDurationInMinutes: form.slotDuration.value,
			entries: entries,
			dayWiseTotalAvailableTimeInMinutes: dayWiseTotalAvailableTimeInMinutes,
			totalAvailableTimeInMinutes: totalAvailableTimeInMinutes,
		}

		let url = "";
		if (recordId) {

			url = UrlConstants.URLS.editAvailableTimeEstimate;
			postObj.recordID = recordId;
		} else {

			url = UrlConstants.URLS.addAvailableTimeEstimate;
		}

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(url, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setApiResponse("Record have been saved successfully!", false);
					this.setState({
						recordId: response.data.data.id,
					})
				} else {
					this.setApiResponse(response.data.message, false);
				}
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	/*
		entries: {
			"09:00AM - 10:00AM": [0, 1, 1, 0, 0, 0, 0],
			"10:00AM - 11:00AM": [0, 1, 1, 0, 0, 0, 0],
			"11:00AM - 12:00PM": [0, 1, 1, 0, 0, 0, 0],
			"12:00PM - 01:00PM": [0, 1, 1, 0, 0, 0, 0],
			....
		},
		dayWiseTotalAvailableTimeInMinutes: [120, 120, 120, 120, 300, 0, 0],
	*/
	updateTotal() {

		const { entries, form, dayWiseTotalAvailableTimeInMinutes } = this.state;

		// Typical usage (don't forget to compare props):
		if (form.slotDuration && entries) {

			/**
			 * Add each day data.
			 * Loop entries, we will get each row data
			 * Loop row we will get each cell
			 * If cell is checked then get the cell index
			 * We will have total of each column(day) in dayWiseTotalAvailableTimeInMinutes
			 * Cell index will be day index. So add the already exisiting value with the slotDuration to compute total hours
			 */
			let dayWiseTotalAvailableTimeInMinutes = [0, 0, 0, 0, 0, 0, 0];
			let timeKeys = _.keys(entries);

			timeKeys.map((eachTime) => { 

				let arrValue = entries[eachTime];

				arrValue.forEach((value, index) => {
					
					if (value) {
						dayWiseTotalAvailableTimeInMinutes[index] = dayWiseTotalAvailableTimeInMinutes[index] + form.slotDuration.value;
					}
				});
			});

			let totalAvailableTimeInMinutes = dayWiseTotalAvailableTimeInMinutes.reduce((a, b) => a + b, 0)

			this.setState({
				dayWiseTotalAvailableTimeInMinutes: dayWiseTotalAvailableTimeInMinutes,
				totalAvailableTimeInMinutes: totalAvailableTimeInMinutes,
			});
		}
	}

	renderCheckMarkCell(eachTime) {

		let entriesCopy = { ...this.state.entries };

		let arrData = entriesCopy[eachTime];

		if (entriesCopy && arrData) {

			return arrData.map((eachCheckMark, index) => {

				var img = eachCheckMark ? "/images/available.svg" : "/images/not-available.svg";

				return (
					<td key={index} style={{ width: '11%' }}>
						<div style={{ "position": "relative" }}>
							<img src={img} width="15" />
							<input type="checkbox"
								className="customCheckATE cursor-pointer"
								style={{ backgroundColor: "#fafafa" }}
								onChange={e => {

									let arrDataCopy = [...arrData];

									arrDataCopy[index] = eachCheckMark ? 0 : 1;

									entriesCopy[eachTime] = arrDataCopy;

									this.setState({
										entries: entriesCopy,
									}, () => {
										this.updateTotal();
									})
								}}
								value={eachCheckMark}
								checked={eachCheckMark ? 'checked' : null}
							/>
						</div>
					</td>
				);
			});
		}
	}

	renderRows() {

		const { entries } = this.state;
		let timeKeys = _.keys(entries);

		return timeKeys.map((eachTime) => { 

			return (
				<tr key={eachTime}>
					{this.renderTimeSlotCell(eachTime)}
					{this.renderCheckMarkCell(eachTime)}
				</tr>
			);
		})
	}

	/**
	 * selectAll = true [selectall] or false[deselectall]
	 * day = index of days 0 = monday, 1 = tuesday
	 * entriesCopy: {
			"09:00AM - 10:00AM": [0, 1, 1, 0, 0, 0, 0],
			"10:00AM - 11:00AM": [0, 1, 1, 0, 0, 0, 0],
			"11:00AM - 12:00PM": [0, 1, 1, 0, 0, 0, 0],
			"12:00PM - 01:00PM": [0, 1, 1, 0, 0, 0, 0],
			....
		},
	 * entriesCopy[eachTime] = 540 which returns row 1 data [0, 1, 1, 0, 0, 0, 0],
	 * if selectAll is true change the index value to 1 from above data to check. entriesCopy[eachTime][day] = 1;
	 * if selectAll is false change the index value to 0 from above data to uncheck.
	*/
	resetTableColumn(selectAll, day) {

		let entriesCopy = { ...this.state.entries };

		if (!_.isEmpty(entriesCopy)) {

			_.forOwn(entriesCopy, (arrValue, eachTime) => {

				if (selectAll) {
					entriesCopy[eachTime][day] = 1;
				}

				if (!selectAll) {
					entriesCopy[eachTime][day] = 0;
				}
			});

			this.setState({
				entries: entriesCopy,
			}, () => {
				this.generateTableData();
			});
		}
	}

	handleSelectOrDeselectAllClick(type, day) {

		if (type === "selectall") {
			this.resetTableColumn(true, day);
		}

		if (type === "deselectall") {
			this.resetTableColumn(false, day);
		}
	}

	handleSaveChanges() {
		this.updateDataAPI();
	}

	renderSelectDeselectButtons(dayIndex) {
		
		return (
			<div className="d-flex justify-content-around mt-2">
				<div className="checkbox-wrapper cursor-pointer" onClick={this.handleSelectOrDeselectAllClick.bind(this, "selectall", dayIndex)}><img src={'/images/available.svg'} width="12" /></div>
				<div className="checkbox-wrapper cursor-pointer" onClick={this.handleSelectOrDeselectAllClick.bind(this, "deselectall", dayIndex)}><img src={'/images/not-available.svg'} width="12" /></div>
			</div>
		);
	}

	renderTable() {

		return (
			<div>
				<div className="text-center">
					<button type="button" onClick={this.handleSaveChanges.bind(this)} className="btn btn-primary mb-4">Save Changes</button>
				</div>
				<div className="table-responsive">
					<table className="table table-curved vertical-center">
						<thead>
							<tr>
								<th className="text-center vertical-center" style={{ width: '23%' }}>TIME SLOT</th>
								<th className="text-center" style={{ width: '11%' }}>
									Mon {this.renderSelectDeselectButtons(0)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Tue {this.renderSelectDeselectButtons(1)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Wed {this.renderSelectDeselectButtons(2)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Thu {this.renderSelectDeselectButtons(3)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Fri {this.renderSelectDeselectButtons(4)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Sat {this.renderSelectDeselectButtons(5)}
								</th>
								<th className="text-center" style={{ width: '11%' }}>
									Sun {this.renderSelectDeselectButtons(6)}
								</th>
							</tr>
						</thead>
						<tbody className="text-center">
							{this.renderRows()}
							{this.renderTotalRow()}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	renderTotalRow() {

		const { dayWiseTotalAvailableTimeInMinutes, totalAvailableTimeInMinutes } = this.state;

		return (
			<tr>
				<td style={{ width: '23%' }}>TOTAL: {Util.convertTimeToReadableText(totalAvailableTimeInMinutes)}</td>
				{dayWiseTotalAvailableTimeInMinutes.map((eachDayTotal, index) => {
					return (
						<td key={index} style={{ width: '11%' }}>{Util.convertTimeToReadableText(eachDayTotal)}</td>
					)
				})}
			</tr>
		)
	}

	render() {

		const { showTable, apiResponse } = this.state;

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<NavLink to="/available-time">Available Time</NavLink>&nbsp;
				</div>
				<div className="section-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="row">
						<div className="col-md-12">
							<div className="form-container">

								{this.timeSlotForm()}

								{showTable ? this.renderTable() : null}

								{
									!_.isEmpty(apiResponse)
										?
										<p className={apiResponse.className}><span>{apiResponse.message}</span></p>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}

AvailableTimeTable.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	AvailableTimeTable
);
