import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AddFocusAreaForm from "./AddFocusAreaForm";
import { getFocusAreaQuestions } from "../../actions/focusAreaActions.js";

class AddFocusArea extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.getFocusAreaQuestions();
	}

	render() {

		const { focusareaquestions } = this.props.focusareas;

		if (focusareaquestions && focusareaquestions.length > 0) {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="bread-crumb">
						<a href="/focus-areas/">Focus Areas</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Add</a>
					</div>
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<AddFocusAreaForm
										focusareaquestions={focusareaquestions}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<p>No Questions found</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

	}
}

AddFocusArea.propTypes = {
	getFocusAreaQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	focusareas: state.focusareas,
});

export default connect(mapStateToProps, { getFocusAreaQuestions })(
	AddFocusArea
);
