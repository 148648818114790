import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import moment from 'moment';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class IIADashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			iiaReports: null,
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.props.setLoading(true);

		let postObj = {};

		API_Services.httpPOST(UrlConstants.URLS.getInnerInsightsReport, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					this.setState({
						iiaReports: response.data.data,
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	renderTableRow(iiaReports, eachIIAReport, index) {
		
		if (!eachIIAReport.results) return null;

		let tableRowArray = [];
		let reportUrl = "/assessments/inner-insight-report/" + eachIIAReport['results']['assessmentId'];
		
		// let balancedStates = _.filter(eachIIAReport.results.emostateResponses, (item) => item.energyState == 'Balanced');
		// let hyperInertStates = _.filter(eachIIAReport.results.emostateResponses, (item) => item.energyState == 'Hyper' || item.energyState == 'Inert');

		// let sortedValues = _.sortBy(eachIIAReport.results.valueScores, 'valueScore');
		// let bottom3Values = sortedValues.slice(0, 3);
		// let top3Values = sortedValues.reverse().slice(0, 3);

		let balancedEnergyState = _.find(eachIIAReport.results.energyProfile, (item) => item.state == 'Balanced');
		let inertEnergyState = _.find(eachIIAReport.results.energyProfile, (item) => item.state == 'Inert');
		let hyperEnergyState = _.find(eachIIAReport.results.energyProfile, (item) => item.state == 'Hyper');
		
		tableRowArray.push(
			<tr key={'row_scope_1_' + index}>
				<td className="text-center">{iiaReports.length - index}</td>
				<td className="text-center" style={{minWidth: 150}}>
					<Link to={reportUrl} style={SharedStyles.titleLink}>{moment(eachIIAReport['results']['updatedAt']).format('MMM Do YYYY')}</Link>
				</td>
				<td className="text-center">{eachIIAReport.recommendedTracks.coreEnablerGroupAvg}</td>
				<td className="text-center">{eachIIAReport.recommendedTracks.innerCompassGroupAvg}</td>
				<td className="text-center">{eachIIAReport.recommendedTracks.goodLivingGroupAvg}</td>
				<td className="text-center">{eachIIAReport.recommendedTracks.beingExtraordinaryGroupAvg}</td>
				<td className="text-center">{inertEnergyState.average}</td>
				<td className="text-center">{balancedEnergyState.average}</td>
				<td className="text-center">{hyperEnergyState.average}</td>
				{/* <td rowSpan="3" className="text-center">{hyperEnergyState.average}</td>
				<td scope="row" class="text-center">{top3Values[0] ? top3Values[0].valueName : ''}</td>
				<td scope="row" class="text-center">{top3Values[0] ? top3Values[0].valueScore : ''}</td>
				<td scope="row" class="text-center">{bottom3Values[0] ? bottom3Values[0].valueName : ''}</td>
				<td scope="row" class="text-center">{bottom3Values[0] ? bottom3Values[0].valueScore : ''}</td>
				<td scope="row" class="text-center">{balancedStates[0] ? balancedStates[0].stateName : ''}</td>
				<td scope="row" class="text-center">{balancedStates[0] ? balancedStates[0].average : ''}</td>
				<td scope="row" class="text-center">{hyperInertStates[0] ? hyperInertStates[0].stateName : ''}</td>
				<td scope="row" class="text-center">{hyperInertStates[0] ? hyperInertStates[0].average : ''}</td> */}
				<td className="text-center" style={{minWidth: 150}}>
					<Link to={reportUrl} className="btn btn-primary btn-xs">View Report</Link>
				</td>
			</tr>
		);

		// tableRowArray.push(
		// 	<tr key={'row_scope_2_' + index}>
		// 		<td scope="row" class="text-center">{top3Values[1] ? top3Values[1].valueName : ''}</td>
		// 		<td scope="row" class="text-center">{top3Values[1] ? top3Values[1].valueScore : ''}</td>
		// 		<td scope="row" class="text-center">{bottom3Values[1] ? bottom3Values[1].valueName : ''}</td>
		// 		<td scope="row" class="text-center">{bottom3Values[1] ? bottom3Values[1].valueScore : ''}</td>
		// 		<td scope="row" class="text-center">{balancedStates[1] ? balancedStates[1].stateName : ''}</td>
		// 		<td scope="row" class="text-center">{balancedStates[1] ? balancedStates[1].average : ''}</td>
		// 		<td scope="row" class="text-center">{hyperInertStates[1] ? hyperInertStates[1].stateName : ''}</td>
		// 		<td scope="row" class="text-center">{hyperInertStates[1] ? hyperInertStates[1].average : ''}</td>
		// 	</tr>
		// );

		// tableRowArray.push(
		// 	<tr key={'row_scope_3_' + index}>
		// 		<td scope="row" class="text-center">{top3Values[2] ? top3Values[2].valueName : ''}</td>
		// 		<td scope="row" class="text-center">{top3Values[2] ? top3Values[2].valueScore : ''}</td>
		// 		<td scope="row" class="text-center">{bottom3Values[2] ? bottom3Values[2].valueName : ''}</td>
		// 		<td scope="row" class="text-center">{bottom3Values[2] ? bottom3Values[2].valueScore : ''}</td>
		// 		<td scope="row" class="text-center">{balancedStates[2] ? balancedStates[2].stateName : ''}</td>
		// 		<td scope="row" class="text-center">{balancedStates[2] ? balancedStates[2].average : ''}</td>
		// 		<td scope="row" class="text-center">{hyperInertStates[2] ? hyperInertStates[2].stateName : ''}</td>
		// 		<td scope="row" class="text-center">{hyperInertStates[2] ? hyperInertStates[2].average : ''}</td>
		// 	</tr>
		// );

		return tableRowArray;
	}

	renderInnerInsights() {

		let { iiaReports } = this.state;

		if (iiaReports == null || iiaReports.length == 0) {
			return (
				<div className="text-info small">No assessments records found.</div>
			);
		};

		return (
			<div style={{overflowX: 'auto'}}>
				<table class="table-curved mb-2">
					<col />
					<col />
					<colgroup span="4"></colgroup>
					<colgroup span="3"></colgroup>
					{/* <colgroup span="2"></colgroup>
					<colgroup span="2"></colgroup> */}
					<col />
					<thead>
						<tr>
							<th rowSpan="2" style={SharedStyles.tableHeading}>#</th>
							<th rowSpan="2" style={SharedStyles.tableHeading}>Date</th>
							<th colSpan="4" style={SharedStyles.tableHeading}>Thrive Meter (out of 10)</th>
							<th colSpan="3" style={SharedStyles.tableHeading}>Energy State (out of 10)</th>
							{/* <th colSpan="2" style={SharedStyles.tableHeading}>Top 3 Values</th>
							<th colSpan="2" style={SharedStyles.tableHeading}>Bottom 3 Values</th>
							<th colSpan="2" style={SharedStyles.tableHeading}>Top Balanced Emostates</th>
							<th colSpan="2" style={SharedStyles.tableHeading}>Inert/Hyper Emostates</th> */}
							<th rowSpan="2" style={SharedStyles.tableHeading}>Action</th>
						</tr>
						<tr>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Core Enablers</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Inner Compass</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Good Living</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Being Extraordinary</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Inert</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Balanced</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Hyper</th>
							{/* <th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Value</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Score</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Value</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Score</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Emostate</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Average</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Emostate</th>
							<th scope="col" style={{...SharedStyles.tableHeading, ...SharedStyles.tableSubHeading}}>Average</th> */}
						</tr>
					</thead>
					<tbody>
						{
							iiaReports.map((eachIIAReport, index) => {
								return this.renderTableRow(iiaReports, eachIIAReport, index)
							})
						}
					</tbody>
				</table>
			</div>
		);
	}

	render() {

		return (
			<div>
				<div className="text-right">
					<Link to="/assessments/inner-insight-assessment" className="btn btn-primary btn-sm mb-4"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp; Inner Insight</Link>	
				</div> {/* Clicking on this should load IIA.js */}
				{this.renderInnerInsights()}
			</div>
		);
	}
}

IIADashboard.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	IIADashboard
);