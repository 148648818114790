import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';

import { removeNote } from "../../actions/noteActions";
var _ = require('lodash');
var moment = require('moment');

class NotesList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			notes: [],
		};
	}

	componentDidMount() {

		const { notes } = this.props.notes;

		this.setState({
			notes: notes
		});
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing note
	handleNoteDelete() {

		if (this.state.itemToRemove) {
			this.props.removeNote(this.state.itemToRemove);
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		}
	}

	// Renders table lists
	renderTable() {

		const { notes } = this.props.notes;
		// const { notes } = this.state;

		return (
			<div className="App">
				<header className="App-header table-responsive">
					<table className="table-curved">
						<thead>
							<tr className="row row-section-container">
								<th className="text-center col-2 col-sm-1">#</th>
								<th className="text-left col-6 col-sm-7">Title</th>
								<th className="text-center col-sm-2 d-none d-sm-block">Updated At.</th>
								<th className="text-center col-4 col-sm-2" colSpan={2}>Actions</th>
							</tr>
						</thead>
						<tbody>
							{notes.map(({ id, title, updatedAt }, index) => {
								return (
									<tr key={id} className="row row-section-container">
										<td className="text-center col-2 col-sm-1">{index + 1}</td>
										<td className="text-left col-6 col-sm-7">{title}</td>
										<td className="text-center col-sm-2 d-none d-sm-block">{moment(updatedAt).format('MMM Do YYYY')}</td>
										<td className="text-center col-2 col-sm-1"><Link to={`/notes/edit/${id}`} title="Edit" className="table-action-button p-0"><i className="fa fa-pencil color-primary" aria-hidden="true"></i></Link></td>
										<td className="text-center col-2 col-sm-1"><button className="table-action-button p-0" title="Delete" onClick={this.toggleDeleteModal.bind(this, id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</header>
			</div>
		);
	}

	renderNoteDeleteModal() {

		var faObj = _.find(this.state.notes, { id: this.state.itemToRemove });
		var faTitle = faObj ? faObj.noteTitle : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Note</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the note <span className="text-warning">{faTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleNoteDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					{this.renderTable()}
				</div>
				{this.renderNoteDeleteModal()}
			</div>
		);
	}
}

NotesList.propTypes = {
	removeNote: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	notes: state.notes,
});

export default connect(mapStateToProps, { removeNote })(
	NotesList
);
