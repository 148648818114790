import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import AddUTask from "./AddUTask";
import EditUTask from "./EditUTask";
import ViewUTask from "../tasks/ViewTask";
import TaskStatus from "../tasks/TaskStatus";
import TaskFlags from "../tasks/TaskFlags";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class UTasksList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
			tasks: [],
			unfilteredTasks: [],
			itemToEdit: null,
			itemToView: null,
			viewTaskModal: false,
			addTaskModal: false,
			editTaskModal: false,
			itemToUpdate: null
		};
	}

	componentDidMount() {
		this.setState({
			tasks: this.props.utasks,
			unfilteredTasks: this.props.unfilteredTasks
		});
	}

	componentWillReceiveProps(props) {
		this.setState({
			tasks: props.utasks,
			unfilteredTasks: props.unfilteredTasks
		});
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	toggleAddTaskModal(rowId) {

		if (this.state.itemToUpdate) {
			this.setState({
				addTaskModal: false,
				itemPosition: null,
				relativePosition: null,
				itemToUpdate: null,
			});
		} else {
			this.setState({
				addTaskModal: true,
				itemToUpdate: rowId,
			});
		}
	}

	addTaskInList(itemPosition, data) {

		setTimeout(() => {
			this.toggleAddTaskModal();
			this.props.onTaskAddition(itemPosition, data);
		}, 500);
	}

	// Show or hide view modal
	toggleViewTaskModal(rowId) {

		if (this.state.itemToView) {
			this.setState({
				viewTaskModal: false,
				itemToView: null,
			});
		} 
		else {
			this.setState({
				viewTaskModal: true,
				itemToView: rowId,
			});
		}
	}

	// Show or hide edit modal
	toggleEditTaskModal(rowId) {

		if (this.state.itemToEdit) {
			this.setState({
				editTaskModal: false,
				itemToEdit: null,
			});
		} 
		else {
			this.setState({
				editTaskModal: true,
				itemToEdit: rowId,
			});
		}
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	// Handler for removing task
	// Add API here so we can handle state even better
	handleTaskDelete() {

		var { tasks, itemToRemove } = this.state;

		if (itemToRemove) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove,
			};

			API_Services.httpPOST(UrlConstants.URLS.deleteTask, postObj, (err, response) => {

				if (err) {
					
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} 
					else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} 
				else if (response.data) {

					if (response.data.status) {

						this.setState({
							deleteModal: false,
							itemToRemove: null,
						}, 
						() => {
							this.props.setLoading(false);
							if (response.data.data && response.data.data.deletedRecord) {
								this.props.onTaskDelete(response.data.data.deletedRecord); 
							};
						});
					} 
					else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} 
				else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}
	}

	handleOnDragEnd(result) {
		
		if (!result.source || !result.destination || !result.draggableId) return;
		if (result.source.index == result.destination.index) return; // No change happened.

		this.props.setLoading(true);

		let postObj = {
			changeItem: result.draggableId,
			sourceIndex: result.source.index,
			destinationIndex: result.destination.index,
			filterSequence: _.map(this.state.tasks, 'id')
		};

		API_Services.httpPOST(UrlConstants.URLS.updateUTasksRanks, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setApiResponse("Rank updated successfully!", false);
					this.props.onRankUpdate(response.data.data.itemSequence);
				} 
				else {
					this.setApiResponse(response.data.message, true);
				};
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	// Renders tasks in table
	renderTasksTable() {

		const { tasks, unfilteredTasks, apiResponse } = this.state;

		if (tasks.length == 0) {
			return (
				<div className="small text-info">No Tasks found for the applied filter.</div>
			);
		};

		return (
			<div>
				{!_.isEmpty(apiResponse)?
					<p className="text-center"><span className={apiResponse.className}>{apiResponse.message}</span></p>
				: null}
				<div className="App" style={{ marginTop: 20 }}>
					<header className="App-header table-responsive">
						<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
							<Droppable droppableId="tasks">
								{(provided) => (
									<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
										<thead>
											<tr>
												<th className="text-center" style={{ width: 50 }}></th>
												{/* <th className="text-center" style={{ width: 50 }}>#</th> */}
												<th className="text-center" style={{ width: 50 }}><i className="fa fa-check" aria-hidden="true"></i></th>
												<th className="text-left" style={{ width: 500, minWidth: 300 }}>Task</th>
												<th className="text-left" style={{ width: 150, minWidth: 150 }}>Time Estimate</th>
												<th className="text-center" style={{ width: 75 }}>Project</th>
												<th className="text-center" style={{ width: 75 }}>Flag</th>
												<th className="text-center" style={{ width: 100 }} colSpan={4}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{tasks.map((eachtask, index) => {
												//let universalRank = _.findIndex(unfilteredTasks, {id: eachtask.id}) + 1;
												return (
													<Draggable key={eachtask.id} draggableId={eachtask.id} index={index}>
														{(provided, snapshot) => (
															<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
																<td className="text-center" style={{ width: 50 }}><i className="fa fa-bars color-primary" aria-hidden="true"></i></td>
																{/* <td className="text-center" style={{ width: 50 }}>{universalRank}</td> */}
																<td className="text-center" style={{ width: 50 }}>
																	<TaskStatus 
																		taskObj={eachtask} 
																		setApiResponse={this.setApiResponse.bind(this)}
																		onTaskUpdate={this.props.onTaskUpdate.bind(this)} />
																</td>
																<td className="text-left" style={{ width: 500, minWidth: 300 }}><span className="cursor-pointer" style={SharedStyles.titleLink} onClick={this.toggleViewTaskModal.bind(this, eachtask.id)}>{eachtask.task}</span></td>
																<td className="text-left" style={{ width: 150, minWidth: 150 }}>{Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)}</td>
																<td className="text-center" style={{ width: 75 }}><a href={'/projects/edit/' + eachtask.outcomeID} target='_blank' style={SharedStyles.titleLink}>{eachtask.outcomeAbbreviation}</a></td>
																<td className="text-center" style={{ width: 50 }}>
																	<TaskFlags 
																		taskObj={eachtask} 
																		setApiResponse={this.setApiResponse.bind(this)}
																		onTaskUpdate={this.props.onTaskUpdate.bind(this)} />
																</td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="Add task above or below" onClick={this.toggleAddTaskModal.bind(this, eachtask.id)}><i className="fa fa-plus color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="View" onClick={this.toggleViewTaskModal.bind(this, eachtask.id)}><i className="fa fa-eye color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleEditTaskModal.bind(this, eachtask.id)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
																<td className="text-center" style={{ width: 25 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteModal.bind(this, eachtask.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
															</tr>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
										</tbody>
									</table>
								)}
							</Droppable>
						</DragDropContext>						
						{this.renderAddTaskModal()}
						{this.renderViewTaskInModal()}
						{this.renderEditTaskInModal()}
						{this.renderTaskDeleteModal()}
					</header>
				</div>
			</div>
		);
	}

	renderAddTaskModal() {

		var tObj = _.find(this.state.unfilteredTasks, { id: this.state.itemToUpdate });
		var tObjIndex = _.findIndex(this.state.unfilteredTasks, { id: this.state.itemToUpdate });
		var tTitle = tObj ? tObj.task : '';

		return (
			this.state.itemPosition ?
				<Modal size={'lg'} isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Add Task</ModalHeader>
					<ModalBody>
						<AddUTask
							itemPosition={this.state.itemPosition}
							refItem={this.state.itemToUpdate}
							relativePosition={this.state.relativePosition}
							reloadData={this.props.reloadData}
							addTaskInList={this.addTaskInList.bind(this)} />
					</ModalBody>
				</Modal>
			:
				<Modal isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Choose Position</ModalHeader>
					<ModalBody>
						<div className="d-flex justify-content-around align-items-center">
							<button className="btn btn-primary btn-sm" title="Above" onClick={() => this.setState({itemPosition: tObjIndex+1, relativePosition: 'above'})}>Above This Task</button>
							<span className="ml-2 mr-2">[OR]</span>
							<button className="btn btn-primary btn-sm" title="Below" onClick={() => this.setState({itemPosition: tObjIndex+2, relativePosition: 'below'})}>Below This Task</button>
						</div>
					</ModalBody>
				</Modal>	
		)
	}

	// Render view modal
	renderViewTaskInModal() {

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.viewTaskModal} toggle={this.toggleViewTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleViewTaskModal.bind(this)}>
						View Task
					</ModalHeader>
					<ModalBody>
						<ViewUTask taskID={this.state.itemToView} />
						<div className="section-container form-container pl-0">
							<button
								className="btn btn-primary btn-sm" 
								onClick={() => { this.toggleEditTaskModal(this.state.itemToView); this.toggleViewTaskModal()}}>
									Edit Task
							</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}

	// Render edit modal
	renderEditTaskInModal() {

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.editTaskModal} toggle={this.toggleEditTaskModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleEditTaskModal.bind(this)}>Edit Task </ModalHeader>
					<ModalBody>
						<EditUTask
							onClose={this.toggleEditTaskModal.bind(this)}
							reloadData={this.props.reloadData}
							taskID={this.state.itemToEdit}
							onTaskUpdate={this.props.onTaskUpdate.bind(this)} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	renderTaskDeleteModal() {

		var tObj = _.find(this.state.unfilteredTasks, { id: this.state.itemToRemove });
		var tTitle = tObj ? tObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Task</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the task <span className="text-warning">{tTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handleTaskDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					{this.renderTasksTable()}
				</div>
			</div>
		);
	}
}

UTasksList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	UTasksList
);
