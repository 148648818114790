import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import TimeScope from "../form-inputs/TimeScope";

import { setLoading } from "../../actions/loadingActions.js";
import SharedStyles from '../../assets/styles/SharedStyles';

import Util from '../../utils/Util';
var _ = require('lodash');

class ProductivityOutcomeMetricForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				title: "",
				description: "",
				dataType: {
					label: "Numerical",
					value: "numerical",
				},
				targetScope: "",
				targetValue: "",
			},
			formErrors: {
				title: null,
				description: null,
				dataType: null,
				targetScope: null,
				targetValue: null,
			},
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.pOutcomeMetricObj)) {

			var formObj = {...this.state.form};

			formObj = {
				...formObj,
				...this.props.pOutcomeMetricObj,
			};

			this.setState({
				form: formObj,
				isEdit: true,
			});
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if(name == 'targetValue') {
			if(value && !Util.validateNumber(value)) {
				var formErrorsObj = { ...formErrors, [name]: "Please enter Numeric Value" };
				this.setState({ formErrors: formErrorsObj });
				return;
			}
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};

		if(!value) {
			formObj = {
				...form,
				[name]: "",
			};
			this.setState({
				form: formObj,
			})
			return;
		}
		
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		const { isEdit, form } = this.state;
		var errorMsg = null;

		switch (name) {

			case "title":
				if (!value) errorMsg = "Please enter Title";
				break;

			case "dataType":
				if (!value) errorMsg = "Please select Data Type";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for OutcomeMetric submit
	handleOutcomeMetricSubmit(e) {
		e.preventDefault();

		const { form, formErrors, isEdit } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;
		} 
		else {

			if(form['targetScope'] && !form['targetValue']) {
				var formErrorsObj = { ...formErrors, "targetValue": "Please enter Target Value" };
				this.setState({ formErrors: formErrorsObj });
				return;
			}
			
			if(form['targetValue'] && !form['targetScope']) {
				var formErrorsObj = { ...formErrors, "targetScope": "Please select Target Scope" };
				this.setState({ formErrors: formErrorsObj });
				return;
			}

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {

					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} 
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			if(isEdit) {
				this.props.updateEditedPOutcomeMetricInList(formCopy);
			} else {
				formCopy['id'] = Util.generateObjectId();
				this.props.addPOutcomeMetricInList(formCopy);
			}
		}
	}

	render() {

		const { form, formErrors, isEdit } = this.state;

		/**
		 * Scale is not for v1
		 * {
				label: "Scale",
				value: "scale",
			}
		 */
		var dataTypeOptions = [
			{
				label: "Numerical",
				value: "numerical",
			},
		];

		return (
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Title <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
								<p className="text-danger">{formErrors['title'] &&
									<span className="err">{formErrors['title']}</span>}</p>
							</div>
						</div>
						<div className="form-group">
							<label>Description</label>
							<div>
								<TextareaAutosize
									onChange={this.handleInputField.bind(this)}
									className="form-control"
									minRows={1} 
									maxRows={10}
									name="description"
									value={form['description']} />
								<p className="text-danger">{formErrors['description'] &&
									<span className="err">{formErrors['description']}</span>}</p>
							</div>
						</div>
						{/* Since we're only supporting numeral datatype for now, we do NOT need to have the user select that from the drop down when creating/editing a metric. Let'd default to that without bringing it up to the user. */}
						{/* <div className="form-group">
							<label>Data Type <span className="text-danger">*</span></label>
							<div>
								<ReactSelect
									name="dataType"
									styles={SharedStyles.selectBoxStyles}
									closeOnSelect={false}
									options={dataTypeOptions}
									value={dataTypeOptions.find(option => option.value === form['dataType'].value)}
									removeSelected={true}
									autosize={true}
									clearable={true}
									onSelectResetsInput={true}
									onChange={this.handleSelectField.bind(this, 'dataType')}
									isDisabled={this.props.disabled}
								/>
								<p className="text-danger">{formErrors['dataType'] &&
									<span className="err">{formErrors['dataType']}</span>}</p>
							</div>
						</div> */}
						<div className="form-group">
							<label>Target</label>
							<div className="row">
								<div className="col-lg-6">
									<TimeScope
										handleSelectField={(label, selectedTimeScope) => this.handleSelectField('targetScope', selectedTimeScope)}
										value={form.targetScope}
										error={formErrors.targetScope}
										placeholder={'Target Scope'}
										isRequired={false}
										source="outcomes"
									/>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" placeholder="Target Value" name="targetValue" value={form['targetValue']} style={{"minHeight": "42px"}} />
										<p className="text-danger">{formErrors['targetValue'] &&
										<span className="err">{formErrors['targetValue']}</span>}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<button type="button" onClick={this.handleOutcomeMetricSubmit.bind(this)} className="btn btn-primary btn-sm mt-n4 mb-3">Save & Close</button>
						{/* {
							!_.isEmpty(this.props.formApiResponse)
								?
								<p className={this.props.formApiResponse.className}><span>{this.props.formApiResponse.message}</span></p>
								: null
						} */}
						<div className="small text-info">* All metrics are numerical.</div>
						{/* <div className="mt-3"><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
					</div>
				</div>
			</form>
		);

	}
}

ProductivityOutcomeMetricForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityOutcomeMetricForm
);
