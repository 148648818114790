import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";

import API_Services from '../../utils/API_Services';

// import FocusAreaSelect from "../focus-areas/FocusAreaSelect";

var UrlConstants = require('../../utils/UrlConstants');
var _ = require('lodash');

class ProjectForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				projectTitle: "",
				abbreviation: "",
				includeInWeeklyPlan: true,
				// focusArea: null,
				outcome: "",
				successDefinition: "",
				creationMaintenance: "",
				bufferInPercentage: 10,
				rolesAndResponsibilities: [],
				collaborators: [],
				keyActivities: [],
				notes: null,
				metricsAndTargets: [],
			},
			formErrors: {
				projectTitle: null,
				abbreviation: null,
				includeInWeeklyPlan: null,
				// focusArea: null,
				outcome: null,
			},
			buttonDisabled: false,
			isEdit: false,
		};
	}

	resetForm() {

		var formObj = {
			projectTitle: "",
			abbreviation: "",
			includeInWeeklyPlan: true,
			// focusArea: null,
			outcome: "",
			successDefinition: "",
			creationMaintenance: "",
			bufferInPercentage: 10,
			rolesAndResponsibilities: [],
			collaborators: [],
			notes: {},
		};

		var formErrors = {
			projectTitle: null,
			abbreviation: null,
			includeInWeeklyPlan: null,
			// focusArea: null,
			outcome: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
		});
	}

	componentDidMount() {

		const { form } = this.state;

		const { productivityOutcome, projectObj } = this.props;

		let formObj = {...projectObj};

		formObj['outcome'] = productivityOutcome['outcome'];

		if (!Util.isEmpty(projectObj)) {

			this.setState({ 
				form: formObj,
				isEdit: true,
			});
		} else {

			this.setState({ 
				form: formObj,
			});
		}
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;
		const { form, formErrors } = this.state;

		value = (!value || value == "false") ? true : false;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "projectTitle":
				if (!value) errorMsg = "Please enter Project Title";
				break;

			case "abbreviation":
				if (!value) errorMsg = "Please enter Project Abbreviation";
				break;

			// case "focusArea":
			// 	if (!value) errorMsg = "Please select a Focus Area";
			// 	break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for submit
	handleSubmit = (e, nextSection) => {

		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		}
		else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {
				if (Array.isArray(value)) {
				}
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			formCopy['notes'] = null;

			this.createOrEditProject(formCopy, nextSection || "");
		}
	}

	createOrEditProject(data, nextSection) {

		if (nextSection) {

		}

		if (this.state.isEdit) {
			this.props.updateEditedProjectInList(data, nextSection);
		} else {
			data['isTemp'] = true;
			data['id'] = Util.generateObjectId();;
			this.props.addProjectInList(data, nextSection);
		}
	}

	renderFooterButtons() {

		const { buttonDisabled } = this.state;

		return (
			<div className="row">
				<div className="col-sm-4">
					<button type="button" onClick={(e) => this.handleSubmit(e)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm" style={{ marginRight: 10 }}>Save & Return</button>
				</div>
				<div className="col-sm-8">
					<div className="text-right">
						{/* <button type="button" onClick={(e)=>this.handleSubmit(e,"ADD_NEW_KA")} disabled={buttonDisabled} className="btn btn-primary btn-sm" style={{marginRight: 10}}>Save & Add New Key Activity</button> */}
						<Button color="secondary" title="Cancel" onClick={this.props.onCancel} className="btn-sm">Cancel</Button>
					</div>
				</div>
			</div>
		);
	}

	renderAddOrEditOutcome() {

		const { form, formErrors, isEdit } = this.state;

		return (
			<div>
				<ModalHeader toggle={this.props.onClose}>{isEdit ? 'Edit Project' : 'Add Project'}</ModalHeader>
				<ModalBody>
					<form onSubmit={(e) => e.preventDefault()}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<label>Title<span className="text-danger">*</span></label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="projectTitle" value={form['projectTitle']} />
										<p className="text-danger">{formErrors['projectTitle'] &&
											<span className="err">{formErrors['projectTitle']}</span>}</p>
									</div>
								</div>
								<div className="form-group">
									<label>Project abbreviation<span className="text-danger">*</span></label>
									<div>
										<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="abbreviation" value={form['abbreviation']} />
										<p className="text-danger">{formErrors['abbreviation'] &&
											<span className="err">{formErrors['abbreviation']}</span>}</p>
									</div>
								</div>
								{/* <div>
									<FocusAreaSelect
										handleFocusAreaSelect={this.handleSelectField.bind(this)}
										value={form.focusArea}
										error={formErrors.focusArea}
										isRequired={true}
									/>
								</div> */}
							</div>
						</div>
						{this.renderFooterButtons()}
					</form>
				</ModalBody>
			</div>
		)
	}

	// Render

	render() {

		return (
			<div>
				{this.renderAddOrEditOutcome()}
			</div>
		);
	}

}

ProjectForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	ProjectForm
);
