import React, { Component } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Button, ButtonGroup, ButtonDropdown, DropdownItem, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from "react-router-dom";

import { setLoading } from "../../actions/loadingActions.js";
import BigCalendar, { momentLocalizer} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import Toolbar from 'react-big-calendar/lib/Toolbar';
import GCFilterOptions from '../common/GCFilterOptions.js';

class CustomizedWeeklyPlanToolbar extends Toolbar {

	constructor(props) {
		super(props);
		this.state = {
			removeOptionsDropdown: false,
			saveOptionsDropdown: false
		};
	}

	toggleSaveOptionsDropdown = () => {
		this.setState({ saveOptionsDropdown: !this.state.saveOptionsDropdown })
	}

	toggleHandler = (key) => {
		this.setState({ [key]: !this.state[key] })
	}

	renderGCFilterOptions = () => {

		const { userPreference, GCMetaData, excludedGoogleCalendarIDs } = this.props;

		return (
			<span style={{marginLeft: 30}}>
				<GCFilterOptions
					GCMetaData={GCMetaData}
					userPreference={userPreference} 
					excludedGoogleCalendarIDs={excludedGoogleCalendarIDs}
					onUpdate={this.props.updateUserPreferencesInServer}
				/>
			</span>
		)
	}

	renderClearButton = () => {

		const { GoogleCalendarCurrentSyncStatus } = this.props;

		if (!GoogleCalendarCurrentSyncStatus) return <span></span>;

		return (
			<div className="mr-4 ml-4">
				<Dropdown
					isOpen={this.state.removeOptionsDropdown}
					toggle={() =>this.toggleHandler('removeOptionsDropdown')}>
						<DropdownToggle className={"weekly-plan-day-btn"} caret>
							Clear
						</DropdownToggle>
					<DropdownMenu right className="mt-2">
						<DropdownItem className="justify-content-center">
							<span>
								<Button 
									className={"weekly-plan-day-btn btn-secondary large"}
									disabled={this.props.disableSaveButton}
									onClick={(e)=>this.props.clearPBHEventsFromGC({clearCurrentNdFeatureSavedInstances : true})}>
										Clear Events In Google Calendar
										<div className={"sub-title-light-text small mb-0"}>
											<small> (Including current and future saved weeks)</small>
										</div>
								</Button>
							</span>
						</DropdownItem>
						<DropdownItem className="justify-content-center">
							<span>
								<Button 
									className={"weekly-plan-day-btn btn-secondary large"}
									disabled={this.props.disableSaveButton}
									onClick={(e)=>this.props.clearPBHEventsFromGC({clearCurrentNdFeatureSavedInstances : false})}>
										Clear Events In Google Calendar
										<div className={"sub-title-light-text small mb-0"}>
											<small> (Excluding current and future saved weeks)</small>
										</div>
								</Button>
							</span>
						</DropdownItem>	
					</DropdownMenu>
				</Dropdown>
			</div>
		)
	}

	renderSaveButton(saveNdSyncBtn = false) {

		if (!saveNdSyncBtn) {
			return (
				<div className="ml-4">
					<Button onClick={this.props.handleSaveChanges} className={"weekly-plan-day-btn-active"} disabled={this.props.disableSaveButton}>Save Changes</Button>
				</div>
			)
		}

		return (
			<div className="ml-4">
				<ButtonDropdown
					isOpen={this.state.saveOptionsDropdown}
					toggle={this.toggleSaveOptionsDropdown}>

					<Button 
						className={"pbh-small-btn-active"}
						disabled={this.props.disableSaveButton}
						onClick={(e)=>this.props.handleSaveChanges(e, {syncWith3rdPartyCalendar : true})}>
							Save Changes
					</Button>

					<DropdownToggle split className={"weekly-plan-day-btn"} />

					<DropdownMenu right className="mt-2">
						<DropdownItem className="justify-content-center">
							<Link to="/profile/#preferences" className="btn btn-primary btn-sm" title="Sync with Calendar">
								<i className="fa fa-calendar alt mr-2" aria-hidden="true"></i>
								<i className="fa fa-link" aria-hidden="true"></i>
								{" "}
								Calendar Preference
							</Link>
						</DropdownItem>
					</DropdownMenu>

				</ButtonDropdown>
			</div>
		)
	}

	render() {

		let activeClass = "weekly-plan-day-btn-active";
		let defaultClass = "weekly-plan-day-btn";

		return (
			<div>
				<div className={"weekly-plan-toolbar mt-n2 mb-2"}>
					<h6>{this.props.label}</h6>
					<div className="row row-section-container">
						<div className="mr-4">
							{/*
							<Link to="/profile/#preferences" className="btn btn-primary btn-sm" title="Sync with Calendar">
								<i className="fa fa-calendar alt mr-2" aria-hidden="true"></i>
								<i className="fa fa-link" aria-hidden="true"></i>
							</Link>
							*/}
						</div>
						{this.renderClearButton()}
						<ButtonGroup>
							<Button onClick={this.view.bind(null, 'week')} className={(this.props.view == 'week'? activeClass: defaultClass)}>Week</Button>
							<Button onClick={this.view.bind(null, 'day')} className={(this.props.view == 'day'? activeClass: defaultClass)}>Day</Button>
						</ButtonGroup>
						{this.renderGCFilterOptions()}
						{this.renderSaveButton(true) /* save nd sync with 3rd-party calendar */ }
					</div>
				</div>
			</div>
		);
	}
}

export default CustomizedWeeklyPlanToolbar;
