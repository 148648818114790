import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getNoteInfoByID, resetnoteData } from "../../actions/noteActions";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import NoteForm from "./NoteForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditNote extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	componentWillUnmount() {
		// Reset data so that table will re-render
		this.props.resetnoteData();
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {
		const { noteId } = this.props.match.params;
		this.props.getNoteInfoByID(noteId)
	}

	// Handler for editing note
	handleEditNote(noteData) {

		noteData.recordID = this.props.match.params.noteId;

		var postObj = noteData;

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.updateNote, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("Note saved successfully.", false);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		const { note } = this.props.notes;
		const { formApiResponse } = this.state;

		if (!Util.isEmpty(note)) {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="bread-crumb">
						<a href="/notes/">Notes</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#">Edit</a>
					</div>
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">
									<NoteForm
										initialValues={note}
										onSubmit={this.handleEditNote.bind(this)}
										isEdit={true}
										noteId={this.props.match.params}
									/>
									{
										!_.isEmpty(formApiResponse)
											?
											<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
											: null
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div className="form-container">

								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

EditNote.propTypes = {
	getNoteInfoByID: PropTypes.func.isRequired,
	resetnoteData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	notes: state.notes,
});

export default connect(mapStateToProps, { getNoteInfoByID, resetnoteData, setLoading })(
	EditNote
);
