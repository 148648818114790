import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import TimeEstimateSelect from "../form-inputs/TimeEstimateSelect";

import { setLoading } from "../../actions/loadingActions.js";
import { resetTaskData } from "../../actions/taskActions";

import Util from '../../utils/Util';
import { parseInt } from "lodash";
var _ = require('lodash');

class TaskForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				task: "",
				timeEstimateInMinutes: null,
				isCompleted: false,
				flags: [],
				actualCompletionTimeInMinutes: 0,
				notes: "",
			},
			formErrors: {
				task: null,
				timeEstimateInMinutes: null,
				actualCompletionTimeInMinutes: null,
				notes: null,
			},
			notesEditorState: EditorState.createEmpty(),
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { notes } = this.props.initialValues;

			var formObj = {};

			formObj = {
				...this.props.initialValues,
				...formObj,
			};

			this.setState({
				form: formObj,
				isEdit: true,
				notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
			});
		}
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == "sessionsPerWeek") {
			if (!isNaN(parseInt(value))) {
				value = parseInt(value);
			};
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	handleStatusChange(name, updatedStatus) {

		const { form } = this.state;

		var formObj = {...form};
		
		if (updatedStatus === true) {
			formObj['isCompleted'] = true;
			formObj['completionDateTime'] = new Date();
			formObj['actualCompletionTimeInMinutes'] = formObj.timeEstimateInMinutes;
		}
		else {
			formObj['isCompleted'] = false;
			formObj['completionDateTime'] = '';
			formObj['actualCompletionTimeInMinutes'] = 0;
		};

		this.setState({
			form: formObj,
		});
	}

	handleFlagChange(name, flagCode) {

		const { form } = this.state;

		var formObj = {...form};
		
		let flags = formObj.flags || [];
        let flagCodeIndex = flags.indexOf(flagCode);

        if (flagCodeIndex > -1) {
            flags.splice(flagCodeIndex, 1);
        }
        else {
            flags.push(flagCode);
        };

		formObj.flags = flags;

		this.setState({
			form: formObj,
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		const { isEdit, form } = this.state;
		var errorMsg = null;

		switch (name) {

			case "task":
				if (!value) errorMsg = "Please enter Task Name";
				break;

			case "timeEstimateInMinutes":
				if (!value) errorMsg = "Please enter Time Estimate";
				break;

			case "actualCompletionTimeInMinutes":
				if (isEdit && form['isCompleted'] === true && !value) errorMsg = "Please specify actual time spent for completing this task.";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Task submit
	handleTaskSubmit(e) {
		e.preventDefault();

		const { form, formErrors, notesEditorState } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;
		} 
		else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (key == 'flags') return; // Don't do any manipulations for flags field.

				if (Array.isArray(value)) {

					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} 
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			formCopy['notes'] = notesContent;
			this.props.onSubmit(formCopy);
		}
	}

	render() {

		const { form, formErrors, notesEditorState, isEdit } = this.state;

		return (
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Task Name <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="task" value={form['task']} />
								<p className="text-danger">{formErrors['task'] &&
									<span className="err">{formErrors['task']}</span>}</p>
							</div>
						</div>
						<div>
							<TimeEstimateSelect
								handleTimeEstimateSelect={this.handleSelectField.bind(this)}
								value={form.timeEstimateInMinutes}
								error={formErrors.timeEstimateInMinutes}
								isRequired={true}
							/>
						</div>
						{isEdit?
							<div className="form-group">
								<label>Status</label>
								<div className="cursor-pointer" onClick={() => this.handleStatusChange('isCompleted', !form['isCompleted'])}>
									{form['isCompleted'] === true ?
										<div><i className="fa fa-check-circle fa-lg color-primary mr-2" aria-hidden="true"></i>Completed</div> :
										<div><i className="fa fa fa-circle-o fa-lg color-primary mr-2" aria-hidden="true"></i>Open</div>
									}
								</div>
							</div>
						: null}
						{isEdit && form['isCompleted'] === true ?
							<TimeEstimateSelect
								handleTimeEstimateSelect={(label, selectedTime) => this.handleSelectField('actualCompletionTimeInMinutes', selectedTime.value)}
								value={form.actualCompletionTimeInMinutes}
								error={formErrors.actualCompletionTimeInMinutes}
								label={'Actual Time'}
								isRequired={true}
							/>
						: null}
						{isEdit?
							<div className="form-group">
								<label>Flag</label>
								<div className="cursor-pointer" onClick={() => this.handleFlagChange('flags', 1)}>
									{form['flags'] && form['flags'].includes(1) ?
										<div><i className="fa fa-flag fa-lg color-primary mr-2" aria-hidden="true"></i>Flagged</div> :
										<div><i className="fa fa-flag-o fa-lg color-primary mr-2" aria-hidden="true"></i>Not flagged</div>
									}
								</div>
							</div>
						: null}
						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
										})
									}
								/>
							</div>
						</div>
					</div>
				</div>

				<button type="button" onClick={this.handleTaskSubmit.bind(this)} className="btn btn-primary mb-2">{isEdit ? "Save" : "Save & Close"}</button>
				{
					!_.isEmpty(this.props.formApiResponse)
						?
						<p className={this.props.formApiResponse.className}><span>{this.props.formApiResponse.message}</span></p>
						: null
				}
			</form>
		);

	}
}

TaskForm.propTypes = {
	resetTaskData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetTaskData, setLoading })(
	TaskForm
);
