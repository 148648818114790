import React from 'react';
import ReactDom from 'react-dom';
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/layoutStyles.css';
import './assets/styles/reactCustom.css';
import Navigation from './Navigation';

const App = DragDropContext(HTML5Backend)(Navigation);

ReactDom.render( <App /> , document.getElementById('root'));