import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import UpdateOutcomeTemplate from "../templates/UpdateOutcomeTemplate.js";
import UpdateProjectTemplate from "../templates/UpdateProjectTemplate.js";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

const templateTypes = [
    {label: 'Outcome', value: 'outcome'},
    {label: 'Project', value: 'project'}
];

const statusOptions = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
];

class Templates extends Component {

	constructor(props) {
		super(props);
        this.state = {
            templates: [],
            templateObjToBeUpdated: {}, // Handle the templateObj to be updated in a separate state variable for easy mappings. The same obj will be used for both 'add' & 'edit' operations.
            selectedTemplateType: null,
            validationErrors: {},
            showUpdateTemplateSection: false,
            itemToRemove: null,
            showDeleteTemplateModal: false,
        };
    }
    
    componentDidMount() {

        this.setStickyToolBar();
        this.props.setLoading(true);

        API_Services.httpGET(UrlConstants.URLS.getSystemTemplates, (err, response) => {

            if (response.data) {
    
                if (response.data.status && response.data.data) {
                    
                    this.setState({
                        templates: response.data.data,
                    });
                }; 
            };
            this.props.setLoading(false);
        });
    }

    setStickyToolBar() {

		const header = document.getElementById("templates-floating-tool-bar");

		window.addEventListener("scroll", () => {
			
			if (!header) return;

			if (window.pageYOffset > 125) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

    toggleUpdateTemplateSection(templateObj, templateType) {

        if (this.state.showUpdateTemplateSection) {
            this.setState({
                selectedTemplateType: null,
                templateObjToBeUpdated: {},
                validationErrors: {},
                showUpdateTemplateSection: false
            },
            () => {
                this.setStickyToolBar();
            });
        }
        else if (templateType) {
            this.setState({
                selectedTemplateType: templateType,
                templateObjToBeUpdated: _.clone(templateObj),
                validationErrors: {},
                showUpdateTemplateSection: true
            });
        };
    }

    handleTemplateUpdates(updatedTemplateObj) {

        if (!updatedTemplateObj) return;

        let {templates} = this.state;
        let itemIndex = _.findIndex(templates, {id: updatedTemplateObj.id});

        if (itemIndex > -1) { // Update operation
            templates[itemIndex] = updatedTemplateObj;
        }
        else { // Add operation
            templates.push(updatedTemplateObj);
        };

        this.setState({
            templates: templates
        },
        () => {
            this.toggleUpdateTemplateSection();
        });
    }

    toggleDeleteTemplateModal(recordId) {

		if (this.state.showDeleteTemplateModal) {
			this.setState({
				showDeleteTemplateModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				showDeleteTemplateModal: true,
				itemToRemove: recordId,
			});
		}
	}

    renderDeleteTemplateModal() {

		var templateObj = _.find(this.state.templates, { id: this.state.itemToRemove });
		var templateTitle = '';

        if (templateObj && templateObj.templateType == 'outcome') templateTitle = templateObj.outcomeTitle;
        else if (templateObj && templateObj.templateType == 'project') templateTitle = templateObj.projectTitle;

		return (
			<div>
				<Modal isOpen={this.state.showDeleteTemplateModal} toggle={this.toggleDeleteTemplateModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteTemplateModal.bind(this)}>Delete Template</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the template <span className="text-warning">{templateTitle}?</span></p>
						<div><small>* This action is permanent and cannot be undone.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-sm" color="danger" title="Delete" onClick={this.handleTemplateDelete.bind(this)}>Delete</Button>{' '}
						<Button className="btn-sm" color="secondary" title="Cancel" onClick={this.toggleDeleteTemplateModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

    handleTemplateDelete() {

        let {templates, itemToRemove} = this.state;

        if (!itemToRemove) return;

        this.props.setLoading(true);

        let postObj = {
            recordID: itemToRemove
        };

        API_Services.httpPOST(UrlConstants.URLS.deleteSystemTemplate, postObj, (err, response) => {

			if (err) {
                alert('Something went wrong!');
			} 
            else if (response.data) {

				if (response.data.status && response.data.data) {
     
                    let itemIndex = _.findIndex(templates, {id: response.data.data.id});
                    templates.splice(itemIndex, 1);

                    this.setState({
                        templates: templates
                    },
                    () => {
                        this.toggleDeleteTemplateModal();
                    });
				}; 
			}; 
			this.props.setLoading(false);
		});
    }

    createTemplate(templateType) {

        this.setState({
            selectedTemplateType: templateType
        },
        () => {
            this.toggleUpdateTemplateSection({}, templateType);
        });
    }

    renderUpdateTemplateSection() {

        let {templates, templateObjToBeUpdated, selectedTemplateType} = this.state;
        let projectTemplates = _.filter(templates, {templateType: 'project'});

        if (selectedTemplateType == 'outcome') {
            return(
                <UpdateOutcomeTemplate
                    projectTemplates={projectTemplates}
                    templateObjToBeUpdated={templateObjToBeUpdated} 
                    handleTemplateUpdates={this.handleTemplateUpdates.bind(this)}
                    handleCancelUpdate={this.toggleUpdateTemplateSection.bind(this)} 
                />
            );
        }
        else if (selectedTemplateType == 'project') {
            return(
                <UpdateProjectTemplate
                    templateObjToBeUpdated={templateObjToBeUpdated} 
                    handleTemplateUpdates={this.handleTemplateUpdates.bind(this)}
                    handleCancelUpdate={this.toggleUpdateTemplateSection.bind(this)} 
                />
            );
        };
    }

	render() {

        let { templates, showUpdateTemplateSection } = this.state;
        
        if (showUpdateTemplateSection) {
            return this.renderUpdateTemplateSection();
        };

		return (
			<div className="mt-n3">
                <div id={"templates-floating-tool-bar"}>
                    <div className="text-right mb-4">
                        <button type="button" className="btn btn-primary btn-sm mr-3" onClick={this.createTemplate.bind(this, 'outcome')}>Outcome Template <i className="fa fa-plus" aria-hidden="true"></i></button>
                        <button type="button" className="btn btn-primary btn-sm" onClick={this.createTemplate.bind(this, 'project')}>Project Template <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </div>
                </div>
                {templates.length == 0 ?
                    <div className="text-info small"> No Template(s) found.</div>
                :
                    <div className="App">
                        <header className="App-header table-responsive" style={{overflowX: 'auto'}}>
                            <table className="table-curved mb-3">
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="" style={{minWidth:200, maxWidth: 300}}>Title</th>
                                        <th className="text-center" style={{minWidth:100, maxWidth: 150}}>Type</th>
                                        <th className="text-center" style={{minWidth:100, maxWidth: 150}}>Status</th>
                                        <th className="text-center" colSpan="2" style={{minWidth:100, maxWidth: 100}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates.map((templateObj, index) => {
                                        
                                        let type = _.find(templateTypes, {value: templateObj.templateType});
                                        let status = _.find(statusOptions, {value: templateObj.templateStatus});
                                        let templateTitle = '';

                                        if (templateObj && templateObj.templateType == 'outcome') templateTitle = templateObj.outcomeTitle;
                                        else if (templateObj && templateObj.templateType == 'project') templateTitle = templateObj.projectTitle;

                                        return(
                                            <tr key={'template_' + index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="" style={{minWidth:200, maxWidth: 300}}>{templateTitle}</td>
                                                <td className="text-center" style={{minWidth:100, maxWidth: 150}}>{type ? type.label : ''}</td>
                                                <td className="text-center" style={{minWidth:100, maxWidth: 150}}>{status ? status.label : ''}</td>
                                                <td className="text-center" style={{minWidth:50, maxWidth: 50}}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleUpdateTemplateSection.bind(this, templateObj, templateObj.templateType)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
                                                <td className="text-center" style={{minWidth:50, maxWidth: 50}}><button className="table-action-button" title="Delete" onClick={this.toggleDeleteTemplateModal.bind(this, templateObj.id)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </header>
                    </div>
                }
                {this.renderDeleteTemplateModal()}
			</div>
		);
	}
}

Templates.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	Templates
);
