import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactSelect from 'react-select';
import moment from "moment";
import { setLoading } from "../../actions/loadingActions.js";
import DragableTasks from './DragableTasks';
import AddUTask from "../universal-tasks/AddUTask";
import ViewUTask from "../tasks/ViewTask";
import EditUTask from "../universal-tasks/EditUTask";
import TaskStatus from "../tasks/TaskStatus";
import Util from '../../utils/Util';
import API_Services from '../../utils/API_Services';
import UrlConstants from "../../utils/UrlConstants.js";
import SharedStyles from '../../assets/styles/SharedStyles';
var _ = require('lodash');

class Tasks extends Component {

	constructor(props) {
		super(props);
		this.state = {
			utasks: [],
            unfilteredTasks: [],
			taskEvents: [],
			showFilterSection: false,
			outcomesWithPopulatedData: [],
			outcomeOptions: [],
			keyActivityOptions: [],
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			statusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Completed', value: 'completed'},
				{label: 'Open', value: 'open'}
			],
			selectedStatusOption: {label: 'Open', value: 'open'},
			scheduleStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Scheduled', value: 'scheduled'},
				{label: 'Unscheduled', value: 'unscheduled'}
			],
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			flagOptions: [
				{label: 'All', value: 'all'},
				{label: 'Flagged', value: 'flagged'},
				{label: 'Not Flagged', value: 'not-flagged'},
			],
			selectedFlagOptions: {label: 'All', value: 'all'},
			weeklyPlanStatusOptions: [
				{label: 'All', value: 'all'},
				{label: 'Active', value: 'active'},
				{label: 'Inactive', value: 'inactive'}
			],
			selectedWeeklyPlanStatus: {label: 'Active', value: 'active'},
			itemToEdit: null,
			itemToView: null,
			itemToRemove: null,
			viewTaskModal: false,
			addTaskModal: false,
			editTaskModal: false
		};
	}

	componentDidMount() {
		this.populatePropsData(this.props);
	}

	componentWillReceiveProps(props) {
		this.populatePropsData(props);
	}

	populatePropsData(props) {
		
		let outcomesWithPopulatedData = props.outcomesWithPopulatedData || [];
		let outcomeOptions = [
			{label: 'All', value: 'all'},
			{label: 'Unassigned', value: 'unassigned'},
		]; // For default selection.

		_.map(outcomesWithPopulatedData, (outcomeObj) => {
			outcomeOptions.push({
				label: outcomeObj.outcomeTitle,
				value: outcomeObj.id
			});
		});

		this.setState({
			outcomesWithPopulatedData: outcomesWithPopulatedData,
			outcomeOptions: outcomeOptions,
			utasks: _.cloneDeep(props.utasks),
			unfilteredTasks: _.cloneDeep(props.utasks)
		},
		() => {
			this.applyFilter();
		}); 
	}

    setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
    }

	handleOnDragEnd(result) {
		
		if (!result.source || !result.destination || !result.draggableId) return;
		if (result.source.index == result.destination.index) return; // No change happened.

		this.props.setLoading(true);

		let postObj = {
			changeItem: result.draggableId,
			sourceIndex: result.source.index,
			destinationIndex: result.destination.index,
			filterSequence: _.map(this.state.utasks, 'id')
		};

		API_Services.httpPOST(UrlConstants.URLS.updateUTasksRanks, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setApiResponse("Rank updated successfully!", false);
					this.onRankUpdate(response.data.data.itemSequence);
				} 
				else {
					this.setApiResponse(response.data.message, true);
				};
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
		});
	}

	onRankUpdate(updatedTaskSequence) {

		let {unfilteredTasks} = this.state;

		unfilteredTasks = _.sortBy(unfilteredTasks, function(item) {
			return updatedTaskSequence.indexOf(item.id);
		});

		this.setState({
			unfilteredTasks: unfilteredTasks
		},
		() => {
			this.applyFilter();
			this.props.onUpdateUTasks(unfilteredTasks);
		});
	}

    computeTaskEvents() {

        let taskEvents = [];
        let outcomesWithPopulatedData = this.state.outcomesWithPopulatedData || [];

        _.map(this.state.utasks, (taskObj) => {

			var startDt = new Date();
			var endDt = new Date();
			startDt.setHours(0, 0, 0);
			endDt.setHours(0, (taskObj.timeEstimateInMinutes), 0);

            let eventObj = {
                id: taskObj.id + '-' + Date.now(),
				title: taskObj.task,
				start: startDt,
				end: endDt,
                timeBlockID: taskObj.id,
                timeBlockTitle: taskObj.task,
				durationInMinutes: taskObj.timeEstimateInMinutes,
                sessionsPerWeek: 1, // Defaults to 1
				isUnplanned: true,
				isCreatedFromTheTask: true,
				taskID: taskObj.id,
				taskTitle: taskObj.task,
				taskCompletionStatus: taskObj.isCompleted,
                isUnAssigned: true,
				notes: taskObj.notes,
				tasks: [{ // The task based on which the event is created should be added as an associated item/task by default.
					id: taskObj.id,
                	task: taskObj.task,
					isActive: true
				}]
            };

            if (taskObj.outcomeID && taskObj.keyActivity) {

                let associatedOutcome = _.find(outcomesWithPopulatedData, {id: taskObj.outcomeID});
                let keyActivityID = taskObj.keyActivity.toString(); // keyActivity is being recorded as a objectID under the tasks collection.

                if (associatedOutcome && associatedOutcome.keyActivities && associatedOutcome.focusArea) {
                    
                    let associatedKeyActivity = _.find(associatedOutcome.keyActivities, {id: keyActivityID});

                    if (associatedKeyActivity) {
                        eventObj.focusArea = associatedOutcome.focusArea.id;
                        eventObj.focusAreaTitle = associatedOutcome.focusArea.focusAreaTitle;
                        eventObj.outcomeID = associatedOutcome.id;
                        eventObj.outcomeAbbr = associatedOutcome.abbreviation;
                        eventObj.outcomeTitle = associatedOutcome.outcomeTitle;
                        eventObj.keyActivityID = keyActivityID;
                        eventObj.keyActivityTitle = associatedKeyActivity.title;
                        eventObj.isUnAssigned = false;
						
						if (associatedKeyActivity.metricsAndTargets) {
							eventObj.metricsAndTargets = _.cloneDeep(associatedKeyActivity.metricsAndTargets);
						};
                    };
                };
            };

            taskEvents.push(eventObj);
        });

        this.setState({
            taskEvents: taskEvents
        });
    }

	handleOutcomeSelectionChange(outcome) {

		if (outcome.value == 'unassigned') {

			let keyActivityOptions = [
				{ label: 'Unassigned', value: 'unassigned'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'Unassigned', value: 'unassigned'}
			});
		}
		else if (outcome.value == 'all') {

			let keyActivityOptions = [
				{ label: 'All', value: 'all'}
			]; // For default selection.

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
		else {

			let filterObjForTheSelectedOutcome = _.find(this.state.outcomesWithPopulatedData, {id: outcome.value});
			let keyActivityOptions = [
				{ label: 'All',value: 'all'}
			]; // For default selection.

			if (filterObjForTheSelectedOutcome && filterObjForTheSelectedOutcome.keyActivities) {

				_.map(filterObjForTheSelectedOutcome.keyActivities, (activityObj) => {
	
					keyActivityOptions.push({
						label: activityObj.title,
						value: activityObj.id
					});
				});
			};

			this.setState({
				selectedOutcome: outcome,
				keyActivityOptions: keyActivityOptions,
				selectedKeyActivity: { label: 'All', value: 'all'}
			});
		}
	}

	applyFilter() {

		const {unfilteredTasks, selectedOutcome, selectedKeyActivity, selectedStatusOption, selectedScheduleStatusOption, selectedFlagOptions, selectedWeeklyPlanStatus} = this.state;

		let filteredTasks = [];

		// 1. Filter based on Outcome and KeyActivity.
		if (selectedOutcome.value == 'unassigned' || selectedKeyActivity.value == 'unassigned') {
			
			filteredTasks = _.filter(unfilteredTasks, (item) => {
				return !item.outcomeID && !item.keyActivity;
			});
		}
		else {

			if (selectedOutcome.value == 'all') {
				filteredTasks = Util.cloneArray(unfilteredTasks);
			}
			else if (selectedOutcome.value) {
				
				filteredTasks = _.filter(unfilteredTasks, (item) => {
					return item.outcomeID == selectedOutcome.value;
				});

				if (selectedKeyActivity.value && selectedKeyActivity.value != 'all' && selectedKeyActivity.value != 'unassigned') {
					
					filteredTasks = _.filter(filteredTasks, (item) => {
						return item.keyActivity == selectedKeyActivity.value;
					});
				}
			}
		};

		// 2. Filter based on includeInWeeklyPlan status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedWeeklyPlanStatus.value == 'all') return true;
			else if (!item.outcomeID && !item.keyActivity) return true; //The unassigned tasks should be unaffected by this filter
			else if (selectedWeeklyPlanStatus.value == 'active') return item.includeInWeeklyPlanStatusOfOutcome && item.includeInWeeklyPlanStatusOfKeyActivity;
			else if (selectedWeeklyPlanStatus.value == 'inactive') return !item.includeInWeeklyPlanStatusOfOutcome || !item.includeInWeeklyPlanStatusOfKeyActivity;
			else return false;
		});
		
		// 3. Filter based on task completion status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedStatusOption.value == 'all') return true;
			else if (selectedStatusOption.value == 'completed') return item.isCompleted;
			else if (selectedStatusOption.value == 'open') return !item.isCompleted;
			else return false;
		});

		// 4. Filter based on calendar schedule status.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedScheduleStatusOption.value == 'all') return true;
			else if (selectedScheduleStatusOption.value == 'scheduled') return item.calendarSchedules && item.calendarSchedules.length > 0;
			else if (selectedScheduleStatusOption.value == 'unscheduled') return !item.calendarSchedules || (item.calendarSchedules && item.calendarSchedules.length == 0);
			else return false;
		});

		// 5. Filter based on flag.
		filteredTasks = _.filter(filteredTasks, (item) => {
			if (selectedFlagOptions.value == 'all') return true;
			else if (selectedFlagOptions.value == 'flagged' && item.flags) return item.flags.length == 1;
			else if (selectedFlagOptions.value == 'not-flagged') return !item.flags || item.flags && item.flags.length == 0;
			else return false;
		});

		this.setState({
			utasks: filteredTasks,
			selectedOutcomeLabel: selectedOutcome ? selectedOutcome.label : '',
			selectedKeyActivityLabel: selectedKeyActivity ? selectedKeyActivity.label : '',
			selectedWeeklyPlanStatusLabel: selectedWeeklyPlanStatus ? selectedWeeklyPlanStatus.label : '',
			selectedStatusOptionLabel: selectedStatusOption ? selectedStatusOption.label : '',
			selectedScheduleStatusOptionLabel: selectedScheduleStatusOption ? selectedScheduleStatusOption.label : '',
			selectedFlagOptionLabel: selectedFlagOptions ? selectedFlagOptions.label : '',
			showFilterSection: false
		},
		() => this.computeTaskEvents());
	}

	resetFilterOptions() {
		
		this.setState({
			selectedOutcome: {label: 'All', value: 'all'},
			selectedKeyActivity: {label: 'All', value: 'all'},
			keyActivityOptions: [{label: 'All', value: 'all'}],
			selectedStatusOption: {label: 'All', value: 'all'},
			selectedScheduleStatusOption: {label: 'All', value: 'all'},
			selectedFlagOptions: {label: 'All', value: 'all'},
			selectedWeeklyPlanStatus: {label: 'All', value: 'all'},
			utasks: Util.cloneArray(this.state.unfilteredTasks),
		},
		() => {
			this.applyFilter();
		});
	}

	handleStartDoing(taskObj) {

		let {taskEvents} = this.state;
		let taskEventObj = _.find(taskEvents, {taskID: taskObj.id});

		if (_.isEmpty(taskEventObj)) return;

		var startDt = new Date();
		var endDt = new Date();
		startDt.setHours(0, 0, 0);
		endDt.setHours(0, taskEventObj.durationInMinutes, 0);

		taskEventObj.start = startDt;
		taskEventObj.end = endDt;
		taskEventObj.trackDuration = true;
		taskEventObj.isAdHocTimeTrackerTimeBlock = true;

		this.props.handleTaskBasedAdHocEvent(taskEventObj);
	}

	onTaskAddition(itemPosition, newTask) {

		let { unfilteredTasks } = this.state;
		itemPosition = itemPosition ? parseInt(itemPosition)-1 : 0; // The newly added task will be added at the top by default.					
			
		if (itemPosition > unfilteredTasks.length) {
			itemPosition = unfilteredTasks.length;
		};
		unfilteredTasks.splice(itemPosition, 0, newTask); // Add tasks at the required position.

		this.setState({
			unfilteredTasks: unfilteredTasks
		},
		() => {
			this.applyFilter();
			this.toggleAddTaskModal();
			this.props.onUpdateUTasks(unfilteredTasks);
		});
	}

	onTaskUpdate(updatedTaskObj) {

		const { unfilteredTasks } = this.state;
		var taskObjIndex = _.findIndex(unfilteredTasks, { id: updatedTaskObj.id });
		
		if (taskObjIndex > -1) {
			unfilteredTasks[taskObjIndex] = _.assign(unfilteredTasks[taskObjIndex], updatedTaskObj);
			this.setState({
				unfilteredTasks: unfilteredTasks
			},
			() => {
				this.applyFilter();
				this.props.onUpdateUTasks(unfilteredTasks);
			});
		};
	}

	toggleFilterSection() {

		this.setState({
			showFilterSection: !this.state.showFilterSection
		});
	}

	toggleAddTaskModal() {

		this.setState({
			addTaskModal: !this.state.addTaskModal
		});
	}

	// Show or hide view modal
	toggleViewTaskModal(rowId) {

		if (this.state.itemToView) {
			this.setState({
				viewTaskModal: false,
				itemToView: null,
			});
		} 
		else {
			this.setState({
				viewTaskModal: true,
				itemToView: rowId,
			});
		}
	}

	// Show or hide edit modal
	toggleEditTaskModal(rowId) {

		if (this.state.itemToEdit) {
			this.setState({
				editTaskModal: false,
				itemToEdit: null,
			});
		} 
		else {
			this.setState({
				editTaskModal: true,
				itemToEdit: rowId,
			});
		}
	}

	toggleDeleteModal(rowId) {

		if (this.state.deleteModal) {
			this.setState({
				deleteModal: false,
				itemToRemove: null,
			});
		} 
		else {
			this.setState({
				deleteModal: true,
				itemToRemove: rowId,
			});
		}
	}

	handleTaskDelete() {

		var { unfilteredTasks, itemToRemove } = this.state;

		if (itemToRemove) {

			this.props.setLoading(true);

			var postObj = {
				recordID: itemToRemove,
			};

			API_Services.httpPOST(UrlConstants.URLS.deleteTask, postObj, (err, response) => {

				if (err) {
					
					if (err.response && err.response.data) {
						this.setApiResponse(err.response.data, true)
					} 
					else {
						this.setApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
					this.props.setLoading(false);
				} 
				else if (response.data) {

					if (response.data.status) {

						this.setState({
							deleteModal: false,
							itemToRemove: null,
						}, 
						() => {
							
							this.props.setLoading(false);
	
							if (response.data.data && response.data.data.deletedRecord) {

								var taskObjIndex = _.findIndex(unfilteredTasks, { id: response.data.data.deletedRecord.id });
								
								if (taskObjIndex > -1) {
									unfilteredTasks.splice(taskObjIndex, 1);
									this.setState({
										unfilteredTasks: unfilteredTasks
									},
									() => {
										this.applyFilter();
										this.props.onUpdateUTasks(unfilteredTasks);
									});
								};
							};
						});
					} 
					else {
						this.props.setLoading(false);
						this.setApiResponse(response.data.message, true);
					}
				} 
				else {
					this.props.setLoading(false);
					this.setApiResponse("Something went wrong!", true);
				}
			});
		}
	}

	renderAddTaskModal() {

		return(
			<Modal size={'lg'} isOpen={this.state.addTaskModal} toggle={this.toggleAddTaskModal.bind(this)}>
				<ModalHeader toggle={this.toggleAddTaskModal.bind(this)}>Add Task</ModalHeader>
				<ModalBody>
					<AddUTask
						itemPosition={1} // The tasks added using this button should always be positioned at the top.
						onClose={this.toggleAddTaskModal.bind(this)}
						reloadData={this.props.reloadOutcomesData}
						addTaskInList={this.onTaskAddition.bind(this)} />
				</ModalBody>
			</Modal>
		)
	}

	// Render view modal
	renderViewTaskInModal() {

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.viewTaskModal} toggle={this.toggleViewTaskModal.bind(this)}>
					<ModalHeader toggle={this.toggleViewTaskModal.bind(this)}>
						View Task
					</ModalHeader>
					<ModalBody>
						<ViewUTask taskID={this.state.itemToView} />
					</ModalBody>
					<ModalFooter>
						<button
							className="btn btn-primary btn-sm" 
							onClick={() => { this.toggleEditTaskModal(this.state.itemToView); this.toggleViewTaskModal()}}>Edit
						</button>
						<button
							className="btn btn-danger btn-sm ml-2" 
							onClick={() => { this.toggleDeleteModal(this.state.itemToView); this.toggleViewTaskModal()}}>Delete
						</button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	// Render edit modal
	renderEditTaskInModal() {

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.editTaskModal} toggle={this.toggleEditTaskModal.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleEditTaskModal.bind(this)}>Edit Task </ModalHeader>
					<ModalBody>
						<EditUTask
							onClose={this.toggleEditTaskModal.bind(this)}
							reloadData={this.props.reloadOutcomesData}
							taskID={this.state.itemToEdit}
							onTaskUpdate={(updatedTaskObj) => {
								this.onTaskUpdate(updatedTaskObj);
								this.toggleEditTaskModal();
							}} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	renderTaskDeleteModal() {

		var tObj = _.find(this.state.unfilteredTasks, { id: this.state.itemToRemove });
		var tTitle = tObj ? tObj.task : '';

		return (
			<div>
				<Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeleteModal.bind(this)}>Delete Task</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the task <span className="text-warning">{tTitle}?</span></p>
						<div><small><i>* This action cannot be undone.</i></small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" className="btn-sm" title="Delete" onClick={this.handleTaskDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" className="btn-sm" onClick={this.toggleDeleteModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	renderFilterSection() {

		let outcomesWithPopulatedData = this.state.outcomesWithPopulatedData || [];
		const { showFilterSection, outcomeOptions, keyActivityOptions, statusOptions, scheduleStatusOptions, flagOptions, weeklyPlanStatusOptions } = this.state;

		if (showFilterSection && outcomesWithPopulatedData.length > 0) {
			return(
				<div className="grey-container mb-3 tracker-tasks-filter">
					<div className="mb-2">
						<label className="small-title-text">Project</label>
						<ReactSelect
							name="outcome"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={outcomeOptions}
							value={this.state.selectedOutcome}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(outcome) => this.handleOutcomeSelectionChange(outcome)}
							placeholder="Filter by Project..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Key Activity</label>
						<ReactSelect
							name="keyActivity"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={keyActivityOptions}
							value={this.state.selectedKeyActivity}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(activity) => this.setState({selectedKeyActivity: activity})}
							placeholder="Filter by Key Activity..."
						/>
					</div>
					{this.state.selectedOutcome && this.state.selectedOutcome.value != 'unassigned' ?
						<div className="mb-2">
							<label className="small-title-text">Include In Weekly Plan Status</label>
							<ReactSelect
								name="includeInWeeklyPlanStatus"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={weeklyPlanStatusOptions}
								value={this.state.selectedWeeklyPlanStatus}
								removeSelected={true}
								autosize={true}
								clearable={true}
								onSelectResetsInput={true}
								onChange={(option) => this.setState({selectedWeeklyPlanStatus: option})}
								placeholder="Filter by Include in Weekly Plan Status..."
							/>
						</div>
					: null}
					<div className="mb-2">
						<label className="small-title-text">Status</label>
						<ReactSelect
							name="status"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={statusOptions}
							value={this.state.selectedStatusOption}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(option) => this.setState({selectedStatusOption: option})}
							placeholder="Filter by completion status..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Calendar Schedule Status</label>
						<ReactSelect
							name="calendarScheduleStatus"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={scheduleStatusOptions}
							value={this.state.selectedScheduleStatusOption}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(option) => this.setState({selectedScheduleStatusOption: option})}
							placeholder="Filter by completion status..."
						/>
					</div>
					<div className="mb-2">
						<label className="small-title-text">Flag</label>
						<ReactSelect
							name="flags"
							styles={SharedStyles.selectBoxStyles}
							closeOnSelect={false}
							options={flagOptions}
							value={this.state.selectedFlagOptions}
							removeSelected={true}
							autosize={true}
							clearable={true}
							onSelectResetsInput={true}
							onChange={(options) => this.setState({selectedFlagOptions: options})}
							placeholder="Filter by flags..."
						/>
					</div>
					<div className="row row-section-container mt-3 mb-2">
						<button type="button" className="btn btn-primary btn-xs mr-3" onClick={this.applyFilter.bind(this)}>Apply</button>
						<button type="button" className="btn btn-primary btn-xs" onClick={this.resetFilterOptions.bind(this)}>Reset</button>
					</div>
				</div>
			)
		}
		else {
			return null;
		};
	}

	render() {

		const {utasks, taskEvents} = this.state;
		const {targetWeekStart, adHocTimeTrackingIsOn} = this.props;
        let isCurrentWeek = moment().isoWeek() == moment(targetWeekStart).isoWeek() ? true : false;

		return(
			<div>
				<div className="mb-2">
					<div className="d-flex align-items-center justify-content-end mb-3">
						<div><button type="button" className="btn btn-primary btn-xs mr-2" title="Add Task" onClick={this.toggleAddTaskModal.bind(this)} style={{fontSize:12, padding:5}}><i className="fa fa-plus" aria-hidden="true"></i></button></div>
						<div><button type="button" className="btn btn-primary btn-xs mr-2" title="Filter Task" onClick={this.toggleFilterSection.bind(this)} style={{fontSize:12, padding:5}}><i className="fa fa-filter" aria-hidden="true"></i></button></div>
						<div><button type="button" className="btn btn-primary btn-xs" title="Refresh Task List" onClick={this.props.onRefresh} style={{fontSize:12, padding:5}}><i className="fa fa-refresh" aria-hidden="true"></i></button></div>
					</div>
					{this.renderFilterSection()}
				</div>
				{utasks.length > 0 ?
					<div className="row m-0">
						<div style={{width:'90%'}}>
							<DragDropContext onDragEnd={this.handleOnDragEnd.bind(this)}>
								<Droppable droppableId="tasks">
									{(provided) => (
										<table className="table-curved" {...provided.droppableProps} ref={provided.innerRef}>
											<thead>
												<tr>
													<th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '15%'}}}><i className="fa fa-check" aria-hidden="true"></i></th>
													<th className="text-left" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '85%'}}}><div style={{height:25, overflow: 'hidden'}}>Task Info.</div></th>
													{isCurrentWeek && !adHocTimeTrackingIsOn ?
                                                        <th className="text-center" style={{...SharedStyles.tableHeading, ...{minWidth:'unset', width: '10%'}}}><i className="fa fa-hourglass-start" aria-hidden="true"></i></th>
                                                    : null}
												</tr>
											</thead>
											<tbody>
												{utasks.map((eachtask, index) => {
													return (
														<Draggable key={eachtask.id} draggableId={eachtask.id} index={index}>
															{(provided, snapshot) => (
																<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? "dragging-row" : ""}>
																	<td className="text-center" style={{fontSize:14, width: '15%'}}>
																		<TaskStatus 
																			taskObj={eachtask} 
																			setApiResponse={this.setApiResponse.bind(this)}
																			onTaskUpdate={this.onTaskUpdate.bind(this)} />
																	</td>
																	<td className="text-left" style={{width: '85%'}}>
																		<div style={{height:25, overflow:'hidden'}}>
																			<span style={SharedStyles.titleLink} className="cursor-pointer" onClick={this.toggleViewTaskModal.bind(this, eachtask.id)}>{eachtask.task.substring(0, 12)}{(eachtask.task.length > 12) ? '... ' : ' '}</span> 
																			<span className="small">({Util.convertTimeToReadableText(eachtask.timeEstimateInMinutes)})</span>
																		</div>
																	</td>
																	{isCurrentWeek && !adHocTimeTrackingIsOn ?
                                                                        <td className="text-center" style={{width: '10%', fontSize:14}}><i className="fa fa-hourglass-start color-primary cursor-pointer" title="Start Doing" aria-hidden="true" onClick={this.handleStartDoing.bind(this, eachtask)}></i></td>
                                                                    : null}
																</tr>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</tbody>
										</table>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						<div style={{width:'5%'}}>
							<table>
								<thead>
									<tr><th className="text-center" style={{width:50, height:44}}></th></tr>
								</thead>
								<tbody>
									{taskEvents.map((eventObj) => {
										
										let taskObject = _.find(utasks, {id: eventObj.taskID});
										let hasCalendarSchedules = taskObject && taskObject.calendarSchedules && taskObject.calendarSchedules.length > 0 ? true : false;

										return (
											<tr key={'schedule_' + eventObj.id}>
												<td className="text-center" style={{padding:'10px 3px'}}>
													<div style={{height: 26}}><DragableTasks event={eventObj} hasCalendarSchedules={hasCalendarSchedules} /></div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				:
					<div className="small">You don't have any tasks for the applied filter.</div>
				}
				{this.renderAddTaskModal()}
				{this.renderViewTaskInModal()}
				{this.renderEditTaskInModal()}
				{this.renderTaskDeleteModal()}
			</div>
		);
	}
}

Tasks.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	Tasks
);
