import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setLoading } from "../../actions/loadingActions.js";
import { resetCollaboratorData } from "../../actions/collaboratorActions";

var _ = require('lodash');

class CollaboratorForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				collaborator: "",
			},
			formErrors: {
				collaborator: null,
			},
			buttonDisabled: false,
		};
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			case "collaborator":
				if (!value) errorMsg = "Please enter Collaborator";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Collaborator submit
	handleCollaboratorSubmit(e) {
		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (Array.isArray(value)) {
					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			this.props.onSubmit(formCopy);
		}
	}

	render() {

		const { form, formErrors } = this.state;

		return (
			<form onSubmit={(e) => e.preventDefault()}>

				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Collaborator <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="collaborator" value={form['collaborator']} />
								<p className="text-danger">{formErrors['collaborator'] &&
									<span className="err">{formErrors['collaborator']}</span>}</p>
							</div>
						</div>
					</div>
				</div>

				<button type="button" onClick={this.handleCollaboratorSubmit.bind(this)} className="btn btn-primary mb-2">Add Collaborator</button>
				{
					!_.isEmpty(this.props.formApiResponse)
						?
						<p className={this.props.formApiResponse.className}><span>{this.props.formApiResponse.message}</span></p>
						: null
				}
			</form>
		);

	}
}

CollaboratorForm.propTypes = {
	resetCollaboratorData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetCollaboratorData, setLoading })(
	CollaboratorForm
);
