import {
	RESET_OUTCOME,
	GET_OUTCOME,
	GET_OUTCOMES,
	GET_OUTCOMES_WITH_POPULATED_DATA,
	DELETE_OUTCOME,
} from "../actions/types.js";

const initialState = {
	outcomes: [],
	outcomeswithpopulateddata: [],
	outcome: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_OUTCOME:
			return {
				...initialState,
			}

		case GET_OUTCOME:
			return {
				...state,
				outcome: action.payload,
			};

		case GET_OUTCOMES:
			return {
				...state,
				outcomes: action.payload,
			};

		case GET_OUTCOMES_WITH_POPULATED_DATA:
			return {
				...state,
				outcomeswithpopulateddata: action.payload,
			};

		case DELETE_OUTCOME:
			return {
				...state,
				outcomes: state.outcomes.filter(outcome => outcome.id !== action.payload)
			};

		default:
			return state;
	}
}
