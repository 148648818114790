import axios from "axios";

import Util from './Util';
var Globals = require('./Globals');

export default class API_Services {

	static httpGET(endPoint, callback) {

		var coreData = Util.getUser();

		var apiURL = Util.generateBaseAPIURLWithUserToken(coreData.userCoreProperties, endPoint);

		// console.log('apiURL: ' + apiURL);

		axios
			.get(apiURL)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				if (err.response && err.response.status && err.response.status == 402) {
					
					let navURL = '/subscription';

					if (err.response.data && err.response.data.module) {
						navURL += '?module=' + err.response.data.module;
					};
					return window.location = navURL;
				};
				
				callback(err, null);
			});
	}

	static httpGETAddParams(endPoint, params, callback) {

		var coreData = Util.getUser();

		var apiURL = Util.generateBaseAPIURLWithUserToken(coreData.userCoreProperties, endPoint);

		// console.log('apiURL: ' + apiURL);
		// console.log('params: ' + params);

		axios
			.get(apiURL + '&' + params)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				if (err.response && err.response.status && err.response.status == 402) {
					
					let navURL = '/subscription';

					if (err.response.data && err.response.data.module) {
						navURL += '?module=' + err.response.data.module;
					};
					return window.location = navURL;
				};

				callback(err, null);
			});
	}

	static httpPOST(endPoint, postObj, callback) {

		var coreData = Util.getUser();

		var apiURL = Util.generateBaseAPIURLWithUserToken(coreData.userCoreProperties, endPoint);

		postObj.client = Globals.APP_NAME;

		// console.log('apiURL: ' + apiURL);
		// console.log('postObj: ' + JSON.stringify(postObj));

		axios
			.post(apiURL, postObj)
			.then(res => {
				// console.log('res..', res);
				callback(null, res);
			})
			.catch(err => {
				
				if (err.response && err.response.status && err.response.status == 402) {
					
					/* If the subscription for the invoking API module is optional, don't forcefully take them to the subscription page incase if they haven't subscribed to that module. */

					if (postObj.isSubscriptionOptional === true) {
						// Don't do anything for now.
					}
					else {

						let navURL = '/subscription';

						if (err.response.data && err.response.data.module) {
							navURL += '?module=' + err.response.data.module;
						};
						return window.location = navURL;
					};
				};

				callback(err, null);
			});
	}
}