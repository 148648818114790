import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EnergyStateReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			results: this.props.results,
		}
		window.scrollTo(0, 0);
	}

	renderPercentileBar(energyState, index) {

		var styleColor = null;

		if (energyState.state == 'Balanced') {
			styleColor = '#acd076';
		}
		else if (energyState.state == 'Hyper') {
			styleColor = '#ee82a1';
		}
		else if (energyState.state == 'Inert') {
			styleColor = '#a9dde2';
		}
		else {
			return <div />;
		}

		// The avg.score is computed on a 10pt scale. Multiply it with 100 to compute bar width.
		let width = (parseFloat(energyState.average) * 10) + '%';

		return (
			<div key={'bar_' + index}>
				<div style={{ ...styles.percentileText, ...{ color: styleColor } }}>{energyState.state} - {energyState.average} / 10</div>
				<div style={{ ...styles.percentileBar, ...{ width: width, backgroundColor: styleColor } }}></div>
			</div>
		)
	}

	renderBalancedReport() {

		return (
			<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#acd076' } }}>
				<div style={styles.sectionTitle}>Balanced</div>
				<img src='/images/Balanced_State_Transparent.png' style={styles.valueIcon}></img>
				<div>
					<p style={styles.sectionText}>In this Energy State, life can be blissful and productive. You can have a greater level of clarity about things that matter and know what you want out of life. You know you’re going to reach your lofty objectives, if you haven’t already, and don’t need others to say nice things to make you feel good about yourself. The deeper the state of Balance, the more every moment is joyful and every person wonderful, and the more you want to share the richness of life with everyone and everything around you. Balanced is the Energy State in which we operate the best.</p>
				</div>
			</div>
		)
	}

	renderHyperReport() {

		return (
			<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#ee82a1' } }}>
				<div style={styles.sectionTitle}>Hyper</div>
				<img src='/images/Hyper_State_Transparent.png' style={styles.valueIcon}></img>
				<div>
					<p style={styles.sectionText}>In this Energy State, you can be wasteful, inefficient, and insensitive. You might be driven to pursue the things you do not have while losing sight of the things you already possess. You see your goals so clearly and approach them with such energy that if others get in your way, it can make you mad. You may feel that others are mostly either with you or against you. It can also become annoying or even frustrating when others bring up contrary or opposing ideas.</p>
				</div>
			</div>
		)
	}

	renderInertReport() {

		return (
			<div style={{ ...styles.reportContainer, ...{ backgroundColor: '#a9dde2' } }}>
				<div style={styles.sectionTitle}>Inert</div>
				<img src='/images/Inert_State_Transparent.png' style={styles.valueIcon}></img>
				<div>
					<p style={styles.sectionText}>In this Energy State, you can be weak and lack initiative. You might experience confusion and lack the ability to make confident decisions. You might feel pressures from life that block you from taking positive action. The more time you spend in the Inert Energy State, the more you feel stalled—unable or unwilling to progress—preventing you from living your life to its fullest potential.</p>
				</div>
			</div>
		)
	}

	render() {

		if (!this.state.results) return null;

		return (
			<div>
				<div className="text-center">
					<p><img src='/images/EnergyState_Icon.png' width={100}></img></p>
					<p style={{color: "#AECF7A", fontSize:20}} className="font-bold">Energy State</p>
				</div>

				<p className="text-blog">An ‘Energy State’ is the place from which you approach your life at any given point in time. Your Energy State is either Inert, Balanced, or Hyper.</p>

				<p className="text-blog">The idea is that we should strive to operate in a Balanced Energy State, as this is our most efficient, psychologically healthy state. Making decisions when in a balanced state will be the best we are capable of, as we are able to exhibit calm and control. By initiating actions from the Balanced Energy State, we can achieve significantly more and better results with significantly less effort. Whichever state we operate in affects all other aspects of our life.</p>

				<p className="text-blog">Let’s take a look at your Energy State Percentiles, and see where you currently operate.</p>

				{this.state.results.energyProfile ? 
					this.state.results.energyProfile.map((energyState, index) => {
						return this.renderPercentileBar(energyState, index)
            		})
				: null}

				<p style={{ marginTop: 15 }} className="text-blog">While the ideal is to always be in the Balanced energy state, for most of us the reality is that we flow from one state to another based on the situation and our tendencies. The %’s above reflect the amount of time you spend in the various Energy States. As such, our aim would be to go lower and lower on Hyper and Inert, and higher on Balanced. Let’s look at what the different Energy States specifically mean:</p>

				{this.renderBalancedReport()}
				{this.renderHyperReport()}
				{this.renderInertReport()}
			</div>
		)
	}
}

EnergyStateReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

const styles = {

	sectionHeading: {
		fontFamily: 'Pangram-Regular',
		color: '#FAB95E',
		textAlign: 'center',
		fontSize: '18px',
		marginBottom: '15px'
	},
	percentileText: {
		fontFamily: 'Pangram-Regular',
		fontSize: 16,
		paddingTop: 10,
		paddingBottom: 10,
	},
	percentileBar: {
		height: 20,
		borderRadius: 5,
		borderWidth: 1,
		backgroundColor: '#d49a89',
		borderColor: 'transparent'
	},
	sectionTitle: {
		fontFamily: 'Pangram-Bold',
		color: '#fff',
		fontSize: 17,
		paddingTop: 15,
		paddingBottom: 5
	},
	sectionText: {
		fontFamily: 'Pangram-Light',
		fontSize: 16,
		textAlign: 'center',
		color: '#fff',
		paddingTop: 5,
		marginBottom: 5,
		lineHeight: 1.75
	},
	reportContainer: {
		backgroundColor: '#FAB95E',
		padding: 10,
		marginTop: 20,
		borderRadius: 10,
		textAlign: 'center',
	},
	valueRecommendationContainer: {
		backgroundColor: '#fff',
		alignSelf: 'center',
		paddingVertical: 3,
		paddingHorizontal: 10,
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '3px 15px',
		width: 'fit-content',
		margin: 'auto',
		marginTop: 10
	},
	valueIcon: {
		resizeMode: 'contain',
		width: 45,
		height: 45,
		// marginRight: 10
	},
	valueRecommendationText: {
		fontFamily: 'Pangram-Regular',
		color: '#737373',
		fontSize: 15
	}
}

export default connect(mapStateToProps, { setLoading })(
	EnergyStateReport
);
