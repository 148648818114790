import {
	RESET_CURRENT_SPRINT,
	GET_CURRENT_SPRINT,
	GET_CURRENT_SPRINT_QUESTIONS,
	DELETE_CURRENT_SPRINT,
} from "../actions/types.js";

const initialState = {
	currentsprintresponse: {},
	currentsprintquestions: [],
};

export default function (state = initialState, action) {

	switch(action.type) {

		case RESET_CURRENT_SPRINT:
			return {
				...initialState,
			}

		case GET_CURRENT_SPRINT:
			return {
				...state,
				currentsprintresponse: action.payload,
			};

		case GET_CURRENT_SPRINT_QUESTIONS:
			return {
				...state,
				currentsprintquestions: action.payload,
			};

		case DELETE_CURRENT_SPRINT:
			return {
				...state,
				currentsprintresponse: state.currentsprintresponse.filter(currentsprint => currentsprint.id !== action.payload)
			};

		default:
			return state;
	}
}
