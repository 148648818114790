import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { setLoading } from "../../actions/loadingActions.js";
import { resetfocusAreaData } from "../../actions/focusAreaActions";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditFocusAreaForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusAreaTitle: '',
				notes: '',
			},
			formErrors: {
				focusAreaTitle: null,
				notes: null,
			},
			buttonDisabled: false,
			formApiResponse: {
				className: "",
				message: "",
			},
			notesEditorState: EditorState.createEmpty(),
		};
	}

	componentDidMount() {

		const { responses, focusAreaTitle, notes } = this.props.initialValues;

		if (responses && responses.length > 0) {

			const { form, formErrors } = this.state;

			// Generate/define question state
			var stateObj = {
				form: { ...form, focusAreaTitle: focusAreaTitle, },
				formErrors: { ...formErrors },
				dynamicqrendered: false,
			};

			responses.map((value, index) => {
				stateObj['form'][value.uniqueID] = value.response;
				stateObj['formErrors'][value.uniqueID] = "";
			});

			stateObj['notesEditorState'] = notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty();

			stateObj['dynamicqrendered'] = true;
			this.setState(stateObj);
		}
	}

	componentWillUnmount() {
		// Reset data so that table will re-render
		this.props.resetfocusAreaData();
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		if (name != "notes") {
			if (!value) errorMsg = "This field is mandatory";
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Focus Area submit
	handleFocusAreaSubmit(gotoParent, e) {
		e.preventDefault();

		return this.setFormApiResponse("The 'Focus Area' section has been moved under 'Guiding Stars' module.", true);

		const { form, formErrors, notesEditorState } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var focusAreaResponses = [];

			const { responses, id } = this.props.initialValues;

			_.forEach(responses, function (value, key) {
				focusAreaResponses.push({
					...value,
					response: form[value['uniqueID']],
				})
			});

			var postObj = {
				focusAreaTitle: this.state.form.focusAreaTitle,
				fa_responses: focusAreaResponses,
				recordID: id,
				notes: notesContent,
			}

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			API_Services.httpPOST(UrlConstants.URLS.editFocusAreaResponse, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {

					this.setFormApiResponse("Changes has been updated", false);
					if (gotoParent) {
						history.push('/focus-areas');
					}
					// console.log('response...', response);
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
				this.setState({
					buttonDisabled: false,
				});
			});

		}
	}

	render() {

		const { form, formErrors, dynamicqrendered, formApiResponse, notesEditorState } = this.state;

		const { responses } = this.props.initialValues;

		if (dynamicqrendered) {
			return (
				<form>

					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Focus Area Title <span className="text-danger">*</span></label>
								<div>
									<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" disabled="disabled" name="focusAreaTitle" value={form['focusAreaTitle']} />
									<p className="text-danger">{formErrors['focusAreaTitle'] &&
										<span className="err">{formErrors['focusAreaTitle']}</span>}</p>
								</div>
							</div>
						</div>
					</div>

					{
						responses.map((value, index) => {

							return <div className="row" key={index}>
								<div className="col-md-12">
									<div className="form-group">
										<label>{value.prompt} <span className="text-danger">*</span></label>
										<div>
											<textarea onChange={this.handleInputField.bind(this)} className="form-control" disabled="disabled" rows={value.defaultRowsToBeRendered} name={value.uniqueID} value={form[value.uniqueID]} >{form[value.uniqueID]}</textarea>
											<p className="text-danger">{formErrors[value.uniqueID] &&
												<span className="err">{formErrors[value.uniqueID]}</span>}</p>
										</div>
									</div>
								</div>
							</div>
						})
					}

					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Notes</label>
								<div className="htmlEditor">
									<Editor
										editorState={notesEditorState}
										toolbarClassName="toolbarClassName"
										wrapperClassName="wrapperClassName"
										editorClassName="editorClassName"
										onEditorStateChange={(editorState) =>
											this.setState({
												notesEditorState: editorState,
											})
										}
										readOnly={true}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* <div>
						<button type="submit" onClick={this.handleFocusAreaSubmit.bind(this, false)} className="btn btn-primary mb-2 mr-4">Save</button>
						<button type="submit" onClick={this.handleFocusAreaSubmit.bind(this, true)} className="btn btn-primary mb-2">Save & Close</button>
					</div> */}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</form>
			);
		} else {
			return (
				<div></div>
			);
		}

	}
}

EditFocusAreaForm.propTypes = {
	resetfocusAreaData: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { resetfocusAreaData, setLoading })(
	EditFocusAreaForm
);
