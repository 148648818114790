import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import ProductivityOutcomeMetricForm from "./ProductivityOutcomeMetricForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ProductivityOutcomeMetrics extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteModal: false,
			itemToRemove: null,
		};
	}

	componentDidMount() {

	}

	addPOutcomeMetricInList(newPOutcomeMetric) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// If this is the first outcome metric
		if (!_.has(productivityOutcomeClone, 'metricsAndTargets')) {
			productivityOutcomeClone['metricsAndTargets'] = [];
		}

		productivityOutcomeClone['metricsAndTargets'].push(newPOutcomeMetric);

		//Add outcome metric in project and ka level. Only copy title and description. 
		if (productivityOutcomeClone.projects.length > 0) {

			let outcomeMetricClone = _.cloneDeep(newPOutcomeMetric);

			outcomeMetricClone['isInheritedFromOutcome'] = true;

			// For each project
			productivityOutcomeClone.projects.forEach((eachProject) => {

				// If there is no project metric
				if (!_.has(eachProject, 'metricsAndTargets')) {
					eachProject['metricsAndTargets'] = [];
				}

				//Push outcome metrics in project
				eachProject['metricsAndTargets'].push({
					...outcomeMetricClone,
					targetScope: "",
					targetValue: "",
				});

				// For each project ka
				if (eachProject.keyActivities.length > 0) {

					eachProject.keyActivities.forEach((eachKA) => {

						// If there is no project metric
						if (!_.has(eachKA, 'metricsAndTargets')) {
							eachKA['metricsAndTargets'] = [];
						}

						//Push outcome metrics in ka
						eachKA['metricsAndTargets'].push({
							...outcomeMetricClone,
							targetScope: "",
							targetValue: "",
						});
					})
				}
			})
		}

		this.props.updateProductivityOutcome(productivityOutcomeClone);
		this.toggleAddPOutcomeMetricModal();
	}

	toggleAddPOutcomeMetricModal() {

		if (this.state.addPOutcomeMetricModal) {
			this.setState({
				addPOutcomeMetricModal: false,
			});
		}
		else {
			this.setState({
				addPOutcomeMetricModal: true,
			});
		}
	}

	renderAddPOutcomeMetricInModal() {

		return (
			<div>
				<Modal isOpen={this.state.addPOutcomeMetricModal} toggle={this.toggleAddPOutcomeMetricModal.bind(this)}>
					<ModalHeader toggle={this.toggleAddPOutcomeMetricModal.bind(this)}>Add Outcome Metric</ModalHeader>
					<ModalBody>
						<ProductivityOutcomeMetricForm
							addPOutcomeMetricInList={this.addPOutcomeMetricInList.bind(this)}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	// update the list with the edited data
	updateEditedPOutcomeMetricInList(data) {

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		// Find item index using _.findIndex
		var index = _.findIndex(productivityOutcomeClone.metricsAndTargets, { id: data.id });

		// Replace item at index using native splice
		productivityOutcomeClone.metricsAndTargets.splice(index, 1, data);

		//Add outcome metric in project and ka level
		if (productivityOutcomeClone.projects.length > 0) {

			let outcomeMetricClone = _.cloneDeep(data);

			outcomeMetricClone['isInheritedFromOutcome'] = true;

			// For each project
			productivityOutcomeClone.projects.forEach((eachProject) => {

				// Find item index using _.findIndex
				var index = _.findIndex(eachProject.metricsAndTargets, { id: outcomeMetricClone.id });

				// Replace item at index using native splice
				eachProject.metricsAndTargets.splice(index, 1, {
					...outcomeMetricClone,
					targetScope: "",
					targetValue: "",
				});

				// For each project ka
				if (eachProject.keyActivities.length > 0) {

					eachProject.keyActivities.forEach((eachKA) => {

						// Find item index using _.findIndex
						var index = _.findIndex(eachKA.metricsAndTargets, { id: outcomeMetricClone.id });

						// Replace item at index using native splice
						eachKA.metricsAndTargets.splice(index, 1, {
							...outcomeMetricClone,
							targetScope: "",
							targetValue: "",
						});
					})
				}
			})
		}

		this.props.updateProductivityOutcome(productivityOutcomeClone);
		this.toggleEditPOutcomeMetricModal(this.state.itemToEdit);
	}

	toggleEditPOutcomeMetricModal(row) {

		if (this.state.editPOutcomeMetricModal) {
			this.setState({
				editPOutcomeMetricModal: false,
				itemToEdit: null,
			});
		}
		else {
			this.setState({
				editPOutcomeMetricModal: true,
				itemToEdit: row,
			});
		}
	}

	renderEditPOutcomeMetricInModal() {

		return (
			<div>
				<Modal isOpen={this.state.editPOutcomeMetricModal} toggle={this.toggleEditPOutcomeMetricModal.bind(this)}>
					<ModalHeader toggle={this.toggleEditPOutcomeMetricModal.bind(this)}>Edit Outcome Metric</ModalHeader>
					<ModalBody>
						<ProductivityOutcomeMetricForm
							updateEditedPOutcomeMetricInList={this.updateEditedPOutcomeMetricInList.bind(this)}
							pOutcomeMetricObj={this.state.itemToEdit}
							productivityOutcomeId={this.props.productivityOutcomeId} />
					</ModalBody>
				</Modal>
			</div>
		)
	}

	// Handler for removing productivity outcome Metric
	handlePOutcomeMetricDelete() {

		let { itemToRemove } = this.state;

		let productivityOutcomeClone = _.cloneDeep(this.props.productivityOutcome);

		if (itemToRemove && itemToRemove.id) {

			productivityOutcomeClone.metricsAndTargets.forEach((eachProductivityOutcomeMeric, index) => {

				if (eachProductivityOutcomeMeric.id == itemToRemove.id) {
					productivityOutcomeClone.metricsAndTargets.splice(index, 1);
				}
			});

			// Soft delete
			//If outcome metric is removed then inherited metrics in project and ka level should also be removed.
			if (productivityOutcomeClone.projects.length > 0) {

				// For each project
				productivityOutcomeClone.projects.forEach((eachProject) => {

					// Remove inherited outcome metric from project metrics
					if (_.has(eachProject, 'metricsAndTargets') && eachProject.metricsAndTargets.length > 0) {
						
						eachProject.metricsAndTargets.forEach((eachPMetric, index) => {

							if(eachPMetric['isInheritedFromOutcome'] && eachPMetric['id'] == itemToRemove['id']) {
								
								let data = {...eachPMetric};

								data = {
									...data,
									isDeleted: true,
								}

								eachProject.metricsAndTargets.splice(index, 1, data);
							}
						})
					}

					// Remove inherited outcome metric from project ka metrics
					if (eachProject.keyActivities.length > 0) {

						eachProject.keyActivities.forEach((eachKA) => {

							if (_.has(eachKA, 'metricsAndTargets') && eachKA.metricsAndTargets.length > 0) {
								
								eachKA.metricsAndTargets.forEach((eachKAMetric, index) => {

									if(eachKAMetric['isInheritedFromOutcome'] && eachKAMetric['id'] == itemToRemove['id']) {

										let data = {...eachKAMetric};

										data = {
											...data,
											isDeleted: true,
										}

										eachKA.metricsAndTargets.splice(index, 1, data);
									}
								})
							}
						})
					}
				})
			}

			this.props.updateProductivityOutcome(productivityOutcomeClone);
			this.toggleDeletePOutcomeMetricModal();
		}
	}

	toggleDeletePOutcomeMetricModal(rowId) {

		if (this.state.deletePOutcomeMetricModal) {
			this.setState({
				deletePOutcomeMetricModal: false,
				itemToRemove: null,
			});
		}
		else {
			this.setState({
				deletePOutcomeMetricModal: true,
				itemToRemove: rowId,
			});
		}
	}

	renderDeletePOutcomeMetricInModal() {

		var tbObj = {};

		if (this.state.itemToRemove && this.state.itemToRemove.id) {
			tbObj = _.find(this.props.productivityOutcome.metricsAndTargets, { id: this.state.itemToRemove.id });
		}

		var tbTitle = tbObj ? tbObj.title : '';

		return (
			<div>
				<Modal isOpen={this.state.deletePOutcomeMetricModal} toggle={this.toggleDeletePOutcomeMetricModal.bind(this)}>
					<ModalHeader toggle={this.toggleDeletePOutcomeMetricModal.bind(this)}>Delete Outcome Metric</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to delete the Outcome Metric <span className="text-warning">{tbTitle}?</span></p>
						{/* <div><small>* The above button will store your changes locally upon clicking it. You need to click save button from the homepage to update the database.</small></div> */}
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Delete" onClick={this.handlePOutcomeMetricDelete.bind(this)}>Delete</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleDeletePOutcomeMetricModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	// Renders metrics & targets in table
	renderMetricsTable() {

		const { metricsAndTargets } = this.props.productivityOutcome;

		if (_.isEmpty(metricsAndTargets)) {
			return (
				<div className="small text-info">No Outcome Metrics added.</div>
			);
		};

		return (
			<div>
				<div className="App" style={{ marginTop: 20 }}>
					<header className="App-header table-responsive">
						<table className="table-curved">
							<thead>
								<tr>
									<th className="text-center font-weight-normal" style={{ width: 50 }}>#</th>
									<th className="text-left font-weight-normal" style={{ width: 500 }}>Title</th>
									<th className="text-center font-weight-normal" style={{ width: 200 }}>Target</th>
									<th className="text-center font-weight-normal" style={{ width: 200 }}>Unit</th>
									<th className="text-center font-weight-normal" style={{ width: 100 }} colSpan={2}>Actions</th>
								</tr>
							</thead>
							<tbody>
								{metricsAndTargets.map((value, index) => {
									return (
										<tr key={value.id} className="text-blog">
											<td className="text-center" style={{ width: 50 }}>{index + 1}</td>
											<td className="text-left" style={{ width: 500 }}>{value.title}</td>
											<td className="text-center" style={{ width: 200 }}>{value.targetValue ? value.targetValue : 'not set'}</td>
											<td className="text-center" style={{ width: 200 }}>{value.targetScope ? _.capitalize(value.targetScope) : 'not set'}</td>
											<td className="text-center" style={{ width: 50 }}><button type="button" className="table-action-button" title="Edit" onClick={this.toggleEditPOutcomeMetricModal.bind(this, value)}><i className="fa fa-pencil color-primary" aria-hidden="true"></i></button></td>
											<td className="text-center" style={{ width: 50 }}><button className="table-action-button" title="Delete" onClick={this.toggleDeletePOutcomeMetricModal.bind(this, value)}><i className="fa fa-times text-danger" aria-hidden="true"></i></button></td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</header>
				</div>
			</div>
		);
	}

	render() {

		return (
			<div>
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="section-container">
					<div className="row">
						<div className="col-6"><label>Outcome Metrics</label></div>
						<div className="col-6 text-right">
							<button type="button" className="btn btn-primary btn-xs ml-3" onClick={this.toggleAddPOutcomeMetricModal.bind(this)}>
								<i className="fa fa-plus" aria-hidden="true"></i> Outcome Metric
							</button>
						</div>
					</div>
					{this.renderMetricsTable()}
					{this.renderAddPOutcomeMetricInModal()}
					{this.renderEditPOutcomeMetricInModal()}
					{this.renderDeletePOutcomeMetricInModal()}
				</div>
			</div>
		);
	}
}

ProductivityOutcomeMetrics.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityOutcomeMetrics
);
