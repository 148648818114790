import Cookies from 'universal-cookie';
import axios from "axios";
import React from "react";
import moment from "moment";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const cookies = new Cookies();

var _ = require('lodash');
var Globals = require('./Globals');

export default class Util {

	static setAuthToken(token) {
		if (token) {
			return axios.defaults.headers.common["Authorization"] = token;
		} else {
			return delete axios.defaults.headers.common["Authorization"];
		}
	};

	// Check mime type
	static checkMimeType(files) {
		// console.log(files);
		//define message container
		let err = [];

		// list allow mime type
		const types = ['xls', 'xlsx', 'officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

		// loop access array
		for (var x = 0; x < files.length; x++) {

			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {

				// create error message and assign to container   
				err[x] = files[x].name + ' file is not in supported format\n';
			}
		};

		return err;
	}

	static isEmpty(value) {
		return (
			value === undefined ||
			value === null ||
			(typeof value === "object" && Object.keys(value).length === 0) ||
			(typeof value === "string" && value.trim().length === 0)
		);
	};


	static cloneArray(baseArray) {
		if (null == baseArray || "object" != typeof baseArray) return baseArray;
		var copy = baseArray.constructor();

		for (var attr in baseArray) {
			if (baseArray.hasOwnProperty(attr)) copy[attr] = this.cloneArray(baseArray[attr]);
		}

		return copy;
	}

	static getUser() {

		return JSON.parse(localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA));
	}

	static generateBaseAPIURLWithUserToken(userCoreProperties, endpoint) {
		//console.log("generateBaseAPIURLWithUserToken:: userCoreProperties: ", userCoreProperties);
		//console.log("generateBaseAPIURLWithUserToken:: endpoint: ", endpoint);
		// var apiURL = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;
		var apiURL = endpoint;
		apiURL += '?userID=' + userCoreProperties.userID;
		apiURL += '&accessToken=' + userCoreProperties.accessToken;
		apiURL += '&platform=' + 'web';
		apiURL += '&client=' + Globals.APP_NAME;

		return apiURL;
	}

	static reportError(error) {

		var apiURL = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;
		apiURL += 'Miscellaneous/reportError';

		console.log('apiURL: ' + apiURL);

		fetch(apiURL, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(error),
		})
			.then(response => {
				console.log("response: " + response);
			})
			.catch(error => {
				console.log('Something bad happened ' + error);
			});
	}

	static validateUserName(username) {

		if (typeof username != 'string' || username.match(/[^a-z0-9 ]/i))
			return false;
		else
			return true;
	}

	static validateName(username) {
		if (typeof username != 'string' || !username.match(/^([a-z]+[,.]?[ ]?|[a-z]+['-]?)+$/i))
			return false;
		else
			return true;
	}

	static validateEmail(email) {
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	static validateNumber(input) {
		var regex = /^[0-9\b]+$/;
		if (input !== '' && regex.test(input)) {
			return true;
		} else {
			return false;
		}
	}

	static validateCurrency(input) {
		// var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/; // will allow comma
		var regex = /^[1-9]\d*((\.\d{0,2})?)$/;
		if (input !== '' && regex.test(input)) {
			return true;
		} else {
			return false;
		}
	}

	static validatePassword(pass) {
		var regex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
		return regex.test(pass);
	}

	static setCookie(cName, cValue) {
		cookies.set(cName, cValue, { Domain: '*.triumphhq.com', path: '/' });
	}

	static getCookie(cName) {
		return cookies.get(cName);
	}

	static removeCookie(cName) {
		cookies.remove(cName)
	}

	static lessThanOneDay(date) {
		const DAY = 1000 * 60 * 60 * 24;
		const aDayAgo = Date.now() - DAY;

		return date > aDayAgo;
	}

	static getQueryStringParams(query) {
		return query
			? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split('&')
				.reduce((params, param) => {
					let [key, value] = param.split('=');
					params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
					return params;
				}, {}
				)
			: {}
	};

	static convertTimeToReadableText(timeInMinutes, appendString = null) {

		timeInMinutes = timeInMinutes ? timeInMinutes : 0;

		let timeText = timeInMinutes + ' min(s)'; // Default
		let isNegative = timeInMinutes < 0 ? true : false;
		
		if (isNegative === true) {
			timeInMinutes = timeInMinutes * -1; // If the value is negative, convert it to positive for diff calculation.
		};

		let hoursInTime = parseInt(timeInMinutes / 60);
		let minutesInTime = parseInt(timeInMinutes % 60);

		if (hoursInTime > 0 && minutesInTime > 0) {
			timeText = hoursInTime + ' hr(s) ' + minutesInTime + ' min(s)';
		}
		else if (hoursInTime > 0) {
			timeText = hoursInTime + ' hr(s)';
		}
		else if (minutesInTime > 0) {
			timeText = minutesInTime + ' min(s)';
		};

		if (appendString) {
			timeText = timeText + appendString;
		};

		if (isNegative) {
			timeText = "- " + timeText; // Assign '-' symbol for negative values.
		};

		return timeText;
	}

	static isBetween = (date, min, max) => (date.getTime() >= min.getTime() && date.getTime() <= max.getTime())

	static generateGUID() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

    static generateUID() {
        return this.generateGUID() + this.generateGUID() + '-' + Date.now() + '-' + this.generateGUID() + '-' + Date.now();
    }

	static generateObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

	/*
     - For Non-Weekly Time Blocks, we need to calculate the approximate time based on the Interval and Duration. 
     - So for Monthly events that are 1 hour long, we would use ¼ hrs (assuming 4 weeks in a month). 
     - For every_x_months, it would be (4 * recurringInterval) weeks. 
     - For annual time blocks, it would be 52 weeks. 
     - And for “every other week,” it would be 2 weeks.
    */
	static computeWeeklyTimeDurationForANonWeeklyTimeBlock(durationInMinutes, interval, recurringInterval = 1) {

		if (!interval || !durationInMinutes) return 0;

		let durationInMinutesPerWeek = 0;

		if (interval == 'every_other_week') {
			durationInMinutesPerWeek = durationInMinutes / 2;
		}
		else if (interval == 'every_x_weeks') {
			durationInMinutesPerWeek = durationInMinutes / recurringInterval;
		}
		else if (interval == 'monthly') {
			durationInMinutesPerWeek = durationInMinutes / 4;
		}
		else if (interval == 'every_x_months') {
			durationInMinutesPerWeek = durationInMinutes / (4 * recurringInterval);
		}
		else if (interval == 'every_x_years') {
			durationInMinutesPerWeek = durationInMinutes / (52 * recurringInterval);
		}
		else if (interval == 'annually') {
			durationInMinutesPerWeek = durationInMinutes / 52;
		};

		return parseInt(durationInMinutesPerWeek);
	}; // End of computeWeeklyTimeDurationForANonWeeklyTimeBlock

	static computeWeeklyTimeDurationForADailyTimeBlock(durationInMinutes, interval, recurringInterval = 1) {

		if (!interval || !durationInMinutes) return 0;

		let durationInMinutesPerWeek = 0;

		if (interval === 'daily' || recurringInterval === 1) {
			durationInMinutesPerWeek = durationInMinutes * 7;
		}
		else if (interval === 'every_x_days') {
			durationInMinutesPerWeek = (Math.ceil(7/recurringInterval)) * durationInMinutes;
		};

		return parseInt(durationInMinutesPerWeek);

	}; // End of computeWeeklyTimeDurationForADailyTimeBlock

	static sortMetricsBasedOnHierarchy(metricsAndTargets) {

		return _.sortBy(metricsAndTargets || [], /* Expected order : 1. keyactivity metric, 2. project metric, 3. outcome metric */
			[
				(item) => item.isInheritedFromOutcome || '', /* This will move all the event metrics that are inherited from the outcome to the last */
				(item) => item.isInheritedFromProject || '', /* This will move all the event metrics that are inherited from the project just before the outcome metrics */
				(item) => item.id || '', /* This will move all the event(KA) metrics to the top */
			]
		);
	}

	static focusAreaDropdownOptions(focusAreas) {

		let focusAreasOptions = [];

		focusAreas.forEach((value) => {

			if(value['type'] == "system-default") {

				focusAreasOptions.push({
					label: value['focusAreaTitle'],
					value: value['id'],
					type: "system-default",
				});
			}
			else if(value['type'] == "custom") {

				focusAreasOptions.push({
					label: value['focusAreaTitle'] + ( value['timescale'] ? " ("+ value['timescale'] +")" : "" ),
					value: value['id'],
					type: "custom",
				});
			}
		});

		return _.orderBy(focusAreasOptions, ['type'], ['desc']);
	}

	static focusAreaTitleWithTimeScaleSuffix(focusareaObj) {

		let focusAreaTitle = "";

		if (focusareaObj['type'] == "system-default") {
			focusAreaTitle = focusareaObj['focusAreaTitle'];
		}
		else if(focusareaObj['type'] == "custom") {
			focusAreaTitle = <div>{focusareaObj['focusAreaTitle']} <small>{focusareaObj['timescale'] ? '(' + focusareaObj['timescale'] + ')' : null}</small></div>;
		}
		return focusAreaTitle;
	}

	static getDateForSelectedXthWeekDayinMonth = (date, interval, isoWeekDay) => {

		var startDate = moment(date).clone().startOf('month');
		var firstWeekDate = moment(date).clone().startOf('month').day(isoWeekDay);

		let updatedIsoWeekDay = isoWeekDay + (7 * (interval - 1));

		var computedStartDate = startDate.clone().day(updatedIsoWeekDay);

		if ((computedStartDate.clone().month() < startDate.clone().month() && computedStartDate.clone().year() == startDate.clone().year()) || (firstWeekDate.clone().month() < startDate.clone().month() && computedStartDate.clone().month() == startDate.clone().month())) {
			computedStartDate.add(7, 'days');
			if (computedStartDate.clone().month() > startDate.clone().month() || (startDate.clone().year() < computedStartDate.clone().year()) && (computedStartDate.clone().month() < startDate.clone().month())) {
				computedStartDate.add(-7, 'days');
			};
		}
		else if (computedStartDate.clone().month() >= startDate.clone().month() && firstWeekDate.clone().year() < startDate.clone().year()) {
			computedStartDate.add(7, 'days');
			if (computedStartDate.clone().month() > startDate.clone().month() && computedStartDate.clone().year() == startDate.clone().year()) {
				computedStartDate.add(-7, 'days');
			};
		}
		else if ((computedStartDate.clone().month() > startDate.clone().month()) ||	(startDate.clone().year() < computedStartDate.clone().year()) && (computedStartDate.clone().month() < startDate.clone().month())) {
			computedStartDate.add(-7, 'days');
		}

		return computedStartDate;
	}

	static convertHtmlToRawDraftJS = (htmlContent) => {

		/* Below will convert the html to raw content */
		const blocksFromHtml = htmlToDraft(htmlContent);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentStateBlockArray = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorStateContent = EditorState.createWithContent(contentStateBlockArray);
		const rawContent = editorStateContent && editorStateContent.getCurrentContent().hasText() ? convertToRaw(editorStateContent.getCurrentContent()): null;

		return rawContent;
	}

	static getEventTitle = (eventObj) => {

		let eventTitle = eventObj.timeBlockTitle; // Default

		if (eventObj.updatedAtInGC) { // Once the event got synced to GC, use the GC event title
			eventTitle = eventObj.title;
		}
		else {
			if (eventObj.outcomeAbbr && eventObj.keyActivityTitle) {
				eventTitle += ` ( ${eventObj.outcomeAbbr}:${eventObj.keyActivityTitle})`;
			};
		};
		return eventTitle;
	}

	/*
		- The 'RRule' treats 0 as Monday, 1 as Tuesday,... and 6 as Sunday
		- Whereas ISOWeek will be represented in 1 for Monday, 2 for Tuesday,... and 7 for Sunday
		- Since the recurringIntervalWeekDaysID array will be in rrule format, the following conversions are necessary
	*/
	static convertRRuleWeekDaysToISOWeekDays = (input) => {
        
		if (Array.isArray(input)) {
			return input.sort().map(wkday => wkday + 1);
		}
		else if (!isNaN(input)) {
			return input + 1;
		}
		else return null;
	} // End of convertRRuleWeekDaysToISOWeekDays
	
	static convertISOWeekDaysToRRuleWeekDays = (input) => {
			
		if (Array.isArray(input)) {
			return input.sort().map(wkday => wkday - 1);
		}
		else if (!isNaN(input)) {
			return input - 1;
		}
		else return null;
	} // End of convertISOWeekDaysToRRuleWeekDays
}