import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { history } from '../../utils/history';
import NumberFormat from 'react-number-format';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';

var moment = require('moment');
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class PlanningModules extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPlanningModule: {},
			formErrors: {},
			showResetConfirmationModal: false
		};
	}

	componentDidMount() {

		this.setState({
			currentPlanningModule: this.props.currentPlanningModule,
		});
		this.setStickyToolBar();
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	performOperation(operandone, operandtwo, operation) {

		var total = 0;

		switch (operation) {

			case "division":
				total = parseFloat(operandone) / parseFloat(operandtwo);
				break;

			case "multiplication":
				total = parseFloat(operandone) * parseFloat(operandtwo);
				break;

			case "subtraction":
				total = parseFloat(operandone) - parseFloat(operandtwo);
				break;

			default:
				break;
		}

		return total;
	}

	/**
	 * Sample items
	 	[
			{
				"itemID": 1,
				"item": "Average Sales Price",
				"requiresUserInput": true,
				"response": "750000",
				"unit": "$"
			},
			{
				"itemID": 2,
				"item": "% Commission",
				"requiresUserInput": true,
				"response": "3",
				"unit": "%"
			},
			{
				"itemID": 3,
				"item": "Total Commission",
				"requiresUserInput": false,
				"unit": "$",
				"computationAlgorithm": [
					{
						"operation": "multiplication",
						"operands": [
							{
								"ref": "itemID",
								"value": 1
							},
							{
								"ref": "itemID",
								"value": 2
							}
						]
					},
					{
						"operation": "division",
						"operands": [
							{
								"ref": "algorithmResultForTheIndex",
								"value": 0
							},
							{
								"ref": "number",
								"value": 100
							}
						]
					}
				]
			},
		]
	 */
	calculateData() {

		var itemsCopy = _.cloneDeep(this.state.currentPlanningModule.items);

		if (itemsCopy && itemsCopy.length > 0) {

			itemsCopy.forEach((eachItem) => {

				if (eachItem && eachItem.computationAlgorithm && eachItem.computationAlgorithm.length > 0) {

					/**
					 * This will hold the total of each computationAlgorithm of current item. Index 1 will hold the total of first computation and so on.
					 * Example:
					 * computationAlgorithm = [
					 * 	25
					 * ]
					 * for below first computation.
					 * {
						"operation": "multiplication",
						"operands": [
							{
							"ref": "itemID",
							"value": 1
							},
							{
							"ref": "itemID",
							"value": 2
							}
						]
						}
						*/
					let subTotalByIndex = [];

					// Update computation response if all computatation are completed
					let actualComputationCountForCurrentItem = 0;
					let completedcomputationCountForCurrentItem = 0;

					// Loop computationAlgorithm
					eachItem.computationAlgorithm.forEach((eachComputation, computationIndex) => {

						let total = '';

						// Loop operands do the required operation
						eachComputation.operands.forEach((eachOperand, operandIndex) => {

							actualComputationCountForCurrentItem = actualComputationCountForCurrentItem + 1;

							// If itemID
							if (eachOperand.ref == "itemID") {

								var item = _.find(itemsCopy, { itemID: eachOperand['value'] });

								// Sometime user will enter data in the middle. So required response will not be there. So do a condition check.
								if (item['response']) {

									/**
									 * - If total empty then its first operand, else it's nth operand
									 * - As per the sample at the first total will be 750000
									 * - Then 750000 * 3
									 * - So subTotalByIndex[0] = 2250000
									 */
									if (total == '') {
										total = parseFloat(item['response']);
									} else {

										total = this.performOperation(total, item['response'], eachComputation['operation']);
									}
									subTotalByIndex[computationIndex] = total;
									completedcomputationCountForCurrentItem = completedcomputationCountForCurrentItem + 1;
								}

							}

							// If algorithmResultForTheIndex
							if (eachOperand.ref == "algorithmResultForTheIndex") {

								/**
								 * - If there is no subTotalByIndex[computationIndex] then below will fail. Rare case. Just in case.
								 * - As per the sample at the first total will be 2250000
								 */
								if (subTotalByIndex[eachOperand['value']]) {

									total = subTotalByIndex[eachOperand['value']];
									subTotalByIndex[computationIndex] = total;
									completedcomputationCountForCurrentItem = completedcomputationCountForCurrentItem + 1;
								}
							}

							// If number
							if (eachOperand.ref == "number") {

								/**
								 * - If there is no subTotalByIndex[computationIndex] then below will fail. Rare case. Just in case.
								 * - As per the sample
								 * - 2250000 / 100 = 22500
								 */
								if (subTotalByIndex[computationIndex] && total != '') {

									total = this.performOperation(total, eachOperand.value, eachComputation['operation']);
									subTotalByIndex[computationIndex] = total;
									completedcomputationCountForCurrentItem = completedcomputationCountForCurrentItem + 1;
								}
							}

							/**
							 * - If last operand. Then current total will be the grandone. So append to current item response. So as per example below object response is 22500.
							 * Always round the value
							 * {
							 * "itemID": 3,
								"item": "Total Commission",
								"requiresUserInput": false,
								"unit": "$",
								"response": "22500",
								}
								*/
							if (eachComputation.operands.length == operandIndex + 1) {

								// Sometime user will enter data in the middle. So required total will not be there. So do a condition check.
								if (total != '' && actualComputationCountForCurrentItem == completedcomputationCountForCurrentItem) {
									eachItem['response'] = Math.round(total);
								}
							}
						})

					})
				}
			})
		}

		let currentPlanningModuleCopy = _.cloneDeep(this.state.currentPlanningModule);
		currentPlanningModuleCopy['items'] = itemsCopy;

		this.setState({
			currentPlanningModule: currentPlanningModuleCopy,
		});

	}

	// Handler for input field change event
	handleInputField(item, name, valueObj) {

		let value = valueObj['value'];

		const { currentPlanningModule, formErrors, showResetConfirmationModal } = this.state;

		let currentPlanningModuleCopy = _.cloneDeep(currentPlanningModule);

		let itemCopy = _.cloneDeep(item);
		itemCopy['response'] = value;

		// Find item index using _.findIndex
		var index = _.findIndex(currentPlanningModuleCopy['items'], item);

		// Replace item at index using native splice
		currentPlanningModuleCopy['items'].splice(index, 1, itemCopy);

		this.setState({
			currentPlanningModule: currentPlanningModuleCopy,
		}, () => {

			if (showResetConfirmationModal) return; /* If the value of an input field is changed due to the RESET action, don't perform any validations. */

			const errorMsg = this.validateField(name, value, itemCopy);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };

			if(!errorMsg) {
				this.calculateData();
			}

			this.setState({
				formErrors: formErrorsObj,
			});
		});

	}

	// Validate Field
	validateField(name, value, wholeItem) {

		var errorMsg = null;

		// Skip validation for calucated inputs
		if(wholeItem['requiresUserInput'] == false) return errorMsg;

		if (!value) errorMsg = "This field is mandatory";

		if (wholeItem['unit'] == "$") {
			if (!Util.validateCurrency(value)) errorMsg = "Should be a valid Amount";
		}

		if (wholeItem['unit'] == "%") {
			if (!Util.validateCurrency(value)) errorMsg = "Should be a valid Number";
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	renderItemsRow() {

		let { formErrors, currentPlanningModule } = this.state;

		if (_.isEmpty(currentPlanningModule)) {
			return;
		}

		return currentPlanningModule.items.map((eachItem, itemInd) => {

			let inputDisabled = eachItem.requiresUserInput ? false : true;

			return (
				<tr key={eachItem.itemID} className="row row-section-container">
					<td className="col-1 d-flex align-items-center justify-content-center">{itemInd + 1}</td>
					<td className="col-7 d-flex align-items-center">{eachItem.item}</td>
					<td className="col-4">
						<div className="input-group">
							{
								eachItem.unit
									?
									<div className="input-group-prepend">
										<div className="input-group-text">{eachItem.unit}</div>
									</div>
									:
									null
							}
							<NumberFormat 
								type="text"
								className="form-control"
								value={eachItem.response ? eachItem.response : ''}
								onValueChange={this.handleInputField.bind(this, eachItem, eachItem['itemID'])}
								disabled={inputDisabled ? "disabled" : false}
								thousandSeparator={true}
								name={eachItem.itemID}
							 />
							{/* <input type="text" onChange={this.handleInputField.bind(this, eachItem)} className="form-control" disabled={inputDisabled ? "disabled" : false} name={eachItem.itemID} value={eachItem.response} /> */}
						</div>
						{formErrors[eachItem.itemID] && <div className="text-danger small mt-1">
							<span className="err">{formErrors[eachItem.itemID]}</span></div>
						}
					</td>
				</tr>
			)
		})
	}

	handleFormSubmit(e) {
		e.preventDefault();

		const { formErrors, currentPlanningModule } = this.state;

		// validated form
		const errorObj = {};
		currentPlanningModule.items.forEach((eachItem) => {

			const msg = this.validateField(eachItem['itemID'], eachItem['response'], eachItem);
			if (msg) errorObj[eachItem['itemID']] = msg;

		})

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var postObj = {
				planningModuleResponses: currentPlanningModule
			};

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			API_Services.httpPOST(UrlConstants.URLS.savePlanningModuleResponse, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {

					if (response.data.status) {
						this.setFormApiResponse("Record have been saved successfully!", false);
						
						this.setState({
							currentPlanningModule: response.data.data,
						})
					} else {
						this.setFormApiResponse(response.data.message, true);
					}
					// console.log('response...', response);
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
				this.setState({
					buttonDisabled: false,
				});
			});
		}
	}

	setStickyToolBar() {

		const header = document.getElementById("planning-module-toolbar");

		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 125) header.classList.add("sticky-toolbar");
			else header.classList.remove("sticky-toolbar");
		});
	}

	handleReset() {

		const { currentPlanningModule } = this.state;

		var postObj = {
			recordID: currentPlanningModule.id,
			refID: currentPlanningModule.refID
		};

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.resetPlanningModuleResponse, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
				};
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {
					this.setFormApiResponse("Record have been reseted successfully!", false);
					this.setState({
						currentPlanningModule: response.data.data,
						formErrors: {}
					},
					() => {
						/* 
						 - Since the user response will be nullified during this process, induce some time delay until system completion validation with the updated values.
						 - If the value of an input field is changed due to the RESET action, don't perform any validations. 
						*/
						setTimeout(() => {
							this.toggleResetConfirmationModal(); 
						}, 100);
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				};
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};
			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	toggleResetConfirmationModal() {

		this.setState({
			showResetConfirmationModal: !this.state.showResetConfirmationModal
		});
	}

	renderResetConfirmationModal() {

		let {currentPlanningModule, showResetConfirmationModal} = this.state;

		if (!showResetConfirmationModal || !currentPlanningModule || (currentPlanningModule && !currentPlanningModule.id)) {
			return null;
		};

		return (
			<div>
				<Modal isOpen={this.state.showResetConfirmationModal} toggle={this.toggleResetConfirmationModal.bind(this)}>
					<ModalHeader toggle={this.toggleResetConfirmationModal.bind(this)}>Reset Module</ModalHeader>
					<ModalBody>
						<p>Are you sure you want to reset the planning module <span className="text-warning">{currentPlanningModule.title}?</span></p>
						<div><small>* This action will reset this module with its active template data and all your saved responses will be erased.</small></div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" title="Reset" onClick={this.handleReset.bind(this)}>Reset</Button>{' '}
						<Button color="secondary" title="Cancel" onClick={this.toggleResetConfirmationModal.bind(this)}>Cancel</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}

	render() {

		const { form, formErrors, currentPlanningModule, buttonDisabled } = this.state;

		if (currentPlanningModule && currentPlanningModule.length == 0) {
			return (<div />);
		}

		return (
			<div className="real-estate-income-planner mt-3">
				<div className="text-right mb-3" id="planning-module-toolbar">
					<button type="submit" disabled={buttonDisabled ? "disabled" : ""} onClick={this.handleFormSubmit.bind(this)} className="btn btn-primary btn-sm">Save Data</button>
					{currentPlanningModule.ownerType == 'user' && currentPlanningModule.ownerID ? // Allow users to RESET only the planning modules to which they have responded already.
						<button type="submit" disabled={buttonDisabled ? "disabled" : ""} onClick={this.toggleResetConfirmationModal.bind(this)} className="btn btn-primary btn-sm ml-3">Reset Module</button>
					: null}
				</div>
				<div>
					<form onSubmit={(e) => e.preventDefault()}>
						<table className="table table-curved w-100 mt-2">
							<thead>
								<tr className="row row-section-container">
									<th className="col-1 text-center">#</th>
									<th className="col-7">ITEM</th>
									<th className="col-4">VALUE</th>
								</tr>
							</thead>
							<tbody>
								{this.renderItemsRow()}
							</tbody>
						</table>
					</form>
				</div>
				{this.renderResetConfirmationModal()}
			</div>
		);
	}
}

PlanningModules.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	PlanningModules
);

const styles = {

}