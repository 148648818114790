import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

var _ = require('lodash');

class SymptomsAndRisks extends Component {

	render() {

        let symptomsAndRisks = this.props.symptomsAndRisks;
        
        if (!symptomsAndRisks || symptomsAndRisks.length == 0) return null;

        return(
            <div>
                <div className="container-with-padding">
                    <p className="text-blog">Before we look at your growth areas, it’s important to take note of the symptoms and risks that you might be most vulnerable to. It’s important to recognize these so you’re able to take necessary actions to address them rather than letting them linger and foster.</p>
                    <p className="text-blog">Below are some of the most important ones you should be mindful of. Please note that <span style={{fontFamily: 'Pangram-Regular'}}>the report does not suggest that you have all these symptoms all the time</span>—we’re only trying to indicate the likelihood of experiencing these risks and symptoms <span style={{fontFamily: 'Pangram-Regular'}}>if and when</span> you go off track in terms of your productivity or when things get challenging.</p>
                </div>
                <div className="risks-report-section my-3 pb-1">
                    <div className="container-with-padding">
                        {symptomsAndRisks.map((reportObj, index) => {
                            let riskLevel = reportObj.score >= 6 ? 'High' : 'Medium';
                            return(
                                <div key={'risks_'+index} style={riskLevel == 'High' ? styles.highRiskContainer : styles.mediumRiskContainer}>
                                    <p className="text-blog">
                                        <span className="font-bold">{index == 0 ? 'Primary: ' : 'Secondary: '}</span>
                                        <span className="font-bold">{reportObj.title} </span>
                                        {/* <span style={{ fontFamily: 'Pangram-Regular' }}>({riskLevel} Risk)</span> */}
                                    </p>
                                    <p className="text-blog mb-1">{reportObj.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
	}
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(
	SymptomsAndRisks
);

const styles = {

    highRiskContainer: {
        backgroundColor: '#fff',
        color: '#737373',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        lineHeight: 1.75
    },

    mediumRiskContainer: {
        backgroundColor: '#fff',
        color: '#737373',
        borderRadius: 10,
        padding: 15,
        marginBottom: 15,
        lineHeight: 1.75
    }
};