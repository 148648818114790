import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import axios from "axios";

import Util from '../../utils/Util';
import { history } from '../../utils/history';

import { setLoading } from "../../actions/loadingActions.js";

var UrlConstants = require('../../utils/UrlConstants');
var Globals = require('../../utils/Globals');

// delete this
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

class Signup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				username: "",
				email: "",
				password: "",
				confirmPassword: "",
				client: "web",
			},
			formErrors: {
				username: null,
				email: null,
				password: null,
				confirmPassword: null,
			},
			buttonClicked: false,
			buttonDisabled: false,
			formApiResponse: {
				className: "",
				message: "",
			},
		};
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			// Realtime validate only if the user click signup with invalid details
			if (this.state.buttonClicked) {
				const errorMsg = this.validateField(name, value);
				var formErrorsObj = { ...formErrors, [name]: errorMsg };
				this.setState({ formErrors: formErrorsObj });
			}
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		const { form } = this.state;

		switch (name) {

			case "username":
				if (!value) {
					errorMsg = "Please enter Username";
				} else if (!Util.validateUserName(value)) {
					errorMsg = "Please enter a valid username";
				}
				break;

			case "email":
				if (!value) {
					errorMsg = "Please enter Email";
				} else if (!Util.validateEmail(value)) {
					errorMsg = "Please enter a valid email address";
				}
				break;

			case "password":
				if (!value) {
					errorMsg = "Please enter Password";
				} else if (!Util.validatePassword(value)) {
					errorMsg = "Password should be at least 6 characters long and include atleast 1 alphabetic and 1 numeric character";
				}
				break;

			case "confirmPassword":
				if (!value) {
					errorMsg = "Please enter Confirm Password";
				} else if (value != form.password) {
					errorMsg = "Confirm Password doesn't match";
				}
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	// Handler for signup submit
	signup(e) {
		e.preventDefault();

		this.setState({
			buttonClicked: true,
		})

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			axios
				.post(UrlConstants.URLS.webSignUp, form)
				.then(res => {
					if (res.data) {

						var coreData = {
							userCoreProperties: {}
						};

						coreData.userCoreProperties.userID = res.data.userID;
						coreData.userCoreProperties.accessToken = res.data.accessToken;
						coreData.userCoreProperties.userName = res.data.username;
						coreData.userCoreProperties.userEmail = res.data.email

						localStorage.setItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA, JSON.stringify(coreData));

						Util.setCookie('_lte', Date.now());

						// Save the newly generated userCoreProperties
						localStorage.setItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES, JSON.stringify(coreData.userCoreProperties));

						this.setFormApiResponse("Signup Success", false);
						window.location = "/assessments/";
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				})
				.catch(err => {
					if (err && err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true);
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				})
				.finally(() => {
					this.props.setLoading(false);
					this.setState({
						buttonDisabled: false,
					});
				});
		}
	}

	render() {

		const { form, formErrors, formApiResponse } = this.state;

		return (
			<div className="page-content">
				<div className="container">
					<div className="login-form-section mb-5">
						<form onSubmit={this.signup.bind(this)}>
							<div className="row">
								<div className="col-sm-12 align-self-center text-center bg-white">
									<div className="row signin-heading">
										<div className="col-sm-12">
											<h4 className="text-center mb-3">Sign Up</h4>
										</div>
									</div>
									<div className="form-group">
										<div>
											<input type="text" className="form-control" name="username" value={form.username} onChange={this.handleInputField.bind(this)} placeholder="Username" />
											<p className="text-danger">{formErrors.username &&
												<span className="err">{formErrors.username}</span>}</p>
										</div>
									</div>
									<div className="form-group">
										<div>
											<input type="text" className="form-control" name="email" value={form.email} onChange={this.handleInputField.bind(this)} placeholder="Email" />
											<p className="text-danger">{formErrors.email &&
												<span className="err">{formErrors.email}</span>}</p>
										</div>
									</div>
									<div className="form-group">
										<div>
											<input type="password" className="form-control" name="password" value={form.password} onChange={this.handleInputField.bind(this)} placeholder="Password" />
											<p className="text-danger">{formErrors.password &&
												<span className="err">{formErrors.password}</span>}</p>
										</div>
									</div>
									<div className="form-group">
										<div>
											<input type="password" className="form-control" name="confirmPassword" value={form.confirmPassword} onChange={this.handleInputField.bind(this)} placeholder="Confirm Password" />
											<p className="text-danger">{formErrors.confirmPassword &&
												<span className="err">{formErrors.confirmPassword}</span>}</p>
										</div>
									</div>
									<button type="submit" className="btn btn-primary mb-3" disabled={this.state.buttonDisabled}>Sign Up</button>
									{
										(formApiResponse.message)
											?
											<p className={formApiResponse.className + ""}><span>{formApiResponse.message}</span></p>
											: null
									}
									<p><NavLink to="/reset-password/">Forgot Password?</NavLink></p>
									<div>Already have an account? <NavLink to="/login/">Login</NavLink></div>
									<div className="mt-3">By continuing, you agree to our <span><a target='_blank' href='https://www.productivitybyhabit.com/terms-of-use/'>Terms</a></span></div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

Signup.propTypes = {
	auth: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { setLoading })(
	Signup
);
