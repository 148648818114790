import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";

import { setLoading } from "../../actions/loadingActions.js";
import { getUserOutcomesWithPopulatedData } from "../../actions/outcomeActions";

import SharedStyles from '../../assets/styles/SharedStyles';
import DragableTimeBlocks from './DragableTimeBlocks';

import TimeBlockTypeSelect from "../time-blocks/TimeBlockTypeSelect";
import DurationSelect from "../form-inputs/DurationSelect";

import AddOutcome from "./AddOutcome"
import AddKeyActivity from "./AddKeyActivity.js";
import Util from '../../utils/Util';
var _ = require('lodash');

class UnplannedTimeBlocks extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
				title: "",
				type: null,
				durationInMinutes: null,
				setTimerDurationInMinutes: null,
				sessionsPerWeek: 1,
			},
			formErrors: {
				focusArea: null,
				outcome: null,
				keyActivity: null,
				title: null,
				type: null,
				durationInMinutes: null,
				setTimerDurationInMinutes: null,
				sessionsPerWeek: null,
			},
			focusAreas: [],
			focusAreasOptions: [],
			outcomesWithPopulatedData: [],
			outcomesOptions: [],
			keyActivityOptions: [],
			showFilterSection: false,
			buttonDisabled: false,
			addUnplannedTimeBlocksModal: false,
			unplannedTimeBlocks: [],
			eventsLoading: false,
			hideScheduledEvents: false,
			hideCompletedEvents: false,
			displaySubSection: ""
		};
	}

	resetForm() {

		// Get new outcomes and ka when closing the modal. Since we are allowing them to create it
		this.props.getOutcomesWithPopulatedData();

		var formObj = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
			title: "",
			type: null,
			durationInMinutes: null,
			setTimerDurationInMinutes: null,
			sessionsPerWeek: 1,
		};

		var formErrors = {
			focusArea: null,
			outcome: null,
			keyActivity: null,
			title: null,
			type: null,
			durationInMinutes: null,
			setTimerDurationInMinutes: null,
			sessionsPerWeek: null,
		};

		this.setState({
			form: formObj,
			formErrors: formErrors,
			outcomesOptions: [],
			keyActivityOptions: [],
		});
	}

	componentDidMount() {

		this.props.onRef(this);

		const { focusAreas, outcomesWithPopulatedData } = this.props;

		this.setState({
			focusAreas: focusAreas,
			outcomesWithPopulatedData: outcomesWithPopulatedData,
		});

		this.yourFocusAreas(focusAreas);

		this.yourOutcomes(outcomesWithPopulatedData);

		this.conditionalSwitchTabsForUnplannedTimeBlocks();

		this.filterEvents();
	}

	componentDidUpdate(prevProps) {

		// Typical usage (don't forget to compare props):
		// If both dates are not same [or] Any new unplaned is added OR removed then refresh the unplanned timeblocks
		if(!moment(prevProps.targetWeekStart).isSame(this.props.targetWeekStart) || ( this.props.unplannedTimeBlocks && !_.isEqual(this.props.unplannedTimeBlocks, prevProps.unplannedTimeBlocks) )) {

			this.conditionalSwitchTabsForUnplannedTimeBlocks();
		}
		if (prevProps.startTrackingBtnClicked != this.props.startTrackingBtnClicked) {

			this.setState({
				addUnplannedTimeBlocksModal: true,
				displayAdHocTimeTrackerContent: true,
				trakingOn: this.props.trackingData.state === 'stopped' ? false : true,
				trackingData: this.props.trackingData
			});

			console.log("trackingData", this.props.trackingData);
		}

		if(!_.isEqual(prevProps.outcomesWithPopulatedData, this.props.outcomesWithPopulatedData)) {
			this.setState({
				outcomesWithPopulatedData: this.props.outcomesWithPopulatedData,
			});

			this.yourOutcomes(this.props.outcomesWithPopulatedData);
		}
	}

	conditionalSwitchTabsForUnplannedTimeBlocks() {

		this.setState({
			unplannedTimeBlocks: this.props.unplannedTimeBlocks,
			hideScheduledEvents: false,
			hideCompletedEvents: false,
		});
	}

	// Set dropdown options for focus areas
	yourFocusAreas(focusAreas) {

		if (focusAreas && focusAreas.length > 0) {

			let focusAreasOptions = [];

			// Default select all focusAreas
			// const { form } = this.state;
			// var formObj = {};
			// formObj = {
			// 	...form,
			// 	focusArea: {
			// 		label: "All",
			// 		value: "all",
			// 	},
			// };

			focusAreasOptions = Util.focusAreaDropdownOptions(focusAreas);

			this.setState({
				// form: formObj,
				focusAreasOptions: focusAreasOptions,
				buttonDisabled: false,
			});
		}
	}

	// Set dropdown options for outcome
	yourOutcomes(outcomes) {

		if (outcomes && outcomes.length > 0) {

			let outcomesOptions = [];

			// Default select none outcomes
			const { form } = this.state;
			var formObj = {};
			formObj = {
				...form,
				outcome: null,
			};

			let filteredoutcomesOptions = [];

			if(form["focusArea"] && form["focusArea"].value && form["focusArea"].value != 'all') {
				_.forEach(outcomes, function (eachOutcome) {
					if(eachOutcome.focusArea.id ===  form["focusArea"].value) {
						filteredoutcomesOptions.push(eachOutcome);
					}
				});
			}

			filteredoutcomesOptions.forEach((eachOutcome) => {
				outcomesOptions.push({
					label: eachOutcome.outcomeTitle,
					value: eachOutcome.id,
					focusAreaID: eachOutcome.focusArea.id
				});
			});

			if(form["focusArea"] && form["focusArea"].value) {
				outcomesOptions.push({
					label: "Add New Project",
					value: "ADD_NEW_OUTCOME",
				})
			}

			this.setState({
				form: formObj,
				outcomesOptions: outcomesOptions,
				buttonDisabled: false,
			});

			this.props.setLoading(false);
		}
	}

	// Handler for focus area select field change event
	handleFocusAreaSelect(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'outcome': null,
			'keyActivity': null,
		};

		let filteredoutcomesOptions = [];

		if(value.value != 'all') {
			_.forEach(this.state.outcomesWithPopulatedData, function (eachOutcome) {
			
				if(eachOutcome.focusArea.id ===  value.value) {
					filteredoutcomesOptions.push(eachOutcome);
				}
			});
		} else {
			filteredoutcomesOptions = this.state.outcomesWithPopulatedData;
		}

		let outcomesOptions = [];
		filteredoutcomesOptions.forEach((eachOutcome) => {

			outcomesOptions.push({
				label: eachOutcome.outcomeTitle,
				value: eachOutcome.id,
				focusAreaID: eachOutcome.focusArea.id
			});
		});

		outcomesOptions.push({
			label: "Add New Project",
			value: "ADD_NEW_OUTCOME",
		})

		this.setState({
			form: formObj,
			outcomesOptions: outcomesOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for outcome select field change event
	handleOutomeSelectField(name, value) {

		if (name == "outcome" && value["value"] == "ADD_NEW_OUTCOME") {
			// Show Section - Add New Outcome
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
			'keyActivity': null,
		};

		let chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: value.value });

		let keyActivities = chosenOutcome['keyActivities'];

		let keyActivityOptions = [];

		// Some user may not have added key activitites for an outcome
		if (keyActivities && keyActivities.length > 0) {

			keyActivities.forEach((eachkeyActivity) => {

				keyActivityOptions.push({
					label: eachkeyActivity.title,
					value: eachkeyActivity.id,
				});
			});
		}

		if (!chosenOutcome.productivityOutcome) {
			keyActivityOptions.push({
				label: "Add New Key Activity",
				value: "ADD_NEW_KA",
			});
		};

		this.setState({
			form: formObj,
			keyActivityOptions: keyActivityOptions,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for Key Activity select field change event
	handleKeyActivitySelectField(name, value) {

		if (name == "keyActivity" && value["value"] == "ADD_NEW_KA") {
			// Show Section - Add New KeyActivity
			return this.setState({ displaySubSection: value["value"]})
		}

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == "sessionsPerWeek") {
			if (!isNaN(parseInt(value))) {
				value = parseInt(value);
			};
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Handler for checkbox field change event
	handleCheckboxField(event) {

		let { name, value } = event.target;

		value = (!value || value == "false") ? true : false;

		this.setState({
			[name]: value,
			eventsLoading: true,
		}, () => {
			this.filterEvents();
		});

	}

	/**
	 * 1) Hide Scheduled Events,
	 * 2) Hide Completed Events. 
	 * - We'd have an on/off toggle for both these settings.
	 * - When the first one is activated, scheduled events will be hidden. 
	 * - When the second one is activated, completed events will be hidden.
	 */
	filterEvents() {

		const { unplannedTimeBlocks } = this.props;
		const { hideScheduledEvents, hideCompletedEvents } = this.state;

		// console.log('this.state...', this.state);
		// console.log('unplannedTimeBlocks..', unplannedTimeBlocks);

		const events = this.props.events || []; // List of Scheduled events.
		let unplannedTimeBlocksCopy = [...unplannedTimeBlocks];

		// Copied the schedule and completed logic here because if user marks a event completed or remove event from plan we need to update realtime.
		unplannedTimeBlocksCopy.map((timeBlockObj) => {
            
			// let associatedTimeBlock = _.filter(events, {timeBlockID: timeBlockObj.timeBlockID});
			
			let associatedTimeBlock = _.filter(events, function(e) { 
				if(!e.isRemovedFromThePlan && e.timeBlockID == timeBlockObj.timeBlockID) {
					return true;
				} else {
					return false;
				}
			});
        
			if (timeBlockObj.isCreatedFromTheTask === true) {
				timeBlockObj.sessionsPerWeek = associatedTimeBlock.length; // For events created using task, # of schedule can be more than one.
			};

            if (associatedTimeBlock.length <= timeBlockObj.sessionsPerWeek) {

                timeBlockObj['scheduled'] = associatedTimeBlock.length + "/" + timeBlockObj.sessionsPerWeek + " scheduled";
                timeBlockObj['totalScheduled'] = associatedTimeBlock.length;

                let totalCompleted = 0;
                associatedTimeBlock.map((eachassociatedTimeBlock) => {

                    if(eachassociatedTimeBlock.isCompleted) {
                        totalCompleted = totalCompleted + 1;
                    }
                })
                timeBlockObj['completed'] = totalCompleted + "/" + timeBlockObj.sessionsPerWeek + " completed";
                timeBlockObj['totalCompleted'] = totalCompleted;
            }
		});

		// Now filteredUnplannedTimeBlocks will have realtime events.
		let filteredUnplannedTimeBlocks = [...unplannedTimeBlocksCopy];

		if(unplannedTimeBlocks && unplannedTimeBlocks.length > 0) {
			unplannedTimeBlocks.map((eachTimeBlock, index) => {

				// If hideScheduledEvents is selected and current unplannedTimeBlocks is scheduled remove them from the unplannedTimeBlocks array. We are using lodash to match object and remove from array instead of array.splice since if we remove index then hideCompletedEvents will be affected.
				if(hideScheduledEvents) {
					if(eachTimeBlock.totalScheduled === eachTimeBlock.sessionsPerWeek) {
						_.remove(filteredUnplannedTimeBlocks, filteredTimeBlock => filteredTimeBlock.timeBlockID === eachTimeBlock.timeBlockID);
					}
				}

				// If hideCompletedEvents is selected and current unplannedTimeBlocks is completed remove them from the unplannedTimeBlocks array
				if(hideCompletedEvents) {
					if(eachTimeBlock.totalCompleted === eachTimeBlock.sessionsPerWeek) {
						_.remove(filteredUnplannedTimeBlocks, filteredTimeBlock => filteredTimeBlock.timeBlockID === eachTimeBlock.timeBlockID);	
					}
				}
			})
		}

		// Reset
		if(!hideScheduledEvents && !hideCompletedEvents) {
			filteredUnplannedTimeBlocks = [...unplannedTimeBlocks];
		}

		this.setState({
			unplannedTimeBlocks: filteredUnplannedTimeBlocks,
			eventsLoading: false,
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		switch (name) {

			// case "outcome":
			// 	if (!value) errorMsg = "Please select an Outcome";
			// 	break;

			// case "focusArea":
			// 	if (!value) errorMsg = "Please select a Focus Area";
			// 	break;

			// case "keyActivity":
			// 	if (!value) errorMsg = "Please select a Key Activity";
			// 	break;

			case "title":
				if (!value) errorMsg = "Please enter Title";
				break;

			case "type":
				if (!value && this.isThisFieldRequired(name)) errorMsg = "Please select Time Block Type";
				break;

			case "durationInMinutes":
				if (!value && this.isThisFieldRequired(name)) errorMsg = "Please enter Expected Duration";
				break;

			case "sessionsPerWeek":
				if (!value) errorMsg = "Please enter # of Sessions";
				if (!Util.validateNumber(value)) errorMsg = "Sessions should be a valid Number";
				break;
			
			case "setTimerDurationInMinutes":
				if (!value && this.isThisFieldRequired(name)) errorMsg = "Please select Set Timer";
				break;
			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	// Handler for submit
	handleSubmit(e) {
		e.preventDefault();

		const { form, formErrors } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			let unplannedTimeBlocks = [];

			let isUnAssigned = true;
			let chosenOutcome = null;
			let customTitle = form['title']; // default title as Timeblock_Title

			if (form['outcome'] && form['outcome']['value']) {
				chosenOutcome = _.find(this.state.outcomesWithPopulatedData, { id: form['outcome']['value'] });
			}

			if (chosenOutcome && chosenOutcome['abbreviation'] && form['keyActivity'] && form['keyActivity']['label']) {
				customTitle =chosenOutcome['abbreviation'] + ": " + form['keyActivity']['label']; // custom title > Outome_Abbreviation: Key_Activity_Title
			}

			if (form['focusArea'] && form['focusArea']['value']
				&& form['outcome'] && form['outcome']['value']
				&& form['keyActivity'] && form['keyActivity']['value']) {
					isUnAssigned = false; // all related information are not assigned(FocusArea, Outcomes, KeyActivity)
			}

			var startDt = new Date();
			var endDt = new Date();
			startDt.setHours(0, 0, 0);
			endDt.setHours(0, (form['durationInMinutes'] && form['durationInMinutes']['value'] || 1), 0);

			let coreData = Util.getUser();
			let user_id = coreData.userCoreProperties.userID;

			let outcomeID = chosenOutcome? form['outcome']['value'] : null;
			let outcomeAbbr = chosenOutcome? chosenOutcome['abbreviation'] : null;
			let outcomeTitle = chosenOutcome? form['outcome']['label'] : null;
			let keyActivityID = form['keyActivity'] && form['keyActivity']['value'] ? form['keyActivity']['value'] : null;
			let keyActivityTitle = form['keyActivity'] && form['keyActivity']['label'] ? form['keyActivity']['label'] : null;
			let timeBlockID = user_id + "-" + Date.now(); //[user-id]-[getTime]
			let timeBlockTitle = form['title'];
			let focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
			let focusAreaTitle = form['focusArea'] && form['focusArea']['label'] ? form['focusArea']['label'] : null;
			let durationInMinutes = form['durationInMinutes'] && form['durationInMinutes']['value'] || null ;
			let setTimerInMinutes = form['setTimerDurationInMinutes'] && form['setTimerDurationInMinutes']['value'] || null;
			let sessionsPerWeek = form['sessionsPerWeek'];
			let type = form['type'];
			let isAdHocTimeTrackerTimeBlock = this.state.displayAdHocTimeTrackerContent? true : false;
			
			/* Construct event object. */
			unplannedTimeBlocks.push({
				id: Date.now(),
				title: customTitle,
				start: startDt,
				end: endDt,
				outcomeID: outcomeID,
				outcomeAbbr: outcomeAbbr,
				outcomeTitle: outcomeTitle,
				keyActivityID: keyActivityID,
				keyActivityTitle: keyActivityTitle,
				timeBlockID: timeBlockID, //[user-id]-[getTime]
				timeBlockTitle: timeBlockTitle,
				focusAreaID: focusAreaID,
				focusAreaTitle: focusAreaTitle,
				durationInMinutes: durationInMinutes,
				trackDuration: true,
				setTimerInMinutes: setTimerInMinutes,
				sessionsPerWeek: sessionsPerWeek,
				type: type,
				maximumBufferDateTime: moment().endOf('day').format(),
				isUnAssigned: isUnAssigned,
				isUnplanned: true,
				isAdHocTimeTrackerTimeBlock: isAdHocTimeTrackerTimeBlock
			});

			// console.log('unplannedTimeBlocks..', unplannedTimeBlocks);

			if (this.state.displayAdHocTimeTrackerContent) {

				// handle when start or stop tacking button is pressed

				const {trackingData} = this.state

				if (trackingData && trackingData.state == 'stopped') {

					// start tracking
					let action = 'start';
					let notificatioScheduledAt = setTimerInMinutes? moment().add(setTimerInMinutes, 'minutes').toDate() : null;
					const unPlannedEvent = unplannedTimeBlocks[0];

					unPlannedEvent.notificatioScheduledAt = notificatioScheduledAt;

					this.props.updateTrackingEvent(unPlannedEvent, action, trackingData);
				}
			}

			this.props.addNewUnplannedTimeBlocks(unplannedTimeBlocks);

			this.setState({
				addUnplannedTimeBlocksModal: false,
				displayAdHocTimeTrackerContent: false,
				unplannedTimeBlocks: [...this.state.unplannedTimeBlocks, ...unplannedTimeBlocks],
			});

			this.resetForm();
		}
	}

	// Handler For Stop Tracking Submit
	handleStopTrackingSubmit(e) {

		e.preventDefault();

		if (this.state.displayAdHocTimeTrackerContent) {

			const {trackingData} = this.state

			if (trackingData && trackingData.action == 'tracking') {

				// stop tracking
				let action = 'stop';
				trackingData.stoppedAt = new Date();

				// find and update unplannedTimeBlocks duration in minutes
				// this.props.addNewUnplannedTimeBlocks(unplannedTimeBlocks);

				this.props.updateTrackingEvent(trackingData.event, action, trackingData);

				this.setState({
					addUnplannedTimeBlocksModal: false,
					displayAdHocTimeTrackerContent: false,
					// unplannedTimeBlocks: [...this.state.unplannedTimeBlocks, ...unplannedTimeBlocks],
					trackingData: null
				});

			}
		}
	}

	toggleAddUnplannedTimeBlocksModall() {

		this.resetForm();

		if (this.state.addUnplannedTimeBlocksModal) {
			this.setState({
				addUnplannedTimeBlocksModal: false,
				displayAdHocTimeTrackerContent: false,
				displaySubSection: "",
			});
		} else {
			this.setState({
				addUnplannedTimeBlocksModal: true,
				displayAdHocTimeTrackerContent: false,
				displaySubSection: "",
			});
		}
	}

	renderFooterButtons() {

		const { buttonDisabled, trakingOn, displayAdHocTimeTrackerContent } = this.state;

		if (!displayAdHocTimeTrackerContent) {
			// Submit Btn For Creating UnPlanned TimeBlocks
			return(
				<div className="col-md-12">
					<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm">Submit</button>
				</div>
			);
		}

		else if (displayAdHocTimeTrackerContent && !trakingOn) {
			// Show Start Tracking Button
			return (
				<div className="col-md-12">
					<button type="button" onClick={this.handleSubmit.bind(this)} disabled={buttonDisabled} className="btn btn-primary mb-2 btn-sm">{"Start Tracking"}</button>
				</div>
			)
		}
	}

	isThisFieldRequired(key) {

		let flag = true;

		if (this.state.displayAdHocTimeTrackerContent) {

			/* These fields are optional for AdHocTimeTracker */

			switch (key) {
				case 'durationInMinutes':
				case 'setTimerDurationInMinutes':
				case 'type':
					flag = false;
					break;
				default:
					break;
			}
		}
		else {

			/* These fields are optional for Add UnPlanned TimeBlock */

			switch (key) {
				case 'setTimerDurationInMinutes':
					flag = false;
					break;
				default:
					break;
			}

		}

		return flag;
	}

	handleAddNewOnCancel = () => {
		this.props.getOutcomesWithPopulatedData();
		this.setState({
			displaySubSection: ""
		})
	}

	handleNewOutcomeSubmit = (createdOutcome, displaySubSection = "" ) => {

		this.props.setLoading(true);

		const { outcomesOptions, form } = this.state;
		const focusAreaID = form['focusArea'] && form['focusArea']['value'] ? form['focusArea']['value'] : null;
		const newOutcome = {
			label: createdOutcome.outcomeTitle,
			value: createdOutcome.id,
			focusAreaID: focusAreaID
		}

		let outcomesWithPopulatedData = [
			...this.state.outcomesWithPopulatedData,
			createdOutcome,
		]

		setTimeout(() => {
			
			outcomesOptions.splice((outcomesOptions.length - 1), 0, newOutcome); // push new outcome
			var keyActivityOptions = [{
				label: "Add New Key Activity",
				value: "ADD_NEW_KA",
			}];

			form["outcome"] = newOutcome; // select newly created outcome
			form["keyActivity"] = null;

			this.setState({
				displaySubSection: displaySubSection,
				outcomesOptions: outcomesOptions,
				form: form,
				keyActivityOptions: keyActivityOptions,
				outcomesWithPopulatedData: outcomesWithPopulatedData,
			})
		}, 500);
	}

	handleNewKeyActivitySubmit = (createdKA, displaySubSection ="" ) => {

		const { keyActivityOptions, form } = this.state;

		const newKA = {
			label: createdKA.title,
			value: createdKA.id,
		}

		keyActivityOptions.splice((keyActivityOptions.length - 1), 0, newKA);

		setTimeout(() => {
			form["keyActivity"] = newKA; // select newly created KA 
			this.setState({
				displaySubSection: displaySubSection,
				keyActivityOptions: keyActivityOptions,
				form: form
			})
		}, 500);
	}

	renderAddUnplannedTimeBlocksInModal() {

		const { form, formErrors, focusAreasOptions, outcomesOptions, keyActivityOptions, displayAdHocTimeTrackerContent } = this.state;
		let maxDutationAvailableInMins = 480;

		if (displayAdHocTimeTrackerContent) {
			maxDutationAvailableInMins = moment().endOf('day').diff(moment(), 'minutes');
		}

		var innerHTML = null;

		if (this.state.displaySubSection == "ADD_NEW_OUTCOME") {
			innerHTML = <AddOutcome 
							onClose={this.toggleAddUnplannedTimeBlocksModall.bind(this)}
							onCancel={this.handleAddNewOnCancel}
							onSuccussSubmit={this.handleNewOutcomeSubmit}
							focusAreasOptions={this.state.focusAreasOptions}
							{...this.props}
							{...this.state} />;
		}

		else if (this.state.displaySubSection == "ADD_NEW_KA") {
			innerHTML = <AddKeyActivity 
							onClose={this.toggleAddUnplannedTimeBlocksModall.bind(this)}
							onCancel={this.handleAddNewOnCancel}
							onSuccussSubmit={this.handleNewKeyActivitySubmit}
							{...this.props}
							{...this.state} />;
		}

		if (innerHTML) {
			return (
				<div>
					<Modal size={'lg'} isOpen={this.state.addUnplannedTimeBlocksModal} toggle={this.toggleAddUnplannedTimeBlocksModall.bind(this)} backdrop={"static"} keyboard={false}>
						{innerHTML}
					</Modal>
				</div>
			)
		}

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.addUnplannedTimeBlocksModal} toggle={this.toggleAddUnplannedTimeBlocksModall.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddUnplannedTimeBlocksModall.bind(this)}>
						{
							displayAdHocTimeTrackerContent ?
							"Start Tracking" : "Add Event"
						}
					</ModalHeader>
					<ModalBody>
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<label>Title<span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="title" value={form['title']} />
											<p className="text-danger">{formErrors['title'] &&
												<span className="err">{formErrors['title']}</span>}</p>
										</div>
									</div>
									<div className="form-group">
										<label>FocusArea</label>
										<div>
											<ReactSelect
												name="focusArea"
												styles={SharedStyles.selectBoxStyles}
												closeOnSelect={false}
												options={focusAreasOptions}
												value={form.focusArea}
												removeSelected={true}
												autosize={true}
												clearable={true}
												onSelectResetsInput={true}
												onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
												placeholder="Select Focus Area"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<label>Project</label>
										<ReactSelect
											name="outcome"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={outcomesOptions}
											value={form['outcome']}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={this.handleOutomeSelectField.bind(this, "outcome")}
											placeholder="Select Project"
										/>
										<p className="text-danger">{formErrors['outcome'] &&
											<span className="err">{formErrors['outcome']}</span>}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<label>Key Activity</label>
										<ReactSelect
											name="keyActivity"
											styles={SharedStyles.selectBoxStyles}
											closeOnSelect={false}
											options={keyActivityOptions}
											value={form['keyActivity']}
											removeSelected={true}
											autosize={true}
											clearable={true}
											onSelectResetsInput={true}
											onChange={this.handleKeyActivitySelectField.bind(this, "keyActivity")}
											placeholder="Select Key Activity"
										/>
										<p className="text-danger">{formErrors['keyActivity'] &&
											<span className="err">{formErrors['keyActivity']}</span>}</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<TimeBlockTypeSelect
										isRequired={this.isThisFieldRequired('type')}
										handleTimeBlockTypeSelect={this.handleSelectField.bind(this)}
										value={form.type}
										error={formErrors.type}
									/>
									<div> 
										<DurationSelect
											keyName={'durationInMinutes'}
											title={'Expected Duration'}
											handleSelectField={this.handleSelectField.bind(this)}
											value={form.durationInMinutes}
											error={formErrors.durationInMinutes}
											isRequired={this.isThisFieldRequired('durationInMinutes')}
											maximumDuration={maxDutationAvailableInMins}
											placeholder={"Select Expected Duration"}
										/>
									</div>
									{/* <div className="form-group">
										<label># of Sessions<span className="text-danger">*</span></label>
										<div>
											<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="sessionsPerWeek" value={form['sessionsPerWeek']} disabled="disabled" />
											<p className="text-danger">{formErrors['sessionsPerWeek'] &&
												<span className="err">{formErrors['sessionsPerWeek']}</span>}</p>
										</div>
									</div> */}
									{
										displayAdHocTimeTrackerContent ?
											<div>
												<DurationSelect
													keyName={'setTimerDurationInMinutes'}
													title={'Set Timer'}
													handleSelectField={this.handleSelectField.bind(this)}
													value={form.setTimerDurationInMinutes}
													error={formErrors.setTimerDurationInMinutes}
													isRequired={this.isThisFieldRequired('setTimerDurationInMinutes')}
													maximumDuration={maxDutationAvailableInMins}
													placeholder={"No Timer"}
												/>
											</div>
											:
											null
									}
								</div>
							</div>
							<div className="row">

								{this.renderFooterButtons()}
							</div>
						</form>
					</ModalBody>
				</Modal>
			</div>
		)
	}

	renderHideEventsFilter() {

		const { hideScheduledEvents, hideCompletedEvents } = this.state;
		
		return(
			<div>
				<div className="mt-1 mb-2">
					<div className="custom-control custom-switch">
						<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="hideScheduledEvents" value={hideScheduledEvents} id="hideScheduledEventsSwitch" checked={hideScheduledEvents ? "checked" : ""} />
						<label className="custom-control-label small" htmlFor="hideScheduledEventsSwitch" style={{paddingTop:3}}>Hide Scheduled Events</label>
					</div>
				</div>
				<div className="mb-2">
					<div className="custom-control custom-switch">
						<input type="checkbox" onChange={this.handleCheckboxField.bind(this)} className="custom-control-input" name="hideCompletedEvents" value={hideCompletedEvents} id="hideCompletedEventsSwitch" checked={hideCompletedEvents ? "checked" : ""} />
						<label className="custom-control-label small" htmlFor="hideCompletedEventsSwitch" style={{paddingTop:3}}>Hide Completed Events</label>
					</div>
				</div>
			</div>
		)
	}

	// Render Unplanned Time Blocks Section
	render() {

		return (
			<div>
				{this.props.activeLeftPaneSection == 'unplanned' ?
					<React.Fragment>
						<div className="clearfix">
							<div className="cursor-pointer float-right mt-n2">
								<button className="btn btn-primary btn-xs mt-1" onClick={this.toggleAddUnplannedTimeBlocksModall.bind(this)}><i className="fa fa-plus" aria-hidden="true"></i></button>
							</div>
						</div>
						{this.props.unplannedTimeBlocks && this.props.unplannedTimeBlocks.length > 0 ?
							<div className="mt-1">
								{this.renderHideEventsFilter()}
								<React.Fragment>
									<DragableTimeBlocks timeBlocks={this.state.unplannedTimeBlocks} events={this.props.events} onDoubleClick={this.props.toggleDetailedInfoModal} />
								</React.Fragment>
							</div>
						: null}
					</React.Fragment>
				: null}
				{this.renderAddUnplannedTimeBlocksInModal()}
			</div>
		);
	}
}

UnplannedTimeBlocks.propTypes = {
	setLoading: PropTypes.func.isRequired,
	getUserOutcomesWithPopulatedData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomesWithPopulatedData })(
	UnplannedTimeBlocks
);