import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Util from '../../utils/Util';
import { history } from '../../utils/history';

import { setLoading } from "../../actions/loadingActions.js";

var UrlConstants = require('../../utils/UrlConstants');
var Globals = require('../../utils/Globals');

// delete this
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			error: null,
			buttonDisabled: true,
			formApiResponse: {
				className: "",
				message: "",
			},
		};
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	login() {

		var error = null;

		if (this.state.email == "" || this.state.password == "") {

			error = "Please enter all the details";
			this.setState({
				error: error
			});
			return false;
		}

		if (!Util.validateEmail(this.state.email)) {

			error = "Please enter a valid email address.";
			this.setState({
				error: error
			});
			return false;
		}

		this.setState({
			buttonDisabled: true,
		})

		this.props.setLoading(true);

		const userData = {
			email: this.state.email,
			password: this.state.password
		};

		axios
			.post(UrlConstants.URLS.webLogin, userData)
			.then(res => {
				if (res.data) {

					var coreData = {
						userCoreProperties: {}
					};

					coreData.userCoreProperties.userID = res.data.userID;
					coreData.userCoreProperties.accessToken = res.data.accessToken;
					coreData.userCoreProperties.userName = res.data.username;
					coreData.userCoreProperties.userEmail = res.data.email

					localStorage.setItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA, JSON.stringify(coreData));

					Util.setCookie('_lte', Date.now());

					// Save the newly generated userCoreProperties
					localStorage.setItem(Globals.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES, JSON.stringify(coreData.userCoreProperties));

					this.setFormApiResponse("Login Success", false);
					
					if (this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname) {
						window.location = this.props.location.state.from.pathname;
					}
					else {
						window.location = "/daily-review/";
					};
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
				}
			})
			.catch(err => {
				if (err && err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true);
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			})
			.finally(() => {
				this.props.setLoading(false);
				this.setState({
					buttonDisabled: false,
				});
			});

	}

	keypress(e) {
		if (e.key === 'Enter') {
			this.login();
		}
	}

	handleEmailChange(e) {
		this.setState({
			error: null,
			email: e.target.value,
		}, () => {
			if (this.state.email && this.state.password) {
				this.setState({
					buttonDisabled: false,
				});
			} else {
				this.setState({
					buttonDisabled: true,
				});
			}

		});
	}

	handlePasswordChange(e) {
		this.setState({
			error: null,
			password: e.target.value,
		}, () => {
			if (this.state.email && this.state.password) {
				this.setState({
					buttonDisabled: false,
				});
			} else {
				this.setState({
					buttonDisabled: true,
				});
			}
		});
	}

	renderButton() {

		return (
			<center><button type="submit" className="btn btn-primary" id="submit" disabled={this.state.buttonDisabled} onClick={this.login.bind(this)}>Login</button></center>
		);
	}

	render() {

		const { formApiResponse } = this.state;

		return (
			<div className="page-content">
				<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
				<div className="container">
					<div className="login-form-section">
						<div className="row">
							<div className="col-sm-12 align-self-center text-center bg-white">
								<div className="row signin-heading">
									<div className="col-sm-12">
										<h4 className="text-center mb-3">Login</h4>
									</div>
								</div>
								<div className="form-group text-center">
									<input type="email" className="form-control" id="email" name="email" value={this.state.email} onKeyPress={this.keypress.bind(this)} onChange={this.handleEmailChange.bind(this)} placeholder="Email" />
								</div>
								<div className="form-group">
									<input type="password" className="form-control" id="pwd" name="password" placeholder="Password" value={this.state.password} onKeyPress={this.keypress.bind(this)} onChange={this.handlePasswordChange.bind(this)} />
								</div>
								<div id="errorMsg">
									<p>{this.state.error}</p>
								</div>
								{this.renderButton()}
								{
									(formApiResponse.message)
										?
										<p className={formApiResponse.className + " mt-2 mb-0"}><span>{formApiResponse.message}</span></p>
										: null
								}
								<br />
								<p><NavLink to="/reset-password/">Forgot Password?</NavLink></p>
								<div>Don't have an account? <NavLink to="/signup/">Sign up</NavLink></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	setLoading: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { setLoading })(Login);