import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import Util from '../../utils/Util';
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

import ProductivityGoals from './ProductivityGoals';
import SymptomsAndRisks from './SymptomsAndRisks';
import GrowthAreas from './GrowthAreas';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class ProductivityMeterReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			results: null,
			showSplitupModal: false,
			displaySection: 'summary'
		}
		window.scrollTo(0, 0);
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.props.setLoading(true);

		const assessmentId = this.props.match.params.assessmentId;

		let postObj = {
			assessmentId: assessmentId,
		};

		API_Services.httpPOST(UrlConstants.URLS.getProductivityMeterReport, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status && response.data.data) {

					this.setState({
						results: response.data.data
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	updateDisplaySection(section) {

		this.setState({
			displaySection: section
		},
		() => window.scrollTo(0, 0));
	}

	sendAssistanceRequest() {

		this.props.setLoading(true);

		let coreData = Util.getUser();
		const assessmentId = this.props.match.params.assessmentId;

		let postObj = {
			username: coreData.userCoreProperties.userName,
			email: coreData.userCoreProperties.userEmail,
			assessmentId: assessmentId,
		};

		API_Services.httpPOST(UrlConstants.URLS.exploreNextSteps, postObj, (err, response) => {

			if (err && err.response && err.response.data) {
				this.setFormApiResponse(err.response.data, true);
			} 
			else if (response.data && response.data.status && response.data.message) {
				alert(response.data.message);
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	renderSummary(productivityGoals, symptomsAndRisks, growthAreas) {

		return(
			<div className="container-with-padding">
				
				{productivityGoals && productivityGoals.length > 0 ?
					<div className="productivity-meter-report-section">
						<h5 className="text-center mt-2 mb-4 text-white">Productivity Goals Evaluation</h5>
						{productivityGoals.map((resultObj, index) => {
							return(
								<div className="row row-section-container mt-3" key={'pg_summary_'+index}>
									<div className="col-0 col-md-3"></div>
									<div className="col-6 col-md-3">{resultObj.title}</div>
									<div className="col-6 col-md-3 form-control p-1 text-center" style={{height: 'auto'}}>{resultObj.score} / 7</div>
									<div className="col-0 col-md-3"></div>
								</div>
							);
						})}
					</div>
				: null}

				{symptomsAndRisks && symptomsAndRisks.length > 0 ?
					<div className="risks-report-section mt-4">
						<h5 className="text-center mt-2 text-white">Your Primary Risk</h5>
						<div className="row row-section-container d-flex justify-content-center">
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{symptomsAndRisks[0]?.title} 
							</div>
						</div>
						<h5 className="text-center mt-2 text-white">Your Secondary Risks</h5>
						<div className="row row-section-container d-flex justify-content-center">
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{symptomsAndRisks[1]?.title} 
							</div>
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{symptomsAndRisks[2]?.title} 
							</div>
						</div>
					</div>
				: null}

				{growthAreas && growthAreas.length > 0 ?
					<div className="growth-areas-report-section mt-4">
						<h5 className="text-center mt-2 text-white">Your Primary Growth Area</h5>
						<div className="row row-section-container d-flex justify-content-center">
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{growthAreas[0]?.title} 
							</div>
						</div>
						<h5 className="text-center mt-2 text-white">Your Secondary Growth Areas</h5>
						<div className="row row-section-container d-flex justify-content-center">
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{growthAreas[1]?.title} 
							</div>
							<div className="col-md-5 form-control p-1 m-2 text-center" style={{height: 'auto'}}>
								{growthAreas[2]?.title} 
							</div>
						</div>
					</div>
				: null}

				<div className="row row-section-container mt-4 d-flex justify-content-center">
					<div className="col-md-10 text-center">
						<button className="btn btn-primary" onClick={this.updateDisplaySection.bind(this, 'detailed-report')} style={{minWidth:215}}>Review Report</button>
						<Link to={'/assessments/productivity-meter'} className="btn btn-primary m-3" style={{minWidth:215}}>Productivity Meter Home</Link>
					</div>
				</div>

			</div>
		);
	}

	renderReport() {

		let { results } = this.state;

		if (!results) return null;

		let productivityGoals = results.productivityGoalsResult || [];
		let symptomsAndRisks = results.symptomsAndRisksResult || [];
		let growthAreas = results.growthAreasResult || [];

		symptomsAndRisks = _.filter(results.symptomsAndRisksResult, (item) => item.score > 3);
		symptomsAndRisks = _.sortBy(symptomsAndRisks, 'score');
		symptomsAndRisks = symptomsAndRisks.reverse();

		growthAreas = _.filter(growthAreas, (item) => item.score < 6);
		growthAreas = _.sortBy(growthAreas, 'score');

		let maxLimit = 3;
		// Show only the top 3 results to the user 
		if (symptomsAndRisks.length > maxLimit) symptomsAndRisks = symptomsAndRisks.slice(0, maxLimit);
		if (growthAreas.length > maxLimit) growthAreas = growthAreas.slice(0, maxLimit);

		if (this.state.displaySection == 'summary') {
			return this.renderSummary(productivityGoals, symptomsAndRisks, growthAreas);
		};

		return (
			<div>
				
				<div className="container-with-padding">

					<h4 className="text-center mb-2" style={{fontSize:20}}>Productivity Meter</h4>
					
					<p className="text-blog">Well done!</p>
					
					<p className="text-blog">All big leaps forward begin with deep understanding, and you just took a huge stride towards cultivating a better sense about where you’re at with your productivity.</p>
					<p className="text-blog">Productivity is something that can affect everything from our wellbeing to career and even relationships. So we’re glad you’re paying attention to yours.</p>
					<p className="text-blog">The Productivity Meter report that follows will walk you through the following important information as it applies to you:</p>
					
					<div className="d-flex flex-wrap border-box">
						{productivityGoals && productivityGoals.length > 0 ?
							<div className="productivity-meter-report-section m-2">
								<p className="text-center"><img src='/images/Productivity_Goals_White.png' style={styles.introIcon} /></p>
								<p className="font-bold text-center" style={{fontSize:17}}>Productivity Goals Evaluation</p> 
								<div className="text-blog">Here we’ll review where you’re at with respect to the most important indicators of productivity. This will give you a sense about the level at which you’re operating as well as the room for growth and improvement.</div>
							</div>
						: null}
						{symptomsAndRisks && symptomsAndRisks.length > 0 ?
							<div className="risks-report-section m-2">
								<p className="text-center"><img src='/images/Risks_White.png' style={styles.introIcon} /></p>
								<p className="font-bold text-center" style={{fontSize:17}}>Your Risks</p>
								<div className="text-blog">We will then review the important symptoms and risks to watch out for if and when your productivity slides. This section will be personalized based on your strengths and weaknesses.</div>
							</div>
						: null}
						{growthAreas && growthAreas.length > 0 ?
							<div className="growth-areas-report-section m-2">
								<p className="text-center"><img src='/images/Growth_Areas_White.png' style={styles.introIcon} /></p>
								<p className="font-bold text-center" style={{fontSize:17}}>Your Growth Areas</p> 
								<div className="text-blog">Here we’ll review the most impactful growth areas for you. This will help you focus your efforts based on your specific needs.</div>
							</div>
						: null}
					</div>
				</div>

				<div className="container-with-padding">
					<p className="text-blog mt-3">With that background, let’s review your Productivity Meter report.</p>
				</div>

				{productivityGoals && productivityGoals.length > 0 ?
					<div>
						<div className="container-with-padding">
							<div className="my-4">
								<span><img src='/images/Productivity_Goals.png' style={styles.titleIcon} /></span>
								<span style={{color: '#fab95e', fontSize:20}} className="font-bold">Productivity Goals Evaluation</span>
							</div>
						</div>
						<ProductivityGoals productivityGoals={productivityGoals} />
					</div>
				: null}

				{symptomsAndRisks && symptomsAndRisks.length > 0 ?
					<div>
						<div className="container-with-padding">
							<div className="my-4">
								<span><img src='/images/Risks.png' style={styles.titleIcon} /></span>
								<span style={{color: '#92d6e1', fontSize:20}} className="font-bold">Your Risks (beta)</span>
							</div>
						</div>
						<SymptomsAndRisks symptomsAndRisks={symptomsAndRisks} />
					</div>
				: null}

				{growthAreas && growthAreas.length > 0 ?
					<div>
						<div className="container-with-padding">
							<div className="my-4">
								<span><img src='/images/Growth_Areas.png' style={styles.titleIcon} /></span>
								<span style={{color: '#f9a576', fontSize:20}} className="font-bold">Your Growth Areas</span>
							</div>
						</div>	
						<GrowthAreas productivityGoals={productivityGoals} growthAreas={growthAreas} />
					</div>
				: null}					
				
				<div className="container-with-padding">
					<div style={{color: '#737373', fontSize:20}} className="font-bold mt-4 mb-3">Next Steps</div>
					<p className="text-blog">Now that you know where you stand in terms of Productivity, you can take make informed decisions about what to do next to address your risks and to drive growth in your target areas.</p>
					<p className="text-blog">We’re here to help you at each step of this journey. If you’d like our assistance, please just press the “Explore Next Steps” button below and we’ll get in touch with you.</p>
				</div>
				
				<div className="container-with-padding text-center">
					<Button type="button" onClick={this.sendAssistanceRequest.bind(this)} className="btn btn-primary m-4">Explore Next Steps</Button>
					<Link to={'/assessments/productivity-meter'} className="btn btn-primary m-4">Productivity Meter Home</Link>
				</div>
			</div>
		);
	}

	render() {

		return (
			<div>
				<div className="inner-container child-container" style={{paddingTop:20}}>
					<div className="bread-crumb">
						<a href="/assessments/">Assessments</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="/assessments/productivity-meter">Productivity Meter</a> &nbsp;
						<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
						<a href="#" onClick={this.updateDisplaySection.bind(this, 'summary')}>Report</a>
					</div>
				</div>
				<div className="page-content container child-container px-0 pt-0">
					{this.renderReport()}
				</div>
			</div>
		);
	}
}

ProductivityMeterReport.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	ProductivityMeterReport
);

const styles = {

	titleIcon: {
		width: 30,
		height: 30,
		marginRight: 10,
		verticalAlign: 'bottom'
	},
	introIcon: {
		width: 50,
		height: 50
	}
}