import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import UserKeyActivityMetricForm from "./UserKeyActivityMetricForm";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class EditUserKeyActivityMetric extends Component {

	constructor(props) {
		super(props);
		this.state = {
			kaMetric: {},
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	componentWillUnmount() {
		// Reset data so that table will re-render
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.setState({
			kaMetric: this.props.kaMetricObj,
		})
	}

	// Handler for editing KA Metric
	handleEditKAMetric(data) {

		data.recordID = this.props.kaMetricObj.id;

		var metricsObj = data;

		metricsObj.keyActivity = this.props.keyActivityID;
		metricsObj.outcome = this.props.outcomeID;

		let postObj = {
			metricsObj: metricsObj,
		};

		this.setState({
			buttonDisabled: true,
		});

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.editUserKeyActivityMetric, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.setFormApiResponse("KeyActivity Metric updated successfully.", false);
					this.props.updateEditedKAMetricInList(response.data.data);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
				// console.log('response...', response);
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
			this.setState({
				buttonDisabled: false,
			});
		});
	}

	render() {

		const { kaMetric } = this.state;
		const { formApiResponse } = this.state;

		if (!Util.isEmpty(kaMetric)) {

			return (
				<div className="section-container">
					<div className="row">
						<div className="col-md-12">
							<div>
								<UserKeyActivityMetricForm
									initialValues={kaMetric}
									onSubmit={this.handleEditKAMetric.bind(this)}
									isEdit={true}
									kaMetricID={this.state.kaMetric.id}
								/>
								{
									!_.isEmpty(formApiResponse)
										?
										<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			);
		} else {

			return (
				<div className="page-content container child-container">
					<ToastContainer position="top-center" transition={Zoom} autoClose={4000} />
					<div className="section-container">
						<div className="row">
							<div className="col-md-12">
								<div>

								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

EditUserKeyActivityMetric.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	EditUserKeyActivityMetric
);
