import React, { Component } from "react";
import { connect } from "react-redux";

class CheckboxSelector extends Component {
	
    handleValueChange(value) {

        if (this.props.onChange) {
            this.props.onChange(value);
        }
        else {
            console.log('Selected checkbox value - ' + value);
        };
    }

    render() {

        let {options, orientation, checkboxGroupName, selectedValues, unSelectedValues = []} = this.props;

        return(
            <div className="custom-checkbox-group" style={{flexDirection: orientation == 'vertical' ? 'column' : 'row'}}>
                {options.map((item, index) => {
                    return(
                        <div key={'checkbox_selector_for_' + checkboxGroupName + '_ind_' + index}>
                            <label className="checkbox-item-container" style={{fontSize: 14}}>
                            {
                                selectedValues && <input 
                                    className="mr-2"
                                    type="checkbox" 
                                    name={checkboxGroupName}
                                    checked={item.value && selectedValues.includes(item.value) ? true : false} 
                                    value={item.value}
                                    onChange={() => this.handleValueChange(item.value)} />
                            }
                            {
                                !selectedValues && unSelectedValues && <input 
                                    className="mr-2"
                                    type="checkbox" 
                                    name={checkboxGroupName}
                                    checked={item.value && unSelectedValues.includes(item.value) ? false : true} 
                                    value={item.value}
                                    onChange={() => this.handleValueChange(item.value)} />
                            }
                            {item.label}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    }
};

CheckboxSelector.propTypes = {
	
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(CheckboxSelector);