import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Util from '../../utils/Util';
import API_Services from '../../utils/API_Services';
import { setLoading } from "../../actions/loadingActions.js";
import TimeEstimateSelect from "../form-inputs/TimeEstimateSelect";

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

/* Flag color codes:
    1 - Peach - default
    2 - Future scops
*/

class TaskFlags extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	setApiResponse(message, hasError) {

		if (this.props.setApiResponse) {
			this.props.setApiResponse(message, hasError);
		};
	}

	updateFlagInServer(flagCode) {

		this.props.setLoading(true);

        let data = this.props.taskObj;
        let flags = data.flags || [];
        let flagCodeIndex = flags.indexOf(flagCode);

        if (flagCodeIndex > -1) {
            flags.splice(flagCodeIndex, 1);
        }
        else {
            flags.push(flagCode);
        };

        data.flags = flags;
		data.recordID = data.id;
		var postObj = data;

		this.setState({
			buttonDisabled: true,
		});
		
		API_Services.httpPOST(UrlConstants.URLS.updateTask, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} 
				else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {
					this.setState({
						promptTaskCompletionTimeModal: false
					},
					() => {
						this.props.onTaskUpdate(response.data.data);
					});
				} 
				else {
					this.setApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setApiResponse("Something went wrong!", true);
			}
			this.props.setLoading(false);
		});
	}

    renderFlags(flagCode) {

        let flags = this.props.taskObj && this.props.taskObj.flags ? this.props.taskObj.flags: [];

        return(
            <div style={{cursor: 'pointer'}} onClick={() => this.updateFlagInServer(flagCode)}>
                {flags.includes(flagCode) ?
                    <i className="fa fa-flag fa-lg color-primary" aria-hidden="true"></i>
                :
                    <i className="fa fa-flag-o fa-lg color-primary" aria-hidden="true"></i>
                }
            </div>
        );
    }

    render() {

        return (
			<div>
				<div className="d-flex align-middle justify-content-around">
                    {this.renderFlags(1)}
				</div>
			</div>
		)
    }
}

TaskFlags.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	TaskFlags
);