import PropTypes from 'prop-types';
import React, { Component } from 'react'

import Util from '../../utils/Util';
import { DragSource } from 'react-dnd';
import BigCalendar from 'react-big-calendar';

/* drag sources */
let eventSource = {
	beginDrag(props) {
		return Object.assign({},
			{ event: props.event },
			{ anchor: 'drop' }
		)
	}
}

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
}

const propTypes = {
	connectDragSource: PropTypes.func.isRequired,
	isDragging: PropTypes.bool.isRequired,
	event: PropTypes.object.isRequired
}

class DraggableTimeBlockEvent extends Component {

	onDoubleClick = () => {
		this.props.onDoubleClick(false, this.props.event);
	}

	render() {

		let { connectDragSource, isDragging, event, isDraggable, isAllCompleted } = this.props;
		let EventWrapper = BigCalendar.components.eventWrapper;
		let { durationInMinutes, scheduled, completed, timeBlockTitle, sessionsPerWeek, regularity, durationInMinutesPerWeek } = event;
		let isNonWeeklyTB = regularity == 'non-weekly' ? true : false;
		let isDailyTB = regularity == 'daily' ? true : false;
		let title = '';

		if (timeBlockTitle && event.outcomeAbbr && event.keyActivityTitle) {
			title = timeBlockTitle;
			title += ' (' + event.outcomeAbbr + ': ' + event.keyActivityTitle + ')';
		}
		else if (event.title) {
			title = event.title;
		};

		if (isDraggable) {

			return (
				<EventWrapper event={event}>
					{
						connectDragSource(
							<div className="time-block-card" onDoubleClick={this.onDoubleClick}>
								<div className="time-block-card-sub-section" style={{ opacity: isDragging ? 0.5 : 1 }}>
									<p className="mb-2 font-bold">{title}</p>
									{/* <p className="mb-2 font-bold">{title.slice(0, 28)}{title.length > 28 ? "" : null}</p> */}
									{/* <p>{timeBlockTitle}</p> */}
									<div className="duration-scheduled-div">
										<p className="pb-1">
											<span style={{...styles.regularityLabel, ...{backgroundColor: isNonWeeklyTB || isDailyTB ? '#FAB95E' : '#AECF7A'}}}>
											{isNonWeeklyTB && 'NW'} 
											{isDailyTB && 'D'}
											{!isNonWeeklyTB && !isDailyTB && 'W'}
											</span>
											<span>{durationInMinutes ? Util.convertTimeToReadableText(durationInMinutes): null}</span>
										</p>
										<p className="pt-1 border-top text-center">{scheduled}</p>
										<p className="pt-1 border-top text-center">{completed}</p>
									</div>
								</div>
							</div>
						)
					}
				</EventWrapper>
			);
		} else {

			return (
				<EventWrapper event={event}>
					<div className="time-block-card reset-cursor" onDoubleClick={this.onDoubleClick}>
						<div className="time-block-card-sub-section" style={{ opacity: isDragging ? 0.5 : 1 }}>
							<p className="mb-2 font-bold">{title}</p>
							{/* <p className="mb-2 font-bold">{title.slice(0, 28)}{title.length > 28 ? "" : null}</p> */}
							{/* <p>{timeBlockTitle}</p> */}
							<div className="duration-scheduled-div">
								<p className="pb-1">
									<span style={{...styles.regularityLabel, ...{backgroundColor: isNonWeeklyTB || isDailyTB ? '#FAB95E' : '#AECF7A'}}}>
									{isNonWeeklyTB && 'NW'} 
									{isDailyTB && 'D'}
									{!isNonWeeklyTB && !isDailyTB && 'W'}
									</span>
									<span>{durationInMinutes ? Util.convertTimeToReadableText(durationInMinutes): null}</span>
								</p>
								<p className="pt-1 border-top text-center"> { event['totalScheduled'] >= event['sessionsPerWeek'] ? <i className="fa fa-check color-green" aria-hidden="true"></i> : ''} {scheduled}</p>
								<p className="pt-1 border-top text-center">{isAllCompleted ? <i className="fa fa-check color-green" aria-hidden="true"></i> : null} {completed}</p>
							</div>
						</div>
					</div>
				</EventWrapper>
			);
		}

	}
}

DraggableTimeBlockEvent.propTypes = propTypes;

export default DragSource('event', eventSource, collectSource)(DraggableTimeBlockEvent);

const styles = {

	regularityLabel: {
		color: '#fff',
		padding: '2px 4px',
		borderRadius: 4,
		fontSize: 8,
		marginRight: 8
	}
}