import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class CompassArchivedList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			archivedList: [],
			formApiResponse: {
				className: "",
				message: "",
			}
		};
	}

	componentDidMount() {
		this.updateFocusAreaList(this.props);
	}

	componentWillReceiveProps(props) {
		this.updateFocusAreaList(props);
	}

	updateFocusAreaList(props) {

		const archivedList = props.archivedList;

		this.setState({
			archivedList: archivedList || [],
		});
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	handleSubmit() {

		let { selectedCompassId } = this.state;

		if (!selectedCompassId) return alert('Invalid Request!');

		let postObj = {
			'newCurrentCompassID' : selectedCompassId, // make this as current compass
		};

		this.props.setLoading(true);

		API_Services.httpPOST(UrlConstants.URLS.switchCurrentCompass, postObj, (err, response) => {

			if (err) {

                if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
					this.toggleWarningPopup();
				} 
                else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				};
				this.reload();
			}
			else if (response.data && response.data.status && response.data.data) {
				// reset compass
				this.toggleWarningPopup();
				this.reload();
			}
			this.props.setLoading(false);
		});
	}

	reload() {
		window.location = "/compass/";
	}

	// Renders archived lists
	renderArchivedList() {

		const archivedList = this.state.archivedList;

		if (!archivedList || archivedList.length == 0) return <div></div>;

		return (
			<div className="App">
				<br></br>
				<br></br><hr></hr>
				<h6 style={{fontSize: '17px'}}>Compass - Archived List</h6>
				<br></br>
				<header className="App-header table-responsive">
					<table className="table-curved">
						<thead>
							<tr>
								<th className="text-center" style={{ width: 100 }}>#</th>
								<th className="text-center" style={{ width: 'auto' }}>Title</th>
								<th className="text-center" style={{ width: 300 }}>Action</th>
							</tr>
						</thead>
						<tbody>
							{archivedList.map((item, index) => {

								if(!item.archivedDate || !item.createdAt) return null;

								let createdDt = moment(item.createdAt).format('MMM Do, YYYY');
								let archivedDt = moment(item.archivedDate).format('MMM Do, YYYY');
								let title = "Compass, Created: " + createdDt + " , Archived: " + archivedDt;
								let navURL = "/compass/" + item.id;

								return (
									<tr key={index}>
										<td className="text-center" style={{ width: 100 }}>{index + 1}</td>
										<td className="text-center" style={{ width: 'auto'}}>{title}</td>
										<td className="text-center" style={{ width: 300 }}>
											<button 
												type="button"
												onClick={this.toggleWarningPopup.bind(this, item.id)} 
												className="btn btn-primary btn-xs">{" Make This Current "}
											</button>
											&nbsp; &nbsp;
											<Link to={navURL} className="btn btn-primary btn-xs">View & Edit</Link>
										</td>
									</tr>
								)}
							)}
						</tbody>
					</table>
				</header>
			</div>
		);
	}

	/* Toggle warning Popup */
	toggleWarningPopup(id) {
		this.setState({
			showWarningModal: !this.state.showWarningModal,
			selectedCompassId: id || null
		});
	}

	/* show/hide Heads Up Popup */
	renderWarningPopup() {
		return (
			<Modal
				isOpen={this.state.showWarningModal}
				toggle={this.toggleWarningPopup.bind(this)}>
				<ModalHeader >{"Heads up!"}</ModalHeader>
				<ModalBody>
					<div className="mb-4">
						<p>Are you sure you want to make this as Current Compass.</p>
						<div className="small">* The current compass will archive automatically.</div>
					</div>
					<div className="popup-footer-section">
						<div>
							<Button color="primary" title="Make This Current Compass" onClick={(e) => this.handleSubmit()} className="btn-md btn-sm mr-2">Make This As Current Compass</Button>
						</div>
						<Button color="secondary" title="Cancel" onClick={this.toggleWarningPopup.bind(this)} className="btn-sm">Cancel</Button>
					</div>
				</ModalBody>
			</Modal>
		)
	}

	render() {

		return (
			<div>
				<div className="section-container">
					{this.renderArchivedList()}
					{this.renderWarningPopup()}
				</div>
			</div>
		);
	}
}

CompassArchivedList.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	// focusareas: state.focusareas,
});

export default connect(mapStateToProps, { setLoading })(
	CompassArchivedList
);
