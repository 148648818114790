import React from "react";
import moment from "moment";
import Util from "../../utils/Util";

const CustomEvent = (props) => {

	let title = Util.getEventTitle(props.event);

	return (
		<div>
			<span>{title}</span> <br/>
			{
				/**
				 * Add extra information here
				 */
			}
			<div className="rbc-event-label">{ moment(props.event.start).format('LT') + ' - '+moment(props.event.end).format('LT')}</div>
		</div>
	)
}

export default CustomEvent;