import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getUserOutcomes } from "../../actions/outcomeActions";
import { setLoading } from "../../actions/loadingActions.js";
import OutcomesList from "./OutcomesList";
import API_Services from '../../utils/API_Services';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class OutcomeDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		this.getUserOutcomes();
	}

	setApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			apiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				apiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	getUserOutcomes() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getUserOutcomes, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setApiResponse(err.response.data, true)
				} else {
					this.setApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				this.props.getUserOutcomes(response);
			} else {
				this.setApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	renderOutcomeList() {

		const { outcomes } = this.props.outcomes;

		if (outcomes && outcomes.length > 0) {
			return (
				<OutcomesList
					outcomes={this.props.outcomes}
				/>
			);
		} else {
			return (<p className="text-center">Please add project using the above button</p>)
		}
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/projects/">Projects</a>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="inner-container">
							<div className="text-center">
								<Link to="/projects/add" className="btn btn-primary mb-2" title="Add Project">Add Project &nbsp; <i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="inner-container-table" style={{marginTop: 20}}>
								{this.renderOutcomeList()}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

OutcomeDashboard.propTypes = {
	getUserOutcomes: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	outcomes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	outcomes: state.outcomes,
});

export default connect(mapStateToProps, { setLoading, getUserOutcomes })(
	OutcomeDashboard
);
