import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';
import '../../assets/styles/assessmentStyles.css';

var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class IIA extends Component {

	constructor(props) {
		super(props);
		this.state = {
			assmntQuestions: [],
			updatedCount: 0,
			submitBtnDisabled: false
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;

		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 5000);
	}

	componentDidMount() {

		this.props.setLoading(true);

		let postObj = {};

		API_Services.httpPOST(UrlConstants.URLS.getAssessmentPrompts, postObj, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					this.setState({
						assmntQuestions: response.data.data,
					});
				} 
				else {
					this.setFormApiResponse(response.data.message, true);
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			};

			this.props.setLoading(false);
		});
	}

	updateAssessmentEntryValue(assessmentEntry, textResponse, e) {

		var value = e.target.value;
		var data = this.state.assmntQuestions;
		var answeredCount = this.state.updatedCount;

		if (!assessmentEntry.isAnswered) {
			answeredCount = answeredCount + 1;
		}

		data[assessmentEntry.questionId - 1].selectedAnswer = parseInt(value);
		data[assessmentEntry.questionId - 1].score = parseInt(value);
		data[assessmentEntry.questionId - 1].textResponse = textResponse;
		data[assessmentEntry.questionId - 1].isAnswered = true;
		data[assessmentEntry.questionId - 1].associatedQuestion = data[assessmentEntry.questionId - 1].question;

		this.setState({
			assmntQuestions: data,
			updatedCount: answeredCount
		},
		() => {

			var nextQuestion = document.getElementById('Q_' + assessmentEntry.questionId);
			nextQuestion.scrollIntoView({ behavior: "smooth" });
		});
	}

	handleSubmit() {

		if (this.state.updatedCount < this.state.assmntQuestions.length) {
			alert("It looks as though you missed a question! Please answer all questions to move forward");
		}
		else {

			this.props.setLoading(true);
			this.setState({submitBtnDisabled: true});
			
			let postObj = {
				assessmentResults: this.state.assmntQuestions,
			};

			API_Services.httpPOST(UrlConstants.URLS.saveAssessmentResponse, postObj, (err, response) => {

				if (err) {
					
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} 
					else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} 
				else if (response.data) {

					if (response.data.status && response.data.data) {
						this.setFormApiResponse("Response saved successfully.", false);
						this.props.setLoading(false);
						return window.location.replace("/assessments/inner-insight-report/" + response.data.data.assessmentId);
					} 
					else {
						this.setFormApiResponse(response.data.message, true);
					}
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
				};

				this.props.setLoading(false);
				this.setState({submitBtnDisabled: false});
			});
		}
	}

	renderTips() {

		return (
			<div>
				<div className="assessment-intro">
					<div className="question-container">
						<div className="tips-section-title">In this section we are going to show you a set of statements related to your tendencies.</div>
						<div className="tips-section-title">Please rate them on the 7-point scale ranging from <i>Very Untrue</i> to <i>Very True</i> based on how much they are true for you.</div>
						<div className="radio-container">
							<div className="radio-div"><div><input type="radio" className="input-radio selected" disabled={true} /><div>Very Untrue</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Mostly Untrue</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Somewhat Untrue</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Neutral</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Somewhat True</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Mostly True</div></div></div>
							<div className="radio-div"><div><input type="radio" className="input-radio disabled" disabled={true} /><div>Very True</div></div></div>
						</div>
						<div className="tips-container">Answer this section based on how you feel or would act right now.</div>
					</div>
				</div>
			</div>
		)
	}

	renderAnswers(question) {

		var score = question.selectedAnswer;

		if (question.reviseScore) {
			return (
				<div key={question.questionId}>
					<div className="radio-div first"><div><input type="radio" value="6" checked={score == 6 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Very True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption">Very Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="5" checked={score == 5 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Mostly True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Mostly Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="4" checked={score == 4 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Somewhat True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Somewhat Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="3" checked={score == 3 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Neutral')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Neutral</div></div></div>
					<div className="radio-div"><div><input type="radio" value="2" checked={score == 2 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Somewhat Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Somewhat True</div></div></div>
					<div className="radio-div"><div><input type="radio" value="1" checked={score == 1 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Mostly Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Mostly True</div></div></div>
					<div className="radio-div last"><div><input type="radio" value="0" checked={score == 0 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Very Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption">Very True</div></div></div>
				</div>
			)
		}
		else {
			return (
				<div key={question.questionId}>
					<div className="radio-div first"><div><input type="radio" value="0" checked={score == 0 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Very Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption">Very Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="1" checked={score == 1 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Mostly Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Mostly Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="2" checked={score == 2 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Somewhat Untrue')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Somewhat Untrue</div></div></div>
					<div className="radio-div"><div><input type="radio" value="3" checked={score == 3 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Neutral')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Neutral</div></div></div>
					<div className="radio-div"><div><input type="radio" value="4" checked={score == 4 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Somewhat True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Somewhat True</div></div></div>
					<div className="radio-div"><div><input type="radio" value="5" checked={score == 5 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Mostly True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption desktop">Mostly True</div></div></div>
					<div className="radio-div last"><div><input type="radio" value="6" checked={score == 6 ? true : false} onChange={this.updateAssessmentEntryValue.bind(this, question, 'Very True')} name={question.questionId} className="input-radio-question " /><div className="radio-caption">Very True</div></div></div>
				</div>
			)
		}
	}

	renderQuestions(question, index) {

		return (
			<div key={index} id={'Q_' + question.questionId}>
				<div className="questions-section">
					<div className="question-container">
						<div style={SharedStyles.questionCount}>{question.questionId} of {this.state.assmntQuestions.length}</div>
						<div className="question-title">{question.question}</div>
						<div className="radio-container">
							{this.renderAnswers(question)}
						</div>
					</div>
					<div className="bottom-div"></div>
				</div>
			</div>
		)
	}

	renderContainer() {

		var questionsWrapper = [];

		this.state.assmntQuestions.map((item, index) => {
			questionsWrapper.push(this.renderQuestions(item, index));
		});

		return questionsWrapper;
	}

	render() {

		return (
			<div className="page-content container child-container">
				<div className="bread-crumb">
					<a href="/assessments/">Assessments</a> &nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<a href="/assessments/inner-insight">Inner Insight</a> &nbsp;
					<i className="fa fa-angle-right" aria-hidden="true"></i> &nbsp;
					<a href="#">New</a>
				</div>
				{this.state.assmntQuestions.length > 0 ?
					<div className="section-container">
						<div className="form-container">
							<div className="emostateAssessment">
								{this.renderTips()}
								{this.renderContainer()}
								<div className="submit-button">
									<button className="btn sub-button" disabled={this.state.submitBtnDisabled} onClick={this.handleSubmit.bind(this)} type="submit">Submit</button>
								</div>
							</div>
						</div>
					</div>
				: null}
			</div>
		);
	}
}

IIA.propTypes = {
	setLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	IIA
);
