import {
	RESET_TIME_BLOCK,
	GET_TIME_BLOCK,
	GET_TIME_BLOCKS,
	DELETE_TIME_BLOCK,
} from "../actions/types.js";

const initialState = {
	timeblocks: [],
	timeblock: {},
};

export default function (state = initialState, action) {

	switch (action.type) {

		case RESET_TIME_BLOCK:
			return {
				...initialState,
			}

		case GET_TIME_BLOCK:
			return {
				...state,
				timeblock: action.payload,
			};

		case GET_TIME_BLOCKS:
			return {
				...state,
				timeblocks: action.payload,
			};

		case DELETE_TIME_BLOCK:
			return {
				...state,
				timeblocks: state.timeblocks.filter(timeblock => timeblock.id !== action.payload)
			};

		default:
			return state;
	}
}
