import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import TimeEstimateSelect from "../form-inputs/TimeEstimateSelect";
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import Util from '../../utils/Util';
import { parseInt } from "lodash";
import SharedStyles from '../../assets/styles/SharedStyles';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AddOutcome from "./AddOutcome"
import AddKeyActivity from "./AddKeyActivity.js";
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class UTaskForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				task: "",
				timeEstimateInMinutes: null,
				isCompleted: false,
				flags: [],
				actualCompletionTimeInMinutes: 0,
				notes: "",
			},
			formErrors: {
				task: null,
				timeEstimateInMinutes: null,
				actualCompletionTimeInMinutes: null,
				notes: null,
			},
			notesEditorState: EditorState.createEmpty(),
			outcomesWithPopulatedData: [],
			outcomeOptions: [],
			keyActivityOptions: [],
			selectedOutcome: null,
			selectedKeyActivity: null,
			buttonDisabled: false,
			isEdit: false,
		};
	}

	componentDidMount() {

		// Render initial values if present
		if (!Util.isEmpty(this.props.initialValues)) {

			const { notes } = this.props.initialValues;

			var formObj = {};

			formObj = {
				...this.props.initialValues,
				...formObj,
			};

			this.setState({
				form: formObj,
				isEdit: true,
				notesEditorState: notes ? EditorState.createWithContent(convertFromRaw(notes)) : EditorState.createEmpty(),
			});
		}
		this.getOutcomesWithPopulatedData();
	}

	getOutcomesWithPopulatedData() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getOutcomesWithPopulatedData, (err, response) => {

			if (err) {
				
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} 
				else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} 
			else if (response.data) {

				if (response.data.status) {

					let outcomesWithPopulatedData = response.data.data || [];
					let outcomeOptions = [];

					_.map(outcomesWithPopulatedData, (outcomeObj) => {
						
						outcomeOptions.push({
							label: outcomeObj.outcomeTitle,
							value: outcomeObj.id
						});
					});

					outcomeOptions.push({
						label: "Add New Project",
						value: "ADD_NEW_OUTCOME",
					})

					this.setState({
						outcomesWithPopulatedData: outcomesWithPopulatedData,
						outcomeOptions: outcomeOptions
					}, () => {

						if(!Util.isEmpty(this.props.initialValues)) {

							let outcome = this.props.initialValues['outcome'];
							let keyActivity = this.props.initialValues['keyActivity'];

							if( outcome && keyActivity) {

								let keyActivityOptions = [];
								let oldKeyActivity = null;
								let selectedOutcome = {};
								let selectedKeyActivity = {};

								oldKeyActivity =  keyActivity['id'];

								selectedOutcome = {
									label: outcome['outcomeTitle'],
									value: outcome['id'],
								}
	
								selectedKeyActivity = {
									label: keyActivity['title'],
									value: keyActivity['id'],
								}

								let filterObjForTheSelectedOutcome = _.find(outcomesWithPopulatedData, {id: outcome['id']});

								if (filterObjForTheSelectedOutcome && filterObjForTheSelectedOutcome.keyActivities) {

									_.map(filterObjForTheSelectedOutcome.keyActivities, (activityObj) => {

										keyActivityOptions.push({
											label: activityObj.title,
											value: activityObj.id
										});
									});

									if (!outcome.productivityOutcome) {
										keyActivityOptions.push({
											label: "Add New Key Activity",
											value: "ADD_NEW_KA",
										});
									};
								};

								this.setState({
									selectedOutcome: selectedOutcome,
									selectedKeyActivity: selectedKeyActivity,
									oldKeyActivity: oldKeyActivity,
									keyActivityOptions: keyActivityOptions,
								});
							}							
						}
					});
				}
			} 
			else {
				this.setFormApiResponse("Something went wrong!", true);
			}
			this.props.setLoading(false);
		});
	}

	handleOutcomeSelectionChange(outcome) {

		if (outcome && outcome["value"] && outcome["value"] == "ADD_NEW_OUTCOME") {
			// Display Section - Add New Outcome
			return this.setState({ displaySubSection: outcome["value"]})
		}

		const { formErrors, outcomesWithPopulatedData } = this.state;
		let keyActivityOptions = [];

		if (outcome && outcome.value) {

			let filterObjForTheSelectedOutcome = _.find(outcomesWithPopulatedData, {id: outcome.value});
		
			if (filterObjForTheSelectedOutcome && filterObjForTheSelectedOutcome.keyActivities) {

				_.map(filterObjForTheSelectedOutcome.keyActivities, (activityObj) => {

					keyActivityOptions.push({
						label: activityObj.title,
						value: activityObj.id
					});
				});

				if (!filterObjForTheSelectedOutcome.productivityOutcome) {
					keyActivityOptions.push({
						label: "Add New Key Activity",
						value: "ADD_NEW_KA",
					});
				};
			};
		};
		
		formErrors['keyActivity'] = null;

		this.setState({
			selectedOutcome: outcome,
			keyActivityOptions: keyActivityOptions,
			selectedKeyActivity: null,
			formErrors: formErrors
		});
	}

	handleKeyActivitySelection(keyActivity) {

		if (keyActivity && keyActivity["value"] && keyActivity["value"] == "ADD_NEW_KA") {
			// Display Section - Add New keyActivity
			return this.setState({ displaySubSection: keyActivity["value"]})
		}

		const { formErrors } = this.state;
		formErrors['keyActivity'] = null;
		
		this.setState({
			selectedKeyActivity: keyActivity,
			formErrors: formErrors
		});
	}

	// Handler for input field change event
	handleInputField(event) {

		var { name, value } = event.target;
		const { form, formErrors } = this.state;

		if (name == "sessionsPerWeek") {
			if (!isNaN(parseInt(value))) {
				value = parseInt(value);
			};
		}

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, 
		() => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	// Handler for select field change event
	handleSelectField(name, value) {

		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});
	}

	handleStatusChange(name, updatedStatus) {

		const { form } = this.state;

		var formObj = {...form};
		
		if (updatedStatus === true) {
			formObj['isCompleted'] = true;
			formObj['completionDateTime'] = new Date();
			formObj['actualCompletionTimeInMinutes'] = formObj.timeEstimateInMinutes;
		}
		else {
			formObj['isCompleted'] = false;
			formObj['completionDateTime'] = '';
			formObj['actualCompletionTimeInMinutes'] = 0;
		};

		this.setState({
			form: formObj,
		});
	}

	handleFlagChange(name, flagCode) {

		const { form } = this.state;

		var formObj = {...form};
		
		let flags = formObj.flags || [];
        let flagCodeIndex = flags.indexOf(flagCode);

        if (flagCodeIndex > -1) {
            flags.splice(flagCodeIndex, 1);
        }
        else {
            flags.push(flagCode);
        };

		formObj.flags = flags;

		this.setState({
			form: formObj,
		});
	}

	// Validate Field
	validateField(name, value, refValue) {

		const { isEdit, form } = this.state;
		var errorMsg = null;

		switch (name) {

			case "task":
				if (!value) errorMsg = "Please enter Task Name";
				break;

			case "timeEstimateInMinutes":
				if (!value) errorMsg = "Please enter Time Estimate";
				break;

			case "actualCompletionTimeInMinutes":
				if (isEdit && form['isCompleted'] === true && !value) errorMsg = "Please specify actual time spent for completing this task.";
				break;

			default:
				break;
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Task submit
	handleTaskSubmit(e) {
		
		e.preventDefault();

		const { form, formErrors, notesEditorState } = this.state;
		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;
		} 
		else {

			var formCopy = _.cloneDeep(form);

			// Get only value from select object
			_.forOwn(formCopy, function (value, key) {

				if (key == 'flags') return; // Don't do any manipulations for flags field.

				if (Array.isArray(value)) {

					var newArr = [];
					value.forEach((eachValue, index) => {
						newArr.push(eachValue.value);
					});
					formCopy[key] = newArr;
				} 
				else if (_.isObject(value)) {
					formCopy[key] = value.value;
				}
			});

			formCopy['notes'] = notesContent;

			if (this.state.isEdit === false) {
				
				const { selectedOutcome, selectedKeyActivity, outcomesWithPopulatedData } = this.state;
				
				if (selectedOutcome && !selectedKeyActivity) {
					formErrors['keyActivity'] = 'Please select both the Project and the Key Activity to assign this task under them. Else deselect both to make this as an independent [or] unassigned task.'
					this.setState({formErrors: formErrors});
				}
				else if (selectedOutcome && selectedKeyActivity) {

					let outcomeObj = _.find(outcomesWithPopulatedData, {id: selectedOutcome.value});
					let keyActivityDetail = _.find(outcomeObj.keyActivities, {id: selectedKeyActivity.value});

					formCopy['outcomeID'] = outcomeObj.id;
					formCopy['outcomeAbbreviation'] = outcomeObj.abbreviation;
					formCopy['includeInWeeklyPlanStatusOfOutcome'] = outcomeObj.includeInWeeklyPlan;
					formCopy['includeInWeeklyPlanStatusOfKeyActivity'] = keyActivityDetail.includeInWeeklyPlan;
					formCopy['keyActivityID'] = keyActivityDetail.id;
					formCopy['keyActivity'] = keyActivityDetail.id;
					this.props.onSubmit(formCopy);
				}
				else {
					formCopy['keyActivityID'] = null;
					this.props.onSubmit(formCopy);
				};
			}
			else {

				const { selectedOutcome, selectedKeyActivity } = this.state;

				if (selectedOutcome && !selectedKeyActivity) {
					formErrors['keyActivity'] = 'Please select both the Project and the Key Activity to assign this task under them. Else deselect both to make this as an independent [or] unassigned task.'
					this.setState({formErrors: formErrors});
				}
				else if (selectedOutcome && selectedKeyActivity) {

					formCopy['keyActivityID'] = selectedKeyActivity.value;
					formCopy['oldKeyActivity'] = this.state.oldKeyActivity;

					// If the oldKeyActivity & keyActivity are the same, set the oldKeyActivity as null. This is to preserve the task ranks under that keyActivity
					if(formCopy['keyActivityID'] == formCopy['oldKeyActivity']) {
						formCopy['oldKeyActivity'] = null;
					}

					// After submit current KA will become old KA
					let oldKeyActivity = selectedKeyActivity.value;
					this.setState({
						oldKeyActivity: oldKeyActivity,
					})

					this.props.onSubmit(formCopy);
				}
				else {
					formCopy['keyActivityID'] = null;
					formCopy['oldKeyActivity'] = this.state.oldKeyActivity;

					// After submit current KA will become old KA
					let oldKeyActivity = null;
					this.setState({
						oldKeyActivity: oldKeyActivity,
					})

					this.props.onSubmit(formCopy);
				};

			};
		}
	}

	// Handler for new Outcome submit
	handleNewOutcomeSubmit = (createdOutcome, displaySubSection = "" ) => {

		this.props.setLoading(true);
		this.props.reloadData();

		const { outcomeOptions, outcomesWithPopulatedData } = this.state;
		const newOutcome = {
			label: createdOutcome.outcomeTitle,
			value: createdOutcome.id,
		}

		outcomeOptions.splice((outcomeOptions.length - 1), 0, newOutcome); // push new outcome
		outcomesWithPopulatedData.push(createdOutcome);

		var keyActivityOptions = [{
			label: "Add New Key Activity",
			value: "ADD_NEW_KA",
		}];

		this.setState({
			displaySubSection: displaySubSection,
			outcomeOptions: outcomeOptions,
			keyActivityOptions: keyActivityOptions,
			selectedOutcome: newOutcome,
			selectedKeyActivity: null,
		})
	}

	// Handler for new KA submit
	handleNewKeyActivitySubmit = (createdKA, displaySubSection ="" ) => {

		this.props.setLoading(true);
		this.props.reloadData();

		const { keyActivityOptions, outcomesWithPopulatedData, selectedOutcome } = this.state;
		const newKA = { label: createdKA.title, value: createdKA.id }
		const idx = _.findIndex(outcomesWithPopulatedData, ['id', selectedOutcome["value"]]);

		if (_.isEmpty(outcomesWithPopulatedData[idx].keyActivities)){
			outcomesWithPopulatedData[idx].keyActivities = [];
		}
		outcomesWithPopulatedData[idx].keyActivities.push(createdKA);

		keyActivityOptions.splice((keyActivityOptions.length - 1), 0, newKA);

		this.setState({
			displaySubSection: displaySubSection,
			keyActivityOptions: keyActivityOptions,
			selectedKeyActivity: newKA, // select newly created KA 
		})
	}

	handleAddNewOnCancel = () => {
		return this.setState({ displaySubSection: ""})
	}

	renderUTasKForm() {

		const { form, formErrors, notesEditorState, isEdit, outcomeOptions, keyActivityOptions } = this.state;

		return (
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<label>Task Name <span className="text-danger">*</span></label>
							<div>
								<input type="text" onChange={this.handleInputField.bind(this)} className="form-control" name="task" value={form['task']} />
								<p className="text-danger">{formErrors['task'] &&
									<span className="err">{formErrors['task']}</span>}</p>
							</div>
						</div>
						<div>
							<TimeEstimateSelect
								handleTimeEstimateSelect={this.handleSelectField.bind(this)}
								value={_.isObject(form.timeEstimateInMinutes) && form.timeEstimateInMinutes["value"] ? form.timeEstimateInMinutes["value"] : form.timeEstimateInMinutes}
								error={formErrors.timeEstimateInMinutes}
								isRequired={true}
							/>
						</div>
						{isEdit?
							<div className="form-group">
								<label>Status</label>
								<div className="cursor-pointer" onClick={() => this.handleStatusChange('isCompleted', !form['isCompleted'])}>
									{form['isCompleted'] === true ?
										<div><i className="fa fa-check-circle fa-lg color-primary mr-2" aria-hidden="true"></i>Completed</div> :
										<div><i className="fa fa fa-circle-o fa-lg color-primary mr-2" aria-hidden="true"></i>Open</div>
									}
								</div>
							</div>
						: null}
						{isEdit && form['isCompleted'] === true ?
							<TimeEstimateSelect
								handleTimeEstimateSelect={(label, selectedTime) => this.handleSelectField('actualCompletionTimeInMinutes', selectedTime.value)}
								value={form.actualCompletionTimeInMinutes}
								error={formErrors.actualCompletionTimeInMinutes}
								label={'Actual Time'}
								isRequired={true}
							/>
						: null}
						{isEdit?
							<div className="form-group">
								<label>Flag</label>
								<div className="cursor-pointer" onClick={() => this.handleFlagChange('flags', 1)}>
									{form['flags'] && form['flags'].includes(1) ?
										<div><i className="fa fa-flag fa-lg color-primary mr-2" aria-hidden="true"></i>Flagged</div> :
										<div><i className="fa fa-flag-o fa-lg color-primary mr-2" aria-hidden="true"></i>Not flagged</div>
									}
								</div>
							</div>
						: null}
						<div className="form-group">
							<label>Project</label>
							<ReactSelect
								name="outcome"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={outcomeOptions}
								value={this.state.selectedOutcome}
								removeSelected={true}
								autosize={true}
								isClearable={true}
								onChange={(outcome) => this.handleOutcomeSelectionChange(outcome)}
								placeholder="Select Project..."
							/>
						</div>
						<div className="form-group">
							<label>Key Activity</label>
							<ReactSelect
								name="keyActivity"
								styles={SharedStyles.selectBoxStyles}
								closeOnSelect={false}
								options={keyActivityOptions}
								value={this.state.selectedKeyActivity}
								removeSelected={true}
								autosize={true}
								isClearable={true}
								onChange={(activity) => this.handleKeyActivitySelection(activity)}
								placeholder="Select Key Activity..."
							/>
							<p className="text-danger">{formErrors['keyActivity'] && <span className="err">{formErrors['keyActivity']}</span>}</p>
						</div>
						<div className="form-group">
							<label>Notes</label>
							<div className="htmlEditor">
								<Editor
									editorState={notesEditorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={(editorState) =>
										this.setState({
											notesEditorState: editorState,
										})
									}
								/>
							</div>
						</div>
					</div>
				</div>

				<button type="button" onClick={this.handleTaskSubmit.bind(this)} className="btn btn-primary mb-2">{isEdit ? "Save" : "Save & Close"}</button>
				{
					!_.isEmpty(this.props.formApiResponse)
						?
						<p className={this.props.formApiResponse.className}><span>{this.props.formApiResponse.message}</span></p>
						: null
				}

				{
					this.props.itemPosition ?
						<div className="small text-info">* This item will be added at the position {this.props.itemPosition}.</div>
						: null
				}
			</form>
		);
	}

	render() {

		const {displaySubSection} = this.state

		if (displaySubSection == 'ADD_NEW_OUTCOME') {
			return(
				<AddOutcome 
					onClose={this.props.onClose}
					onCancel={this.handleAddNewOnCancel}
					onSuccussSubmit={this.handleNewOutcomeSubmit}
					{...this.props}
					{...this.state} />
			)
		}
		else if (displaySubSection == 'ADD_NEW_KA') {
			return(
				<AddKeyActivity 
					onClose={this.props.onClose}
					onCancel={this.handleAddNewOnCancel}
					onSuccussSubmit={this.handleNewKeyActivitySubmit}
					{...this.props}
					{...this.state} />
			)
		} else {
			return this.renderUTasKForm()
		}
	}
}

UTaskForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { setLoading })(
	UTaskForm
);
