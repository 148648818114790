import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import ReactSelect from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SharedStyles from '../../assets/styles/SharedStyles';
import { setLoading } from "../../actions/loadingActions.js";
import API_Services from '../../utils/API_Services';
import { history } from '../../utils/history';
import TextareaAutosize from 'react-textarea-autosize';

import Util from '../../utils/Util';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class AddFocusArea extends Component {

	constructor(props) {
		super(props);
		this.state = {
			form: {
				focusAreaID: '',
				type: 'system-default',
				notes: '',
			},
			formErrors: {
				focusAreaID: null,
				type: null,
				notes: null,
			},
			buttonDisabled: false,
			formApiResponse: {
				className: "",
				message: "",
			},
			systemDefaultFocusAreas: [],
			userFocusAreas: [],
			notesEditorState: EditorState.createEmpty(),
		};
	}

	resetForm() {

		var formObj = { ...this.state.form };

		let recursiveReset = (obj) => {
			Object.keys(obj).map(key => {
				// Test if it's an Object
				if (obj[key] === Object(obj[key])) {
					recursiveReset(obj[key])
					return
				}
				if (obj[key] instanceof Array) obj[key] = []
				else obj[key] = ""
			})
		}

		recursiveReset(formObj);

		this.setState({
			form: formObj,
		});
	}

	componentDidMount() {

		const { timescale, focusAreaPrompts } = this.props;

		if (focusAreaPrompts && focusAreaPrompts.length > 0) {

			this.props.setLoading(true);

			API_Services.httpGET(UrlConstants.URLS.getFocusAreasForTheUser, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {

					const { form, formErrors } = this.state;

					// Generate/define question state
					var stateObj = {
						form: { ...form },
						formErrors: { ...formErrors },
						dynamicqrendered: false,
						timescale: timescale,
						userFocusAreas: response.data.data,
					};

					focusAreaPrompts.map((value, index) => {
						stateObj['form'][value.uniqueID] = "";
						stateObj['formErrors'][value.uniqueID] = "";
					});

					stateObj['dynamicqrendered'] = true;
					this.setState(stateObj);

				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
			});
		}
	}

	componentWillUnmount() {
		// Reset form data
		this.resetForm();
	}

	// Handler for input field change event
	handleInputField(event) {

		const { name, value } = event.target;
		const { form, formErrors } = this.state;

		var formObj = {};
		formObj = {
			...form,
			[name]: value,
		};

		this.setState({
			form: formObj,
		}, () => {

			const errorMsg = this.validateField(name, value);
			var formErrorsObj = { ...formErrors, [name]: errorMsg };
			this.setState({ formErrors: formErrorsObj });
		});

	}

	// Validate Field
	validateField(name, value, refValue) {

		var errorMsg = null;

		if (name != "notes") {
			if (!value) errorMsg = "This field is mandatory";
		}

		return errorMsg;
	};

	// Validates form
	validateForm(form, formErrors, validateFunc) {

		const errorObj = {};

		Object.keys(formErrors).map(x => {

			let refValue = null;

			const msg = validateFunc(x, form[x], refValue);
			if (msg) errorObj[x] = msg;
		});

		return errorObj;
	};

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
		setTimeout(() => {

			this.setState({
				formApiResponse: {
					className: "",
					message: "",
				},
			});
		}, 3000);
	}

	// Handler for Focus Area submit
	handleFocusAreaSubmit(e) {
		e.preventDefault();

		const { form, formErrors, notesEditorState, timescale, userFocusAreas } = this.state;

		const errorObj = this.validateForm(form, formErrors, this.validateField.bind(this));

		let notesContent = notesEditorState && notesEditorState.getCurrentContent().hasText() ? convertToRaw(notesEditorState.getCurrentContent()) : null;

		if (Object.keys(errorObj).length !== 0) {

			this.setState({
				formErrors: { ...formErrors, ...errorObj },
			});

			return false;

		} else {

			var formCopy = { ...form };

			formCopy['notes'] = notesContent;

			var focusAreaResponses = [];

			_.forEach(this.props.focusAreaPrompts, function (value, key) {
				focusAreaResponses.push({
					...value,
					response: formCopy[value['uniqueID']],
				})
			});

			let focusAreaObj = _.find(userFocusAreas, { id: form['focusAreaID'] });

			var postObj = {
				focusAreaTitle: focusAreaObj['focusAreaTitle'],
				fa_responses: focusAreaResponses,
				notes: notesContent,
				type: "custom",
				timescale: timescale,
				guidingstars: this.props.timescaleRecordId,
			}

			if(formCopy['type'] == "system-default") {
				postObj['systemDefaultFocusAreaID'] = form['focusAreaID'];
				postObj['fromSystemDefault'] = true;
			}

			this.setState({
				buttonDisabled: true,
			});

			this.props.setLoading(true);

			API_Services.httpPOST(UrlConstants.URLS.addFocusAreaForTheTimescale, postObj, (err, response) => {

				if (err) {
					if (err.response && err.response.data) {
						this.setFormApiResponse(err.response.data, true)
					} else {
						this.setFormApiResponse("Something went wrong!", true);
						console.log('err...', err);
					}
				} else if (response.data) {

					if(response.data.status) {
						this.setFormApiResponse("Record have been saved successfully!", false);
						this.props.addFocusAreaInList(response.data.data);
					} else {
						this.setFormApiResponse("Something went wrong!", true);
					}
				} else {
					this.setFormApiResponse("Something went wrong!", true);
				}

				this.props.setLoading(false);
				this.setState({
					buttonDisabled: false,
				});
			});

		}
	}

	handleFocusAreaTitleTypeSelect(name, value) {

		let formObj = { ...this.state.form };
		formObj[name] = value['value'];
		formObj['type'] = value['type'];

		this.setState({
			form: formObj,
		});
	}

	// Handler for Focus Area submit
	createFocusArea(e) {
		e.preventDefault();

		const { userFocusAreas, customFocusAreaTitle } = this.state;

		if (_.isEmpty(customFocusAreaTitle)) {

			this.setState({
				customFocusAreaTitleError: "Please enter focus area title",
			});
			return false;
		}

		// If creating custom focus area already exists
		var ifFocusExists = _.find(userFocusAreas, function (fa) {
			return fa.focusAreaTitle.toLowerCase() === customFocusAreaTitle.toLowerCase();
		})
		if(ifFocusExists) {
			
			this.setState({
				customFocusAreaTitleError: "This focus area title is already in the list. Please choose it from the dropdown",
			});
			return false;
		}

		let tempIDForNewCustomFA = Util.generateObjectId();

		let userFocusAreasCopy = [
			...this.state.userFocusAreas,
			{
				focusAreaTitle: customFocusAreaTitle,
				id: tempIDForNewCustomFA,
				type: "custom",
			}
		];

		// Choose the newly created FA
		let formObj = { ...this.state.form };
		formObj['focusAreaID'] = tempIDForNewCustomFA;
		formObj['type'] = "custom";

		this.setState({
			userFocusAreas: userFocusAreasCopy,
			form: formObj,
		});

		this.toggleAddCustomFocusArea();
	}

	// Handler for adding custom focus area change event
	handleNewFAInputField(event) {

		const { name, value } = event.target;

		this.setState({
			customFocusAreaTitle: value,
			customFocusAreaTitleError: "",
		});

	}

	toggleAddCustomFocusArea() {

		if (this.state.addCustomFocusAreaModal) {
			this.setState({
				addCustomFocusAreaModal: false,
				customFocusAreaTitle: "",
				customFocusAreaTitleError: "",
			});
		} else {
			this.setState({
				addCustomFocusAreaModal: true,
			});
		}
	}

	renderAddCustomFocusArea() {

		const { customFocusAreaTitle, customFocusAreaTitleError } = this.state;

		return (
			<div>
				<Modal size={'lg'} isOpen={this.state.addCustomFocusAreaModal} toggle={this.toggleAddCustomFocusArea.bind(this)} backdrop={"static"} keyboard={false}>
					<ModalHeader toggle={this.toggleAddCustomFocusArea.bind(this)}>Add Custom Focus Area</ModalHeader>
					<ModalBody>
						<div>
							<label>Focus Area Title <span className="text-danger">*</span></label>		
							<input type="text" onChange={this.handleNewFAInputField.bind(this)} className="form-control" name="customFocusAreaTitle" value={customFocusAreaTitle} />
							<p className="text-danger">{customFocusAreaTitleError &&
									<span className="err">{customFocusAreaTitleError}</span>}</p>
							<button type="button" className="btn btn-primary mt-2" onClick={this.createFocusArea.bind(this)}>Create</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}

	render() {

		const { form, formErrors, dynamicqrendered, formApiResponse, notesEditorState, userFocusAreas } = this.state;

		if (dynamicqrendered) {

			let dropDownOptions = [];

			if( userFocusAreas && userFocusAreas.length > 0 ) {

				dropDownOptions = Util.focusAreaDropdownOptions(userFocusAreas);
			}

			return (
				<form onSubmit={this.handleFocusAreaSubmit.bind(this)}>

					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Focus Area Title <span className="text-danger">*</span></label>
								<div className="row">
									<div className="col-sm-9">
										<ReactSelect
											name="focusAreaID"
											styles={SharedStyles.selectBoxStyles}
											options={dropDownOptions}
											value={_.find(dropDownOptions, { value: form['focusAreaID'] })}
											autosize={true}
											onChange={this.handleFocusAreaTitleTypeSelect.bind(this, 'focusAreaID')}
											placeholder="Select one from these option(s)"
										/>
									</div>
									<div className="col-sm-1 text-center" style={{lineHeight:2.5}}>[OR]</div>
									<div className="col-sm-2 text-right">
										<button type="button" className="btn btn-primary" onClick={this.toggleAddCustomFocusArea.bind(this)}>Add New</button>
									</div>
								</div>
								<p className="text-danger">{formErrors['focusAreaID'] &&
									<span className="err">{formErrors['focusAreaID']}</span>}</p>
							</div>
						</div>
					</div>

					{
						this.props.focusAreaPrompts.map((value, index) => {

							return <div className="row" key={index}>
								<div className="col-md-12">
									<div className="form-group">
										<label>{value.prompt} <span className="text-danger">*</span></label>
										<div>
											<TextareaAutosize 
												onChange={this.handleInputField.bind(this)} 
												className="form-control" 
												minRows={value.defaultRowsToBeRendered}
												maxRows={10} 
												name={value.uniqueID} 
												value={form[value.uniqueID]}>
													{form[value.uniqueID]}
											</TextareaAutosize>
											<p className="text-danger">{formErrors[value.uniqueID] &&
												<span className="err">{formErrors[value.uniqueID]}</span>}</p>
										</div>
									</div>
								</div>
							</div>
						})
					}

					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<label>Notes</label>
								<div className="htmlEditor">
									<Editor
										editorState={notesEditorState}
										toolbarClassName="toolbarClassName"
										wrapperClassName="wrapperClassName"
										editorClassName="editorClassName"
										onEditorStateChange={(editorState) =>
											this.setState({
												notesEditorState: editorState,
											})
										}
									/>
								</div>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary mb-2">Add Focus Area</button>
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
					{this.renderAddCustomFocusArea()}
				</form>
			);
		} else {
			return (
				<div></div>
			);
		}

	}
}

AddFocusArea.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	AddFocusArea
);
