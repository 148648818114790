import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactSelect from 'react-select';

import { setLoading } from "../../actions/loadingActions.js";
import { getFocusAreasForTheUser, searchFocusAreasForTheUser } from "../../actions/focusAreaActions";
import API_Services from '../../utils/API_Services';
import SharedStyles from '../../assets/styles/SharedStyles';

import Util from '../../utils/Util';
var _ = require('lodash');
var UrlConstants = require('../../utils/UrlConstants');

class FocusAreaSelect extends Component {

	constructor(props) {
		super(props);
		this.timeout = null;
		this.state = {
			formApiResponse: {
				className: "",
				message: "",
			},
		}
	}

	setFormApiResponse(message, hasError) {

		if (hasError) {
			alert(message);
		};
		return;
		
		this.setState({
			formApiResponse: {
				className: hasError ? "text-danger" : "text-success",
				message: message,
			},
		});
	}

	componentDidMount() {

		this.props.setLoading(true);

		API_Services.httpGET(UrlConstants.URLS.getFocusAreasForTheUser, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getFocusAreasForTheUser(response);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	}

	// Search focus areas
	searchFocusAreasForTheUser(searchString) {

		this.setState({
			formApiResponse: {
				className: "",
				message: "",
			},
		});

		this.props.setLoading(true);

		var endPoint = UrlConstants.URLS.searchFocusAreasForTheUser;

		var postObj = searchString;

		API_Services.httpPOST(endPoint, postObj, (err, response) => {

			if (err) {
				if (err.response && err.response.data) {
					this.setFormApiResponse(err.response.data, true)
				} else {
					this.setFormApiResponse("Something went wrong!", true);
					console.log('err...', err);
				}
			} else if (response.data) {

				if (response.data.status) {
					this.props.getFocusAreasForTheUser(response);
				} else {
					this.setFormApiResponse(response.data.message, true);
				}
			} else {
				this.setFormApiResponse("Something went wrong!", true);
			}

			this.props.setLoading(false);
		});
	};

	// Handler for focusArea change event
	handleFocusAreaSelect(name, value) {
		this.props.handleFocusAreaSelect(name, value);
	}

	// Handler for focusArea name search
	handleFocusAreaInputChange(value) {

		// if (value) {

		// 	// Search focusAreas only after user stops typing
		// 	if (this.timeout) clearTimeout(this.timeout);
		// 	this.timeout = setTimeout(() => {
		// 		this.searchFocusAreasForTheUser({ searchString: value });
		// 	}, 300);
		// }
	}

	render() {

		const { focusarearesponses } = this.props.focusareas;
		const { formApiResponse } = this.state;

		var focusAreasOptions = [];

		if (focusarearesponses.length > 0) {

			focusAreasOptions = Util.focusAreaDropdownOptions(focusarearesponses);
		}

		if (this.props.hasAllOption) {
			focusAreasOptions.push({
				label: "All",
				value: "all"
			});
		};

		let selectedValue = null;

		if (_.isObject(this.props.value) && this.props.value.value) { // Eg; {label: 'Wellbeing', value: '123456yqwyjb-234'}
			selectedValue = _.find(focusAreasOptions, {value: this.props.value.value}); // Eg. 123456yqwyjb-234 => Places like productivity planner's UpdateProjects.js file will use this format.
		}
		else if (_.isString(this.props.value)) {
			selectedValue = _.find(focusAreasOptions, {value: this.props.value});
		};

		return (
			<div className="form-group">
				<label>FocusArea {this.props.isRequired ? <span className="text-danger">*</span> : null}</label>
				<div>
					<ReactSelect
						name="focusArea"
						styles={SharedStyles.selectBoxStyles}
						closeOnSelect={false}
						options={focusAreasOptions}
						value={selectedValue}
						removeSelected={true}
						autosize={true}
						clearable={true}
						onSelectResetsInput={true}
						onChange={this.handleFocusAreaSelect.bind(this, 'focusArea')}
						onInputChange={this.handleFocusAreaInputChange.bind(this)}
						placeholder="Select Focus Area"
						isDisabled={this.props.disabled}
					/>
					{
						this.props.error
							?
							<p className="text-danger"><span className="err">{this.props.error}</span></p>
							:
							null
					}
					{
						!_.isEmpty(formApiResponse)
							?
							<p className={formApiResponse.className}><span>{formApiResponse.message}</span></p>
							: null
					}
				</div>
			</div>
		);
	}
}

FocusAreaSelect.propTypes = {
	searchFocusAreasForTheUser: PropTypes.func.isRequired,
	getFocusAreasForTheUser: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	focusareas: state.focusareas,
});

export default connect(mapStateToProps, { getFocusAreasForTheUser, searchFocusAreasForTheUser, setLoading })(
	FocusAreaSelect
);
