import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import moment from "moment";

import { setLoading } from "../../actions/loadingActions.js";

var _ = require('lodash');

class DailyNotes extends Component {

	constructor(props) {
		super(props);
		this.state = {
			notesEditorState: {},
			dailyNotes: {},
			showNotesForTheDate: this.props.showNotesForTheDate
		};
	}

	componentDidMount() {
		this.props.onRef(this)
	}

	constructDailyNotesEditorState(props) {

		let dailyNotes = props.dailyNotes || {};
	
		if(!_.isEqual(this.state.dailyNotes, props.dailyNotes)) {
	
			this.setState({
				notesEditorState: {
					"0": dailyNotes['0'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['0'])) : EditorState.createEmpty(), // Notes corresponding to Mon
					"1": dailyNotes['1'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['1'])) : EditorState.createEmpty(), // Notes corresponding to Tue
					"2": dailyNotes['2'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['2'])) : EditorState.createEmpty(), // Notes corresponding to Wed
					"3": dailyNotes['3'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['3'])) : EditorState.createEmpty(), // Notes corresponding to Thu
					"4": dailyNotes['4'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['4'])) : EditorState.createEmpty(), // Notes corresponding to Fri
					"5": dailyNotes['5'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['5'])) : EditorState.createEmpty(), // Notes corresponding to Sat
					"6": dailyNotes['6'] ? EditorState.createWithContent(convertFromRaw(dailyNotes['6'])) : EditorState.createEmpty()  // Notes corresponding to Sun
				},
				dailyNotes: props.dailyNotes,
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		
		this.constructDailyNotesEditorState(nextProps);
		
		if (this.state.showNotesForTheDate != nextProps.showNotesForTheDate) {
			
			this.setState({
				showNotesForTheDate: nextProps.showNotesForTheDate
			},
			() => {
				console.log('showNotesForTheDate ==', this.state.showNotesForTheDate);
			});
		};
	}

	handleSubmit() {

		// e.preventDefault();

		this.props.setLoading(true);

		const { notesEditorState } = this.state;

		let notesContent = {
			"0": notesEditorState['0'] && notesEditorState['0'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['0'].getCurrentContent()) : null, // Notes corresponding to Mon
			"1": notesEditorState['1'] && notesEditorState['1'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['1'].getCurrentContent()) : null, // Notes corresponding to Tue
			"2": notesEditorState['2'] && notesEditorState['2'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['2'].getCurrentContent()) : null, // Notes corresponding to Wed
			"3": notesEditorState['3'] && notesEditorState['3'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['3'].getCurrentContent()) : null, // Notes corresponding to Thu
			"4": notesEditorState['4'] && notesEditorState['4'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['4'].getCurrentContent()) : null, // Notes corresponding to Fri
			"5": notesEditorState['5'] && notesEditorState['5'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['5'].getCurrentContent()) : null, // Notes corresponding to Sat
			"6": notesEditorState['6'] && notesEditorState['6'].getCurrentContent().hasText() ? convertToRaw(notesEditorState['6'].getCurrentContent()) : null  // Notes corresponding to Sun
		};

		this.props.handleDailyNoteSaveChanges(notesContent);
	}

	handleNoteChangeForTheDay(editorState, dayIndex) {

		//console.log('editorState...', editorState);

		const { notesEditorState } = this.state;
		notesEditorState[dayIndex] = editorState;

		this.setState({
			notesEditorState: notesEditorState
		},
			() => {
				//console.log('notesEditorState...', notesEditorState);
			});
	}

	renderNotesForTheDay(day, dayIndex) {

		let date = moment(this.props.targetWeekStart).add(dayIndex, 'days').format('MM/DD/YYYY');

		if (this.state.showNotesForTheDate == 'all' || this.state.showNotesForTheDate == date) {

			return (
				<tr style={{color:'#737373'}}>
					<td className="text-center">
						<div>{day}</div>
						<div style={{fontSize:13}} className="mt-2">{date}</div>
					</td>
					<td>
						<Editor
							toolbar={{
								options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
							}}
							editorState={this.state.notesEditorState[dayIndex]}
							placeholder="Enter your note here..."
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={(editorState) => this.handleNoteChangeForTheDay(editorState, dayIndex)}
						/>
					</td>
				</tr>
			);
		}
		else {
			return null;
		}
	}

	render() {

		return (
			<div>

				<div className="d-flex justify-content-between align-items-center">
					<h6>DAILY NOTES</h6>
					{/* <button type="button" onClick={this.handleSubmit.bind(this)} className="btn btn-primary btn-sm">Save Notes</button> */}
				</div>

				<table className="table table-curved w-100 mt-4">
					<thead>
						<tr>
							<th width="100px" className="text-center">Day</th>
							<th className="text-center">Notes</th>
						</tr>
					</thead>
					<tbody>
						{this.renderNotesForTheDay('Mon', '0')}
						{this.renderNotesForTheDay('Tue', '1')}
						{this.renderNotesForTheDay('Wed', '2')}
						{this.renderNotesForTheDay('Thu', '3')}
						{this.renderNotesForTheDay('Fri', '4')}
						{this.renderNotesForTheDay('Sat', '5')}
						{this.renderNotesForTheDay('Sun', '6')}
					</tbody>
				</table>
			</div>
		);
	}
}

DailyNotes.propTypes = {
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { setLoading })(
	DailyNotes
);
