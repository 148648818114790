import React from "react";
import moment from "moment";
import _ from "lodash";
import Util from "../../utils/Util";

const CustomEvent = (props) => {

	// console.log("CustomEvent - > ", props)

	let completedHiglighterClassName = 'tracker-completed-event';

	if (props.event.isUnplanned) completedHiglighterClassName = 'tracker-completed-un-planned-event';
	else if (props.event.isNonPBHEvent) completedHiglighterClassName = 'tracker-completed-non-pbh-event';

	completedHiglighterClassName += " mr-1";

	let title = Util.getEventTitle(props.event);
	
	// Active tasks associated with an event
	let associatedTasks = props.event.tasks ? _.filter(props.event.tasks, {isActive: true}) : [];

	return (
		<div>

			{ props.event.isCompleted ? <span className={completedHiglighterClassName}>&#10003;</span> : null }

			<span>{title}</span> <br/>
			{
				/**
				 * Add extra information here
				 */
			}
			<div className="rbc-event-label">{ moment(props.event.start).format('LT') + ' - '+moment(props.event.end).format('LT')}</div>
			<div>{associatedTasks.length > 0 ? <i className="fa fa-lg fa-tasks my-1" aria-hidden="true"></i> : null }</div>
		</div>
	)
}

export default CustomEvent;